import { Backdrop, Button, Dialog, DialogTitle, IconButton, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BasicDialog } from '../BasicDialog/BasicDialog';
import Loading from '../../../Loading/Loading';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

export const RestoreProcedure = (props) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    props.closeDialog();
    setOpen(false);
  };

  useEffect(() => {
    setOpen(props.openDialog);
  }, [props.openDialog]);

  const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  return (
    <>
      {loading && (
        <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 100 }} open={loading}>
          <Loading />
        </Backdrop>
      )}

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        scroll="paper"
        PaperProps={{
          sx: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '24px !important',
            minWidth: '400px',
          },
        }}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}></BootstrapDialogTitle>
        <Grid container sx={{ color: 'text.primary', justifyContent: 'center' }}>
          <CheckCircleIcon fontSize="large" color="success" />
        </Grid>

        <br />
        <br />
        <Grid container sx={{ color: 'text.primary', justifyContent: 'center', alignItems: 'center', display: 'flex',textAlign: 'center',}}>
          <Grid item xs={8}>
            {`${props.procedureName} ${t('PROCEDURE.WAS_RESTORED_SUCCESSFULLY')}`}
          </Grid>
        </Grid>
      </BootstrapDialog>
    </>
  );
};
