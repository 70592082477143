import { mediaType } from '../../entities/Trainee/WorkInstructions';

export function convertFileBlobToMediaType(file: File): mediaType {
  let objUrl = URL.createObjectURL(file);
  let media: mediaType = {
    fileType: file.type,
    title: file.name,
    objectUrl: objUrl,
    dataUrl: objUrl,
  };
  return media;
}

