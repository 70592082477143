import { IUserStats } from './Trainee/MockTrainee';

export class AssemblyState {
  currentStep: number = 1;
  totalSteps: number = 1;
  currentTime: number = 0;
  userStats: IUserStats = {} as IUserStats;
  showParts: boolean = false;
  showTools: boolean = false;
  showSteps: boolean = true;
  volume: number = 0;
  play: boolean = false;
  showExploded: boolean = false;
  showAnnotations: boolean = false;
  isFullScreen: boolean = false;
  currentZoom: number = 100;
}

export class FileManager {
  selectedFile: null | undefined;
}

export interface Steps {
  timeSpend?: number;
}

export interface Procedures {
  procedureId: string | undefined;
  currentStep?: number;
  status?: string;
  steps?: object[];
}
