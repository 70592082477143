import { KeyboardArrowDown, Search } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, Menu, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Theme, ToggleButton, ToggleButtonGroup, Typography, useMediaQuery, useTheme } from '@mui/material';
import BorderAllRoundedIcon from '@mui/icons-material/BorderAllRounded';
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import PublishIcon from '@mui/icons-material/Publish';
import React, { useCallback, useEffect, useState } from 'react';
import './UserList.scss';
import FuzzySearch from 'fuzzy-search';
import { User } from '../../entities/User/MockUser';
import UserService from '../../services/User/UserService';
import { useParams, useNavigate } from 'react-router-dom';
import UserCard from './UserCard';
import { useDropzone } from 'react-dropzone';
import Loading from '../../components/Loading/Loading';
import { Access } from '../../components/Auth/Access';
import { UserPermissions } from '../../shared/permissions';
import { useHasPermissions } from '../../hooks/useHasPermissions';
import { StyledGridView } from '../../components/Styled/StyledGridView';
import { StyledSwitch } from '../../components/Styled/StyledSwitch';
import { StyledAvatarRecordCount } from '../../components/Styled/StyledAvatarRecordCount';
import { Toast } from '../../components/Notifications/Notification';
import { getMessage } from '../../shared/utils/message-helpers';
import { ActionMenu } from '../../components/ActionMenu/ActionMenu';
import { trackEvent } from '../../App';
import { AmplitudeEvents } from '../../shared/enums';
import { useTranslation } from 'react-i18next';
import { useDocumentTitle } from '../../App';
import { debounce } from "lodash";

interface Props {
  getSubscription: any;
}

const UserList: React.FC<Props> = (props) => {
  let navigate = useNavigate();
  const [layout, setLayout] = useState<string | null>('card');
  const [loading, setLoading] = useState(false);
  const [statusUpdating, setStatusUpdating] = useState({});

  useDocumentTitle('CDS Mentor Users');

  const handleLayout = (event: React.MouseEvent<HTMLElement>, newLayout: string | null) => {
    if (newLayout !== null) {
      setLayout(newLayout);
    }
  };
  const [sort, setSort] = useState<string>('createdDate');
  const [filter, setFilter] = useState<string>('');
  const [users, setUsers] = useState<Array<User>>([]);
  const [filteredUsers, setFilteredUsers] = useState<User[]>();
  const [list, setList] = useState(false);

  const sortOptions = [
    {
      key: 'createdDate',
      name: 'Added On',
    },
    {
      key: 'firstName',
      name: 'First Name',
    },
    {
      key: 'lastName',
      name: 'Last Name',
    },
  ];

  const [openDialog, setOpenDialog] = React.useState(false);
  const [anchorElImport, setAnchorElImport] = React.useState<null | HTMLElement>(null);
  const openImportMenu = Boolean(anchorElImport);
  const handleImportMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElImport(event.currentTarget);
  };
  const handleImportMenuClose = () => {
    setAnchorElImport(null);
  };
  const { companyId } = useParams();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  let userList: any[] = [];

  const handleStatusChange = async (flag: boolean, record: any): Promise<boolean> => {
    const payload = { ...record, isActive: flag };
    let statusUpdateDataChange = { ...statusUpdating };
    statusUpdateDataChange[record.id] = true;
    setStatusUpdating(statusUpdateDataChange);
    return UserService.updateUser(payload.id, payload)
      .then((e) => {
        record.isActive = flag;
        setUsers([...users]);

        Toast.success(t('OPERATION_SUCCESS', { ns: 'success' }), t(e.data.messageCode, { ns: 'success' }) || e.data.message);

        statusUpdateDataChange[record.id] = false;
        setStatusUpdating(statusUpdateDataChange);
        return Promise.resolve(false);
      })
      .catch(({ response }) => {
        statusUpdateDataChange = { ...statusUpdating };
        statusUpdateDataChange[record.id] = false;
        setStatusUpdating(statusUpdateDataChange);
        // setStatusUpdating((state) => state[record.id] = false);
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        return Promise.resolve(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    UserService.getUsers()
      .then((res) => {
        setLoading(false);
        userList = [];

        res.data.data.map((obj) => {
          const newObj = {
            firstName: obj.firstName,
            lastName: obj.lastName,
            id: obj.id,
            isActive: obj.isActive,
            persona: obj.personas[0].name,
            personaId: obj.personas[0].id,
            email: obj.email,
            organizationId: obj.organizationId,
            company: obj.organizationName,
            createdDate: obj.createdOn,
          };

          userList.push(newObj);
        });

        setUsers(userList as User[]);
        setFilteredUsers(userList as User[]);
        const statusUpdateData = {};
        userList.map((o) => {
          statusUpdateData[o.id] = false;
          return o;
        });
        setStatusUpdating(statusUpdateData);
      })
      .catch(({ response }) => {
        setLoading(false);
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
      });
  }, [list]);

  const sortBy = (key) => {
    if (users) {
      setSort(key);
      let sorted;
      if (key === 'createdDate') {
        sorted = users?.sort((a, b) => b[key] - a[key]);
      } else {
        sorted = users?.sort((a, b) => a[key].localeCompare(b[key]));
      }
      setFilteredUsers(sorted as User[]);
    }
  };

  const filterBy = (key) => {
    let filtered;
    if (key === 'active') filtered = users?.filter((user) => user.isActive === true);
    else if (key === 'inactive') filtered = users?.filter((user) => user.isActive === false);
    else filtered = users;
    setFilteredUsers(filtered as User[]);
  };

  const search = debounce((key: string) => {
    if (users) {
      const searcher = new FuzzySearch(users, ['firstName', 'lastName', 'email'], {
        caseSensitive: false,
      });
      const result = searcher.search(key);
      result.length > 0 ? setFilteredUsers(result) : setFilteredUsers(users);
    }
  }, 400);

  const handleDialogOpen = () => {
    handleImportMenuClose();
    setFile('');
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleAdd = () => {
    navigate('/users/add');
  };
  const handleEdit = (id) => {
    navigate('/users/edit/' + id);
  };

  const handleDelete = (user) => {
    setLoading(true);
    UserService.deleteUser(user.id || 'null')
      .then((e) => {
        setList(!list);
        setLoading(false);
        props.getSubscription();
        trackEvent(AmplitudeEvents.USER_DELETED, { deleted_by: localStorage.getItem('email'), email: user.email });
        Toast.success(t('OPERATION_SUCCESS', { ns: 'success' }), t(e.data.messageCode, { ns: 'success' }) || e.data.message);
      })
      .catch(({ response }) => {
        setLoading(false);
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        console.error('EditUser -> editUserHandler', response);
      });
  };

  const canUpdate = useHasPermissions(UserPermissions.UPDATE);
  const canDelete = useHasPermissions(UserPermissions.DELETE);

  const [file, setFile] = useState<any>();

  const onDrop = useCallback((acceptedFiles) => {
    console.log('🚀 ~ file: UserList.tsx ~ line 188 ~ onDrop ~ acceptedFiles', acceptedFiles);
    console.log('🚀 ~ file: UserList.tsx ~ line 191 ~ onDrop ~ acceptedFiles[0].path', acceptedFiles[0].path);
    let fileFormat = acceptedFiles[0].path.split('.')[1];
    console.log('🚀 ~ file: UserList.tsx ~ line 192 ~ onDrop ~ fileFormat', fileFormat);
    setFile(
      acceptedFiles.map((file) => (
        <div key={file.path}>
          <p className="file-name">
            <b>{file.path}</b>
          </p>
          {file.size} bytes
        </div>
      )),
    );
  }, []);

  const { getRootProps, getInputProps, acceptedFiles, fileRejections } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      'text/*': ['.csv'],
    },
  });

  const handleFileChange = (files) => {};

  const handleSave = () => {};

  const handleDownload = () => {};
  const { t } = useTranslation();

  return (
    <div className="user-list-container">
      <Paper elevation={0} className="user-title-container" style={{ marginTop: '1rem' }}>
        <Box component="div" className="left">
          <Typography variant="h6" component="div" className="page-title">
            {t('USER.USERS') as string}
          </Typography>
          {users && <StyledAvatarRecordCount>{users.length}</StyledAvatarRecordCount>}
        </Box>
        <Access permission={UserPermissions.CREATE}>
          <Box component="div" className="right">
            <Button className="activeButton nonActiveButton" variant="contained" onClick={handleAdd}>
              <Box component={'div'} sx={{ display: { xs: 'none', md: 'flex' } }}>
                {t('USER.CREATE_NEW') as string}
              </Box>
              <Box component={'div'} sx={{ display: { xs: 'flex', md: 'none' } }}>
                <AddIcon />
              </Box>
            </Button>
            {/* <Button
            className="activeButton nonActiveButton"
            variant="contained"
            endIcon={<KeyboardArrowDown />}
            aria-controls={openImportMenu ? "import-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openImportMenu ? "true" : undefined}
            onClick={handleImportMenuClick}
          >
            <Box component={"div"} sx={{ display: { xs: "none", md: "flex" } }}>
              {t('USER.CREATE_MULTIPLE') as string}
            </Box>
            <Box component={"div"} sx={{ display: { xs: "flex", md: "none" } }}>
              <PublishIcon />
            </Box>
          </Button> */}
            <Menu
              id="import-menu"
              anchorEl={anchorElImport}
              open={openImportMenu}
              onClose={handleImportMenuClose}
              MenuListProps={{
                'aria-labelledby': 'import-button',
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem onClick={handleDownload}> {t('USER.DOWNLOAD_TEMPLATE') as string}</MenuItem>
              <MenuItem onClick={handleDialogOpen}> {t('USER.UPLOAD_CSV') as string}</MenuItem>
            </Menu>
            <Dialog
              PaperProps={{
                sx: {
                  borderRadius: '12px',
                },
              }}
              open={openDialog}
              onClose={handleDialogClose}
              fullScreen={fullScreen}
              fullWidth={true}
              maxWidth={'xs'}
              className="dialog-wrapper"
            >
              <DialogTitle
                sx={{
                  m: 0,
                  p: 2,
                }}
              >
                {t('USER.IMPORT_CSV') as string}
                <IconButton
                  aria-label="close"
                  onClick={handleDialogClose}
                  sx={{
                    float: 'right',
                    color: '#000000',
                    border: '1px solid #000000',
                    padding: '0',
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent sx={{ m: 0, p: 2 }}>
                <DialogContentText sx={{ mb: 3, color: '#000000' }}>{t('USER.UPLOAD_CSV_FILE') as string}</DialogContentText>
                <div className="drop-zone">
                  {file === '' && (
                    <div {...getRootProps()} className="drop-zone-container" style={{ height: '7rem' }}>
                      <input {...getInputProps()} />
                      <Box component="div" sx={{ display: 'flex' }}>
                        <img
                          src="/imgs/drag_drop.svg"
                          alt="files"
                          height="40"
                          width="40"
                          style={{
                            marginLeft: 'auto',
                            marginRight: 'auto',
                          }}
                        />
                      </Box>
                      <p style={{ marginBottom: 0 }}> {t('USER.UPLOAD_FILE_MESSAGE') as string}</p>
                    </div>
                  )}
                  {file !== '' && (
                    <div className="file-container">
                      <Box component="div" className="image" sx={{ display: 'flex', bgcolor: '#ffffff' }}>
                        <img
                          src="/imgs/csv_file.svg"
                          alt="files"
                          height="80"
                          width="80"
                          style={{
                            marginLeft: 'auto',
                            marginRight: 'auto',
                          }}
                        />
                      </Box>
                      <Box component="div" sx={{ display: 'flex', flexDirection: 'column', pl: 2 }}>
                        <Box component="div"> {file}</Box>
                        <div {...getRootProps()} className="browse">
                          <Button
                            variant="outlined"
                            sx={{
                              color: (theme: Theme) => theme.palette.primary.main,
                              height: 'fit-content',
                            }}
                          >
                            {t('USER.BROWSE') as string} <input {...getInputProps()} />
                          </Button>
                        </div>
                      </Box>
                    </div>
                  )}
                </div>
              </DialogContent>
              <DialogActions>
                <Box
                  component={'div'}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    width: '100%',
                  }}
                >
                  <Box
                    component={'div'}
                    sx={{
                      pl: 1,
                      pr: 1,
                    }}
                  >
                    <Button variant="contained" className="activeButton nonActiveButton" onClick={handleSave}>
                      {t('BUTTON.SAVE')}
                    </Button>
                  </Box>
                  <Box
                    component={'div'}
                    sx={{
                      pr: 1,
                    }}
                  >
                    <Button onClick={handleDialogClose} variant="outlined" sx={{ color: (theme: Theme) => theme.palette.primary.main }} className="cancel">
                      {t('BUTTON.CANCEL')}
                    </Button>
                  </Box>
                </Box>
              </DialogActions>
            </Dialog>
          </Box>
        </Access>
      </Paper>
      <Access permission={UserPermissions.READ}>
        <Paper elevation={0} className="user-title-container">
          <Box component="div" className="left">
            <ToggleButtonGroup size="small" color="primary" value={layout} exclusive onChange={handleLayout} aria-label="layout">
              <ToggleButton
                sx={{
                  color: 'primary.main',
                  bgcolor: '#ffffff',
                  border: '1px solid',
                  lineHeight: '0.7',
                  width: '2.5rem',
                  '&.Mui-selected': {
                    bgcolor: 'primary.main',
                    color: '#ffffff',
                    borderColor: 'primary.main',
                  },
                  '&.Mui-selected:hover': {
                    bgcolor: 'primary.main',
                    color: '#ffffff',
                  },
                }}
                value="card"
                aria-label="card"
              >
                <BorderAllRoundedIcon />
              </ToggleButton>
              <ToggleButton
                sx={{
                  color: 'primary.main',
                  bgcolor: '#ffffff',
                  border: '1px solid',
                  lineHeight: '0.7',
                  width: '2.5rem',
                  '&.Mui-selected': {
                    bgcolor: 'primary.main',
                    color: '#ffffff',
                    borderColor: 'primary.main',
                  },
                  '&.Mui-selected:hover': {
                    bgcolor: 'primary.main',
                    color: '#ffffff',
                  },
                }}
                value="list"
                aria-label="list"
              >
                <FormatListBulletedRoundedIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          <Box component="div" className="right">
            <Box component="div" sx={{ display: 'flex', alignItems: 'flex-end' }} className="search-btn">
              <FormControl fullWidth>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search sx={{ color: 'primary.main', mr: 1, my: 0.5 }} />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={t('COMMON.SEARCH')}
                  onChange={(e) => search(e.target.value)}
                />
              </FormControl>
            </Box>
            <FormControl
              sx={{
                m: 1,
                minWidth: 120,
                display: { xs: 'none', md: 'flex' },
              }}
            >
              <Select value={sort} onChange={(e) => sortBy(e.target.value)} size="small" displayEmpty inputProps={{ 'aria-label': 'Without label' }}>
                <MenuItem value={'createdDate'}>
                  {' '}
                  <em>{t('USER.SORT')}</em>{' '}
                </MenuItem>
                <MenuItem value={'firstName'}>{t('USER.FIRST_NAME')}</MenuItem>
                <MenuItem value={'lastName'}>{t('USER.LAST_NAME')}</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              sx={{
                m: 1,
                mr: 0,
                minWidth: 120,
                display: { xs: 'none', md: 'flex' },
              }}
            >
              <Select
                value={filter}
                onChange={(e) => {
                  setFilter(e.target.value);
                  filterBy(e.target.value);
                }}
                size="small"
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value="">
                  {' '}
                  <em>{t('COMMON.FILTER')}</em>{' '}
                </MenuItem>
                <MenuItem value={'active'}>{t('USER.ACTIVE')}</MenuItem>
                <MenuItem value={'inactive'}>{t('USER.INACTIVE')}</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Paper>
      </Access>
      {loading && <Loading />}
      {layout === 'card' && (
        <StyledGridView>
          {filteredUsers &&
            filteredUsers.map((user, index) => {
              return (
                <Grid item xs={12} md={6} lg={4} key={index}>
                  <UserCard user={user} onEdit={handleEdit} onDelete={handleDelete} canUpdate={canUpdate} canDelete={canDelete} onStatusChange={handleStatusChange} isLoading={statusUpdating[user.id]} />
                </Grid>
              );
            })}
        </StyledGridView>
      )}

      {layout === 'list' && (
        <div className="wrapper">
          <TableContainer component={Paper} sx={{ borderRadius: '0', boxShadow: 'none' }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">{t('USER.FIRST_NAME').toUpperCase()}</TableCell>
                  <TableCell align="left">{t('USER.LAST_NAME').toUpperCase()}</TableCell>
                  <TableCell align="left">{t('ADD_USER.EMAIL').toUpperCase()}</TableCell>
                  <TableCell align="left">{t('ADD_USER.COMPANY').toUpperCase()}</TableCell>
                  <TableCell align="left">{t('ADD_USER.USER_PERSONA').toUpperCase()}</TableCell>
                  <TableCell align="left">{t('COMMON.STATUS').toUpperCase()}</TableCell>
                  <TableCell align="center">{t('COMMON.ACTIONS').toUpperCase()}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredUsers &&
                  filteredUsers.map((user, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        '&:nth-of-type(odd)': {
                          backgroundColor: theme.palette.action.hover,
                        },
                      }}
                    >
                      <TableCell align="left">{user.firstName}</TableCell>
                      <TableCell align="left">{user.lastName}</TableCell>
                      <TableCell align="left">{user.email}</TableCell>
                      <TableCell align="left">{user.company}</TableCell>
                      <TableCell align="left">{user.persona}</TableCell>
                      <TableCell align="left">
                        <FormControlLabel className="active" control={<StyledSwitch name="isActive" checked={user.isActive} color={user.isActive ? 'primary' : 'error'} onChange={(e) => handleStatusChange(e.target.checked, user)} />} label={user.isActive ? t('USER.ACTIVE') : t('USER.INACTIVE')} />
                      </TableCell>
                      <TableCell align="center">
                        <ActionMenu onEdit={() => handleEdit(user.id)} onDelete={() => handleDelete(user.id)} editPermission={UserPermissions.UPDATE} deletePermission={UserPermissions.DELETE} />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
};

export default UserList;
