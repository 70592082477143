import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React, { ReactNode } from 'react';

export interface ConfirmationDialogProps {
  title: string;
  message?: string;
  value: string;
  open: boolean;
  onClose: (value?: string) => void;
  okText?: string;
  okButtonColor?:
    | 'primary'
    | 'inherit'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
    | undefined;
  cancelText?: string;
  cancelButtonColor?:
    | 'primary'
    | 'inherit'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
    | undefined;
  children?: ReactNode;
}

export const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  const {
    title,
    message,
    value: valueProp,
    open,
    onClose,
    okText = 'OK',
    okButtonColor = 'primary',
    cancelText = 'Cancel',
    cancelButtonColor = 'inherit',
    children,
    ...other
  } = props;
  const [value, setValue] = React.useState(valueProp);

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value);
  };

  return (
    <Dialog
      keepMounted
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
      {...other}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {props.message && <Box component="div">{props.message}</Box>}
        {props.children && <Box component="div">{props.children}</Box>}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color={cancelButtonColor}
          autoFocus
          onClick={handleCancel}
        >
          {cancelText}
        </Button>
        <Button variant="contained" color={okButtonColor} onClick={handleOk}>
          {okText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
