import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Chip, Grid, MenuItem, Select, Skeleton, Typography, useTheme } from '@mui/material';
import './TraineeAssignedDetails.scss';
import Loading from '../../../Loading/Loading';
import TraineeAssignedDetailsHeader from './TraineeAssignedDetailsHeader';
import { SelectionType, useAnalytics } from '../../../../states/analytics';
import TrendingCount from '../../TrendingCount';
import { ProceduresAssigned } from './TraineeAssignedProceduresPieChart';
import TraineeAssignedProceduresAreaChart from './TraineeAssignedProceduresAreaChart';
import TraineeAssignedProceduresList from './TraineeAssignedProceduresList';
import { IAnalyticsUserData, IDateRange, IOrganizationNewlyAssigned, IOrganizationProcedureCompletionStatus, IOrganizationTraineeActivityAnalysis } from '../../../../entities/Analytics/AnalyticsProcedure';
import { useLocation, useParams } from 'react-router-dom';
import AnalyticsService from '../../../../services/Analytics/AnalyticsService';
import { Toast } from '../../../Notifications/Notification';
import moment from 'moment';
import { DATE_FORMAT } from '../../../../shared/utils/const-helpers';
import UserService from '../../../../services/User/UserService';
import { TraineeProcedureProgressStatus } from '../../../../shared/enums';
import { useTranslation } from 'react-i18next';
import TableContainer, { columnFilter } from '../../ProcedureReports/TableContainer';
import { secondsToHms } from '../../ProcedureReports/TraineeTime';
import { BasicDialog } from '../../../Trainer/Dialog/BasicDialog/BasicDialog';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { lighten } from '../../../Cards/TourCard/TourCard';
import { QuestionResponsesPerUser } from '../../Modals/QuestionResponses';

interface TraineeAssignedDetailsProps {}

export const TraineeAssignedDetails: React.FC<TraineeAssignedDetailsProps> = () => {
  const data = [
    { name: 'Completed', value: 40 },
    { name: 'Pending', value: 6 },
    { name: 'Not Started', value: 6 },
  ];
  enum LoadersState {
    GET_USER = 'GET_USER',
    PROCEDURE_COUNT = 'PROCEDURE_COUNT',
    PROCEDURE_STATUS = 'PROCEDURE_STATUS',
    ACTIVITY_ANALYSIS = 'ACTIVITY_ANALYSIS',
    ALL_PROCEDURES = 'ALL_PROCEDURES',
  }
  type LoaderStateType = { [key in LoadersState]: boolean };
  type ProcedureStatusKeyType = keyof typeof TraineeProcedureProgressStatus;
  type ProcedureStatusType = (typeof TraineeProcedureProgressStatus)[ProcedureStatusKeyType];
  const currentDateRange = useAnalytics((state) => state.selectedDateRange);
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState<LoaderStateType>({
    [LoadersState.GET_USER]: true,
    [LoadersState.PROCEDURE_COUNT]: true,
    [LoadersState.PROCEDURE_STATUS]: true,
    [LoadersState.ACTIVITY_ANALYSIS]: true,
    [LoadersState.ALL_PROCEDURES]: true,
  });
  const [statusFilter, setStatusFilter] = useState<ProcedureStatusType>(TraineeProcedureProgressStatus.COMPLETED);
  const [userData, setUserData] = useState<IAnalyticsUserData>();
  const [assignedProceduresCountData, setAssignedProceduresCountData] = useState<IOrganizationNewlyAssigned>();
  const [proceduresProgressCount, setproceduresProgressCount] = useState<Array<any>>();
  const [activityAnalysisData, setActivityAnalysisData] = useState<Array<IOrganizationTraineeActivityAnalysis>>();
  const [procedureCompletionStatusData, setProcedureCompletionStatusData] = useState<Array<IOrganizationProcedureCompletionStatus>>([]);
  const [stepCompletionStatusData, setStepCompletionStatusData] = useState<Array<any>>([]);
  const [selectedWeek, setSelectedWeek] = useState(0);
  const [showComments, setShowComments] = useState(false);
  const [showResponses, setShowResponses] = useState(false);
  const [procedure, setProcedure] = useState('');
  const [comments, setComments] = useState<any>([]);
  const [questionResponses, setQuestionResponses] = useState<any>([]);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [showCycleTime, setShowCycleTime] = useState(false);
  const { userId } = useParams();
  const { search: searchQuery } = useLocation();
  const query = new URLSearchParams(searchQuery);
  const filterType = query.get('filter');
  const startDate = query.get('startDate');
  const endDate = query.get('endDate');

  const QuestionResponses = () => {
    return (
      <BasicDialog
        title={`Responses`}
        onClose={() => {
          setShowResponses(false);
        }}
        onOpen={showResponses}
      >
        <QuestionResponsesPerUser questionResponses={questionResponses} />
      </BasicDialog>
    );
  };

  const theme = useTheme();

  const columns = useMemo(
    () => [
      {
        Header: 'Procedure Name',
        accessor: 'procedure',
        Cell: ({ value }) => (
          <Box component="div" sx={{ display: 'flex', flexDirection: 'column', padding: '20px' }}>
            <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '12px' }}>WI: {value.wiName}</Typography>
            <Typography sx={{ fontSize: '14px', margin: '8px 0px' }}>{value.procedureName}</Typography>
            <Box component="div" sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography className="feedback" sx={{ background: lighten(theme.palette.primary.main, 0.95), color: 'rgba(0, 0, 0, 0.6)' }}>
                Ratings {value.feedback ? (value?.feedback[0]?.ratings || '0') + '/5' : '(N/A)'}
              </Typography>
              <Typography className="feedback" sx={{ background: lighten(theme.palette.primary.main, 0.95), color: 'rgba(0, 0, 0, 0.6)' }}>
                Recommended {value.feedback ? (value?.feedback[0]?.recommendation || '0') + '/10' : '(N/A)'}
              </Typography>
              <Button
                sx={{ fontSize: '12px', background: lighten(theme.palette.primary.main, 0.95), borderRadius: '4px', padding: '1px', margin: 0, marginLeft: '6px', minHeight: 0 }}
                onClick={() => {
                  setShowComments(true);
                  setComments(value.feedback);
                }}
              >
                Comments {value?.feedback?.filter((obj) => obj.comments !== null).length || 0}
              </Button>
            </Box>
          </Box>
        ),
      },
      {
        Header: 'Status',
        accessor: 'status',
        disableSortBy: true,
        Filter: columnFilter,
        Cell: ({ value }) => <span className={value === 'Completed' ? 'status-success-color' : value === 'In-Progress' ? 'status-warning-color' : 'status-error-color'}>{value}</span>,
      },
      {
        Header: 'Assigned',
        accessor: 'assigned_on',
        Cell: ({ value }) => <span>{moment.unix(value).format('Do MMM,YYYY')}</span>,
      },
      {
        Header: 'Assigned By',
        accessor: 'assigned_by',
        disableSortBy: true,
      },
      {
        Header: 'Started',
        accessor: 'started',
        Cell: ({ value }) => <span>{moment.unix(value).format('Do MMM,YYYY')}</span>,
      },
      {
        Header: 'Completed',
        accessor: 'completed',
        Cell: ({ value }) => <span>{moment.unix(value).format('Do MMM,YYYY')}</span>,
      },
      {
        Header: 'Time Taken',
        accessor: 'time_taken',
        Cell: ({ value, row }) => (
          <Button
            sx={{ fontSize: '12px', background: lighten(theme.palette.primary.main, 0.95), borderRadius: '4px', padding: '1px', margin: 0, marginLeft: '6px', minHeight: 0 }}
            onClick={() => {
              setShowCycleTime(true);
              setSelectedRow(row.index);
              const stepArr: any = [];
              procedureCompletionStatusData[row.index]?.stepNames?.map((rows, index) => {
                stepArr.push({ stepNames: rows, stepCycleTime: procedureCompletionStatusData[row.index]?.stepCycleTime[index], elapsedTime: procedureCompletionStatusData[row.index]?.elapsedTime[index] });
              });
              setStepCompletionStatusData(stepArr);
            }}
          >
            {value}
          </Button>
        ),
      },
      {
        Header: 'Time Allotted',
        accessor: 'time_allotted',
      },
      {
        Header: 'Responses',
        accessor: 'responses',
        disableSortBy: true,
        Cell: ({ value }) => {
          return (
            <Box component="div" className="left">
              {value?.answers?.length > 0 ? (
                <Button
                  onClick={() => {
                    setShowResponses(true);
                    setQuestionResponses(value.answers);
                  }}
                  sx={{
                    margin: 0,
                    padding: 0,
                    minWidth: 0,
                  }}
                >
                  View ({value?.answers?.length}){' '}
                </Button>
              ) : (
                '-'
              )}
            </Box>
          );
        },
      },
    ],
    [procedureCompletionStatusData],
  );

  const stepColumns = useMemo(
    () => [
      {
        Header: 'Step Names',
        accessor: 'stepNames',
      },
      {
        Header: 'Step Cycle Time',
        accessor: 'stepCycleTime',
        Cell: ({ value }) => {
          if (value === 0 || value === undefined || value === null) {
            return <span className={'status-error-color'}>Not Set</span>;
          }
          return secondsToHms(value);
        },
        disableSortBy: true,
      },
      {
        Header: 'Elapsed Time',
        accessor: 'elapsedTime',
        Cell: ({ value }) => {
          if (value === 0) {
            return '0s';
          }
          return secondsToHms(value);
        },
      },
    ],
    [],
  );

  useEffect(() => {
    setLoading(false);

    if (currentDateRange && currentDateRange.startDate && currentDateRange.endDate && userId) {
      loadData(currentDateRange, userId);
    } else if (startDate && endDate && userId) {
      loadData(
        {
          startDate: startDate,
          endDate: endDate,
        } as IDateRange,
        userId,
      );
    }

    return () => {};
  }, []);

  useEffect(() => {
    let dateRange: IDateRange = currentDateRange;
    if (!dateRange.startDate && !dateRange.endDate) {
      dateRange = {
        startDate: startDate,
        endDate: endDate,
      } as IDateRange;
    }
    if (dateRange && dateRange.startDate && dateRange.endDate && userId && statusFilter) {
      getAllProcedures(dateRange, userId, statusFilter);
    }
  }, [statusFilter]);

  const loadData = (dateRange: IDateRange, userId: string) => {
    getUser(userId);
    getAssignedProceduresCount(dateRange, userId);
    getCountsByProcedureStatus(dateRange, userId);
    getTraineeActivityAnalysis(dateRange, userId);
    getAllProcedures(dateRange, userId, statusFilter);
  };

  const setLoadingDataState = (key: keyof typeof LoadersState, value: boolean) => {
    setLoadingData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const getUser = (userId: string) => {
    setLoadingDataState(LoadersState.GET_USER, true);
    UserService.getUsersById(userId)
      .then((e) => {
        const {
          data: { data: user },
        } = e;
        setUserData(user);
        setLoadingDataState(LoadersState.GET_USER, false);
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        setLoadingDataState(LoadersState.GET_USER, false);
      });
  };

  const getAssignedProceduresCount = (dateRange: IDateRange, userId: string) => {
    setLoadingDataState(LoadersState.PROCEDURE_COUNT, true);
    AnalyticsService.getAnalyticsOrganizationTraineeNewlyAssigned(userId, dateRange.startDate, dateRange.endDate)
      .then((e) => {
        const {
          data: { data: records },
        } = e;
        setproceduresProgressCount([
          { name: t('COMMON.WORKINSTRUCTION_TRAINEE_STATUS_COMPLETED'), value: records.procedureProgress.COMPLETED },
          { name: t('COMMON.WORKINSTRUCTION_TRAINEE_STATUS_IN_PROGRESS'), value: records.procedureProgress.INPROGRESS },
          { name: t('COMMON.WORKINSTRUCTION_TRAINEE_STATUS_NOT_STARTED'), value: records.procedureProgress.NOTSTARTED },
        ]);
        setAssignedProceduresCountData(records);
        setLoadingDataState(LoadersState.PROCEDURE_COUNT, false);
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        setLoadingDataState(LoadersState.PROCEDURE_COUNT, false);
      });
  };

  const getCountsByProcedureStatus = (dateRange: IDateRange, userId: string) => {};

  const getTraineeActivityAnalysis = (dateRange: IDateRange, userId: string) => {
    setLoadingDataState(LoadersState.ACTIVITY_ANALYSIS, true);
    AnalyticsService.getAnalyticsOrganizationTraineeActivityAnalysis(userId, dateRange.startDate, dateRange.endDate)
      .then((e) => {
        const {
          data: { data: records },
        } = e;

        setActivityAnalysisData(records);
        setLoadingDataState(LoadersState.ACTIVITY_ANALYSIS, false);
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        setLoadingDataState(LoadersState.ACTIVITY_ANALYSIS, false);
      });
  };

  const getAllProcedures = (dateRange: IDateRange, userId: string, statusFilter: ProcedureStatusType) => {
    setLoadingDataState(LoadersState.ALL_PROCEDURES, true);
    AnalyticsService.getAnalyticsOrganizationTraineeCompletionStatus(userId, statusFilter, dateRange.startDate, dateRange.endDate)
      .then((e) => {
        const {
          data: { data: records },
        } = e;

        const arr: any = [];
        e?.data?.data?.map((procedure) => {
          arr.push({
            procedure: { procedureName: procedure.procedureName, wiName: procedure.workInstructionName, feedback: procedure.feedback },
            elapsedTime: procedure.elapsedTime,
            stepCycleTime: procedure.stepCycleTime,
            stepNames: procedure.stepNames,
            status: procedure.status,
            assigned_on: procedure.assignedOn,
            assigned_by: procedure.assignedBy,
            started: procedure.startTimeStamp || null,
            completed: procedure.endTimeStamp || null,
            time_taken: secondsToHms(procedure.completionTime) || null,
            time_allotted: secondsToHms(procedure.estimated_time) || null,
            responses: { procedure: procedure.procedureName, answers: procedure.questions },
          });
        });

        setProcedureCompletionStatusData(arr);
        setLoadingDataState(LoadersState.ALL_PROCEDURES, false);
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        setLoadingDataState(LoadersState.ALL_PROCEDURES, false);
      });
  };

  const handleFilterTypeClick = (filterType: SelectionType) => {};

  const handleFilterChange = (filterType: ProcedureStatusType) => {
    setStatusFilter(filterType);
  };

  const { t } = useTranslation();

  const handleWeekChange = (weekIndex) => {
    setSelectedWeek(weekIndex);
  };

  const calculateWeekData = (selectedWeek) => {
    const startIndex = selectedWeek * 7;
    const endIndex = startIndex + 6;
    return activityAnalysisData?.slice(startIndex, endIndex + 1);
  };
  const generateDropdownOptions = () => {
    if (!activityAnalysisData || activityAnalysisData.length < 1) {
      return [];
    }
    const weeksInMonth = Math.ceil((activityAnalysisData?.length || 0) / 7);
    return Array.from({ length: weeksInMonth }, (_, index) => {
      const start = moment(activityAnalysisData[index * 7]?.date, 'DD MM YYYY').format('DD MMM');
      const end = moment(activityAnalysisData[index * 7 + 6]?.date || activityAnalysisData[activityAnalysisData?.length - 1]?.date, 'DD MM YYYY').format('DD MMM');
      return { value: index, label: `${start} - ${end}` };
    });
  };

  const weekData = useMemo(() => calculateWeekData(selectedWeek), [selectedWeek, activityAnalysisData]);
  const dropdownOptions = useMemo(() => generateDropdownOptions(), [selectedWeek, activityAnalysisData]);

  return (
    <>
      {loading && <Loading />}
      {showResponses && <QuestionResponses />}
      {selectedRow >= 0 && showCycleTime && (
        <BasicDialog
          title={`Step Cycle Time`}
          onClose={() => {
            setShowCycleTime(false);
            setSelectedRow(null);
          }}
          onOpen={showCycleTime}
        >
          <div style={{ width: '100%' }}>
            {procedureCompletionStatusData[selectedRow]?.stepNames ? (
              <TableContainer columns={stepColumns} data={stepCompletionStatusData} />
            ) : (
              <Grid container sx={{ color: 'text.primary', justifyContent: 'center', alignItems: 'center', display: 'flex', textAlign: 'center' }}>
                <Grid item xs={8}>
                  Data Unavailable
                </Grid>
              </Grid>
            )}
          </div>
        </BasicDialog>
      )}

      <BasicDialog
        title={`Comments (${comments?.filter((obj) => obj.comments !== null).length || 0})`}
        onClose={() => {
          setShowComments(false);
        }}
        onOpen={showComments}
      >
        {comments?.map(
          (feedback, index) =>
            feedback.comments && (
              <Box
                component="div"
                key={`key-${index}`}
                sx={{
                  pb: 2,
                  mb: 2,
                  width: '500px',
                  borderBottom: '1px solid #80838869',
                }}
              >
                {/* <Typography sx={{ mb: 2, fontSize: '14px' }}>
              <AccountCircleIcon sx={{ mb: '-7px' }} /> {comment?.firstName + ' ' + comment?.lastName}
            </Typography> */}
                <Typography sx={{ color: '#808388', fontSize: '14px', ml: '8px' }}>{feedback.comments}</Typography>
              </Box>
            ),
        )}
      </BasicDialog>

      {!loading && (
        <Box component="div" className="org-trainee-assigned-details analytics" sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box component="div">
            {loadingData[LoadersState.GET_USER] === true && <Skeleton variant="rectangular" width={150} height={52} />}
            {loadingData[LoadersState.GET_USER] === false && userData && <TraineeAssignedDetailsHeader title={userData?.firstName + ' ' + userData?.lastName} subtitle={userData?.personas[0]?.name} onFilterTypeClick={(filterType) => handleFilterTypeClick(filterType)} />}
          </Box>
          <Grid container spacing={2} sx={{ minHeight: '450px', mt: '10px', display: 'flex' }}>
            <Grid item xs={6} sx={{ display: 'flex' }}>
              <Box component="div" className="analytics-box" sx={{ display: 'flex', flexGrow: 1 }}>
                {loadingData[LoadersState.PROCEDURE_COUNT] === true && (
                  <Box component="div" sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                    <Skeleton variant="rectangular" width={150} sx={{ marginTop: '10px' }} height={60} />
                    <Skeleton variant="rectangular" width={'100%'} sx={{ marginTop: '60px', marginBottom: '24px' }} height={'80%'} />
                  </Box>
                )}

                {loadingData[LoadersState.PROCEDURE_COUNT] === false && <ProceduresAssigned data={proceduresProgressCount} />}
              </Box>
            </Grid>
            <Grid item xs={6} sx={{ display: 'flex' }}>
              <Box component="div" className="analytics-box" sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
                <Box component="div" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography sx={{ display: 'flex', alignItems: 'flex-start', fontSize: '18px' }}>{'Weekly Data'}</Typography>
                  <Select sx={{ boxShadow: 'none', outline: 'none', border: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }, '.MuiOutlinedInput:focus': { border: 0 } }} value={selectedWeek} onChange={(e) => handleWeekChange(e.target.value)}>
                    {dropdownOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>

                {loadingData[LoadersState.ACTIVITY_ANALYSIS] === true && <Skeleton variant="rectangular" width={'100%'} sx={{ marginTop: '24px' }} height={'80%'} />}
                {loadingData[LoadersState.ACTIVITY_ANALYSIS] === false && weekData && <TraineeAssignedProceduresAreaChart data={weekData} />}
              </Box>
            </Grid>
          </Grid>
          <Grid container sx={{ mt: '35px' }}>
            <TableContainer columns={columns} data={procedureCompletionStatusData} />
            {loadingData[LoadersState.ALL_PROCEDURES] === true && <Skeleton variant="rectangular" width={'100%'} sx={{ marginTop: '24px' }} height={'50px'} />}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default TraineeAssignedDetails;
