import { Dialog, DialogContent, Grid, Box, Typography, Button, styled, ListItem, List, IconButton } from '@mui/material';
import { demoSteps } from '../../../shared/data/demoSteps';
import { useTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import CloseIcon from '@mui/icons-material/Close';

const MUIDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    height: '500px',
    maxHeight: '500px',
    display: 'flex',
  },
  '& .MuiDialog-paper': {
    width: '90%',
    maxWidth: 'none',
  },
  '& .MuiDialogActions-root': {
    padding: '0px 24px 24px 24px',
  },
}));

export const DemoDialog = ({ demoStep, onClose, setDemoStep }) => {
  const [videoLoading, setVideoLoading] = useState(false);
  const theme = useTheme();
  return (
    <>
      <MUIDialog open={true} onClose={onClose}>
        <DialogContent>
          <Grid container spacing={2} sx={{ display: 'flex' }}>
            <Grid xs={12} md={4} item sx={{ display: 'flex' }}>
              <Box component="div" sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', backgroundColor: theme.palette.primary.main + '1c', flexGrow: 1, padding: '24px', borderRadius: '8px' }}>
                <Box component="div">
                  <Typography sx={{ fontSize: '1.1rem' }}>{demoSteps[demoStep - 1].title}</Typography>

                  <Box component="div">
                    <List>
                      {demoSteps[demoStep - 1]?.description?.map((line, index) => (
                        <ListItem key={index} sx={{ display: 'flex', fontSize: '0.89rem', paddingLeft: 0 }}>
                          {' '}
                          <KeyboardArrowRightOutlinedIcon sx={{ color: theme.palette.primary.main }} />
                          <Typography sx={{ display: 'flex', fontSize: '0.89rem', color: '#777777' }}>{line}</Typography>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Box>
                <Box component="div" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography>
                    {demoStep} of {demoSteps.length}
                  </Typography>
                  <Box component="div">
                    {demoStep !== 1 && (
                      <Button onClick={() => setDemoStep(demoStep - 1)} sx={{ margin: '0px 8px' }}>
                        Back
                      </Button>
                    )}
                    {demoStep !== demoSteps.length && (
                      <Button variant="contained" onClick={() => setDemoStep(demoStep + 1)}>
                        Next
                      </Button>
                    )}
                    {demoStep === demoSteps.length && (
                      <Button variant="contained" onClick={() => onClose()}>
                        Finish
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid xs={12} md={8} item sx={{ display: 'flex' }}>
              <Box component="div" sx={{ alignItems: 'flex-end' }}>
                <IconButton
                  aria-label="close"
                  onClick={() => {
                    onClose();
                  }}
                  sx={{
                    color: (theme) => theme.palette.grey[500],
                    padding: 0,
                    position: 'absolute',
                    right: '15px',
                    top: '15px',
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              {videoLoading && (
                <Box component="div" sx={{ display: 'flex', flexGrow: 1, alignItems: 'center', justifyContent: 'center' }}>
                  Loading...
                </Box>
              )}

              <React.Fragment key={`/videos/${demoSteps[demoStep - 1].video}.mp4`}>
                <video
                  onLoadStart={() => {
                    setVideoLoading(true);
                  }}
                  onLoadedData={() => {
                    setVideoLoading(false);
                  }}
                  width={videoLoading ? '0%' : '100%'}
                  height={videoLoading ? '0px' : '500px'}
                  autoPlay
                  loop
                  controls={false}
                >
                  <source src={`/videos/${demoSteps[demoStep - 1].video}.mp4`} type="video/mp4" />
                </video>
              </React.Fragment>
            </Grid>
          </Grid>
        </DialogContent>
      </MUIDialog>
    </>
  );
};
