import { Backdrop, Button } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BasicDialog } from '../BasicDialog/BasicDialog';
import TrainerService from '../../../../services/trainer/TrainerService';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import Loading from '../../../Loading/Loading';

export const DuplicateProcedure = (props) => {
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const { refreshProcedure, setRefreshProcedure } = useCurrentUser();

  const cloneProcedure = async () => {
    const payload = {
      procedureId: props.procedureId,
    };
    setLoading(true);
    TrainerService.cloneProcedure(payload)
      .then((response) => {
        setRefreshProcedure(!refreshProcedure);
        setTimeout(() => setLoading(false), 2000);
        props.closeDialog();
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  return (
    <>
      {loading && (
        <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 100 }} open={loading}>
          <Loading />
        </Backdrop>
      )}
      <BasicDialog
        title={t('PROCEDURE.DUPLICATE_PROCEDURE')}
        onClose={() => {
          props.closeDialog();
        }}
        onOpen={props.openDialog}
        actions={
          <>
            <Button
              onClick={() => {
                props.closeDialog();
              }}
              variant="outlined"
              size="small"
              type="submit"
            >
              {t('BUTTON.CANCEL') as string}
            </Button>
            <Button
              onClick={() => {
                cloneProcedure();
              }}
              variant="contained"
              size="small"
              type="submit"
            >
              {t('BUTTON.CONFIRM') as string}
            </Button>
          </>
        }
      >
        {t('PROCEDURE.ARE_YOU_SURE_DUPLICATE_PROCEDURE')}
      </BasicDialog>
    </>
  );
};
