import React from 'react';
import { Grid, styled, useTheme } from '@mui/material';

export const StyledGridView = (props) => {
  const theme = useTheme();

  const StyledGridViewComponent = styled(Grid)({
    padding: '2rem',
  });

  return (
    <StyledGridViewComponent
      container
      spacing={{ xs: 2, md: 4 }}
      direction={'row'}
      alignItems={'stretch'}
      {...props}
    >
      {props.children}
    </StyledGridViewComponent>
  );
};
