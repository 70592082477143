import React from 'react';
import { Card, styled, useTheme } from '@mui/material';

export const StyledCard = (props) => {
  const theme = useTheme();

  const { minCardHeight, ...rest } = props;
  const mh = minCardHeight ?? '280px';

  return (
    <Card
      sx={{
        display: 'flex',
        borderRadius: '12px',
        boxShadow: 'rgba(149, 157, 165, 0.5) 0px 8px 24px',
        height: '100%',
        minHeight: mh,
        minWidth: '400px',
        width: '100%',
      }}
      {...rest}
    >
      {props.children}
    </Card>
  );
};
