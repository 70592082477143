import { useEffect, useState } from "react";
import FileManagerService from "../../../services/FileManager/FileManagerService";
import { Box, CardMedia, Typography } from "@mui/material";
import { SmallFolderIcon } from "../../../components/Icons/CdsIcons";
import fallBackIcon from '../../../assets/fallback_img.svg';

const EntityName =  ({ value, row }) => {
    const [thumbnail, setThumbnail] = useState('');
    const getThumbnailImage = (workInstructionImageURL) => {
      if (workInstructionImageURL)
        FileManagerService.getWorkInstructionThumbnailPath(workInstructionImageURL)
          .then((res) => {
            const blob = new Blob([res.data]);
            const url = URL.createObjectURL(blob);
            setThumbnail(url);
          })
          .catch((err) => {
            console.log(err);
          });
      else {
        return '';
      }
    };

    useEffect(() => {
      getThumbnailImage(row.original.workInstructionImageURL);
    }, [row.original.workInstructionImageURL]);
    return (
      <Typography sx={{ marginLeft: '8px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Typography sx={{ marginRight: '8px' }}>
          {row.original.type === 'WorkInstruction' && (
            <Box component="div">
              {row.original.workInstructionImageURL && <CardMedia sx={{ width: '28px', height: '28px', objectFit: 'fill', display: 'flex', alignItems: 'center' }} component="img" image={thumbnail} alt="work instruction model" />}
              {!row.original.workInstructionImageURL && <CardMedia component="img" sx={{ width: '28px', height: '28px', objectFit: 'fill', display: 'flex', alignItems: 'center' }} image={fallBackIcon} alt="work instruction model"></CardMedia>}
            </Box>
          )}
          {row.original.type === 'Folder' && <SmallFolderIcon />}
        </Typography>
        <Typography sx={{ marginLeft: '8px', fontSize: '16px', fontWeight: '600' }}>{value}</Typography>
      </Typography>
    );
  }

  export {
    EntityName,
  }