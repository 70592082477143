import { PROCEDURE_VISIBILITY_SCOPE } from "../../shared/enums";

export enum PendingStatus {
  ALL = 'ALL',
  UNAPPROVED = 'UNAPPROVED',
  UNASSIGNED = 'UNASSIGNED',
  UNPUBLISHED = 'UNPUBLISHED',
  REQUIRE_STEPS = 'REQUIRE STEPS',
  REJECTED = 'REJECTED',
}

export enum AssignedStatus {
  INPROGRESS = 'INPROGRESS',
  NOTSTARTED = 'NOTSTARTED',
  COMPLETED = 'COMPLETED',
}

export enum AnalyticsLabels {
  MY_PENDING_PROCEDURES = 'These are the procedures that are created by you in the selected time period and that require trainers action',
  ALL_PENDING_PROCEDURES = 'These are all the procedures that are created in the selected time period and that require trainers action',
  TRAINEES_ASSIGNED = 'These are the trainees who got a procedure (or more) assigned to them in the selected time period.',
  ASSIGNED_PROCEDURES = 'These are the procedures that were assigned during the selected time period',
}

export type PendingStatusType = keyof typeof PendingStatus;
export type AssignedStatusType = keyof typeof AssignedStatus;

export interface IPendingProcedure {
  pendingStatus: PendingStatusType[];
  procedure: IProcedure;
  workInstruction: IWorkInstruction;
}

export interface IWorkInstruction {
  workInstructionId: string;
  organizationId: string;
  workInstructionName: string;
  workInstructionDescription: string;
  workInstructionImageURL: string;
  brand: string;
  procedures: string[];
  fileStatus: string;
  createdOn: number;
  createdBy: string;
  modifiedOn: number;
  modifiedBy: string;
  file: IFile;
  status: string;
  is2d?: boolean;
  workInstructionType?: string;
}

export interface IProcedure {
  procedureType?: PROCEDURE_VISIBILITY_SCOPE;
  procedureId: string;
  organizationId: string;
  approvalSentStatus?: string;
  approvalStatus?: string;
  procedureName: string;
  documentId: string;
  workInstructionId: string;
  approvalId: string;
  versionId: string;
  version: string;
  changelogId: string;
  isPrerequisite: boolean;
  ppeEquipments: string[];
  createdOn: number;
  createdBy: string;
  estimated_time: number;
  duplicateContentCreated?: string;
  isPublished: boolean;
  tasks_count: number;
  file: IFile;
  stepNames?: string[]
  visibilityScope?: any;
}

export interface IFile {
  name?: string;
  size?: number;
}

export interface IProcedureCompletionStatus {
  date: string;
  value: number;
}

export interface IProcedureFeedback {
  ratings: number;
  recommendation: number;
  comments: IComment[];
}

export interface IComment {
  userId: string;
  firstName: string;
  lastName: string;
  comment: string;
}

export interface IProcedureAverageTimeTaken {
  estimatedTime: number;
  avgTimeTaken: number;
}

export interface IProcedureTrainees {
  traineeCount: number;
  traineesData: ITraineesDatum[] | null;
}

export interface ITraineesDatum {
  assignedOn: number;
  completedOn: number;
  startedOn: number;
  userId: string;
  firstName: string;
  lastName: string;
  responses: { trainee: string; answers: Array<any> };
  procedureName: string;
  trainee_name: string;
  status: string;
  estimatedTime: number;
  completionTime: string;
  ratings: number;
  comments: string;
  recommendation: number;
  calls: number;
  answers: Array<IAnswers>;
}

export interface IAnswers {
  questionText: string;
  questionType: string;
  questionUUID: string;
  multiChoiceCorrectAnswerIndex: number;
  multipleSelectAnswers: string[];
  answer: number;
}

export interface IProcedureTraineeDetails {
  userId: string;
  firstName: string;
  lastName: string;
  procedureName: string;
  status: string;
  estimatedTime: number;
  completionTime: string;
  ratings: number;
  comments: string;
  recommendation: number;
  calls: number;
  answers: Array<IAnswers>;
}

export interface IOrganizationAssignedProcedures {
  procedureId: string;
  procedureName: string;
  workInstructionId: string;
  workInstructionName: string;
  versionId: string;
  versionName: string;
  progressStatus: {
    status: string;
    percentage: number;
  };
}
export interface IOrganizationNewlyAdded {
  totalWorkInstructions: number;
  totalProcedures: number;
  newlyAddedWorkInstructions: number;
  newlyAddedProcedures: number;
}

export interface IOrganizationAssignedTrainee {
  userId: string;
  firstName: string;
  lastName: string;
  progress: IProcedureProgress;
  newlyAddedProcedures?: number;
}

export interface IProcedureProgress {
  notStarted: string[];
  inProgress: string[];
  completed: string[];
}

export interface IDateRange {
  startDate: string;
  endDate: string;
}

export interface IOrganizationNewlyAssigned {
  totalProceduresAssigned: number;
  newlyAddedProcedures: number;
  procedureProgress: {
    COMPLETED: number;
    INPROGRESS: number;
    NOTSTARTED: number;
  };
}

export interface IOrganizationProcedureCompletionStatus {
  userId: string;
  workInstructionId: string;
  procedureId: string;
  versionId: string;
  assignedOn: number;
  assignedBy: string;
  estimated_time: number;
  organizationId: string;
  startTimeStamp: number;
  endTimeStamp: number;
  questions: IQuestion[];
  status: string;
  currentStep: number;
  completedSteps: number[];
  completionTime: string;
  version: string;
  procedureName: string;
  workInstructionName: string;
  feedback: IFeedback;
  elapsedTime: number[];
  stepNames: string[];
  stepCycleTime: number[];
}

export interface IFeedback {
  id: string;
  procedureId: string;
  organizationId: string;
  versionId: string;
  userId: string;
  ratings: number;
  recommendation: number;
  comments: string;
}

export interface IQuestion {
  questionText: string;
  questionType: string;
  questionUUID: string;
  multiChoiceCorrectAnswerIndex: number;
  multipleSelectAnswers: string[];
  answer: number;
  step: number;
  title: string;
}

export interface IOrganizationTraineeActivityAnalysis {
  date: string;
  value: number;
}

export interface IOrganizationPendingProcedure {
  pendingCounts: {
    unapproved: IRequireStep[];
    unassigned: IRequireStep[];
    unpublished: IRequireStep[];
    noSteps: IRequireStep[];
  };
  pendingApprovals:any[],
  pendingProceduresData: Array<IPendingProcedure>;
  totalPendingProceduresCount: number;
}

export interface IRequireStep {
  procedureId: string;
  organizationId: string;
  versionId: string;
  procedureName?: string;
  documentId?: string;
  workInstructionId: string;
  isPrerequisite?: boolean;
  ppeEquipments?: PpeEquipment[];
  createdOn: number;
  createdBy: string;
  modifiedOn: number;
  modifiedBy: string;
  estimated_time?: number;
  isPublished: boolean;
  approvalId: string;
  tasks_count?: number;
  file?: IFile;
  tools?: Tool[];
  workInstructionName?: string;
  approvingManager?: null | string;
  signatures?: null | string;
  stepsLog?: IStepsLog[];
}

export interface IFile {
  name?: string;
  size?: number;
}

export enum PpeEquipment {
  EarplugsOrMuffs = 'Earplugs or Muffs',
  FullBodySuits = 'Full Body Suits',
  Gloves = 'Gloves',
  HardHats = 'Hard Hats',
  SafetyGlasses = 'Safety Glasses',
  SafetyGloves = 'Safety Gloves',
  Shoes = 'Shoes',
  Vests = 'Vests',
}

export interface IStepsLog {
  modifiedOn: number;
  modifiedBy: string;
  stepsAdded: number;
  toolsAdded: null;
}

export enum Tool {
  ToolData = 'toolData',
  UploadedToolData = 'uploadedToolData',
}

export interface IAnalyticsUserData {
  id: string;
  organizationId: string;
  organizationName: string;
  personas: IAnalyticsPersona[];
  createdOn: number;
  modifiedOn: number;
  isActive: boolean;
  email: string;
  firstName: string;
  lastName: string;
  userName: string;
}

export interface IAnalyticsPersona {
  id: string;
  appId: string;
  name: string;
}

export interface IOrganizationProceduresAssigned {
  totalNotStartedProcedures: number;
  totalInProgressProcedures: number;
  totalCompletedProcedures: number;
  procedureLevelInsight: IProcedureLevelInsight[];
}
export interface IProcedureLevelInsight {
  procedureId: string;
  totalNumberOfTrainees: number;
  progress: IProgress;
}
export interface IProgress {
  notStarted: number;
  completed: number;
  inProgress: number;
}
