import React from 'react';
import SubscriptionEnd from '../../assets/subscription.svg';
import { Box, Button, Card, CardMedia, Dialog, DialogActions, Grid, Typography } from '@mui/material';
import { trackEvent } from '../../App';
import { AmplitudeEvents } from '../../shared/enums';
import UserService from '../../services/User/UserService';
import { useMsal } from '@azure/msal-react';

export const EndSubscription = ({ subscriptionDetails }) => {
  const timestamp = subscriptionDetails?.expiration;
  const now = Math.floor(Date.now() / 1000);
  const difference = now - timestamp;
  const daysAgo = Math.floor(difference / (60 * 60 * 24));
  const { instance } = useMsal();
  const handleLogout = () => {
    trackEvent(AmplitudeEvents.LOGGED_OUT, { email: localStorage.getItem('email') });
    UserService.disableUserToken()
      .then(async (res) => {
        await instance.initialize();
        instance.logoutRedirect().catch((e) => {
          console.error(e);
        });
        localStorage.clear();
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <>
      <Box component="div" sx={{ display: 'flex', width: '100%' }}>
        <Dialog
          keepMounted
          sx={{
            '& .MuiDialog-paper': {
              minWidth: '50%',
              margin: '0px',
            },
          }}
          open={true}
        >
          <Grid container>
            <CardMedia component="img" sx={{ width: '100%' }} image={SubscriptionEnd}></CardMedia>

            <Grid item xs={12}>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '24px',
                  margin: '40px 32px 16px 32px',
                  fontFamily: 'Fira Sans',
                  color: '#A73131',
                }}
              >
                Your CDS Mentor subscription expired {daysAgo > 0 ? `${daysAgo} days ago` : ''}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography sx={{ fontSize: '18px', fontWeight: 400, fontFamily: 'Fira Sans', margin: '0px 32px 32px 32px' }}>
                Please ask your admin to renew the subscription. <br />
                We will notify you once the subscription is purchased.
              </Typography>
              <Box component="div" sx={{ display: 'flex', justifyContent: 'flex-end', margin: '0px 32px 32px 0px' }}>
                <Button variant="contained" sx={{ height: 'fit-content', minWidth: 0, alignSelf: 'flex-end' }} onClick={handleLogout}>
                  Logout
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Dialog>
      </Box>
    </>
  );
};
