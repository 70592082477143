export enum PROCEDURE_LIBRARY_DIALOG {
  SHARE_PREVIEW = 'SHARE_PREVIEW',
  SHARE_QR = 'SHARE_QR',
  EXPORT_PDF = 'EXPORT_PDF',
  DUPLICATE = 'DUPLICATE',
  DELETE = 'DELETE',
  LANGUAGE_SUPPORT = 'LANGUAGE_SUPPORT',
  REVOKE_ACCESS = 'REVOKE_ACCESS',
  SELECT_WORK_INSTRUCTION = "SELECT_WORK_INSTRUCTION",
  SELECT_PROCEDURE = "SELECT_PROCEDURE",
  TAG_MANAGER = "TAG_MANAGER",
  LINKED_WI = "LINKED_WI",
  VERSION_DIALOG = "VERSION_DIALOG",
  PERIODIC_REVIEW = "PERIODIC_REVIEW",
  MANAGER_REJECT = "MANAGER_REJECT",
  MANAGER_APPROVE = "MANAGER_APPROVE"
}
