import React, { useEffect, useState } from 'react';
import './ReferenceDocumentation.scss';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Paper, Grid, Button, useTheme } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArticleIcon from '@mui/icons-material/Article';
import TraineeService from '../../../services/trainee/TraineeService';
import { Toast } from '../../../components/Notifications/Notification';
import { Link, useParams } from 'react-router-dom';
import Loading from '../../../components/Loading/Loading';
import FileManagerService from '../../../services/FileManager/FileManagerService';
import axios from 'axios';
import TutorialService from '../../../services/trainee/TraineeService';
import TrainerService from '../../../services/trainer/TrainerService';
import { LeftArrow } from '../../../components/Icons/CdsIcons';
import useCurrentTranslation from '../../../hooks/useCurrentTranslation';
interface Props {}

const ReferenceDocumentation: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [procedures, setProcedures] = useState<any>([]);
  const [prerequisitesCount, setPrerequisitesCount] = useState();
  const [coreProceduresCount, setCoreProceduresCount] = useState();
  const [workInstruction, setWorkInstruction] = useState<any>();
  const { companyId, workInstructionId } = useParams();

  const getProcedures = () => {
    if (workInstructionId) {
      TraineeService.getProcedures(workInstructionId)
        .then((response) => {
          setProcedures(response.data.data);
          setLoading(false);
        })
        .catch(({ response }) => {
          setLoading(false);
          Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        });
    }
  };

  const getWorkInstruction = () => {
    TutorialService.getWorkInstructionsById(workInstructionId)
      .then((response) => {
        setWorkInstruction(response.data.data);
      })
      .catch((e) => {
        setWorkInstruction(null);
      });
  };

  useEffect(() => {
    if (workInstructionId) {
      setLoading(true);
      getProcedures();
      getWorkInstruction();
    }
  }, [workInstructionId]);

  useEffect(() => {
    if (procedures) {
      setPrerequisitesCount(procedures.filter((p) => p.isPrerequisite === true && !!p.file.length).length);
      setCoreProceduresCount(procedures.filter((p) => p.isPrerequisite === false && !!p.file.length).length);
    }
  }, [procedures]);

  const getReferencePdf = (procedureId, fileId) => {
    setLoading(true);
    if (workInstructionId)
      FileManagerService.getFileById(fileId)
        .then((e) => {
          const blob = new Blob([e.data], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
          window.open(url, '_blank');
          setLoading(false);
        })
        .catch((response) => {
          setLoading(false);
          Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response?.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        });
  };

  const { currentTranslation } = useCurrentTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language.toUpperCase();
  const systemPrerequisite = currentTranslation?.PREREQUISITES ? currentTranslation.PREREQUISITES[currentLanguage] : t('PROCEDURE.PRE_REQUISITES');
  const systemCoreProcedure = currentTranslation?.CORE_PROCEDURES ? currentTranslation.CORE_PROCEDURES[currentLanguage] : t('PROCEDURE.CORE_PROCEDURES');

  const ReferenceDocumentCard = ({ procedure }) => {
    return (
      <Box component="div">
        <Typography sx={{ fontSize: '20px', color: '#808080', textAlign: 'left' }}>{procedure.procedureName}</Typography>
        {procedure.file.map((file, index) => (
          <Box
            component="div"
            sx={{ display: 'flex', flexGrow: 1, backgroundColor: '#F5F4F5', margin: '24px 0px', borderRadius: '8px', alignItems: 'center', padding: '24px', cursor: 'pointer', justifyContent: 'space-between' }}
            onClick={() => {
              getReferencePdf(procedure.procedureId, file.fileId);
            }}
          >
            <Box component="div" sx={{ display: 'flex' }}>
              <ArticleIcon color="primary" />
              <Typography sx={{ ml: '24px' }}>{file.name}</Typography>
            </Box>

            <ArrowForwardIcon color="primary" />
          </Box>
        ))}
      </Box>
    );
  };

  const theme = useTheme();

  return (
    <>
      {loading && <Loading />}

      <Box component="div" sx={{ display: 'flex', padding: '32px', flexDirection: 'column', flexGrow: 1 }}>
        <Link to={`/procedures/${companyId}/${workInstructionId}`}>
          <Button
            variant="contained"
            className="all-work-instructions card"
            sx={{
              color: theme.palette.primary.main,
              mb: '16px',
              alignSelf: 'flex-start',
              display: 'flex',
            }}
          >
            <LeftArrow />
            {t('NAVBAR.ALL_PROCEDURES')}
          </Button>
        </Link>
        <Typography sx={{ justifyContent: 'flex-start', display: 'flex', fontSize: '24px', textAlign: 'left' }}>
          {workInstruction?.workInstructionName} - {t('REFERENCES.REFERENCE_DOCUMENTS')}
        </Typography>
        <Paper elevation={0} sx={{ borderRadius: '12px', boxShadow: 'rgba(149, 157, 165, 0.5) 0px 8px 24px', margin: '24px 0px', padding: '32px', display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
          <Typography sx={{ fontSize: '20px', textAlign: 'left', mb: '24px' }}>{systemPrerequisite}</Typography>
          {prerequisitesCount === 0 && !loading && <Typography sx={{ textAlign: 'left', padding: '0px 0px 24px 24px', color: '#808080' }}>{t('PROCEDURE.NO_REFERENCE_DOC_AVAILABLE')}</Typography>}
          {procedures &&
            procedures.map((procedure, index) => {
              return <div key={index}>{procedure.isPrerequisite && Boolean(procedure.file.length) && <ReferenceDocumentCard procedure={procedure} />}</div>;
            })}

          <Typography className="sentenceCase" sx={{ fontSize: '20px', textAlign: 'left', mb: '24px' }}>
            {systemCoreProcedure}
          </Typography>
          {coreProceduresCount === 0 && !loading && <Typography sx={{ textAlign: 'left', padding: '0px 0px 24px 24px', color: '#808080' }}>{t('PROCEDURE.NO_REFERENCE_DOC_AVAILABLE')}</Typography>}
          {procedures &&
            procedures.map((procedure, index) => {
              return <div key={index}>{!procedure.isPrerequisite && Boolean(procedure.file.length) && <ReferenceDocumentCard procedure={procedure} />}</div>;
            })}
        </Paper>
      </Box>
    </>
  );
};

export default ReferenceDocumentation;
