import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Typography, Autocomplete } from '@mui/material';
import Link, { LinkProps } from '@mui/material/Link';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Toast } from '../../components/Notifications/Notification';
import { IOrganization } from '../../entities/Organization/Organization';
import OrganizationService from '../../services/Organization/OrganizationService';
import { getMessage } from '../../shared/utils/message-helpers';
import OrganizationForm from './OrganizationForm';

interface AddOrganizationProps {}

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

const LinkRouter = (props: LinkRouterProps) => <Link {...props} component={RouterLink as any} />;

const AddOrganization: React.FC<AddOrganizationProps> = (props) => {
  const { t } = useTranslation();
  const breadcrumbs = [
    <Link underline="hover" key="1" sx={{ opacity: '0.7' }}>
      {t('NAVBAR.ADMINISTRATION') as string}
    </Link>,
    <LinkRouter underline="hover" key="2" to="/organizations" sx={{ opacity: '0.7' }}>
      Organizations
    </LinkRouter>,
    <Typography key="3" color="primary.main">
      Create New Organization
    </Typography>,
  ];
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const addOrganizationHandler = (organizationData: IOrganization) => {
    setLoading(true);
    OrganizationService.createOrganization(organizationData)
      .then((e) => {
        setLoading(false);
        Toast.success(t('OPERATION_SUCCESS', { ns: 'success' }), t(e.data.messageCode, { ns: 'success' }) || e.data.message);
        backHandler();
      })
      .catch(({ response }) => {
        setLoading(false);
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        console.error('AddOrganization -> addOrganizationHandler', response);
      });
  };

  const backHandler = () => {
    navigate('/organizations');
  };

  return (
    <>
      <Breadcrumbs sx={{ margin: '2rem' }} separator={<NavigateNextIcon color="primary" fontSize="small" />} aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>
      <OrganizationForm organization={{}} onBack={backHandler} onSubmit={addOrganizationHandler} isLoading={loading} />
    </>
  );
};

export default AddOrganization;
