import {
  Card,
  Box,
  CardContent,
  Typography,
  Tooltip,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Switch,
} from "@mui/material"
import React from "react"
import { Resource } from "../../entities/User/MockResource"
import "./ResourceCard.scss"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"

interface ResourceCardProps {
  resource: Resource
  onEdit: (resourceId: String) => void
  onDelete: (resourceId: String) => void
}

const ResourceCard: React.FC<ResourceCardProps> = (props) => {
  const shortenDescription = (text) => {
    return text.length > 55 ? text.substring(0, 55) + "..." : text
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleEdit = () => {
    props.onEdit(props.resource.id)
  }
  const handleDelete = () => {
    props.onDelete(props.resource.id)
  }

  return (
    <Card sx={{ display: "flex" }} elevation={5} style={{ height: "100%" }}>
      <Box
        component="div"
        sx={{ display: "flex", flexDirection: "column", p: 2, width: "100%" }}
      >
        <CardContent sx={{ flex: "1 0 auto", textAlign: "left", pt: 0, pb: 0 }}>
          <Typography component="div" variant="h5" className="resource-text">
            {props.resource.name}
          </Typography>
          <Typography
            variant="subtitle2"
            color="text.secondary"
            component="div"
            className="resource-text"
          >
            {props.resource.alias}
          </Typography>
          <Tooltip title={props.resource.description} placement="bottom-start">
            <Typography
              variant="subtitle2"
              color="text.secondary"
              component="div"
              className="resource-text"
            >
              {shortenDescription(props.resource.description)}
            </Typography>
          </Tooltip>

          <Typography
            variant="subtitle2"
            color="text.secondary"
            component="div"
            className="resource-text"
          >
            {new Date(props.resource.createdDate).toLocaleDateString()}
          </Typography>
        </CardContent>
        <Box
          component="div"
          sx={{
            display: "flex",
            alignItems: "center",
            pl: 1,
            pr: 1,
            width: "100%",
          }}
        >
          <Box
            component="div"
            sx={{
              display: "flex",
              alignItems: "center",
              pl: 1,
              justifyContent: "flex-start",
              width: "100%",
            }}
          >
            <FormControlLabel
              className="active"
              control={<Switch defaultChecked={props.resource.isActive} />}
              label={props.resource.isActive ? "Active" : "Inactive"}
            />
          </Box>
          <Box
            component="div"
            sx={{
              display: "flex",
              alignItems: "center",
              pr: 2,
              pb: 1,
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <IconButton
              size="small"
              onClick={handleClick}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              sx={{
                borderRadius: "5",
                border: "1px solid gray",
                p: 0,
                color: "black",
              }}
            >
              <MoreHorizIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem onClick={handleEdit}>Edit</MenuItem>
              <MenuItem onClick={handleDelete}>Delete</MenuItem>
            </Menu>
          </Box>
        </Box>
      </Box>
    </Card>
  )
}

export default ResourceCard
