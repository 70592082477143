import CloseIcon from '@mui/icons-material/Close';
import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { FormikErrors, FormikProps } from 'formik';
import { useEffect, useState } from 'react';
import { IAdditionalContactForm, IOrganizationFormValues } from '.';
import { MaxLengthConstants } from '../../shared/utils/const-helpers';

interface ContactFormProps {
  formik: FormikProps<IOrganizationFormValues>;
}

const ContactForm: React.FC<ContactFormProps> = ({ formik }) => {
  const [isDisable, setIsDisable] = useState<boolean>(false);

  useEffect(() => {
    let emptyFlag = false;
    const contactList = formik?.values?.contactForm?.additionalContacts;
    contactList.forEach((element) => {
      if (
        element.designation === '' ||
        element.firstName === '' ||
        element.lastName === '' ||
        element.emailAddress === '' ||
        element.phoneNumber === ''
      ) {
        emptyFlag = true;
      }
    });
    if (emptyFlag !== isDisable) {
      setIsDisable(emptyFlag);
    }
  }, [formik?.values?.contactForm?.additionalContacts]);

  const handleAddNewContact = () => {
    const additionalContacts = [ ...formik?.values.contactForm.additionalContacts ];
    additionalContacts.push({
      designation: '',
      firstName: '',
      lastName: '',
      emailAddress: '',
      phoneNumber: '',
      alternateNumber: '',
    });
    formik.setFieldValue('contactForm.additionalContacts', additionalContacts);
  };

  const handleContactDelete = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number,
  ): void => {
    const additionalContacts = [ ...formik?.values.contactForm.additionalContacts ];
    additionalContacts.splice(index, 1);
    formik.setFieldValue('contactForm.additionalContacts', additionalContacts);
  };

  return (
    <>
      <Box component={'div'}>
        <Typography
          variant="h6"
          component="div"
          className="page-title"
          sx={{
            position: 'relative',
            '&::after': {
              content: '""',
              background:
                'linear-gradient(90deg, #000000 0.71%, rgba(0, 0, 0, 0) 100%)',
              position: 'absolute',
              bottom: 0,
              left: '0px',
              width: '57%',
              height: '1px',
            },
          }}
        >
          Own Contact
        </Typography>
      </Box>
      <Grid
        container
        spacing={4}
        sx={{ paddingTop: '32px', paddingRight: '32px' }}
      >
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <FormControl fullWidth required>
            <FormLabel>Email Address</FormLabel>
            <TextField
              label=""
              name="contactForm.emailAddress"
              value={formik.values.contactForm.emailAddress}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              placeholder="Enter Email Address"
              style={{ borderRadius: '8px !important' }}
              inputProps={{ maxLength: MaxLengthConstants.Common.email }}
              required
              error={Boolean(
                formik?.touched?.contactForm?.emailAddress &&
                  formik?.errors?.contactForm?.emailAddress,
              )}
            />
            {!Boolean(
              formik?.touched?.contactForm?.emailAddress &&
                formik?.errors?.contactForm?.emailAddress,
            ) && (
              <FormHelperText className="helperText">
                {MaxLengthConstants.Common.email -
                  formik.values.contactForm.emailAddress.length}{' '}
                Characters Remaining
              </FormHelperText>
            )}
            {Boolean(
              formik?.touched?.contactForm?.emailAddress &&
                formik?.errors?.contactForm?.emailAddress,
            ) && (
              <FormHelperText className="helperText" error={true}>
                {formik?.errors?.contactForm?.emailAddress}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <FormControl fullWidth required>
            <FormLabel>Phone Number</FormLabel>
            <TextField
              label=""
              name="contactForm.phoneNumber"
              value={formik.values.contactForm.phoneNumber}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              placeholder="Enter Phone Number"
              style={{ borderRadius: '8px !important' }}
              inputProps={{ maxLength: MaxLengthConstants.Common.phone }}
              required
              error={Boolean(
                formik?.touched?.contactForm?.phoneNumber &&
                  formik?.errors?.contactForm?.phoneNumber,
              )}
            />
            {!Boolean(
              formik?.touched?.contactForm?.phoneNumber &&
                formik?.errors?.contactForm?.phoneNumber,
            ) && (
              <FormHelperText className="helperText">
                {MaxLengthConstants.Common.phone -
                  formik.values.contactForm.phoneNumber.length}{' '}
                Characters Remaining
              </FormHelperText>
            )}
            {Boolean(
              formik?.touched?.contactForm?.phoneNumber &&
                formik?.errors?.contactForm?.phoneNumber,
            ) && (
              <FormHelperText className="helperText" error={true}>
                {formik?.errors?.contactForm?.phoneNumber}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <FormControl fullWidth>
            <FormLabel>Alternate Number</FormLabel>
            <TextField
              label=""
              name="contactForm.alternateNumber"
              value={formik.values.contactForm.alternateNumber}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              placeholder="Enter Alternate Number"
              style={{ borderRadius: '8px !important' }}
              inputProps={{ maxLength: MaxLengthConstants.Common.phone }}
              error={Boolean(
                formik?.touched?.contactForm?.alternateNumber &&
                  formik?.errors?.contactForm?.alternateNumber,
              )}
            />
            {!Boolean(
              formik?.touched?.contactForm?.alternateNumber &&
                formik?.errors?.contactForm?.alternateNumber,
            ) && (
              <FormHelperText className="helperText">
                {MaxLengthConstants.Common.phone -
                  formik.values.contactForm.alternateNumber.length}{' '}
                Characters Remaining
              </FormHelperText>
            )}
            {Boolean(
              formik?.touched?.contactForm?.alternateNumber &&
                formik?.errors?.contactForm?.alternateNumber,
            ) && (
              <FormHelperText className="helperText" error={true}>
                {formik?.errors?.contactForm?.alternateNumber}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          sm={7}
          md={7}
          lg={7}
          marginBottom={2}
          sx={{
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            '&::after': {
              content: '""',
              background:
                'linear-gradient(90deg, #000000 0.71%, rgba(0, 0, 0, 0) 100%)',
              position: 'absolute',
              bottom: 0,
              left: '32px',
              width: '100%',
              height: '1px',
            },
          }}
        >
          <Typography
            variant="h6"
            component="div"
            className="page-title"
            sx={{ marginRight: '1rem' }}
          >
            Additional Contacts
          </Typography>
          {formik.values?.contactForm?.additionalContacts && (
            <Avatar
              sx={{
                bgcolor: 'neutral',
                marginRight: '1rem',
                color: '#000000',
                borderRadius: '15%',
                height: 34,
              }}
            >
              {formik.values?.contactForm?.additionalContacts.length}
            </Avatar>
          )}
          <Button
            variant="contained"
            size="small"
            disabled={isDisable}
            onClick={handleAddNewContact}
            sx={{ mt: 2, ml: 0, mb: 2, minWidth: 60 }}
          >
            Add
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box
            component={'div'}
            sx={{
              maxHeight: '18rem',
              overflow: 'hidden',
              overflowY: 'auto',
              width: '100%',
            }}
          >
            {formik.values?.contactForm?.additionalContacts &&
              formik.values?.contactForm?.additionalContacts.map(
                (ac, index) => {
                  const additionalContactTouched =
                    formik?.touched?.contactForm?.additionalContacts &&
                    formik?.touched?.contactForm?.additionalContacts.length &&
                    formik?.touched?.contactForm?.additionalContacts[index]
                      ? formik?.touched?.contactForm?.additionalContacts[index]
                      : {};
                  const additionalContactErrors =
                    formik?.errors?.contactForm?.additionalContacts &&
                    formik?.errors?.contactForm?.additionalContacts.length &&
                    formik?.errors?.contactForm?.additionalContacts[index]
                      ? (formik?.errors?.contactForm?.additionalContacts[
                          index
                        ] as FormikErrors<IAdditionalContactForm>)
                      : {};
                  return (
                    <Grid container spacing={1} key={index} sx={{ mt: 0.2 }}>
                      <Grid item xs={12} sm={6} md={2} lg={2} marginBottom={1}>
                        <FormControl variant="standard" required fullWidth>
                          <FormLabel>Designation</FormLabel>
                          <TextField
                            label=""
                            name={`contactForm.additionalContacts[${index}].designation`}
                            value={
                              formik.values.contactForm.additionalContacts[
                                index
                              ].designation
                            }
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            placeholder="Enter Designation"
                            style={{ borderRadius: '8px !important' }}
                            inputProps={{
                              maxLength: MaxLengthConstants.Common.name,
                            }}
                            required
                            error={Boolean(
                              additionalContactTouched?.designation &&
                                additionalContactErrors?.designation,
                            )}
                          />
                          {!Boolean(
                            additionalContactTouched?.designation &&
                              additionalContactErrors?.designation,
                          ) && (
                            <FormHelperText className="helperText">
                              {MaxLengthConstants.Common.name -
                                formik.values.contactForm.additionalContacts[
                                  index
                                ].designation.length}{' '}
                              Characters Remaining
                            </FormHelperText>
                          )}
                          {Boolean(
                            additionalContactTouched?.designation &&
                              additionalContactErrors?.designation,
                          ) && (
                            <FormHelperText className="helperText" error={true}>
                              {additionalContactErrors?.designation}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={2} lg={2} marginBottom={1}>
                        <FormControl variant="standard" required fullWidth>
                          <FormLabel>First Name</FormLabel>
                          <TextField
                            label=""
                            name={`contactForm.additionalContacts[${index}].firstName`}
                            value={
                              formik.values.contactForm.additionalContacts[
                                index
                              ].firstName
                            }
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            placeholder="Enter First Name"
                            style={{ borderRadius: '8px !important' }}
                            inputProps={{
                              maxLength: MaxLengthConstants.Common.name,
                            }}
                            required
                            error={Boolean(
                              additionalContactTouched?.firstName &&
                                additionalContactErrors?.firstName,
                            )}
                          />
                          {!Boolean(
                            additionalContactTouched?.firstName &&
                              additionalContactErrors?.firstName,
                          ) && (
                            <FormHelperText className="helperText">
                              {MaxLengthConstants.Common.name -
                                formik.values.contactForm.additionalContacts[
                                  index
                                ].firstName.length}{' '}
                              Characters Remaining
                            </FormHelperText>
                          )}
                          {Boolean(
                            additionalContactTouched?.firstName &&
                              additionalContactErrors?.firstName,
                          ) && (
                            <FormHelperText className="helperText" error={true}>
                              {additionalContactErrors?.firstName}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={2} lg={2} marginBottom={1}>
                        <FormControl variant="standard" required fullWidth>
                          <FormLabel>Last Name</FormLabel>
                          <TextField
                            label=""
                            name={`contactForm.additionalContacts[${index}].lastName`}
                            value={
                              formik.values.contactForm.additionalContacts[
                                index
                              ].lastName
                            }
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            placeholder="Enter Last Name"
                            style={{ borderRadius: '8px !important' }}
                            inputProps={{
                              maxLength: MaxLengthConstants.Common.name,
                            }}
                            required
                            error={Boolean(
                              additionalContactTouched?.lastName &&
                                additionalContactErrors?.lastName,
                            )}
                          />
                          {!Boolean(
                            additionalContactTouched?.lastName &&
                              additionalContactErrors?.lastName,
                          ) && (
                            <FormHelperText className="helperText">
                              {MaxLengthConstants.Common.name -
                                formik.values.contactForm.additionalContacts[
                                  index
                                ].lastName.length}{' '}
                              Characters Remaining
                            </FormHelperText>
                          )}
                          {Boolean(
                            additionalContactTouched?.lastName &&
                              additionalContactErrors?.lastName,
                          ) && (
                            <FormHelperText className="helperText" error={true}>
                              {additionalContactErrors?.lastName}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={2} lg={2} marginBottom={1}>
                        <FormControl variant="standard" required fullWidth>
                          <FormLabel>Email Address</FormLabel>
                          <TextField
                            label=""
                            name={`contactForm.additionalContacts[${index}].emailAddress`}
                            value={
                              formik.values.contactForm.additionalContacts[
                                index
                              ].emailAddress
                            }
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            placeholder="Enter Email Address"
                            style={{ borderRadius: '8px !important' }}
                            inputProps={{
                              maxLength: MaxLengthConstants.Common.email,
                            }}
                            required
                            error={Boolean(
                              additionalContactTouched?.emailAddress &&
                                additionalContactErrors?.emailAddress,
                            )}
                          />
                          {!Boolean(
                            additionalContactTouched?.emailAddress &&
                              additionalContactErrors?.emailAddress,
                          ) && (
                            <FormHelperText className="helperText">
                              {MaxLengthConstants.Common.email -
                                formik.values.contactForm.additionalContacts[
                                  index
                                ].emailAddress.length}{' '}
                              Characters Remaining
                            </FormHelperText>
                          )}
                          {Boolean(
                            additionalContactTouched?.emailAddress &&
                              additionalContactErrors?.emailAddress,
                          ) && (
                            <FormHelperText className="helperText" error={true}>
                              {additionalContactErrors?.emailAddress}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={2} lg={2} marginBottom={1}>
                        <FormControl variant="standard" required fullWidth>
                          <FormLabel>Phone Number</FormLabel>
                          <TextField
                            label=""
                            name={`contactForm.additionalContacts[${index}].phoneNumber`}
                            value={
                              formik.values.contactForm.additionalContacts[
                                index
                              ].phoneNumber
                            }
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            placeholder="Enter Phone Number"
                            style={{ borderRadius: '8px !important' }}
                            inputProps={{
                              maxLength: MaxLengthConstants.Common.phone,
                            }}
                            required
                            error={Boolean(
                              additionalContactTouched?.phoneNumber &&
                                additionalContactErrors?.phoneNumber,
                            )}
                          />
                          {!Boolean(
                            additionalContactTouched?.phoneNumber &&
                              additionalContactErrors?.phoneNumber,
                          ) && (
                            <FormHelperText className="helperText">
                              {MaxLengthConstants.Common.phone -
                                formik.values.contactForm.additionalContacts[
                                  index
                                ].phoneNumber.length}{' '}
                              Characters Remaining
                            </FormHelperText>
                          )}
                          {Boolean(
                            additionalContactTouched?.phoneNumber &&
                              additionalContactErrors?.phoneNumber,
                          ) && (
                            <FormHelperText className="helperText" error={true}>
                              {additionalContactErrors?.phoneNumber}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={1.5}
                        lg={1.5}
                        marginBottom={1}
                      >
                        <FormControl variant="standard" fullWidth>
                          <FormLabel>Alternate Number</FormLabel>
                          <TextField
                            label=""
                            name={`contactForm.additionalContacts[${index}].alternateNumber`}
                            value={
                              formik.values.contactForm.additionalContacts[
                                index
                              ].alternateNumber
                            }
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            placeholder="Enter Alternate Number"
                            style={{ borderRadius: '8px !important' }}
                            inputProps={{
                              maxLength: MaxLengthConstants.Common.phone,
                            }}
                            error={Boolean(
                              additionalContactTouched?.alternateNumber &&
                                additionalContactErrors?.alternateNumber,
                            )}
                          />
                          {!Boolean(
                            additionalContactTouched?.alternateNumber &&
                              additionalContactErrors?.alternateNumber,
                          ) && (
                            <FormHelperText className="helperText">
                              {MaxLengthConstants.Common.phone -
                                formik.values.contactForm.additionalContacts[
                                  index
                                ].alternateNumber.length}{' '}
                              Characters Remaining
                            </FormHelperText>
                          )}
                          {Boolean(
                            additionalContactTouched?.alternateNumber &&
                              additionalContactErrors?.alternateNumber,
                          ) && (
                            <FormHelperText className="helperText" error={true}>
                              {additionalContactErrors?.alternateNumber}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={'auto'}
                        sm={'auto'}
                        md={'auto'}
                        lg={'auto'}
                        sx={{
                          '&.MuiGrid-item': {
                            paddingLeft: { sm: 3, md: 3, lg: 2 },
                          },
                          marginTop: 'auto',
                          marginBottom: 'auto',
                        }}
                      >
                        <IconButton
                          size="small"
                          color="primary"
                          aria-label="close"
                          onClick={(event) => handleContactDelete(event, index)}
                          sx={{
                            border: '1px solid',
                            height: 'fit-content',
                            padding: '0',
                            marginTop: '0.3rem',
                          }}
                        >
                          <CloseIcon fontSize="small" color="primary" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  );
                },
              )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default ContactForm;
