import {
  Box,
  CardContent,
  Typography,
  Tooltip,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
} from '@mui/material';
import React from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { IOrganization } from '../../entities/Organization/Organization';
import './OrganizationCard.scss';
import { StyledCard } from '../../components/Styled/StyledCard';
import { shorten } from '../../shared/utils/helpers';
import { StyledSwitch } from '../../components/Styled/StyledSwitch';

interface OrganizationCardProps {
  organization: IOrganization;
  canUpdate: boolean;
  onEdit: (organizationId: String) => void;
  canDelete: boolean;
  onDelete: (organizationId: String) => void;
  onStatusChange: (
    flag: boolean,
    organization: IOrganization,
  ) => Promise<boolean>;
  isLoading: boolean;
}

const OrganizationCard: React.FC<OrganizationCardProps> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    props.onEdit(props.organization.id!);
  };

  const handleDelete = () => {
    props.onDelete(props.organization.id!);
  };

  const handleStatusChange = async (flag: boolean, data: IOrganization) => {
    await props.onStatusChange(flag, data);
  };

  return (
    <StyledCard minCardHeight={"250px"}>
      <Box
        component="div"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          p: 2,
          width: '100%',
          maxWidth: '100%',
        }}
      >
        <CardContent sx={{ flex: '1 0 auto', textAlign: 'left', pt: 0, pb: 0 }}>
          {/* <Stack
            sx={{
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "start",
              pb: 1,
              width: "fit-content",
            }}
            flexWrap={"wrap"}
          >
            <Chip
              size="small"
              label={props.organization.isActive ? "Active" : "Inactive"}
              variant="outlined"
              color={props.organization.isActive ? "success" : "error"}
            />
          </Stack> */}
          <Tooltip arrow title={props.organization.name} placement="bottom-start">
            <Typography
              component="div"
              variant="h5"
              className="organization-text truncate-text"
            >
              {shorten(props.organization.name, 25)}
            </Typography>
          </Tooltip>
          <Typography
            variant="subtitle2"
            color="text.secondary"
            component="div"
            className="organization-text truncate-text"
          >
            {props.organization.idpName}
          </Typography>
          <Tooltip arrow
            title={props.organization.description!}
            placement="bottom-start"
          >
            <Typography
              variant="subtitle2"
              color="text.secondary"
              component="div"
              className="organization-text truncate-text"
            >
              {shorten(props.organization.description!, 45)}
            </Typography>
          </Tooltip>
        </CardContent>
        <Box
          component="div"
          sx={{
            display: 'flex',
            alignItems: 'center',
            pl: 1,
            pr: 1,
            width: '100%',
          }}
        >
          <Box
            component="div"
            sx={{
              display: 'flex',
              alignItems: 'center',
              pl: 2,
              justifyContent: 'flex-start',
              width: '100%',
            }}
          >
            {!props.isLoading ? (
              <FormControlLabel
                disabled={!props.canUpdate}
                control={
                  <StyledSwitch
                    name="isActive"
                    checked={props.organization.isActive}
                    color={props.organization.isActive ? 'primary' : 'error'}
                    onChange={(e) =>
                      handleStatusChange(e.target.checked, props.organization)
                    }
                  />
                }
                label={props.organization.isActive ? 'Active' : 'Inactive'}
              />
            ) : (
              <Skeleton sx={{ height: '32px', width: '50%' }} />
            )}
          </Box>
          <Box
            component="div"
            sx={{
              display: 'flex',
              alignItems: 'center',
              pr: 2,
              pb: 1,
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <IconButton
              size="small"
              onClick={handleClick}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              sx={{
                borderRadius: '5',
                border: '1px solid gray',
                p: 0,
                color: 'black',
              }}
            >
              <MoreHorizIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              {props.canUpdate && (
                <MenuItem onClick={handleEdit}>Edit</MenuItem>
              )}
              {props.canDelete && (
                <MenuItem onClick={handleDelete}>Delete</MenuItem>
              )}
            </Menu>
          </Box>
        </Box>
      </Box>
    </StyledCard>
  );
};

export default OrganizationCard;
