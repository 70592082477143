import { Box, Typography } from '@mui/material';

export const QuestionResponsesPerUser = ({ questionResponses }) => {
  return (
    <>
      {questionResponses?.map((data, index) => {
        return (
          <Box
            component="div"
            key={`key-${index}`}
            sx={{
              pb: 2,
              mb: 2,
              width: '500px',
              borderBottom: '1px solid #80838869',
              ml: '8px',
            }}
          >
            <Box component="span" sx={{ display: 'flex', color: '#12181F', opacity: 0.6 }}>
              <Typography>Q:</Typography>
              <Typography sx={{ width:'100%',ml: '8px', display: { xs: 'none', md: 'flex' },  textOverflow: 'ellipsis',justifyContent: 'flex-start' }}>
                {data.questionText} <br />
              </Typography>
            </Box>
            <Box component="span" sx={{ display: 'flex', mt: '8px' }}>
              <Typography>A:</Typography>
              <Typography sx={{width:'100%', display: { xs: 'none', md: 'flex' },  textOverflow: 'ellipsis', justifyContent: 'flex-start', ml: '8px' }}>{data.questionType === 'multipleChoice' ? data.multipleSelectAnswers[data.answer] : data.answer}</Typography>
            </Box>
          </Box>
        );
      })}
    </>
  );
};
