import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect } from 'react';
import { Box, IconButton, styled, useTheme } from '@mui/material';
import TrainerService from '../../../../services/trainer/TrainerService';
import { useNavigate } from 'react-router-dom';
import Loading from '../../../Loading/Loading';
import './DeleteModularProcedure.scss';
import { useTranslation } from 'react-i18next';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import { Toast } from '../../../Notifications/Notification';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { IProcedures } from '../../../../entities/Trainee/MockTrainee';
import { fetchTemplateProcedures } from '../../../../redux/features/ModularProcedures/ModularProcedures.slice';
import { getLinkedWorkInstruction } from '../../../../services/ModularProcedure/ModularProcedure';
import { WarningIcon } from '../../../Icons/CdsIcons';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { AttachedWIList } from '../../../../pages/Trainer/ProcedureLibrary/LinkedWorkInstructions';

interface Props {
  openDialog: boolean;
  closeDialog: () => void;
  id: string;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: '24px',
  },
  '& .MuiDialogActions-root': {
    padding: '0px 24px 24px 24px',
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
  isRecycle?: boolean;
  isDeletePermanently?: boolean;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      className="remove-margin"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '24px !important',
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
            padding: 0,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const DeleteModularProcedure = (props) => {
  const { procedureId } = props;
  const {searchPayload} = useAppSelector(state => state.modularProcedure.procedureLibrary)
  const { data: templateProcedures } = useAppSelector((state) => state.modularProcedure.templateProcedures.data);
  const dispatch = useAppDispatch();

  const [procedure, setProcedure] = React.useState<IProcedures | undefined>(undefined);
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const companyId = localStorage.getItem('company');
  const navigate = useNavigate();
  const [contentReady, setContentReady] = React.useState<boolean>(true);
  const [messageRecycle, setMessageRecycle] = React.useState<string>('');
  const [messageDeletePermanently, setMessageDeletePermanently] = React.useState<string>('');
  const [showAttachedWIs, setShowAttachedWIs] = React.useState(false);

  const [attachedWIs, setAttachedWIs] = React.useState<any>([]);
  const { t } = useTranslation();

  const handleClose = () => {
    props.closeDialog();
    setOpen(false);
  };

  const { refreshProcedure, setRefreshProcedure } = useCurrentUser();
  useEffect(() => {
    setOpen(props.openDialog);
  }, [props.openDialog]);

  useEffect(() => {
    const procedureToSet = templateProcedures.find((procedure) => procedure.procedureId === procedureId);
    setProcedure(procedureToSet);
    getLinkedWorkInstruction(procedureId)
      .then((response) => {
        setAttachedWIs(response.data.data);
      })
      .catch((error) => {
        Toast.error(t('PROCEDURE_LIBRARY.LINKED_WI_DIALOG.ERROR_MSG'), error.message);
      })
  }, [procedureId]);

  const moveToRecyleBin = (id) => {
    setContentReady(false);
    TrainerService.recyclebinProcedure(id)
      .then((response) => {
        setRefreshProcedure(!refreshProcedure);
        dispatch(fetchTemplateProcedures(searchPayload))
        setOpen(props.closeDialog);
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
      })
      .finally(() => setContentReady(true));
  };

  const deletePermanently = (id) => {
    setLoading(true);

    setContentReady(false);
    TrainerService.deleteProcedure(id)
      .then((response) => {
        setOpen(false);
        setLoading(false);
        setRefreshProcedure(!refreshProcedure);
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
      })
      .finally(() => {
        setOpen(false);
        setLoading(false);
        setContentReady(true);
      });
  };

  const theme = useTheme();

  const handleExpandClick =() => {
    setShowAttachedWIs(!showAttachedWIs)
  }
  return (
    <div>
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {t('PROCEDURE.DELETE_PROCEDURE')}
        </BootstrapDialogTitle>

        <DialogContent>
          {!contentReady && (
            <Box component="div" className="align-loader">
              <Loading />
            </Box>
          )}
          {attachedWIs.length ? (
            <Box component={'div'}>
              <Box component={'div'} sx={{ display: 'flex', alignItems: 'center', color: theme.palette.error.main, gap: '8px' }}>
                <WarningIcon /> This will unlink all {attachedWIs.length} linked Work Instructions.
              </Box>
              <Box component={'div'} sx={{ display: 'flex', alignItems: 'center', color: theme.palette.primary.main, gap: '8px', cursor: 'pointer', padding: '5px 32px' }} onClick={handleExpandClick}>
                Show Linked Work Instructions {showAttachedWIs ? <ExpandLess /> : <ExpandMore />}
              </Box>
              <Box component={'div'} sx={{ padding: '5px 24px' }} onClick={handleExpandClick}>
                {showAttachedWIs && <AttachedWIList attachedWIs={attachedWIs} />}
              </Box>
            </Box>
          ) : null}
          {props.isDeletePermanently && <DialogContentText sx={{ color: '#000000' }}>{t('PROCEDURE.DELETE_PERMANENTLY_PROCEDURE_MESSAGE')}</DialogContentText>}
          {props.isRecycle && <DialogContentText sx={{ color: '#000000' }}>{t('PROCEDURE.DELETE_MODULAR_PROCEDURE_MESSAGE', { procedureName: procedure?.procedureName })}</DialogContentText>}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            {t('BUTTON.CANCEL')}
          </Button>
          {props.isRecycle && (
            <Button variant="contained" onClick={() => moveToRecyleBin(procedureId)} autoFocus>
              {t('BUTTON.DELETE')}
            </Button>
          )}
          {props.isDeletePermanently && (
            <Button variant="contained" onClick={() => deletePermanently(procedureId)} autoFocus>
              {t('BUTTON.DELETE')}
            </Button>
          )}
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default DeleteModularProcedure;
