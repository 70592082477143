import React from 'react';
import { useParams } from 'react-router-dom';
import ProcedureAnalyticsContainer from '../../components/Analytics/ProcedureReports';

const ProcedureAnalytics = () => {
  const { workInstructionId = '', procedureId = '' } = useParams();

  return (
    <ProcedureAnalyticsContainer
      procedureId={procedureId}
      workInstructionId={workInstructionId}
    />
  );
};

export default ProcedureAnalytics;
