import {
  Grid,
  FormControl,
  FormLabel,
  Input,
  InputLabel,
  ToggleButton,
  ToggleButtonGroup,
  Box,
  FormHelperText,
  TextField,
} from "@mui/material"
import { useTranslation } from "react-i18next"

interface DetailFormProps {
  name: string
  onNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  description: string
  onDescriptionChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  isActive: boolean
  onIsActiveChange: (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: boolean
  ) => void
}

const DetailForm: React.FC<DetailFormProps> = (props) => {
  const { t } = useTranslation();
  return (
    <Box component={"div"} sx={{ paddingRight: '32px' }}>
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          sx={{ marginBottom: { xs: 0, md: 2 } }}
        >
          <FormControl variant="standard" required fullWidth>
            <FormLabel>{t('ADD_USER_GROUP.GROUP_NAME') as string}</FormLabel>
            <TextField
              label=""
              name="name"
              value={props.name}
              onChange={props.onNameChange}
              placeholder={t('ADD_USER_GROUP.ENTER_GROUP_NAME') as string}
              id="component-outlined"
              style={{ borderRadius: "8px !important" }}
              inputProps={{ maxLength: 20 }}
              required
            />
            <FormHelperText id="component-helper-text" className="helperText">
              {20 - props.name.length} {t('COMMON.CHARACTERS_LEFT') as string}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          sx={{ marginBottom: { xs: 0, md: 2 } }}
        >
          <FormControl variant="standard" fullWidth>
            <FormLabel>{t('ADD_USER_GROUP.GROUP_DESCRIPTION') as string}</FormLabel>
            <TextField
              label=""
              name="description"
              value={props.description}
              onChange={props.onDescriptionChange}
              placeholder={t('ADD_USER_GROUP.ENTER_DESCRIPTION') as string}
              id="component-outlined"
              style={{ borderRadius: "8px !important" }}
              inputProps={{ maxLength: 170 }}
            />
            <FormHelperText id="component-helper-text" className="helperText">
              {170 - props.description.length} {t('COMMON.CHARACTERS_LEFT') as string}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{ marginBottom: { xs: 2, lg: 0 } }}
          marginTop={2}
        >
          <InputLabel id="isActive" sx={{ mb: 1 }}>
            {t('COMMON.STATUS') as string}
          </InputLabel>
          <ToggleButtonGroup
            id="isActive"
            color="primary"
            value={props.isActive}
            exclusive
            onChange={props.onIsActiveChange}
          >
            <ToggleButton
              sx={{
                color: "primary.main",
                bgcolor: "#ffffff",
                border: "1px solid",
                lineHeight: "0.7",
                width: "5rem",
                "&.Mui-selected": {
                  bgcolor: "primary.main",
                  color: "#ffffff",
                  borderColor: "primary.main",
                },
                "&.Mui-selected:hover": {
                  bgcolor: "primary.main",
                  color: "#ffffff",
                },
              }}
              value={true}
            >
               {t('COMMON.ACTIVE') as string}
            </ToggleButton>
            <ToggleButton
              sx={{
                color: "primary.main",
                bgcolor: "#ffffff",
                border: "1px solid",
                lineHeight: "0.7",
                width: "5rem",
                "&.Mui-selected": {
                  bgcolor: "primary.main",
                  color: "#ffffff",
                  borderColor: "primary.main",
                },
                "&.Mui-selected:hover": {
                  bgcolor: "primary.main",
                  color: "#ffffff",
                },
              }}
              value={false}
            >
              {t('COMMON.INACTIVE') as string}
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </Box>
  )
}

export default DetailForm
