import { Search } from '@mui/icons-material';
import { Avatar, Box, Button, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, Menu, MenuItem, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, ToggleButton, ToggleButtonGroup, Typography, useTheme } from '@mui/material';
import BorderAllRoundedIcon from '@mui/icons-material/BorderAllRounded';
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import AddIcon from '@mui/icons-material/Add';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import React, { useEffect, useState } from 'react';
import { Resource } from '../../entities/User/MockResource';
import { useParams, useNavigate } from 'react-router-dom';
import FuzzySearch from 'fuzzy-search';
import './ResourceList.scss';
import ResourceCard from './ResourceCard';
import { StyledAvatarRecordCount } from '../../components/Styled/StyledAvatarRecordCount';
import { useTranslation } from 'react-i18next';

interface ResourceListProps {}

const ResourceList: React.FC<ResourceListProps> = (props) => {
  let navigate = useNavigate();
  const [layout, setLayout] = useState<string | null>('card');
  const { t } = useTranslation();
  const handleLayout = (event: React.MouseEvent<HTMLElement>, newLayout: string | null) => {
    if (newLayout !== null) {
      setLayout(newLayout);
    }
  };
  const [sort, setSort] = useState<string>('createdDate');
  const [filter, setFilter] = useState<string>('');
  const [resources, setResources] = useState<Resource[]>();
  const [filteredResources, setFilteredResources] = useState<Resource[]>();
  const sortOptions = [
    {
      key: 'createdDate',
      name: 'Added On',
    },
    {
      key: 'firstName',
      name: 'First Name',
    },
    {
      key: 'lastName',
      name: 'Last Name',
    },
  ];
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { companyId } = useParams();
  const theme = useTheme();

  useEffect(() => {
    if (companyId) {
      // ResourceService.getResourcesByCompanyId(props.companyId).then((e) => {
      //   console.log(e.data)
      //   setResources(e.data as Resource[])
      //   setFilteredResources(e.data as Resource[])
      // })
    }

    const resources = [
      {
        alias: 'ORG',
        identifier: 'ORG',
        id: 'sd232wdqc32rq',
        isActive: true,
        name: 'Organization',
        description: 'Organization',
        createdDate: '2022-07-15T05:39:09.9779298Z',
      },
      {
        alias: 'USER',
        identifier: 'USER',
        id: 'fq234q234rfq34',
        isActive: false,
        name: 'User',
        description: 'User',
        createdDate: '2022-07-15T05:39:09.9779298Z',
      },
      {
        alias: 'WI',
        identifier: 'WI',
        id: 'gw49tug2034g3',
        isActive: false,
        name: 'Work Instruction',
        description: 'Work Instruction',
        createdDate: '2022-07-15T05:39:09.9779298Z',
      },
    ];
    setResources(resources as Resource[]);
    setFilteredResources(resources as Resource[]);
  }, [companyId]);

  const sortBy = (key) => {
    if (resources) {
      resources.sort((a, b) => a[key].localeCompare(b[key]));
      setResources(resources as Resource[]);
    }
  };

  const search = (key: string) => {
    if (resources) {
      const searcher = new FuzzySearch(resources, ['firstName', 'lastName'], {
        caseSensitive: false,
      });
      const result = searcher.search(key);
      result.length > 0 ? setFilteredResources(result) : setFilteredResources(resources);
    }
  };

  const handleAdd = () => {
    navigate('/resource/add');
  };
  const handleEdit = (id) => {
    navigate('/resource/edit/' + id);
  };
  const handleDelete = (id) => {
    // handle delete
  };

  return (
    <div className="resource-list-container">
      <Paper elevation={0} className="resource-title-container" style={{ marginTop: '1rem' }}>
        <Box component="div" className="left">
          <Typography variant="h6" component="div" className="page-title">
            Resources
          </Typography>
          {resources && <StyledAvatarRecordCount>{resources.length}</StyledAvatarRecordCount>}
        </Box>
        <Box component="div" className="right">
          <Button className="activeButton nonActiveButton" variant="contained" onClick={handleAdd}>
            <Box component={'div'} sx={{ display: { xs: 'none', md: 'flex' } }}>
              Create New
            </Box>
            <Box component={'div'} sx={{ display: { xs: 'flex', md: 'none' } }}>
              <AddIcon />
            </Box>
          </Button>
        </Box>
      </Paper>

      <Paper elevation={0} className="resource-title-container">
        <Box component="div" className="left">
          <ToggleButtonGroup size="small" color="primary" value={layout} exclusive onChange={handleLayout} aria-label="layout">
            <ToggleButton
              sx={{
                color: 'primary.main',
                bgcolor: '#ffffff',
                border: '1px solid',
                lineHeight: '0.7',
                width: '2.5rem',
                '&.Mui-selected': {
                  bgcolor: 'primary.main',
                  color: '#ffffff',
                  borderColor: 'primary.main',
                },
                '&.Mui-selected:hover': {
                  bgcolor: 'primary.main',
                  color: '#ffffff',
                },
              }}
              value="card"
              aria-label="card"
            >
              <BorderAllRoundedIcon />
            </ToggleButton>
            <ToggleButton
              sx={{
                color: 'primary.main',
                bgcolor: '#ffffff',
                border: '1px solid',
                lineHeight: '0.7',
                width: '2.5rem',
                '&.Mui-selected': {
                  bgcolor: 'primary.main',
                  color: '#ffffff',
                  borderColor: 'primary.main',
                },
                '&.Mui-selected:hover': {
                  bgcolor: 'primary.main',
                  color: '#ffffff',
                },
              }}
              value="list"
              aria-label="list"
            >
              <FormatListBulletedRoundedIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Box component="div" className="right">
          <Box component="div" sx={{ display: 'flex', alignItems: 'flex-end' }} className="search-btn">
            <FormControl fullWidth>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search sx={{ color: 'primary.main', mr: 1, my: 0.5 }} />
                    </InputAdornment>
                  ),
                }}
                placeholder={t('COMMON.SEARCH')}
                style={{ borderRadius: '8px !important' }}
                onChange={(e) => search(e.target.value)}
              />
            </FormControl>
          </Box>
          <FormControl
            sx={{
              m: 1,
              minWidth: 120,
              display: { xs: 'none', md: 'flex' },
            }}
          >
            <Select value={sort} onChange={(e) => sortBy(e.target.value)} size="small" displayEmpty inputProps={{ 'aria-label': 'Without label' }}>
              <MenuItem value={'createdDate'}>
                {' '}
                <em>Sort: Added On</em>{' '}
              </MenuItem>
              <MenuItem value={'firstName'}>First Name</MenuItem>
              <MenuItem value={'lastName'}>Last Name</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            sx={{
              m: 1,
              mr: 0,
              minWidth: 120,
              display: { xs: 'none', md: 'flex' },
            }}
          >
            <Select value={filter} onChange={(e) => setFilter(e.target.value)} size="small" displayEmpty inputProps={{ 'aria-label': 'Without label' }}>
              <MenuItem value="">
                {' '}
                <em>Filter</em>{' '}
              </MenuItem>
              <MenuItem value={'firstName'}>First Name</MenuItem>
              <MenuItem value={'lastName'}>Last Name</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Paper>
      {layout === 'card' && (
        <Grid className="card-container" container spacing={{ xs: 2, md: 4 }}>
          {filteredResources &&
            filteredResources.map((resource, index) => {
              return (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                  <ResourceCard resource={resource} onEdit={handleEdit} onDelete={handleDelete} />
                </Grid>
              );
            })}
        </Grid>
      )}
      {layout === 'list' && (
        <div className="wrapper">
          <TableContainer component={Paper} sx={{ borderRadius: '0', boxShadow: 'none' }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">NAME</TableCell>
                  <TableCell align="left">ALIAS</TableCell>
                  <TableCell align="left">IDENTIFIER</TableCell>
                  <TableCell align="left">DESCRIPTION</TableCell>
                  <TableCell align="left">STATUS</TableCell>
                  <TableCell align="center">ACTIONS</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredResources &&
                  filteredResources.map((resource, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        '&:nth-of-type(odd)': {
                          backgroundColor: theme.palette.action.hover,
                        },
                      }}
                    >
                      <TableCell align="left">{resource.name}</TableCell>
                      <TableCell align="left">{resource.alias}</TableCell>
                      <TableCell align="left">{resource.identifier}</TableCell>
                      <TableCell align="left">{resource.description}</TableCell>
                      <TableCell align="left">
                        <FormControlLabel sx={{ pointerEvents: 'none', cursor: 'default' }} className="active" control={<Switch defaultChecked={resource.isActive} />} label={resource.isActive ? 'Active' : 'Inactive'} />
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          size="small"
                          onClick={handleClick}
                          aria-controls={open ? 'account-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                          sx={{
                            borderRadius: '5',
                            border: '1px solid gray',
                            p: 0,
                            color: 'black',
                          }}
                        >
                          <MoreHorizIcon />
                        </IconButton>
                        <Menu
                          anchorEl={anchorEl}
                          id="account-menu"
                          open={open}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                        >
                          <MenuItem onClick={() => handleEdit(resource.id)}>Edit</MenuItem>
                          <MenuItem onClick={() => handleDelete(resource.id)}>Delete</MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
};

export default ResourceList;
