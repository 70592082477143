import { Refresh, Search } from '@mui/icons-material';
import { Avatar, Box, FormControl, Grid, IconButton, InputAdornment, Paper, TextField, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import WorkInstructionCards from '../../../components/Cards/WorkInstructionCard/WorkInstructionCards';
import { IWorkInstructions } from '../../../entities/Trainee/MockTrainee';
import TutorialService from '../../../services/trainee/TraineeService';
import '../TraineeHome/TraineeHome.scss';
import { useParams } from 'react-router-dom';
import Loading from '../../../components/Loading/Loading';
import FileManagerService from '../../../services/FileManager/FileManagerService';
import { useTranslation } from 'react-i18next';
import { useDocumentTitle } from '../../../App';
import TourCard from '../../../components/Cards/TourCard/TourCard';
import useTour from '../../../hooks/tour';
import TrainerService from '../../../services/trainer/TrainerService';

interface Props {
  isTrainer?: boolean;
}

const RecertificationDueRequest: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [sortVal, setSortVal] = useState<string>('');
  const [filterVal, setFilterVal] = useState<string>('');
  const [searchVal, setSearchVal] = useState<string>('');
  const [workInstructions, setWorkInstructions] = useState<IWorkInstructions[]>();
  const [totalWorkInstructionsLength, setTotalWorkInstructionsLength] = useState(0);
  const [isRecertificationDue, setIsRecertificationDue] = useState<boolean>(true);
  const [certificateDueCount, setCertificateDueCount] = useState<any>(0);
  const { companyId } = useParams();
  useDocumentTitle('CDS Mentor Home');
  const { createWiTour, setCreateWiTour } = useTour();

  useEffect(() => {
    if (companyId) {
      loadData(companyId);
    }
  }, [companyId]);
  console.log(workInstructions);

  const previousController = useRef<any>(null);
  const [imageObject, setImageObject] = useState<Object>({});

  const loadData = (companyId: string, search = searchVal, sort = sortVal, filter = filterVal, page = 1, pageLimit = 15) => {
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;

    TutorialService.getWorkInstructionsWithRecertificationDue(companyId, page, pageLimit, search, sort, filter, signal)
      .then((e) => {
        setTotalWorkInstructionsLength(e.data.data.total);
        e.data.data.workInstructions.map((wi) => {
          FileManagerService.getWorkInstructionThumbnail(localStorage.getItem('organizationId') || '', wi.workInstructionId)
            .then((res) => {
              imageObject[wi.workInstructionId] = (res as any).data[0].id;
              setImageObject({ ...imageObject });
            })
            .catch((e) => {
              imageObject[wi.workInstructionId] = '';
            });
        });
        if (workInstructions && page > 1) {
          setWorkInstructions([...workInstructions, ...(e.data.data.workInstructions as IWorkInstructions[])]);
        } else {
          setWorkInstructions(e.data.data.workInstructions as IWorkInstructions[]);
        }
      })
      .catch(({ response }) => {
        setLoading(false);
      });
  };

  const handleReloadData = () => {
    if (companyId) {
      loadData(companyId);
    }
  };

  const search = (key: string) => {
    setSearchVal(key);

    if (companyId) loadData(companyId, key);
  };

  useEffect(() => {
    if (!props.isTrainer) {
      TrainerService.certificateDueCount().then((response) => {
        setCertificateDueCount(response.data.data);
      });
    }
  }, [props.isTrainer]);

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <div>
          <Paper elevation={0} className="instructions-title-container" sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Box component="div" className="left">
              <Typography variant="h6" component="div" className="page-title">
                {' '}
                {t('WI_LIST.ALL_RECERTIFICATION_DUE') as string}{' '}
              </Typography>
              {
                <Avatar sx={{ bgcolor: 'grey.300', color: '#000', height: '32px', padding: '0 5px' }} variant="rounded">
                  {' '}
                  {certificateDueCount}{' '}
                </Avatar>
              }
            </Box>
            <Box component="div" className="right">
              <Box component="div" sx={{ display: 'flex', alignItems: 'flex-end' }} className="search-btn">
                <FormControl fullWidth>
                  <TextField
                    onChange={(e) => search(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {' '}
                          <Search sx={{ color: 'primary.main', mr: 1, my: 0.5 }} />{' '}
                        </InputAdornment>
                      ),
                    }}
                    placeholder={t('COMMON.SEARCH')}
                    style={{ borderRadius: '8px !important' }}
                  />
                </FormControl>
              </Box>
            </Box>
          </Paper>
          <Grid className="card-container" container spacing={{ xs: 3, md: 4 }} direction={'row'} alignItems={'stretch'} sx={{ marginBottom: { xs: '80px', md: '0px' } }}>
            {createWiTour && (
              <Grid item xs={12} md={4} lg={4}>
                <TourCard />
              </Grid>
            )}
            {workInstructions &&
              workInstructions.map((ws, index) => {
                return (
                  <Grid item xs={12} md={4} lg={4} key={index}>
                    <WorkInstructionCards companyId={companyId} workInstruction={ws} isTrainer={props.isTrainer} isRecertificationDue={isRecertificationDue} />
                  </Grid>
                );
              })}
          </Grid>
        </div>
      )}
    </>
  );
};

export default RecertificationDueRequest;
