export enum WorkInstructionModelStatus {
  PREPARING_MODEL = 'PREPARING 3D MODEL',
  MODEL_CONVERSION_FAILED = '3D MODEL CONVERSION FAILED',
  MODEL_READY = '3D MODEL READY',
  FILE_UPLOAD_FAILED = 'CAD FILE UPLOAD FAILED',
}

export enum ProcedureDuplicationStatus {
  PROCESSING = 'PROCESSING',
  READY = 'READY',
  FAILED = 'FAILED'
}

export const translate = {
  'CAD FILE UPLOAD FAILED': 'WORKINSTRUCTION_CAD_FILE_UPLOAD_FAILED',
  '3D MODEL READY': 'WORKINSTRUCTION_3D_MODEL_READY',
  'PREPARING 3D MODEL': 'WORKINSTRUCTION_PREPARING_3D_MODEL',
  '3D MODEL CONVERSION FAILED': 'WORKINSTRUCTION_3D_MODEL_CONVERSION_FAILED',
}

export const TRANSLATE_TRAINEE_STATUS = {
  ALL: 'ANALYTICS_SECTION.ALL',
  INPROGRESS: 'COMMON.WORKINSTRUCTION_TRAINEE_STATUS_IN_PROGRESS',
  COMPLETED: 'COMMON.WORKINSTRUCTION_TRAINEE_STATUS_COMPLETED',
  NOTSTARTED: 'COMMON.WORKINSTRUCTION_TRAINEE_STATUS_NOT_STARTED',
}

export const TRANSLATE_TRAINEE_FILTERS = {
  "IN-PROGRESS": 'COMMON.WORKINSTRUCTION_TRAINEE_STATUS_IN_PROGRESS',
  "NOT STARTED": 'COMMON.WORKINSTRUCTION_TRAINEE_STATUS_NOT_STARTED',
  "COMPLETED": 'COMMON.WORKINSTRUCTION_TRAINEE_STATUS_COMPLETED',
  "EXPIRED": 'COMMON.WORKINSTRUCTION_TRAINEE_STATUS_EXPIRED'
}

export enum WorkInstructionModelFileUploadStatus {
  FILE_NOT_UPLOADED = 'File not uploaded',
  FILE_UPLOAD_IN_PROCESS = 'File Upload in process',
  FILE_UPLOAD_SUCCESS = 'File Upload Success',
  FILE_UPLOAD_FAILED = 'File Upload Failed',
  FILE_ALREADY_EXISTS = 'This file already exists. We suggest you upload a new CAD file.',
  CONVERSION_PENDING = 'Conversion Pending',
  CONVERSION_PROCESSING = 'Conversion Processing',
  CONVERSION_GLTF_CONVERSION = 'Conversion GLTF Conversion',
  CONVERSION_SUCCESS = 'Conversion Success',
  CONVERSION_FAILED = 'Conversion Failed',
}

export enum TraineeProcedureProgressStatus {
  IN_PROGRESS = 'In-Progress',
  NOT_STARTED = 'Not started',
  COMPLETED = 'Completed'
}

export enum LogSeverity {
  INFO = 'INFO',
  WARN = 'WARN',
  ERROR = 'ERROR'
}

export enum AmplitudeEvents {
  SIGNED_UP = 'Signed Up',
  SIGN_UP_FAILED = 'Sign Up Failed',
  LOGGED_IN = 'Logged In',
  LOGGED_OUT = 'Logged Out',
  CREATE_NEW_USER = 'Create New User',
  CREATE_NEW_USER_GROUP = 'Create New User Group',
  USER_DELETED = 'User Deleted',
  USER_GROUP_DELETED = 'User Group Deleted',
  CREATE_WORK_INSTRUCTION = 'Create Work Instruction',
  CREATE_WORK_INSTRUCTION_STEP_1 = 'Create Work Instruction Step 1',
  CREATE_WORK_INSTRUCTION_STEP_2 = 'Create Work Instruction Step 2',
  CREATE_WORK_INSTRUCTION_STEP_3 = 'Create Work Instruction Step 3',
  CAD_ASSET_CONVERSION_SUCCESS = 'CAD Asset Conversion Success',
  CAD_ASSET_CONVERSION_FAILED = 'CAD Asset Conversion Failed',
  CAD_ASSET_UPLOAD_FAILED = 'CAD Asset Upload Failed',
  CAD_ASSET_UPLOAD_SUCCESS = 'CAD Asset Upload Success',
  CREATE_PROCEDURE = 'Create Procedure',
  PUBLISH_PROCEDURE = 'Publish Procedure',
  ASSIGN_PROCEDURE = 'Assign Procedure',
  PREVIEW_PROCEDURE = 'Preview Procedure',
  APPROVE_PROCEDURE = 'Approve Procedure',
  REJECT_PROCEDURE = 'Reject Procedure',
  RETRY_CAD_FILE_UPLOAD = 'Retry CAD File Upload'
}

export enum PROCEDURE_VISIBILITY_SCOPE {
  NORMAL = "NORMAL",
  GLOBAL = "GLOBAL",
  REFERENCE = "REFERENCE"
}

export enum SUPPORTED_VIEWS {
  WORK_INSTRUCTION = "WORK_INSTRUCTION",
  PROCEDURE_LIBRARY = "PROCEDURE_LIBRARY"
};

export enum USER_TYPES {
  ADMIN = 'admin',
  TRAINER = 'trainer',
  TRAINEE = 'trainee',
}

export enum PROCEDURE_TYPE {
  PREREQUISITE = 'PREREQUISITE',
  CORE_PROCEDURE = 'CORE_PROCEDURE'
}

export enum ANALYTICS_SUPPORTED_VIEWS {
  PERSONAL = 'PERSONAL',
  ORGANISATIONAL = 'ORGANISATIONAL',
}

export enum SELECT_DATARANGE_OPTIONS {
  ALL = "ALL",
  CURRENT_MONTH = "CURRENT_MONTH",
  CURRENT_WEEK = "CURRENT_WEEK",
  LAST_WEEK = "LAST_WEEK",
  LAST_MONTH = 'LAST_MONTH',
  CUSTOM = 'CUSTOM',
}

export enum ANALYTICS_TYPES {
  TRAINER_ANALYTICS = 'TRAINER_ANALYTICS',
  TRAINEE_ANALYTICS = 'TRAINEE_ANALYTICS',
  TRAINING_ANALYTICS = 'TRAINING_ANALYTICS'
}

export enum ENTITY {
  WORK_INSTRUCTION = 'WORK_INSTRUCTION',
  PROCEDURE = 'PROCEDURE'
}

export enum APP_SIDEBARS  {
  USER_LANGUAGE = 'USER_LANGUAGE', 
  EDIT_SUBSTITUTION = "EDIT_SUBSTITUTION"
}

export enum SUPPORTED_LANGUAGES {
    ENGLISH = 'en',
    GERMAN = 'de',
    FRENCH = 'fr',
    SPANISH = 'es',
    CHINESE = 'zh-cn',
    PORTUGUESE = 'pt-br',
    POLISH = 'pl'
}

export enum PROCEDURE_LOCATION {
  WORK_INSTRUCTION = 'workinstruction',
  ALL = "all"
}

export enum PROCEDURE_STATUS {
  DRAFT = 'draft',
  APPROVAL_PENDING = 'approvalPending',
  PUBLISHED = 'published',
  ASSIGNED = 'assigned',
  PERIODIC_REVIEW = 'periodicReview',
  REJECTED = 'rejected'
}