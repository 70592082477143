import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Typography } from '@mui/material';
import Link, { LinkProps } from '@mui/material/Link';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { Toast } from '../../components/Notifications/Notification';
import { IPersona } from '../../entities/Organization/Persona/Persona';
import PersonaService from '../../services/Persona/PersonaService';
import { getMessage } from '../../shared/utils/message-helpers';
import PersonaForm from './PersonaForm';

interface EditPersonaProps {}

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

const LinkRouter = (props: LinkRouterProps) => <Link {...props} component={RouterLink as any} />;

const EditPersona: React.FC<EditPersonaProps> = (props) => {
  const { t } = useTranslation();

  const breadcrumbs = [
    <Link underline="hover" key="1" sx={{ opacity: '0.7' }}>
      {t('NAVBAR.ADMINISTRATION') as string}
    </Link>,
    <LinkRouter underline="hover" key="2" to="/persona" sx={{ opacity: '0.7' }}>
      Persona
    </LinkRouter>,
    <Typography key="3" color="primary.main">
      Edit Persona
    </Typography>,
  ];
  const { personaId } = useParams();
  const [persona, setPersona] = useState<Partial<IPersona>>();
  const [loading, setLoading] = useState(true);

  let navigate = useNavigate();

  const editPersonaHandler = (personaData: IPersona) => {
    setLoading(true);
    PersonaService.updatePersona(personaData)
      .then((e) => {
        setLoading(false);
        Toast.success(t('OPERATION_SUCCESS', { ns: 'success' }), t(e.data.messageCode, { ns: 'success' }) || e.data.message);
        backHandler();
      })
      .catch(({ response }) => {
        setLoading(false);
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        console.error('EditPersona -> editPersonaHandler', response);
      });
  };

  const backHandler = () => {
    navigate('/persona');
  };

  useEffect(() => {
    if (personaId) {
      setLoading(true);
      PersonaService.getPersona(personaId)
        .then((e) => {
          const {
            data: { data: personaData },
          } = e;
          setPersona(personaData as Partial<IPersona>);
          setLoading(false);
        })
        .catch(({ response }) => {
          setLoading(false);
          Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
          console.error('EditPersona -> init -> getPersona', response);
        });
    }
  }, [personaId]);

  return (
    <>
      <Breadcrumbs sx={{ margin: '2rem' }} separator={<NavigateNextIcon color="primary" fontSize="small" />} aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>

      {persona && <PersonaForm onBack={backHandler} onSubmit={editPersonaHandler} persona={persona} isLoading={loading} />}
    </>
  );
};

export default EditPersona;
