import { Box, Button, Chip, Grid } from '@mui/material';

import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import NewProcedure from '../../../components/Trainer/NewProcedure/NewProcedure';
import AddPPE from '../../../components/Trainer/PPEKit/AddPPE';
import { IProcedures } from '../../../entities/Trainee/MockTrainee';
import { IProcedureCreate } from '../../../entities/Trainee/WorkInstructions';
import './CreateNewProcedure.scss';
import { useTranslation } from 'react-i18next';
import { PROCEDURE_VISIBILITY_SCOPE } from '../../../shared/enums';
import { setVisibilityScope } from '../../../redux/features/Procedures/Procedures.slice';
import { useAppDispatch } from '../../../hooks/redux';
interface Props {}

const CreateNewProcedure: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const steps = [t('ADD_WI.PROCEDURE'), t('ADD_WI.PPE_EQUIPMENTS')];
  const [procValues, setProcValues] = useState<IProcedureCreate | null>(null);
  const [procFileValue, setProcFileValue] = useState<any>([]);
  const [ppeValues, setPpeValues] = useState<string[]>([]);
  const [ppeEquipment, setPpeEquipment] = useState<any>([]);
  const [activeStep, setActiveStep] = useState(0);
  const location = useLocation();
  const GO_BACK = 'GO_BACK';
  const GO_NEXT = 'GO_NEXT';
  type LocationState = { procedureDetails?: IProcedures, visibilityScope?: PROCEDURE_VISIBILITY_SCOPE } | null;

  let state: LocationState = null;
  

  if (location.state !== null) {
    state = location.state as LocationState;
  }

  useEffect(() => {
    if (state?.procedureDetails !== null) {
      setPpeEquipment(state?.procedureDetails?.ppeEquipments);
    } else {
      setPpeEquipment([]);
    }
  }, []);

  useEffect(() => {
    if(state?.visibilityScope){
      dispatch(setVisibilityScope(state.visibilityScope));
    } else {
      dispatch(setVisibilityScope(PROCEDURE_VISIBILITY_SCOPE.NORMAL));
    }
  }, [state?.visibilityScope])

  const goBack = () => {
    if (activeStep > 0) {
      setActiveStep((state) => state - 1);
    }
  };

  const setProcedureDataAndNavigate = (direction: string, formData: IProcedureCreate, fileData: any) => {
    setProcValues(formData);
    setProcFileValue(fileData);

    if (direction === GO_NEXT) {
      if (!formData) {
        setActiveStep(0);
      } else {
        setActiveStep(1);
      }
    } else if (direction === GO_BACK) {
      goBack();
    }
  };

  const handleGoBackClickedInProcedure = (formData: IProcedureCreate, fileData: any) => {
    setProcedureDataAndNavigate(GO_BACK, formData, fileData);
  };

  const handleGoNextClickedInProcedure = (formData: IProcedureCreate, fileData: any) => {
    setProcedureDataAndNavigate(GO_NEXT, formData, fileData);
  };

  const setPPEDataAndNavigate = (direction: string, formData: string[]) => {
    setPpeValues(formData);

    if (direction === GO_NEXT) {
      // No Next Button
    } else if (direction === GO_BACK) {
      goBack();
    }
  };

  const handleGoBackClickedInPPE = (formData: string[]) => {
    setPPEDataAndNavigate(GO_BACK, formData);
  };

  const handleGoNextClickedInPPE = (formData: string[]) => {
    setPPEDataAndNavigate(GO_NEXT, formData);
  };

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center">
      <Grid className="card-containers" style={{ width: '360px', marginRight: '15rem' }}>
        <Stepper orientation="horizontal" activeStep={activeStep}>
          {steps.map((label, i) => (
            <Step key={label}>
              <StepLabel>
                <Chip label={label}></Chip>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
      <Grid container alignItems="center" justifyContent="center">
        {activeStep === 0 && (
          <Box component={'div'} sx={{ display: 'flex', flexDirection: 'column' }}>
            <NewProcedure formData={procValues} fileData={procFileValue} setFileData={setProcFileValue} procedureDetails={state?.procedureDetails ?? null} workInstructionData={null} showBackButton={false} onGoBack={(formData, fileData) => handleGoBackClickedInProcedure(formData, fileData)} onGoNext={(formData, fileData) => handleGoNextClickedInProcedure(formData, fileData)} />
            <Box component="div">{/*<Button variant="contained" style={{ float: "right" }} onClick={() => setFormValues(null)}> Back </Button>*/}</Box>
          </Box>
        )}
        {activeStep === 1 && (
          <Box component="div" sx={{ display: 'flex', flexDirection: 'column' }}>
            <AddPPE procedureDetails={state?.procedureDetails ?? null} ppeEquipment={ppeValues} workInstructionData={null} procedureData={procValues} procedureRefDoc={procFileValue} showBackButton={true} onGoBack={(formData) => handleGoBackClickedInPPE(formData)} onGoNext={(formData) => handleGoNextClickedInPPE(formData)} />
            <Box component="div">{/*<Button variant="contained" style={{ float: "right" }} onClick={() => {setFormValues(null); setProcValues(null);}}> Back </Button>*/}</Box>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default CreateNewProcedure;
