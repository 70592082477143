import { Box, Typography } from '@mui/material';

interface FeedbackProps {
  title: string;
  value?: Number | string;
  total?: Number;
  entries?: Number;
}

export const Feedback = ({ title, value, total, entries }: FeedbackProps) => {
  return (
    <Box component="div" sx={{ textAlign: 'left', height: 'fit-content' }}>
      <Typography sx={{ mb: '8px', fontSize: '14px' }}>{title}</Typography>
      {typeof value !== 'undefined' ? <Typography sx={{ fontSize: '28px' }}>{`${value}${total ? '/' + total : ''} ${entries ? '(' + entries + ')' : ''} `}</Typography> : 'Not Given'}
    </Box>
  );
};
