import React, { useEffect, useState } from 'react';
import { Box, IconButton, Tooltip, useTheme } from '@mui/material';

export const StyledModalIcon = ({
  tooltip,
  canFlip = false,
  selected = false,
  onClick = (event: any, state: boolean) => {
    return;
  },
  ...props
}) => {
  const theme = useTheme();
  const [isPressed, setIsPressed] = useState(false);

  useEffect(() => {
    setIsPressed(selected);

    return () => {
      setIsPressed(false);
    };
  }, [selected]);

  const handleClick = (event: any) => {
    if (canFlip) {
      setIsPressed((state) => !state);
    }
    if (onClick !== null) {
      onClick(event, isPressed);
    }
  };

  return (
    <Tooltip
      title={tooltip}
      placement="top"
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: 'common.black',
            '& .MuiTooltip-arrow': {
              color: 'common.black',
            },
            p: 1.5,
            fontSize: '13px',
            maxWidth: '420px',
          },
        },
      }}
    >
      <Box component="span">
        <IconButton
          size="large"
          edge="start"
          color="primary"
          sx={{
            textAlign: 'center',
            width: '-webkit-fill-available',
            marginLeft: '5px',
            backgroundColor: isPressed
              ? `${theme.palette.primary.main}`
              : `transparent`,
            borderRadius: '5px',
            padding: '8px',
            '.MuiSvgIcon-root': {
              color: isPressed
                ? `${theme.palette.common.white}`
                : `${theme.palette.primary.main}`,
              fill: isPressed
                ? `${theme.palette.common.white}`
                : `${theme.palette.primary.main}`,
              stroke: isPressed
                ? `${theme.palette.common.white}`
                : `${theme.palette.primary.main}`,
            },
          }}
          onClick={handleClick}
          disableRipple
          {...props}
        >
          {props.children}
        </IconButton>
        <Box component="div" sx={{ fontSize: '0.75rem', lineHeight: '1.66', textAlign: 'center', paddingLeft: '0.3rem', width: '-webkit-fill-available'}}>
            {props.text}
        </Box>
      </Box>
    </Tooltip>
  );
};
