import { IconButton, Menu, MenuItem } from '@mui/material';
import React from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Access } from '../Auth/Access';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';

interface ActionMenuProps {
  onEdit: () => void;
  onDelete: () => void;
  editPermission: string;
  deletePermission: string;
}

export const ActionMenu = ({
  onEdit,
  onDelete,
  editPermission,
  deletePermission,
}: ActionMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { t } = useTranslation();

  return (
    <>
      <IconButton
        size="small"
        onClick={handleClick}
        aria-controls={open ? 'table-view-action-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        sx={{
          borderRadius: '5',
          border: '1px solid gray',
          p: 0,
          color: 'black',
        }}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="table-view-action-menu"
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Access permission={editPermission}>
          <MenuItem
            onClick={() => {
              handleClose();
              onEdit();
            }}
          >
            <EditIcon sx={{mr:"10px"}}/>
            {t('COMMON.EDIT') as string}
          </MenuItem>
        </Access>
        <Access permission={deletePermission}>
          <MenuItem
            onClick={() => {
              handleClose();
              onDelete();
            }}
          >
            <DeleteIcon sx={{mr:"10px"}}/>
            {t('COMMON.DELETE') as string}
          </MenuItem>
        </Access>
      </Menu>
    </>
  );
};
