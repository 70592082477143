import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const SubstitutionsHeader = ({ setIsModalOpen }) => {
  const { t } = useTranslation();
  const handleSearchTextChange = () => {};
  const handleSortChange = () => {};
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography variant="h5">{t('SUBSTITUTION.HEADING')}</Typography>
      <Box sx={{ display: 'flex', gap: '10px', marginLeft: 'auto' }}>
        {/* <SearchBar onChangeHandler={handleSearchTextChange} />
          <SelectSort
            sortOptions={[]}
            onChange={handleSortChange}
            value={''}
          />
          <FilterButton
            selectedFilters={''}
            onApplyFilterClick={function (any: any): void {
              throw new Error('Function not implemented.');
            }}
            onFilterClose={function (): void {
              throw new Error('Function not implemented.');
            }}
            filterOptions={[]}
          /> */}
        <Button
          variant={'contained'}
          onClick={() => setIsModalOpen(true)}
        >
          {t('SUBSTITUTION.ADD_NEW')}
        </Button>
      </Box>
    </Box>
  );
};

export default SubstitutionsHeader;