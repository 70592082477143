import { useEffect, useState } from 'react';
import { Box, CircularProgress} from '@mui/material';
import MediaRef from '../../../Trainer/Dialog/MediaUploadDialog/MediaRef';
import { environment } from '../../../../environment/environment';
import FileManagerService from '../../../../services/FileManager/FileManagerService';
import { Buffer } from 'buffer';
import FileHelper from '../../../../services/FileManager/FileHelper';
import { convertFileBlobToMediaType } from '../../../../services/Mapper/Mapper';

export const CommentFilePreview = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const { file } = props;
    const [fileObject, setFileObject] = useState<any>(null);

  
    useEffect(() => {
      if (file && file.fileId) {
        let aReference: any = {};
  
        if (file.isBlobUpload) {
          const fileType = file.mimeType;
          if (fileType == 'application/pdf') {
            FileHelper.getObjectUrlByFileId(file.fileId, fileType).then((objectUrl) => {
              aReference.dataUrl = objectUrl;
            });
          } else {
            aReference.dataUrl = `${environment.AppConfigs.fileManagerApi}/v1/files/${file.fileId}?cache=true`;
          }
  
          aReference.title = file.fileName;
          aReference.fileType = file.mimeType;
        } else {
          FileManagerService.getFileById(file.fileId).then((response) => {
            const base64Url = Buffer.from(response.data).toString();
            aReference.dataUrl = base64Url.replaceAll('"', '');
            aReference.title = file.fileName;
            aReference.fileType = file.mimeType;
          });
        }
        setFileObject(aReference);
        setIsLoading(false);
      }
      else{
        let fileToShow = convertFileBlobToMediaType(file);
        setFileObject(fileToShow)
        setIsLoading(false);
      }
     
    }, []);
    
  
    return isLoading ? (
      <Box component={'div'} sx={{ display: 'flex', flexDirection: 'column', flex: '0 0 100px', borderRadius: '10px', border: '4px solid #bfbfbf', height: '100px', width: '100px', margin: '1.5%' }}>
        <Box component={'div'} sx={{ width: '100%', height: 'calc( 100% - 16px )', display: 'flex', position: 'relative' }}>
          <Box component={'div'} sx={{ width: '100%', height: '100%', backgroundColor: '#a1a1a17d', display: 'flex', position: 'absolute', left: '0px', top: '0px', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        </Box>
      </Box>
    ) : (
      <MediaRef key={file?.fileId} aReference={fileObject} index={file?.fileId} commentMediaRef={true} selectedFiles={props.selectedFiles} setSelectedFiles={props.setSelectedFiles} file={props.file}/>
    );
  };