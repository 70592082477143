import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import "./AssignmentConfirmationDialog.scss";
import { Box, Card, CardContent, CardMedia, Grid, Paper } from "@mui/material";
import fallBackIcon from "../../../../assets/fallback_img.svg";
import { ProductAffected } from "../../../Icons/CdsIcons";
import "./AssignmentConfirmationDialog.scss";
import { getFormattedDateFromUnixTime } from "../../../../shared/utils/formateDate";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

interface Props {
  openAssignmentDialog: boolean;
  userAssignedCount: number;
  workInstruction: any;
  procedure: any;
}

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

export default function AssignmentConfirmationDialog(props) {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(true);
  }, []);

  const handleClose = () => {
    setOpen(false);
    refreshPage();
  };

  const refreshPage = () => {
    window.location.reload();
  }

  useEffect(() => {
    setTimeout(() => {
      handleClose();
    }, 5000);
  });

  const convertDate = (date) => {
    date = new Date(date);
    return date.toLocaleDateString("en-IN", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  const { t } = useTranslation();

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="md"
        PaperProps={{
          sx: {
            overflow: "hidden",
          }
        }}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Box component={"div"}>
            <Grid container
              width={"100% !important"}
              direction="row"
              justifyContent="space-between"
              alignItems="stretch"
              spacing={4}>
              <Grid item xs={6}>
                <Card
                  sx={{
                    display: { xs: "none", md: "flex" },
                    border: "1px solid rgba(0, 0, 0, 0.2)",
                  }}
                  elevation={0}
                >
                  {props.workInstruction.workInstructionImageURL && (
                    <CardMedia
                      component="img"
                      sx={{ width: "5em", height: "5em", mt: "1em", mr: '1em', objectFit: 'fill' }}
                      image={props.workInstruction.workInstructionImageURL}
                      className="work-instruction-img"
                      alt="work instruction link"
                    />
                  )}
                  {!props.workInstruction.workInstructionImageURL && (
                    <CardMedia
                      component="img"
                      className="fall-back-img"
                      sx={{ width: "5em", height: "5em", mt: "1em", mr: '1em', objectFit: 'fill' }}
                      image={fallBackIcon}
                    ></CardMedia>
                  )}
                  <Box
                    component="div"
                    sx={{ display: "flex", flexDirection: "column" }}
                    className={"work-ins-card-right"}
                  >
                    <CardContent
                      sx={{ flex: "1 0 auto" }}
                      className="work-ins-card-content"
                    >
                      <Typography
                        component="div"
                        variant="h5"
                        className="card-work-ins-text multi-line-ellipsis"
                        title={props.workInstruction.workInstructionName}
                      >
                        {props.workInstruction.workInstructionName}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: "rgba(49, 45, 50, .7) !important",
                          marginBottom: '0px !important'
                        }}
                        color="text.secondary"
                        component="div"
                        className="card-work-ins-text  multi-line-ellipsis"
                        title={props.workInstruction.workInstructionDescription}
                      >
                        {props.workInstruction.workInstructionDescription}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: "rgba(49, 45, 50, .7) !important",
                          lineHeight: '1'
                        }}
                        color="text.secondary"
                        component="div"
                        className="card-work-ins-text multi-line-ellipsis"
                        title={convertDate(props.workInstruction.createdOn)}
                      >
                        {getFormattedDateFromUnixTime(props.workInstruction.createdOn)}
                      </Typography>
                      <Typography
                        variant="button"
                        color="text.secondary"
                        component="div"
                        sx={{ color: "rgba(49, 45, 50, .7) !important" }}
                        className="card-work-ins-text multi-line-ellipsis"
                        title={props.workInstruction.brand}
                      >
                        {props.workInstruction.brand}
                      </Typography>
                    </CardContent>
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Paper className="assignment-requisits-action" elevation={0}>
                  <Typography component="span" variant="h6">
                    1 {t('PROCEDURE.PROCEDURE')}
                  </Typography>
                  <Box component="div" className="assignment-requisits-info">
                    <Box
                      component="div"
                      className="assignment-requisits-heading"
                    >
                      <ProductAffected />
                      <Typography component="span" variant="h6">
                          {props.procedure.procedureName}
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
              <Grid className="confirmation-grid" item xs={12}>
                <Box component="div">
                  <Typography
                    component="div"
                    variant="h5"
                    sx={{ textAlign: "center", fontSize: "1.5em" }}
                  >
                    {t('PROCEDURE.PROCEDURE_ASSIGNED')}!
                  </Typography>
                  <Typography
                    className="success-msg"
                    component="div"
                    sx={{ textAlign: "center" }}
                  >
                    {props.userAssignedCount} {props.userAssignedCount === 1 ? t('ANALYTICS_SECTION.TRAINEE') : t('ANALYTICS_SECTION.TRAINEES')} {t('ANALYTICS_SECTION.WERE_ASSIGNED_A_PROCEDURE')}
                     {props.workInstruction.workInstructionName} {t('ANALYTICS_SECTION.WORK_INSTRUCTION')}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
