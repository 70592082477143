import { IUserGroup } from "../../entities/Organization/User/UserGroup";
import { newAxiosInstance as http } from "../http-common";
import { DataResponse } from "../service-models";

const getAllUserGroups = () => {
    const url = `/usergroup/get`;
    return http.get<DataResponse<Array<IUserGroup>>>(url);
};

const getUserGroupsById = (id: string) => {
  const url = `/usergroup/get/${id}`;
  return http.get<DataResponse<Array<IUserGroup>>>(url);
};

const updateUserGroup = (id: string,userGroup: any) => {
  const url = `/usergroup/edit/${id}`;
  return http.put<DataResponse<IUserGroup>>(url, userGroup);
};

const createUserGroup = (userGroup: any) => {
  const url = `/usergroup/create`;
  return http.post<DataResponse<any>>(url, userGroup);
};

const deleteUserGroup = (id: string) => {
  return http.delete<any>(`/usergroup/delete/${id}`)
}

const UserGroupService = {
  getAllUserGroups,
  updateUserGroup,
  createUserGroup,
  getUserGroupsById,
  deleteUserGroup
};

export default UserGroupService;
