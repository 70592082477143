import { Refresh, Search } from '@mui/icons-material';
import { Avatar, Box, FormControl, Grid, IconButton, InputAdornment, MenuItem, Paper, Select, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import WorkInstructionCards from '../../../components/Cards/WorkInstructionCard/WorkInstructionCards';
import { IWorkInstructions } from '../../../entities/Trainee/MockTrainee';
import FuzzySearch from 'fuzzy-search';
import { useParams } from 'react-router-dom';
import Loading from '../../../components/Loading/Loading';
import { Toast } from '../../../components/Notifications/Notification';
import { ActionFilter, SortDesc } from '../../../components/Icons/CdsIcons';
import TrainerService from '../../../services/trainer/TrainerService';
import { useTranslation } from 'react-i18next';

interface Props {
  isTrainer?: boolean;
}

const RevisitRequests: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [sort, setSort] = useState<string>('');
  const [filter, setFilter] = useState<string>('');
  const [workInstructions, setWorkInstructions] = useState<IWorkInstructions[]>();
  const [filteredWorkInstructions, setFilteredWorkInstructions] = useState<IWorkInstructions[]>();
  const [isRevisitRequest, setIsRevisitRequest] = React.useState<any>(true);

  const { companyId } = useParams();

  useEffect(() => {
    loadData();
  }, []);

  const sortFilter = (sort: string) => {
    if (filteredWorkInstructions) {
      if (sort === 'Alphabetical') {
        var x = filteredWorkInstructions.sort((a, b) => a.workInstructionName.localeCompare(b.workInstructionName));
        setFilteredWorkInstructions(x);
      } else if (sort === 'Status') {
        var x = filteredWorkInstructions.sort((a, b) => a.completionStatus.localeCompare(b.completionStatus));
        setFilteredWorkInstructions(x);
      } else {
        setFilteredWorkInstructions(sortByModifiedDate(filteredWorkInstructions));
      }
    }
  };

  const filterFilter = (sort: string) => {
    if (filteredWorkInstructions && workInstructions) {
      if (sort !== '') {
        setFilteredWorkInstructions(workInstructions.filter((a) => a.completionStatus === sort));
      } else {
        setFilteredWorkInstructions(sortByModifiedDate(workInstructions));
      }
    }
  };

  const loadData = () => {
    setLoading(true);
    TrainerService.getPendingRevisits()
      .then((e) => {
        setWorkInstructions(e?.data?.data as IWorkInstructions[]);
        setFilteredWorkInstructions(e?.data?.data as IWorkInstructions[]);
        setLoading(false);
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        setLoading(false);
      });
  };

  const handleReloadData = () => {
    loadData();
  };
  const theme = useTheme();

  const search = (key: string) => {
    if (workInstructions) {
      const searcher = new FuzzySearch(workInstructions, ['workInstructionName', 'workInstructionDescription'], {
        caseSensitive: false,
      });
      const result = searcher.search(key);
      result.length > 0 ? setFilteredWorkInstructions(result) : setFilteredWorkInstructions(workInstructions);
    }
  };

  const sortByModifiedDate = (ws: IWorkInstructions[]) => {
    if (ws) {
      return ws.sort((obj1, obj2) => {
        if (obj1.modifiedOn < obj2.modifiedOn) {
          return 1;
        }
        if (obj1.modifiedOn > obj2.modifiedOn) {
          return -1;
        }
        return 0;
      });
    }
  };

  const { t } = useTranslation();
 
  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <div>
          <Paper elevation={0} className="instructions-title-container" sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Box component="div" className="left">
              <Typography variant="h6" component="div" className="page-title">
                {' '}
                {t('APPROVALREQUESTS.REVISIT_WI')}{' '}
              </Typography>{' '}
              <Avatar
                sx={{
                  bgcolor: 'grey.300',
                  color: '#000',
                  height: '32px',
                  padding: '0 5px',
                }}
                variant="rounded"
              >
                {filteredWorkInstructions?.length || 0}
              </Avatar>
            </Box>
            <Box component="div" className="right">
              <Box component="div" sx={{ display: 'flex', alignItems: 'flex-end' }} className="search-btn">
                <FormControl fullWidth>
                  <TextField
                    onChange={(e) => search(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {' '}
                          <Search sx={{ color: 'primary.main', mr: 1, my: 0.5 }} />{' '}
                        </InputAdornment>
                      ),
                    }}
                    placeholder={t('COMMON.SEARCH')}
                    style={{ borderRadius: '8px !important' }}
                  />
                </FormControl>
              </Box>
              <FormControl
                sx={{
                  m: 1,
                  minWidth: 120,
                  display: { xs: 'none', md: 'flex' },
                }}
              >
                <Select
                  className="select-trainee-bar"
                  inputProps={{ 'aria-label': 'Without label' }}
                  startAdornment={
                    <InputAdornment position="start" sx={{ color: 'primary.main' }}>
                      {' '}
                      <SortDesc />{' '}
                    </InputAdornment>
                  }
                  sx={{ color: 'primary.main' }}
                  value={sort}
                  onChange={(e) => {
                    setSort(e.target.value);
                    sortFilter(e.target.value);
                  }}
                  size="small"
                  displayEmpty
                >
                  <MenuItem value="" sx={{ color: 'primary.main' }}>
                    {' '}
                    <em>{t('COMMON.SORT')}</em>{' '}
                  </MenuItem>
                  <MenuItem value={'Alphabetical'} sx={{ color: 'primary.main' }}>
                    {t('WI_LIST.ALPHABETICAL')}
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl
                sx={{
                  m: 1,
                  minWidth: 120,
                  display: { xs: 'none', md: 'flex' },
                }}
              >
                <Select
                  className="select-trainee-bar"
                  defaultValue=""
                  startAdornment={
                    <InputAdornment position="start" sx={{ color: 'primary.main' }}>
                      {' '}
                      <ActionFilter />{' '}
                    </InputAdornment>
                  }
                  sx={{ color: 'primary.main' }}
                  value={filter}
                  onChange={(e) => {
                    setFilter(e.target.value);
                    filterFilter(e.target.value);
                  }}
                  size="small"
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value="" sx={{ color: 'primary.main' }}>
                    {' '}
                    <em>{t('COMMON.FILTER')}</em>{' '}
                  </MenuItem>
                  <MenuItem sx={{ color: 'primary.main' }} value={'Not started'}>
                    {t('COMMON.WORKINSTRUCTION_TRAINEE_STATUS_NOT_STARTED')}
                  </MenuItem>
                  <MenuItem sx={{ color: 'primary.main' }} value={'In-Progress'}>
                    {t('COMMON.WORKINSTRUCTION_TRAINEE_STATUS_IN_PROGRESS')}
                  </MenuItem>
                  <MenuItem sx={{ color: 'primary.main' }} value={'Completed'}>
                    {t('COMMON.WORKINSTRUCTION_TRAINEE_STATUS_COMPLETED')}
                  </MenuItem>
                </Select>
              </FormControl>
              <Tooltip title="Refresh work instructions">
                <IconButton color="primary" sx={{ mr: props.isTrainer ? 1 : 0, borderRadius: '8px' }} onClick={handleReloadData}>
                  <Refresh />
                </IconButton>
              </Tooltip>
            </Box>
          </Paper>

          <Grid container spacing={1} sx={{ py: 2, px: 3, display: { xs: 'flex', md: 'none' } }}>
            <Grid item xs={9}>
              <Box component="div" display="flex" justifyContent="flex-start">
                <Typography variant="h6" component="div" sx={{ mr: 1, fontSize: '13px' }}>
                  {' '}
                  {t('NAVBAR.ALL_WORK_INSTRUCTIONS')}{' '}
                </Typography>
                {workInstructions && (
                  <Avatar
                    sx={{
                      bgcolor: 'grey.300',
                      color: '#000',
                      height: '24px',
                      padding: '0',
                      fontSize: '13px',
                      fontWeight: 600
                    }}
                    variant="rounded"
                  >
                    {' '}
                    {workInstructions.length}{' '}
                  </Avatar>
                )}
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box component="div" display="flex" justifyContent="flex-end">
                <Box component="div" mr={1} onClick={(e) => setOpen(true)}>
                  <SortDesc />
                </Box>
                <Box component="div" mr={1} onClick={(e) => setFilterOpen(true)}>
                  <ActionFilter />
                </Box>
                <Tooltip title="Refresh work instructions">
                  <IconButton
                    color="primary"
                    sx={{
                      mr: props.isTrainer ? 1 : 0,
                      borderRadius: '8px',
                      p: 0,
                    }}
                    onClick={handleReloadData}
                  >
                    <Refresh />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box component="div" sx={{ display: 'flex', alignItems: 'flex-end' }} className="search-btn">
                <FormControl fullWidth>
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {' '}
                          <Search sx={{ color: 'primary.main', mr: 1, my: 0.5 }} />{' '}
                        </InputAdornment>
                      ),
                    }}
                    placeholder={t('COMMON.SEARCH')}
                    style={{ borderRadius: '8px !important' }}
                  />
                </FormControl>
              </Box>
            </Grid>
          </Grid>
          <Grid className="card-container" container spacing={{ xs: 3, md: 4 }} direction={'row'} alignItems={'stretch'} sx={{ marginBottom: { xs: '80px', md: '0px' } }}>
            {filteredWorkInstructions?.length &&
              filteredWorkInstructions?.map((ws, index) => {
                return (
                  <Grid item xs={12} md={4} lg={4} key={index}>
                    <WorkInstructionCards companyId={companyId} workInstruction={ws} isTrainer={props.isTrainer} isRevisitRequest={isRevisitRequest} />
                  </Grid>
                );
              })}
          </Grid>
        </div>
      )}
    </>
  );
};

export default RevisitRequests;
