import { Autocomplete, Backdrop, Box, Button, FormControl, FormHelperText, FormLabel, Grid, InputLabel, MenuItem, Select, Skeleton, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BasicDialog } from '../BasicDialog/BasicDialog';
import TrainerService from '../../../../services/trainer/TrainerService';
import LanguageService from '../../../../services/Language/LanguageService';
import { Formik, Form, Field, ErrorMessage, useFormik, isString } from 'formik';
import * as Yup from 'yup';
import Loading from '../../../Loading/Loading';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import { IpProtectionIcon } from '../../../Icons/CdsIcons';

export const IpProtectionDialog = (props) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<any>([]);
  const { refreshProcedure, setRefreshProcedure } = useCurrentUser();

  const { t } = useTranslation();

  const validationSchema = Yup.object({
    selectedOption: Yup.object({ code: Yup.string().required(t('PROCEDURE.PLEASE_SELECT_OPTION')) }),
    otherLanguage: Yup.string().when('selectedOption', {
      is: 'others',
      then: Yup.string().required(t('PROCEDURE.PLEASE_ENTER_LANGUAGE')),
    }),
  });

  const cloneProcedure = (values) => {
    const payload = {
      procedureId: props.procedureId,
      language: values.selectedOption,
    };
    setLoading(true);
    props.closeDialog();
    TrainerService.cloneProcedure(payload)
      .then((response) => {
        setRefreshProcedure(!refreshProcedure);
        setTimeout(() => setLoading(false), 2000);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  const { values, errors, touched, handleChange, handleBlur, submitForm, setFieldValue } = useFormik({
    initialValues: {
      selectedOption: { code: '', name: '' },
      otherLanguage: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      cloneProcedure(values);
    },
  });

  type AutoCompleteFieldProps<T> = {
    selectValue: keyof T;
    options: T[];
    onChange: any;
  };

  return (
    <>
      {loading && (
        <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 100 }} open={loading}>
          <Loading />
        </Backdrop>
      )}
      <BasicDialog
        title={t('NAVBAR.INTELLECTUAL_PROPERTY_SECURITY')}
        onClose={() => {
          props.closeDialog();
        }}
        onOpen={props.openDialog}
      >
        <Grid xs={12} sx={{ display: 'flex', flexDirection: 'row' }}>
          <Grid xs={9} sx={{ width: '100%', marginRight: '24px' }}>
            <Typography sx={{ fontSize: '16px', margin: '8px 0px', fontWeight: '800' }}>{t('NAVBAR.INTELLECTUAL_PROPERTY_SECURITY_IS_OUR_PRIORITY')}</Typography>
            <Typography sx={{ fontSize: '16px', margin: '8px 0px',fontWeight: '100', textAlign: 'justify' }}>{t('NAVBAR.INTELLECTUAL_PROPERTY_SECURITY_IS_OUR_PRIORITY_DESCRIPTION')}</Typography>
          </Grid>
          <Grid item xs={3} sx={{ width: '100%',alignItems: 'center',marginTop: '16px' }}>
            <IpProtectionIcon />
          </Grid>
        </Grid>
      </BasicDialog>
    </>
  );
};
