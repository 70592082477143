import { Box, Button, Grid, Paper, Typography, useTheme } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import './ManageSubscription.scss';
import { environment } from '../../environment/environment';
import TrainerService from '../../services/trainer/TrainerService';
import Loading from '../../components/Loading/Loading';

const Tile = ({ title, subtitle, danger = false }) => {
  const theme = useTheme();
  return (
    <Grid item xs={12} sm={6} md={2.4} lg={2.4}>
      <Paper className="tile" sx={{ backgroundColor: danger ? 'rgba(167, 49, 49, 0.05)' : theme.palette.primary.main + '0d' }}>
        <Typography fontSize="24px" sx={{ color: danger ? '#A73131' : 'black' }}>
          {title}
        </Typography>
        <Typography fontSize="14px" sx={{ textAlign: 'left' }}>
          {subtitle}
        </Typography>
      </Paper>
    </Grid>
  );
};

export const ManageSubscription = (props) => {
  const [loading, setLoading] = useState(true);
  const [subscriptionDetails, setSubscriptionDetails] = useState<any>();
  const [daysAgo, setDaysAgo] = useState(0);
  const [planExceeded, setPlanExceeded] = useState(false);
  const { state }: any = useLocation();

  useEffect(() => {
    const timestamp = subscriptionDetails?.expiration;
    const now = Math.floor(Date.now() / 1000);
    const difference = now - timestamp;
    setDaysAgo(Math.floor(difference / (60 * 60 * 24)));
    setPlanExceeded(subscriptionDetails?.plan.license.created.trainee > subscriptionDetails?.plan.license.licenses.trainee || subscriptionDetails?.plan.license.created.trainer > subscriptionDetails?.plan.license.licenses.trainer);
  }, [subscriptionDetails]);

  // useEffect(() => {
  //   TrainerService.getSubscriptionDetails()
  //     .then((e) => {
  //       setSubscriptionDetails(e.data.data);
  //       if (e.data?.data?.status === 'ACTIVE') {
  //         window.location.reload();
  //       }
  //       setLoading(false);
  //     })
  //     .catch((e) => {
  //       setLoading(false);
  //     });
  // }, []);

  const openSubscription = () => {
    var cbInstance2 = (window as any).Chargebee.init({
      site: environment.AppConfigs.chargebeeSiteName, // your test site
    });
    var chargebeePortalInstance = cbInstance2.createChargebeePortal();
    chargebeePortalInstance.open({
      close:()=>{
        window.location.reload();
      }
    });
  };

  return (
    <>
      <Box component="div" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {loading && <Loading />}
        {!loading && (
          <Paper className="subscription root" elevation={0} sx={{ borderRadius: '12px', boxShadow: 'rgba(149, 157, 165, 0.5) 0px 8px 24px', margin: '24px', width: '60%' }}>
            <Box component="div" className="title">
              <Box component="div" sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                <Typography sx={{ fontSize: '14px' }}>Plan Name</Typography>
                <Typography sx={{ fontSize: '18px' }}>Business Plan</Typography>
              </Box>
              <Box component="div" sx={{ backgroundColor: '#A73131', color: 'white', padding: '4px 10px', borderRadius: '8px', fontSize: '14px' }}>
                {daysAgo > 0 ? `Expired ${daysAgo} days ago` : `Plan Limit Exceeded`}
              </Box>
            </Box>
            <Box component="div" className="body">
              <Typography fontSize="14px" sx={{ margin: '8px 0px' }}>
                Plan Details
              </Typography>
              <Grid container spacing={2}>
                <Tile title={subscriptionDetails?.plan.license.created.trainee + '/' + subscriptionDetails?.plan.license.licenses.trainee} subtitle={'Trainee license in use'} danger={subscriptionDetails?.plan.license.created.trainee > subscriptionDetails?.plan.license.licenses.trainee} />
                <Tile title={subscriptionDetails?.plan.license.created.trainer + '/' + subscriptionDetails?.plan.license.licenses.trainer} subtitle={'Trainer license in use'} danger={subscriptionDetails?.plan.license.created.trainer > subscriptionDetails?.plan.license.licenses.trainer} />
              </Grid>
              {planExceeded && (
                <Typography fontSize="16px" sx={{ marginTop: '16px', textAlign: 'left' }}>
                  You have exceeded your plan limit, please delete users that are not required through user management.
                </Typography>
              )}
            </Box>

            {daysAgo > 0 && (
              <>
                <Box component="div" sx={{ margin: '40px 0px 35px 0px', background: `linear-gradient(90deg, #d9d3d3 0%, white 98.9%)`, height: '1px', width: '50%' }}></Box>
                <Box component="div" className="body">
                  <Typography sx={{ margin: '0px 0px 8px' }}>Renew your subscription or you will lose</Typography>
                  <Grid container spacing={2}>
                    <Tile title={subscriptionDetails?.contentCreated.workInstructions} subtitle={'Work Instructions'} />
                    <Tile title={subscriptionDetails?.contentCreated.procedures} subtitle={'Procedures'} />
                    <Tile title={subscriptionDetails?.contentCreated.steps} subtitle={'Steps in Studio'} />
                    <Tile title={subscriptionDetails?.plan.license.created.trainee} subtitle={'Trainees'} />
                    <Tile title={subscriptionDetails?.plan.license.created.trainer} subtitle={'Trainers'} />
                  </Grid>
                </Box>
              </>
            )}
            <Box component="div" className="actions">
              {planExceeded && (
                <>
                  <Link to={`/users`}>
                    <Button sx={{ marginRight: '8px' }} variant="contained">
                      See User Management
                    </Button>
                  </Link>
                  {daysAgo < 0 && (
                    <Button variant="outlined" onClick={() => openSubscription()}>
                      Modify Subscription
                    </Button>
                  )}
                </>
              )}
              {daysAgo > 0 && (
                <Button variant="contained" onClick={() => openSubscription()}>
                  Renew
                </Button>
              )}
            </Box>
          </Paper>
        )}
      </Box>
    </>
  );
};
