export const RegExpConstants = {
  phone: /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
  latitude_longitude: /^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,6}/,
  base64: /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/,
  name: /^([A-zÀ-ỹ_. -]{1,}([\'-]))*[A-zÀ-ỹ_. -]+?$/,
  email: /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,20}$/,
};

export const MaxLengthConstants = {
  Common: {
    name: 60,
    address: 100,
    country: 50,
    state: 50,
    city: 50,
    zip: 11,
    phone: 15,
    email: 120,
    location: 20,
  },
  Organization: {
    name: 100,
    alias: 50,
    identifier: 50,
    description: 200,
  },
  Persona: {
    name: 100,
    alias: 50,
    identifier: 50,
    description: 200,
  },
};

export const DATE_FORMAT = 'DD-MM-YYYY';

export const ETQPayload = {
  type: 'LEARNING_MANAGEMENT_SYSTEM',
  applicationUrl: '',
  name: '',
  integrationClientSecretHash: '',
  integrationClientId: '',
  lmsType: '',
  bearerTokenGenerationUrl: '',
  refreshTokenGenerationUrl: '',
  actions: {
    enableVersion: {
      httpMethod: 'PUT',
      endpoint: '/reliance/rest/v1/documents',
      payload: {
        Document: [
          {
            applicationName: 'ETQ_TRAINING',
            formName: 'ETQ_TRAINING_COURSE_PROFILE',
            documentId: '[COURSE_ID]',
            Fields: [
              {
                fieldName: 'ETQ_TRAINING_COURSE_PROFILE_DISABLED',
                Values: ['[STATUS]'],
              },
            ],
          },
        ],
      },
    },
    createContent: {
      httpMethod: 'POST',
      endpoint: '/reliance/rest/v1/documents',
      payload: {
        Document: [
          {
            applicationName: 'ETQ_TRAINING',
            formName: 'ETQ_TRAINING_COURSE_PROFILE',
            Fields: [
              {
                fieldName: 'COURSE_OWNER_EMAIL_1',
                Values: ['[CREATED_BY_USERNAME]'],
              },
              {
                fieldName: 'ETQ_TRAINING_COURSE_PROFILE_NAME',
                Values: ['[PROCEDURE_NAME]'],
              },
              {
                fieldName: 'ETQ_TRAINING_COURSE_PROFILE_COURSE_TYPE',
                Values: ['Online'],
              },
              {
                fieldName: 'REVISION_NUMBER',
                Values: ['[PROCEDURE_VERSION_NAME]'],
              },
              {
                fieldName: 'COURSE_FROM_MENTOR',
                Values: ['Yes'],
              },
              {
                fieldName: 'ETQ_TRAINING_COURSE_PROFILE_TRAINING_MATERIAL_LINK_TO_EXTERNAL_MATERIALS',
                Values: ['[PROCEDURE_NAME]ETQ$SEPARATOR[ACCESS_URL]'],
              },
              {
                fieldName: 'PROCEDURE_ID',
                Values: ['[PROCEDURE_ID]'],
              },
              {
                fieldName: 'WORK_INSTRUCTION_ID',
                Values: ['[WORK_INSTRUCTION_ID]'],
              },
            ],
          },
        ],
      },
    },
    upgradeContent: {
      httpMethod: 'POST',
      endpoint: '/reliance/rest/v1/documents',
      payload: {
        Document: [
          {
            applicationName: 'ETQ_TRAINING',
            formName: 'ETQ_TRAINING_COURSE_PROFILE',
            Fields: [
              {
                fieldName: 'COURSE_OWNER_EMAIL_1',
                Values: ['[CREATED_BY_USERNAME]'],
              },
              {
                fieldName: 'ETQ_TRAINING_COURSE_PROFILE_NAME',
                Values: ['[PROCEDURE_NAME]'],
              },
              {
                fieldName: 'ETQ_TRAINING_COURSE_PROFILE_COURSE_TYPE',
                Values: ['Online'],
              },
              {
                fieldName: 'REVISION_NUMBER',
                Values: ['[PROCEDURE_VERSION_NAME]'],
              },
              {
                fieldName: 'COURSE_FROM_MENTOR',
                Values: ['Yes'],
              },
              {
                fieldName: 'ETQ_TRAINING_COURSE_PROFILE_TRAINING_MATERIAL_LINK_TO_EXTERNAL_MATERIALS',
                Values: ['[PROCEDURE_NAME]ETQ$SEPARATOR[ACCESS_URL]'],
              },
              {
                fieldName: 'PROCEDURE_ID',
                Values: ['[PROCEDURE_ID]'],
              },
              {
                fieldName: 'WORK_INSTRUCTION_ID',
                Values: ['[WORK_INSTRUCTION_ID]'],
              },
              {
                fieldName: 'PREVIOUS_REVISION_COURSE',
                Values: ['[PREVIOUS_REVISION]'],
              },
            ],
          },
        ],
      },
    },
    updateUserProgress: {
      httpMethod: 'PUT',
      endpoint: '/reliance/rest/v1/documents',
      payload: {
        Document: [
          {
            applicationName: 'ETQ_TRAINING',
            formName: 'ETQ_TRAINING_COURSE_PROFILE',
            documentId: '[COURSE_ID]',
            Fields: [
              {
                fieldName: 'COURSE_COMPLETED_EMPLOYEES',
                Values: ['[USER_NAME]'],
              },
            ],
          },
        ],
      },
    },
  },
};
