import { Buffer } from 'buffer';

export function dataURItoBlob(dataURI: string) {
  let mime: string = dataURI.split(',')[0].split(':')[1].split(';')[0];
  let buff = Buffer.from(dataURI.split(',')[1], 'base64');
  const blob = new Blob([buff], { type: mime });
  return blob;
}

export const createObjectUrlFromDataUrl: (inputDataUrl: string) => string = (inputDataUrl: string) => {
  let newBlob: Blob = dataURItoBlob(inputDataUrl);
  return URL.createObjectURL(newBlob);
};

export const revokeObjectUrl: (inputObjectUrl: string) => void = (inputObjectUrl: string) => {
  URL.revokeObjectURL(inputObjectUrl);
};
