import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import './PendingProcedureList.scss';
import { IDateRange, IPendingProcedure, PendingStatus, PendingStatusType } from '../../../../entities/Analytics/AnalyticsProcedure';
import TrainerProcedureCard from '../../../Cards/Procedures/TrainerProcedureCard/TrainerProcedureCard';
import AnalyticsService from '../../../../services/Analytics/AnalyticsService';
import { useLocation } from 'react-router';
import { useAnalytics } from '../../../../states/analytics';
import { Toast } from '../../../Notifications/Notification';
import Loading from '../../../Loading/Loading';
import { WorkInstructionModelStatus } from '../../../../shared/enums';
import { useTranslation } from 'react-i18next';

export const PendingProceduresList: React.FC = () => {
  const { t } = useTranslation();

  const [allRecords, setAllRecords] = useState<Array<any>>();
  const [filteredRecords, setFilteredRecords] = useState<Array<IPendingProcedure>>();
  const [filter, setFilter] = React.useState<PendingStatusType>(PendingStatus.ALL);
  const [loading, setLoading] = React.useState(false);

  const { search: searchQuery } = useLocation();
  const currentDateRange = useAnalytics((state) => state.selectedDateRange);
  const query = new URLSearchParams(searchQuery);
  const startDate = query.get('startDate');
  const endDate = query.get('endDate');
  const procedureOwner = query.get('procedure');
  const pendingStatusQuery = query.get('procedureFilter') || 'ALL';

  const pendingProcedureList = useAnalytics((state) => state.pendingProceduresList);

  useEffect(() => {
    if (pendingProcedureList) {
      setAllRecords(pendingProcedureList.pendingProceduresData);
      filterRecords(pendingProcedureList.pendingProceduresData);
      setLoading(false);
    } else {
      if (currentDateRange && currentDateRange.startDate && currentDateRange.endDate) {
        getPendingProceduresList(currentDateRange);
      } else {
        getPendingProceduresList({
          startDate: startDate,
          endDate: endDate,
        } as IDateRange);
      }
    }
  }, []);

  const filterRecords = (data) => {
    if (pendingStatusQuery === PendingStatus.UNPUBLISHED) {
      setFilteredRecords(data.filter((a) => a.pendingStatus.indexOf(pendingStatusQuery as PendingStatusType) > -1 || a.pendingStatus.indexOf(PendingStatus.REQUIRE_STEPS as PendingStatusType) > -1 || (a.pendingStatus.indexOf(PendingStatus.UNAPPROVED as PendingStatusType) > -1 && a.procedure.approvalSentStatus !== 'pending' && a.procedure.approvalStatus !== 'pending')));
    } else setFilteredRecords(data.filter((a) => a.pendingStatus.indexOf(pendingStatusQuery as PendingStatusType) > -1));
  };

  const getPendingProceduresList = (dateRange: IDateRange) => {
    setLoading(true);
    AnalyticsService.getAnalyticsOrganizationPendingProcedures(procedureOwner === 'Mine' ? 'Mine' : 'All', dateRange.startDate, dateRange.endDate)
      .then((e) => {
        const {
          data: { data: records },
        } = e;
        setAllRecords(records.pendingProceduresData);
        if (pendingStatusQuery === PendingStatus.ALL) {
          setFilteredRecords(records.pendingProceduresData);
        } else {
          setFilter(pendingStatusQuery as PendingStatusType);
          filterRecords(records.pendingProceduresData);
        }
        setLoading(false);
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        setLoading(false);
      });
  };

  return (
    <Box
      component="div"
      className="pending-procedure-analytics"
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {loading && <Loading />}
      <Box
        component="div"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h6" sx={{ mb: '24px' }}>
          {pendingStatusQuery === PendingStatus.REJECTED && 'Rejected Procedures'}
          {pendingStatusQuery === PendingStatus.UNAPPROVED && 'Unapproved Procedures'}
          {pendingStatusQuery === PendingStatus.UNPUBLISHED && 'Unpublished Procedures'}
          {pendingStatusQuery === PendingStatus.UNASSIGNED && 'Unassigned Procedures'}

          <strong className="count">{filteredRecords && filteredRecords.length > 0 ? filteredRecords.length : 0}</strong>
        </Typography>
      </Box>
      <Box
        component="div"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}
      >
        {filteredRecords &&
          filteredRecords.length > 0 &&
          filteredRecords.map((procedure) => (
            <TrainerProcedureCard
              key={procedure.procedure.procedureId}
              isExternalPageOpened={function (value: boolean): void {
                throw new Error('Function not implemented.');
              }}
              title={procedure?.procedure?.procedureName}
              workInstruction={procedure?.workInstruction}
              procedureDetails={procedure?.procedure}
              status={procedure?.workInstruction?.status === WorkInstructionModelStatus.MODEL_CONVERSION_FAILED ? '3D Model Conversion Failed' : procedure?.workInstruction?.status === WorkInstructionModelStatus.PREPARING_MODEL ? 'Preparing 3D Model' : procedure?.workInstruction?.status === WorkInstructionModelStatus.MODEL_READY ? '3D Model Ready' : ' '}
              authored={procedure?.procedure?.isPublished}
            />
          ))}
      </Box>
    </Box>
  );
};

export default PendingProceduresList;
