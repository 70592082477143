import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PROCEDURE_VISIBILITY_SCOPE } from "../../../shared/enums";

type InitialState = {
    createProcedure: {
        visibilityScope: PROCEDURE_VISIBILITY_SCOPE
    }
}
const initialState: InitialState = {
    createProcedure : {
      visibilityScope: PROCEDURE_VISIBILITY_SCOPE.NORMAL
    }
}


const procedureSlice = createSlice({
  name: 'procedure',
  initialState: initialState,
  reducers: {
    setVisibilityScope: (state, action) => {
      state.createProcedure.visibilityScope = action.payload;
    },
  },
});

export default procedureSlice.reducer;
export const { setVisibilityScope } = procedureSlice.actions;