import React, { useEffect, useState } from 'react';
import { Box, FormControl, InputAdornment, TextField, Typography } from '@mui/material';
import { IDateRange, IOrganizationAssignedTrainee } from '../../../../entities/Analytics/AnalyticsProcedure';
import Loading from '../../../Loading/Loading';
import FuzzySearch from 'fuzzy-search';
import { Search } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAnalytics } from '../../../../states/analytics';
import TraineeAssignedCard from './TraineeAssignedCard';
import './TraineesAssignedList.scss';
import '../../Analytics.scss';
import { Toast } from '../../../Notifications/Notification';
import AnalyticsService from '../../../../services/Analytics/AnalyticsService';
import { useTranslation } from 'react-i18next';

interface TraineesAssignedListProps {}

export const TraineesAssignedList: React.FC<TraineesAssignedListProps> = (props) => {
  const currentDateRange = useAnalytics((state) => state.selectedDateRange);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<Array<IOrganizationAssignedTrainee>>();
  const [filteredData, setFilteredData] = useState<Array<IOrganizationAssignedTrainee>>();

  const navigate = useNavigate();
  const { search: searchQuery } = useLocation();
  const query = new URLSearchParams(searchQuery);
  const filterType = query.get('filter');
  const startDate = query.get('startDate');
  const endDate = query.get('endDate');
  const progress = query.get('progress');
  const assignedTraineesList = useAnalytics((state) => state.assignedTraineesList);
  useEffect(() => {
    if (assignedTraineesList) {
      setData(assignedTraineesList[progress || 'completed']);
      setFilteredData(assignedTraineesList[progress || 'completed']);
      setLoading(false);
    } else {
      if (currentDateRange && currentDateRange.startDate && currentDateRange.endDate) {
        getAssignedTrainees(currentDateRange);
      } else {
        getAssignedTrainees({
          startDate: startDate,
          endDate: endDate,
        } as IDateRange);
      }
    }
  }, []);

  const getAssignedTrainees = (dateRange: IDateRange) => {
    setLoading(true);
    AnalyticsService.getAnalyticsOrganizationAssignedTrainees(dateRange.startDate, dateRange.endDate)
      .then((e) => {
        const {
          data: { data: records },
        } = e;
        let status = {
          completed: [] as any,
          inProgress: [] as any,
          notStarted: [] as any,
        };
        if (records) {
          records.map((record) => {
            if ((record.progress.completed.length === 0 && record.progress.notStarted.length === 0) || (record.progress.completed.length > 0 && record.progress.notStarted.length > 0)) {
              status.inProgress.push(record);
            } else {
              if (record.progress.completed.length > 0) {
                status.completed.push(record);
              } else {
                status.notStarted.push(record);
              }
            }
          });
          setData(status[progress || 'completed']);
          
          setFilteredData(status[progress || 'completed']);
        }
        setLoading(false);
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        setLoading(false);
      });
  };

  const search = (key: string) => {
    if (data) {
      const searcher = new FuzzySearch(data, ['firstName', 'lastName'], {
        caseSensitive: false,
      });
      const result = searcher.search(key);
      setFilteredData(result);
    }
  };

  const handleCardClick = (userId: string) => {
    navigate(`/analytics/trainees/assigned/details/${userId}?filter=${filterType}&startDate=${startDate}&endDate=${endDate}&progress=${progress}`);
  };

  const { t } = useTranslation();
  const progressText = {
    notStarted: 'Not Started',
    completed: 'Completed',
    inProgress: 'In Progress',
  };

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <Box
          component="div"
          className="trainees-assigned-analytics"
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            component="div"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              margin: '20px 0px',
            }}
          >
            <Typography variant="h6">
              {`Trainees: ${progressText[progress || 'completed']}`} <strong className="count">{filteredData?.length}</strong>
            </Typography>
            <Box component="div" sx={{ display: 'flex', alignItems: 'flex-end' }} className="search-btn">
              <FormControl fullWidth>
                <TextField
                  onChange={(e) => search(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {' '}
                        <Search sx={{ color: 'primary.main', mr: 1, my: 0.5 }} />{' '}
                      </InputAdornment>
                    ),
                  }}
                  placeholder={t('COMMON.SEARCH')}
                  style={{ borderRadius: '8px !important' }}
                />
              </FormControl>
            </Box>
          </Box>
          <Box
            component="div"
            sx={{
              display: 'flex',
              gap: 2,
              flexWrap: 'wrap',
            }}
          >
            <>
              {filteredData &&
                filteredData.map((record, index) => (
                  <Box
                    key={`card-key-${index}`}
                    component="div"
                    sx={{
                      display: 'flex',
                      flexGrow: 1,
                      minWidth: 0,
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: 'calc(33.33% - 0.7em)',
                      maxWidth: 'calc(33.33% - 0.7em)',
                      height: '100%',
                    }}
                  >
                    <TraineeAssignedCard
                      onCardClick={(userId) => handleCardClick(userId)}
                      data={{
                        user_id: record?.userId,
                        username: record?.firstName + ' ' + record?.lastName,
                        completed: record.progress.completed.length,
                        in_progress: record.progress.inProgress.length,
                        not_started: record.progress.notStarted.length,
                      }}
                    />
                  </Box>
                ))}
            </>
          </Box>
        </Box>
      )}
    </>
  );
};

export default TraineesAssignedList;
