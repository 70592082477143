import { useContext } from 'react';
import TranslationContext from '../context/TranslationContext';

const useCurrentTranslation = () => {
  const { currentTranslation, setCurrentTranslation, refreshProcedure, setRefreshProcedure } = useContext(TranslationContext);
  return {
    currentTranslation,
    setCurrentTranslation,
    refreshProcedure,
    setRefreshProcedure
  };
};

export default useCurrentTranslation;
