import { Box, Button, FormControl, Grid, MenuItem, Select, SelectChangeEvent, Skeleton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import usePpeList from '../../hooks/ppeList';
import TrainerService from '../../services/trainer/TrainerService';
import { PPE_DATA } from '../../shared/data/ppe-data';
import Loading from '../Loading/Loading';
import { Toast } from '../Notifications/Notification';
import AddCustomPPEDialog from '../Trainer/Dialog/AddCustomPPEDialog/AddCustomPPEDialog';
import { PpeDialog } from '../Trainer/Dialog/PpeDialog/PpeDialog';
import { AddNewPpe, PPEListComp } from '../Trainer/PPEKit/AddPPE';
import { useTranslation } from 'react-i18next';
import './PpeEquipments.scss';

interface Props {
  onLoading: any;
}
const DeletePpeDialog = (props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [countLoading, setCountLoading] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState(t('COMMON.PPE_DATA.DELETE.MESSAGE') as string);

  useEffect(() => {
    setCountLoading(true);
    props.onLoading(true);
    TrainerService.getPPEUsedCount(props.id)
      .then((response) => {
        setCountLoading(false);
        props.onLoading(false);
        if (response.data.data.ppeOccurrence > 0) setDeleteMessage(deleteMessage.replace('number', response.data.data.ppeOccurrence));
        else setDeleteMessage(t('COMMON.PPE_DATA.DELETE_UNUSED.MESSAGE'));
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        props.onLoading(false);
      });
  }, []);

  const deletePpe = () => {
    setLoading(true);
    TrainerService.deletePPEEquipment(props.id)
      .then((res) => {
        setLoading(false);
        props.getPpeList();
        props.closeDeletePpeDialogHandler();
      })
      .catch(({ response }) => {
        setLoading(false);
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        props.closeDeletePpeDialogHandler();
      });
  };

  return (
    <>
      {!loading && !countLoading && (
        <PpeDialog
          title={t('COMMON.PPE_DATA.DELETE.TITLE')}
          message={deleteMessage}
          onClose={props.closeDeletePpeDialogHandler}
          actions={
            <>
              <Button
                onClick={() => {
                  props.closeDeletePpeDialogHandler();
                }}
                variant="outlined"
                size="small"
              >
                {t('BUTTON.CANCEL') as string}
              </Button>
              <Button
                onClick={() => {
                  deletePpe();
                }}
                variant="contained"
                size="small"
                disabled={countLoading}
              >
                {t('BUTTON.DELETE') as string}
              </Button>
            </>
          }
        />
      )}
    </>
  );
};

export const PpeEquipments = (props: Props) => {
  const [checked, setChecked] = useState<string[]>([]);
  const { sharedPpeList, setSharedPpeList, showSystemSettings, setShowSystemSettings } = usePpeList();
  const [addCustomPPEDialog, setAddCustomPPEDialog] = useState<boolean>(false);
  const [standardPpeCount, setStandardPpeCount] = useState<any>();
  const [customPpeCount, setCustomPpeCount] = useState<any>();
  const [ppeList, setPpeList] = useState<any>(sharedPpeList);
  const [showDeletePpeDialog, setShowDeletePpeDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [openPpeDialog, setOpenPpeDialog] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState();
  const [editId, setEditId] = useState('');
  const handlePpeValues = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };
  const closeDeletePpeDialogHandler = () => {
    setShowDeletePpeDialog(false);
  };

  const closeDialogHandler = () => {
    setAddCustomPPEDialog(false);
  };

  useEffect(() => {
    getPpeList();
  }, []);

  const getPpeList = () => {
    setLoading(true);

    TrainerService.getAllPPEEquipments()
      .then((response) => {
        setPpeList(response.data.data);
        setSharedPpeList(response.data.data);
        setLoading(false);
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        setLoading(false);
      });
  };
  const editCustomPpe = (ppeFormData) => {
    setLoading(true);
    TrainerService.editPPEEquipment(editId, ppeFormData)
      .then((res) => {
        setLoading(false);
        getPpeList();
        setAddCustomPPEDialog(false);
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        setLoading(false);
      });
  };
  useEffect(() => {
    var standard = 0;
    var custom = 0;
    ppeList?.forEach((item) => {
      if (item.isStandard) {
        standard++;
      } else {
        custom++;
      }
    });
    setStandardPpeCount(standard);
    setCustomPpeCount(custom);
  }, [ppeList, standardPpeCount]);

  const handleDelete = (id) => {
    setDeleteId(id);
    setShowDeletePpeDialog(true);
  };
  const handleEdit = (id) => {
    setAddCustomPPEDialog(true);
    setEditId(id);
  };

  const uploadCustomPpe = (ppeFormData) => {
    setLoading(true);
    TrainerService.createPPEEquipment(ppeFormData)
      .then((res) => {
        setLoading(false);
        getPpeList();
        setAddCustomPPEDialog(false);
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        setLoading(false);
      });
  };

  const { t } = useTranslation();

  return (
    <>
      {showDeletePpeDialog && <DeletePpeDialog closeDeletePpeDialogHandler={closeDeletePpeDialogHandler} id={deleteId} getPpeList={getPpeList} onLoading={props.onLoading} />}
      <AddCustomPPEDialog closeDialog={closeDialogHandler} openDialog={addCustomPPEDialog} editMode={true} editId={editId} editCustomPpe={editCustomPpe} style={{ padding: '24px' }} loading={loading} />

      <PPEListComp
        standardPpeCount={standardPpeCount}
        customPpeCount={customPpeCount}
        handlePpeValues={handlePpeValues}
        contentReady={!loading}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        defaultValue={1}
        systemSettings={true}
        ppeList={sharedPpeList}
        addNew={
          <>
            <AddNewPpe customPpeCount={customPpeCount} title={'Add New Custom PPE'} systemSettings={true} getPpeList={getPpeList} contentReady={loading}>
              <Button size="small" variant="contained" sx={{ alignSelf: 'center', marginRight: '18px' }} disabled={loading}>
                {t('COMMON.ADD_PPE') as string}
              </Button>
            </AddNewPpe>
          </>
        }
      />
    </>
  );
};
