import { Box, CardContent, Typography, Grid, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StyledCard } from '../../../components/Styled/StyledCard';
import { StyledGridView } from '../../../components/Styled/StyledGridView';
import { useEffect, useState } from 'react';
import TrainerService from '../../../services/trainer/TrainerService';
import { ArrowForward } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Toast } from '../../../components/Notifications/Notification';
import Loading from '../../../components/Loading/Loading';

const transformText = (text) => {
  if (!text) return '';

  const transformedText = text
    .toLowerCase()
    .replace(/_/g, ' ')
    .split(' ')
    .map((word) => {
      const capitalizedWord = word.charAt(0).toUpperCase() + word.slice(1);
      return capitalizedWord;
    })
    .join(' ');
  return transformedText;
};

const IntegrationTypeCard = (props) => {
  const { t } = useTranslation();
  console.log(props.integrationType, 'in');
  return (
    <StyledCard minCardHeight={'250px'} sx={{ boxShadow: 'rgba(149, 157, 165, 0.5) 0px 8px 24px !important', height: '250px', display: 'flex' }}>
      <Box component="div" sx={{position: 'relative', display: 'flex', flexDirection: 'column', p: 2 }}>
        <CardContent sx={{ flex: '1 0 auto', textAlign: 'left', pt: 0, pb: 0 }}>
          <Typography component="div" variant="h6" className="integration-text">
            {t(`BUTTON.${props.integrationType?.LMS?.name.toString()}`)} (LMS)
          </Typography>

          <Typography variant="subtitle2" color="text.secondary" component="div" className="integration-text" sx={{ marginTop: '18px', overflow: 'scroll', height: '130px' }}>
            {t('BUTTON.THIS_INTEGRATION_ENABLES_PUBLISHING_OF_WORK_INSTRUCTIONS')}
          </Typography>
        </CardContent>
        <Button sx={{ position:'absolute', bottom: '24px', left: '24px' }} onClick={() => props.handleConnectorNavigation(props.integrationType?.LMS?.name.toString())}>
          {t('BUTTON.VIEW_CONNECTORS')}
          <ArrowForward sx={{ m: '0px 8px' }} />
        </Button>
      </Box>
    </StyledCard>
  );
};

const IntegrationTypes: React.FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [integrationTypes, setIntegrationTypes] = useState<any>([]);
  let navigate = useNavigate();
  useEffect(() => {
    setLoading(true);
    TrainerService.getAvailableIntegrationTypes()
      .then((res) => {
        setIntegrationTypes([res.data.data]);
        setLoading(false);
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response?.data.messageCode, { ns: 'error' }) || response?.data.errorMessage);
        setLoading(false);
      });
  }, []);

  const handleConnectorNavigation = (connector) => {
    if (connector === 'LEARNING_MANAGEMENT_SYSTEM') {
      navigate(`/integration/${transformText(integrationTypes[0]?.LMS?.name)}`);
    }
  };

  return (
    <>
      {loading && <Loading />}
      <Typography component="div" variant="h6" className="integration-text" sx={{ display: 'flex', margin: '32px 0px 0px 32px' }}>
        {t('BUTTON.INTEGRATIONS')}
      </Typography>
      <StyledGridView>
        {integrationTypes.map((integrationType) => (
          <Grid item xs={4} md={4} lg={4} key={'i'}>
            <IntegrationTypeCard integrationType={integrationType} handleConnectorNavigation={handleConnectorNavigation} />
          </Grid>
        ))}
      </StyledGridView>
    </>
  );
};

export default IntegrationTypes;
