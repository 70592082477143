import CloseIcon from '@mui/icons-material/Close';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import { Box, useTheme } from '@mui/material';
import { ReactHTMLElement, useEffect, useState } from 'react';
import { openStudioEditorInATab } from '../../../components/Cards/Procedures/TrainerProcedureCard/TrainerProcedureCard';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { IProcedures } from '../../../entities/Trainee/MockTrainee';
import SearchBar from '../../../components/SearchBar/SearchBar';
import { Replay } from '@mui/icons-material';
import { fetchTemplateProcedures } from '../../../redux/features/ModularProcedures/ModularProcedures.slice';

const ProcedureStepsViewer = (props: { procedureId: string; onClose: () => void; isStepViewerOpen: boolean }) => {
  const { procedureId, onClose, isStepViewerOpen } = props;
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const {
    templateProcedures: {
      data: { data: templateProcedures },
    },
    procedureLibrary: {
      searchPayload
    }
  } = useAppSelector((state) => state.modularProcedure);
  const [procedureDetails, setProcedureDetails] = useState<IProcedures>();
  const [searchText, setSearchText] = useState<string>('');
  const [isStudioOpen, setIsStudioOpen] = useState<boolean>(false);

  useEffect(() => {
    // isStudioOpen && handleReloadTemplateProcedureClick();
    const procedure = templateProcedures.find((procedure) => procedure.procedureId === procedureId);
    setProcedureDetails(procedure);
  }, [procedureId, templateProcedures]);

  const handleOpenTemplateProcedureClick = () => {
    const procedure = templateProcedures.find((procedure) => procedure.procedureId === procedureId);
    if (procedure) {
      openStudioEditorInATab(procedure.organizationId, procedure.workInstructionId, procedure.procedureId, true);
      setIsStudioOpen(true);
    } else {
      console.log('Error while opening template procedure. Procedure not found');
    }
  };

  const handleSearchBarChange = (e) => {
    const textToSearch = e?.target?.value;
    setSearchText(textToSearch);
  };

  const handleReloadTemplateProcedureClick = () => {
    dispatch(fetchTemplateProcedures(searchPayload));
    setIsStudioOpen(false);
  }

  const handleClose = (e) => {
    isStudioOpen && handleReloadTemplateProcedureClick();
    onClose()
  }

  return (
    <Box
      component={'div'}
      sx={{
        border: '1px solid #D9D9D9',
        width: isStepViewerOpen ? '500px' : 0,
        // transition: 'all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1)',
        display: isStepViewerOpen ? 'block' : 'none',
      }}
    >
      {/* ----- header start */}
      <Box
        component={'div'}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          fontWeight: 600,
          padding: '20px',
        }}
      >
        <div>
          <Box component={'span'}>All Steps ({procedureDetails?.stepNames?.length})</Box>
        </div>
        <Box component={'div'} sx={{ display: 'flex' }}>
          {isStudioOpen ? (
            <Box
              component={'span'}
              sx={{
                padding: '0 20px',
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                cursor: 'pointer',
              }}
              onClick={handleReloadTemplateProcedureClick}
            >
              <Box
                component={'span'}
                sx={{
                  fontWeight: 600,
                  color: theme.palette.primary.main,
                }}
              >
                Refresh
              </Box>{' '}
              <Replay />
            </Box>
          ) : (
            <Box
              component={'span'}
              sx={{
                padding: '0 20px',
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                cursor: 'pointer',
              }}
              onClick={handleOpenTemplateProcedureClick}
            >
              <Box
                component={'span'}
                sx={{
                  fontWeight: 600,
                  color: theme.palette.primary.main,
                }}
              >
                Open
              </Box>{' '}
              <TrendingFlatIcon />
            </Box>
          )}
          <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
        </Box>
      </Box>
      {/* ----- header end -----*/}

      {/* ----- search bar start -----*/}
      <Box
        component={'div'}
        sx={{
          display: 'flex',
          gap: '5px',
          flexDirection: 'column',
          padding: '0 20px',
        }}
      >
        <SearchBar onChangeHandler={handleSearchBarChange} />
      </Box>
      {/* ----- search bar end -----*/}

      {/* ----- steps start ----- */}
      <Box
        component={'div'}
        sx={{
          display: 'flex',
          gap: '5px',
          flexDirection: 'column',
          padding: '20px',
        }}
      >
        {procedureDetails?.stepNames?.length ? (
          procedureDetails?.stepNames?.map((each, index) => {
            if (searchText && each.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) === -1) return null;
            return (
              <Box
                component={'div'}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '15px 20px',
                  backgroundColor: theme.palette.primary.main + '1c',
                  borderRadius: '8px',
                  cursor: 'pointer',
                  '&:hover': {
                    svg: {
                      visibility: 'unset',
                    },
                  },
                }}
                key={'procedure-step-' + index}
              >
                <Box
                  component={'span'}
                  sx={{
                    fontWeight: 600,
                  }}
                >
                  {each}
                </Box>
                {/* // todo: click should the step in the studio  */}
                {/* <TrendingFlatIcon sx={{ visibility: 'hidden' }} /> */}
              </Box>
            );
          })
        ) : (
          <Box component={'div'} sx={{}}>
            {' '}
            No Steps Added{' '}
          </Box>
        )}
      </Box>
      {/* ----- steps end ----- */}
    </Box>
  );
};

export default ProcedureStepsViewer;
