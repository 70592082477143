import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect } from 'react';
import { Box, IconButton, styled, useTheme } from '@mui/material';
import TrainerService from '../../../../services/trainer/TrainerService';
import { useNavigate } from 'react-router-dom';
import Loading from '../../../Loading/Loading';
import './DeleteWorkInstruction.scss';
import { useTranslation } from 'react-i18next';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import { Toast } from '../../../Notifications/Notification';

interface Props {
  openDialog: boolean;
  closeDialog: () => void;
  id: string;
  isWorkinstruction: boolean;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: '24px',
  },
  '& .MuiDialogActions-root': {
    padding: '0px 24px 24px 24px',
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
  isRecycle?: boolean;
  isDeletePermanently?: boolean;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      className="remove-margin"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '24px !important',
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
            padding: 0,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const DeleteWorkInstruction = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const companyId = localStorage.getItem('company');
  const navigate = useNavigate();
  const [contentReady, setContentReady] = React.useState<boolean>(true);
  const [messageRecycle, setMessageRecycle] = React.useState<string>('');
  const [messageDeletePermanently, setMessageDeletePermanently] = React.useState<string>('');
  const { t } = useTranslation();

  const handleClose = () => {
    props.closeDialog();
    setOpen(false);
  };

  const { refreshProcedure, setRefreshProcedure } = useCurrentUser();
  useEffect(() => {
    setOpen(props.openDialog);
  }, [props.openDialog]);

  useEffect(() => {
    if (props.isWorkinstruction) {
      setMessageRecycle(t('PROCEDURE.DELETE_WI_MESSAGE'));
    } else {
      setMessageRecycle(t('PROCEDURE.DELETE_PROCEDURE_MESSAGE'));
    }
  }, []);

  useEffect(() => {
    if (props.isWorkinstruction) {
      setMessageDeletePermanently(t('PROCEDURE.DELETE_PERMANENTLY_WI_MESSAGE'));
    } else {
      setMessageDeletePermanently(t('PROCEDURE.DELETE_PERMANENTLY_PROCEDURE_MESSAGE'));
    }
  }, []);

  const recyclebinWorkInstruction = (id) => {
    if (props.isWorkinstruction) {
      setContentReady(false);
      TrainerService.recyclebinWorkInstruction(id)
        .then((response) => {
          navigate(`/trainer/home/${companyId}`);
        }).catch(({ response }) => {
          Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        })
        .finally(() => setContentReady(true));
    } else {
      setContentReady(false);
      TrainerService.recyclebinProcedure(id)
        .then((response) => {
          setRefreshProcedure(!refreshProcedure);
          setOpen(props.closeDialog);
        })
        .catch(({ response }) => {
          Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        })
        .finally(() => setContentReady(true));
    }
  };

  const deleteWorkInstruction = (id) => {
    setLoading(true);
    if (props.isWorkinstruction) {
      setContentReady(false);
      TrainerService.deleteWorkInstruction(id)
        .then((response) => {
          navigate(`/trainer/home/${companyId}`);
        }).catch(({ response }) => {
          Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        })
        .finally(() => setContentReady(true));
    } else {
      setContentReady(false);
      TrainerService.deleteProcedure(id)
        .then((response) => {
          setOpen(false);
          setLoading(false);
          setRefreshProcedure(!refreshProcedure);
        }).catch(({ response }) => {
          Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        })
        .finally(() => {
          setOpen(false);
          setLoading(false);
          setContentReady(true);
        });
    }
  };

  const theme = useTheme();

  return (
    <div>
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {props.isWorkinstruction ? t('PROCEDURE.DELETE_WORK_INSTRUCTION') : t('PROCEDURE.DELETE_PROCEDURE')}
        </BootstrapDialogTitle>

        <DialogContent>
          {!contentReady && (
            <Box component="div" className="align-loader">
              <Loading />
            </Box>
          )}
          {props.isDeletePermanently && <DialogContentText sx={{ color: '#000000' }}>{messageDeletePermanently}</DialogContentText>}
          {props.isRecycle && <DialogContentText sx={{ color: '#000000' }}>{messageRecycle}</DialogContentText>}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            {t('BUTTON.CANCEL')}
          </Button>
          {props.isRecycle && (
            <Button variant="contained" onClick={() => recyclebinWorkInstruction(props.id)} autoFocus>
              {t('BUTTON.DELETE')}
            </Button>
          )}
          {props.isDeletePermanently && (
            <Button variant="contained" onClick={() => deleteWorkInstruction(props.id)} autoFocus>
              {t('BUTTON.DELETE')}
            </Button>
          )}
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default DeleteWorkInstruction;
