import { Box, CardContent, Stack, Chip, Typography, FormControlLabel, Skeleton, IconButton, Menu, MenuItem, Grid, Button, Breadcrumbs, Backdrop } from '@mui/material';
import { t } from 'i18next';
import { Tooltip } from 'recharts';
import { StyledCard } from '../../../components/Styled/StyledCard';
import { StyledGridView } from '../../../components/Styled/StyledGridView';
import { StyledSwitch } from '../../../components/Styled/StyledSwitch';
import { useEffect, useState } from 'react';
import TrainerService from '../../../services/trainer/TrainerService';
import { ArrowForward } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link as RouterLink } from 'react-router-dom';
import Link, { LinkProps } from '@mui/material/Link';
import IntegrationCard from './IntegrationCard';
import { useTranslation } from 'react-i18next';
import { PreRequisiteIcon } from '../../../components/Icons/CdsIcons';
import { Toast } from '../../../components/Notifications/Notification';
import Loading from '../../../components/Loading/Loading';

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

const LinkRouter = (props: LinkRouterProps) => <Link {...props} component={RouterLink as any} />;
const IntegrationTypes: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [integrationInstances, setIntegrationInstances] = useState<any>([]);
  const [statusLoading, setStatusLoading] = useState(false);
  const { t } = useTranslation();
  const { id = '' } = useParams();
  const transformText = (text) => {
    if (!text) return '';

    const transformedText = text
      .toLowerCase()
      .replace(/_/g, ' ')
      .split(' ')
      .map((word) => {
        const capitalizedWord = word.charAt(0).toUpperCase() + word.slice(1);
        return capitalizedWord;
      })
      .join(' ');
    return transformedText;
  };
  const breadcrumbs = [
    <LinkRouter underline="hover" key="2" to="/integrations" sx={{ opacity: '0.7' }}>
      {t('BUTTON.INTEGRATIONS')}
    </LinkRouter>,
    <Typography key="3" color="primary.main">
      {t(`BUTTON.LEARNING_MANAGEMENT_SYSTEM`)}
    </Typography>,
  ];
  let navigate = useNavigate();
  const getIntegrationList = () => {
    setLoading(true);
    TrainerService.getIntegrations()
      .then((res) => {
        setLoading(false);
        setIntegrationInstances(res.data.data);
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        setLoading(false);
      });
  };
  useEffect(() => {
    getIntegrationList();
  }, []);
  const handleConnectorNavigation = (connector) => {
    if (connector === 'LEARNING MANAGEENT SYSTEM') {
      navigate(`/integration/${transformText(id)}`);
    }
  };

  const handleAdd = () => {
    navigate(`/integration/connectors/add/${transformText(id)}`);
  };
  const handleEdit = (id, integration) => {
    console.log('integration/connectors/edit/' + id, 'iiiiii');
    navigate(`/integration/connectors/edit/${integration.type}/${transformText(id)}`, {
      state: {
        integration: integration,
      },
    });
  };

  const handleDelete = (id) => {
    setLoading(true);
    TrainerService.deleteIntegrationSystem(id)
      .then((res) => {
        getIntegrationList();
        Toast.success(t('OPERATION_SUCCESS', { ns: 'success' }), t(res.data.messageCode, { ns: 'success' }) || res.data.message);
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        setLoading(false);
      });
  };

  return (
    <>
      {loading && <Loading />}
      <Box component={'div'} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '32px' }}>
        <Breadcrumbs sx={{ margin: '0px 0px 0px 2rem' }} separator={<NavigateNextIcon color="primary" fontSize="small" />} aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
        {integrationInstances?.systems?.length > 0 && (
          <Button variant="contained" onClick={handleAdd} sx={{ marginRight: '32px' }}>
            {t('BUTTON.ADD_NEW_CONNECTOR')}
          </Button>
        )}
      </Box>

      {(!integrationInstances || !integrationInstances?.systems || !(integrationInstances?.systems?.length > 0)) && !loading && (
        <Grid container sx={{ minHeight: '600px', alignItems: 'center' }}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box component="div">
              <PreRequisiteIcon />
            </Box>
            <Box
              component="div"
              sx={{
                fontSize: '1.5rem',
                lineHeight: '1.5',
                margin: '.8rem 0rem',
              }}
            >
              {t('BUTTON.NO_CONNECTORS')}
            </Box>
            <Box component="div" sx={{ color: 'rgba(28, 29, 31, 0.6)', marginBottom: '1.3rem' }}>
              {t('BUTTON.THERE_ARE_NO_CONNECTORS_ADDED')}
            </Box>

            <Button className="proctab-left-margin" onClick={handleAdd} variant="contained">
              + {t('BUTTON.CREATE_CONNECTOR')}
            </Button>
          </Grid>
        </Grid>
      )}

      <StyledGridView>
        {integrationInstances?.systems?.length > 0 &&
          integrationInstances?.systems?.map((integration) => (
            <Grid item xs={4} md={4} lg={4} key={'i'}>
              <IntegrationCard integration={integration} clientId={integrationInstances.clientId} setIntegrationInstances={setIntegrationInstances} handleConnectorNavigation={handleConnectorNavigation} onEdit={handleEdit} onDelete={handleDelete} statusLoading={statusLoading} loading={loading} getIntegrationList={getIntegrationList} />
            </Grid>
          ))}
      </StyledGridView>
    </>
  );
};

export default IntegrationTypes;
