import { ILanguageData } from "../../entities/Languages/Languages";
import {newAxiosInstance as http} from "../http-common"
import i18n from "i18next";

const getLanguages = () => {
  return http.get(`/languages`);
}

const updateLanguage = (languageData: ILanguageData) => {
    return http.put(
      `/languages`,
      languageData
    ).then(response => {
      i18n.changeLanguage(languageData.id)
      return response
    });
}

const LanguageService = {
    getLanguages,
    updateLanguage,
}
export default LanguageService
