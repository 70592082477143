import { useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import * as CommentAction from '../redux/features/Comments/Comments.slice';
import { useTranslation } from 'react-i18next';
import { CanceledError } from 'axios';
import TutorialService from '../services/trainee/TraineeService';

const useLoadEntities = (props: any = {}) => {
  const previousController = useRef<any>(null);
  const dispatch = useAppDispatch();
  const { listStepCommentThreads } = useAppSelector((state) => state.comments);
  const [unreadCountData, setUnreadCountData] = useState<any>(0);
  const [totalCommentCountData, setTotalCommentCountData] = useState<any>(0);
  const [hasNext, setHasNext] = useState<boolean>(true);

  const [totalThreadLength, setTotalThreadLength] = useState(0);

  const [currPage, setCurrPage] = useState(1);
  const [drawerLoading, setDrawerLoading] = useState<boolean>(false);

  const setListStepCommentThreads = (data) => {
    dispatch(CommentAction.setListStepCommentThreads(data));
  };
  const { t } = useTranslation();
  const loadCommentData = (procedureId: string, search = '', page = 1, pageLimit = 20) => {
    // if (previousController.current) {
    //   previousController.current.abort();
    // }
    // const controller = new AbortController();
    // const signal = controller.signal;
    // previousController.current = controller;

    setDrawerLoading(true);
    const data = {
      page: page,
      pageSize: pageLimit,
      searchString: search,
    };

    TutorialService.listStepCommentThreadsPost(procedureId, data)
      .then((e) => {
        setUnreadCountData(e.data?.data?.totalUnreadCount);
        setTotalCommentCountData(e.data?.data?.totalCommentCount);
        setTotalThreadLength(e?.data?.data?.totalThreadCount);
        setDrawerLoading(false);

        if (listStepCommentThreads && page > 1) {
          setListStepCommentThreads([...listStepCommentThreads, ...(e?.data?.data?.data as any)]);
        } else {
          setListStepCommentThreads(e?.data?.data?.data as any);
        }

        setCurrPage(page + 1);
      })
      .catch((error) => {
        setDrawerLoading(false);
        if (error instanceof CanceledError) return;
        // const { response } = error;
        // Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response?.data?.messageCode, { ns: 'error' }) || response?.data?.errorMessage);
      })
      .finally(() => {
        setDrawerLoading(false);
      });
  };

  return {
    loadCommentData,
    drawerLoading,
    currPage,
    setCurrPage,
    listStepCommentThreads,
    setListStepCommentThreads,
    totalThreadLength,
    unreadCountData,
    totalCommentCountData
  };
};

export default useLoadEntities;
