import { Avatar, Box, Typography } from '@mui/material';
import React from 'react';
import '../../TraineeHome.scss';

interface VIEW_SELECTOR_OPTION<T> {
  viewName: T;
  label: string;
  count?: number;
}

interface ViewSelectorProps<T> {
  options: VIEW_SELECTOR_OPTION<T>[];
  currentView: string,
  handleViewSelect: (view: T) => void
}

const ViewSelector = <T,>(props: ViewSelectorProps<T>): React.ReactElement => {
 
  const { options, currentView, handleViewSelect } = props;

  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        alignItems: 'center',
        // justifyContent: 'center',
        gap: '20px',
      }}
    >
      {options.map((option, index) => (
        <Box
          key={index}
          component="div"
          onClick={() => handleViewSelect(option.viewName)}
          sx={{
            display: 'flex',
            gap: '10px',
            borderColor: 'primary.main',
            borderBottom: currentView === option.viewName ? '2px solid' : 'none',
            color: 'primary.main',
            opacity: currentView === option.viewName ? 1 : 0.5,
            cursor: 'pointer',
            padding: option.count ? '10px 0' : 0,
          }}
        >
          <Typography variant="h6" component="div" className="page-title">
            {option.label}
          </Typography>
          {option.count ? (
            <Avatar
              sx={{
                bgcolor: 'grey.300',
                color: 'primary.main',
                height: '32px',
                width: 'auto',
                padding: '0 5px',
                fontWeight: 600,
              }}
              variant="rounded"
            >
              {option.count}
            </Avatar>
          ) : null}
        </Box>
      ))}
    </Box>
  );
};

export default ViewSelector;
