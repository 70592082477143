import { Box, IconButton, styled } from '@mui/material';
import { AppBar, Toolbar, useTheme } from '@mui/material';
import React from 'react';
import { NavLink } from 'react-router-dom';
import './UnAuthenticatedBar.scss';

interface Props {}

const UnAuthenticatedBar: React.FC<Props> = (props) => {
  const theme = useTheme();

  const StyledNavLink = styled(NavLink)({
    color: '#000000',
    '&.active': {
      color: '#FFFFFF',
      backgroundColor: theme.palette.primary.main,
    },
  });

  const Bar = styled('div')({
    '&::after': {
      content: '""',
      background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, white 98.9%)`,
      position: 'absolute',
      left: '16px',
      width: '50%',
      height: '2px',
    },
  });

  return (
    <>
       <AppBar position="relative" className="appBar" elevation={0}>
        <Toolbar className="sign-in-bar">
          <div>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              href="/"
              sx={{ mr: 2 }}
              disableRipple
            >
              <img
                alt="logo"
                className="logo-banner-mini"
                src={'/logos/cds_logo_default.svg'}
              ></img>
            </IconButton>
          </div>
          <Box
            component="div"
            className="right-menu"
            display={{ xs: 'flex', md: 'flex' }}
          >
            {/* <StyledNavLink
							className='sign-in-link'
							to={'/help'}
							style={{ pointerEvents: 'none' }}
						>
							Help
						</StyledNavLink> */}
            {/* <StyledNavLink className="sign-in-link" to={'/'}>
              Sign In
            </StyledNavLink>
            <StyledNavLink  className="sign-in-link" to={'/signup'}>
              Sign Up
            </StyledNavLink> */}
          </Box>
        </Toolbar>
        </AppBar>

      <Bar/>
    </>
  );
};

export default UnAuthenticatedBar;
