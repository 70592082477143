import { Breadcrumbs, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, Link as RouterLink, useParams } from 'react-router-dom';
import { UserGroup } from '../../entities/User/MockUser';
import UserGroupForm from './UserGroupForm';
import Link, { LinkProps } from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import UserGroupService from '../../services/UserGroup/UserGroupService';
import Loading from '../../components/Loading/Loading';
import { getMessage } from '../../shared/utils/message-helpers';
import { Toast } from '../../components/Notifications/Notification';
import { useTranslation } from 'react-i18next';

interface EditUserGroupProps {}

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

const LinkRouter = (props: LinkRouterProps) => <Link {...props} component={RouterLink as any} />;

const EditUserGroup: React.FC<EditUserGroupProps> = (props) => {
  const { t } = useTranslation();

  const breadcrumbs = [
    <Link underline="hover" key="1" sx={{ opacity: '0.7' }}>
      {t('NAVBAR.ADMINISTRATION') as string}
    </Link>,
    <Link underline="hover" key="2" sx={{ opacity: '0.7' }}>
      {t('NAVBAR.USER_MANAGEMENT') as string}
    </Link>,
    <LinkRouter underline="hover" key="3" to="/user-group" sx={{ opacity: '0.7' }}>
      {t('NAVBAR.USER_GROUPS') as string}
    </LinkRouter>,
    <Typography key="4" color="primary.main">
      {t('NAVBAR.EDIT_USER_GROUP') as string}
    </Typography>,
  ];
  const { groupId } = useParams();
  const [group, setGroup] = useState<any>();
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  const editGroupHandler = (groupData: UserGroup, id: string) => {
    UserGroupService.updateUserGroup(id, groupData)
      .then((e) => {
        setLoading(false);
        Toast.success(t('OPERATION_SUCCESS', { ns: 'success' }), t(e.data.messageCode, { ns: 'success' }) || e.data.message);
        backHandler();
      })
      .catch(({ response }) => {
        setLoading(false);
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        console.error('AddUser -> addUserHandler', response);
      });
  };

  const backHandler = () => {
    navigate('/user-group');
  };

  useEffect(() => {
    if (groupId) {
      setLoading(true);
      UserGroupService.getUserGroupsById(groupId)
        .then((res) => {
          setLoading(false);
          setGroup(res.data.data);
        })
        .catch(({ response }) => {
          setLoading(false);
          Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        });
    }
  }, [groupId]);

  return (
    <>
      <Breadcrumbs sx={{ margin: '2rem' }} separator={<NavigateNextIcon color="primary" fontSize="small" />} aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>
      {loading && <Loading />}

      <UserGroupForm onBack={backHandler} onSubmit={editGroupHandler} group={group} />
    </>
  );
};

export default EditUserGroup;
