import moment from "moment";

export const getFormattedDateFromUnixTime = (unixTime: any) => {
    const date = new Date(unixTime * 1000);
    // return new Intl.DateTimeFormat('en-GB', { dateStyle: 'medium' }).format(date);
    return moment(date).format('MMM DD, YYYY');
}
export const getFormattedTimeFromUnixTime = (timestamp) => {
    return moment(timestamp).format('MMM DD, YYYY | hh:mm A').toString();
}
