import { Box, Button, IconButton, styled, Typography } from '@mui/material';
import { AppBar, Toolbar, useTheme } from '@mui/material';
import React from 'react';
import { Navigate, NavLink, useNavigate } from 'react-router-dom';
import useTour from '../../../hooks/tour';

interface Props {
  title?: string;
  step?: any;
  maxStep?: any;
}

const DemoBar: React.FC<Props> = (props) => {
  const theme = useTheme();

  const { isTourOpen, setIsTourOpen, tourStep, setTourStep, setTourTitle } = useTour();
  const navigate = useNavigate();

  return (
    <>
      <AppBar position="relative" className="appBar" elevation={0}>
        <Toolbar className="sign-in-bar" sx={{ backgroundColor: theme.palette.primary.main }}>
          <Box component="div">
            <Typography sx={{ color: 'white' }}>{props.title}</Typography>
          </Box>
          <Box component="div" sx={{backgroundColor: 'rgba(255, 255, 255, 0.1)', borderRadius:'12px', padding:'8px'}}>
            <Typography sx={{ color: 'white', fontSize: '14px' }}>{'Step ' + props.step + ' of ' + ' Step ' + props.maxStep}</Typography>
          </Box>
          <Box component="div" className="right-menu" display={{ xs: 'none', sm: 'none', md: 'flex' }}>
            <Button
              sx={{ color: 'white', border: '1px solid white' }}
              onClick={() => {
                setIsTourOpen(false);
                setTourStep(0);
                setTourTitle('');
                navigate(`/trainer/home/${localStorage.getItem('company')}`);
              }}
            >
              Exit
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default DemoBar;
