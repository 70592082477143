import { createSlice } from "@reduxjs/toolkit";
import { APP_SIDEBARS } from "../../../shared/enums";

type InitialState = {
    sidebarName: APP_SIDEBARS | null
}
const initialState: InitialState = {
    sidebarName: null
}


const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: initialState,
  reducers: {
    setSidebarToOpen: (state, action) => {
      state.sidebarName = action.payload;
    },
  },
});

export default sidebarSlice.reducer;
export const { setSidebarToOpen } = sidebarSlice.actions;