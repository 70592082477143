import { createSlice } from '@reduxjs/toolkit';
import { IRbacUser } from '../../../context/UserContext';
import { USER_TYPES } from '../../../shared/enums';
import { CompanyTheme } from '../../../entities/Trainee/MockTrainee';
interface InitialAppState {
  currentUser: IRbacUser,
  persona: string,
  isTrainer: boolean,
  companyTheme: CompanyTheme | undefined
}
const initialState : InitialAppState = {
  currentUser: {
    id: "",
    userId: "",
    name: "",
    organizationId: "",
    organizationName: "",
    scopes: {},
  },
  persona: "",
  isTrainer: false,
  companyTheme: undefined
};

const userSlice = createSlice({
  name: 'app',
  initialState: initialState,
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    setPersona: (state, action) => {
      state.persona = action.payload;
      if(action.payload === USER_TYPES.TRAINER || action.payload === USER_TYPES.ADMIN){
        state.isTrainer = true;
      }
    },
    setCompanyTheme: (state, action) => {
      state.companyTheme = action.payload;
    }
  },
});

export default userSlice.reducer;
export const { setCurrentUser, setPersona, setCompanyTheme } = userSlice.actions;
