import { Avatar, Box, Button, Grid, Typography } from '@mui/material';
import { FormikProps } from 'formik';
import { useEffect, useState } from 'react';
import { IAppScopeFormValue, IPersonaFormValues } from '.';
import { StyledAvatarRecordCount } from '../../components/Styled/StyledAvatarRecordCount';
import { RESOURCE_ACTIONS } from '../../shared/data/resource-actions';
import AddResources from './AddResources';

interface AssociationFormProps {
  formik: FormikProps<IPersonaFormValues>;
}

const AssociationForm: React.FC<AssociationFormProps> = ({ formik }) => {
  const [isDisable, setIsDisable] = useState<boolean>(false);
  const [resources, setResources] = useState<string[]>([]);

  useEffect(() => {
    const resourceList = Object.keys(RESOURCE_ACTIONS).map((key) => key);
    setResources(resourceList);
  }, []);

  const handleAddNewAppScope = () => {
    const appScopesFromForm = [...formik.values.associationForm.appScopes];
    let newResource = resources[0];
    if (appScopesFromForm.length > 0) {
      newResource = '';
      const appScopesResources = appScopesFromForm
        .map((ac) => ac.resourceName)
        .filter((ac) => ac);
      for (let i = 0; i < resources.length; i++) {
        if (!appScopesResources.includes(resources[i])) {
          newResource = resources[i];
          break;
        }
      }
    }
    if (newResource) {
      appScopesFromForm.push({
        resourceName: newResource,
        actions: [],
      });
      formik.setFieldValue('associationForm.appScopes', appScopesFromForm);
    } else {
      // All the resources have been added, nothing else to add, disable Add button
      setIsDisable(true);
    }
  };

  const handleResourceChange = (
    appScopeFormValue: IAppScopeFormValue,
    index: number,
  ) => {
    const valid = appScopeFormValue.actions.length > 0;
    setIsDisable(!valid);
    const appScopesFromForm = [...formik.values.associationForm.appScopes];
    appScopesFromForm[index] = appScopeFormValue;
    formik.setFieldValue('associationForm.appScopes', appScopesFromForm);
  };

  const handleResourceDelete = (
    appScopeFormValue: IAppScopeFormValue,
    index: number,
  ) => {
    const appScopesFromForm = [...formik.values.associationForm.appScopes];
    appScopesFromForm.splice(index, 1);
    formik.setFieldValue('associationForm.appScopes', appScopesFromForm);
  };

  return (
    <Box component={'div'} sx={{ paddingRight: '32px' }}>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          sx={{
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            '&::after': {
              content: '""',
              background:
                'linear-gradient(90deg, #000000 0.71%, rgba(0, 0, 0, 0) 100%)',
              position: 'absolute',
              bottom: 0,
              width: '100%',
              height: '1px',
            },
          }}
        >
          <Typography
            variant="h6"
            component="div"
            className="page-title"
            sx={{ marginRight: '1rem', marginBottom: 2 }}
          >
            Resource Access
          </Typography>
          <Avatar
            sx={{
              bgcolor: 'neutral',
              marginRight: '1rem',
              color: '#000000',
              borderRadius: '15%',
              marginBottom: 2,
            }}
          ></Avatar>
          {formik?.values?.associationForm?.appScopes && (
            <StyledAvatarRecordCount
              sx={{ marginRight: '1rem', marginBottom: 2, color: '#000000' }}
            >
              {formik?.values?.associationForm?.appScopes?.length}
            </StyledAvatarRecordCount>
          )}
          <Button
            variant="contained"
            disabled={isDisable}
            onClick={handleAddNewAppScope}
            sx={{ marginBottom: 2 }}
          >
            Add
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item>
          <Box component={'div'}>
            {formik.values.associationForm.appScopes.map((appScope, index) => (
              <AddResources
                key={index}
                formik={formik}
                index={index}
                appScopes={formik.values.associationForm.appScopes}
                appScope={appScope}
                onDelete={handleResourceDelete}
                onChange={handleResourceChange}
              />
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AssociationForm;
