import { CompanyTheme, Feedback, IProcedures, IUserStats, IUserStatsFinish, IUserStatsNext, IWorkInstructions } from '../../entities/Trainee/MockTrainee';
import { DataResponse } from '../../shared/interfaces';
import http, { partnerInstance } from '../http-common';

const getWorkInstructionsByCompanyId = (companyId: string, page: number, pageSize: number, search?: string, sort?: string, filter?: string, signal?: any) => {
  let url = `/workinstructions/getWorkInstructions?page=${page}&pageSize=${pageSize}`;
  if (search) {
    url = url + `&search=${search}`;
  }
  if (sort) {
    url = url + `&sort=${sort}`;
  }
  if (filter) {
    url = url + `&filter=${filter}`;
  }

  return http.get<DataResponse<{ workInstructions: IWorkInstructions[]; total: number }>>(url, { signal });
};

const getWorkInstructionsWithRecertificationDue = (companyId: string, page: number, pageSize: number, search?: string, sort?: string, filter?: string, signal?: any) => {
  let url = `/workinstructions/getWorkInstructionsWithRecertificationDue?page=${page}&pageSize=${pageSize}`;
  if (search) {
    url = url + `&search=${search}`;
  }
  if (sort) {
    url = url + `&sort=${sort}`;
  }
  if (filter) {
    url = url + `&filter=${filter}`;
  }

  return http.get<DataResponse<{ workInstructions: IWorkInstructions[]; total: number }>>(url, { signal });
};

const getWorkInstructionByCompanyIdInRecycleBin = (companyId: string, page: number, pageSize: number, search?: string, sort?: string, filter?: string, signal?: any) => {
  let url = `/recyclebin/workInstructions/list?page=${page}&pageSize=${pageSize}`;
  if (search) {
    url = url + `&search=${search}`;
  }
  if (sort) {
    url = url + `&sort=${sort}`;
  }
  if (filter) {
    url = url + `&filter=${filter}`;
  }
  return http.get<DataResponse<{ workInstructions: IWorkInstructions[]; total: number }>>(url, { signal });
};

const getWorkInstructionsById = (id?: string, accessToken?: string | null) => {
  let instance = http;
  let url = '';
  if (accessToken) {
    instance = partnerInstance(accessToken);
    url = '/partnerapps/workinstruction';
  } else {
    url = `/workinstructions/getWorkInstruction/${id}`;
  }
  return instance.get<DataResponse<IWorkInstructions>>(url);
};

const getWorkInstructionBasedOnDeleteProcedure = (companyId: string, page: number, pageSize: number, search?: string, sort?: string, filter?: string, signal?: any) => {
  let url = `/recyclebin/procedures/list?page=${page}&pageSize=${pageSize}`;
  if (search) {
    url = url + `&search=${search}`;
  }
  if (sort) {
    url = url + `&sort=${sort}`;
  }
  if (filter) {
    url = url + `&filter=${filter}`;
  }
  return http.get<DataResponse<{ workInstructions: IWorkInstructions[]; total: number; procedureCount: number }>>(url, { signal });
};

const getProcedure = (procId?: string, accessToken?: string | null) => {
  let instance = http;
  let url = '';
  if (accessToken) {
    instance = partnerInstance(accessToken);
    url = '/partnerapps/procedure';
  } else {
    url = `/procedures/getProcedure/${procId}`;
  }
  return instance.get<DataResponse<IProcedures>>(url);
};

const getTasks = (workInstructionId?: string, procId?: string, versionId?: string | null, accessToken?: string | null) => {
  let url = '';
  let instance = http;
  if (accessToken) {
    url = '/partnerapps/cdsp';
    instance = partnerInstance(accessToken);
  } else {
    if (versionId) url = `/tasks/v2/getAllTasks/${workInstructionId}/${procId}?version=${versionId}`;
    else url = `/tasks/v2/getAllTasks/${workInstructionId}/${procId}`;
  }
  return instance.get<DataResponse<any>>(url);
};

const getProcedures = (workInstructionId: string) => {
  return http.get<any>(`/procedures/getProcedures/${workInstructionId}`);
};

const getProceduresBin = (workInstructionId: string) => {
  return http.get<any>(`/recyclebin/procedures/list/${workInstructionId}`);
};

// ! Not used anywhere
// const getReferenceDocument = (workInstructionId: string, procedureId: string, fileId: string) => {
//   return http.get<any>(`/file/getFile/${workInstructionId}/${procedureId}/${fileId}`, {
//     responseType: 'arraybuffer',
//     headers: {
//       Accept: 'application/pdf',
//     },
//   });
// };

const getTheme = (comapnyId: string | null, accessToken?: string | null) => {
  let instance = http;
  let url = '';
  if (accessToken) {
    instance = partnerInstance(accessToken);
    url = '/partnerapps/theme';
  } else {
    url = '/organization/theme';
  }
  return instance.get<DataResponse<CompanyTheme>>(url);
};

const getProceedureById = (id: string) => {
  return http.get<DataResponse<IProcedures>>(`/workinstructions/proceedure/${id}`);
};

const getFeedback = (procedureId: string) => {
  return http.get<DataResponse<Array<Feedback>>>(`/procedures/feedback/filter/procedure/${procedureId}`);
};

const createFeedback = (feedback: Feedback) => {
  return http.post<DataResponse<Feedback>>(`/procedures/feedback`, feedback);
};

const startUserStats = (workInstructionId: string, procedureId: string) => {
  const url = `/userStats/start/${workInstructionId}/${procedureId}`;
  return http.put<DataResponse<IUserStats>>(url, {});
};

const nextUserStats = (workInstructionId: string, procedureId: string, data: IUserStatsNext) => {
  const url = `/userStats/next/${workInstructionId}/${procedureId}`;
  return http.put<DataResponse<IUserStats>>(url, data);
};

const getElapsedTime = (workInstructionId: string, procedureId: string, stepIndex: any) => {
  const url = `/userStats/getElapsedTime/${workInstructionId}/${procedureId}/${stepIndex}`;
  return http.get<any>(url);
};

const updateElapsedTime = (workInstructionId: string, procedureId: string, stepIndex: any, elapsedSeconds: any) => {
  return http.post<DataResponse<any>>(`/userStats/updateElapsedTime/${workInstructionId}/${procedureId}/${stepIndex}`, { elapsedTime: elapsedSeconds });
};

const addCommentToStep = (procedureId: string, stepIndex: any, data: any) => {
  return http.post<DataResponse<any>>(`/userStats/addCommentToStep/${procedureId}/${stepIndex}`, data);
};

const addCommentToThread = (threadId: string, data: any) => {
  return http.post<DataResponse<any>>(`/userStats/addCommentToStep/${threadId}`, data);
};

const listStepCommentThreadsPost = (procedureId: string,data: any) => {
  const url = `/userstats/listStepCommentThreads/${procedureId}`;
  return http.post<DataResponse<any>>(url,data);
};

const listStepCommentThreads = (procedureId: string) => {
  const url = `/userstats/listStepCommentThreads/${procedureId}`;
  return http.get<DataResponse<any>>(url);
};


const notificationList = () => {
  const url = `/notifications/notifications`;
  return http.get<DataResponse<any>>(url);
};

const listStepComments = (threadId: string) => {
  const url = `/userstats/listStepComments/${threadId}`;
  return http.get<DataResponse<any>>(url);
};

const deleteStepComment = (threadId: string, commentId: string) => {
  const url = `/userstats/deleteStepComment/${threadId}/${commentId}`;
  return http.delete<any>(url);
};

const finishUserStats = (workInstructionId: string, procedureId: string, data: IUserStatsFinish) => {
  const url = `/userStats/finish/${workInstructionId}/${procedureId}`;
  return http.put<DataResponse<IUserStats>>(url, data);
};

const getUserStats = (workInstructionId: string, procedureId: string) => {
  const url = `/userStats/userStats/${workInstructionId}/${procedureId}`;
  return http.get<DataResponse<Array<IUserStats>>>(url);
};

const restoreBinProcedures = (workInstructionId: string) => {
  const url = `/recyclebin/restore/workInstruction/${workInstructionId}`;
  return http.patch<DataResponse<IWorkInstructions>>(url, {});
};

const restoreBinProcedure = (procedureId: string) => {
  const url = `recyclebin/restore/procedure/${procedureId}`;
  return http.patch<DataResponse<IProcedures>>(url, {});
};

const sendToBinProcedure = (workInstructionId: string) => {
  const url = `recyclebin/workInstructions/deleteWorkInstruction/${workInstructionId}?bin=true`;
  return http.patch<DataResponse<IWorkInstructions>>(url, {});
};

const signOff = (versionId, userId, historyIndex, signOffBody) => {
  const url = `/userstats/signOff/${versionId}/${userId}/${historyIndex}`;
  return http.post<DataResponse<any>>(url, signOffBody);
};

const signOffPdf = (versionId: string, userId: string, historyIndex: number) => {
  const url = `/analytics/procedure/trainingDoc/${versionId}/${userId}/${historyIndex}`;
  return http.get<ArrayBuffer>(url, { responseType: 'arraybuffer' });
};

const TutorialService = {
  signOff,
  signOffPdf,
  getWorkInstructionsByCompanyId,
  getWorkInstructionsWithRecertificationDue,
  getWorkInstructionByCompanyIdInRecycleBin,
  getWorkInstructionBasedOnDeleteProcedure,
  getProcedures,
  restoreBinProcedure,
  restoreBinProcedures,
  sendToBinProcedure,
  getProceduresBin,
  getWorkInstructionsById,
  getTheme,
  getProcedure,
  getProceedureById,
  getTasks,
  getFeedback,
  createFeedback,
  startUserStats,
  nextUserStats,
  finishUserStats,
  getUserStats,
  // getReferenceDocument,
  getElapsedTime,
  updateElapsedTime,
  addCommentToStep,
  addCommentToThread,
  listStepCommentThreads,
  listStepComments,
  notificationList,
  deleteStepComment,
  listStepCommentThreadsPost 
};

export default TutorialService;

