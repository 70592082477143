import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ReactToPrint from 'react-to-print';
import { Form, Formik } from 'formik';
import Loading from '../../../Loading/Loading';
import SuccessDialog from '../SuccessDialog/SuccessDialog';
import * as yup from 'yup';
import './ShareQR.scss';
import { Avatar, Box, Checkbox, FormControlLabel, Grid, Input, InputLabel, Tab, Tabs, TextField, Typography, useTheme } from '@mui/material';
import { useEffect } from 'react';
import QRCode from 'react-qr-code';
import { environment } from '../../../../environment/environment';
import TrainerService from '../../../../services/trainer/TrainerService';
import { getMessage } from '../../../../shared/utils/message-helpers';
import { Toast } from '../../../Notifications/Notification';
import { Buffer } from 'buffer';
import { useTranslation } from 'react-i18next';
import { SendIcon } from '../../../Icons/CdsIcons';
import PrintIcon from '@mui/icons-material/Print';

var psk = 'QeO070WNsctf7X4XpTU93WWk6XZVq9Ti';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

interface Props {
  openDialog: boolean;
  closeDialog: () => void;
  userList1?: any;
  procedure?:any;
  workInstruction?: any;
}

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, minWidth: 600 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
type qrProps = {
  componentToPrint: React.MutableRefObject<null>;
  value: any;
};
export const QR = (props: qrProps) => {
  const { componentToPrint } = props;
  const { t } = useTranslation();
  return (
    <>
      <div className="print-component" ref={(el: any) => (componentToPrint.current = el)}>
        <div className="qr-title">{t('ANALYTICS_SECTION.SCAN_QR_CODE')}</div>
        <Box component="div" sx={{ borderColor: 'divider' }}>
          <Box component="div" className="qr-align">
            <QRCode value={props.value} bgColor={'#FFFFFF'} fgColor={'black'} size={262} className="qq" />
          </Box>
        </Box>
      </div>
    </>
  );
};

export default function AssignUserDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [showDialog, setShowDialog] = React.useState<boolean>(false);
  const theme = useTheme();
  const [loading, setLoading] = React.useState(false);
  const [copySuccess, setCopySuccess] = React.useState(false);
  const [value, setValue] = React.useState('');
  const [procedureLink, setProcedureLink] = React.useState('');
  const [back, setBack] = React.useState('#FFFFFF');
  const [fore, setFore] = React.useState('#000000');
  const [size, setSize] = React.useState(256);
  const [tabValue, setTabValue] = React.useState(0);

  useEffect(() => {
    if (props?.procedure?.tasks_count > 0 && props.openDialog) {
      setValue(`${psk}/${props?.procedure?.organizationId}/${props?.procedure?.workInstructionId}/${props?.procedure?.procedureId}`);
      setProcedureLink(`${environment.AppConfigs.redirectUrl}/assembly/${props?.procedure?.organizationId}/${props?.procedure?.workInstructionId}/${props?.procedure?.procedureId}?workInstructionType=${props.workInstruction?.is2d ? '2d' : '3d'}`);
    }
    setOpen(props.openDialog);
  }, [props.openDialog, props.procedure, props.workInstruction]);

  const handleClose = () => {
    props.closeDialog();
    setOpen(false);
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  const shareQR = (email) => {
    setLoading(true);

    const body = {
      url: procedureLink,
      qrCodeUrl: procedureLink,
      procedureId: props?.procedure?.procedureId,
      userEmail: email,
    };
    TrainerService.shareQR(body)
      .then((res) => {
        setLoading(false);
        handleClose();
        setShowDialog(true);
      })
      .catch(({ response }) => {
        setLoading(false);
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
      });
  };

  const closeCurrentDialog = () => {
    props.closeDialog();
    setOpen(false);
    setShowDialog(true);
  };
  const componentToPrint = React.useRef(null);
  const { t } = useTranslation();

  const validationSchema = yup.object({
    email: yup.string().email(t('PROCEDURE.ENTER_VALID_EMAIL')).required(t('PROCEDURE.EMAIL_Is_REQUIRED')),
  });

  const updateStyles = {
    color: theme.palette.primary.main,
    opacity: '0.5',
    '&.Mui-selected': {
      color: theme.palette.primary.main,
      opacity: '1',
    },
  };

  const handleTooltipClose = () => {
    setCopySuccess(false);
  };
  const copyToClipBoard = async (copyText: string) => {
    try {
      await navigator.clipboard.writeText(copyText);
      setCopySuccess(true);
      setTimeout(() => handleTooltipClose(), 1000);
    } catch (err) {
      setCopySuccess(false);
    }
  };

  const handleChange = (e: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const MagicLinkTab = () => {
    const [loading, setLoading] = React.useState(false);
    const [magicLink, setMagicLink] = React.useState<string>('');
    const [magicIframe, setMagicIframe] = React.useState<string>('');
    const [copyMagicLink, setCopyMagicLink] = React.useState(false);
    const [copyMagicIFrame, setCopyMagicIFrame] = React.useState(false);

    const copyMagicLinkClipboard = async (copyText: string) => {
      await navigator.clipboard.writeText(copyText);
      setCopyMagicLink(true);
      setTimeout(() => setCopyMagicLink(false), 1000);
    };
    const copyMagicIframeClipboard = async (copyText: string) => {
      await navigator.clipboard.writeText(copyText);
      setCopyMagicIFrame(true);
      setTimeout(() => setCopyMagicIFrame(false), 1000);
    };

    useEffect(() => {
      getMagicLinkAccessToken();
    }, [props?.procedure?.procedureId]);

    const getMagicLinkAccessToken = () => {
      if (!magicLink) {
        setLoading(true);
        TrainerService.getMagicLink(props?.procedure?.procedureId)
          .then((e) => {
            setLoading(false);
            setMagicLink(e.data.data.link + `&workInstructionType=${props.workInstruction?.is2d ? '2d' : '3d'}` + `&cdsInternalApiKey=${environment.AppConfigs.cdsInternalApiKey}`);
            setMagicIframe(`<iframe\nwidth="500" height="500" \nsrc="${e.data.data.link + `&workInstructionType=${props.workInstruction?.is2d ? '2d' : '3d'}`}"\n/>`);
          })
          .catch(({ response }) => {
            Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
            setLoading(false);
          });
      }
    };
    const IframeComponent = () => {
      const htmlCodeLine1 = `<iframe`;
      const htmlCodeLine2 = `width="500" height="500"`;
      const htmlCodeLine3 = `src=${magicLink}`;
      const htmlCodeLine4 = `/>`;

      return (
        <>
          {!loading && (
            <Box
              component="div"
              sx={{
                background: (theme) => theme.palette.primary.main + '1c',
                wordBreak: 'break-all',
                maxHeight: '150px',
                overflowY: 'scroll',
                borderRadius: '8px',
                padding: '4px',
              }}
            >
              <Typography sx={{ fontSize: '0.75rem', padding: '4px' }}>{htmlCodeLine1}</Typography>
              <Typography sx={{ fontSize: '0.75rem', marginLeft: '30px', padding: '4px' }}>{htmlCodeLine2}</Typography>
              <Box component="div" sx={{ marginLeft: '30px', fontSize: '0.75rem', padding: '4px' }}>
                <Typography sx={{ fontSize: '0.75rem', padding: '4px' }}>{htmlCodeLine3}</Typography>
              </Box>
              <Typography sx={{ fontSize: '0.75rem', padding: '4px' }}>{htmlCodeLine4}</Typography>
            </Box>
          )}
        </>
      );
    };

    return (
      <>
        {loading && <Loading />}
        <Box
          component="div"
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
          }}
        >
          <InputLabel sx={{ mb: 1, ml: 1, color: 'black' }}>{t('PROCEDURE.EMBED_PROCEDURE_LINK')}</InputLabel>
          <div className="copy-procedure-link">
            <TextField
              variant="standard"
              className="procedure-link procedure-link-magic"
              InputProps={{
                disableUnderline: true,
                inputComponent: IframeComponent,
                
              }}
            />
            <Tooltip
              title={t('PROCEDURE.COPIED') + '!'}
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleTooltipClose}
              open={copyMagicIFrame}
              disableFocusListener
              disableHoverListener
              disableTouchListener
            >
              <Button size="small" onClick={() => copyMagicIframeClipboard(magicIframe)} aria-label="close" variant="contained">
                {t('PROCEDURE.COPY')}
                <ContentCopyIcon fontSize="small" sx={{ margin: '0px 6px' }} />
              </Button>
            </Tooltip>
          </div>
          <InputLabel sx={{ mb: 1, ml: 1, color: 'black' }}>{t('PROCEDURE.PROCEDURE_LINK')}</InputLabel>
          <Box component="div" className="copy-procedure-link-bottom">
            <Input
              className="procedure-link procedure-link-magic"
              sx={{
                display: '-webkit-box',
                background: (theme) => theme.palette.primary.main + '1c',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
                wordBreak: 'break-all',
                margin: '8px',
              }}
              value={magicLink}
            />
            <Tooltip
              title={t('PROCEDURE.COPIED') + '!'}
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleTooltipClose}
              open={copyMagicLink}
              disableFocusListener
              disableHoverListener
              disableTouchListener
            >
              <Button size="small" onClick={() => copyMagicLinkClipboard(magicLink)} aria-label="close" variant="contained">
                {t('PROCEDURE.COPY')}
                <ContentCopyIcon fontSize="small" sx={{ margin: '0px 6px' }} />
              </Button>
            </Tooltip>
          </Box>
          <Typography sx={{ fontSize: '12px', ml: 1 }}>{t('PROCEDURE.NO_LOGIN_REQUIRED')}</Typography>
        </Box>
      </>
    );
  };
  const TraineesTab = () => {
    return (
      <>
        <Formik
          initialValues={{ email: '' }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            shareQR(values.email);
          }}
        >
          {(props) => (
            <Form>
              <Box component="div" className="dialog-content">
                <div className="left">
                  {t('PROCEDURE.PROCEDURE_LINK')}
                  <div className="copy-procedure-link">
                    <Input
                      className="procedure-link"
                      sx={{
                        display: '-webkit-box',
                        background: (theme) => theme.palette.primary.main + '1c',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 1,
                        wordBreak: 'break-all',
                        margin: '8px',
                      }}
                      value={procedureLink}
                    />
                    <Tooltip
                      title={t('PROCEDURE.COPIED') + '!'}
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={handleTooltipClose}
                      open={copySuccess}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                    >
                      <Button size="small" onClick={() => copyToClipBoard(procedureLink)} aria-label="close" variant="contained">
                        {t('PROCEDURE.COPY')}
                        <ContentCopyIcon fontSize="small" sx={{ margin: '0px 6px' }} />
                      </Button>
                    </Tooltip>
                  </div>
                  <InputLabel sx={{ mb: 1, color: 'black' }}>{t('PROCEDURE.SHARE_VIA_EMAIL')}</InputLabel>
                  <Box component="div" sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <TextField
                      id="outlined-basic"
                      className="procedure-link"
                      placeholder={t('PROCEDURE.ENTER_HERE')}
                      variant="outlined"
                      name="email"
                      onChange={(event) => props.setFieldValue('email', event.target.value)}
                      error={props.touched?.email && (props.errors?.email ? true : false)}
                      helperText={props.touched?.email && props.errors?.email ? `${props.errors?.email}` : ''}
                      sx={{
                        width: '100%',
                      }}
                    />

                    <Button size="small" type="submit" sx={{ height: '35px', marginTop: '10px' }} aria-label="close" variant="contained">
                      {t('BUTTON.SEND')} {/* <Box component="div" sx={{ marginLeft: '6px' }}> */}
                      <SendIcon />
                      {/* </Box> */}
                    </Button>
                  </Box>
                </div>
                <Box component="div" className="right-qr-code" sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginLeft: '15px', paddingLeft: '15px', borderImageSource: `linear-gradient(to bottom,white, ${theme.palette.primary.main}, white) !important` }}>
                  <Box component="div" sx={{ borderColor: 'divider' }}>
                    <Box component="div" className="qr-align">
                      <QRCode value={procedureLink} bgColor={'#FFFFFF'} fgColor={'black'} size={116} />
                    </Box>
                  </Box>
                  <QR componentToPrint={componentToPrint} value={procedureLink} />
                  <ReactToPrint
                    trigger={() => (
                      <Button size="small" variant="outlined">
                        {t('BUTTON.PRINT_QR_CODE')}
                        <PrintIcon sx={{ marginLeft: '4px' }} />
                      </Button>
                    )}
                    content={() => componentToPrint.current}
                    documentTitle={'Scan QR code to open this procedure'}
                  />
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </>
    );
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        scroll="paper"
        className="share-qr-dialog"
        PaperProps={{
          sx: {
            padding: '24px',
          },
        }}
      >
        {loading && <Loading />}

        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {t('PROCEDURE.SHARE_PROCEDURE')}
          {/* {props.errors[0]} */}
        </BootstrapDialogTitle>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            borderBottom: '2px solid transparent',
            borderImage: `linear-gradient(0.25turn, ${theme.palette.primary.main},white)`,
            borderImageSlice: 1,
          }}
          TabIndicatorProps={{
            style: {
              display: 'none',
            },
          }}
        >
          <Tab
            sx={{
              ...updateStyles,
            }}
            label={t('PROCEDURE.TRAINEES')}
          />
          <Tab
            sx={{
              ...updateStyles,
            }}
            label={t('PROCEDURE.EMBED')}
          />
          <Tab
            sx={{
              ...updateStyles,
            }}
            label={t('PROCEDURE.GUESTS')}
            disabled={true}
          />
          <Tab
            sx={{
              ...updateStyles,
            }}
            label={t('PROCEDURE.AR/MR')}
            disabled={true}
          />
        </Tabs>
        <DialogContent
          className="dialog-content"
          dividers
          sx={{
            borderTop: 'none',
            marginTop: '24px',
            padding: '24px',
            borderRadius: '8px',
            backgroundColor: theme.palette.primary.main + '1c',
          }}
        >
          {tabValue === 0 && <TraineesTab />}
          {tabValue === 1 && <MagicLinkTab />}
        </DialogContent>
      </BootstrapDialog>

      {showDialog && <SuccessDialog closeDialog={closeDialog} message={t('PROCEDURE.PROCEDURE_SHARED') + '!'} />}
    </div>
  );
}
