import React from 'react';
import { createContext, Dispatch } from 'react';

const tourFn: Dispatch<React.SetStateAction<any>> = () => {};

const TourContext = createContext({
  isTourOpen: false,
  tourStep: 0,
  setTourStep: tourFn,
  setIsTourOpen: tourFn,
  tourTitle: '',
  setTourTitle: tourFn,
  tourSteps: [],
  setTourSteps: tourFn,
  createWiTour: true,
  setCreateWiTour: tourFn,
  tourGuide: {
    CREATE_WORK_INSTRUCTION: false,
    CREATE_PROCEDURE: false,
    ADD_STEPS_IN_STUDIO: false,
    PUBLISH: false,
    ASSIGN: false,
    TRAINING: false,
  },
  setTourGuide: tourFn,
  isDeletedWorkInstructionBackButton: false,
  setIsDeletedWorkInstructionBackButton: tourFn,
});

export default TourContext;
