import React from 'react';
import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, LabelList, Cell, ResponsiveContainer, CartesianGrid } from 'recharts';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { IAssignedTraineeRecord } from '../../../../entities/Analytics/AssignedTraineeRecord';

import './TraineeAssignedCard.scss';
import { useDeviceResponsiveSize } from '../../../../hooks/useDeviceResponsiveSize';
import { useTranslation } from 'react-i18next';
import { Feedback } from '../../ProcedureReports/Feedback';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import { IDateRange } from '../../../../entities/Analytics/AnalyticsProcedure';
import { SelectionType } from '../../../../states/analytics';

interface TraineeAssignedCardProps {
  data: any;
  onCardClick: (userId: string) => void;
  enableClickEvent?: boolean;
  dateRange: IDateRange;
  filterType: SelectionType;
}

const TraineeAssignedCard: React.FC<TraineeAssignedCardProps> = ({ data, onCardClick, enableClickEvent = true, dateRange, filterType }) => {
  const responsiveSize = useDeviceResponsiveSize();
  const COLORS = ['#47D897', '#FFB349', '#D84747'];
  const { t } = useTranslation();

  const chartData = [
    { name: t('COMMON.WORKINSTRUCTION_TRAINEE_STATUS_COMPLETED'), value: data[0].value },
    { name: t('COMMON.WORKINSTRUCTION_TRAINEE_STATUS_IN_PROGRESS'), value: data[0].value },
    { name: t('COMMON.WORKINSTRUCTION_TRAINEE_STATUS_NOT_STARTED'), value: data[0].value },
  ];
  const legendData = [{ value: data.completed }, { value: data.in_progress }, { value: data.not_started }];

  const renderColorfulLegendText = (value: string, entry: any) => {
    const {
      color,
      payload: { value: count },
    } = entry;
    return <span style={{ color }}>{count}</span>;
  };

  const handleOnCardClick = () => {
    if (enableClickEvent) {
      onCardClick(data.user_id);
    }
  };

  const navigate = useNavigate();

  const handleGotoAllTraineesAssigedList = (name) => {
    const mapProgress = {
      'Not Started': 'notStarted',
      Completed: 'completed',
      'In Progress': 'inProgress',
    };
    navigate(`/analytics/trainees/assigned?filter=${filterType}&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}&progress=${mapProgress[name]}`);
  };

  const theme = useTheme();
  const renderCustomizedLabel = (props) => {
    const { x, y, width, height, value, name } = props;

    const fireOffset = value.toString().length < 5;
    const offset = fireOffset ? -40 : 5;

    return (
      <text onClick={() => handleGotoAllTraineesAssigedList(name)} style={{ fontSize: '14px', fill: '#004b8d', marginLeft: '100px', paddingLeft: '100px', cursor: 'pointer' }} x={x + width - offset - 30} y={y + height - 12} fill={fireOffset ? 'black' : 'black'}>
        {name + ': ' + value} {value !== 1 ? 'Trainees \u2b62' : `Trainee \u2b62`}
      </text>
    );
  };

  const renderCustomizedLabelName = (props) => {
    const { x, y, width, height, value } = props;

    return (
      <text x={x} y={y} dy={-10} textAnchor="start" style={{ fontSize: '12px', marginBottom: '12px' }}>
        {value}
      </text>
    );
  };

  interface CustomTooltipProps {
    active?: any;
    payload?: any;
    label?: any;
  }

  const CustomTooltip = ({ active, payload, label }: CustomTooltipProps) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">
            {`${payload[0]?.payload?.name}: `}
            {`${payload[0]?.payload?.value} Trainees`}
          </p>
        </div>
      );
    }

    return null;
  };

  const barColors = [
    { start: '#59BFAB', end: '#ACE8DA' },
    { start: '#DAE187', end: '#EAEDCB' },
    { start: '#E47D62', end: '#E9B9AD' },
  ];

  return (
    <>
      <ResponsiveContainer width={'100%'}>
        <BarChart className={'outline'} data={data} layout="vertical" barCategoryGap={1} barSize={36} margin={{ right: 190, top: 0 }}>
          <XAxis type="number" hide />
          <YAxis type="category" dataKey="name" tickLine={false} hide tick={{ fontSize: '12px' }} />
          <defs>
            {barColors.map((color, index) => (
              <linearGradient key={index} id={`colorUv${index}`} x1="0" y1="0" x2="100%" y2="0" spreadMethod="reflect">
                <stop offset="0" stopColor={color.start} />
                <stop offset="1" stopColor={color.end} />
              </linearGradient>
            ))}
          </defs>
          <Bar isAnimationActive={false} dataKey="value" radius={[0, 6, 6, 0]}>
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={`url(#colorUv${index})`} />
            ))}
            <LabelList dataKey="value" position="right" content={renderCustomizedLabel} fill="black" />
          </Bar>
          <Tooltip cursor={{ fill: 'transparent' }} wrapperStyle={{ outline: 'none' }} content={<CustomTooltip />} />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default TraineeAssignedCard;
