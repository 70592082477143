import { environment } from "./environment/environment";

export const getMsalConfig = (opco, email) => {
  let authority = `https://${environment.AppConfigs.tenantAuthority}/${environment.AppConfigs.tenant}/${environment.AppConfigs.b2cPolicy}`;

  if(opco && opco.length > 0){
     authority += '-OPCO';
  }

    const msalConfig = {
      auth: {
        clientId: environment.AppConfigs.clientId,
        authority: authority,
        redirectUri: environment.AppConfigs.redirectUrl,
        postLogoutRedirectUri: environment.AppConfigs.redirectUrl,
        knownAuthorities: [environment.AppConfigs.tenantAuthority],
      },
      cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
      }
    };

    return msalConfig;
}

export const msalConfig = {
    auth: {
      clientId: environment.AppConfigs.clientId,
      authority: `https://${environment.AppConfigs.tenantAuthority}/${environment.AppConfigs.tenant}/${environment.AppConfigs.b2cPolicy}-OPCO`,
      redirectUri: environment.AppConfigs.redirectUrl,
      postLogoutRedirectUri: environment.AppConfigs.redirectUrl,
      knownAuthorities: [environment.AppConfigs.tenantAuthority],
    },
    cache: {
      cacheLocation: "localStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };

  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
    scopes: [environment.AppConfigs.clientId,'profile','openid']
  };

  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  /** export const graphConfig = {
      graphMeEndpoint: "Enter_the_Graph_Endpoint_Here/v1.0/me"
  }; */

  export const backendConfig = {
    backendEndpoint: environment.AppConfigs.BackendURL
};
