import { cdsPublish, cdsSubscribe } from "cds-component-library";

export type playStateType = "play" | "pause" | "stop" | "init"
export const setAnimationState:(newState:playStateType)=>void = (newState)=>{
    // use channel 0 since there will always only be a single viewer
    cdsPublish("ANIMATION_ACTION", newState, 0)
}
export const setExplosionState:(newState:playStateType)=>void = (newState)=>{
    // use channel 0 since there will always only be a single viewer
    cdsPublish("EXPLOSION_ACTION", newState, 0)
}
export const setAnimationIndex:(newIndex:number)=>void = (newIndex)=>{
    cdsPublish("ANIMATION_INDEX", JSON.stringify(newIndex), 0)
}

export const setHiddenModels:(hiddenModels:string[])=>void = (hiddenModels)=>{
    cdsPublish("MODEL_HIDE", hiddenModels, 0)
}

export const setCameraViewPoint:(position:any, target:any, zoom:any)=>void = (position, target, zoom)=>{
    // position, target, zoom
    let newCameraPositioning = {
        position:position,
        target:target, 
        zoom:zoom
    }
    cdsPublish("CAMERA_CONTROL", newCameraPositioning, 0)
    
}

export const setAnnotationsVisibility:(newVisibility:boolean)=>void = (newVisibility)=>{
    cdsPublish("ANNOTATIONS_ENABLE", newVisibility, 0)
}

export const setModelViewMode:(mode:"translucent"|"outline"|null)=>void = (mode)=>{
    cdsPublish("MATERIAL_EDGES", false, 0);
    cdsPublish("MATERIAL_TRANSLUCENT", false, 0);
    switch(mode){
        case "translucent":
            cdsPublish("MATERIAL_TRANSLUCENT", true, 0)
            break;
        case "outline":
            cdsPublish("MATERIAL_EDGES", true, 0)
    }
}

export const setViewPointByName:(viewPointName:"front"|"back"|"left"|"right"|"top"|"bottom")=>void = (viewPointName)=>{
    cdsPublish("SET_CAMERA_BY_VIEW_NAME", viewPointName, 0)
}