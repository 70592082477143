import { FolderCopy } from '@mui/icons-material';
import { Box, Button, Grid, Paper, Typography, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import LeftProceduresCard from '../../../components/Cards/Procedures/Left/LeftProceduresCard';
import RightProceduresCard from '../../../components/Cards/Procedures/Right/RightProceduresCard';
import { IProcedures, IWorkInstructions } from '../../../entities/Trainee/MockTrainee';
import TutorialService from '../../../services/trainee/TraineeService';
import TrainerService from '../../../services/trainer/TrainerService';
import { useFileUpload } from '../../../shared/context';
import { LeftArrow } from '../../../components/Icons/CdsIcons';
import './Procedures.scss';
import Loading from '../../../components/Loading/Loading';
import { Toast } from '../../../components/Notifications/Notification';
import { AmplitudeEvents, ProcedureDuplicationStatus, WorkInstructionModelFileUploadStatus, WorkInstructionModelStatus } from '../../../shared/enums';
import { fileSize } from '../../../shared/utils/helpers';
import LinearWithValueLabel from '../../../components/LinearProgressWithLabel/LinearProgressWithLabel';
import FileManagerService from '../../../services/FileManager/FileManagerService';
import { trackEvent } from '../../../App';
import { useTranslation } from 'react-i18next';
import { useDocumentTitle } from '../../../App';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { closeSnackbar } from 'notistack';

interface Props {
  isTrainer?: boolean;
  isDelete?: boolean;
  isRevisitRequest?: boolean;
}

const Procedures: React.FC<Props> = (props) => {
  const timerRef = useRef<NodeJS.Timer>();
  const [workInstruction, setWorkInstruction] = useState<IWorkInstructions>();
  const [procedures, setProcedures] = useState<IProcedures[]>();
  const { companyId, id } = useParams();
  const [cadFileUpload, setCadFileUpload] = useState<any>(null);
  const [pdfFileUpload, setPdfFileUpload] = useState<any>([]);
  const [canUploadFiles, setCanUploadFiles] = useState(false);
  const [status, setStatus] = useState<null | string>(null);
  const [isTaskCreated, setIsTaskCreated] = useState(false);
  const [showInfoMessage, setShowInfoMessage] = useState<boolean>(false);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [isStatusLoading, setIsStatusLoading] = useState(true);
  const [cadFileUploadError, setCadFileUploadError] = useState('');
  const fileUploadDetails = useFileUpload();
  const [cadUploadIsInProgress, setCadUploadIsInProgress] = useState(false);
  const [cadUploadProgress, setCadUploadProgress] = useState(0);
  const [cadUploadProgressStatus, setCadUploadProgressStatus] = useState('');

  const { t } = useTranslation();

  useDocumentTitle('CDS Mentor Procedures');

  const resetMessages = (showInfo: boolean = false, showError: boolean = false) => {
    setShowInfoMessage(showInfo);
    setShowErrorMessage(showError);
  };

  const resetPdfFileUpload = () => {
    fileUploadDetails.dispatch({ type: 'isUploadingPdf', payload: false });
    setPdfFileUpload(null);
    fileUploadDetails.dispatch({
      type: 'clearPdf',
      payload: undefined,
    });
    fileUploadDetails.dispatch({
      type: 'emptyProcedure',
      payload: undefined,
    });
  };

  const resetCadFileUpload = () => {
    fileUploadDetails.dispatch({ type: 'isUploadingCad', payload: false });
    fileUploadDetails.dispatch({
      type: 'clearCad',
      payload: undefined,
    });
    setCadFileUpload(null);
  };

  const initCadFileUploadStatus = () => {
    setCadUploadProgress(0);
    setCadUploadProgressStatus('');
    setCadUploadIsInProgress(true);
  };

  const sendFileMetaData = (modelStatus, fileUploadStatus) => {
    if (workInstruction) {
      let fileData = {
        ...workInstruction,
        status: modelStatus,
        fileStatus: fileUploadStatus,
        file: {
          name: cadFileUpload.name,
          size: cadFileUpload.size,
        },
      };
      TrainerService.editWorkInstruction(fileData.workInstructionId, fileData)
        .then((e) => {
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const cadFileUploadCall = async () => {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        setCadUploadProgress(Math.round((100 * progressEvent.loaded) / progressEvent.total));

        let progressStatus = `${t('ADD_WI.PLEASE_WAIT_TRANSFERRING_FILE')} ${fileSize(progressEvent.loaded)} ${t('ADD_WI.OF')} ${fileSize(progressEvent.total)}`;
        if (progressEvent.loaded === progressEvent.total) {
          progressStatus = `${t('ADD_WI.PROCESSING_FILE_UPLOAD')}  ${fileSize(progressEvent.loaded)}  ${t('ADD_WI.OF')}  ${fileSize(progressEvent.total)} .`;
        }
        setCadUploadProgressStatus(progressStatus);
      },
    };
    if (cadFileUpload && workInstruction) {
      const formData = new FormData();
      formData.append('file', cadFileUpload, cadFileUpload?.name.split('.')[0]);
      formData.append('org', localStorage.getItem('organizationId') || '');
      formData.append('model', workInstruction.workInstructionId);
      formData.append('name', cadFileUpload?.name);
      formData.append('type', '.' + cadFileUpload?.name.split('.').pop());
      formData.append('model_name', cadFileUpload?.name.split('.')[0] + Date.now());
      formData.append('creo', cadFileUpload?.name.split('.').pop()?.toLowerCase() === 'pvz' ? '1' : '0');

      setStatus('Preparing 3D Model');
      resetMessages(true);
      fileUploadDetails.dispatch({ type: 'isUploadingCad', payload: true });
      initCadFileUploadStatus();
      await FileManagerService.fileUpload(formData, config)
        .then((response) => {
          setCadUploadIsInProgress(false);
          setCadFileUploadError('');
          resetCadFileUpload();
          checkStatusWithTimer();
          sendFileMetaData(WorkInstructionModelStatus.PREPARING_MODEL, WorkInstructionModelFileUploadStatus.FILE_UPLOAD_SUCCESS);
          trackEvent(AmplitudeEvents.CAD_ASSET_UPLOAD_SUCCESS, { created_by: localStorage.getItem('email'), work_instruction_id: workInstruction.workInstructionId, cad_asset: cadFileUpload?.name, cad_asset_size: cadFileUpload.size / (1024 * 1024) + 'MB' });
        })
        .catch((error) => {
          setCadUploadIsInProgress(false);
          trackEvent(AmplitudeEvents.CAD_ASSET_UPLOAD_FAILED, { created_by: localStorage.getItem('email'), work_instruction_id: workInstruction.workInstructionId, cad_asset: cadFileUpload?.name, cad_asset_size: cadFileUpload.size / (1024 * 1024) + 'MB' });
          const {
            response: {
              data: { error: errorMessage },
            },
          } = error;
          sendFileMetaData(WorkInstructionModelStatus.FILE_UPLOAD_FAILED, WorkInstructionModelFileUploadStatus.FILE_UPLOAD_FAILED + '.' + errorMessage);
          if (errorMessage) {
            setCadFileUploadError(errorMessage);
            Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(error.data.messageCode, { ns: 'error' }) || error.data.errorMessage);
          } else {
            setCadFileUploadError('CAD File Upload Failed.');
          }
          setStatus('CAD File Upload Failed');
          resetMessages(false, true);
          resetCadFileUpload();
          setIsStatusLoading(false);
        });
    }
  };

  const checkStatus = async (intervalId: any) => {
    setIsStatusLoading(false);
    if (workInstruction && workInstruction.fileStatus === WorkInstructionModelFileUploadStatus.FILE_NOT_UPLOADED) {
      setIsStatusLoading(true);
      await TutorialService.getWorkInstructionsById(workInstruction.workInstructionId).then((response) => {
        workInstruction.fileStatus = response.data.data.fileStatus;
        workInstruction.status = response.data.data.status;
      });
    }
    if (workInstruction && workInstruction.status === WorkInstructionModelStatus.PREPARING_MODEL) {
      setIsStatusLoading(true);
      await TrainerService.getStatus(workInstruction.workInstructionId)
        .then((response) => {
          setIsStatusLoading(false);
          if (response?.data?.data?.status === 'PREPARING 3D MODEL') {
            setStatus('Preparing 3D Model');
            resetMessages(true);
          } else if (response?.data?.data?.status === '3D MODEL READY') {
            clearInterval(intervalId);
            setModelReady();
          } else if (response?.data?.data?.status === '3D MODEL CONVERSION FAILED') {
            clearInterval(intervalId);
            setModelConversionFailed();
          }
        })
        .catch((error) => {
          setIsStatusLoading(false);
          const {
            response: {
              data: { errorMessage },
            },
          } = error;
          if (errorMessage) {
            Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(error.data.messageCode, { ns: 'error' }) || error.data.errorMessage);
          }
          clearInterval(intervalId);
          setModelConversionFailed();
        });
    } else if (workInstruction && workInstruction.status === WorkInstructionModelStatus.MODEL_CONVERSION_FAILED) {
      clearInterval(intervalId);
      setModelConversionFailed();
    } else if (workInstruction && workInstruction.status === WorkInstructionModelStatus.FILE_UPLOAD_FAILED) {
      clearInterval(intervalId);
      setCADFileUploadFailed();
    } else if (workInstruction && workInstruction.status === WorkInstructionModelStatus.MODEL_READY) {
      clearInterval(intervalId);
      setModelReady();
    }
  };

  const checkStatusWithTimer = async () => {
    if (props.isTrainer) {
      await checkStatus(null);

      const intervalId = setInterval(async () => {
        await checkStatus(intervalId);
      }, 10000);
      timerRef.current = intervalId;
    }
  };

  useEffect(() => {
    (async () => {
      if (workInstruction && !cadFileUpload && !workInstruction.is2d) {
        if (workInstruction.status === WorkInstructionModelStatus.PREPARING_MODEL) {
          // Check status
          await checkStatusWithTimer();
        } else if (workInstruction.status === WorkInstructionModelStatus.MODEL_CONVERSION_FAILED) {
          // Show errors
          setModelConversionFailed();
        } else if (workInstruction.status === WorkInstructionModelStatus.MODEL_READY) {
          // Model is ready
          setModelReady();
        } else if (workInstruction.status === WorkInstructionModelStatus.FILE_UPLOAD_FAILED) {
          setCADFileUploadFailed();
        }
      }
    })();
  }, [workInstruction]);

  useEffect(() => {
    if (procedures) {
      for (const procedure of procedures) {
        if (procedure.tasks_count > 0) {
          setIsTaskCreated(true);
        }
      }
    }
  }, [procedures]);

  useEffect(() => {
    if (fileUploadDetails.state.cadFileUploadDetails) {
      setCadFileUpload(fileUploadDetails.state.cadFileUploadDetails[0]);
    }
    if (fileUploadDetails.state.pdfFileUploadDetails) {
      setPdfFileUpload(fileUploadDetails.state.pdfFileUploadDetails);
    }
    setCanUploadFiles(fileUploadDetails.state.canUploadFiles);
  }, [fileUploadDetails]);

  useEffect(() => {
    if (id && workInstruction && !workInstruction.is2d && workInstruction.workInstructionId === id && cadFileUpload && canUploadFiles && !fileUploadDetails.state.isUploadingCad) {
      cadFileUploadCall();
    }
  }, [cadFileUpload, workInstruction]);

  const setModelConversionFailed = () => {
    setIsStatusLoading(false);
    setStatus('3D Model Conversion Failed');
    resetMessages(false, true);
  };

  const setCADFileUploadFailed = () => {
    setIsStatusLoading(false);
    setStatus('CAD File Upload Failed');
    resetMessages(false, true);
  };

  const setModelReady = () => {
    setIsStatusLoading(false);
    setStatus('3D Model Ready');
    resetMessages();
  };

  const setErrorMessage = (showErrorMsg: boolean, showInfoMsg: boolean, statusMsg: null | string) => {
    setShowErrorMessage(showErrorMsg);
    setShowInfoMessage(showInfoMsg);
    setStatus(statusMsg ?? '');
  };

  const setStatusLoading = (flag: boolean) => {
    setIsStatusLoading(flag);
  };

  const getWorkInstruction = useCallback(
    (id: string) => {
      TutorialService.getWorkInstructionsById(id)
        .then((e) => {
          setWorkInstruction(e.data.data);
          if (e.data.data.status.toUpperCase() === WorkInstructionModelStatus.FILE_UPLOAD_FAILED) {
            setCadFileUploadError(WorkInstructionModelStatus.FILE_UPLOAD_FAILED);
          }
        })
        .catch((e) => {
          setIsStatusLoading(false);
        });
    },
    [id],
  );

  const getProcedures = useCallback(
    (id: string) => {
      TutorialService.getProcedures(id)
        .then((e) => {
          setProcedures(e.data.data);
          for (let i = 0; i < e.data.data.length; i++) {
            if (e.data.data[i].duplicateContentCreated === ProcedureDuplicationStatus.PROCESSING) {
              const interval = setTimeout(() => {
                getProcedures(id);
              }, 20000);
              break;
            }
          }
        })
        .catch((e) => {
          setProcedures([]);
        });
    },
    [id],
  );

  const clearCheckStatusWithTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
  };

  const { refreshProcedure, setRefreshProcedure } = useCurrentUser();

  useEffect(() => {
    if (!workInstruction?.is2d) {
      clearCheckStatusWithTimer();
      resetMessages();
    }

    if (id) {
      getWorkInstruction(id);
      getProcedures(id);
    }
    // Clear timer when unmount
    return () => {
      clearCheckStatusWithTimer();
    };
  }, [refreshProcedure]);

  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <>
      {!procedures && <Loading />}
      {cadUploadIsInProgress && (
        <Loading>
          <LinearWithValueLabel progress={cadUploadProgress} message={cadUploadProgressStatus} variant={cadUploadProgressStatus.indexOf('Processing...') === -1 ? 'determinate' : 'indeterminate'} />
        </Loading>
      )}
      <Grid sx={{ paddingBottom: '0px !important' }} className="procedure-card-container" container>
        {procedures && !props.isTrainer && (
          <>
            <Paper className="procedures-title-container" elevation={0} sx={{ display: { xs: 'none', md: 'flex' } }}>
              <Link to={`/home/${companyId}`}>
                <Button
                  variant="contained"
                  className="all-work-instructions card"
                  sx={{
                    color: theme.palette.primary.main,
                  }}
                >
                  <LeftArrow />
                  {t('WI_LIST.ALL_WORK_INSTRUCTIONS') as string}
                </Button>
              </Link>
            </Paper>
            <Paper className="trainee-go-to-wi-mobile" elevation={0} sx={{ display: { xs: 'flex', md: 'none' } }}>
              <Link to={`/home/${companyId}`}>
                <Button variant="text">
                  <LeftArrow />
                  {t('NAVBAR.ALL_WORK_INSTRUCTIONS')}
                </Button>
              </Link>
            </Paper>
            <Paper className="procedures-title-container" elevation={0} sx={{ display: { xs: 'none', md: 'flex' }, mt: '2px', mb: '5px' }}>
              <Typography component="div" variant="h5" sx={{ marginBottom: '0.2rem' }}>
                {t('PROCEDURE.PROCEDURES')}: {workInstruction?.workInstructionName}
              </Typography>
              <Box component="div">
                <Button onClick={() => navigate(`/procedures/${companyId}/references/${workInstruction?.workInstructionId}`)} variant="outlined" startIcon={<FolderCopy />}>
                  {t('PROCEDURE.SEE_REFERENCE_DOCUMENTS')}
                </Button>
              </Box>
            </Paper>
            <Paper className="procedures-title-container" elevation={0} sx={{ display: { xs: 'flex', md: 'none' }, mt: '2px', mb: '15px' }}>
              <Typography component="div" variant="h5" sx={{ fontSize: '13px' }}>
                Procedures: {workInstruction?.workInstructionName}
              </Typography>
              <Box component="div">
                <Button onClick={() => navigate(`/procedures/${companyId}/references/${workInstruction?.workInstructionId}`)} sx={{ fontSize: '10px', lineHeight: 1, margin: 0, borderRadius: '4px', padding: '3px 10px' }} variant="outlined" startIcon={<FolderCopy />}>
                  Reference Docs
                </Button>
              </Box>
            </Paper>
          </>
        )}
        <Paper className={`procedures-card ${props.isTrainer ? 'trainer-procedure-container' : 'trainee-procedure-container'}`} elevation={0} sx={{ borderRadius: '12px', boxShadow: 'rgba(149, 157, 165, 0.5) 0px 8px 24px' }}>
          {workInstruction && procedures && (
            <>
              <LeftProceduresCard statusLoading={isStatusLoading} isTaskCreated={isTaskCreated} showErrorMessage={showErrorMessage} showInfoMessage={showInfoMessage} status={status} id={workInstruction?.workInstructionId} title={workInstruction?.workInstructionName} description={workInstruction?.workInstructionDescription} pendingApprovals={workInstruction?.pendingApprovals} isTrainer={props.isTrainer} brand={workInstruction?.brand} procedures={procedures} workInstruction={workInstruction} isRevisitRequest={props.isRevisitRequest} />
              <RightProceduresCard cadFileUpload={cadFileUpload} showErrorMessage={showErrorMessage} showInfoMessage={showInfoMessage} status={status} workInstruction={workInstruction} procedures={procedures} isTrainer={props.isTrainer} emitShowErrorMessage={setErrorMessage} emitIsStatusLoading={setStatusLoading} cadFileUploadError={cadFileUploadError} isRevisitRequest={props.isRevisitRequest} />
            </>
          )}
        </Paper>
      </Grid>
    </>
  );
};

export default Procedures;
