import { Card, Box, CardContent, Breadcrumbs, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useNavigate, Link as RouterLink, useParams } from "react-router-dom"
import { Resource } from "../../entities/User/MockResource"
import ResourceForm from "./ResourceForm"
import Link, { LinkProps } from "@mui/material/Link"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"

interface EditResourceProps {}

interface LinkRouterProps extends LinkProps {
  to: string
  replace?: boolean
}

const LinkRouter = (props: LinkRouterProps) => (
  <Link {...props} component={RouterLink as any} />
)

const EditResource: React.FC<EditResourceProps> = (props) => {
  const breadcrumbs = [
    <Link underline="hover" key="1" sx={{ opacity: "0.7" }}>
      Setup
    </Link>,
    <LinkRouter
      underline="hover"
      key="2"
      to="/resource"
      sx={{ opacity: "0.7" }}
    >
      Resource
    </LinkRouter>,
    <Typography key="3" color="primary.main">
      Edit Resource
    </Typography>,
  ]

  const { resourceId } = useParams()
  const [resource, setResource] = useState<Resource>()

  let navigate = useNavigate()

  const editResourceHandler = (resourceData: Resource) => {
    fetch(
      "https://react-getting-started-48dec-default-rtdb.firebaseio.com/meetups.json",
      {
        method: "POST",
        body: JSON.stringify(resourceData),
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then(() => {
      backHandler()
    })
  }

  const backHandler = () => {
    navigate("/resource")
  }

  useEffect(() => {
    if (resourceId) {
      // ResourceService.getResourcesByCompanyId(props.companyId).then((e) => {
      //   console.log(e.data)
      //   setResources(e.data as Resource[])
      //   setFilteredResources(e.data as Resource[])
      // })
    }

    const resource = {
      alias: "ORG",
      identifier: "ORG",
      id: "sd232wdqc32rq",
      isActive: true,
      name: "Organization",
      description: "Organization",
      createdDate: "2022-07-15T05:39:09.9779298Z",
    }
    setResource(resource as Resource)
  }, [resourceId])

  return (
    <>
      <Breadcrumbs
        sx={{ margin: "2rem" }}
        separator={<NavigateNextIcon color="primary" fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
      <Card
        sx={{ display: "flex" }}
        elevation={5}
        style={{ margin: "2rem", height: "100%" }}
      >
        <Box
          component="div"
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            textAlign: "left",
          }}
        >
          <CardContent
            sx={{ flex: "1 0 auto", p: 0 }}
            className="resource-form-content"
          >
            <ResourceForm
              onBack={backHandler}
              onSubmit={editResourceHandler}
              title={"Edit Resource"}
              resource={resource}
            />
          </CardContent>
        </Box>
      </Card>
    </>
  )
}

export default EditResource
