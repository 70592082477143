import { Box, Button, ButtonBase, Chip, Grid, Link, Paper, Stack, SvgIcon, Typography, useTheme } from '@mui/material';
import React, { ReactNode, useEffect } from 'react';
import { ProductAffected, RightArrow } from '../../../Icons/CdsIcons';
import './ButtonLink.scss';

interface Props {
  onClick?: () => void;
  title: string;
  authored?: boolean;
  children?: ReactNode;
}

const ButtonLink: React.FC<Props> = (props) => {
  const theme = useTheme();
  const themePrimaryExtraLight = theme.palette.primary.main + '0d';

  return (
    <Box component="div" className="pre-requisits-link" onClick={props.onClick}>
      <Paper className={!props.authored ? 'btn-disable pre-requisits-action' : 'pre-requisits-action'} elevation={0} sx={{ borderRadius: '12px', backgroundColor: themePrimaryExtraLight, border: '2px solid ' + (!props.authored ? theme.palette.grey.A200 : theme.palette.primary.main) }}>
        <Box component="div" className="pre-requisits-info">
          <Box component="div" className="pre-requisits-heading">
            <ProductAffected />
            <Box component="div" sx={{ flexDirection: 'column', display: 'flex', flexGrow: 1 }}>
              <Typography component="div" variant="h5" sx={{ float: 'left' }}>
                {props.title}
              </Typography>
              <Box component="div" className="completed-time">
                {props.children}
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default ButtonLink;
