import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import './AssignUserDialog.scss';
import EastIcon from '@mui/icons-material/East';
import { Avatar, Box, Checkbox, FormControlLabel, Grid, Tab, Tabs, TextField } from '@mui/material';
import { useEffect } from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { SearchOutlined } from '@mui/icons-material';
import { IuserList } from '../../../../entities/Trainer/MockTrainer';
import TrainerService from '../../../../services/trainer/TrainerService';
import AssignmentConfirmationDialog from '../AssignmentConfirmationDialog/AssignmentConfirmationDialog';
import Loading from '../../../Loading/Loading';
import { trackEvent } from '../../../../App';
import { AmplitudeEvents } from '../../../../shared/enums';
import { useTranslation } from 'react-i18next';
import { Toast } from '../../../Notifications/Notification';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box component="div" sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

interface Props {
  openDialog: boolean;
  closeDialog: () => void;
  userList: any;
  workInstruction: any;
}

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function AssignUserDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [checked, setChecked] = React.useState<string[]>([]);
  const [userList, setUserList] = React.useState<IuserList>();
  const [userGroupList, setUserGroupList] = React.useState<any>();
  const [checkedGroup, setCheckedGroup] = React.useState<string[]>([]);
  const [searchText, setSearchText] = React.useState('');
  const [showDialog, setShowDialog] = React.useState<boolean>(false);
  const [usersCount, setUsersCount] = React.useState<number>(0);
  const [contentReady, setContentReady] = React.useState<boolean>(true);
  const [userGroupReady, setUserGroupReady] = React.useState<boolean>(true);
  const [selectedItemId, setSelectedItemId] = React.useState<any>(null);
  const [hideUserGroupList, setHideUserGroupList] = React.useState<boolean>(false);

  const handleRowClick = (itemId) => {
    const allEmails = userGroupList[itemId].users.map((user) => user.email);
    setCheckedGroup(allEmails);
    setSelectedItemId(itemId);
    setHideUserGroupList(true);
  };

  const tabs = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    setOpen(props.openDialog);
  }, [props.openDialog]);

  useEffect(() => {
    setContentReady(false);
    if (props.userList !== undefined) {
      setUserList(props.userList);
      setContentReady(true);
    }
  }, [props.userList]);

  useEffect(() => {
    setUserGroupReady(false);
    setUserGroupList(props.userGroupList);
    setUserGroupReady(true);
  }, [props.userGroupList]);

  const handleClose = () => {
    props.closeDialog();
    setChecked([]);
    setCheckedGroup([]);
    setOpen(false);
  };

  const handleUserValues = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };

  const handleBackClick = () => {
    setHideUserGroupList(false);
    setSelectedItemId(null);
  };

  const isChecked = (email) => {
    return checked.indexOf(email) > -1 ? true : false;
  };

  const handleUserGroupValues = (event, email) => {
    setCheckedGroup((prevCheckedGroup) => {
      const updatedList = [...prevCheckedGroup];

      if (isCheckedUserGroup(email)) {
        const index = updatedList.indexOf(email);
        if (index !== -1) {
          updatedList.splice(index, 1);
        }
      } else {
        updatedList.push(email);
      }
      return updatedList;
    });
  };

  const isCheckedUserGroup = (email) => {
    return checkedGroup.includes(email);
  };

  const handleAssignGroup = () => {
    setContentReady(false);
    const request = {
      procedureId: props.procedure.procedureId,
      workInstructionId: props.procedure.workInstructionId,
      users: checkedGroup,
    };
    setUsersCount(request.users.length);
    TrainerService.assignProcedureToUsers(request)
      .then((response) => {
        trackEvent(AmplitudeEvents.ASSIGN_PROCEDURE, {
          procedure_id: request.procedureId,
          assigned_By: localStorage.getItem('email'),
          assignee_list: request.users,
        });
        handleClose();
        setShowDialog(true);
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
      })
      .finally(() => {
        setContentReady(true);
      });
  };

  const handleAssign = () => {
    setContentReady(false);
    const request = {
      procedureId: props.procedure.procedureId,
      workInstructionId: props.procedure.workInstructionId,
      users: checked,
    };

    setUsersCount(request.users.length);
    TrainerService.assignProcedureToUsers(request)
      .then((response) => {
        trackEvent(AmplitudeEvents.ASSIGN_PROCEDURE, {
          procedure_id: request.procedureId,
          assigned_By: localStorage.getItem('email'),
          assignee_list: request.users,
        });
        handleClose();
        setShowDialog(true);
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
      })
      .finally(() => {
        setContentReady(true);
      });
  };

  const { t } = useTranslation();

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        scroll="paper"
        PaperProps={{
          sx: {
            minWidth: '43%',
            minHeight: userList && userList.length === 0 ? '20vh' : '80vh',
            maxHeight: '80vh',
          },
        }}
      >
        {!contentReady && (
          <Box component="div">
            <Loading />
          </Box>
        )}
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {t('PROCEDURE.SELECT_TRAINEES')}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box component="div" sx={{ borderColor: 'divider' }}>
            {userList && userList.length > 0 && (
              <Tabs value={value} onChange={handleChange} aria-label="Assigning user tabs">
                <Tab sx={{ textTransform: 'none' }} label={t('PROCEDURE.INDIVIDUALS')} {...tabs(0)} />
                <Tab sx={{ textTransform: 'none' }} label={t('PROCEDURE.GROUPS')} {...tabs(1)} />
              </Tabs>
            )}
            <TabPanel value={value} index={0}>
              {userList && userList.length > 0 && (
                <>
                  <Box component="div" className="assign-user-text">
                    {userList && userList.length} {userList && userList.length === 1 ? t('PROCEDURE.TRAINEE') : t('PROCEDURE.TRAINEES')}
                  </Box>
                  <TextField
                    sx={{ paddingBottom: '1rem' }}
                    fullWidth
                    id="standard-bare"
                    variant="outlined"
                    placeholder={t('PROCEDURE.SEARCH_EMAIL')}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <IconButton>
                          <SearchOutlined />
                        </IconButton>
                      ),
                    }}
                  />
                  {userList &&
                    userList
                      .filter((item) => {
                        if (!searchText) return true;
                        if (item.email.toLowerCase().includes(searchText.toLowerCase()) || item.firstName.toLowerCase().includes(searchText.toLowerCase()) || item.lastName.toLowerCase().includes(searchText.toLowerCase())) {
                          return true;
                        }
                      })
                      .map((e, i) => {
                        return (
                          <Grid
                            component="span"
                            sx={{
                              display: 'block',
                              backgroundColor: 'rgba(242, 246, 249, 1)',
                              padding: '0.5rem 1rem 0.5rem 0.5rem',
                              borderBottom: '7px solid #ffffff',
                            }}
                            item
                            xs={12}
                            md={12}
                            lg={12}
                            key={i}
                          >
                            <FormControlLabel
                              control={<Checkbox onChange={handleUserValues} value={e.email} checked={isChecked(e.email)} />}
                              label={
                                <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                                  <Avatar component="span" sx={{ borderRadius: '12px' }} variant="square" alt="Remy Sharp">
                                    <AccountCircleIcon />
                                  </Avatar>
                                  <Box component="span" sx={{ paddingTop: '1rem' }}>
                                    <Box
                                      component="span"
                                      // variant="subtitle1"
                                      sx={{
                                        display: 'block',
                                        marginLeft: '1rem',
                                        marginTop: '-1rem',
                                      }}
                                      className="section-text"
                                    >
                                      {e.firstName} {e.lastName}
                                    </Box>
                                    <Box
                                      component="span"
                                      // variant="subtitle1"
                                      sx={{ marginLeft: '1rem' }}
                                      className="section-text"
                                    >
                                      {e.email}
                                    </Box>
                                  </Box>
                                </Box>
                              }
                            />
                          </Grid>
                        );
                      })}
                </>
              )}
            </TabPanel>
            <TabPanel value={value} index={1}>
              {!userGroupReady && (
                <Box component="div">
                  <Loading />
                </Box>
              )}
              {userGroupList && !hideUserGroupList && (
                <>
                  <Box component="div" className="assign-user-text">
                    {userGroupList.length} {(userGroupList.length === 1) ? t('USER_GROUP.USER_GROUP') : t('USER_GROUP.USER_GROUPS')}
                  </Box>

                  <div>
                    {userGroupList.map((e, i) => (
                      <Grid
                        component="span"
                        sx={{
                          display: 'block',
                          backgroundColor: 'rgba(242, 246, 249, 1)',
                          padding: '0.5rem 1rem 0.5rem 0.5rem',
                          borderBottom: '7px solid #ffffff',
                          cursor: 'pointer',
                        }}
                        item
                        xs={12}
                        md={12}
                        lg={12}
                        key={e.id}
                        onClick={() => handleRowClick(i)}
                      >
                        <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                          <Box component="span" sx={{ paddingTop: '1rem', width: '600px' }}>
                            <Box
                              component="span"
                              sx={{
                                display: 'block',
                                marginLeft: '1rem',
                                marginTop: '-1rem',
                              }}
                              className="section-text"
                            >
                              {e.name}
                            </Box>
                            <Box component="span" sx={{ marginLeft: '1rem' }} className="section-text">
                              {e.users.length} {e.users.length > 1 ? t('PROCEDURE.TRAINEES') : t('PROCEDURE.TRAINEE')}
                            </Box>
                          </Box>
                          <EastIcon color="primary" fontSize="large" />
                        </Box>
                      </Grid>
                    ))}
                  </div>
                </>
              )}

              {selectedItemId || selectedItemId === 0 ? (
                <div>
                  {userGroupList[selectedItemId].users && userGroupList[selectedItemId].users.length > 0 && (
                    <>
                      <Box component="div" className="assign-user-text">
                        {userGroupList[selectedItemId].users && userGroupList[selectedItemId].users.length} {userGroupList[selectedItemId].users && userGroupList[selectedItemId].users.length === 1 ? t('PROCEDURE.TRAINEE') : t('PROCEDURE.TRAINEES')}
                      </Box>
                      <TextField
                        sx={{ paddingBottom: '1rem' }}
                        fullWidth
                        id="standard-bare"
                        variant="outlined"
                        placeholder={t('PROCEDURE.SEARCH_EMAIL')}
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        InputProps={{
                          startAdornment: (
                            <IconButton>
                              <SearchOutlined />
                            </IconButton>
                          ),
                        }}
                      />
                      {userGroupList[selectedItemId].users &&
                        userGroupList[selectedItemId].users
                          .filter((item) => {
                            if (!searchText) return true;
                            if (item.email.toLowerCase().includes(searchText.toLowerCase()) || item.firstName.toLowerCase().includes(searchText.toLowerCase()) || item.lastName.toLowerCase().includes(searchText.toLowerCase())) {
                              return true;
                            }
                          })
                          .map((e, i) => {
                            return (
                              <Grid
                                component="span"
                                sx={{
                                  display: 'block',
                                  backgroundColor: 'rgba(242, 246, 249, 1)',
                                  padding: '0.5rem 1rem 0.5rem 0.5rem',
                                  borderBottom: '7px solid #ffffff',
                                }}
                                item
                                xs={12}
                                md={12}
                                lg={12}
                                key={i}
                              >
                                <FormControlLabel
                                  key={e.email}
                                  control={<Checkbox onChange={(event) => handleUserGroupValues(event, e.email)} value={e.email} checked={isCheckedUserGroup(e.email)} />}
                                  label={
                                    <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                                      <Avatar component="span" sx={{ borderRadius: '12px' }} variant="square" alt="Remy Sharp">
                                        <AccountCircleIcon />
                                      </Avatar>
                                      <Box component="span" sx={{ paddingTop: '1rem' }}>
                                        <Box
                                          component="span"
                                          // variant="subtitle1"
                                          sx={{
                                            display: 'block',
                                            marginLeft: '1rem',
                                            marginTop: '-1rem',
                                          }}
                                          className="section-text"
                                        >
                                          {e.firstName} {e.lastName}
                                        </Box>
                                        <Box
                                          component="span"
                                          // variant="subtitle1"
                                          sx={{ marginLeft: '1rem' }}
                                          className="section-text"
                                        >
                                          {e.email}
                                        </Box>
                                      </Box>
                                    </Box>
                                  }
                                />
                              </Grid>
                            );
                          })}
                    </>
                  )}
                </div>
              ) : null}
            </TabPanel>
            {userList && userList.length === 0 && (
              <Box component="div" className="assign-user-msg">
                {t('PROCEDURE.NO_TRAINEES', { procedure: props.procedure.procedureName })}
              </Box>
            )}
          </Box>
        </DialogContent>

        {!Boolean(value) && userList && userList.length > 0 && (
          <DialogActions>
            <Button variant="contained" type="submit" disabled={checked.length === 0} onClick={handleAssign}>
              {' '}
              {t('BUTTON.ASSIGN')} {checked.length > 0 && ` (${checked.length})`}
            </Button>
          </DialogActions>
        )}

        {Boolean(value) && userGroupList && userGroupList.length > 0 && hideUserGroupList && (
          <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button variant="outlined" onClick={handleBackClick}>
              {' '}
              {t('BUTTON.BACK')}
            </Button>
            <Button variant="contained" type="submit" disabled={checkedGroup.length === 0} onClick={handleAssignGroup}>
              {' '}
              {t('BUTTON.ASSIGN')} {checkedGroup.length > 0 && ` (${checkedGroup.length})`}
            </Button>
          </DialogActions>
        )}
      </BootstrapDialog>
      {showDialog && <AssignmentConfirmationDialog openAssignmentDialog={showDialog} userAssignedCount={usersCount} workInstruction={props.workInstruction} procedure={props.procedure} />}
    </div>
  );
}
