import React from 'react';
import { createContext, Dispatch } from 'react';

const ppeFn: Dispatch<React.SetStateAction<any>> = () => {};

const PpeContext = createContext({
    sharedPpeList: [],
    setSharedPpeList: ppeFn,
    showSystemSettings: false,
    setShowSystemSettings:ppeFn,
    systemSettingsTab:1,
    setSystemSettingsTab:ppeFn,
    showIntro: true,
    setShowIntro: ppeFn,
    introStep: 1,
    setIntroStep: ppeFn
});

export default PpeContext;
