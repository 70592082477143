import { Button, Dialog, DialogContent, Typography } from '@mui/material';

type Props = {
  children?: React.ReactNode;
  positionFixed?: boolean;
};

const SessionActive = ({ children, positionFixed }: Props) => {
  return (
    <div className={positionFixed ? 'container-fixed' : 'container'}>
      {children && <div>{children}</div>}
      {!children && (
        <Dialog aria-labelledby="customized-dialog-title" open={true}>
          <DialogContent
            sx={{
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
              flexDirection: 'column',
              m: 3,
            }}
          >
            <Typography>Mentor is open in another window.</Typography>
            <Typography>
              Click 'Use Here' to use Mentor in this window.
            </Typography>
            <Button variant="contained" sx={{marginTop:"24px"}} onClick={()=>{localStorage.removeItem('activeId');window.location.reload()}}>
              Use Here
            </Button>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default SessionActive;
