import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import "./approvalDialog.scss";
import { useMsal } from "@azure/msal-react";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

interface Props {
  openApprovalDialog: boolean;
}

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

export default function ApprovalDialog(props) {
  const [open, setOpen] = React.useState(false);
  const { accounts } = useMsal();
  const name = accounts[0] && accounts[0].name;

  useEffect(() => {
    setOpen(true);
  }, []);

  const refreshPage = () => {
    window.location.reload();
  }

  const handleClose = () => {
    setOpen(false);
    refreshPage();
  };

  useEffect (() => {
    setTimeout (() => {
      handleClose();
    }, 5000)
  })

  const { t } = useTranslation();

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ m: 3 }}>
          <Typography gutterBottom>
            <CheckCircleRoundedIcon
              sx={{ fontSize: 60 }}
              className="align-approval-icon"
            />
          </Typography>
          <Typography gutterBottom className="align-msg">
            {t('PROCEDURE.APPROVED_SUCCESSFULLY')}
          </Typography>
          <Typography variant="caption" gutterBottom className="align-name">
            {t('PROCEDURE.BY')} {name}
          </Typography>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
