import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import './RecertificationConfigurationDialog.scss';
import EastIcon from '@mui/icons-material/East';
import { Autocomplete, Avatar, Box, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Skeleton, Tab, Tabs, TextField } from '@mui/material';
import { useEffect } from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { AccountCircle, SearchOutlined } from '@mui/icons-material';
import { IuserList } from '../../../../entities/Trainer/MockTrainer';
import TrainerService from '../../../../services/trainer/TrainerService';
import AssignmentConfirmationDialog from '../AssignmentConfirmationDialog/AssignmentConfirmationDialog';
import Loading from '../../../Loading/Loading';
import { trackEvent } from '../../../../App';
import { AmplitudeEvents } from '../../../../shared/enums';
import { useTranslation } from 'react-i18next';
import { Toast } from '../../../Notifications/Notification';
import { StyledSwitch } from '../../../Styled/StyledSwitch';

import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import { FormControlLabelProps } from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { start } from 'repl';
import { useFormik } from 'formik';
import useCurrentUser from '../../../../hooks/useCurrentUser';
interface StyledFormControlLabelProps extends FormControlLabelProps {
  checked: boolean;
}

const StyledFormControlLabel = styled((props: StyledFormControlLabelProps) => <FormControlLabel {...props} />)(({ theme, checked }) => ({
  '.MuiFormControlLabel-label': checked && {
    color: theme.palette.primary.main,
  },
}));

type AutoCompleteFieldProps<T> = {
  selectValue: keyof T;
  options: T[];
  onChange: any;
  recertificationProcedureStatus?: any;
  notifyTrainerEdit?: any;
};
export const isString = (item: any): item is string => {
  return typeof item === 'string';
};
const AutoCompleteField = <T extends {}>({ selectValue, options, onChange, recertificationProcedureStatus, notifyTrainerEdit }: AutoCompleteFieldProps<T>): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <Autocomplete
      disabled={recertificationProcedureStatus}
      id="manager-auto-complete"
      options={options}
      getOptionLabel={(option: any) => {
        const val = option[selectValue];
        return isString(val) ? val : '';
      }}
      onChange={onChange}
      renderInput={(params) => (
        <Box component="div">
          <TextField {...params} sx={{ backgroundColor: !recertificationProcedureStatus ? 'none' : 'rgba(0, 0, 0, 0.1)'}} placeholder={Boolean(notifyTrainerEdit) ? notifyTrainerEdit : t('NAVBAR.SELECT_EMAIL')} />
        </Box>
      )}
    />
  );
};
function MyFormControlLabel(props: FormControlLabelProps) {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return <StyledFormControlLabel checked={checked} {...props} />;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box component="div" sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

interface Props {
  openDialog: boolean;
  closeDialog: () => void;
  userList: any;
  workInstruction: any;
}

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 0 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function RecertificationConfigurationDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [contentReady, setContentReady] = React.useState<boolean>(true);
  const [duration, setDuration] = React.useState<any>(props?.procedure?.recertificationFormat === 'y' ? 'years' : props?.procedure?.recertificationFormat === 'm' ? 'months' : props?.procedure?.recertificationFormat === 'd' ? 'days' : props?.procedure?.recertificationFormat === undefined && props?.procedure?.certificateExpiryDuration > 0 ? 'days' : 'years');
  const [managersLoading, setManagersLoading] = React.useState<boolean>(false);
  const [approvalManagers, setApprovalManagers] = React.useState<Array<any>>([]);
  const [userInputValue, setUserInputValue] = React.useState<any>(0);
  const [calculatedDays, setCalculatedDays] = React.useState<any>(null);
  const [notifyTrainer, setNotifyTrainer] = React.useState<any>();
  const [notifyTrainerEdit, setNotifyTrainerEdit] = React.useState<any>();
  const { refreshProcedure, setRefreshProcedure } = useCurrentUser();
  const [recertificationProcedureStatus, setRecertificationProceudreStatus] = React.useState<boolean>(Boolean(props.procedure.certificateExpiryDuration));

  const handleRecertificationReviewSave = () => {
    setContentReady(false);
    if (recertificationProcedureStatus) {
      const payload = {
        recertificationManager: notifyTrainer,
        recertificationFormat: duration === 'years' ? 'y' : duration === 'months' ? 'm' : 'd',
        recertificationDuration: calculatedDays,
      };
      TrainerService.addRecertificationConfiguration(props.procedure.procedureId, payload)
        .then(async (response) => {
          setContentReady(true);
          Toast.success(`${t('NAVBAR.RECERTIFICATION_CONFIGURATION')}`, `${t('NAVBAR.RECERTIFICATION_CONFIGURATION_SUCCESS_MESSAGE')}`);
          setRefreshProcedure(!refreshProcedure);
          props.closeDialog();
        })
        .catch(({ response }) => {
          Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
          setContentReady(true);
        });
    } else {
      const payload = {
        recertificationManager: null,
      };
      TrainerService.addRecertificationConfiguration(props.procedure.procedureId, payload)
        .then(async (response) => {
          setContentReady(true);
          setDuration('years');
          Toast.success(`${t('NAVBAR.RECERTIFICATION_CONFIGURATION')}`, `${t('NAVBAR.RECERTIFICATION_CONFIGURATION_SUCCESS_MESSAGE')}`);
          setRefreshProcedure(!refreshProcedure);
          props.closeDialog();
        })
        .catch(({ response }) => {
          Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
          setContentReady(true);
        });
    }
  };

  useEffect(() => {
    setManagersLoading(true);
    TrainerService.getManagerList(localStorage.getItem('personaId')).then((response) => {
      const filteredTrainers = response.data.data;
      setApprovalManagers(filteredTrainers);
      setManagersLoading(false);
    });
  }, []);

  useEffect(() => {
    setOpen(props.openDialog);
  }, [props.openDialog]);

  const handleClose = () => {
    props.closeDialog();
    setOpen(false);
    setRecertificationProceudreStatus(Boolean(props.procedure.certificateExpiryDuration));
  };

  const onInputChangeNotifyTrainer = (e: React.ChangeEvent, value: any): void => {
    setNotifyTrainer(value.id);
  };

  const recertificationProcedureStatusHandler = () => {
    setRecertificationProceudreStatus(!recertificationProcedureStatus);
  };

  const handleCalculatedDays = (event) => {
    const numericValue = event.target.value;
    if (!Number.isInteger(parseFloat(numericValue))) {
      setUserInputValue('');
      return;
    }
    setUserInputValue(parseInt(numericValue));
    let conversionFactor = 1;
    switch (duration) {
      case 'years':
        conversionFactor = 365;
        break;
      case 'months':
        conversionFactor = 30;
        break;
      case 'days':
        conversionFactor = 1;
        break;
      default:
        conversionFactor = 1;
        break;
    }
    const days = numericValue * conversionFactor;
    setCalculatedDays(days);
  };

  const isValidForm = (): boolean => {
    let isValid = true;
    if (userInputValue < 1) {
      isValid = false;
    }
    if (!notifyTrainer) {
      isValid = false;
    }

    return isValid;
  };

  const handleDurationChange = (event) => {
    const selectedValue = event.target.value;
    setDuration(event.target.value);
    let conversionFactor = 1;
    switch (selectedValue) {
      case 'years':
        conversionFactor = 365;
        break;
      case 'months':
        conversionFactor = 30;
        break;
      case 'days':
        conversionFactor = 1;
        break;
      default:
        conversionFactor = 1;
        break;
    }
    const days = userInputValue * conversionFactor;
    setCalculatedDays(days);
  };

  const getRecertificationExpiryPlaceholder = () => {
    if (props?.procedure?.recertificationFormat === 'y') {
      return (props.procedure.certificateExpiryDuration / 365).toString();
    } else if (props?.procedure?.recertificationFormat === 'm') {
      return (props.procedure.certificateExpiryDuration / 30).toString();
    } else if (props?.procedure?.recertificationFormat === 'd' && props?.procedure?.certificateExpiryDuration > 0) {
      return props?.procedure?.certificateExpiryDuration?.toString();
    } else if (props?.procedure?.recertificationFormat === undefined && props?.procedure?.certificateExpiryDuration > 0) {
      return props?.procedure?.certificateExpiryDuration?.toString();
    } else {
      return '';
    }
  };

  useEffect(() => {
    if (props.procedure.recertificationManager) {
      const managerWithEmail = approvalManagers.find((manager) => manager.id === props.procedure.recertificationManager);
      if (managerWithEmail) {
        setNotifyTrainerEdit(managerWithEmail.email);
      }
    }
  }, [approvalManagers, props.procedure.recertificationManager]);

  const { t } = useTranslation();

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        scroll="paper"
        PaperProps={{
          sx: {
            width: '584px',
            padding: '24px',
          },
        }}
      >
        {!contentReady && (
          <Box component="div">
            <Loading />
          </Box>
        )}
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {t('NAVBAR.RECERTIFICATIONS_CONFIGURATION')}
        </BootstrapDialogTitle>
        <Box component="div" sx={{ borderColor: 'divider', marginTop: '24px' }}>
          <Box component="span" sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={{ fontSize: '16px' }}>{t('NAVBAR.RECERTIFICATION_PROCEDURES')}</Typography>
            <StyledSwitch name="isActive" checked={recertificationProcedureStatus} color={'primary'} onChange={recertificationProcedureStatusHandler} />
          </Box>
        </Box>
        <FormControl sx={{ width: '100%', marginTop: '24px' }}>
          <FormLabel
            component="legend"
            className="formStyle"
            sx={{
              color: 'rgba(28, 29, 31, .8)',
              fontSize: '12px',
              fontWeight: '500',
              paddingBottom: '15px',
            }}
          >
            {t('NAVBAR.RECERTIFICATION_REVIEW_INTERVAL')}
          </FormLabel>
          <Box component="div" sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <TextField type="number" autoComplete="off" disabled={!recertificationProcedureStatus} placeholder={getRecertificationExpiryPlaceholder()} sx={{ width: '260px', height: '44px', border: '0.5px', borderRadius: '8px', backgroundColor: recertificationProcedureStatus ? undefined : 'rgba(0, 0, 0, 0.1)' }} onChange={handleCalculatedDays} InputProps={{ inputProps: { min: 1 }, sx: { height: '44px', boxSizing: 'border-box' } }} />
            <Select value={duration} disabled={!recertificationProcedureStatus} onChange={handleDurationChange} displayEmpty inputProps={{ 'aria-label': 'Without label' }} sx={{ width: '260px', border: '0.5px', borderRadius: '8px', height: '44px', backgroundColor: recertificationProcedureStatus ? undefined : 'rgba(0, 0, 0, 0.1)'}}>
              <MenuItem value="years">{t('NAVBAR.YEARS')}</MenuItem>
              <MenuItem value="months">{t('NAVBAR.MONTHS')}</MenuItem>
              <MenuItem value="days">{t('NAVBAR.DAYS')}</MenuItem>
            </Select>
          </Box>
        </FormControl>
        <FormControl sx={{ width: '100%', marginTop: '24px' }} className="tour-instruction-brand">
          <FormLabel
            component="legend"
            className="formStyle"
            sx={{
              color: 'rgba(28, 29, 31, .8)',
              fontSize: '12px',
              fontWeight: '500',
              paddingBottom: '8px',
            }}
          >
            {' '}
            {t('NAVBAR.ESCALATION_EMAIL') as string}{' '}
          </FormLabel>

          {!managersLoading ? (
            <>
              <AutoCompleteField<any> options={approvalManagers} selectValue="email" onChange={onInputChangeNotifyTrainer} recertificationProcedureStatus={!recertificationProcedureStatus} notifyTrainerEdit={notifyTrainerEdit} />
            </>
          ) : (
            <Skeleton sx={{ height: '36px', width: '100%' }} />
          )}
        </FormControl>

        <DialogActions style={{ display: 'flex', justifyContent: 'end', marginTop: '24px' }}>
          <Button variant="contained" type="submit" disabled={!recertificationProcedureStatus ? false : !isValidForm()} onClick={handleRecertificationReviewSave}>
            {' '}
            {t('BUTTON.SAVE')}
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            {' '}
            {t('BUTTON.CANCEL')}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
