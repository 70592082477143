import { Autocomplete, Avatar, Box, Button, ButtonGroup, Card, CardActions, CardContent, CardHeader, Checkbox, Chip, Collapse, FormControlLabel, FormGroup, FormLabel, Grid, IconButton, Paper, Skeleton, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography, styled, useTheme } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import Dropzone from '../../../components/Dropzone/Dropzone';
import './NewProcedure.scss';
import { useFileUpload } from '../../../shared/context';
import { IProcedures } from '../../../entities/Trainee/MockTrainee';
import TutorialService from '../../../services/trainee/TraineeService';
import Loading from '../../Loading/Loading';
import { DeleteIconRed, LeftArrow } from '../../Icons/CdsIcons';
import { IProcedureCreate } from '../../../entities/Trainee/WorkInstructions';
import { useTranslation } from 'react-i18next';
import languages from '../Dialog/LanguageSupportDialog/Languages';
import useTour from '../../../hooks/tour';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import TrainerService from '../../../services/trainer/TrainerService';
import { environment } from '../../../environment/environment';
import useCurrentTranslation from '../../../hooks/useCurrentTranslation';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Toast } from '../../Notifications/Notification';
import { useAppSelector } from '../../../hooks/redux';
import { useParams } from 'react-router-dom';
import { PROCEDURE_VISIBILITY_SCOPE } from '../../../shared/enums';

interface Props {
  workInstructionData: any;
  procedureDetails: null | IProcedures;
  formData: IProcedureCreate | null;
  fileData: any | null;
  showBackButton: boolean;
  onGoNext: (formData: IProcedureCreate, fileData: any) => void;
  onGoBack: (formData: IProcedureCreate, fileData: any) => void;
  setFileData: any;
}

const StyledListItem = styled(ListItem)(({ theme }) => ({
  border: `1px solid ${theme.palette.success.main}`,
  margin: '8px 0',
}));

export const isString = (item: any): item is string => {
  return typeof item === 'string';
};

const NewProcedure: React.FC<Props> = (props) => {
  const [errorMessage, setErrorMessage] = useState<string | number>('');
  const [deleteRecord, setDeleteRecord] = useState<string[]>([]);
  const [fileValues, setFileValues] = useState<any>(props.fileData);
  const [procedureType, setProcedureType] = useState('Pre Requisite');
  const [contentReady, setContentReady] = useState<boolean>(true);
  const [managersLoading, setManagersLoading] = React.useState<boolean>(false);
  const [approvalManagers, setApprovalManagers] = React.useState<Array<any>>([]);
  const [signOffSelector, setSignOffSelector] = React.useState<any>(false);
  const [procedureDetails, setProcedureDetails] = React.useState<any>(props?.procedureDetails?.file || []);
  const [filesSelectedCount, setFilesSelectedCount] = React.useState<number>(0);

  const fileUpload = useFileUpload();
  const { workInstructionId } = useParams();
  const { t } = useTranslation();
  const { isTourOpen } = useTour();
  const { visibilityScope } = useAppSelector((state) => state.procedure.createProcedure);

  useEffect(() => {
    if (fileUpload.state.isPrerequisite === 'Add Pre Requisite' || fileUpload.state.isPrerequisite === 'Edit Pre Requisite') {
      setProcedureType('Pre Requisite');
    } else {
      setProcedureType('Core Procedure');
    }
  }, []);

  useEffect(() => {
    if (props.formData) {
      Object.keys(props.formData).forEach((key) => {
        if (key === 'estimated_time') {
          const seconds = props.formData && props.formData[key] ? props.formData[key] : 0;
          var h = Math.floor(seconds / 3600);
          var m = Math.floor((seconds % 3600) / 60);
          var s = Math.floor((seconds % 3600) % 60);
          formik.setFieldValue('hours', h, false);
          formik.setFieldValue('minutes', m, false);
          formik.setFieldValue('seconds', s, false);
        } else {
          const value = props.formData && props.formData[key] ? props.formData[key] : '';
          formik.setFieldValue(key, value, false);
        }
      });
    }
  }, [props.formData]);

  useEffect(() => {
    formik.setFieldValue('procedureType', procedureType);
  }, [procedureType]);

  useEffect(() => {
    if (props.procedureDetails !== null) {
      setContentReady(false);
      TutorialService.getProcedure(props?.procedureDetails.procedureId)
        .then((e) => {
          const {
            data: { data: workInstructionData },
          } = e;
          const fields = ['procedureName', 'documentId', 'hours', 'minutes', 'seconds'];

          fields.forEach((field) => {
            if (field === 'hours') {
              var h = Math.floor(workInstructionData['estimated_time'] / 3600);
              formik.setFieldValue(field, h, false);
            } else if (field === 'minutes') {
              var m = Math.floor(Math.floor((workInstructionData['estimated_time'] % 3600) / 60));
              formik.setFieldValue(field, m, false);
            } else if (field === 'seconds') {
              var s = Math.floor((workInstructionData['estimated_time'] % 3600) % 60);
              formik.setFieldValue(field, s, false);
            } else formik.setFieldValue(field, workInstructionData[field], false);
          });
        })
        .finally(() => {
          setContentReady(true);
        });
    }
  }, [props.procedureDetails?.procedureId]);
  interface procedureForm {
    procedureType: string;
    procedureName: string;
    documentId: string;
    signOffUsers: Array<any>;
    language: any;
    hours: number;
    minutes: number;
    seconds: number;
    visibilityScope: PROCEDURE_VISIBILITY_SCOPE;
  }
  const formik = useFormik<procedureForm>({
    initialValues: {
      procedureType: procedureType,
      procedureName: isTourOpen ? t('CREATE_WI.MOUNTAIN_BIKE_INSTALLATION', { ns: 'demoTour' }) : '',
      documentId: isTourOpen ? 'SDFC23' : '',
      signOffUsers: [],
      language: props?.procedureDetails?.language || { code: 'en', name: 'English' },
      hours: 0,
      minutes: 0,
      seconds: 0,
      visibilityScope: PROCEDURE_VISIBILITY_SCOPE.NORMAL,
    },
    onSubmit: (values) => {
      fileUpload.dispatch({ type: 'uploadPdf', payload: fileValues });
      // setFormValues(values);
      props.onGoNext(values, fileValues);
    },
  });

  useEffect(() => {
    formik.setFieldValue('visibilityScope', visibilityScope);
    if (visibilityScope === PROCEDURE_VISIBILITY_SCOPE.GLOBAL) {
      formik.setFieldValue('workInstructionType', '2D');
    }
  }, [visibilityScope]);

  const handleBackButtonClick = () => {
    props.onGoBack(formik.values, fileValues);
  };
  const handleDelete = (i) => {
    const updateFileValues = fileValues.filter((_, index) => index != i);
    setFileValues(updateFileValues);
    const updateSelectedFiles = updateFileValues.map((file) => ({
      name: file.name,
      size: file.size,
    }));

    props.setFileData(updateSelectedFiles);
  };

  const handleEditProcDelete = (file) => {
    const updatedProcedureDetails = procedureDetails.filter((item) => item !== file);
    setProcedureDetails(updatedProcedureDetails);
    const deletedFileId = props?.procedureDetails?.file?.find((item) => item === file)?.fileId;
    if (deletedFileId) {
      setDeleteRecord((prevDeletedRecord) => [...prevDeletedRecord, deletedFileId]);
    }
  };

  const deleteSelectedReferenceDoc = async () => {
    const req = JSON.stringify(deleteRecord);
    await TrainerService.bulkDeleteReferenceDoc(workInstructionId, props?.procedureDetails?.procedureId, req).then((response) => {});
  };

  const hasDataLength = (obj: any, len: number): boolean => {
    return !!(obj && obj.length > len);
  };

  const onInputChangeSignOffUser = (e, value: any): void => {
    const currentSignOffUsers = formik?.values?.signOffUsers || [];
    const isUserAlreadyAdded = currentSignOffUsers.some((user) => user.email === value.email);
    if (!isUserAlreadyAdded) {
      const updatedSignOffUsers = [...currentSignOffUsers, { email: value.email, userId: value.id }];
      formik.setFieldValue('signOffUsers', updatedSignOffUsers);
    }
  };

  const handleDeleteSignOff = (index) => {
    const updatedSignOffUsers = [...formik.values.signOffUsers];
    updatedSignOffUsers.splice(index, 1);
    formik.setFieldValue('signOffUsers', updatedSignOffUsers);
  };

  const isValidForm = (): boolean => {
    let isValid = true;

    if (formik && formik.values) {
      isValid = isValid && hasDataLength(formik.values.procedureName, 1);
    } else {
      isValid = false;
    }

    return isValid;
  };

  useEffect(() => {
    setManagersLoading(true);

    TrainerService.getManagerList(localStorage.getItem('personaId'))
      .then((response) => {
        const filteredTrainers = response.data.data;
        const signOffUsers = props?.procedureDetails?.signOffUsers || [];

        const filteredTrainersWithSignOffUsers = filteredTrainers.filter((trainer) => signOffUsers.includes(trainer.id));
        const updatedSignOffUsers = filteredTrainersWithSignOffUsers.map((user) => ({
          email: user.email,
          userId: user.id,
        }));
        formik.setFieldValue('signOffUsers', updatedSignOffUsers);
        setApprovalManagers(filteredTrainers);
        setManagersLoading(false);
      })
      .catch(({ response }) => {
        setManagersLoading(false);
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
      });
  }, []);

  useEffect(() => {
    if (fileValues) {
      setFilesSelectedCount(fileValues.length);
    }
  }, [fileValues]);

  const { currentTranslation } = useCurrentTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language.toUpperCase();
  const systemPrerequisite = currentTranslation?.PREREQUISITES ? currentTranslation.PREREQUISITES[currentLanguage] : `${t('PROCEDURE.PRE_REQUISITES')}`;
  const systemCoreProcedure = currentTranslation?.CORE_PROCEDURES ? currentTranslation.CORE_PROCEDURES[currentLanguage] : `${t('PROCEDURE.CORE_PROCEDURES')}`;
  const display = (addMessage) => {
    if (addMessage.toUpperCase() === 'ADD PRE REQUISITE') {
      return `${t('BUTTON.ADD')}${' '}${systemPrerequisite}`;
    } else if (addMessage.toUpperCase() === 'ADD CORE PROCEDURE') {
      return `${t('BUTTON.ADD')}${' '}${systemCoreProcedure}`;
    } else if (addMessage.toUpperCase() === 'EDIT PRE REQUISITE') {
      return `${t('COMMON.EDIT')}${' '}${systemPrerequisite}`;
    } else if (addMessage.toUpperCase() === 'EDIT CORE PROCEDURE') {
      return `${t('COMMON.EDIT')}${' '}${systemCoreProcedure}`;
    }
  };

  useEffect(() => {
    if ((formik?.values?.signOffUsers?.length ?? 0) >= 2) {
      setSignOffSelector(true);
    } else {
      setSignOffSelector(false);
    }
  }, [formik?.values?.signOffUsers]);

  const getHeading = () => {
    if (props.workInstructionData !== null || visibilityScope === PROCEDURE_VISIBILITY_SCOPE.GLOBAL) return t('ADD_WI.ADD_PROCEDURE_DETAILS');
    return display(fileUpload.state.isPrerequisite);
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <Card className="procedure-create-card" elevation={3} style={{ borderRadius: '12px', maxWidth: '548px', margin: '10px 10px 20px 10px', padding: '16px' }}>
        <Typography sx={{ textAlign: 'left', padding: '16px', fontSize: '20px' }}>{getHeading()}</Typography>
        <CardContent sx={{ overflow: 'auto' }}>
          {!contentReady && (
            <Box component="div" className="align-center">
              <Loading />
            </Box>
          )}
          {errorMessage && (
            <Box component="div" description-container>
              <Typography component="div" className="error-msg">
                {' '}
                {errorMessage}{' '}
              </Typography>
            </Box>
          )}
          {visibilityScope === PROCEDURE_VISIBILITY_SCOPE.NORMAL && (
            <>
              <FormControl sx={{ width: '100%' }} required>
                <FormControl>
                  {true && (
                    <FormLabel sx={{ color: 'rgba(28, 29, 31, .8)' }} component="legend" className="formStyle new-procedure-type">
                      {t('ADD_WI.SELECT_PROCEDURE_TYPE')}
                    </FormLabel>
                  )}
                </FormControl>
                <ButtonGroup variant="outlined" aria-label="outlined button group" className="tour-pre-or-core">
                  <Button
                    disableElevation
                    id={'procedureType'}
                    value={'Pre Requisite'}
                    onClick={(e) => {
                      formik.handleChange(e);

                      fileUpload.dispatch({
                        type: 'setProcedureType',
                        payload: 'Add Pre Requisite',
                      });
                    }}
                    variant={formik.values.procedureType === 'Pre Requisite' ? 'contained' : 'outlined'}
                    sx={{
                      brorderRadius: '6px 0px 0px 6px',
                      textTransform: 'none',
                    }}
                  >
                    {systemPrerequisite as string}
                  </Button>
                  <Button
                    disableElevation
                    id={'procedureType'}
                    value={'Core Procedure'}
                    onClick={(e) => {
                      formik.handleChange(e);
                      fileUpload.dispatch({
                        type: 'setProcedureType',
                        payload: 'Add Core Procedure',
                      });
                    }}
                    variant={formik.values.procedureType === 'Core Procedure' ? 'contained' : 'outlined'}
                    sx={{
                      brorderRadius: '0px 6px 6px 0px',
                      textTransform: 'none',
                    }}
                  >
                    {systemCoreProcedure as string}
                  </Button>
                </ButtonGroup>
              </FormControl>
              <FormControl sx={{ width: '100%' }}>
                {formik.values.procedureType === 'Pre Requisite' && (
                  <FormLabel sx={{ color: 'rgba(28, 29, 31, .8)' }} component="legend" className="formStyle new-procedure-msg-text">
                    {t('ADD_WI.PREREQUISITE_MESSAGE') as string}
                  </FormLabel>
                )}
                {formik.values.procedureType === 'Core Procedure' && (
                  <FormLabel sx={{ color: 'rgba(28, 29, 31, .8)' }} component="legend" className="formStyle new-procedure-msg-text">
                    {t('ADD_WI.CORE_PROCEDURE_MESSAGE') as string}
                  </FormLabel>
                )}
              </FormControl>
            </>
          )}
          <FormControl sx={{ width: '100%' }} className="tour-proc-name">
            {props.workInstructionData !== null && (
              <FormLabel
                component="legend"
                className="formStyle"
                sx={{
                  color: 'rgba(28, 29, 31, .8)',
                  fontSize: '12px',
                  fontWeight: '500',
                  paddingBottom: '8px',
                }}
              >
                {t('ADD_WI.PROCEDURE_NAME') as string}
              </FormLabel>
            )}
            {props.workInstructionData === null && (
              <FormLabel sx={{ color: 'rgba(28, 29, 31, .8)' }} component="legend" className="formStyle new-procedure-type">
                {t('COMMON.NAME')}
              </FormLabel>
            )}
            <TextField label="" name="procedureName" value={formik.values.procedureName} onChange={formik.handleChange} placeholder={t('ADD_WI.PRODUCTS_AFFECTED')} id="component-outlined" style={{ borderRadius: '8px !important' }} inputProps={{ maxLength: 55 }} required />
            <FormHelperText id="component-helper-text" className="helperText">
              {55 - formik.values.procedureName.length} {t('COMMON.CHARACTERS_LEFT') as string}
            </FormHelperText>
          </FormControl>
          <FormControl sx={{ width: '100%', marginTop: '14px' }} className="tour-proc-lang">
            <FormLabel
              component="legend"
              className="formStyle"
              sx={{
                color: 'rgba(28, 29, 31, .8)',
                fontSize: '12px',
                fontWeight: '500',
                paddingBottom: '8px',
              }}
            >
              {t('PROCEDURE.LANGUAGE')}
              {/* {props.workInstructionData !== null && (t('ADD_WI.PROCEDURE_LANGUAGE') as string)}
              {props.workInstructionData === null && (formik.values.procedureType === 'Pre Requisite' ? t('ADD_WI.PREREQUISITE_LANGUAGE', { systemPrerequisite }) : t('ADD_WI.CORE_PROCEDURE_LANGUAGE', { systemCoreProcedure }))} */}
            </FormLabel>
            <Autocomplete
              id="selectedOption"
              autoHighlight
              disabled={!!props?.procedureDetails?.versionId}
              options={languages.sort((a, b) => -b.name.localeCompare(a.name))}
              getOptionLabel={(option) => option.name || ''}
              value={formik.values.language ?? { code: 'en', name: 'English' }}
              onChange={(event, value) => {
                formik.setFieldValue('language', value);
              }}
              renderInput={(params) => (
                <Box component="div">
                  <TextField {...params} value={formik.values.language} placeholder={t('PROCEDURE.ENTER_HERE')} />
                </Box>
              )}
            />
          </FormControl>
          <FormControl sx={{ width: '100%', marginTop: '24px' }}>
            <FormLabel
              component="legend"
              className="formStyle"
              sx={{
                color: 'rgba(28, 29, 31, .8)',
                fontSize: '12px',
                fontWeight: '500',
                paddingBottom: '15px',
              }}
            >
              {t('ADD_WI.CYCLE_TIME')}
            </FormLabel>
            <Box component="div" sx={{ display: 'flex' }}>
              <TextField type="number" label={t('ADD_WI.HOURS')} name="hours" value={formik.values.hours} onChange={formik.handleChange} InputProps={{ inputProps: { min: 0 } }} />
              <TextField type="number" label={t('ADD_WI.MINUTES')} name="minutes" value={formik.values.minutes} sx={{ ml: 2, mr: 2 }} InputProps={{ inputProps: { min: 0 } }} onChange={formik.handleChange} />
              <TextField type="number" label={t('ADD_WI.SECONDS')} name="seconds" value={formik.values.seconds} InputProps={{ inputProps: { min: 0 } }} onChange={formik.handleChange} />
            </Box>
          </FormControl>

          <FormControl sx={{ width: '100%', marginTop: '24px' }} className="tour-doc-id">
            <FormLabel
              component="legend"
              className="formStyle"
              sx={{
                color: 'rgba(28, 29, 31, .8)',
                fontSize: '12px',
                fontWeight: '500',
                paddingBottom: '8px',
              }}
            >
              {t('ADD_WI.DOCUMENT_ID')}
            </FormLabel>
            <TextField value={formik.values.documentId} name="documentId" id="outlined-multiline-static" onChange={formik.handleChange} placeholder="SCB4352" />
          </FormControl>
          <FormControl sx={{ width: '100%', marginTop: '24px' }} className="tour-doc-id">
            <FormLabel
              component="legend"
              className="formStyle"
              sx={{
                color: 'rgba(28, 29, 31, .8)',
                fontSize: '12px',
                fontWeight: '500',
                paddingBottom: '8px',
              }}
            >
              {t('ADD_WI.ASSIGN_SIGN_OFF')}
            </FormLabel>
            {!managersLoading ? (
              <>
                <Autocomplete
                  id="selectedOption"
                  autoHighlight
                  options={approvalManagers}
                  disabled={signOffSelector}
                  getOptionLabel={(option: any) => option['email'] || ''}
                  value={formik.values.signOffUsers[formik.values.signOffUsers.length - 1] ?? { email: '', userId: '' }}
                  onChange={onInputChangeSignOffUser}
                  renderInput={(params) => (
                    <Box component="div">
                      <TextField sx={{ backgroundColor: signOffSelector ? 'rgba(0, 0, 0, 0.1)' : undefined }} {...params} value={formik.values.signOffUsers[formik.values.signOffUsers.length - 1]?.email} placeholder={t('NAVBAR.SELECT_EMAIL')} />
                    </Box>
                  )}
                />
              </>
            ) : (
              <Skeleton sx={{ height: '60px', width: '100%' }} />
            )}
          </FormControl>
          <Stack direction="row" justifyContent="space-between" alignItems="flex-start" sx={{ py: 1 }}>
            <Box component="span" className="helperText" sx={{ color: 'rgba(28, 29, 31, .8)', textAlign: 'start' }}>
              {t('ADD_WI.MAXIMUM_NUMBER_OF_SIGN_OFF_MANAGERS_IS_2')}
            </Box>
          </Stack>
          <List dense={false} sx={{ paddingBottom: '0px' }}>
            {formik?.values?.signOffUsers ? (
              formik?.values?.signOffUsers?.map((file, index) => (
                <StyledListItem
                  key={index}
                  sx={{ borderRadius: '8px', backgroundColor: '#024789', color: 'white' }}
                  secondaryAction={
                    <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteSignOff(index)}>
                      <CancelOutlinedIcon sx={{ width: 16, height: 16, color: 'white' }} />
                    </IconButton>
                  }
                >
                  <Typography sx={{ fontSize: '12px' }}>{file.email}</Typography>
                </StyledListItem>
              ))
            ) : (
              <></>
            )}
          </List>
          <FormControl sx={{ width: '100%', marginTop: '24px' }} className="tour-cad-asset">
            <FormLabel
              component="legend"
              className="formStyle"
              sx={{
                color: 'rgba(28, 29, 31, .8)',
                fontSize: '12px',
                fontWeight: '500',
                paddingBottom: '8px',
              }}
            >
              {t('ADD_WI.UPLOAD_REFERENCE_DOCUMENT')}
            </FormLabel>
            <Dropzone
              acceptedFiles={['.pdf']}
              selectedFiles={props.fileData}
              maxFileSize={environment.AppConfigs.fileUploadLimit.procedureReferenceDoc}
              fileUploadValues={(data) => {
                setFileValues(data);
              }}
              newProcedureDropzone={true}
              updatedProcedureDetails={procedureDetails}
            />
            <Stack direction="row" justifyContent="space-between" alignItems="flex-start" sx={{ py: 1 }} spacing={2}>
              <Box component="span" className="helperText" sx={{ color: 'rgba(28, 29, 31, .8)' }}>
                {`${t('DROPZONE.MAX_FILES')}${' '}10`}
              </Box>
              <Box component="span" className="helperText" sx={{ color: 'rgba(28, 29, 31, .8)' }}>
                {t('COMMON.SUPPORTS') as string} ".pdf"
              </Box>
            </Stack>

            <FormLabel
              component="legend"
              className="formStyle"
              sx={{
                color: 'rgba(28, 29, 31, .8)',
                fontSize: '12px',
                fontWeight: '500',
                marginTop: '20px',
              }}
            >
              {t('ADD_WI.UPLOADED')} {props.procedureDetails ? `(${(procedureDetails ? procedureDetails.length : 0) + filesSelectedCount})` : `(${fileValues.length})`}
            </FormLabel>
            <List dense={false}>
              {props?.procedureDetails
                ? procedureDetails.map((file, index) => (
                    <StyledListItem
                      key={file?.fileId}
                      sx={{ borderRadius: '8px' }}
                      secondaryAction={
                        <IconButton edge="end" aria-label="delete" onClick={() => handleEditProcDelete(file)}>
                          <DeleteIconRed sx={{ width: 16, height: 16, color: '#303030' }} />
                        </IconButton>
                      }
                    >
                      <Typography sx={{ fontSize: '12px' }}>{file.name}</Typography>
                    </StyledListItem>
                  ))
                : fileValues.map((file, index) => (
                    <StyledListItem
                      key={index}
                      sx={{ borderRadius: '8px' }}
                      secondaryAction={
                        <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(index)}>
                          <DeleteIconRed sx={{ width: 16, height: 16, color: '#303030' }} />
                        </IconButton>
                      }
                    >
                      <Typography sx={{ fontSize: '12px' }}>{file.name}</Typography>
                    </StyledListItem>
                  ))}

              {props?.procedureDetails && fileValues ? (
                fileValues.map((file, index) => (
                  <StyledListItem
                    key={index}
                    sx={{ borderRadius: '8px' }}
                    secondaryAction={
                      <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(index)}>
                        <DeleteIconRed sx={{ width: 16, height: 16, color: '#303030' }} />
                      </IconButton>
                    }
                  >
                    <Typography sx={{ fontSize: '12px' }}>{file.name}</Typography>
                  </StyledListItem>
                ))
              ) : (
                <></>
              )}
            </List>
          </FormControl>
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end', margin: '12px' }}>
          {props.showBackButton && (
            <IconButton size="small" sx={{ float: 'right', borderRadius: 1 }} title="Back" onClick={handleBackButtonClick}>
              <LeftArrow />
            </IconButton>
          )}
          <Button
            size="small"
            variant="contained"
            sx={{ float: 'right' }}
            type="submit"
            disabled={!isValidForm()}
            onClick={() => {
              if (props?.procedureDetails && deleteRecord.length !== 0) {
                deleteSelectedReferenceDoc();
              }
              setErrorMessage('');
            }}
            className="tour-proc-next"
          >
            {t('BUTTON.NEXT') as string}
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};

export default NewProcedure;
