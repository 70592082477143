import React, { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TrainerProcedureCard from '../TrainerProcedureCard/TrainerProcedureCard';
import { Button, CircularProgress, ClickAwayListener, Grid, Grow, MenuItem, MenuList, Paper, Popper, useTheme } from '@mui/material';
import './TrainerProcedureTab.scss';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useFileUpload } from '../../../../shared/context';
import { CoreProcedureIcon, PreRequisiteIcon } from '../../../Icons/CdsIcons';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import TrainerService from '../../../../services/trainer/TrainerService';
import { useTranslation } from 'react-i18next';
import useCurrentTranslation from '../../../../hooks/useCurrentTranslation';
import moment from 'moment';
import UserContext from '../../../../context/UserContext';
import { ExpandMore, InfoOutlined } from '@mui/icons-material';
import { BasicDialog } from '../../../Trainer/Dialog/BasicDialog/BasicDialog';
import { ProcedureLibraryTable } from '../../../../pages/Trainer/ProcedureLibrary/ProcedureLibrary';
import { importFromLibrary } from '../../../../services/ModularProcedure/ModularProcedure';
import { PROCEDURE_VISIBILITY_SCOPE } from '../../../../shared/enums';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import { ProcedureLibrarySearchSortFilterOptions } from '../../../../pages/Trainer/ProcedureLibrary/ProcedureLibraryToolbar';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

interface Props {
  procedureDetails: any;
  workInstruction: any;
  status: string;
  isDelete?: boolean;
  setProcedures?: any;
  isRevisitRequest?: boolean;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box component="div">
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const TrainerProcedureTab = (props) => {
  const [value, setValue] = useState<number>(0);
  const [preRequisiteCount, setPreRequisiteCount] = useState<number>(0);
  const [coreProcedureCount, setCoreProcedureCount] = useState<number>(0);
  const [buttonText, setButtonText] = useState('Add Prerequisite');
  const [preRequisites, setPreRequisites] = useState(Array<any>);
  const [coreProcedures, setCoreProcedures] = useState(Array<any>);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSelectTemplateProcedureDialogOpen, setIsSelectTemplateProcedureDialogOpen] = useState(false);
  const [selectedTemplateProcedures, setSelectedTemplateProcedures] = useState<string[]>([]);
  const procedureType = useFileUpload();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const theme = useTheme();
  const { companyId } = useParams();

  const reorder = (orderArr) => {
    const reorderBody = {
      id: props.workInstruction.workInstructionId,
      organizationId: companyId,
      order: orderArr,
    };
    TrainerService.reorderProcedure(props.workInstruction.workInstructionId, reorderBody).then((res) => {
      console.log('procedure reordered');
    });
  };

  useEffect(() => {
    if (props.language === 'default') {
      setPreRequisites(props.procedureDetails.filter((data) => data.isPrerequisite === true && !data.language));
      setCoreProcedures(props.procedureDetails.filter((data) => data.isPrerequisite !== true && !data.language));
    } else if (props.language === 'all') {
      setPreRequisites(props.procedureDetails.filter((data) => data.isPrerequisite === true));
      setCoreProcedures(props.procedureDetails.filter((data) => data.isPrerequisite !== true));
    } else {
      setPreRequisites(props.procedureDetails.filter((data) => data.isPrerequisite === true && data.language?.name?.toLowerCase() === props.language?.toLowerCase()));
      setCoreProcedures(props.procedureDetails.filter((data) => data.isPrerequisite !== true && data.language?.name?.toLowerCase() === props.language?.toLowerCase()));
    }
  }, [props.procedureDetails, props.language]);

  useEffect(() => {
    let preRequisiteCount: number = 0,
      coreProcedureCount: number = 0;
    if (props.procedureDetails) {
      for (const procedure of props.procedureDetails) {
        if (procedure.isPrerequisite) {
          preRequisiteCount += 1;
        } else {
          coreProcedureCount += 1;
        }
      }
      setCoreProcedureCount(coreProcedureCount);
      setPreRequisiteCount(preRequisiteCount);
    }
  }, [props.procedureDetails]);

  useEffect(() => {
    if (procedureType.state.isPrerequisite === '' && (preRequisiteCount > coreProcedureCount)) {
      procedureType.dispatch({
        type: 'setProcedureType',
        payload: 'Pre Requisite',
      });
    } else if (procedureType.state.isPrerequisite === '' && (coreProcedureCount > preRequisiteCount)) {
      procedureType.dispatch({
        type: 'setProcedureType',
        payload: 'Core Procedure',
      });
    }
  }, [preRequisiteCount, coreProcedureCount, procedureType.state.isPrerequisite]);

  useEffect(() => {
    if (procedureType.state.isPrerequisite.indexOf('Pre Requisite') > -1) {
      setButtonText('Add Prerequisite');
      setValue(0);
    } else if (procedureType.state.isPrerequisite.indexOf('Core Procedure') > -1) {
      setButtonText('Add Core Procedure');
      setValue(1);
    }
  }, [procedureType.state.isPrerequisite]);

  const revisitPath = RegExp('/trainer/RevisitProcedures');

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (newValue === 0) {
      setButtonText('Add Prerequisite');
      procedureType.dispatch({
        type: 'setProcedureType',
        payload: 'Add Pre Requisite',
      });
    } else {
      setButtonText('Add Core Procedure');
      procedureType.dispatch({
        type: 'setProcedureType',
        payload: 'Add Core Procedure',
      });
    }
  };
  const populateLabel = (buttonText) => {
    return buttonText === 'Add Prerequisite' ? `${t('BUTTON.ADD')}${' '}${systemPrerequisite}` : `${t('BUTTON.ADD')}${' '}${systemCoreProcedure}`;
  };

  const isExternalPageOpenedHandler = (value) => {
    setButtonDisable(value);
  };

  const reloadPage = () => {
    window.location.reload();
  };

  const location = useLocation();
  const { currentUser } = useContext(UserContext);
  const { refreshProcedure, setRefreshProcedure } = useCurrentUser();
  const { currentTranslation } = useCurrentTranslation();
  const { i18n } = useTranslation();
  const addProcedureButton = useRef(null);
  const currentLanguage = i18n.language.toUpperCase();
  const systemPrerequisite = currentTranslation?.PREREQUISITES ? currentTranslation.PREREQUISITES[currentLanguage] : `${t('PROCEDURE.PRE_REQUISITES')}`;
  const systemCoreProcedure = currentTranslation?.CORE_PROCEDURES ? currentTranslation.CORE_PROCEDURES[currentLanguage] : `${t('PROCEDURE.CORE_PROCEDURES')}`;

  const handleMenuClose = () => {
    setIsMenuOpen(false)
  }
  const handleCreateNewProcedureClick = () => {
    navigate(`/trainer/addProcedure/${props.workInstruction.workInstructionId}`)
  }
  const handleImportTemplateProcedureClick = () => {
    setIsSelectTemplateProcedureDialogOpen(true);
  }

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setIsMenuOpen(false);
    } else if (event.key === 'Escape') {
      setIsMenuOpen(false);
    }
  }

  function handleRowClick(row){
    let temp = [...selectedTemplateProcedures];
    const clickedProcedureId = row.original.procedureId;

    if(temp.includes(clickedProcedureId)){
      temp = temp.filter(proc => proc !== clickedProcedureId);
    } else {
      temp.push(clickedProcedureId) 
    }
    setSelectedTemplateProcedures(temp);
  }

  const handleAddClick = () => {
    // add logic to create reference procedure 
    let promiseArray = selectedTemplateProcedures.map(each => importFromLibrary({
      visibilityScope: PROCEDURE_VISIBILITY_SCOPE.REFERENCE,
      referencedProcedure: each,
      workInstructionId: props.workInstruction.workInstructionId,
      isPrerequisite: procedureType.state.isPrerequisite.indexOf('Pre Requisite') > -1 ? true : false
    }));
      
    return Promise.allSettled(promiseArray).then(results => {
        results.forEach(result => {
          if(result.status !== 'fulfilled'){
            console.log('Failed to move');
          }
        })
      }).then(() => {
        setRefreshProcedure(!refreshProcedure)
        handleClose();
      })
  }

  const handleClose = () => {
    setSelectedTemplateProcedures([])
    setIsSelectTemplateProcedureDialogOpen(false);
  }
  return (
    <>
      <Box component="div" sx={{ width: '100%' }}>
        <Box component="div" sx={{ width: '100%', display:'flex', alignItems:'center', justifyContent: 'space-between' }}>
          <Tabs value={value} onChange={handleChange} aria-label="tabs">
            <Tab
              sx={{
                color: theme.palette.primary.main,
                opacity: '0.5',
                '&.Mui-selected': {
                  color: theme.palette.primary.main,
                  opacity: '1',
                },
              }}
              className="single-line"
              label={
                <>
                  <Box component="span">{systemPrerequisite}</Box>
                  {!props.isRevisitRequest && (
                    <Box className="tab-count-border" component="span">
                      {preRequisites.length}{' '}
                    </Box>
                  )}
                </>
              }
              {...a11yProps(0)}
            />
            <Tab
              sx={{
                color: theme.palette.primary.main,
                opacity: '0.5',
                '&.Mui-selected': {
                  color: theme.palette.primary.main,
                  opacity: '1',
                },
              }}
              className="single-line"
              label={
                <>
                  <span>{systemCoreProcedure}</span>
                  {!props.isRevisitRequest && <span className="tab-count-border">{coreProcedures.length} </span>}
                </>
              }
              {...a11yProps(1)}
            />
          </Tabs>
          <Box component="div" sx={{display: 'flex', gap: '5px'}}>
            {buttonDisable && (
              <Button variant="contained" onClick={reloadPage}>
                {t('PROCEDURE.REFRESH')}
              </Button>
            )}
            {!props.isRevisitRequest && !props.isDelete && (
              <Box component="div" sx={{ display: 'flex', flexDirection: 'row' }}>
                <Button variant="contained" ref={addProcedureButton} onClick={() => setIsMenuOpen(true)} endIcon={<ExpandMore />}>
                  {t('BUTTON.ADD')}
                </Button>
                <Popper open={isMenuOpen} anchorEl={addProcedureButton.current} role={undefined} placement="bottom-start" transition disablePortal sx={{ zIndex: 1 }}>
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleMenuClose}>
                          <MenuList autoFocusItem={isMenuOpen} aria-labelledby="add-procedure-menu-list" onKeyDown={handleListKeyDown}>
                            <MenuItem onClick={handleCreateNewProcedureClick}>
                              <Box component={'div'}>{t('PROCEDURE_LIBRARY.TOOLBAR.ADD_PROCEDURE_DROPDOWN.CREATE')}</Box>
                            </MenuItem>
                            <MenuItem onClick={handleImportTemplateProcedureClick}>
                              <Box component={'div'}>{t('PROCEDURE_LIBRARY.IMPORT_PROCEDURE_DIALOG.TITLE')}</Box>
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Box>
             )}
          </Box>
        </Box>

        <TabPanel value={value} index={0}>
          {!!preRequisiteCount && preRequisiteCount > 0 && (
            <DragDropContext
              onDragEnd={(param) => {
                const srcI = param.source.index;
                const desI = param.destination?.index === undefined ? srcI : param.destination?.index;

                preRequisites.splice(desI, 0, preRequisites.splice(srcI, 1)[0]);

                const pID: Array<string> = [];
                const cID: Array<string> = [];

                preRequisites.map((p, index) => {
                  pID.push(p.procedureId);
                });

                coreProcedures.map((p, index) => {
                  cID.push(p.procedureId);
                });

                reorder([...pID, ...cID]);
              }}
            >
              <Box component="div" className={'tab-pannel-scroll-no-msg'}>
                <Droppable droppableId="droppable-1">
                  {(provided, _) => {
                    return (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {preRequisites && (
                          <div>
                            {preRequisites.map((p, index) => {
                              return (
                                p.isPrerequisite &&
                                ((p?.revisitExpiry !== null && revisitPath.test(location.pathname) && p?.lastRevisitedOn + p?.revisitExpiry * 60 * 60 * 24 < moment().unix() && !p?.revisitComments && p?.revisitSentTo === currentUser.userId) || !revisitPath.test(location.pathname)) && (
                                  <Draggable key={p.procedureId} draggableId={p.procedureId.toString()} index={index}>
                                    {(provided, snapshot) => {
                                      return (
                                        <div ref={provided.innerRef} {...provided.draggableProps} className="row">
                                          <div>
                                            <TrainerProcedureCard
                                              isExternalPageOpened={(value) => {
                                                isExternalPageOpenedHandler(value);
                                              }}
                                              provided={provided}
                                              snapshot={snapshot}
                                              status={props.status}
                                              procedureDetails={p}
                                              key={index}
                                              authored={p.isPublished}
                                              title={p.procedureName}
                                              workInstruction={props.workInstruction}
                                              isDelete={props.isDelete}
                                              setProcedures={props.setProcedures}
                                              isRevisitRequest={props.isRevisitRequest}
                                            />
                                          </div>
                                        </div>
                                      );
                                    }}
                                  </Draggable>
                                )
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        )}
                      </div>
                    );
                  }}
                </Droppable>
              </Box>
            </DragDropContext>
          )}

          {!props.isRevisitRequest && !props.isDelete && !preRequisiteCount && (
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: '3rem' }}>
              <Box component="div">
                <PreRequisiteIcon />
              </Box>
              <Box
                component="div"
                sx={{
                  fontSize: '1.5rem',
                  lineHeight: '1.5',
                  margin: '.8rem 0rem',
                }}
              >
                {t('ADD_WI.PROCEDURE_NO_PRE_REQUISITE', { systemPrerequisite })}
              </Box>
              <Box component="div" sx={{ color: 'rgba(28, 29, 31, 0.6)', marginBottom: '1.3rem' }}>
                {t('PROCEDURE.EMPTY_PRE_REQUISITE_MESSAGE_LINE1', { systemPrerequisite })}
                <br />
                {t('PROCEDURE.EMPTY_PRE_REQUISITE_MESSAGE_LINE2')}
              </Box>
              {/* <Link to={`/trainer/addProcedure/${props.workInstruction.workInstructionId}`}>
                <Button disabled={buttonDisable} className="proctab-left-margin" variant="contained">
                  {populateLabel(buttonText)}
                </Button>
              </Link> */}
            </Grid>
          )}
          {props.isDelete && !preRequisiteCount && (
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: '3rem' }}>
              <Box component="div">
                <PreRequisiteIcon />
              </Box>
              <Box
                component="div"
                sx={{
                  fontSize: '1.5rem',
                  lineHeight: '1.5',
                  margin: '.8rem 0rem',
                }}
              >
                {t('PROCEDURE.NO_PROCEDURE_PRE_REQUISITE_DELETED', { systemPrerequisite })}
              </Box>
              <Box component="div" sx={{ color: 'rgba(28, 29, 31, 0.6)', marginBottom: '1.3rem' }}>
                {t('PROCEDURE.EMPTY_PRE_REQUISITE_MESSAGE_LINE1', { systemPrerequisite })}
                <br />
                {t('PROCEDURE.EMPTY_PRE_REQUISITE_MESSAGE_LINE2')}
              </Box>
            </Grid>
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {coreProcedureCount > 0 && (
            <DragDropContext
              onDragEnd={(param) => {
                const srcI = param.source.index;
                const desI = param.destination?.index === undefined ? srcI : param.destination?.index;
                coreProcedures.splice(desI, 0, coreProcedures.splice(srcI, 1)[0]);

                const pID: Array<string> = [];
                const cID: Array<string> = [];

                coreProcedures.map((p, index) => {
                  cID.push(p.procedureId);
                });

                preRequisites.map((p, index) => {
                  pID.push(p.procedureId);
                });

                reorder([...pID, ...cID]);
              }}
            >
              <Box component="div" className={'tab-pannel-scroll-no-msg'}>
                <Droppable droppableId="droppable-2">
                  {(provided, _) => {
                    return (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {coreProcedures && (
                          <div>
                            {coreProcedures.map((p, index) => {
                              return (
                                !p.isPrerequisite &&
                                ((p?.revisitExpiry !== null && revisitPath.test(location.pathname) && p?.lastRevisitedOn + p?.revisitExpiry * 60 * 60 * 24 < moment().unix() && !p?.revisitComments && p?.revisitSentTo === currentUser.userId) || !revisitPath.test(location.pathname)) && (
                                  <Draggable key={p.procedureId} draggableId={p.procedureId.toString()} index={index}>
                                    {(provided, snapshot) => {
                                      return (
                                        <div ref={provided.innerRef} {...provided.draggableProps}>
                                          <TrainerProcedureCard
                                            isExternalPageOpened={(value) => {
                                              isExternalPageOpenedHandler(value);
                                            }}
                                            status={props.status}
                                            procedureDetails={p}
                                            key={index}
                                            provided={provided}
                                            authored={p.isPublished}
                                            title={p.procedureName}
                                            workInstruction={props.workInstruction}
                                            isDelete={props.isDelete}
                                            setProcedures={props.setProcedures}
                                            isRevisitRequest={props.isRevisitRequest}
                                          />
                                        </div>
                                      );
                                    }}
                                  </Draggable>
                                )
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        )}
                      </div>
                    );
                  }}
                </Droppable>
              </Box>
            </DragDropContext>
          )}
          {!coreProcedureCount && (
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: '3rem' }}>
              <Box component="div">
                <CoreProcedureIcon />
              </Box>
              <Box
                component="div"
                sx={{
                  fontSize: '1.5rem',
                  lineHeight: '1.5',
                  margin: '.8rem 0rem',
                }}
              >
                {!props.isRevisitRequest && !props.isDelete ? `${t('PROCEDURE.NO_CORE', { systemCoreProcedure })}` : `${t('PROCEDURE.NO_CORE_DELETED', { systemCoreProcedure })}`}
              </Box>
              <Box component="div" sx={{ color: 'rgba(28, 29, 31, 0.6)', marginBottom: '1.3rem' }}>
                {!props.isRevisitRequest && !props.isDelete ? `${t('PROCEDURE.EMPTY_CORE_MESSAGE', { systemCoreProcedure })}` : `${t('PROCEDURE.EMPTY_CORE_MESSAGE')}`}
              </Box>
              {/* <Link to={`/trainer/addProcedure/${props.workInstruction.workInstructionId}`}>
                <Button disabled={buttonDisable} className="proctab-left-margin" variant="contained">
                  {populateLabel(buttonText)}
                </Button>
              </Link> */}
            </Grid>
          )}
        </TabPanel>
      </Box>
      <ProcedureTableModal
        isOpen={isSelectTemplateProcedureDialogOpen}
        handleClose={handleClose}
        handleRowClick={handleRowClick}
        selectedTemplateProcedures={selectedTemplateProcedures}
        handleAddClick={handleAddClick}
      />
    </>
  );
};

export default TrainerProcedureTab;


const ProcedureTableModal = (props) => {
  const {isOpen, handleClose, handleRowClick, selectedTemplateProcedures, handleAddClick} = props;
  const {t} = useTranslation();

  const checkIsRowSelected = (row) => {
    return selectedTemplateProcedures.includes(row.original.procedureId);
  }

  const checkIsRowDisabled = (row) => {
    return !row.original.isPublished;
  }
  return (
    <BasicDialog onClose={handleClose} onOpen={isOpen} title={t('PROCEDURE_LIBRARY.IMPORT_PROCEDURE_DIALOG.TITLE')} id="select-entity" fullWidth={true} maxWidth={'lg'} actions={<DialogueActionContainer handleClose={handleClose} handleAddClick={handleAddClick} selectedProcedures={selectedTemplateProcedures} />}>
      <Box component={'div'} sx={{ 
        display: 'flex',
        gap: "10px",
        marginBottom: '10px',
        '> .sort': {
          marginLeft: 'auto'
        }
      }}>
        <ProcedureLibrarySearchSortFilterOptions />
      </Box>
      <ProcedureLibraryTable handleRowClick={handleRowClick} showProcedureOptions={false} checkIsRowSelected={checkIsRowSelected} checkIsRowDisabled={checkIsRowDisabled} scrollableDivId={'dialogue-content-select-entity'}/>
    </BasicDialog>
  );
}

const DialogueActionContainer = (props) => {
  const { t } = useTranslation();
  const { handleClose, handleAddClick, selectedProcedures } = props;

  const [isAddClicked, setIsAddClicked] = useState(false);

  const handleAddButtonClick = () => {
    setIsAddClicked(true);
    handleAddClick().then(() => {
      setIsAddClicked(false);
    })
  }
  return (
    <>
      <Box component={'div'} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px', marginRight: 'auto', height: '60px' }}>
        <InfoOutlined />
        <span>{t('PROCEDURE_LIBRARY.IMPORT_PROCEDURE_DIALOG.ONLY_PUBLISHED_SUPPORTED')}</span>
      </Box>
      <Button variant="outlined" onClick={handleClose}>
        {t('BUTTON.CANCEL')}
      </Button>
      <Button 
        endIcon={isAddClicked ? <CircularProgress size={20} /> : null}
        disabled={!selectedProcedures.length || isAddClicked} 
        variant="contained" 
        onClick={handleAddButtonClick} 
        autoFocus
      >
        {t('BUTTON.ADD')} {selectedProcedures.length ? ` (${selectedProcedures.length})` : null}
      </Button>
    </>
  );
};