import React from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import './TraineeAssignedDetailsHeader.scss';
import { SelectionType } from '../../../../states/analytics';

interface TraineeAssignedDetailsHeaderProps {
  title: string;
  subtitle: string;
  onFilterTypeClick: (filterType: SelectionType) => void;
}

export const TraineeAssignedDetailsHeader: React.FC<
  TraineeAssignedDetailsHeaderProps
> = ({ title, subtitle, onFilterTypeClick }) => {
  return (
    <Box
      component="div"
      className="trainee-assigned-details-header"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box component="div">
        <Stack
          direction="column"
          alignItems="center"
          sx={{ alignItems: 'flex-start' }}
        >
          <Typography fontSize={'24px'}>{title}</Typography>
          <Typography
            variant="body1"
            sx={{
              color: '#1C1D1F',
              opacity: '.5',
              border: '1px solid #D9D9D9',
              borderRadius: '2px',
              fontSize: '.75em',
              padding: '0 .25em',
            }}
          >
            {subtitle}
          </Typography>
        </Stack>
      </Box>
      <Box component="div" className="filter-buttons">
        {/* <Button size="small" variant="outlined">
          This Month
        </Button>
        <Button size="small" variant="outlined">
          Last Month
        </Button>
        <Button size="small" variant="outlined">
          Custom
        </Button> */}
      </Box>
    </Box>
  );
};

export default TraineeAssignedDetailsHeader;
