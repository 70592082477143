import { Backdrop, Box, Button, Checkbox, FormControl, IconButton, InputAdornment, TextField, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BasicDialog } from '../BasicDialog/BasicDialog';
import TrainerService from '../../../../services/trainer/TrainerService';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import Loading from '../../../Loading/Loading';
import { AccountCircle, CheckBox, Input, Search } from '@mui/icons-material';
import FuzzySearch from 'fuzzy-search';
import { RevokeConfirmationDialog } from '../RevokeConfirmationDialog/RevokeConfirmationDialog';
import { name } from '@azure/msal-common/dist/packageMetadata';
import SuccessDialog from '../SuccessDialog/SuccessDialog';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { Toast } from '../../../Notifications/Notification';
import CloseIcon from '@mui/icons-material/Close';

export const RevokeAccess = (props) => {
  const [loading, setLoading] = useState(false);
  const [assigneeList, setAssigneeList] = useState<any>([]);
  const [filteredAssigneeList, setFilteredAssigneeList] = useState<any>([]);
  const [revokeList, setRevokeList] = useState<any>([]);
  const [showRevokeConfirmation, setShowRevokeConfirmation] = useState<boolean>(false);
  const [showRevokeSuccess, setShowRevokeSuccess] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const theme = useTheme();
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    TrainerService.getAssignees(props.procedureId)
      .then((response) => {
        setAssigneeList(response.data.data);
        setFilteredAssigneeList(response.data.data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const userIds = assigneeList.filter((item) => item.checked).map((item) => item.id);
    setRevokeList(userIds);
    search(searchKey);
  }, [searchKey, assigneeList]);

  const handleChange = (event, id) => {
    if (event.target.checked) {
      setAssigneeList((prev) => prev.map((item) => (item.id === id ? { ...item, checked: true } : item)));
    } else {
      setAssigneeList((prev) => prev.map((item) => (item.id === id ? { ...item, checked: false } : item)));
    }
  };

  const search = (key: string) => {
    if (assigneeList) {
      const searcher = new FuzzySearch(assigneeList, ['firstName', 'lastName', 'email'], {
        caseSensitive: false,
      });
      const result = searcher.search(key);
      setFilteredAssigneeList(result);
    }
  };

  const commonStyles = {
    border: 1,
    borderColor: 'text.primary',
  };

  const revokeAssignees = () => {
    setLoading(true);

    TrainerService.revokeAssignees(props.procedureId, { users: revokeList })
      .then((res) => {
        setLoading(false);
        setShowRevokeSuccess(true);
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        setLoading(false);
      });
  };

  return (
    <>
      {showRevokeSuccess && (
        <BasicDialog
          onClose={() => {
            setShowRevokeConfirmation(false);
            props.closeDialog();
          }}
          onOpen={showRevokeSuccess}
        >
          <Box component="div" sx={{ alignItems: 'flex-end' }}>
            <IconButton
              aria-label="close"
              onClick={() => {
                setShowRevokeConfirmation(false);
                props.closeDialog();
              }}
              sx={{
                color: (theme) => theme.palette.grey[500],
                padding: 0,
                position: 'absolute',
                right: '15px',
                top: '15px',
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Box component="div" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography>
              <CheckCircleRoundedIcon sx={{ fontSize: 60, color: 'green' }} />
            </Typography>
            <Typography>
              Access for {revokeList.length} {revokeList.length > 1 ? 'trainees' : 'trainee'} was removed successfully
            </Typography>
          </Box>
        </BasicDialog>
      )}

      {showRevokeConfirmation && <RevokeConfirmationDialog closeDialog={() => setShowRevokeConfirmation(false)} openDialog={showRevokeConfirmation} revokeAssignees={revokeAssignees} />}
      <BasicDialog
        title={`${t("REMOVE_ACCESS_MODAL.TITLE")} (${assigneeList.length})`}
        onClose={() => {
          setShowRevokeConfirmation(false);
          props.closeDialog();
        }}
        onOpen={props.openDialog}
        actions={
          <>
            <Button
              onClick={() => {
                setShowRevokeConfirmation(true);
              }}
              disabled={revokeList.length < 1}
              variant="contained"
              size="small"
              type="submit"
            >
              {t("PROCEDURE.REMOVE_ACCESS")}
            </Button>
          </>
        }
      >
        <Box component="div" sx={{ width: '530px', overflow: 'hidden' }}>
          {loading && <Loading />}
          <Box component="div" sx={{ display: 'flex' }}>
            <FormControl fullWidth sx={{ mb: '30px', mt: '5px' }}>
              <TextField
                onChange={(e) => setSearchKey(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {' '}
                      <Search sx={{ color: 'primary.main', mr: 1, my: 0.5 }} />{' '}
                    </InputAdornment>
                  ),
                }}
                placeholder={t("REMOVE_ACCESS_MODAL.SEARCH_PLACEHOLDER")}
                style={{ borderRadius: '8px !important' }}
              />
            </FormControl>
          </Box>
          <Box component="div" sx={{ overflowY: 'auto' }}>
            {filteredAssigneeList.map((assignee, index) => {
              return (
                <Box component="div" key={assignee.id} sx={{ height: '50px', borderRadius: '8px', border: '1px solid rgba(2, 71, 137, 0.2)', backgroundColor: theme.palette.primary.main + '1c', mb: '16px', display: 'flex', alignItems: 'center', padding: '9px' }}>
                  <Checkbox checked={assignee.checked === true} onChange={(e) => handleChange(e, assignee.id)} />
                  <AccountCircle sx={{ marginRight: '12px' }} />
                  <Box component="div">
                    <Typography sx={{ fontSize: '14px', margin: '0px 4px 4px 0px' }}>{assignee.firstName + assignee.lastName}</Typography>

                    <Typography sx={{ fontSize: '14px' }}>{assignee.email}</Typography>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      </BasicDialog>
    </>
  );
};
