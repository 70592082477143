import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Input,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  InputLabel,
} from "@mui/material"
import { Resource } from "../../entities/User/MockResource"
import CloseIcon from "@mui/icons-material/Close"
import { useEffect, useState } from "react"

interface ResourceFormProps {
  resource?: Resource
  onSubmit: (resource: Resource) => void
  onBack: () => void
  title: string
}

const ResourceForm: React.FC<ResourceFormProps> = (props) => {
  const [name, setName] = useState<string>("")
  const [alias, setAlias] = useState<string>("")
  const [identifier, setIdentifier] = useState<string>("")
  const [description, setDescription] = useState<string>("")
  const [isActive, setIsActive] = useState<boolean>(true)
  useEffect(() => {
    if (props.resource) {
      const resourceData: Resource = props.resource
      //   set resource data
      setName(resourceData.name)
      setAlias(resourceData.alias)
      setIdentifier(resourceData.identifier)
      setDescription(resourceData.description)
      setIsActive(resourceData.isActive)
    }
  }, [props.resource])

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value as string)
  }
  const handleAliasChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAlias(event.target.value as string)
  }
  const handleIdentifierChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIdentifier(event.target.value as string)
  }
  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDescription(event.target.value as string)
  }

  const handleIsActiveChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: boolean
  ) => {
    if (newAlignment !== null) {
      setIsActive(newAlignment)
    }
  }
  const submitHandler = (resourceData) => {
    resourceData.preventDefault()
    props.onSubmit(resourceData)
  }
  const handleClose = () => {
    props.onBack()
  }
  return (
    <>
      <Box component={"div"} sx={{ padding: "1rem" }}>
        <Box component="h2" sx={{ m: 0, p: 2, pt: 1, pb: 1 }}>
          {props.title}
        </Box>
      </Box>
      <Box component="hr" sx={{}}></Box>
      <Box component={"div"} sx={{ padding: "1rem" }}>
        <Box
          component="form"
          sx={{
            padding: { xs: 0, lg: 2 },
            width: "100%",
            textAlign: "left",
          }}
          onSubmit={submitHandler}
        >
          <Box component={"div"} sx={{ minHeight: "30rem" }}>
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={2}
                sx={{ marginBottom: { xs: 0, md: 2 } }}
              >
                <FormControl variant="standard" required fullWidth>
                  <FormLabel>Resource Name</FormLabel>
                  <TextField
                    label=""
                    name="name"
                    value={name}
                    onChange={handleNameChange}
                    placeholder="Enter Resource Name"
                    id="component-outlined"
                    style={{ borderRadius: "8px !important" }}
                    inputProps={{ maxLength: 20 }}
                    required
                  />
                  <FormHelperText
                    id="component-helper-text"
                    className="helperText"
                  >
                    {20 - name.length} Characters Remaining
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={2}
                sx={{ marginBottom: { xs: 0, md: 2 } }}
              >
                <FormControl variant="standard" required fullWidth>
                  <FormLabel>Alias</FormLabel>
                  <TextField
                    label=""
                    name="alias"
                    value={alias}
                    onChange={handleAliasChange}
                    placeholder="Enter Alias"
                    id="component-outlined"
                    style={{ borderRadius: "8px !important" }}
                    inputProps={{ maxLength: 20 }}
                    required
                  />
                  <FormHelperText
                    id="component-helper-text"
                    className="helperText"
                  >
                    {20 - alias.length} Characters Remaining
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={2}
                sx={{ marginBottom: { xs: 0, md: 2 } }}
              >
                <FormControl variant="standard" required fullWidth>
                  <FormLabel>Identifier</FormLabel>
                  <TextField
                    label=""
                    name="identifier"
                    value={identifier}
                    onChange={handleIdentifierChange}
                    placeholder="Enter Identifier"
                    id="component-outlined"
                    style={{ borderRadius: "8px !important" }}
                    inputProps={{ maxLength: 20 }}
                    required
                  />
                  <FormHelperText
                    id="component-helper-text"
                    className="helperText"
                  >
                    {20 - identifier.length} Characters Remaining
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={2}
                sx={{ marginBottom: { xs: 0, md: 2 } }}
              >
                <FormControl variant="standard" required fullWidth>
                  <FormLabel>Description</FormLabel>
                  <TextField
                    label=""
                    name="description"
                    value={description}
                    onChange={handleDescriptionChange}
                    placeholder="Enter Description"
                    id="component-outlined"
                    style={{ borderRadius: "8px !important" }}
                    inputProps={{ maxLength: 20 }}
                    required
                  />
                  <FormHelperText
                    id="component-helper-text"
                    className="helperText"
                  >
                    {20 - description.length} Characters Remaining
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={2}
                sx={{ marginBottom: { xs: 2, lg: 0 } }}
                marginTop={2}
              >
                <InputLabel id="isActive" sx={{ mb: 1 }}>
                  Status
                </InputLabel>
                <ToggleButtonGroup
                  id="isActive"
                  color="primary"
                  value={isActive}
                  exclusive
                  onChange={handleIsActiveChange}
                >
                  <ToggleButton
                    sx={{
                      color: "primary.main",
                      bgcolor: "#ffffff",
                      border: "1px solid",
                      lineHeight: "0.7",
                      width: "5rem",
                      "&.Mui-selected": {
                        bgcolor: "primary.main",
                        color: "#ffffff",
                        borderColor: "primary.main",
                      },
                      "&.Mui-selected:hover": {
                        bgcolor: "primary.main",
                        color: "#ffffff",
                      },
                    }}
                    value={true}
                  >
                    Active
                  </ToggleButton>
                  <ToggleButton
                    sx={{
                      color: "primary.main",
                      bgcolor: "#ffffff",
                      border: "1px solid",
                      lineHeight: "0.7",
                      width: "5rem",
                      "&.Mui-selected": {
                        bgcolor: "primary.main",
                        color: "#ffffff",
                        borderColor: "primary.main",
                      },
                      "&.Mui-selected:hover": {
                        bgcolor: "primary.main",
                        color: "#ffffff",
                      },
                    }}
                    value={false}
                  >
                    Inactive
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </Grid>
          </Box>
          <Box
            component="div"
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: { xs: 0, lg: 4 },
            }}
          >
            <Button variant="contained" type="submit" sx={{ ml: 0 }}>
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default ResourceForm
