import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Typography } from '@mui/material';
import Link, { LinkProps } from '@mui/material/Link';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { Toast } from '../../components/Notifications/Notification';
import { IOrganization } from '../../entities/Organization/Organization';
import OrganizationService from '../../services/Organization/OrganizationService';
import { getMessage } from '../../shared/utils/message-helpers';
import OrganizationForm from './OrganizationForm';

interface EditOrganizationProps {}

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

const LinkRouter = (props: LinkRouterProps) => <Link {...props} component={RouterLink as any} />;

const EditOrganization: React.FC<EditOrganizationProps> = (props) => {
  const { t } = useTranslation();
  const breadcrumbs = [
    <Link underline="hover" key="1" sx={{ opacity: '0.7' }}>
      {t('NAVBAR.ADMINISTRATION') as string}
    </Link>,
    <LinkRouter underline="hover" key="2" to="/organizations" sx={{ opacity: '0.7' }}>
      Organizations
    </LinkRouter>,
    <Typography key="3" color="primary.main">
      Edit Organization
    </Typography>,
  ];
  const { organizationId } = useParams();
  const [organization, setOrganization] = useState<Partial<IOrganization>>();
  const [loading, setLoading] = useState(true);

  let navigate = useNavigate();

  const editOrganizationHandler = (organizationData: IOrganization) => {
    setLoading(true);
    OrganizationService.updateOrganization(organizationData)
      .then((e) => {
        setLoading(false);
        Toast.success(t('OPERATION_SUCCESS', { ns: 'success' }), t(e.data.messageCode, { ns: 'success' }) || e.data.message);
        backHandler();
      })
      .catch(({ response }) => {
        setLoading(false);
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
      });
  };

  const backHandler = () => {
    navigate('/organizations');
  };

  useEffect(() => {
    if (organizationId) {
      setLoading(true);
      OrganizationService.getOrganization(organizationId)
        .then((e) => {
          const {
            data: { data: organizationData },
          } = e;
          setOrganization(organizationData as Partial<IOrganization>);
          setLoading(false);
        })
        .catch(({ response }) => {
          setLoading(false);
          Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
          console.error('EditOrganization -> init -> getOrganization', response);
        });
    }
  }, [organizationId]);

  return (
    <>
      <Breadcrumbs sx={{ margin: '2rem' }} separator={<NavigateNextIcon color="primary" fontSize="small" />} aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>
      {organization && <OrganizationForm onBack={backHandler} onSubmit={editOrganizationHandler} organization={organization} isLoading={loading} />}
    </>
  );
};

export default EditOrganization;
