import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
} from '@mui/material';
import React, { ReactNode, useState } from 'react';
import { BootstrapDialogTitle } from '../dialogTitle';
import { useTranslation } from 'react-i18next';

export interface SortDialogProps {
  open: boolean;
  onClose: any;
  sortFilter: any;
  setSort:any;
  sort: any;
  sortOptions: any;
}

export const SortDialog = (props: SortDialogProps) => {
  const onClose = () => {
    props.onClose();
  };
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Dialog
      keepMounted
      sx={{
        '& .MuiDialog-paper': {
          minWidth: '100%',
          maxHeight: 435,
          margin: '0px',
          alignSelf: 'flex-end',
          borderBottomRightRadius: 'unset',
          borderBottomLeftRadius: 'unset',
        },
      }}
      maxWidth="xs"
      open={props.open}
      onClose={onClose}
    >
      <BootstrapDialogTitle onClose={onClose}>{t('WI_LIST.SORT_BY')}</BootstrapDialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', padding: '14px 24px 24px 24px !important' }}>
        {props.sortOptions.map((item, index) => {
          return (
            <Button
              key={index}
              onClick={(e) => {
                props.setSort(item);
                props.sortFilter(item);
              }}
              sx={{
                justifyContent: 'flex-start',
                width: 'fit-content',
                color: props.sort
                  ? props.sort === item
                    ? theme.palette.primary.main
                    : '#000000'
                  : index === 0
                  ? theme.palette.primary.main
                  : '#000000',
                fontSize: '14px',
                paddingLeft: '0px'
              }}
            >
              {t('WI_LIST.' + item.toUpperCase())}
            </Button>
          );
        })}
      </DialogContent>
    </Dialog>
  );
};
