import { Box, Button, Card, CardContent, Chip, Grid, SelectChangeEvent, Step, StepLabel, Stepper } from '@mui/material';
import { User, UserGroup } from '../../entities/User/MockUser';
import { useEffect, useState } from 'react';
import React from 'react';
import DetailForm from './DetailForm';
import UserForm from './UserForm';
import { useParams, useNavigate } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import UserGroupService from '../../services/UserGroup/UserGroupService';
import UserService from '../../services/User/UserService';
import Loading from '../../components/Loading/Loading';
import { Toast } from '../../components/Notifications/Notification';
import { getMessage } from '../../shared/utils/message-helpers';
import { StyledStepper } from '../../components/Styled/StyledStepper';
import { trackEvent } from '../../App';
import { AmplitudeEvents } from '../../shared/enums';
import { useTranslation } from 'react-i18next';

interface UserGroupFormProps {
  group?: any;
  onSubmit: (group: any, id: string) => void;
  onBack: () => void;
}

const UserGroupForm: React.FC<UserGroupFormProps> = (props) => {
  const [activeStep, setActiveStep] = React.useState(0);

  const { t } = useTranslation();

  const steps = [t('USER_GROUP.DETAILS'), t('USER_GROUP.USERS')];

  const handleNext = () => {
    if (activeStep !== 1) {
      setActiveStep(activeStep + 1);
    } else {
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [userList, setUserList] = useState<Array<string>>([]);
  const [users, setUsers] = useState<Array<User>>([]);
  const [isActive, setIsActive] = useState<boolean>(true);
  const [loading, setLoading] = useState(false);

  let userListTemp: any[] = [];
  let navigate = useNavigate();

  const getAllUserGroupsData = () => {
    UserService.getUsers()
      .then((res) => {
        setLoading(false);
        userListTemp = [];

        res.data.data.map((obj) => {
          const newObj = {
            firstName: obj.firstName,
            lastName: obj.lastName,
            id: obj.id,
            isActive: obj.isActive,
            persona: obj.personas[0].name,
            personaId: obj.personas[0].id,
            email: obj.email,
            organizationId: obj.organizationId,
            company: obj.organizationName,
            createdDate: obj.createdOn,
          };

          userListTemp.push(newObj);
        });

        setUsers(userListTemp as User[]);
      })
      .catch(({ response }) => {
        setLoading(false);
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
      });
  };

  useEffect(() => {
    getAllUserGroupsData();
  }, []);

  useEffect(() => {
    if (props.group) {
      const groupData = props.group;
      //   set group data
      setName(groupData.name);
      setDescription(groupData.description);
      setIsActive(groupData.isActive);
      setUserList(groupData.users);
    }
  }, [props.group]);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value as string);
  };
  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value as string);
  };
  const handleIsActiveChange = (event: React.MouseEvent<HTMLElement>, newAlignment: boolean) => {
    if (newAlignment !== null) {
      setIsActive(newAlignment);
    }
  };

  const backHandler = () => {
    navigate('/user-group');
  };
  const submitHandler = (e) => {
    setLoading(true);
    e.preventDefault();
    const userData = {
      name,
      description,
      isActive,
      users: userList,
    };
    if (!props.group) {
      UserGroupService.createUserGroup(userData)
        .then((e) => {
          setLoading(false);
          trackEvent(AmplitudeEvents.CREATE_NEW_USER_GROUP, { created_by: localStorage.getItem('email'), group_name: userData.name, user_list: userData.users });
          Toast.success(t('OPERATION_SUCCESS', { ns: 'success' }), t(e.data.messageCode, { ns: 'success' }) || e.data.message);
          backHandler();
        })
        .catch(({ response }) => {
          setLoading(false);
          Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
          console.error('AddUser -> addUserHandler', response);
        });
    } else {
      props.onSubmit(userData, props?.group?.id);
    }
  };
  const handleSelection = (ids) => {
    setUserList(ids);
  };

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return <DetailForm name={name} onNameChange={handleNameChange} description={description} onDescriptionChange={handleDescriptionChange} isActive={isActive} onIsActiveChange={handleIsActiveChange} />;
      case 1:
        return <UserForm userList={userList} users={users} onSelectionChange={handleSelection} />;
      default:
        throw new Error('Unknown step');
    }
  }
  function getStepName(step: number) {
    switch (step) {
      case 0:
        return t('USER_GROUP.DETAILS');
      case 1:
        return t('USER_GROUP.USERS');
      default:
        throw new Error('Unknown step');
    }
  }

  return (
    <>
      <Grid container spacing={3} sx={{ pl: 3 }}>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={2}
          sx={{
            '&.MuiGrid-item': { paddingTop: '1rem' },
            paddingRight: { xs: 3, md: 0 },
          }}
        >
          <StyledStepper orientation="horizontal" activeStep={activeStep} sx={{ ml: 1 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </StyledStepper>
        </Grid>
      </Grid>
      <Card sx={{ display: 'flex' }} elevation={5} style={{ margin: '2rem', height: '100%' }}>
        <Box
          component="div"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            textAlign: 'left',
          }}
        >
          <CardContent sx={{ flex: '1 0 auto', p: 0 }} className="group-form-content">
            <Box component={'div'} sx={{ padding: '1rem' }}>
              <Box component="h2" sx={{ m: 0, p: 2, pt: 1, pb: 1 }}>
                {getStepName(activeStep)}
              </Box>
            </Box>
            <Box component="hr" sx={{}}></Box>

            <Box component={'div'} sx={{ padding: '1rem' }}>
              <Box
                component="form"
                sx={{
                  padding: { xs: 0, lg: 2 },
                  width: '100%',
                  textAlign: 'left',
                }}
                onSubmit={submitHandler}
              >
                <Box component={'div'}>{getStepContent(activeStep)}</Box>
                <Box
                  component="div"
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    paddingRight: { xs: 0, lg: 4 },
                  }}
                >
                  {activeStep !== 0 && (
                    <Button variant="outlined" size="small" onClick={handleBack} sx={{ mt: 3, ml: 1, minWidth: 'fit-content' }}>
                      <ArrowBackIosNewIcon />
                    </Button>
                  )}
                  {activeStep !== steps.length - 1 && (
                    <Button variant="contained" onClick={handleNext} sx={{ mt: 3, ml: 1 }}>
                      {t('BUTTON.NEXT')}
                    </Button>
                  )}
                  {activeStep === steps.length - 1 && (
                    <Button variant="contained" type="submit" sx={{ mt: 3, ml: 1 }}>
                      {t('BUTTON.SAVE')}
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Box>
      </Card>
      {loading && <Loading />}
    </>
  );
};

export default UserGroupForm;
