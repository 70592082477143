import { Box, Button, CardMedia, Grid, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BasicDialog } from '../BasicDialog/BasicDialog';
import TrainerService from '../../../../services/trainer/TrainerService';
import Loading from '../../../Loading/Loading';
import './ThumbnailDialog.scss';
import fallBackIcon from '../../../../assets/fallback_img.svg';
import { ThumbnailUploadIcon } from '../../../Icons/CdsIcons';
import FileManagerService from '../../../../services/FileManager/FileManagerService';
import { Toast } from '../../../Notifications/Notification';
import { environment } from '../../../../environment/environment';

export const ThumbnailDialog = (props) => {
  const [loading, setLoading] = useState(false);
  const [localFiles, setLocalFiles] = React.useState<any>([]);
  const [removeThumbnailCheck, setRemoveThumbnailCheck] = React.useState<boolean>(false);
  const { t } = useTranslation();
  function promptFile(contentType, multiple = false) {
    var input: any = document.createElement('input');
    input.type = 'file';
    input.multiple = multiple;
    input.accept = contentType;

    return new Promise((resolve) => {
      input.onchange = function () {
        var files = Array.from(input.files);
        resolve(multiple ? files : files[0]);
      };
      input.click();
    });
  }
  const blob: any = useRef<any>();
  const handleThumbnailChange = async () => {
    const file: any = await promptFile(['image/jpeg', 'image/png'], false);
    const maxFileSizeInMB = 10;
    if (file.size > maxFileSizeInMB * 1024 * 1024) {
      Toast.error(t('COMMON.PPE_DATA.MAX_LIMIT.TITLE'), t(`${t('DROPZONE.SELECTED_FILE_SIZE_EXCEED_LIMIT')} ${environment.AppConfigs.thumbnailLimit.fileLimitThumbnail}MB.`));
      return;
    }
    if (!['image/jpeg', 'image/png'].includes(file.type)) {
      Toast.error(t(`ADD_WI.SUPPORTED_FORMATS`), environment.AppConfigs.thumbnailLimit.fileTypeThumbnail );
      return;
    }

    const controller = new AbortController();
    const signal = controller.signal;
    blob.current = new Blob([file]);
    const url = URL.createObjectURL(blob.current);
    setLocalFiles(url);
  };

  const handleRemoveThumbnail = () => {
    setRemoveThumbnailCheck(true);
    setLocalFiles([]);
  };
  const handleClose = () => {
    setRemoveThumbnailCheck(false);
    props.setShowThumbnailDialog(false);
    setLocalFiles([]);
  };
  useEffect(() => {
    setLocalFiles(props.thumbnail);
  }, [props.openDialog]);

  const handleSubmit = async () => {
    if (localFiles.length > 0) {
      setLoading(true);
      const metaData = { organizationId: props.workInstruction.organizationId, workInstructionId: props.workInstruction.workInstructionId };
      await FileManagerService.fileUploadBlob(blob.current, metaData).then((response: any) => {
        const mentorID = response.data.id;
        TrainerService.customThumbnail(props.workInstruction.workInstructionId, { mentorId: mentorID }).then(() => {
          props.setShowThumbnailDialog(false);
          setLocalFiles([]);
          setLoading(false);
          setRemoveThumbnailCheck(false);
          if (props.clearCardsSelection) {
            props.clearCardsSelection();
          }
          if (props.handleReloadData) {
            props.handleReloadData();
          }
        });
      });
    } else {
      setLoading(true);
      await TrainerService.deleteThumbnail(props.workInstruction.workInstructionId).then(() => {
        props.setShowThumbnailDialog(false);
        setLocalFiles([]);
        setLoading(false);
        setRemoveThumbnailCheck(false);

        if (props.clearCardsSelection) {
          props.clearCardsSelection();
        }
        if (props.handleReloadData) {
          props.handleReloadData();
        }
      });
    }
  };

  return (
    <>
      <BasicDialog
        title={t('COMMON.CHANGE_THUMBNAIL')}
        onClose={handleClose}
        onOpen={props.openDialog}
        additionalProp={{ mb: '0px', pb: '0px' }}
        actions={
          (blob.current || removeThumbnailCheck) && (
            <>
              <Button
                onClick={handleClose}
                variant="outlined"
                size="small"
                type="submit"
              >
                {t('BUTTON.CANCEL') as string}
              </Button>
              <Button
                variant="contained"
                size="small"
                type="submit"
                onClick={handleSubmit}
              >
                {t('BUTTON.CONFIRM') as string}
              </Button>
            </>
          )
        }
      >
        {loading && (
          <Box
            component={'div'}
            sx={{ marginLeft: '-25px' }}
          >
            <Loading />
          </Box>
        )}
        <Grid
          xs={12}
          sx={{ display: 'flex', flexDirection: 'row' }}
        >
          <Grid
            item
            xs={3}
            sx={{ width: '100%', alignItems: 'center', marginTop: '24px' }}
          >
            <Box
              component="div"
              className="wi-img-container"
            >
              {localFiles.length > 0 ? (
                <CardMedia
                  component="img"
                  className="wi-img"
                  image={localFiles}
                  alt="work instruction model"
                />
              ) : (
                <CardMedia
                  component="img"
                  className="wi-img"
                  image={fallBackIcon}
                  alt="work instruction model"
                ></CardMedia>
              )}
            </Box>
            {localFiles.length > 0 && (
              <Button
                onClick={handleRemoveThumbnail}
                sx={{ padding: 'auto', fontSize: '16px', mt: '16px', ml: '8px' }}
                className="wi-img-container"
              >
                {t('COMMON.REMOVE')}
              </Button>
            )}
          </Grid>

          <Button
            variant="outlined"
            onClick={handleThumbnailChange}
            sx={{ width: '395px', height: '119px', borderRadius: '8px', mt: '24px', ml: '24px' }}
          >
            <Typography>
              <Typography>
                <ThumbnailUploadIcon />
              </Typography>
              <Typography sx={{ fontWeight: '600', fontSize: '16px', marginBottom: '8px' }}>{t('COMMON.UPLOAD_IMAGE')}</Typography>
              <Typography sx={{ fontWeight: '400', fontSize: '14px', color: '#000000', opacity: '50%' }}>{t('COMMON.FILE_TYPE')}: PNG, JPEG</Typography>
              <Typography sx={{ fontWeight: '400', fontSize: '14px', color: '#000000', opacity: '50%' }}>{t('COMMON.MAX_FILE_SIZE', { MB: '10MB' }) as string}</Typography>
            </Typography>
          </Button>
        </Grid>
      </BasicDialog>
    </>
  );
};
