import * as React from "react";
import LinearProgress, {
    LinearProgressProps,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function LinearProgressWithLabel(
    props: LinearProgressProps & { value: number; message: string }
) {
    return (
        <Box component="div">
            <Box component="div" sx={{ minWidth: 35 }}>
                <Typography
                    variant="h6"
                    color="text.primary"
                    sx={{ fontSize: "1rem", textAlign: "center" }}
                >
                    {props.message}
                </Typography>
            </Box>
            <Box className="loading-bar" component="div" sx={{ width: "100%" }}>
                <LinearProgress color="inherit" className="custom-linear-progress-with-label"
                    sx={{ height: "10px", borderRadius: "15px" }}
                    {...props}
                />
            </Box>
            {/* <Box component="div" sx={{ minWidth: 35 }}>
        <Typography variant="h6" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box> */}
        </Box>
    );
}

interface Props {
    progress: number;
    message: string;
    [x:string]: any;
}

export default function LinearWithValueLabel(props: Props) {
    const { progress, message, ...other } = props;
    return (
        <Box component="div" sx={{ minWidth: "250px" }}>
            <LinearProgressWithLabel
                value={progress}
                message={message}
                {...other}
            />
        </Box>
    );
}
