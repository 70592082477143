
import { Box, ButtonBase, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BackActive, BackDefault, BottomActive, BottomDefault, FrontActive, FrontDefault, LeftActive, LeftDefault, RightActive, RightDefault, Rotate, TopActive, TopDefault } from "../../../../Icons/CdsIcons";
import { StyledModalIcon } from "../../../../Styled/StyledModalIcon";
import './RotatePopover.scss';
import { useCanvasState } from "../../../../../state/canvasState";
import {useTranslation} from 'react-i18next'
import { setViewPointByName } from "../../../../../integrations/commonViewer/commonViewer";

interface Props {

}


const RotatePopover: React.FC<Props> = (props) => {
    const canvasState = useCanvasState() 
    const [isRotating, setIsRotating]= useState<boolean>(false);
    useEffect(() => {
        setIsRotating(true)
        setTimeout(() => {
            setIsRotating(false)
        }, 1000);
    }, [canvasState.rotate])


    const rotateModel = (spin: "front"|"back"|"left"|"right"|"top"|"bottom") => {
        // useCanvasState.setState({rotate: spin})
        setViewPointByName(spin);
    }
const { t }=useTranslation()
    return (
        <Paper className="rotate-container canvas-control-container-buttons">
            <Box component="div" className={'rotate-content-container'} >
                <ButtonBase component="div" className={`rotate-link`}  onClick={() => { rotateModel('front')}}  disabled={isRotating}>
                    <Box component="div" className={'rotate-content'} >
                        <Box component="div" className="mode-icon-top">
                            {canvasState.rotate !== 'front' && <StyledModalIcon text={t('3D_VIEWER.FRONT')} tooltip={t('3D_VIEWER.FRONT')}>
                                <FrontDefault />
                            </StyledModalIcon>}
                            {canvasState.rotate === 'front' && <StyledModalIcon text={t('3D_VIEWER.FRONT')} tooltip={t('3D_VIEWER.FRONT')}>
                                <FrontActive />
                            </StyledModalIcon>}
                        </Box>
                    </Box>
                </ButtonBase>
                <ButtonBase component="div" className={`rotate-link`} onClick={() => { rotateModel('back')}} disabled={isRotating}>
                    <Box component="div" className={'rotate-content'} >
                        <Box component="div" className="mode-icon-top">
                            <Box component="div" className="mode-icon-top">
                                {canvasState.rotate !== 'back' && <StyledModalIcon text={t('3D_VIEWER.BACK')} tooltip={t('3D_VIEWER.BACK')}>
                                    <BackDefault />
                                </StyledModalIcon>}
                                {canvasState.rotate === 'back' && <StyledModalIcon text={t('3D_VIEWER.BACK')} tooltip={t('3D_VIEWER.BACK')}>
                                    <BackActive />
                                </StyledModalIcon>}
                            </Box>
                        </Box>
                    </Box>
                </ButtonBase>
                <ButtonBase component="div" className={`rotate-link`} onClick={() => { rotateModel('left')}} disabled={isRotating}>
                    <Box component="div" className={'rotate-content'} >
                        <Box component="div" className="mode-icon-top">
                            <Box component="div" className="mode-icon-top">
                                {canvasState.rotate !== 'left' && <StyledModalIcon text={t('3D_VIEWER.LEFT')} tooltip={t('3D_VIEWER.LEFT')}>
                                    <LeftDefault />
                                </StyledModalIcon>}
                                {canvasState.rotate === 'left' && <StyledModalIcon text={t('3D_VIEWER.LEFT')} tooltip={t('3D_VIEWER.LEFT')}>
                                    <LeftActive />
                                </StyledModalIcon>}
                            </Box>
                        </Box>
                    </Box>
                </ButtonBase>
                <ButtonBase component="div" className={`rotate-link`} onClick={() => { rotateModel('right')}} disabled={isRotating}>
                    <Box component="div" className={'rotate-content'} >
                    <Box component="div" className="mode-icon-top">
                            <Box component="div" className="mode-icon-top">
                                {canvasState.rotate !== 'right' && <StyledModalIcon text={t('3D_VIEWER.RIGHT')} tooltip={t('3D_VIEWER.RIGHT')}>
                                    <RightDefault />
                                </StyledModalIcon>}
                                {canvasState.rotate === 'right' && <StyledModalIcon text={t('3D_VIEWER.RIGHT')} tooltip={t('3D_VIEWER.RIGHT')}>
                                    <RightActive />
                                </StyledModalIcon>}
                            </Box>
                        </Box>
                    </Box>
                </ButtonBase>
                <ButtonBase component="div" className={`rotate-link`} onClick={() => {rotateModel('top')}} disabled={isRotating}>
                    <Box component="div" className={'rotate-content'} >
                    <Box component="div" className="mode-icon-top">
                            <Box component="div" className="mode-icon-top">
                                {canvasState.rotate !== 'top' && <StyledModalIcon text={t('3D_VIEWER.TOP')} tooltip={t('3D_VIEWER.TOP')}>
                                    <TopDefault />
                                </StyledModalIcon>}
                                {canvasState.rotate === 'top' && <StyledModalIcon text={t('3D_VIEWER.TOP')} tooltip={t('3D_VIEWER.TOP')}>
                                    <TopActive />
                                </StyledModalIcon>}
                            </Box>
                        </Box>
                    </Box>
                </ButtonBase>
                <ButtonBase component="div" className={`rotate-link`} onClick={() => {rotateModel('bottom')}} disabled={isRotating}>
                    <Box component="div" className={'rotate-content'} >
                    <Box component="div" className="mode-icon-top">
                            <Box component="div" className="mode-icon-top">
                                {canvasState.rotate !== 'bottom' && <StyledModalIcon text={t('3D_VIEWER.BOTTOM')} tooltip={t('3D_VIEWER.BOTTOM')}>
                                    <BottomDefault />
                                </StyledModalIcon>}
                                {canvasState.rotate === 'bottom' && <StyledModalIcon text={t('3D_VIEWER.BOTTOM')} tooltip={t('3D_VIEWER.BOTTOM')}>
                                    <BottomActive />
                                </StyledModalIcon>}
                            </Box>
                        </Box>
                    </Box>
                </ButtonBase>

            </Box>
        </Paper>

    )
}

export default RotatePopover;
