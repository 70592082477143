import create, { State } from 'zustand';
import { WorkInstructions, CameraPosition } from '../entities/Trainee/WorkInstructions';
import { PartsList } from '../entities/Trainee/MockTrainee';

export interface GlobalState {
  currentlyPlaying: boolean;
  //This will let the mesh layer know that it needs to initialize opacity but the camera is moving
  animationStarting: boolean;
  cameraRotating: boolean;
  changeMode: string;
  showAnnotations: boolean;
  workInstructions: WorkInstructions | null;
  rotate: CameraPosition | string | null;
  partList: PartsList[] | null;
  procedureId: string;
  replay: boolean;
  isShowStepsVisible: boolean;
  mediaReferencesEnabled: boolean;
  setShowStepsVisible: (isShowStepsVisible: boolean) => void;
  setReplay: (replay: boolean) => void;
  setPartList: (newPartList: PartsList[]) => void;
  setMediaRefEnabled: (newBool: boolean) => void;
}

export const useCanvasState = create<GlobalState>((set: any) => ({
  currentlyPlaying: false,
  animationStarting: false,
  cameraRotating: true,
  changeMode: '',
  showAnnotations: false,
  workInstructions: null,
  rotate: null,
  partList: null,
  procedureId: '',
  replay: false,
  isShowStepsVisible: true,
  mediaReferencesEnabled: false,
  setShowStepsVisible: (isShowStepsVisible: boolean) => {
    useCanvasState.setState({ isShowStepsVisible: isShowStepsVisible });
  },
  setReplay: (replay: boolean) => {
    useCanvasState.setState({ replay: replay });
  },
  setPartList: (newPartList) => {
    set({ partList: [...newPartList] });
  },
  setMediaRefEnabled: (newBool: boolean) =>
    set((state: GlobalState) => {
      state.mediaReferencesEnabled = newBool;
    }),
}));
