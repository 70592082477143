import { Box, Card, CardActionArea, CardContent, Container, Grid, Skeleton, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { fetchAllSubstitutions, setEditDialogData } from '../../redux/features/Substitutions/Substitutions.slice';
import { addSubstitution } from '../../services/Substitution/SubstitutionService';
import { MongoDBBaseDocument } from '../../shared/interfaces';
import AddSubstitutionModal from './AddSubstitutionModal';
import SubstitutionsHeader from './SubstitutionHeader';
import SubstitutionsEmptyState from './SubstitutionsEmptyState';
import { ISubstitution } from './Substitutions';
import { setSidebarToOpen } from '../../redux/features/SideBar/Sidebar.slice';
import { APP_SIDEBARS } from '../../shared/enums';

const LoadingSkeleton = (props) => {
  return (
    <>
      <Grid
        item
        xs={3}
      >
        <Skeleton sx={{ height: '300px' }} />
      </Grid>
      <Grid
        item
        xs={3}
      >
        <Skeleton sx={{ height: '300px' }} />
      </Grid>
      <Grid
        item
        xs={3}
      >
        <Skeleton sx={{ height: '300px' }} />
      </Grid>
      <Grid
        item
        xs={3}
      >
        <Skeleton sx={{ height: '300px' }} />
      </Grid>
    </>
  );
};
const SubstitutionsContainer = (props) => {
  const dispatch = useAppDispatch();
  const { substitutions, loading } = useAppSelector((state) => state.substitution);

  const [collatedSubstitutions, setCollatedSubstitutions] = useState<any>([]);

  useEffect(() => {
    return () => {
      dispatch(setSidebarToOpen(null));
    }
  }, [])

  useEffect(() => {
    const collateData = (data: ISubstitution[]) => {
      const collatedData = {};
      data.forEach((datum) => {
        let sourceLanguage = datum.byDefaultLanguage;

        if (!collatedData[datum.word]) {
          collatedData[datum.word] = {
            word: datum.word,
            substitutions: [],
          };
        }

        if(sourceLanguage !== datum.byDefaultLanguage){
            console.warn('Different source language found for same word');
        }

        collatedData[datum.word].substitutions.push({ substitute: datum.substitute, langCode: datum.translatedLanguage, id: datum.id });
        collatedData[datum.word].sourceLanguage = sourceLanguage;
      });
      return Object.values(collatedData);
    };

    setCollatedSubstitutions(collateData(substitutions));
  }, [substitutions]);

  return (
    <Grid
      container
      spacing={2}
    >
      {loading ? (
        <LoadingSkeleton />
      ) : collatedSubstitutions.length ? (
        collatedSubstitutions.map((each: any) => (
          <SubstituteWordCard
            word={each.word}
            substitutions={each.substitutions}
            sourceLanguage={each.sourceLanguage}
          />
        ))
      ) : (
        <Grid
          item
          xs={12}
        >
          <SubstitutionsEmptyState />
        </Grid>
      )}
    </Grid>
  );
};

const limit = 3;
const SubstituteWordCard = (props) => {
  const dispatch = useAppDispatch();
  const {editDialogData} = useAppSelector(state => state.substitution);
  const theme = useTheme();

  const { word, substitutions, sourceLanguage } = props;
  const [isSelected, setIsSelected] = useState<boolean>(false);


  useEffect(() => {
    setIsSelected(editDialogData?.word === word);
  }, [editDialogData]);

  const handleSubstitutionCardClick = () => {
    dispatch(
      setEditDialogData({
        word,
        substitutions,
        sourceLanguage: sourceLanguage
      }),
    );
    dispatch(setSidebarToOpen(APP_SIDEBARS.EDIT_SUBSTITUTION));
  };
  return (
    <Grid
      item
      xs={12 / 1}
      sm={12 / 2}
      md={12 / 3}
      lg={12 / 4}
      xl={12 / 5}
    >
      <Card
        sx={{
          border: '1px solid',
          borderColor: isSelected ? theme.palette.primary.main : "#D9D9D9",
          background: isSelected ? theme.palette.primary.main + "6c" : 'transparent',
          borderRadius: '8px',
          height: '100%',
          minHeight: '165px',
        }}
      >
        <CardActionArea
          sx={{ height: '100%' }}
          onClick={handleSubstitutionCardClick}
        >
          <CardContent sx={{ height: '100%' }}>
            <Typography
              variant="h6"
              sx={{ marginBottom: '10px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              {word}
            </Typography>
            <Grid
              container
              rowGap={{ xs: '6px' }}
            >
              {substitutions.slice(0, limit).map(({ substitute, langCode }) => (
                <>
                  <Language language={langCode} />
                  <Substitution substitution={substitute} />
                </>
              ))}
              {substitutions.length === limit + 1
                ? substitutions.slice(limit, limit + 1).map(({ substitute, langCode }) => (
                    <>
                      <Language language={langCode} />
                      <Substitution substitution={substitute} />
                    </>
                  ))
                : null}
              {substitutions.length > limit + 1 ? (
                <Grid
                  item
                  xs={12}
                  sx={{
                    fontSize: '16px',
                  }}
                >
                  +{substitutions.length - limit} MORE
                </Grid>
              ) : null}
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

const Language = ({ language }) => {
  const { t } = useTranslation();
  return (
    <Grid
      item
      xs={4}
      sx={{
        fontSize: '14px',
        opacity: '0.6',
      }}
    >
      {t('LANGUAGE_SIDEBAR.SUPPORTED_LANGUAGES.' + language.toUpperCase())}
    </Grid>
  );
};

const Substitution = ({ substitution }) => {
  return (
    <Grid
      item
      xs={6}
      sx={{
        fontSize: '16px',
        whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'
      }}
    >
      {substitution}
    </Grid>
  );
};

export default SubstitutionsContainer;
