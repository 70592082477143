import { Box, FormControlLabel, Typography, Grid, Skeleton, Avatar, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import usePpeList from '../../hooks/ppeList';
import TraineeService from '../../services/trainee/TraineeService';
import TrainerService from '../../services/trainer/TrainerService';
import { useCanvasState } from '../../state/canvasState';
import { RightArrow } from '../Icons/CdsIcons';
import { Toast } from '../Notifications/Notification';

interface Props {}

const StepsPpeList: React.FC<Props> = (props) => {
  const [ppeList, setPpeList] = useState<any>();
  const [loading, setLoading] = useState(true);
  const { introStep, showIntro } = usePpeList();
  const { workInstructionId } = useParams();
  const [workInstructionName, setWorkInstructionName] = useState('');
  const canvasState = useCanvasState();
  const [procedureName, setProcedureName] = useState('');

  const { search: searchQuery } = useLocation();
  const query = new URLSearchParams(searchQuery);
  const partnerAccessToken = query.get('access');

  const setProcedureDetails = () => {
    TraineeService.getWorkInstructionsById(workInstructionId, partnerAccessToken)
      .then((response) => {
        setWorkInstructionName(response.data.data.workInstructionName);
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
      });
    TrainerService.getProcedureById(canvasState.procedureId, partnerAccessToken)
      .then((response) => {
        setProcedureName(response.data.data.procedureName);
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
      });
  };

  const getPpeList = () => {
    TrainerService.getPPEOfProcedure(canvasState.procedureId, partnerAccessToken)
      .then((response) => {
        setPpeList(response.data.data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (introStep === 1) {
      setLoading(true);
      setProcedureDetails();
    }
    if (introStep === 2) {
      setLoading(true);
      getPpeList();
    }
  }, [canvasState.procedureId, introStep]);

  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <>
      {loading && (
        <Box component="div" sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column', overflow: 'auto', width: '100%' }}>
          <Grid container spacing={2} sx={{ height: '100%', width: '100%', padding: '32px !important', margin: 0 }}>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((e, i) => {
              return (
                <Grid item xs={12} md={2} lg={2} key={i}>
                  <Skeleton height={'180px'} width={'100%'} />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      )}
      {!loading && ppeList?.length > 0 && (
        <Box component="div" sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column', overflow: 'auto', width: '100%' }}>
          {showIntro && introStep === 2 && (
            <Grid
              container
              spacing={{ xs: 2, md: 2, lg: 2 }}
              sx={{
                textAlign: 'left',
                margin: 0,
                justifyContent: 'center',
                padding: '32px !important',
                height: '100%',
                width: '100%',
                pointerEvents: 'none',
              }}
            >
              {ppeList.map((e, i) => {
                return (
                  <Grid item xs={12} md={2} lg={2} key={i}>
                    <Box
                      component="div"
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        border: `2px solid #CDCDCD`,
                        borderRadius: '8px',
                        margin: '0px 10px 0px 0px',
                        padding: '12px',
                        minWidth: '120px',
                        maxWidth: '250px',
                      }}
                    >
                      <Avatar
                        sx={{
                          borderRadius: '12px',
                          height: '90px',
                          width: 'fit-content',
                          minWidth: '60%',
                          maxWidth: '100%',
                        }}
                        imgProps={{
                          style: {
                            objectFit: 'contain',
                          },
                        }}
                        variant="square"
                        alt="PPE"
                        src={'data:image/jpeg;base64,' + e.previewImgUrl}
                      />
                      <Typography
                        component="div"
                        sx={{
                          fontSize: '20px !important',
                          maxWidth: '100%',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          paddingTop: '12px',
                        }}
                      >
                        {e.name}
                      </Typography>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Box>
      )}
    </>
  );
};

export default StepsPpeList;
