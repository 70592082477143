import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import './PpeDialog.scss';
import { useMsal } from '@azure/msal-react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { DialogActions, DialogTitle } from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: "24px",
  },
  '& .MuiDialogActions-root': {
    padding: "0px 24px 24px 24px",
  }, 
}));

interface Props {
  openApprovalDialog: boolean;
}

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

export const PpeDialog = ({ message, title, onClose, actions }) => {
  const [open, setOpen] = React.useState(false);
  const { accounts } = useMsal();
  const name = accounts[0] && accounts[0].name;

  const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle
        className="remove-margin"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding:'24px !important'
        }}
        {...other}
      >
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
              padding:0
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  useEffect(() => {
    setOpen(true);
  }, []);

  const handleClose = () => {
    onClose();
    setOpen(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography sx={{ fontSize: '20px'}}>{title}</Typography>
        </BootstrapDialogTitle>

        <DialogContent>
          <Typography gutterBottom>{message}</Typography>
        </DialogContent>
        <DialogActions>{actions}</DialogActions>
      </BootstrapDialog>
    </div>
  );
};
