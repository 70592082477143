import { Box, Button, Chip, Grid } from '@mui/material';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { trackEvent } from '../../../App';
import NewProcedure from '../../../components/Trainer/NewProcedure/NewProcedure';
import NewWorkInstruction from '../../../components/Trainer/NewWorkInstruction/NewWorkInstruction';
import AddPPE from '../../../components/Trainer/PPEKit/AddPPE';
import { IProcedureCreate, IWorkInstructionCreate } from '../../../entities/Trainee/WorkInstructions';
import { AmplitudeEvents } from '../../../shared/enums';
import './CreateWorkInstruction.scss';
import useTour from '../../../hooks/tour';

interface Props {}

const CreateWorkInstructions: React.FC<Props> = () => {
  const { t } = useTranslation();
  const steps = [t('ADD_WI.WORK_INSTRUCTION'), t('ADD_WI.PROCEDURE'), t('ADD_WI.PPE_EQUIPMENTS')];
  const [formValues, setFormValues] = useState<IWorkInstructionCreate | null>(null);
  const [fileValue, setFileValue] = useState<any>(null);
  const [procValues, setProcValues] = useState<IProcedureCreate | null>(null);
  const [procFileValue, setProcFileValue] = useState<any>([]);
  const [ppeValues, setPpeValues] = useState<string[]>([]);
  const [activeStep, setActiveStep] = useState(0);
  const GO_BACK = 'GO_BACK';
  const GO_NEXT = 'GO_NEXT';
  const { isTourOpen, setIsTourOpen, tourStep, setTourStep, setTourTitle, tourSteps, setTourSteps } = useTour();

  useEffect(() => {
    if (tourStep === 5) {
      setActiveStep(1);
      setTourStep(tourStep + 1);
    } else if (tourStep === 11) {
      setActiveStep(2);
      setTourStep(tourStep + 1);
    }
  }, [tourStep]);

  const goBack = () => {
    if (activeStep > 0) {
      setActiveStep((state) => state - 1);
    }
  };

  useEffect(() => {
    if (isTourOpen) {
      setFormValues({ workInstructionName: isTourOpen ? 'Sample work instruction' : '', workInstructionDescription: isTourOpen ? 'This is the sample work instruction to try Mentor more quickly and efficiently.' : '', brand: isTourOpen ? 'CDS' : '' });
    }
  }, [isTourOpen]);

  const setWorkInstructionDataAndNavigate = (direction: string, formData: IWorkInstructionCreate, fileData: any) => {
    setFormValues(formData);
    setFileValue(fileData);

    if (direction === GO_NEXT) {
      if (!formData) {
        setActiveStep(0);
      } else {
        setActiveStep(1);
      }
    } else if (direction === GO_BACK) {
      goBack();
    }
  };

  const handleGoBackClickedInWorkInstruction = (formData: IWorkInstructionCreate, fileData: any) => {
    setWorkInstructionDataAndNavigate(GO_BACK, formData, fileData);
  };

  const handleGoNextClickedInWorkInstruction = (formData: IWorkInstructionCreate, fileData: any) => {
    trackEvent(AmplitudeEvents.CREATE_WORK_INSTRUCTION_STEP_2, { initiated_by: localStorage.getItem('email') });
    setWorkInstructionDataAndNavigate(GO_NEXT, formData, fileData);
  };

  const setProcedureDataAndNavigate = (direction: string, formData: IProcedureCreate, fileData: any) => {
    setProcValues(formData);
    setProcFileValue(fileData);

    if (direction === GO_NEXT) {
      if (formValues && !formData) {
        setActiveStep(1);
      } else if (formValues && formData) {
        setActiveStep(2);
      }
    } else if (direction === GO_BACK) {
      goBack();
    }
  };

  const handleGoBackClickedInProcedure = (formData: IProcedureCreate, fileData: any) => {
    setProcedureDataAndNavigate(GO_BACK, formData, fileData);
  };

  const handleGoNextClickedInProcedure = (formData: IProcedureCreate, fileData: any) => {
    trackEvent(AmplitudeEvents.CREATE_WORK_INSTRUCTION_STEP_3, { initiated_by: localStorage.getItem('email') });
    setProcedureDataAndNavigate(GO_NEXT, formData, fileData);
  };

  const setPPEDataAndNavigate = (direction: string, formData: string[]) => {
    setPpeValues(formData);

    if (direction === GO_NEXT) {
      // No Next Button
    } else if (direction === GO_BACK) {
      goBack();
    }
  };

  const handleGoBackClickedInPPE = (formData: string[]) => {
    setPPEDataAndNavigate(GO_BACK, formData);
  };

  const handleGoNextClickedInPPE = (formData: string[]) => {
    setPPEDataAndNavigate(GO_NEXT, formData);
  };

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center">
      <Grid className="card-containers" style={{ width: '548px', marginRight: '3rem' }}>
        <Stepper orientation="horizontal" activeStep={activeStep}>
          {steps.map((label, i) => (
            <Step key={label}>
              <StepLabel>
                <Chip label={label}></Chip>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
      <Grid container alignItems="center" justifyContent="center">
        {activeStep === 0 && <NewWorkInstruction formData={formValues} fileData={fileValue} isEditWorkInstruction={false} showBackButton={false} onGoBack={(formData, fileData) => handleGoBackClickedInWorkInstruction(formData, fileData)} onGoNext={(formData, fileData) => handleGoNextClickedInWorkInstruction(formData, fileData)} />}
        {activeStep === 1 && (
          <Box component={'div'} sx={{ display: 'flex', flexDirection: 'column' }}>
            <NewProcedure formData={procValues} fileData={procFileValue} setFileData={setProcFileValue} procedureDetails={null} workInstructionData={formValues} showBackButton={true} onGoBack={(formData, fileData) => handleGoBackClickedInProcedure(formData, fileData)} onGoNext={(formData, fileData) => handleGoNextClickedInProcedure(formData, fileData)} />
            <Box component="div">{/*<Button variant="contained" style={{ float: "right" }} onClick={() => setFormValues(null)}> Back </Button>*/}</Box>
          </Box>
        )}
        {activeStep === 2 && (
          <Box component="div" sx={{ display: 'flex', flexDirection: 'column' }}>
            <AddPPE procedureRefDoc={procFileValue} procedureDetails={null} ppeEquipment={ppeValues} workInstructionData={formValues} procedureData={procValues} showBackButton={true} onGoBack={(formData) => handleGoBackClickedInPPE(formData)} onGoNext={(formData) => handleGoNextClickedInPPE(formData)} />
            <Box component="div">{/*<Button variant="contained" style={{ float: "right" }} onClick={() => {setFormValues(null); setProcValues(null);}}> Back </Button>*/}</Box>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default CreateWorkInstructions;
