import { Box, Button, ButtonGroup, Card, CardActions, CardContent, CardHeader, FormLabel, IconButton, List, Paper, Stack, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import FormControl from '@mui/material/FormControl';

import FormHelperText from '@mui/material/FormHelperText';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Dropzone from '../../../components/Dropzone/Dropzone';
import { IWorkInstructions } from '../../../entities/Trainee/MockTrainee';
import TutorialService from '../../../services/trainee/TraineeService';
import TrainerService from '../../../services/trainer/TrainerService';
import { useFileUpload } from '../../../shared/context';
import './NewWorkInstruction.scss';
import Loading from '../../Loading/Loading';
import { environment } from '../../../environment/environment';
import { LeftArrow } from '../../Icons/CdsIcons';
import { IWorkInstructionCreate } from '../../../entities/Trainee/WorkInstructions';
import { useTranslation } from 'react-i18next';
import useTour from '../../../hooks/tour';

interface Props {
  isEditWorkInstruction: boolean;
  formData: IWorkInstructionCreate | null;
  fileData: any | null;
  showBackButton: boolean;
  onGoNext: (formData: IWorkInstructionCreate, fileData: any) => void;
  onGoBack: (formData: IWorkInstructionCreate, fileData: any) => void;
}

enum WORK_INSTRUCTION_TYPE {
  STANDARD = '2D',
  CAD_ENABLED = '3D',
  AUTO_INSTRUCT = 'VIDEO',
}

const NewWorkInstruction: React.FC<Props> = (props) => {
  const [formValues, setFormValues] = useState<IWorkInstructionCreate | null>(props.formData);
  const [fileValues, setFileValues] = useState<any>(props.fileData);
  const [title, setTitle] = useState<string>('');
  const [buttonText, setButtonText] = useState<string>('');
  const [workInstruction, setWorkInstruction] = useState<IWorkInstructions>();
  const [contentReady, setContentReady] = useState<boolean>(true);
  const companyId = localStorage.getItem('company');
  const fileUpload = useFileUpload();
  const search = window.location.pathname;
  const workInstructionId = search
    .split('/')
    .splice(search.split('/').length - 1)
    .toString();
  const navigate = useNavigate();
  const theme = useTheme();

  const { isTourOpen, setIsTourOpen, tourStep, setTourStep, setTourTitle, tourSteps, setTourSteps } = useTour();

  const { t } = useTranslation();

  useEffect(() => {
    if (props.formData) {
      Object.keys(props.formData).forEach((key) => {
        const value = props.formData && props.formData[key] ? props.formData[key] : '';
        formik.setFieldValue(key, value, false);
      });
    }
    return () => {};
  }, [props.formData]);

  useEffect(() => {
    if (!props.isEditWorkInstruction) {
      setTitle(t('ADD_WI.ADD_WI_TITLE'));
      setButtonText(t('BUTTON.NEXT'));
    } else {
      setTitle(t('ADD_WI.EDIT_WI_TITLE'));
      setButtonText(t('BUTTON.SAVE'));
    }
  }, [props.isEditWorkInstruction]);

  useEffect(() => {
    if (props.isEditWorkInstruction) {
      setContentReady(false);
      TutorialService.getWorkInstructionsById(workInstructionId)
        .then((e) => {
          const {
            data: { data: workInstructionData },
          } = e;
          setWorkInstruction(e.data.data);
          const fields = ['workInstructionName', 'workInstructionDescription', 'brand', 'is2d'];
          fields.forEach((field) => formik.setFieldValue(field, workInstructionData[field], false));
          // add work instruction type for existing data when in edit mode
          formik.setFieldValue('workInstructionType', workInstructionData.is2d ? WORK_INSTRUCTION_TYPE.STANDARD : WORK_INSTRUCTION_TYPE.CAD_ENABLED);
        })
        .finally(() => {
          setContentReady(true);
        });
    }
  }, []);

  const editWorkInstruction = (values) => {
    if (workInstructionId !== 'addWorkInstruction' && workInstruction) {
      const request = workInstruction;
      request.workInstructionName = values.workInstructionName;
      request.workInstructionDescription = values.workInstructionDescription;
      request.brand = values.brand;
      // update workInstructionType once updating the workInstruction
      request.workInstructionType = values.workInstructionType;
      TrainerService.editWorkInstruction(request.workInstructionId, request).then((response) => {
        navigate(`/trainer/procedures/${companyId}/${request.workInstructionId}`);
      });
    }
  };
  const url = window.location.search;
  const urlParams = new URLSearchParams(url);
  const parentFolderId = urlParams.get('parentFolderId');
  const formik = useFormik({
    initialValues: {
      workInstructionName: isTourOpen ? 'Sample work instruction' : '',
      workInstructionDescription: isTourOpen ? 'This is the sample work instruction to try Mentor more quickly and efficiently.' : '',
      brand: isTourOpen ? 'CDS' : '',
      is2d: false,
      workInstructionType: '',
      ...(parentFolderId ? { parentFolderId: parentFolderId } : {}),
    },

    onSubmit: (values) => {
      setFormValues(values);
      fileUpload.dispatch({ type: 'uploadCad', payload: fileValues });
      props.onGoNext(values, fileValues);
    },
  });

  const handleBackButtonClick = () => {
    props.onGoBack(formik.values, fileValues);
  };

  const hasDataLength = (obj: any, len: number): boolean => {
    return !!(obj && obj.length > len);
  };

  const isValidForm = (): boolean => {
    let isValid = true;

    if (formik && formik.values) {
      isValid = !!(props.isEditWorkInstruction || formik.values.is2d ? !fileValues : fileValues);
      isValid = isValid && hasDataLength(formik.values.workInstructionName, 1);
      isValid = isValid && hasDataLength(formik.values.workInstructionDescription, 1);
      isValid = isValid && hasDataLength(formik.values.brand, 1);
    } else {
      isValid = false;
    }

    return isValid;
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Card className="work-instruction-create-card" elevation={3} style={{ borderRadius: '12px', maxWidth: '548px', margin: '10px 10px 20px 10px', padding: '16px' }}>
        <Typography sx={{ textAlign: 'left', padding: '16px', fontSize: '20px' }}>{title}</Typography>
        <CardContent sx={{ overflow: 'auto' }}>
          {!contentReady && (
            <Box component="div" className="align-center">
              <Loading />
            </Box>
          )}
          <ButtonGroup variant="outlined" aria-label="outlined button group" sx={{ display: 'flex', mb: '24px' }} disabled={props.isEditWorkInstruction}>
            <Tooltip title="Create procedure with Image, Video, PDF and Text">
              <Button
                disableElevation
                id={'is2d'}
                onClick={() => {
                  formik.setFieldValue('is2d', true);
                  formik.setFieldValue('workInstructionType', WORK_INSTRUCTION_TYPE.STANDARD);
                }}
                variant={formik.values.is2d ? 'contained' : 'outlined'}
                sx={{
                  brorderRadius: '6px 0px 0px 6px',
                  textTransform: 'none',
                }}
              >
                {t('ADD_WI.STANDARD') as string}
              </Button>
            </Tooltip>
            <Tooltip title="Create 3D procedure along with Image, Video, PDF, Text and 3D">
              <Button
                disableElevation
                id={'is2d'}
                onClick={() => {
                  formik.setFieldValue('is2d', false);
                  formik.setFieldValue('workInstructionType', WORK_INSTRUCTION_TYPE.CAD_ENABLED);
                }}
                variant={!formik.values.is2d ? 'contained' : 'outlined'}
                sx={{
                  brorderRadius: '0px 6px 6px 0px',
                  textTransform: 'none',
                }}
              >
                {t('ADD_WI.CAD_ENABLED') as string}
              </Button>
            </Tooltip>
          </ButtonGroup>
          <FormControl sx={{ width: '100%' }} className="tour-instruction-name">
            <FormLabel
              component="legend"
              className="formStyle"
              sx={{
                color: 'rgba(28, 29, 31, .8)',
                fontSize: '12px',
                fontWeight: '500',
                paddingBottom: '8px',
              }}
            >
              {t('ADD_WI.INSTRUCTION_NAME') as string}
            </FormLabel>
            <TextField label="" name="workInstructionName" value={formik.values.workInstructionName} onChange={formik.handleChange} placeholder={t('ADD_WI.INSTRUCTION_NAME') as string} id="component-outlined" style={{ borderRadius: '8px !important' }} inputProps={{ maxLength: 55 }} required />
            <FormHelperText id="component-helper-text" className="helperText">
              {55 - formik.values.workInstructionName.length} {t('COMMON.CHARACTERS_LEFT') as string}
            </FormHelperText>
          </FormControl>

          <FormControl sx={{ width: '100%', marginTop: '24px' }} className="tour-instruction-desc">
            <FormLabel
              component="legend"
              className="formStyle"
              sx={{
                color: 'rgba(28, 29, 31, .8)',
                fontSize: '12px',
                fontWeight: '500',
                paddingBottom: '8px',
              }}
            >
              {t('ADD_WI.INSTRUCTION_DESCRIPTION') as string}
            </FormLabel>
            <TextField value={formik.values.workInstructionDescription} name="workInstructionDescription" id="outlined-multiline-static" onChange={formik.handleChange} placeholder={t('ADD_WI.INSTRUCTION_DESCRIPTION') as string} multiline rows={4} inputProps={{ maxLength: 170 }} required />
            <FormHelperText id="component-helper-text" className="helperText">
              {170 - formik.values.workInstructionDescription.length}  {t('COMMON.CHARACTERS_LEFT') as string}
            </FormHelperText>
          </FormControl>
          <FormControl sx={{ width: '100%', marginTop: '24px' }} className="tour-instruction-brand">
            <FormLabel
              component="legend"
              className="formStyle"
              sx={{
                color: 'rgba(28, 29, 31, .8)',
                fontSize: '12px',
                fontWeight: '500',
                paddingBottom: '8px',
              }}
            >
              {' '}
              {t('ADD_WI.PRODUCT_BRAND_NAME') as string}{' '}
            </FormLabel>
            <TextField value={formik.values.brand} name="brand" placeholder={t('ADD_WI.PRODUCT_BRAND_NAME') as string} onChange={formik.handleChange} id="component-outlined" inputProps={{ maxLength: 55 }} required />
          </FormControl>
          <FormControl sx={{ width: '100%', marginTop: '24px', marginBottom: '24px' }} className="tour-cad-asset">
            {!props.isEditWorkInstruction && !formik.values.is2d && (
              <>
                <FormLabel
                  component="legend"
                  className="formStyle"
                  sx={{
                    color: 'rgba(28, 29, 31, .8)',
                    fontSize: '12px',
                    fontWeight: '500',
                    paddingBottom: '8px',
                  }}
                >
                  {t('ADD_WI.UPLOAD_CAD_ASSET') as string}
                </FormLabel>
                <Dropzone className="file-upload-active" acceptedFiles={['.pvz', '.gltf', '.glb', '.stp', '.step']} selectedFiles={props.fileData} maxFileSize={environment.AppConfigs.fileUploadLimit.cad ?? 250} fileUploadValues={(data) => setFileValues(data)} />
                <Stack direction="row" justifyContent="space-between" alignItems="flex-start" sx={{ py: 1 }} spacing={2}>
                  <Box component="span" className="helperText" sx={{ color: 'rgba(28, 29, 31, .8)' }}>
                    {t('COMMON.MAX_FILE_SIZE', { MB: (environment.AppConfigs.fileUploadLimit.cad ?? 250) + 'MB' }) as string}
                  </Box>
                  <Box component="span" className="helperText" sx={{ color: 'rgba(28, 29, 31, .8)' }}>
                    {t('COMMON.SUPPORTS') as string} ".pvz", ".stp", ".step", ".gltf",".glb"
                  </Box>
                </Stack>
              </>
            )}
          </FormControl>
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end', margin: '0px 12px 12px 12px', paddingTop: '0px' }}>
          {props.showBackButton && (
            <IconButton size="small" sx={{ float: 'right', borderRadius: 1 }} title="Back" onClick={handleBackButtonClick}>
              <LeftArrow />
            </IconButton>
          )}
          <Button
            className="tour-wi-next"
            size="small"
            variant="contained"
            sx={{ float: 'right' }}
            type="submit"
            disabled={!isValidForm() && !isTourOpen}
            onClick={() => {
              editWorkInstruction(formik.values);
            }}
          >
            {buttonText}
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};

export default NewWorkInstruction;
