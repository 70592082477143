import { BorderAll, Circle, PlayArrow, ViewInAr, ViewList, VolumeMute, } from "@mui/icons-material";
import { AppBar, Toolbar, IconButton, Typography, Button, Box, Paper, Slider, Stack, ToggleButton, ToggleButtonGroup, Avatar, List, ListItem, ListItemAvatar, ListItemText, } from "@mui/material";
import React from "react";
import { Tools } from "../../../../../entities/Trainee/WorkInstructions";
import "./ToolsPopover.scss";
import { styled } from "@mui/material/styles";

interface Props {
    tools: Tools[];
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.secondary,
}));

const ToolsPopover: React.FC<Props> = (props) => {
    const [layout, setLayout] = React.useState<string | null>("left");
    return (
        <Paper className="tools-container">
            <Box component="div" className="tools-header">
                <Typography variant="button" component="div" className="tools-title" >
                    Tools
                </Typography>
                <ToggleButtonGroup value={layout} exclusive onChange={(e, n) => setLayout(n)} aria-label="text alignment" >
                    <ToggleButton value="left" aria-label="left aligned">
                        <ViewList />
                    </ToggleButton>
                    <ToggleButton value="center" aria-label="centered">
                        <BorderAll />
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>
            <Box component="div" className={"tools-content-container"}>
                {layout === "left" && (
                    <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper", }} >
                        {props.tools.map((t, i) => (
                            <ListItem key={i}>
                                <ListItemAvatar>
                                    <Avatar variant="square" sx={{borderRadius:'5px !important', height:'50px',width:'50px'}}>
                                        <img src={t.img} className="tool-img" alt="" />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={t.title} />
                            </ListItem>
                        ))}
                    </List>
                )}
                {layout === "center" && (
                    <Box component="div" sx={{ display: "flex", flexWrap: "wrap", p: 1, m: 1, bgcolor: "background.paper", maxWidth: 300, borderRadius: 1, }} >
                        {props.tools.map((t, i) => (
                            <Item key={i} sx={{borderWidth:'0', height:'165px',width:'40%',padding:'5px',margin:'5px'}} elevation={0}>
                                <Avatar variant="square" sx={{borderRadius:'5px !important',marginBottom:'5px',height: '100px', width: '100%'}}> <img src={t.img} className="tool-img" alt="" />
                                </Avatar>
                                <Box component="span" sx={{wordBreak:"break-word", marginTop:'10px'}} title={t.title}>{t.title}</Box>
                            </Item>
                        ))}
                    </Box>
                )}
            </Box>
        </Paper>
    );
};

export default ToolsPopover;
