import {
  Box,
  CardContent,
  Typography,
  Chip,
  Stack,
  Tooltip,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
} from "@mui/material"
import React, { useState } from "react"
import { User } from "../../entities/User/MockUser"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import "./UserCard.scss"
import { StyledCard } from '../../components/Styled/StyledCard';
import { StyledSwitch } from '../../components/Styled/StyledSwitch';
import { useTranslation } from "react-i18next"

interface Props {
  user: User
  onEdit: (userId: String) => void
  onDelete: (user: User) => void
  canUpdate: boolean;
  canDelete: boolean;
  onStatusChange: (flag: boolean, user: User) => Promise<boolean>;
  isLoading: boolean
}

const UserCard: React.FC<Props> = (props) => {
  const shortenEmail = (text) => {
    return text.length > 55 ? text.substring(0, 55) + "..." : text
  }

  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleEdit = () => {
    props.onEdit(props.user.id)
  }
  const handleDelete = () => {
    props.onDelete(props.user)
  }

  const handleStatusChange = async (flag: boolean, data: any) => {
    setLoading(true);
    await props.onStatusChange(flag, data);
    setLoading(false);
  }

  const { t } = useTranslation();

  return (
    <StyledCard minCardHeight={"250px"}>
      <Box
        component="div"
        sx={{ display: "flex", flexDirection: "column", p: 2, width: "100%" }}
      >
        <CardContent sx={{ flex: "1 0 auto", textAlign: "left", pt: 0, pb: 0 }}>
          <Stack
            sx={{
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "start",
              pb: 1,
              width: "fit-content",
            }}
            flexWrap={"wrap"}
          >
            <Chip
              size="small"
              variant="outlined"
              label={t('COMMON.'+ props.user.persona.toUpperCase())}
              sx={{
                marginRight: "0.5rem",
                borderRadius: "0",
                opacity: "0.6",
                marginBottom: { xs: "0.5rem", md: "0" },
              }}
            />
          </Stack>
          <Typography component="div" variant="h6" className="user-text">
            {props.user.firstName + " " + props.user.lastName}
          </Typography>
          <Tooltip title={props.user.email} placement="bottom-start">
            <Typography
              variant="subtitle2"
              color="text.secondary"
              component="div"
              className="user-text"
            >
              {shortenEmail(props.user.email)}
            </Typography>
          </Tooltip>
          <Typography
            variant="subtitle2"
            color="text.secondary"
            component="div"
            className="user-text"
          >
            {props.user.company}
          </Typography>
          <Typography
            variant="subtitle2"
            color="text.secondary"
            component="div"
            className="user-text"
          >
            {new Date(props.user.createdDate as any * 1000).toLocaleDateString()}
          </Typography>
        </CardContent>
        <Box
          component="div"
          sx={{
            display: "flex",
            alignItems: "center",
            pl: 1,
            pr: 1,
            width: "100%",
          }}
        >
          <Box
            component="div"
            sx={{
              display: "flex",
              alignItems: "center",
              pl: 2,
              justifyContent: "flex-start",
              width: "100%",
            }}
          >
            {!props.isLoading ?
            <FormControlLabel
              disabled={!props.canUpdate}
              className="active"
              control={<StyledSwitch  name="isActive" checked={props.user.isActive}
              color={props.user.isActive ? 'primary' : 'error'}
              onChange={(e) => handleStatusChange(e.target.checked, props.user)}
              />}
              label={props.user.isActive ? t('USER.ACTIVE') : t('USER.INACTIVE')}
            /> : <Skeleton sx={{height: '32px', width:'50%'}} />
            }
          </Box>
          <Box
            component="div"
            sx={{
              display: "flex",
              alignItems: "center",
              pr: 2,
              pb: 1,
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <IconButton
              size="small"
              onClick={handleClick}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              sx={{
                borderRadius: "5",
                border: "1px solid gray",
                p: 0,
                color: "black",
              }}
            >
              <MoreHorizIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {props.canUpdate && <MenuItem onClick={handleEdit}>{t('COMMON.EDIT')}</MenuItem>}
              {props.canDelete && <MenuItem onClick={handleDelete}>{t('COMMON.DELETE')}</MenuItem>}
            </Menu>
          </Box>
        </Box>
      </Box>
    </StyledCard>
  )
}

export default UserCard
