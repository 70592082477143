import { Close } from '@mui/icons-material';
import { Box, Paper, Popover, LinearProgress, useTheme, Badge } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './InstructionPlayer.scss';
import RotatePopover from '../../PopOverContent/Rotate/RotatePopover';
import { AnnotationsIcon, BoxExpand, CommentBox, ContainerIcon, Pause, Play, PlayAnnotationsIcon, RotateIcon } from '../../../../Icons/CdsIcons';
import ModesPopover from '../../PopOverContent/Modes/ModesPopover';
import { StyledIconButton } from '../../../../Styled/StyledIconButton';
import { FullScreenMode } from '../CanvasControls/CanvasControls';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUserOutlined';
import { useCanvasState } from '../../../../../state/canvasState';
import usePpeList from '../../../../../hooks/ppeList';
import { useTranslation } from 'react-i18next';
import { IpProtectionDialog } from '../../../../Trainer/Dialog/IpProtectionDialog/IpProtectionDialog';
import { CommentSideBar } from '../../../../Analytics/ProcedureReports/CommentsSideBar/CommentSideBar';
import { IProcedure } from '../../../../../entities/Analytics/AnalyticsProcedure';
import TutorialService from '../../../../../services/trainee/TraineeService';

interface Props {
  stepHasLinkedAnimation: boolean;
  isTrainer?: boolean;
  timerLoading?: any;
  isViewComment?: boolean;
  procedure?: IProcedure;
  stepIndex?: number;
  is2dStep?: boolean;
}

const InstructionPlayer: React.FC<Props> = (props) => {
  const canvasState = useCanvasState();
  const mediaReferencesEnabled = useCanvasState((state) => state.mediaReferencesEnabled);
  const setMediaReferencesEnabled = useCanvasState((state) => state.setMediaRefEnabled);
  const setReplayState = useCanvasState((state) => state.setReplay);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [currentPopover, setCurrentPopover] = useState<string | null>(null);
  const [showRightPaneComment, setShowRightPaneComment] = useState<boolean>(false);
  const [stepCommentThreads, setStepCommentThreads] = useState<any>([]);
  const [stepComments, setStepComments] = useState<any>([]);
  const [rotateModel, setRotateModel] = useState<boolean>(false);
  const [showTools, setShowTools] = useState<boolean>(false);
  const [showIpProtectionDialog, setShowIpProtectionDialog] = useState<boolean>(false);
  const currentlyPlaying = useCanvasState((state) => state.currentlyPlaying);
  const [threadUpdate, setThreadUpdate] = useState<boolean>(false);
  const [threadLoading, setThreadLoading] = useState<boolean>(false);
  const [totalUnreadComments, setTotalUnreadComments] = useState<number>(0);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, popover: string) => {
    if (window.innerWidth < 900) {
      setAnchorEl(document.querySelector('.popoverTarget'));
      setShowTools(false);
    } else setAnchorEl(event.currentTarget);
    if (popover === 'rotate') {
      setRotateModel(true);
    }
    setCurrentPopover(popover);
  };
  const handleClose = () => {
    if (window.innerWidth < 900) setShowTools(true);
    setAnchorEl(null);
    setCurrentPopover(null);
    setRotateModel(false);
    useCanvasState.setState({ rotate: null });
  };

  const ipProtectionDialog = () => {
    setAnchorEl(null);
    setShowIpProtectionDialog(true);
  };

  const closeIpProtectionDialogHandler = () => {
    setShowIpProtectionDialog(false);
    setAnchorEl(null);
  };

  const handleModeClose = () => {
    if (window.innerWidth < 900) setShowTools(true);
    setAnchorEl(null);
    setCurrentPopover(null);
    useCanvasState.setState({ rotate: null });
    useCanvasState.setState({ changeMode: '' });
  };
  const listStepCommentThread = (procedureId: string) => {
    setThreadLoading(true);
    TutorialService.listStepCommentThreads(procedureId)
      .then((e) => {
        const filteredData = e.data.data.filter((obj) => obj.stepNo === props.stepIndex);
        setStepCommentThreads(filteredData);
        let unreadCount: any = 0;
        e?.data?.data?.map((threadData) => {
          unreadCount += threadData.unreadCount;
        });
        setTotalUnreadComments(unreadCount);
        setThreadLoading(false);
      })
      .catch(({ response }) => {
        setThreadLoading(false);
      });
  };

  useEffect(() => {
    if (props.procedure) {
      listStepCommentThread(props.procedure.procedureId);
      setThreadUpdate(false);
    }
  }, [threadUpdate]);

  const commentSideBarDialogHandler = () => {
    setAnchorEl(null);
    setShowRightPaneComment(true);
  };

  const theme = useTheme();
  const { showIntro } = usePpeList();
  const { t } = useTranslation();

  useEffect(() => {
    useCanvasState.setState({ replay: false });
  }, [canvasState.currentlyPlaying]);

  return (
    <>
      <CommentSideBar
        listAllThreads={() => {
          if (props?.procedure) {
            listStepCommentThread(props?.procedure?.procedureId);
          }
        }}
        showRightPaneComment={showRightPaneComment}
        setShowRightPaneComment={setShowRightPaneComment}
        isTrainer={props.isTrainer}
        procedure={props.procedure}
        stepIndex={props.stepIndex}
        stepComments={stepComments}
        stepCommentThreads={stepCommentThreads}
        threadUpdate={threadUpdate}
        setThreadUpdate={setThreadUpdate}
        threadLoading={threadLoading}
      />
      <IpProtectionDialog
        openDialog={showIpProtectionDialog}
        closeDialog={closeIpProtectionDialogHandler}
      />
      <Box
        component="div"
        className="mobile-canvas-control-container-right canvas-control-container-center"
      >
        <Paper
          variant="outlined"
          className="canvas-control-container-center canvas-control-container-buttons"
          sx={{ display: { xs: showTools ? 'flex' : 'none', md: 'flex' } }}
        >
          {props.is2dStep && (
            <>
              <div className="instruction-player-length">
                {canvasState.currentlyPlaying ? (
                  <StyledIconButton
                    tooltip={t('3D_VIEWER.PAUSE')}
                    onClick={() => {
                      useCanvasState.setState({ replay: false });
                    }}
                  >
                    <Pause />
                  </StyledIconButton>
                ) : (
                  <StyledIconButton
                    tooltip={t('3D_VIEWER.REPLAY_STEP')}
                    disabled={showIntro || !props.stepHasLinkedAnimation}
                    onClick={() => setReplayState(true)}
                  >
                    <Play />
                  </StyledIconButton>
                )}
                {canvasState.currentlyPlaying && (
                  <Box
                    component={'div'}
                    sx={{ display: { xs: 'none', md: 'flex' }, width: '70px' }}
                  >
                    <LinearProgress />
                  </Box>
                )}
              </div>
              <StyledIconButton
                selected={rotateModel}
                canFlip={true}
                tooltip={t('3D_VIEWER.ROTATE_MODEL')}
                onClick={(e) => handleClick(e, 'rotate')}
                disabled={canvasState.currentlyPlaying}
              >
                <RotateIcon />
              </StyledIconButton>
            </>
          )}
          {canvasState.changeMode === 'outline' || canvasState.changeMode === 'translucent' || canvasState.changeMode === 'explode' ? (
            <StyledIconButton
              tooltip={`Close ${canvasState.changeMode} view`}
              onClick={(e) => handleModeClose()}
              disabled={canvasState.currentlyPlaying}
            >
              <Close />
            </StyledIconButton>
          ) : (
            props.is2dStep && (
              <StyledIconButton
                selected={currentPopover === 'modes' && canvasState.changeMode !== 'explode' && canvasState.changeMode !== 'outline' && canvasState.changeMode !== 'translucent'}
                canFlip={true}
                tooltip={t('3D_VIEWER.VIEW_MODES')}
                onClick={(e) => handleClick(e, 'modes')}
                disabled={canvasState.currentlyPlaying}
              >
                <BoxExpand />
              </StyledIconButton>
            )
          )}

          {props.is2dStep && (
            <>
              <StyledIconButton
                selected={canvasState.showAnnotations}
                tooltip={t('3D_VIEWER.INFO_TOOLTIP')}
                onClick={(e) => useCanvasState.setState({ showAnnotations: !canvasState.showAnnotations })}
                disabled={canvasState.currentlyPlaying}
              >
                <AnnotationsIcon />
              </StyledIconButton>
              <StyledIconButton
                className="play-annotation-icon"
                selected={mediaReferencesEnabled}
                tooltip={t('3D_VIEWER.MEDIA_TOOLTIP')}
                onClick={(e) => setMediaReferencesEnabled(!mediaReferencesEnabled)}
                disabled={canvasState.currentlyPlaying}
              >
                <PlayAnnotationsIcon />
              </StyledIconButton>
            </>
          )}
          <Box
            component="div"
            sx={{ display: { md: 'none', xs: 'flex' } }}
          >
            <FullScreenMode playing={canvasState.currentlyPlaying} />
          </Box>
          <Box
            component="div"
            sx={{ display: { md: 'none', xs: 'flex' } }}
          >
            <StyledIconButton
              tooltip={t('NAVBAR.IP_PROTECTION')}
              disabled={canvasState.currentlyPlaying}
            >
              <VerifiedUserIcon
                color="primary"
                onClick={ipProtectionDialog}
              />
            </StyledIconButton>
          </Box>
          {!props.isTrainer && (
            <Box
              component="div"
              sx={{ display: { md: 'none', xs: 'flex' } }}
            >
              <StyledIconButton
                tooltip={t('NAVBAR.COMMENTS')}
                disabled={canvasState.currentlyPlaying}
                onClick={commentSideBarDialogHandler}
              >
                <Badge
                  badgeContent={totalUnreadComments}
                  color="primary"
                >
                  <CommentBox color="primary" />
                </Badge>
              </StyledIconButton>
            </Box>
          )}
        </Paper>
        <Box
          component="div"
          className="popoverTarget"
          sx={{ alignItems: 'center', margin: '5px 0px 0px 0px', display: { xs: 'flex', md: 'none' } }}
        >
          {!currentPopover && (
            <StyledIconButton
              selected={showTools}
              canFlip={true}
              tooltip={t('3D_VIEWER.VIEW_ALL_OPTIONS')}
              onClick={(e) => setShowTools(!showTools)}
              disabled={canvasState.currentlyPlaying}
            >
              <ContainerIcon />
            </StyledIconButton>
          )}

          {currentPopover === 'rotate' && (
            <StyledIconButton
              className="rotate-icon"
              selected={currentPopover === 'rotate'}
              canFlip={true}
              tooltip={t('3D_VIEWER.ROTATE_MODEL')}
              onClick={(e) => handleClick(e, 'rotate')}
              disabled={canvasState.currentlyPlaying}
            >
              <RotateIcon />
            </StyledIconButton>
          )}

          {currentPopover === 'modes' && (
            <StyledIconButton
              className="modes-icon"
              selected={currentPopover === 'modes'}
              canFlip={true}
              tooltip={t('3D_VIEWER.VIEW_MODES')}
              onClick={(e) => handleClick(e, 'modes')}
              disabled={canvasState.currentlyPlaying}
            >
              <BoxExpand />
            </StyledIconButton>
          )}
        </Box>
        {currentPopover && (
          <Popover
            PaperProps={{ style: { backgroundColor: 'transparent' } }}
            open={open}
            disableScrollLock={true}
            sx={{ marginTop: '-8px' }}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            anchorEl={anchorEl}
            onClose={handleClose}
            transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            {currentPopover === 'rotate' && <RotatePopover />}
            {currentPopover === 'modes' && <ModesPopover />}
          </Popover>
        )}
      </Box>
    </>
  );
};

export default InstructionPlayer;
