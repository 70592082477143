import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Typography } from '@mui/material';
import Link, { LinkProps } from '@mui/material/Link';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Toast } from '../../components/Notifications/Notification';
import { IPersona } from '../../entities/Organization/Persona/Persona';
import PersonaService from '../../services/Persona/PersonaService';
import { getMessage } from '../../shared/utils/message-helpers';
import PersonaForm from './PersonaForm';
import { useTranslation } from 'react-i18next';

interface AddPersonaProps {}

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

const LinkRouter = (props: LinkRouterProps) => <Link {...props} component={RouterLink as any} />;

const AddPersona: React.FC<AddPersonaProps> = () => {
  const breadcrumbs = [
    <Link underline="hover" key="1" sx={{ opacity: '0.7' }}>
      Administration
    </Link>,
    <LinkRouter underline="hover" key="2" to="/persona" sx={{ opacity: '0.7' }}>
      Persona
    </LinkRouter>,
    <Typography key="3" color="primary.main">
      Create New Persona
    </Typography>,
  ];
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const addPersonaHandler = (personaData: IPersona) => {
    setLoading(true);
    PersonaService.createPersona(personaData)
      .then((e) => {
        setLoading(false);
        Toast.success(t('OPERATION_SUCCESS', { ns: 'success' }), t(e.data.messageCode, { ns: 'success' }) || e.data.message);
        backHandler();
      })
      .catch(({ response }) => {
        setLoading(false);
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        console.error('AddPersona -> addPersonaHandler', response);
      });
  };

  const backHandler = () => {
    navigate('/persona');
  };

  return (
    <>
      <Breadcrumbs sx={{ margin: '2rem' }} separator={<NavigateNextIcon color="primary" fontSize="small" />} aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>
      <PersonaForm onBack={backHandler} onSubmit={addPersonaHandler} persona={{}} isLoading={loading} />
    </>
  );
};

export default AddPersona;
