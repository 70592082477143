import { Button, Typography } from '@mui/material';
import { BasicDialog } from '../BasicDialog/BasicDialog';
import { useTranslation } from 'react-i18next';

export const RevokeConfirmationDialog = (props) => {
  const {t} = useTranslation();
  return (
    <BasicDialog
      title={t("PROCEDURE.REMOVE_ACCESS")}
      onClose={() => {
        props.closeDialog();
      }}
      onOpen={props.openDialog}
      actions={
        <>
          <Button
            onClick={() => {
              props.closeDialog();
            }}
            variant="outlined"
            size="small"
            type="submit"
          >
            {t("BUTTON.CANCEL")}
          </Button>
          <Button
            onClick={() => {
              props.revokeAssignees();
              props.closeDialog();
            }}
            variant="contained"
            size="small"
            type="submit"
          >
            {t("COMMON.REMOVE")}
          </Button>
        </>
      }
    >
      <Typography>{t("REMOVE_ACCESS_MODAL.PRIMARY_TEXT")}</Typography>
    </BasicDialog>
  );
};
