import { enqueueSnackbar } from 'notistack';
import { INotificationProps } from './NotificationSnackbar';

export class Toast {
  /**
   * Show notification using Snackbar and Alert components
   * @param variant "error" | "success" | "warning" | "info"
   * @param title Notification Title
   * @param message Notification Message, multiline text is separated by pipe '|'
   * @param persist
   */

  static showNotification(variant: any, title: string, message: string, persist: boolean = false) {
    const options: Partial<INotificationProps> = {
      variant: variant,
      title: title,
      persist: persist,
    };
    enqueueSnackbar(message, options);
  }

  /**
   * Show error notification using Snackbar and Alert components
   * @param title Notification Title
   * @param message Notification Message, multiline text is separated by pipe '|'
   */
  static error = (title: string, message: string) => {
    this.showNotification('error', title, message);
  };

  /**
   * Show info notification using Snackbar and Alert components
   * @param title Notification Title
   * @param message Notification Message, multiline text is separated by pipe '|'
   */
  static info = (title: string, message: string, persist: boolean) => {
    this.showNotification('info', title, message, persist);
  };

  /**
   * Show success notification using Snackbar and Alert components
   * @param title Notification Title
   * @param message Notification Message, multiline text is separated by pipe '|'
   */
  static success = (title: string, message: string) => {
    this.showNotification('success', title, message);
  };

  /**
   * Show warning notification using Snackbar and Alert components
   * @param title Notification Title
   * @param message Notification Message, multiline text is separated by pipe '|'
   */
  static warning = (title: string, message: string) => {
    this.showNotification('warning', title, message);
  };
}
