import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
//import './TourCompleteDialog.scss';
import { useMsal } from '@azure/msal-react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Avatar, Box, Button, DialogActions, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: '24px',
  },
  '& .MuiDialogActions-root': {
    padding: '0px 24px 24px 24px',
  },
}));

interface Props {
  openApprovalDialog: boolean;
}

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose?: () => void;
}

const TourCompleteDialog = ({ message, title, image, onClose }) => {
  const [open, setOpen] = React.useState(false);
  const { accounts } = useMsal();
  const name = accounts[0] && accounts[0].name;

  const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 0 }} {...other}>
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  useEffect(() => {
    setOpen(true);
  }, []);

  const handleClose = () => {
    onClose();
    setOpen(false);
  };

  const { t } = useTranslation();

  return (
    <div>
      <BootstrapDialog onClose={()=>handleClose()} aria-labelledby="customized-dialog-title" open={open}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={()=>handleClose()}></BootstrapDialogTitle>

        <img alt="Profile" height={300} width={600} src={'/imgs/WI_Tour.png'} />

        <DialogContent>
          <Typography gutterBottom sx={{ fontSize: '24px', fontWeight: 600 }}>
            {title}
          </Typography>
          <Typography
            gutterBottom
            sx={{
              color: 'rgba(31, 30, 32, 0.5)',
              fontWeight: 500,
              fontSize: '18px',
            }}
          >
            {message}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={()=>handleClose()}>
            {t('BUTTON.CONTINUE')}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default TourCompleteDialog;
