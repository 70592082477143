import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import "./SuccessDialog.scss";
import { useMsal } from "@azure/msal-react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

interface Props {
  openSuccessDialog: boolean;
  message: string;
}

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

export default function SuccessDialog(props) {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(true);
  }, []);

  const refreshPage = () => {
    window.location.reload();
  }

  const handleClose = () => {
    setOpen(false);
    props.closeDialog();
    refreshPage();
  };

  useEffect (() => {
    setTimeout (() => {
      handleClose();
    }, 5000)
  })

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogContent sx={{ m: 3 }}>
          <Typography gutterBottom>
            <CheckCircleRoundedIcon
              sx={{ fontSize: 60 }}
              className="align-approval-icon"
            />
          </Typography>
          <Typography gutterBottom className="align-msg-bottom">
            {props.message}
          </Typography>
          {props.children}
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
