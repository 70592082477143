import React from 'react';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { PieChart, Pie, Label, Cell, Legend, ResponsiveContainer, Tooltip } from 'recharts';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { IAssignedTraineeRecord } from '../../../../entities/Analytics/AssignedTraineeRecord';
import './TraineeAssignedCard.scss';
import { useDeviceResponsiveSize } from '../../../../hooks/useDeviceResponsiveSize';
import { useTranslation } from 'react-i18next';

interface TraineeAssignedCardProps {
  data: any;
  onCardClick: (userId: string) => void;
  enableClickEvent?: boolean;
}

const TraineeAssignedCard: React.FC<TraineeAssignedCardProps> = ({ data, onCardClick, enableClickEvent = true }) => {
  const responsiveSize = useDeviceResponsiveSize();
  const COLORS = ['#47D897', '#FFB349', '#D84747'];
  const { t } = useTranslation();

  const chartData = [
    { name: t('COMMON.WORKINSTRUCTION_TRAINEE_STATUS_COMPLETED'), value: data.completed },
    { name: t('COMMON.WORKINSTRUCTION_TRAINEE_STATUS_IN_PROGRESS'), value: data.in_progress },
    { name: t('COMMON.WORKINSTRUCTION_TRAINEE_STATUS_NOT_STARTED'), value: data.not_started },
  ];
  const legendData = [{ value: data.completed }, { value: data.in_progress }, { value: data.not_started }];

  interface CustomTooltipProps {
    active?: any;
    payload?: any;
    label?: any;
  }

  const CustomTooltip = ({ active, payload, label }: CustomTooltipProps) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">
            {`${payload[0]?.payload?.name}: `}
            <strong>{`${payload[0]?.payload?.value}`}</strong>
          </p>
        </div>
      );
    }

    return null;
  };

  const renderColorfulLegendText = (value: string, entry: any) => {
    const {
      color,
      payload: { value: count },
    } = entry;
    return <span style={{ color }}>{count}</span>;
  };

  const handleOnCardClick = () => {
    if (enableClickEvent) {
      onCardClick(data.user_id);
    }
  };

  const theme = useTheme();

  return (
    <Box
      component="div"
      className="org-trainee-assigned-card"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexGrow: 1,

        cursor: enableClickEvent ? 'pointer' : 'default',
      }}
      onClick={handleOnCardClick}
    >
      <AccountCircleIcon sx={{ fontSize: '2em' }} />
      <Box component="div" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', ml: '10px' }}>
        <Typography sx={{ fontSize: '16px', color: theme.palette.primary.main }}>{data.username}</Typography>

        <Typography sx={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.6)' }}>
          {data.completed + data.in_progress + data.not_started + ' '}
          {t('PROCEDURE.PROCEDURES') + ' Assigned'}
        </Typography>
      </Box>
    </Box>
  );
};

export default TraineeAssignedCard;
