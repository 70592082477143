import { useContext } from 'react';
import TourContext from '../context/TourContext';

const useTour = () => {
  const { isTourOpen, tourStep, setTourStep, setIsTourOpen, tourTitle, setTourTitle, tourSteps, setTourSteps, createWiTour, setCreateWiTour, tourGuide, setTourGuide } = useContext(TourContext);
  return {
    isTourOpen,
    tourStep,
    setTourStep,
    setIsTourOpen,
    tourTitle,
    setTourTitle,
    tourSteps,
    setTourSteps,
    createWiTour,
    setCreateWiTour,
    tourGuide,
    setTourGuide,
  };
};

export default useTour;
