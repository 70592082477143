import { CompanyTheme, Feedback, IProcedures, IUserStats, IUserStatsFinish, IUserStatsNext, IWorkInstructions } from '../../entities/Trainee/MockTrainee';
import http, { partnerInstance } from '../http-common';
import { DataResponse } from '../../shared/interfaces';

export const getTemplateProcedureTags = () => {
  let instance = http;
  let url = '/procedures/library/tags';
  return instance.get<any>(url);
};
export const addTagsToTemplateProcedure = (procedureId: string, tags: string[]) => {
  let instance = http;
  let url = '/procedures/library/tags/' + procedureId;
  return instance.post<any>(url, { tags: tags });
};

export const createNewTag = (tagName: string) => {
  let instance = http;
  let url = '/tags';
  return instance.post<any>(url, {name: tagName}).then(response => response.data);
}

export const deleteTag = (tagId: string) => {
  let instance = http;
  let url = '/tags/'+tagId;
  return instance.delete<any>(url);
}