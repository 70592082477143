import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Paper, Radio, RadioGroup, Rating, Slider, TextField, TextareaAutosize, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import {useTranslation} from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AssemblyState } from '../../../../../entities/AssemblyState';
import { Feedback, IUserStatsFinish } from '../../../../../entities/Trainee/MockTrainee';
import { CardQuestion, CardsData } from '../../../../../entities/Trainee/WorkInstructions';
import usePpeList from '../../../../../hooks/ppeList';
import TutorialService from '../../../../../services/trainee/TraineeService';
import { useCanvasState } from '../../../../../state/canvasState';
import { LeftArrow, ResizeIcon } from '../../../../Icons/CdsIcons';
import './StepsPopOver.scss';
import StepsPpeList from '../../../../IntroStepContainer/StepsPpeList';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { a } from 'react-spring';
import TraineeService from '../../../../../services/trainee/TraineeService';

interface Props {
  close: () => void;
  assemblyState: AssemblyState;
  cardsData: CardsData[];
  selectedStep: (step: number) => void;
  playing: boolean;
  totalStep: number;
  isTrainer?: boolean;
  isViewComment?: boolean;
  handlePartsClick?: any;
  updateQuestion: (question: CardQuestion) => void;
}

const StepsPopOver: React.FC<Props> = (props) => {
  const canvasState = useCanvasState();
  const [showAllSteps, setShowAllSteps] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [userFont, setuserFont] = useState(16);

  const { innerWidth, innerHeight } = window;
  const [paperWidth, setPaperWidth] = React.useState<any>(300);
  const [paperHeight, setPaperHeight] = React.useState<any>(window.innerWidth < 899 ? 200 : 400);
  const { showIntro } = usePpeList();
  const defaultFeedback = {
    procedureId: canvasState.procedureId,
    comments: '',
    recommendation: 0,
    ratings: 0,
  };
  const [feedBack, setFeedBack] = useState<Feedback>(defaultFeedback);
  let navigate = useNavigate();
  const { companyId, workInstructionId, procedureId } = useParams();
  const location = useLocation();
  const partnerPath = new RegExp('/partner');
  const partnerAccess = partnerPath.test(location.pathname);

  const theme = useTheme();
  const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
      color: theme.palette.primary.light,
    },
    '& .MuiRating-iconHover': {
      color: theme.palette.primary.light,
    },
  });
  const themePrimaryExtraLight = theme.palette.primary.main + '5c';
  const recommendationMarks = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((mark) => {
    return { value: mark, label: mark };
  });

  let initialX, initialY, deltaX, deltaY;

  const handleTouchDown = (e) => {
    initialX = e.touches[0].clientX;
    initialY = e.touches[0].clientY;
    const handleMouseMove = (e) => {
      deltaX = e.touches[0].clientX - initialX;
      deltaY = e.touches[0].clientY - initialY;
      setPaperHeight(paperHeight - deltaY);
      setPaperWidth(paperWidth + deltaX);
    };
    const handleMouseUp = () => {
      document.removeEventListener('touchmove', handleMouseMove);
      document.removeEventListener('touchend', handleMouseUp);
    };

    document.addEventListener('touchmove', handleMouseMove, { passive: false });
    document.addEventListener('touchend', handleMouseUp, { passive: false });
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    initialX = e.screenX;
    initialY = e.screenY;
    const handleMouseMove = (e) => {
      deltaX = e.screenX - initialX;
      deltaY = e.screenY - initialY;
      setPaperHeight(paperHeight - deltaY);
      setPaperWidth(paperWidth + deltaX);
    };
    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFeedBack((prev) => ({
      ...prev,
      [name]: ['recommendation', 'ratings'].includes(name) ? Number(value) : value,
    }));
  };

  const handleClickOpen = async (e) => {
    if (window.innerWidth > 900 || e.target.checked)
      if (workInstructionId && canvasState.procedureId) {
        const data: IUserStatsFinish = {
          completedSteps: props.totalStep,
        };
        await TutorialService.finishUserStats(workInstructionId, canvasState.procedureId, data);
        setOpen(true);
      }
  };

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    setOpen(false);
  };

  const handleCloseComplete = (finished: boolean) => {
    if (finished) {
      if (JSON.stringify(defaultFeedback) !== JSON.stringify(feedBack)) {
        TutorialService.createFeedback(feedBack).then((e) => {
          gotoAllProcedures();
        });
      } else {
        gotoAllProcedures();
      }
    }
    setOpen(false);
  };

  const gotoAllProcedures = () => {
    navigate(`/procedures/${companyId}/${workInstructionId}`);
  };

  const questionAnswer = (cardQuestion: CardQuestion, answer: any) => {
    cardQuestion.answer = answer;
    props.updateQuestion(cardQuestion);
  };

  const getAnsweredMultiplChoice = (index: number, uuid: string) => {
    if (props.assemblyState.userStats && props.assemblyState.userStats.questions) {
      var answer = props.assemblyState.userStats.questions.find((x) => x.questionUUID === uuid);
      if (answer) {
        return answer.answer === index;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const getShortAnswer = (uuid: string) => {
    if (props.assemblyState.userStats && props.assemblyState.userStats.questions) {
      var answer = props.assemblyState.userStats.questions.find((x) => x.questionUUID === uuid);
      if (answer) {
        return answer.answer;
      } else {
        return '';
      }
    } else {
      return '';
    }
  };

  const { t } = useTranslation();

  return (
    <Paper className="steps-container" sx={{ boxShadow: 'rgba(149, 157, 165, 0.5) 4px 8px 24px', height: paperHeight + 'px', width: paperWidth + 'px' }}>
      <Box
        component="div"
        onTouchStart={(e) => {
          handleTouchDown(e);
        }}
        onMouseDown={(e) => handleMouseDown(e)}
        sx={{ position: 'absolute', right: '12px', top: '12px', color: theme.palette.primary.main, cursor: 'grab', width: 'fit-content' }}
      >
        <ResizeIcon />
      </Box>

      {!showAllSteps ? (
        <>
          {!showIntro && (
            <Box component="div" className="steps-header">
              <Typography variant="button" component="div" className="steps-title" sx={{ fontSize: userFont + 'px' }}>
                {props.cardsData[props.assemblyState.currentStep - 1]?.title}
              </Typography>
            </Box>
          )}
          <Box component="div" className={'steps-content-container'}>
            <Box component="div" className="steps-content steps-content-box" >
              {!showIntro && (
                <Box component="div" sx={{ width: '100%' }}>
                  <Typography sx={{ fontSize: userFont + 'px', fontFamily: 'sans-serif' }} component="div" variant="h5" className="section-text">
                    <div dangerouslySetInnerHTML={{ __html: props.cardsData[props.assemblyState.currentStep - 1]?.description }} />
                  </Typography>
                  {props.cardsData[props.assemblyState.currentStep - 1]?.questions &&
                    props.cardsData[props.assemblyState.currentStep - 1]?.questions.length > 0 &&
                    props.cardsData[props.assemblyState.currentStep - 1]?.questions.map((q, i) => {
                      return (
                        <Box component="div" sx={{ marginBottom: '1rem' }} key={i}>
                          <Box component="div" sx={{ display: 'flex' }}>
                            {q.required && (
                              <Typography variant="h5" sx={{ marginRight: '0.5rem', color: 'red' }}>
                                *
                              </Typography>
                            )}
                            <Typography sx={{ paddingBottom: '0.5rem', fontSize: userFont + 'px' }} component="div" variant="h5" className="section-text">
                              {q.questionText}
                            </Typography>
                          </Box>
                          {q.questionType === 'shortAnswer' && <TextField id="outlined-basic" inputProps={{ className: 'section-text' }} variant="outlined" value={getShortAnswer(q.questionUUID)} sx={{ width: '100%' }} onChange={(e) => questionAnswer(q, e.target.value)} />}
                          {q.questionType === 'multipleChoice' && (
                            <FormControl sx={{ width: '100%' }}>
                              <RadioGroup aria-labelledby="demo-radio-buttons-group-label" defaultValue="female" name="radio-buttons-group">
                                {q.multipleSelectAnswers &&
                                  q.multipleSelectAnswers.map((a, index) => {
                                    return getAnsweredMultiplChoice(index, q.questionUUID) ? (
                                      <Box className="section-text" component="div" sx={{ backgroundColor: theme.palette.primary.main + '1c', paddingLeft: '16px', margin: '4px', borderRadius: '8px', fontSize: userFont + 'px' }} key={index}>
                                        <FormControlLabel value={a} checked control={<Radio />} label={<Typography className="section-text"> {a} </Typography>} key={index} onClick={(a) => questionAnswer(q, index)} />
                                      </Box>
                                    ) : (
                                      <Box component="div" key={index} sx={{ backgroundColor: theme.palette.primary.main + '1c', paddingLeft: '16px', margin: '4px', borderRadius: '8px' }}>
                                        <FormControlLabel
                                          value={a}
                                          control={<Radio />}
                                          label={
                                            <Typography className="section-text" sx={{ fontSize: userFont + 'px' }}>
                                              {' '}
                                              {a}{' '}
                                            </Typography>
                                          }
                                          key={index}
                                          onClick={(a) => questionAnswer(q, index)}
                                        />
                                      </Box>
                                    );
                                  })}
                              </RadioGroup>
                            </FormControl>
                          )}
                        </Box>
                      );
                    })}
                  {props.assemblyState.totalSteps === props.assemblyState.currentStep && !props.isTrainer && !partnerAccess && !props.isViewComment && (
                    <>
                      <FormControlLabel sx={{ display: { xs: 'flex', md: 'none' } }} label={<Typography sx={{ fontSize: '12px !important', wordBreak: 'break-word !important' }}> I {t('3D_VIEWER.FINISHED')} </Typography>} control={<Checkbox onChange={handleClickOpen} disabled={props.assemblyState.userStats?.completedSteps?.length < props.assemblyState.totalSteps - 1} />} />
                      <Button sx={{ display: { xs: 'none', md: 'flex' }, marginBottom: '18px' }} className="finish-button" variant="contained" color="primary" onClick={handleClickOpen} disabled={props.assemblyState.userStats?.completedSteps?.length < props.assemblyState.totalSteps - 1}>
                        {t('3D_VIEWER.FINISH')}
                      </Button>
                    </>
                  )}
                </Box>
              )}
            </Box>
          </Box>
          {!showIntro && (
            <Box component="div" sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box component="div" className="show-all-steps-btn">
                <Button variant="text" onClick={() => setShowAllSteps(true)}>
                  {t('3D_VIEWER.VIEW_ALL_STEPS')}
                </Button>
                {/* <Button sx={{ minWidth: 0, display: { sx: 'flex', md: 'none' } }} variant="text" onClick={(e) => { props.handlePartsClick(e, 'parts'); }} >
              {' '}
              {t('3D_VIEWER.PARTS')}
            </Button> */}
              </Box>
              <Box component="div" className="font-size-icons" sx={{ display: 'flex', alignItems: 'center', position: 'relative', bottom: props.assemblyState.totalSteps === props.assemblyState.currentStep && !props.isTrainer && !partnerAccess ? '45px' : '0px' }}>
                <Button
                  disabled={userFont >= 24}
                  sx={{ minWidth: 0 }}
                  onClick={() => {
                    if (userFont < 24) setuserFont(userFont + 2);
                  }}
                >
                  <AddIcon />
                </Button>
                <Button
                  disabled={userFont <= 16}
                  sx={{ minWidth: 0 }}
                  onClick={() => {
                    if (userFont > 16) setuserFont(userFont - 2);
                  }}
                >
                  <RemoveIcon />
                </Button>
              </Box>
            </Box>
          )}
        </>
      ) : (
        <>
          <Box component="div" className="steps-header">
            <Typography variant="button" component="div" className="steps-title">
              {t('3D_VIEWER.SELECT_STEP')}
            </Typography>
          </Box>
          <Box component="div" className={'steps-content-container'}>
            <Box component="div" className="steps-content steps-content-box">
              <List sx={{ width: '100%', maxWidth: '100%', bgcolor: 'background.paper' }} component="nav" aria-labelledby="nested-list-subheader">
                {props.cardsData &&
                  props.cardsData.map((e, i) => {
                    return (
                      <ListItemButton
                        className="select-step-menu"
                        key={i}
                        onClick={() => {
                          props.selectedStep(i + 1);
                          setShowAllSteps(false);
                        }}
                        disabled={props.playing}
                      >
                        <ListItemIcon sx={{ color: '#000000' }}> {(i + 1) as any} </ListItemIcon> <ListItemText disableTypography={window.innerWidth < 900} className="list-item-text" primary={e.title} />
                        <KeyboardArrowRightIcon sx={{ color: theme.palette.primary.main }} />
                      </ListItemButton>
                    );
                  })}
              </List>
            </Box>
          </Box>
          <Box component="div" className="show-all-steps-btn">
            <Button variant="text" onClick={() => setShowAllSteps(false)}>
              {t('3D_VIEWER.BACK_TO_CURRENT_STEP')}
            </Button>
          </Box>
        </>
      )}
      <Dialog open={open} onClose={handleClose} maxWidth="md" aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{t('3D_VIEWER.RATE_THIS_PROCEDURE')}</DialogTitle>
        {feedBack && (
          <DialogContent style={{ position: 'relative' }}>
            <Box component="div" className="star-rating-container">
              <StyledRating name="ratings" value={feedBack.ratings} onChange={handleChange} size="large" color="primary" />
              <Typography component="div" variant="h5" className="star-feedback-text">
                {feedBack.ratings}/5
              </Typography>
            </Box>
            <DialogContentText className="rating-description">{t('3D_VIEWER.RECOMMEND_LIKELY')}</DialogContentText>
            <Box component="div" className="rating-slider">
              <Slider aria-label="recommendation" name="recommendation" value={feedBack.recommendation} onChange={handleChange} valueLabelDisplay="auto" step={1} min={0} max={10} marks={recommendationMarks} sx={{ height: '10px' }} />
            </Box>
            <TextareaAutosize name="comments" value={feedBack.comments} onChange={handleChange} maxRows={4} aria-label="maximum height" placeholder={t('3D_VIEWER.ADD_ADDITIONAL_COMMENTS')} style={{ width: '100%', minHeight: '150px', maxWidth: '100%', borderRadius: '10px', backgroundColor: themePrimaryExtraLight, padding: '10px', margin: '0', boxSizing: 'border-box' }} />
          </DialogContent>
        )}
        <DialogActions sx={{ justifyContent: 'flex-start', margin: '0 12px 12px' }}>
          <Button title={t('3D_VIEWER.CLOSE')} onClick={() => handleCloseComplete(false)} sx={{ minWidth: '42px', width: '42px', lineHeight: 1, minHeight: '33px', maxHeight: '33px' }}>
            <LeftArrow />
          </Button>
          <Button title={t('3D_VIEWER.FINISH')} variant="contained" onClick={() => handleCloseComplete(true)} autoFocus sx={{ lineHeight: 1, minHeight: '33px', maxHeight: '33px' }}>
            {t('3D_VIEWER.FINISH')}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default StepsPopOver;
