import { Box, Button, Tab, Tabs } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import ViewSelector from '../../../pages/Trainee/TraineeHome/Toolbar/ViewSelector/ViewSelector';
import * as AnalyticsActions from '../../../redux/features/Analytics/Analytics.slice';
import { ANALYTICS_SUPPORTED_VIEWS, ANALYTICS_TYPES, ENTITY } from '../../../shared/enums';
import './Header.scss';
import DateRangeSelector from '../../DateRangeSelector/DateRangeSelector';

interface HeaderProps {}

const Header: React.FC<HeaderProps> = (props) => {
    const {
        currentUser: { organizationName },
    } = useAppSelector((state) => state.app);
    const { selectedAnalytics, selectedEntity, selectedOverview, selectedDateRangeOption, dateRange } = useAppSelector((state) => state.analytics.header);

    const dispatch = useAppDispatch();
    const setCurrentView = (selectedView) => {
        dispatch(AnalyticsActions.setSelectedOverview(selectedView));
    };

    const { t } = useTranslation();

    const handleSelectEntityTabClick = (selectedValue: ENTITY) => {
        dispatch(AnalyticsActions.setSelectedEntity(selectedValue));
    };

    const setSelectedAnalytics = (selectedValue: ANALYTICS_TYPES) => {
        dispatch(AnalyticsActions.setSelectedAnalytics(selectedValue));
    };

    const setDateRange = (dateRange) => {
        dispatch(AnalyticsActions.setDateRange(dateRange));
    }

    const setSelectedDateRangeOption = (dateRangeOption) => {
        dispatch(AnalyticsActions.setSelectedDateRangeOption(dateRangeOption));
    }

    return (
        <Box
            component="div"
            sx={{ display: 'flex', gap: '10px', flexDirection: 'column' }}
        >
            {selectedAnalytics === ANALYTICS_TYPES.TRAINER_ANALYTICS ? (
                <Box
                    component={'div'}
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                >
                    <ViewSelector
                        options={[
                            { viewName: ANALYTICS_SUPPORTED_VIEWS.PERSONAL, label: t('ANALYTICS_SECTION.PERSONAL_OVERVIEW') as string },
                            { viewName: ANALYTICS_SUPPORTED_VIEWS.ORGANISATIONAL, label: t('ANALYTICS_SECTION.ORGANIZATION_OVERVIEW', { orgName: organizationName }) as string },
                        ]}
                        currentView={selectedOverview}
                        handleViewSelect={(view) => setCurrentView(view)}
                    />
                </Box>
            ) : null}

            <Box
                component="div"
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <ViewSelector
                    options={[
                        { viewName: ANALYTICS_TYPES.TRAINER_ANALYTICS, label: t('ANALYTICS_SECTION.TRAINER_ANALYTICS') as string },
                        { viewName: ANALYTICS_TYPES.TRAINEE_ANALYTICS, label: t('ANALYTICS_SECTION.TRAINEE_ANALYTICS') as string },
                        { viewName: ANALYTICS_TYPES.TRAINING_ANALYTICS, label: t('ANALYTICS_SECTION.TRAINING_ANALYTICS') as string },
                    ]}
                    currentView={selectedAnalytics}
                    handleViewSelect={(view) => setSelectedAnalytics(view)}
                />
                {selectedAnalytics === ANALYTICS_TYPES.TRAINER_ANALYTICS ? (
                    <Box
                        component="div"
                        sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}
                    >
                        <Box
                            component="span"
                            sx={{ opacity: 0.6 }}
                        >
                            {t("WI_LIST.VIEW_BY")}
                        </Box>
                        <Tabs
                            value={Object.values(ENTITY).findIndex((val) => val === selectedEntity)}
                            onChange={(e, newTabIndex) => {
                                handleSelectEntityTabClick(Object.values(ENTITY)[newTabIndex]);
                            }}
                            sx={{
                                '& .MuiTabs-indicator': {
                                    display: 'none',
                                },
                                '.tab-button:first-child > button': {
                                    borderRadius: '8px 0px 0px 8px',
                                },
                                '.tab-button > button': {
                                    borderRadius: 0,
                                },
                                '.tab-button:last-child > button': {
                                    borderRadius: '0px 8px 8px 0px',
                                },
                            }}
                        >
                            {Object.values(ENTITY).map((key) => {
                                return (
                                    <Tab
                                        sx={{ padding: 0 }}
                                        className="tab-button"
                                        disableRipple={true}
                                        label={
                                            <Button
                                                size="small"
                                                variant={selectedEntity === key ? 'contained' : 'outlined'}
                                                sx={{ margin: 0, textTransform: 'capitalize', minWidth: '130px' }}
                                            >
                                                {t('ANALYTICS_SECTION.' + key)}
                                            </Button>
                                        }
                                    />
                                );
                            })}
                        </Tabs>
                    </Box>
                ) : (
                    <DateRangeSelector
                        dateRange={dateRange}
                        setDateRange={setDateRange}
                        selectedDateRangeOption={selectedDateRangeOption}
                        setSelectedDateRangeOption={setSelectedDateRangeOption}
                    />
                )}
            </Box>
        </Box>
    );
};

export default Header;
