import React, { useEffect, useState } from 'react';
import { BasicDialog, BootstrapDialogTitle, DialogTitleProps } from '../../components/Trainer/Dialog/BasicDialog/BasicDialog';
import { Box, Button, CircularProgress, FormControl, Grid, IconButton, InputAdornment, InputBase, InputLabel, MenuItem, Paper, Select, styled, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Add, RemoveCircleOutline, Search } from '@mui/icons-material';
import { SUPPORTED_LANGUAGES } from '../../shared/enums';
import { useAppSelector } from '../../hooks/redux';
import { AddSubstitutionPayload } from './Substitutions';

export const SUBSTITUTE_WORD_LIMIT = 100;

type SubstitutionToAdd = {
  langCode: string;
  substituteText: string;
};

interface AddSubstitutionModalProps {
  handleClose: () => void;
  isOpen: boolean;
  handleSaveClick: (payload: AddSubstitutionPayload[]) => void;
}

interface IDialoagHeader extends DialogTitleProps {
  handleAddLanguageClick: () => void;
  wordToSubstitute: string;
  handleWordToSubstituteChange: any;
  isAddLanguageDisabled: boolean;
}

const CustomInput = styled(InputBase)(({ theme }) => ({
  borderTop: '1px solid #D9D9D9',
  padding: '0 16px',
  '& .MuiInputBase-input': {
    paddingBottom: '14px',
    paddingTop: '14px',
    fontSize: '16px',
    fontWeight: '400',
    color: '#000000',
    opacity: 1,
  },
  '& svg': {
    visibility: 'hidden',
  },
  ':hover': {
    '& svg': {
      visibility: 'unset',
    },
  },
}));

const DialogHeader: React.FC<IDialoagHeader> = (props) => {
  const { handleAddLanguageClick, wordToSubstitute, handleWordToSubstituteChange, isAddLanguageDisabled } = props;

  const { t } = useTranslation();
  return (
    <BootstrapDialogTitle {...props}>
      <Box
        component={'div'}
        sx={{ display: 'flex', width: '100%', marginRight: '20px' }}
      >
        <FormControl>
          <TextField
            onChange={handleWordToSubstituteChange}
            placeholder={t('SUBSTITUTION.ADD_MODAL.INPUT_PLACEHOLDER')}
            style={{ borderRadius: '8px !important' }}
            value={wordToSubstitute}
          />
        </FormControl>

        <Button
          startIcon={<Add />}
          sx={{ marginLeft: 'auto' }}
          onClick={handleAddLanguageClick}
          disabled={isAddLanguageDisabled}
        >
          {t('SUBSTITUTION.ADD_MODAL.ADD_LANGUAGE')}
        </Button>
      </Box>
    </BootstrapDialogTitle>
  );
};
export const DialogueActionContainer = ({ handleClose, handleSave, isSaveDisabled }) => {
  const { t } = useTranslation();
  const [isActionButtonClicked, setIsActionButtonClicked] = useState<boolean>(false);
  const handleActionButtonClick = () => {
    setIsActionButtonClicked(true);
    handleSave()
  }
  return (
    <Box>
      <Button
        variant="outlined"
        onClick={handleClose}
        sx={{ marginRight: '12px' }}
      >
        {' '}
        {t('BUTTON.CANCEL')}
      </Button>
      <Button
        variant="contained"
        type="submit"
        onClick={handleActionButtonClick}
        disabled={isSaveDisabled || isActionButtonClicked}
        endIcon={isActionButtonClicked ? <CircularProgress size={20} /> : null}
      >
        {' '}
        {t('BUTTON.SAVE')}
      </Button>
    </Box>
  );
};

export const useEditSubstitution = () => {
  const [wordToSubstitute, setWordToSubstitute] = useState<string>('');
  const [substitutionsToAdd, setSubstitutionsToAdd] = useState<SubstitutionToAdd[]>([]);
  const [isSaveDisabled, setIsSaveDiabled] = useState(true);

  useEffect(() => {
    if (!wordToSubstitute || substitutionsToAdd.length === 0 || substitutionsToAdd.find((any) => any.langCode === '' || any.substituteText === '')) {
      setIsSaveDiabled(true);
      return;
    }
    setIsSaveDiabled(false);
  }, [wordToSubstitute, substitutionsToAdd]);

  const handleAddLanguageClick = () => {
    setSubstitutionsToAdd([...substitutionsToAdd, { langCode: '', substituteText: '' }]);
  };

  

  return {
    wordToSubstitute,
    setWordToSubstitute,
    substitutionsToAdd, 
    setSubstitutionsToAdd,
    handleAddLanguageClick,
    isSaveDisabled
  }
}

const AddSubstitutionModal = (props: AddSubstitutionModalProps) => {
  const { handleClose, handleSaveClick, isOpen } = props;
  const { companyTheme, currentUser } = useAppSelector((state) => state.app);

  const {wordToSubstitute, setWordToSubstitute, substitutionsToAdd, setSubstitutionsToAdd, handleAddLanguageClick, isSaveDisabled} = useEditSubstitution();


  const handleSave = () => {
    if (!companyTheme) return;

    const payload: AddSubstitutionPayload[] = [];
    substitutionsToAdd.forEach((data) => {
      payload.push({
        byDefaultLanguage: companyTheme.language.id.toUpperCase(),
        translatedLanguage: data.langCode.toUpperCase(),
        word: wordToSubstitute,
        substitute: data.substituteText,
        organizationId: currentUser.organizationId,
      });
    });

    handleSaveClick(payload);
  };

  const handleWordToSubstituteChange = (e) => {
    let value: string = e.target.value;
    value = value.substring(0, SUBSTITUTE_WORD_LIMIT);
    setWordToSubstitute(value);
  }

  return (
    <BasicDialog
      onClose={handleClose}
      onOpen={isOpen}
      id="add-substitution"
      fullWidth={true}
      maxWidth={'sm'}
      components={{
        DialogTitle: (
          <DialogHeader
            onClose={handleClose}
            id={'add-substitution-dialog-header'}
            handleAddLanguageClick={handleAddLanguageClick}
            wordToSubstitute={wordToSubstitute}
            handleWordToSubstituteChange={handleWordToSubstituteChange}
            isAddLanguageDisabled={Object.keys(SUPPORTED_LANGUAGES).length === substitutionsToAdd.length}
          />
        ),
      }}
      actions={
        <DialogueActionContainer
          handleClose={handleClose}
          handleSave={handleSave}
          isSaveDisabled={isSaveDisabled}
        />
      }
    >
      <EditSubstitution substitutionsToAdd={substitutionsToAdd} setSubstitutionsToAdd={setSubstitutionsToAdd} />
    </BasicDialog>
  );
};

export default AddSubstitutionModal;

export const EditSubstitution = ({substitutionsToAdd, setSubstitutionsToAdd}) => {
  const {t} = useTranslation();
  const handleChange = (key, value, index) => {
    let data: SubstitutionToAdd[] = JSON.parse(JSON.stringify(substitutionsToAdd));
    data[index] = {
      ...data[index],
      [key]: value.substring(0, SUBSTITUTE_WORD_LIMIT),
    };
    setSubstitutionsToAdd(data);
  };

  const handleRemoveIconClick = (index) => {
    let data: SubstitutionToAdd[] = JSON.parse(JSON.stringify(substitutionsToAdd));
    data.splice(index, 1);
    setSubstitutionsToAdd(data);
  };

  return (
    <Grid
      container
      sx={{
        minHeight: '100px',
        '.MuiGrid-item': {
          ':nth-last-child(2), :nth-last-child(1)': {
            '.MuiInputBase-root': {
              borderBottom: '1px solid #D9D9D9',
            },
          },
        },
      }}
    >
      {!substitutionsToAdd.length && (
        <Grid
          item
          xs={12}
          sx={{ textAlign: 'center', opacity: 0.5, border: 0 }}
        >
          {t('SUBSTITUTION.ADD_MODAL.EMPTY_STATE')}
        </Grid>
      )}
      {substitutionsToAdd.map(({ langCode, substituteText }, index) => (
        <EditSubstitutionRow
          langCode={langCode}
          substituteText={substituteText}
          handleChange={handleChange}
          handleRemoveIconClick={handleRemoveIconClick}
          index={index}
          substitutionsToAdd={substitutionsToAdd}
        />
      ))}
    </Grid>
  );
};
const EditSubstitutionRow = ({langCode, substituteText, handleChange, handleRemoveIconClick, index, substitutionsToAdd}) => {
  const { t } = useTranslation();
  return (
    <>
      <Grid
        item
        xs={4}
      >
        <FormControl fullWidth>
          <Select
            value={langCode}
            onChange={(e) => handleChange('langCode', e.target.value, index)}
            sx={{
              background: '#F9F9FB',
              '& .MuiInputBase-input': {
                opacity: '0.6',
              },
            }}
            input={<CustomInput />}
          >
            {Object.keys(SUPPORTED_LANGUAGES).map((key) => {
              if (substitutionsToAdd.find((any) => any.langCode.toUpperCase() === SUPPORTED_LANGUAGES[key].toUpperCase()) && langCode !== SUPPORTED_LANGUAGES[key]) return null;

              return <MenuItem value={SUPPORTED_LANGUAGES[key]}>{t('LANGUAGE_SIDEBAR.SUPPORTED_LANGUAGES.' + SUPPORTED_LANGUAGES[key].toUpperCase())}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid
        item
        xs={8}
      >
        <CustomInput
          fullWidth={true}
          value={substituteText}
          onChange={(e) => handleChange('substituteText', e.target.value, index)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={() => handleRemoveIconClick(index)}
                edge="end"
              >
                <RemoveCircleOutline />
              </IconButton>
            </InputAdornment>
          }
        />
      </Grid>
    </>
  );
};