export const OrganizationPermissions = {
  CREATE: 'ORGANIZATION.CREATE',
  READ: 'ORGANIZATION.READ',
  UPDATE: 'ORGANIZATION.UPDATE',
  DELETE: 'ORGANIZATION.DELETE',
};

export const UserPermissions = {
  CREATE: 'USER.CREATE',
  READ: 'USER.READ',
  UPDATE: 'USER.UPDATE',
  DELETE: 'USER.DELETE',
};

export const PersonaPermissions = {
  CREATE: 'PERSONA.CREATE',
  READ: 'PERSONA.READ',
  UPDATE: 'PERSONA.UPDATE',
  DELETE: 'PERSONA.DELETE',
};

export const ActionsPermissions = {
  CREATE: 'ACTIONS.CREATE',
  READ: 'ACTIONS.READ',
  UPDATE: 'ACTIONS.UPDATE',
  DELETE: 'ACTIONS.DELETE',
};

export const ResourcesPermissions = {
  CREATE: 'RESOURCES.CREATE',
  READ: 'RESOURCES.READ',
  UPDATE: 'RESOURCES.UPDATE',
  DELETE: 'RESOURCES.DELETE',
};

export const ThemesPermissions = {
  CREATE: 'THEMES.CREATE',
  READ: 'THEMES.READ',
  UPDATE: 'THEMES.UPDATE',
  DELETE: 'THEMES.DELETE',
};

export const WorkInstructionPermissions = {
  CREATE: 'WORK_INSTRUCTION.CREATE',
  READ: 'WORK_INSTRUCTION.READ',
  UPDATE: 'WORK_INSTRUCTION.UPDATE',
  DELETE: 'WORK_INSTRUCTION.DELETE',
};

export const ProcedurePermissions = {
  CREATE: 'PROCEDURE.CREATE',
  READ: 'PROCEDURE.READ',
  UPDATE: 'PROCEDURE.UPDATE',
  DELETE: 'PROCEDURE.DELETE',
};

export const StepsPermissions = {
  CREATE: 'STEPS.CREATE',
  READ: 'STEPS.READ',
  UPDATE: 'STEPS.UPDATE',
  DELETE: 'STEPS.DELETE',
};

export const AssignUsersPermissions = {
  CREATE: 'ASSIGN_USERS.CREATE',
  READ: 'ASSIGN_USERS.READ',
  UPDATE: 'ASSIGN_USERS.UPDATE',
  DELETE: 'ASSIGN_USERS.DELETE',
};

export const PublishPermissions = {
  CREATE: 'PUBLISH.CREATE',
  READ: 'PUBLISH.READ',
  UPDATE: 'PUBLISH.UPDATE',
  DELETE: 'PUBLISH.DELETE',
};

export const StudioPermissions = {
  CREATE: 'STUDIO.CREATE',
  READ: 'STUDIO.READ',
  UPDATE: 'STUDIO.UPDATE',
  DELETE: 'STUDIO.DELETE',
};

export const UserGroupsPermissions = {
  CREATE: 'USERGROUPS.CREATE',
  READ: 'USERGROUPS.READ',
  UPDATE: 'USERGROUPS.UPDATE',
  DELETE: 'USERGROUPS.DELETE',
};

export const CadFilesPermissions = {
  CREATE: 'CAD_FILES.CREATE',
  READ: 'CAD_FILES.READ',
  UPDATE: 'CAD_FILES.UPDATE',
  DELETE: 'CAD_FILES.DELETE',
  UPLOAD: 'CAD_FILES.UPLOAD',
  DOWNLOAD: 'CAD_FILES.DOWNLOAD',
};

export const FilesPermissions = {
  CREATE: 'FILES.CREATE',
  READ: 'FILES.READ',
  UPDATE: 'FILES.UPDATE',
  DELETE: 'FILES.DELETE',
  UPLOAD: 'FILES.UPLOAD',
  DOWNLOAD: 'FILES.DOWNLOAD',
};
