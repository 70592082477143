import { Box, Button, Typography, useTheme } from '@mui/material';
import { environment } from '../../environment/environment';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export const SubscriptionEnd = ({ subscriptionEndDays, isTrainer }) => {
  const [showAlert, setShowAlert] = useState(true);
  const openSubscription = () => {
    var cbInstance = (window as any).Chargebee.init({
      site: environment.AppConfigs.chargebeeSiteName, // your test site
    });
    var chargebeePortalInstance = cbInstance.createChargebeePortal();
    chargebeePortalInstance.open({
      close: () => {
        window.location.reload();
      },
    });
  };

  const theme = useTheme();
  return (
    <>
      {showAlert && (
        <Box component="div" sx={{ backgroundColor: theme.palette.primary.main + '1c', color: '#000C16', fontSize: '14px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '12px' }}>
          <Box component="div">
            <ErrorOutlineIcon fontSize="small" color='primary' />
          </Box>
          <Box component="div">
            {subscriptionEndDays === 0 ? (
              <Box component="span">Heads up: Your trial ends today. </Box>
            ) : (
              <Box component="span">
                Heads up: Your trial ends in {subscriptionEndDays.toString()} {subscriptionEndDays > 1 ? 'days.' : 'day.'}{' '}
              </Box>
            )}
            {isTrainer && (
              <>
                <Button sx={{ padding: 0, color: '#000C16', textDecoration: 'underline', minWidth: 0, margin: '0px 8px' }} onClick={() => openSubscription()}>
                  Upgrade now{' '}
                </Button>
                to keep your account active.
              </>
            )}
            {!isTrainer && 'Please contact your Administrator.'}
          </Box>
          <Box component="div">
            <Button
              onClick={() => {
                setShowAlert(false);
              }}
              sx={{ marginLeft: 'auto', minWidth: 0 }}
            >
              <CloseIcon fontSize="small" />
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};
