import { Card, Box, CardContent, Typography, Tooltip, FormControlLabel, IconButton, Menu, MenuItem, Skeleton, Switch } from '@mui/material';
import React, { useState } from 'react';
import { UserGroup } from '../../entities/User/MockUser';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import './UserGroupCard.scss';
import { StyledCard } from '../../components/Styled/StyledCard';
import { StyledSwitch } from '../../components/Styled/StyledSwitch';
import { useTranslation } from 'react-i18next';

interface UserGroupCardProps {
  group: any;
  onEdit: (groupId: String) => void;
  onDelete: (group: any) => void;
  canUpdate: boolean;
  canDelete: boolean;
  onStatusChange: (flag: boolean, userGroup: any) => Promise<boolean>;
  isLoading: boolean;
}

const UserGroupCard: React.FC<UserGroupCardProps> = (props) => {
  const shortenDescription = (text) => {
    return text && text.length > 55 ? text.substring(0, 55) + '...' : text;
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [loading, setLoading] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    props.onEdit(props.group.id);
  };
  const handleDelete = () => {
    props.onDelete(props.group);
  };

  const handleStatusChange = async (flag: boolean, data: any) => {
    await props.onStatusChange(flag, data);
  };

  const { t } = useTranslation();

  return (
    <StyledCard minCardHeight={'220px'}>
      <Box component="div" sx={{ display: 'flex', flexDirection: 'column', p: 2, width: '100%' }}>
        <CardContent sx={{ flex: '1 0 auto', textAlign: 'left', pt: 0, pb: 0 }}>
          <Typography component="div" variant="h5" className="group-text">
            {props.group.name}
          </Typography>
          <Typography variant="subtitle2" color="text.secondary" component="div" className="group-text">
            {props.group.users ? props.group.users.length : 0}
          </Typography>
          <Tooltip title={props.group.description} placement="bottom-start">
            <Typography variant="subtitle2" color="text.secondary" component="div" className="group-text">
              {shortenDescription(props.group.description)}
            </Typography>
          </Tooltip>
        </CardContent>
        <Box
          component="div"
          sx={{
            display: 'flex',
            alignItems: 'center',
            pl: 1,
            pr: 1,
            width: '100%',
          }}
        >
          <Box
            component="div"
            sx={{
              display: 'flex',
              alignItems: 'center',
              pl: 1,
              justifyContent: 'flex-start',
              width: '100%',
            }}
          >
            {!props.isLoading ? <FormControlLabel className="active" disabled={!props.canUpdate} control={<StyledSwitch defaultChecked={props.group.isActive} color={props.group.isActive ? 'primary' : 'error'} onChange={(e) => handleStatusChange(e.target.checked, props.group)} />} label={props.group.isActive ? t('USER.ACTIVE') : t('USER.INACTIVE')} /> : <Skeleton sx={{ height: '32px', width: '50%' }} />}
          </Box>
          <Box
            component="div"
            sx={{
              display: 'flex',
              alignItems: 'center',
              pr: 2,
              pb: 1,
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <IconButton
              size="small"
              onClick={handleClick}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              sx={{
                borderRadius: '5',
                border: '1px solid gray',
                p: 0,
                color: 'black',
              }}
            >
              <MoreHorizIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              {props.canUpdate && <MenuItem onClick={handleEdit}>{t('COMMON.EDIT')}</MenuItem>}
              {props.canDelete && <MenuItem onClick={handleDelete}>{t('COMMON.DELETE')}</MenuItem>}
            </Menu>
          </Box>
        </Box>
      </Box>
    </StyledCard>
  );
};

export default UserGroupCard;
