import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ProcedureInfoProps {
  name: string;
  tasks_count?: number;
  version?: string;
  completedCount?: number;
  notStartedCount?: number;
  inProgressCount?: number;
  workInstruction: any;
}

const ProcedureInfo: React.FC<ProcedureInfoProps> = ({ name, workInstruction, tasks_count, version, completedCount, notStartedCount, inProgressCount }) => {
  const { t } = useTranslation();
  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <Typography sx={{ fontSize: '16px', padding: '8px 0px' }}>WI: {workInstruction?.workInstructionName}</Typography>
      <Box component="div" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: '8px' }}>
        <Typography sx={{ fontSize: '24px' }}>{name}</Typography>
      </Box>
      <Box component="div" className="procedure-info" sx={{ display: 'flex' }}>
        <Box component="span">{tasks_count} Steps</Box>
        <Box component="span">
          {t('PROCEDURE.CARD_VERSION')} {version}
        </Box>
      </Box>
    </Box>
  );
};

export default ProcedureInfo;
