import { Box, CardContent, Typography, Chip, Stack, Tooltip, FormControlLabel, IconButton, Menu, MenuItem, Skeleton } from '@mui/material';
import React, { useState } from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { StyledCard } from '../../../components/Styled/StyledCard';
import { StyledSwitch } from '../../../components/Styled/StyledSwitch';
import { useTranslation } from 'react-i18next';
import './IntegrationForm.scss';
import TrainerService from '../../../services/trainer/TrainerService';
import { Toast } from '../../../components/Notifications/Notification';

const IntegrationCard = (props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [loading, setLoading] = useState(false);

  const [active, setActive] = useState(props.integration.inactive ? false : true);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleEdit = () => {
    props.onEdit(props.integration.integrationId, props.integration);
  };
  const handleDelete = () => {
    props.onDelete(props.integration.integrationId);
  };

  const handleStatusChange = async (checked, id) => {
    setLoading(true);
    setActive(!active);
    TrainerService.activateIntegrationSystem(checked, id)
      .then(async (e) => {
        setLoading(false);
        Toast.success(t('OPERATION_SUCCESS', { ns: 'success' }), t(e.data.messageCode, { ns: 'success' }) || e.data.message);
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        console.error('[ThemeSupportSection -> handleSave]', response);
        setLoading(false);
      });
  };
  const { t } = useTranslation();

  return (
    <StyledCard minCardHeight={'250px'} sx={{ boxShadow: 'rgba(149, 157, 165, 0.5) 0px 8px 24px !important', height: '250px', display: 'flex', flexDirection: 'column' }}>
      <Stack sx={{ flexDirection: { xs: 'column', md: 'row', padding: '24px 24px 0px 32px' }, justifyContent: 'start' }} flexWrap={'wrap'}>
        <Chip sx={{ height: 23 }} label={props.clientId ? t('BUTTON.CONNECTION_AVAILABLE') : t('BUTTON.CONNECTION_REQUEST_IS_PENDING')} variant="outlined" className={props.clientId ? 'completed' : 'in-progress'} />
      </Stack>

      <Box component="div" sx={{ display: 'flex', flexDirection: 'column', p: 2, flexGrow: 1 }}>
        <CardContent sx={{ flex: '1 0 auto', textAlign: 'left', pt: 0, pb: 0 }}>
          <Typography component="div" variant="h6" className="integration-text">
            {props.integration.name}
          </Typography>

          <Typography variant="subtitle2" color="text.secondary" component="div" className="integration-text" sx={{ marginTop: '18px', overflowY: 'scroll', height: '60px' }}>
            {t('BUTTON.THIS_CONNECTOR_PUBLISHES_TO')} {props.integration.applicationUrl}
          </Typography>
        </CardContent>
        {/* <IntegrationCard/> */}
        <Box
          component="div"
          sx={{
            display: 'flex',
            alignItems: 'center',
            pl: 1,
            pr: 1,
            // width: '100%',
          }}
        >
          <Box
            component="div"
            sx={{
              display: 'flex',
              alignItems: 'center',
              pl: 2,
              justifyContent: 'flex-start',
              width: '100%',
            }}
          >
            {!loading ? <FormControlLabel className="active" control={<StyledSwitch name="isActive" checked={active} color={!active ? 'error' : 'primary'} onChange={(e) => handleStatusChange(e.target.checked, props.integration.integrationId)} />} label={!active ? t('USER.INACTIVE') : t('USER.ACTIVE')} /> : <Skeleton sx={{ height: '32px', width: '50%' }} />}
          </Box>
          <Box
            component="div"
            sx={{
              display: 'flex',
              alignItems: 'center',
              //pr: 2,
              pb: 1,
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <IconButton
              size="small"
              onClick={handleClick}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              sx={{
                borderRadius: '5',
                border: '1px solid gray',
                p: 0,
                color: 'black',
              }}
            >
              <MoreHorizIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              {<MenuItem onClick={handleEdit}>{t('COMMON.EDIT')}</MenuItem>}
              {<MenuItem onClick={handleDelete}>{t('COMMON.DELETE')}</MenuItem>}
            </Menu>
          </Box>
        </Box>
      </Box>
    </StyledCard>
  );
};

export default IntegrationCard;
