import React, { useRef, useState } from 'react';
import { IWorkInstructions } from '../entities/Trainee/MockTrainee';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import * as HomePageAction from '../redux/features/HomePage/HomePage.slice';
import FileManagerService from '../services/FileManager/FileManagerService';
import TrainerService from '../services/trainer/TrainerService';
import { Toast } from '../components/Notifications/Notification';
import { useTranslation } from 'react-i18next';
import { CanceledError } from 'axios';

const useLoadEntities = (props: any = {}) => {
  const previousController = useRef<any>(null);
  const dispatch = useAppDispatch();
  const { folders, workInstructions } = useAppSelector((state) => state.homePage);
  const [hasNext, setHasNext] = useState<boolean>(true);

  const [totalWorkInstructionsLength, setTotalWorkInstructionsLength] = useState(0);
  const [totalFolderWorkInstructionLength,setTotalFolderWorkInstructionLength]= useState(0);
  const [currPage, setCurrPage] = useState(1);
  const [imageObject, setImageObject] = useState<Object>({});
  const [parentFolderId, setParentFolderId] = React.useState<String>('');
  const [loading, setLoading] = useState(false);

  const setFolders = (data) => {
    dispatch(HomePageAction.setFolders(data));
  };
  const setWorkInstructions = (data) => {
    dispatch(HomePageAction.setWorkInstructions(data));
  };
  const { t } = useTranslation();
  const loadData = (folderId: String, search = '', sort = '', filter = '', page = 1, pageLimit = 20) => {
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;

    setLoading(true);
    
    TrainerService.folderWiContent(folderId, page, pageLimit, search, sort, filter, signal)
      .then((e) => {
        setTotalFolderWorkInstructionLength(e?.data?.data?.total)
        setTotalWorkInstructionsLength(e?.data?.data?.workInstructionCount);
        setHasNext(e?.data?.data?.hasNext);
        e?.data?.data?.workinstructions?.map((wi) => {
        
          if (wi?.is2d === false) {
            FileManagerService.getWorkInstructionThumbnail(localStorage.getItem('organizationId') || '', wi.workInstructionId)
              .then((res) => {
                imageObject[wi.workInstructionId] = (res as any).data[0].id;
                setImageObject({ ...imageObject });
              })

              .catch((e) => {
                imageObject[wi.workInstructionId] = '';
              });
          }

          if (wi?.is2d === true) {
            FileManagerService.getWorkInstructionThumbnail2d(localStorage.getItem('organizationId') || '', wi.workInstructionId)
              .then((res) => {
                imageObject[wi.workInstructionId] = (res as any).data[0].id;
                setImageObject({ ...imageObject });
              })
              .catch((e) => {
                imageObject[wi.workInstructionId] = '';
              });
          }
        });

        if (workInstructions && folders && page > 1) {
          setWorkInstructions([...workInstructions, ...(e?.data?.data?.workinstructions as IWorkInstructions[])]);
          setFolders([...folders, ...(e?.data?.data?.folders as any)]);
        } else {
          setWorkInstructions(e?.data?.data?.workinstructions as IWorkInstructions[]);
          setFolders(e?.data?.data?.folders as any);
        }

        setCurrPage(page + 1);
        setParentFolderId(folderId);
      })
      .catch((error) => {
        if(error instanceof CanceledError) return;
        const {response} = error;
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response?.data?.messageCode, { ns: 'error' }) || response?.data?.errorMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  };



  return {
    loadData,
    totalWorkInstructionsLength,
    currPage,
    parentFolderId,
    loading,
    setCurrPage,
    setParentFolderId,
    hasNext,
    imageObject,
    totalFolderWorkInstructionLength
  };
};

export default useLoadEntities;
