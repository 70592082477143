import { useEffect, useState, useCallback } from 'react';
import { List, Typography, Box, Button, useTheme, Grid, Divider } from '@mui/material';
import { ArrowDownIcon, ArrowRightCommentIcon, ReplyCommentIcon } from '../../../Icons/CdsIcons';
import { useTranslation } from 'react-i18next';
import { IProcedures, IWorkInstructions } from '../../../../entities/Trainee/MockTrainee';
import { Toast } from '../../../Notifications/Notification';
import TutorialService from '../../../../services/trainee/TraineeService';
import { getFormattedTimeFromUnixTime } from '../../../../shared/utils/formateDate';
import Avatar from './Avatar';
import CommentCrud from './CommentCrud';
import { CommentFilePreview } from './CommonFilePreview';
import { AddCommentToExistingThread } from './AddCommentToExistingThread';

interface Props {
  index?: number;
  thread?: any;
  traineesData?: any;
  showRightPaneComment?: boolean;
  setShowRightPaneComment?: any;
  activeThreadId?: string;
  setActiveThreadId?: any;
  procedure?: IProcedures | null;
  workInstruction?: IWorkInstructions | null;
  isTrainer?: boolean;
  stepIndex?: number;
  notification?: any;
  stepCommentThreads?: any;
  setEditComment?: any;
  listAllThreads?: any;
}

export const ThreadComment = (props: Props) => {
  const [commentCountOnThread, setCommentCountOnThread] = useState<number>(0);
  const [showMoreCommentOnThread, setShowMoreCommentOnThread] = useState<boolean>(false);
  const [replyCommentHandler, setReplyCommentHandler] = useState<boolean>(false);
  const [isButtonFirstBelow, setIsButtonFirstBelow] = useState(false);
  const [editComment, setEditComment] = useState<boolean>(false);
  const theme = useTheme();
  const { t } = useTranslation();

  const listStepCommentThread = useCallback(
    (threadId: string) => {
      TutorialService.listStepComments(threadId)
        .then((e) => {
          setCommentCountOnThread(e?.data?.data?.comments?.slice(0, -1).length);
        })
        .catch(({ response }) => {
          Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response?.data?.messageCode, { ns: 'error' }) || response?.data?.errorMessage);
        });
    },
    [t],
  );

  useEffect(() => {
    if (props?.thread?._id) {
      listStepCommentThread(props?.thread?._id);
    }
  }, [props?.thread?._id, showMoreCommentOnThread, listStepCommentThread]);

  const showCommentOnThreadHandler = useCallback(() => {
    setShowMoreCommentOnThread((prev) => !prev);
  }, []);

  const showCommentonThreadReplyHandler = useCallback(() => {
    setIsButtonFirstBelow(true);
    setReplyCommentHandler((prev) => !prev);
  }, []);

  return (
    <>
      <List
        key={props.index}
        sx={{ padding: commentCountOnThread > 0 ? null : '0px 0px 0px 24px' }}
      >
        <Grid sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <Grid>
            {commentCountOnThread > 0 && (
              <Button
                sx={{
                  color: `${theme.palette.primary.main}`,
                  fontSize: '14px',
                  padding: 0,
                  pl: '10px',
                  minWidth: 'auto',
                }}
                onClick={showCommentOnThreadHandler}
              >
                {showMoreCommentOnThread ? <ArrowDownIcon color={theme.palette.primary.main} /> : <ArrowRightCommentIcon />}
              </Button>
            )}
          </Grid>

          <Grid>
            <Avatar
              firstName={props.thread?.firstComment?.userFirstName}
              lastName={props.thread?.firstComment?.userLastName}
              isRead={props.thread?.unreadCount === 0}
              color={theme.palette.primary.main}
              role="trainer"
              sx={{ width: '24px', height: '24px', borderRadius: '18px', marginRight: '4px', marginLeft: '6px' }}
            />
          </Grid>

          <Grid sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Grid sx={{ justifyContent: 'left' }}>
              <Typography sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left', marginLeft: '0.5em', fontSize: '14px', color: '#12181F' }}>{`${props.thread?.firstComment?.userFirstName} ${props.thread?.firstComment?.userLastName}`}</Typography>
              <Typography sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left', marginLeft: '0.5em', fontWeight: '400', fontSize: '12px', color: '#12181F66' }}>{`${getFormattedTimeFromUnixTime(props.thread?.firstComment?.createdOn)}`}</Typography>
            </Grid>
            <Grid>
              <Button
                sx={{
                  color: `${theme.palette.primary.main}`,
                  minWidth: 'auto',
                  marginRight: '8px',
                }}
                onClick={showCommentonThreadReplyHandler}
              >
                <Typography sx={{ fontSize: '14px', paddingRight: '24px', position: 'relative' }}>{!replyCommentHandler ? <ReplyCommentIcon /> : null}</Typography>
              </Button>

              <CommentCrud
                  listAllThreads={props.listAllThreads}
                comment={props.thread?.firstComment}
                threadId={props?.thread?._id}
                listStepCommentThread={listStepCommentThread}
                editComment={editComment}
                setEditComment={props.setEditComment}
              />
            </Grid>
          </Grid>
        </Grid>

        <Typography
          sx={{
            ml: commentCountOnThread > 0 ? '30px' : '6px',
            marginTop: '16px',
            width: '376px',
            fontWeight: '400',
            fontSize: '14px',
            color: '#12181F99',
          }}
        >
          {props.thread?.firstComment?.comment}
        </Typography>
      </List>

      <Box
        component={'div'}
        sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', pl: '24px' }}
      >
        {props.thread?.firstComment?.files?.map((file, index) => (
          <CommentFilePreview
            file={file}
            key={index}
          />
        ))}
      </Box>

      <AddCommentToExistingThread
      key={props.index}
        threadId={props.thread._id}
        procedure={props.procedure}
        showMoreCommentOnThread={showMoreCommentOnThread}
        replyCommentHandler={replyCommentHandler}
        showCommentonThreadReplyHandler={showCommentonThreadReplyHandler}
        setCommentCountOnThread={setCommentCountOnThread}
        
      />

      <Divider sx={{ margin: '16px' }} />
    </>
  );
};
