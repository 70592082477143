import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { getTemplateProcedures } from '../../../services/ModularProcedure/ModularProcedure';
import { IProcedure, IWorkInstruction } from '../../../entities/Analytics/AnalyticsProcedure';
import { PROCEDURE_LIBRARY_DIALOG } from '../../../pages/Trainer/ProcedureLibrary/constants';
import TutorialService from '../../../services/trainee/TraineeService';
import { IProcedures } from '../../../entities/Trainee/MockTrainee';
import { FilterOption } from '../../../components/Dialog/mobileDialog/filterDialog/filterDialog';
import { Filterable, Pageable, Paginated, Searchable, Sortable } from '../../../shared/interfaces';
interface TemplateProcedureSearchPayload extends Pageable, Searchable<'procedureName', string>, Sortable<'createdOn' | 'procedureName'>, Filterable<'tags', string[]> { 
}

type TemplateProcedureTag = {
  _id: string,
  tagId: string,
  name: string,
  organizationId: string
}

type InitialState = {
  templateProcedures: {
    loading: boolean;
    data: Paginated<IProcedures[]>;
    error: string;
  };
  workInstruction: {
    loading: boolean;
    data: IWorkInstruction | null;
    error: string;
  };
  procedureLibrary: {
    selectedProcedure: string;
    dialogueToOpen: PROCEDURE_LIBRARY_DIALOG | '';
    searchPayload: TemplateProcedureSearchPayload | null;
    availableTags: TemplateProcedureTag[],
    toolbar: {
      selectedFilters: any
    }
  };
};
const templateProcedureInitState = {
  totalDocuments: 0,
  totalPages: 0,
  currentPage: 1,
  pageSize: 20,
  data: [],
};
const initialState: InitialState = {
  templateProcedures: {
    loading: false,
    data: structuredClone(templateProcedureInitState),
    error: '',
  },
  workInstruction: {
    loading: false,
    data: null,
    error: '',
  },
  procedureLibrary: {
    selectedProcedure: '',
    dialogueToOpen: '',
    searchPayload: null,
    availableTags: [],
    toolbar: {
      selectedFilters: [],
    }
  },
};

// type TemplateProcedureSearchPayload = {
//   currPage?: number;
//   pageSize?: number;
//   procedureName?: string;
//   sortBy?: 'createOn' | 'procedureName';
//   sortOrder?: 'asc' | 'desc';
//   tags?: string[];
// };



const getQueryFromSearchPayload = (searchPayload) => {
  let query = '';
  if (!searchPayload) return query;

  if (searchPayload.currPage !== undefined) {
    query += `page=${searchPayload.currPage}&`;
  }

  if (searchPayload.pageSize !== undefined) {
    query += `pageSize=${searchPayload.pageSize}&`;
  }

  if (searchPayload.procedureName) {
    query += `procedureName=${encodeURIComponent(searchPayload.procedureName)}&`;
  }

  if (searchPayload.sortBy) {
    query += `sortBy=${searchPayload.sortBy}&`;
  } else {
    query += `sortBy=modified_at&`;
  }

  if (searchPayload.sortOrder) {
    query += `sortOrder=${searchPayload.sortOrder}&`;
  } else {
    query += `sortOrder=desc&`;
  }

  if (searchPayload.tags && searchPayload.tags.length > 0) {
    searchPayload.tags.forEach((tag) => {
      query += `tags[]=${encodeURIComponent(tag)}&`;
    });
  }

  if (query.endsWith('&')) {
    query = query.slice(0, -1);
  }

  return query;
};

export const fetchTemplateProcedures = createAsyncThunk('modularProcedure/fetchTemplateProcedures', (searchPayload: TemplateProcedureSearchPayload | null) => {
  const payload = Object.assign({}, searchPayload);;
  
  if (!searchPayload) {
    searchPayload = {};
  }
  if (!searchPayload.pageSize) {
    payload.pageSize = 20;
  }

  const queryString = getQueryFromSearchPayload(payload);
  return getTemplateProcedures(queryString).then((response) => response.data.data);
});

export const fetchWorkInstruction = createAsyncThunk('modularProcedure/fetchWorkInstruction', (workInstructionId: string) => {
  return TutorialService.getWorkInstructionsById(workInstructionId).then((response) => response.data.data);
});
const modularProcedureSlice = createSlice({
  name: 'modularProcedure',
  initialState: initialState,
  reducers: {
    setProcedureToView: (state, action) => {
      state.procedureLibrary.selectedProcedure = action.payload;
    },
    setDialogueToOpen: (state, action) => {
      state.procedureLibrary.dialogueToOpen = action.payload;
    },
    setSearchPayload: (state, action) => {
      state.procedureLibrary.searchPayload = action.payload;
    },
    setAvailableTagAction: (state, action) => {      
      state.procedureLibrary.availableTags = action.payload;
    },
    setSelectedFiltersAction: (state, action) => {
      state.procedureLibrary.toolbar.selectedFilters = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTemplateProcedures.pending, (state) => {
      // state.templateProcedures.loading = true;
    });
    builder.addCase(fetchTemplateProcedures.fulfilled, (state, action: PayloadAction<Paginated<IProcedures[]>>) => {
      state.templateProcedures.loading = false;
      if(action.payload.currentPage > 1){
        const existingData = [...state.templateProcedures.data.data];
        state.templateProcedures.data = action.payload;
        state.templateProcedures.data.data = [...existingData, ...action.payload.data];
      } else {
        state.templateProcedures.data = action.payload;
      }
      state.templateProcedures.error = '';
    });
    builder.addCase(fetchTemplateProcedures.rejected, (state, action) => {
      state.templateProcedures.loading = false;
      state.templateProcedures.data = structuredClone(templateProcedureInitState);
      state.templateProcedures.error = action.error.message || 'Something Went Wrong';
    });
    builder.addCase(fetchWorkInstruction.pending, (state) => {
      state.workInstruction.loading = true;
    });
    builder.addCase(fetchWorkInstruction.fulfilled, (state, action: PayloadAction<any>) => {
      state.workInstruction.loading = false;
      state.workInstruction.data = action.payload;
      state.workInstruction.error = '';
    });
    builder.addCase(fetchWorkInstruction.rejected, (state, action) => {
      state.workInstruction.loading = false;
      state.workInstruction.data = null;
      state.workInstruction.error = action.error.message || 'Something Went Wrong';
    });
  },
});

export default modularProcedureSlice.reducer;
export const { setProcedureToView, setDialogueToOpen, setSearchPayload, setAvailableTagAction, setSelectedFiltersAction } = modularProcedureSlice.actions;
