import React from 'react';
import { createContext, Dispatch } from 'react';

export interface IRbacUser {
  id: string;
  name: string;
  organizationId: string;
  organizationName: string;
  userId: string;
  scopes: {
    [key: string]: string[];
  };
  userFullName?: string;
  userScopes?: any;
  userFirstName?: string;
  email?: string;
}

const user: IRbacUser = {
  id: '',
  name: '',
  organizationId: '',
  organizationName: '',
  userId: '',
  scopes: {},
};

const refreshProcedure: boolean = false;

const userFn: Dispatch<React.SetStateAction<IRbacUser>> = () => {};
const refreshProcedureFn: Dispatch<React.SetStateAction<boolean>> = () => {};

const UserContext = createContext({
  currentUser: user,
  setCurrentUser: userFn,
  refreshProcedure: refreshProcedure,
  setRefreshProcedure: refreshProcedureFn,
});

export default UserContext;
