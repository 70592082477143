import { BorderAll, ViewList, Visibility } from '@mui/icons-material';
import { Box, Divider, IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, ToggleButton, ToggleButtonGroup, Button, Typography, useTheme, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { PartsList } from '../../../../../entities/Trainee/MockTrainee';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import './PartsPopOver.scss';
import { Puzzle, ResizeIcon } from '../../../../Icons/CdsIcons';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useCanvasState } from '../../../../../state/canvasState';

interface Props {
  close?: any;
  handlePartsClick: (e?: any, parts?: any) => any;
}

const PartsPopOver: React.FC<Props> = (props) => {
  const canvasState = useCanvasState();
  const [infiniteList, setInfiniteList] = useState<PartsList[] | null>(null);
  const [eyeIcon, setEyeIcon] = useState({});
  const [paperWidth, setPaperWidth] = React.useState<any>(300);
  const [paperHeight, setPaperHeight] = React.useState<any>(window.innerWidth < 899 ? 200 : 400);

  const theme = useTheme();

  let initialX, initialY, deltaX, deltaY;

  const handleTouchDown = (e) => {
    initialX = e.touches[0].clientX;
    initialY = e.touches[0].clientY;
    const handleMouseMove = (e) => {
      deltaX = e.touches[0].clientX - initialX;
      deltaY = e.touches[0].clientY - initialY;
      setPaperHeight(paperHeight - deltaY);
      setPaperWidth(paperWidth + deltaX);
    };
    const handleMouseUp = () => {
      document.removeEventListener('touchmove', handleMouseMove);
      document.removeEventListener('touchend', handleMouseUp);
    };

    document.addEventListener('touchmove', handleMouseMove, { passive: false });
    document.addEventListener('touchend', handleMouseUp, { passive: false });
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    initialX = e.screenX;
    initialY = e.screenY;
    const handleMouseMove = (e) => {
      deltaX = e.screenX - initialX;
      deltaY = e.screenY - initialY;
      setPaperHeight(paperHeight - deltaY);
      setPaperWidth(paperWidth + deltaX);
    };
    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  useEffect(() => {
    if (canvasState.partList) {
      var filteredParts = canvasState.partList.filter((part) => part.partName !== null && part.partName !== undefined);
      setInfiniteList(filteredParts);
      const showEye = {};
      filteredParts.map((filteredPart) => {
        showEye[filteredPart.meshUid] = true;
        return filteredPart;
      });
      setEyeIcon(showEye);
    }
  }, [canvasState.partList]);

  const getElementClicked = (meshUid) => {
    for (const key in eyeIcon) {
      eyeIcon[key] = true;
    }
    eyeIcon[meshUid] = false;
    setEyeIcon(eyeIcon);
  };

  const RenderRow = (child) => {
    const { index } = child;
    return (
      <ListItem key={index} component="div" onClick={() => getElementClicked(infiniteList && infiniteList[index].meshUid)}>
        {infiniteList && (
          <Grid container>
            <Grid item md={8} xs={8} alignItems="center">
              <Grid container alignItems="center">
                <Box
                  component="div"
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                  }}
                >
                  <Button sx={{ minWidth: 0, padding: 0, margin: '0 10px 0 0' }} className="list-item-puzzle-icon">
                    <Puzzle />
                  </Button>
                  <Typography
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                    className="list-item-text"
                  >
                    {infiniteList[index].partName ? infiniteList[index].partName : ''}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid item md={4} xs={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                onClick={() => {
                  // props.partSelected(infiniteList[index].mesh);
                }}
                sx={{ minWidth: 0, padding: 0, margin: 0 }}
                className="list-item-see-icon"
              >
                {eyeIcon[infiniteList[index].meshUid] && <Visibility color="primary" />}
                {!eyeIcon[infiniteList[index].meshUid] && (
                  <VisibilityOffOutlinedIcon
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      color: 'white',
                      borderRadius: '.5rem',
                    }}
                  />
                )}
              </Button>
            </Grid>
          </Grid>
        )}
      </ListItem>
    );
  };

  return (
    <Paper className="parts-container" sx={{ boxShadow: 'rgba(149, 157, 165, 0.5) 4px 8px 24px', height: paperHeight + 'px', width: paperWidth + 'px' }}>
      <Box
        component="div"
        onTouchStart={(e) => {
          handleTouchDown(e);
        }}
        onMouseDown={(e) => handleMouseDown(e)}
        sx={{ position: 'absolute', right: '12px', top: '12px', color: theme.palette.primary.main, cursor: 'grab', width: 'fit-content' }}
      >
        <ResizeIcon />
      </Box>
      <Box component="div" className="parts-header">
        <Typography variant="button" component="div" className="parts-title">
          Parts <span className="text-box">{infiniteList && infiniteList.length}</span>
        </Typography>
      </Box>
      <Box component="div" id="infinite-list" className={'parts-content-container select-part-menu'}>
        {infiniteList && (
          <>
            {infiniteList.map((item, index) => (
              <RenderRow key={index} index={index} />
            ))}
          </>
        )}
      </Box>
      <Box component="div" sx={{ display: { xs: 'flex', md: 'flex' } }} className="show-all-steps-btn">
        <Button
          sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}
          variant="text"
          onClick={(e) => {
            if (props.handlePartsClick) props.handlePartsClick(e, null);
          }}
        >
          Back to current step
        </Button>
      </Box>
    </Paper>
  );
};

export default PartsPopOver;
