import { Search } from '@mui/icons-material';
import { Box, Button, FormControl, FormControlLabel, Grid, InputAdornment, MenuItem, Paper, Select, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, ToggleButton, ToggleButtonGroup, Typography, useTheme } from '@mui/material';
import BorderAllRoundedIcon from '@mui/icons-material/BorderAllRounded';
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import AddIcon from '@mui/icons-material/Add';
import React, { useEffect, useState } from 'react';
import './PersonaList.scss';
import FuzzySearch from 'fuzzy-search';
import { useNavigate } from 'react-router-dom';
import PersonaCard from './PersonaCard';
import { StyledAvatarRecordCount } from '../../components/Styled/StyledAvatarRecordCount';
import { IPersona } from '../../entities/Organization/Persona/Persona';
import { PersonaPermissions } from '../../shared/permissions';
import { useHasPermissions } from '../../hooks/useHasPermissions';
import PersonaService from '../../services/Persona/PersonaService';
import { Toast } from '../../components/Notifications/Notification';
import { StyledSwitch } from '../../components/Styled/StyledSwitch';
import { ActionMenu } from '../../components/ActionMenu/ActionMenu';
import { StyledGridView } from '../../components/Styled/StyledGridView';
import Loading from '../../components/Loading/Loading';
import { ConfirmationDialog } from '../../components/Dialog/ConfirmationDialog';
import { getMessage } from '../../shared/utils/message-helpers';
import { Access } from '../../components/Auth/Access';
import { useTranslation } from 'react-i18next';

interface PersonaListProps {}

const PersonaList: React.FC<PersonaListProps> = () => {
  const [loading, setLoading] = useState(true);
  const [statusUpdating, setStatusUpdating] = useState({});
  const [layout, setLayout] = useState<string | null>('card');
  const [sort, setSort] = useState<string>('name');
  const [filter, setFilter] = useState<string>('');
  const [personas, setPersonas] = useState<IPersona[]>([]);
  const [filteredPersonas, setFilteredPersonas] = useState<IPersona[]>([]);
  const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] = useState(false);
  const [idToDelete, setIdToDelete] = useState('');
  const canUpdate = useHasPermissions(PersonaPermissions.UPDATE);
  const canDelete = useHasPermissions(PersonaPermissions.DELETE);
  let navigate = useNavigate();
  const theme = useTheme();

  const { t } = useTranslation();

  // Initialize
  useEffect(() => {
    getAllPersonasData();
  }, []);

  const handleLayout = (event: React.MouseEvent<HTMLElement>, newLayout: string | null) => {
    if (newLayout !== null) {
      setLayout(newLayout);
    }
  };

  const getAllPersonasData = () => {
    PersonaService.getAllPersonas()
      .then((e) => {
        const {
          data: { data: personaList },
        } = e;
        setPersonas(personaList);
        setFilteredPersonas(personaList);
        setLoading(false);
        const statusUpdateData = {};
        personaList.map((o) => {
          statusUpdateData[o.id] = false;
          return o;
        });
        setStatusUpdating(statusUpdateData);
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        setLoading(false);
      });
  };

  const sortBy = (key: string) => {
    if (personas) {
      personas.sort((a, b) => a[key].localeCompare(b[key]));
      setPersonas(personas);
    }
  };

  const search = (key: string) => {
    if (personas) {
      const searcher = new FuzzySearch(personas, ['name', 'identifier'], {
        caseSensitive: false,
      });
      const result = searcher.search(key);
      result.length > 0 ? setFilteredPersonas(result) : setFilteredPersonas(personas);
    }
  };

  const handleAdd = () => {
    navigate('/persona/add');
  };

  const handleEdit = (id) => {
    navigate('/persona/edit/' + id);
  };

  const handleDelete = (id) => {
    setIdToDelete(id);
    setOpenDeleteConfirmationDialog(true);
  };

  const handleStatusChange = async (flag: boolean, record: IPersona): Promise<boolean> => {
    const payload = { ...record, isActive: flag };
    let statusUpdateDataChange = { ...statusUpdating };
    statusUpdateDataChange[record.id] = true;
    setStatusUpdating(statusUpdateDataChange);
    return PersonaService.updatePersona(payload)
      .then((e) => {
        record.isActive = flag;
        setPersonas([...personas]);
        Toast.success(t('OPERATION_SUCCESS', { ns: 'success' }), t(e.data.messageCode, { ns: 'success' }) || e.data.message);
        statusUpdateDataChange[record.id] = false;
        setStatusUpdating(statusUpdateDataChange);
        return Promise.resolve(true);
      })
      .catch((e) => {
        statusUpdateDataChange = { ...statusUpdating };
        statusUpdateDataChange[record.id] = false;
        setStatusUpdating(statusUpdateDataChange);
        // setStatusUpdating((state) => state[record.id] = false);
        const {
          response: {
            data: { errorMessage },
          },
        } = e;
        if (errorMessage) {
          Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(e.response.data.messageCode, { ns: 'error' }) || e.response.data.errorMessage);
        } else {
          Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(e.response.data.messageCode, { ns: 'error' }) || e.response.data.errorMessage);
        }
        console.error('PersonaList -> handleStatusChange', e);
        return Promise.resolve(false);
      });
  };

  const handleDeleteClose = (value?: string) => {
    setOpenDeleteConfirmationDialog(false);
    if (value && value === idToDelete) {
      PersonaService.deletePersona(value)
        .then((e) => {
          Toast.success(t('OPERATION_SUCCESS', { ns: 'success' }), t(e.data.messageCode, { ns: 'success' }) || e.data.message);
        })
        .catch((error) => {
          const {
            response: {
              data: { errorMessage },
            },
          } = error;
          if (errorMessage) {
            Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(error.response.data.messageCode, { ns: 'error' }) || error.response.data.errorMessage);
          } else {
            Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(error.response.data.messageCode, { ns: 'error' }) || error.response.data.errorMessage);
          }
        });
    }
  };

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <div className="persona-list-container">
          <Paper elevation={0} className="persona-title-container" style={{ marginTop: '1rem' }}>
            <Box component="div" className="left">
              <Typography variant="h6" component="div" className="page-title">
                Persona
              </Typography>
              {personas && <StyledAvatarRecordCount>{personas.length}</StyledAvatarRecordCount>}
            </Box>
            <Access permission={PersonaPermissions.CREATE}>
              <Box component="div" className="right">
                <Button className="activeButton nonActiveButton" variant="contained" disabled={true} onClick={handleAdd}>
                  <Box component={'div'} sx={{ display: { xs: 'none', md: 'flex' } }}>
                    Create New
                  </Box>
                  <Box component={'div'} sx={{ display: { xs: 'flex', md: 'none' } }}>
                    <AddIcon />
                  </Box>
                </Button>
              </Box>
            </Access>
          </Paper>

          <Paper elevation={0} className="persona-title-container">
            <Box component="div" className="left">
              <ToggleButtonGroup size="small" color="primary" value={layout} exclusive onChange={handleLayout} aria-label="layout">
                <ToggleButton
                  sx={{
                    color: 'primary.main',
                    bgcolor: '#ffffff',
                    border: '1px solid',
                    lineHeight: '0.7',
                    width: '2.5rem',
                    '&.Mui-selected': {
                      bgcolor: 'primary.main',
                      color: '#ffffff',
                      borderColor: 'primary.main',
                    },
                    '&.Mui-selected:hover': {
                      bgcolor: 'primary.main',
                      color: '#ffffff',
                    },
                  }}
                  value="card"
                  aria-label="card"
                >
                  <BorderAllRoundedIcon />
                </ToggleButton>
                <ToggleButton
                  sx={{
                    color: 'primary.main',
                    bgcolor: '#ffffff',
                    border: '1px solid',
                    lineHeight: '0.7',
                    width: '2.5rem',
                    '&.Mui-selected': {
                      bgcolor: 'primary.main',
                      color: '#ffffff',
                      borderColor: 'primary.main',
                    },
                    '&.Mui-selected:hover': {
                      bgcolor: 'primary.main',
                      color: '#ffffff',
                    },
                  }}
                  value="list"
                  aria-label="list"
                >
                  <FormatListBulletedRoundedIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <Box component="div" className="right">
              <Box component="div" sx={{ display: 'flex', alignItems: 'flex-end' }} className="search-btn">
                <FormControl fullWidth>
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search sx={{ color: 'primary.main', mr: 1, my: 0.5 }} />
                        </InputAdornment>
                      ),
                    }}
                    placeholder={t('COMMON.SEARCH')}
                    style={{ borderRadius: '8px !important' }}
                    onChange={(e) => search(e.target.value)}
                  />
                </FormControl>
              </Box>
              <FormControl
                sx={{
                  m: 1,
                  minWidth: 120,
                  display: { xs: 'none', md: 'flex' },
                }}
              >
                <Select
                  value={sort}
                  onChange={(e) => {
                    setSort(e.target.value);
                    sortBy(e.target.value);
                  }}
                  size="small"
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value={''}>
                    {' '}
                    <em>Sort</em>{' '}
                  </MenuItem>
                  <MenuItem value={'name'}>Name</MenuItem>
                  <MenuItem value={'alias'}>Alias</MenuItem>
                  <MenuItem value={'isActive'}>Status</MenuItem>
                </Select>
              </FormControl>
              <FormControl
                sx={{
                  m: 1,
                  mr: 0,
                  minWidth: 120,
                  display: { xs: 'none', md: 'flex' },
                }}
              >
                <Select value={filter} onChange={(e) => setFilter(e.target.value)} size="small" displayEmpty inputProps={{ 'aria-label': 'Without label' }}>
                  <MenuItem value="">
                    {' '}
                    <em>Filter</em>{' '}
                  </MenuItem>
                  <MenuItem value={'name'}>Name</MenuItem>
                  <MenuItem value={'alias'}>Alias</MenuItem>
                  <MenuItem value={'isActive'}>Status</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Paper>
          {layout === 'card' && (
            <StyledGridView>
              {filteredPersonas &&
                filteredPersonas.map((persona, index) => {
                  return (
                    <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
                      <PersonaCard persona={persona} canUpdate={canUpdate} onEdit={handleEdit} canDelete={canDelete} onDelete={handleDelete} onStatusChange={handleStatusChange} isLoading={statusUpdating[persona.id]} />
                    </Grid>
                  );
                })}
            </StyledGridView>
          )}
          {layout === 'list' && (
            <div className="wrapper">
              <TableContainer component={Paper} sx={{ borderRadius: '0', boxShadow: 'none' }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <colgroup>
                    <col width="20%" />
                    <col width="20%" />
                    <col width="40%" />
                    <col width="10%" />
                    <col width="10%" />
                  </colgroup>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">PERSONA NAME</TableCell>
                      <TableCell align="left">ALIAS</TableCell>
                      <TableCell align="left">DESCRIPTION</TableCell>
                      <TableCell align="left">STATUS</TableCell>
                      <TableCell align="center">ACTIONS</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredPersonas &&
                      filteredPersonas.map((persona, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            '&:nth-of-type(odd)': {
                              backgroundColor: theme.palette.action.hover,
                            },
                          }}
                        >
                          <TableCell align="left">{persona.name}</TableCell>
                          <TableCell align="left">{persona.alias}</TableCell>
                          <TableCell align="left">{persona.description}</TableCell>
                          <TableCell align="left">{!statusUpdating[persona.id] ? <FormControlLabel control={<StyledSwitch name="isActive" checked={persona.isActive} color={persona.isActive ? 'primary' : 'error'} onChange={(e) => handleStatusChange(e.target.checked, persona)} />} label={persona.isActive ? 'Active' : 'Inactive'} /> : <Skeleton sx={{ height: '38px', width: '100%' }} />}</TableCell>
                          <TableCell align="center">
                            <ActionMenu onEdit={() => handleEdit(persona.id)} onDelete={() => handleDelete(persona.id)} editPermission={PersonaPermissions.UPDATE} deletePermission={PersonaPermissions.DELETE} />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
          <ConfirmationDialog title="Delete Persona" message="Are you sure you want to delete this persona?" value={idToDelete} open={openDeleteConfirmationDialog} okButtonColor="error" okText="Delete" onClose={handleDeleteClose}></ConfirmationDialog>
        </div>
      )}
    </>
  );
};

export default PersonaList;
