import { Card, Box, CardContent, Breadcrumbs, Typography } from "@mui/material"
import { useEffect } from "react"
import { useNavigate, Link as RouterLink } from "react-router-dom"
import { Resource } from "../../entities/User/MockResource"
import ResourceForm from "./ResourceForm"
import Link, { LinkProps } from "@mui/material/Link"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"

interface AddResourceProps {}

interface LinkRouterProps extends LinkProps {
  to: string
  replace?: boolean
}

const LinkRouter = (props: LinkRouterProps) => (
  <Link {...props} component={RouterLink as any} />
)

const AddResource: React.FC<AddResourceProps> = (props) => {
  const breadcrumbs = [
    <Link underline="hover" key="1" sx={{ opacity: "0.7" }}>
      Setup
    </Link>,
    <LinkRouter
      underline="hover"
      key="2"
      to="/resource"
      sx={{ opacity: "0.7" }}
    >
      Resource
    </LinkRouter>,
    <Typography key="3" color="primary.main">
      Create New Resource
    </Typography>,
  ]

  let navigate = useNavigate()

  const addResourceHandler = (resourceData: Resource) => {
    fetch(
      "https://react-getting-started-48dec-default-rtdb.firebaseio.com/meetups.json",
      {
        method: "POST",
        body: JSON.stringify(resourceData),
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then(() => {
      backHandler()
    })
  }

  const backHandler = () => {
    navigate("/resource")
  }

  return (
    <>
      <Breadcrumbs
        sx={{ margin: "2rem" }}
        separator={<NavigateNextIcon color="primary" fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
      <Card
        sx={{ display: "flex" }}
        elevation={5}
        style={{ margin: "2rem", height: "100%" }}
      >
        <Box
          component="div"
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            textAlign: "left",
          }}
        >
          <CardContent
            sx={{ flex: "1 0 auto", p: 0 }}
            className="resource-form-content"
          >
            <ResourceForm
              onBack={backHandler}
              onSubmit={addResourceHandler}
              title={"Create New Resource"}
            />
          </CardContent>
        </Box>
      </Card>
    </>
  )
}

export default AddResource
