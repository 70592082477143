import { User } from "../../entities/User/MockUser"
import {newAxiosInstance as http} from "../http-common"

const getUsersByCompanyId = (companyId: string) => {
  return http.get<User[]>(`/user/users/company/${companyId}`)
}

const getOrganizations = () => {
  return http.get(`/organization/getAllOrganizations`);
}

const getUsersById = (id: string) => {
  return http.get(`/user/users/${id}`)
}
const getUserByUsername = (username: string) => {
  return http.get(`user/userByUsername/${username}`)
}

const getUsers = () => {
  return http.get(`/user/users`)
}

const getChargebeeAccessUrl = () => {
  return http.get(`/subscription/manage/sso`)
}

const getPersonas = () => {
  return http.get(`/persona/get`)
}

const createUser = (user: any) => {
  return http.post<any>(`/user/users`, user)
}
const updateUser = (id: string, user: any) => {
  return http.put<any>(`/user/users/${id}`, user)
}
const deleteUser = (id: string) => {
  return http.delete<any>(`/user/users/${id}`)
}

const disableUserToken = () => {
  return http.post<any>(`/logout`,{"action":"logged out"})
}

const UserService = {
  getUsersByCompanyId,
  getUsersById,
  getChargebeeAccessUrl,
  createUser,
  updateUser,
  deleteUser,
  getUsers,
  getOrganizations,
  getUserByUsername,
  getPersonas,
  disableUserToken
}
export default UserService
