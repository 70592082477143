import { Box, Button, Grid, Skeleton, Typography } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { IDateRange, IOrganizationNewlyAdded } from '../../entities/Analytics/AnalyticsProcedure';
import { useAppSelector } from '../../hooks/redux';
import AnalyticsService from '../../services/Analytics/AnalyticsService';
import { ANALYTICS_TYPES } from '../../shared/enums';
import { useAnalytics } from '../../states/analytics';
import { Toast } from '../Notifications/Notification';
import './Analytics.scss';
import AnalyticsHeader from './Header/AnalyticsHeader';
import { AnalyticsTooltip } from './ProcedureReports/AnalyticsTooltip';
import { Feedback } from './ProcedureReports/Feedback';
import TraineesAssigned from './TraineeReports/TraineesAssigned';
import { AssignmentHistory } from './TraineeReports/TraineesAssigned/AssignmentHistory';
import TrainerOverview from './TrainerOverview';
import { TrainingSignOff } from './TrainingReports/TrainingSignOff';


const TraineeOverview = ({ loadingData, LoadersState, currentDateRange, currentFilterType, pendingProcedures, startDate, endDate, assignedTraineesData, completionRate, assignmentHistory, filter }) => {
  const navigate = useNavigate();

  const handleGotoAllPendingProceduresList = () => {
    navigate(`/analytics/procedures/pending?filter=${currentFilterType}&startDate=${currentDateRange.startDate}&endDate=${currentDateRange.endDate}&procedure=All&procedureFilter=UNASSIGNED`);
  };

  return (
    <>
      <Typography sx={{ fontSize: '14px', display: 'flex', mb: '12px', justifyContent: 'flex-start' }}>ORGANIZATION OVERVIEW</Typography>
      <Box component="div" className="analytics-box" sx={{ display: 'flex', height: '570px' }}>
        <Grid container spacing={2} sx={{ display: 'flex', flexGrow: 1, backgroundColor: 'rgba(255, 255, 255, 0.2)', overflowY: 'hidden' }}>
          <Grid item xs={8} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Grid container spacing={2} sx={{ display: 'flex', flexGrow: 1 }}>
              <Grid item xs={4} sx={{ display: 'flex' }}>
                <Box component="div" className="analytics-box" sx={{ flexGrow: 1, boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', minHeight: '71px', cursor: 'pointer', alignItems: 'flex-start', display: 'flex' }}>
                  {loadingData[LoadersState.ASSIGNED_TRAINEES] === true && <Skeleton variant="rectangular" height={'100%'} width="100%" />}
                  {loadingData[LoadersState.ASSIGNED_TRAINEES] === false && assignedTraineesData && (
                    <>
                      <AnalyticsTooltip label={'Click to see who have completed the procedure'}>
                        <Button
                          sx={{ padding: 0, color: '#004b8d' }}
                          onClick={() => {
                            navigate(`/analytics/trainees/completionRate/details?startDate=${currentDateRange.startDate || startDate}&endDate=${currentDateRange.endDate || endDate}&filter=${currentFilterType || filter}`);
                          }}
                        >
                          <Feedback title="Completion Rate" value={completionRate} />
                        </Button>
                      </AnalyticsTooltip>
                    </>
                  )}
                </Box>
              </Grid>
              <Grid item xs={4} sx={{ display: 'flex' }}>
                <Box component="div" className="analytics-box" sx={{ flexGrow: 1, boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', minHeight: 'fit-content' }}>
                  {loadingData[LoadersState.ASSIGNMENT_HISTORY] === true && <Skeleton variant="rectangular" height={'100%'} width="100%" />}
                  {loadingData[LoadersState.ASSIGNMENT_HISTORY] === false && assignmentHistory && <Feedback title="Procedures Assigned" value={assignmentHistory.length || '0'} />}
                </Box>
              </Grid>
              <Grid item xs={4} sx={{ display: 'flex' }}>
                <Box component="div" className="analytics-box" sx={{ flexGrow: 1, boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', minHeight: '71px' }}>
                  {loadingData[LoadersState.PENDING_PROCEDURES] === true && <Skeleton variant="rectangular" height={'100%'} width="100%" />}
                  {loadingData[LoadersState.PENDING_PROCEDURES] === false && pendingProcedures && (
                    <Button
                      sx={{ padding: 0, color: '#004b8d', display: 'flex' }}
                      onClick={() => {
                        handleGotoAllPendingProceduresList();
                      }}
                    >
                      <Feedback title="Procedures Ready for Assignment" value={pendingProcedures.pendingCounts.unassigned || 0} />
                    </Button>
                  )}
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', flexGrow: 1, mt: '16px' }}>
              <Box component="div" className="analytics-box" sx={{ display: 'flex', flexGrow: 1, boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
                {loadingData[LoadersState.ASSIGNED_TRAINEES] === true && (
                  <Box component="div" sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                    <Skeleton variant="rectangular" height={'50px'} width="200px" />
                    <br />
                    <Skeleton variant="rectangular" height={'100%'} width="100%" />
                  </Box>
                )}
                {loadingData[LoadersState.ASSIGNED_TRAINEES] === false && assignedTraineesData && <TraineesAssigned dateRange={currentDateRange} filterType={currentFilterType} assignedTraineesData={assignedTraineesData} />}
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={4} sx={{ height: '100%' }}>
            <Box component="div" className="analytics-box" sx={{ height: 'calc(100% - 50px)', overflow: 'auto', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
              {loadingData[LoadersState.ASSIGNMENT_HISTORY] === true && <Skeleton variant="rectangular" height={'100%'} width="100%" />}
              {loadingData[LoadersState.ASSIGNMENT_HISTORY] === false && assignmentHistory && <AssignmentHistory assignmentHistory={assignmentHistory} />}
              {assignmentHistory && assignmentHistory.length === 0 && <Typography sx={{ fontSize: '14px', mt: '24px', color: 'rgba(18, 24, 31, 0.6)' }}>[No procedures have been assigned]</Typography>}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const TrainingOverview = () => {
  return (
    <>
      <TrainingSignOff />
    </>
  );
};
enum LoadersState {
  ASSIGNED_TRAINEES = 'ASSIGNED_TRAINEES',
  ASSIGNMENT_HISTORY = 'ASSIGNMENT_HISTORY',
  PENDING_PROCEDURES = 'PENDING_PROCEDURES',
  TRAINER_ORG_OVERVIEW = 'TRAINER_ORG_OVERVIEW',
  TRAINER_USER_OVERVIEW = 'TRAINER_USER_OVERVIEW',
}
type LoaderStateType = { [key in LoadersState]: boolean };

const AnalyticsContainer = () => {
  const { t } = useTranslation();
  const {header} = useAppSelector(state => state.analytics);
  const { selectedAnalytics, dateRange: currentDateRange } = header;

  const currentFilterType = useAnalytics((state) => state.selectedType);
  const [loadingData, setLoadingData] = useState<LoaderStateType>({
    [LoadersState.ASSIGNED_TRAINEES]: true,
    [LoadersState.ASSIGNMENT_HISTORY]: true,
    [LoadersState.PENDING_PROCEDURES]: true,
    [LoadersState.TRAINER_ORG_OVERVIEW]: true,
    [LoadersState.TRAINER_USER_OVERVIEW]: true,
  });
  const [assignedTraineesData, setAssignedTraineesData] = useState<any>();
  const [assignmentHistory, setAssignmentHistory] = useState<any>();
  const saveAssignedTraineesList = useAnalytics((state) => state.setAssignedTraineesList);
  const saveAssignmentHistory = useAnalytics((state) => state.setAssignmentHistory);
  const [pendingProcedures, setPendingProcedures] = useState<any>();
  const [completionRate, setCompletionRate] = useState('');
  
  const { search: searchQuery } = useLocation();
  const query = new URLSearchParams(searchQuery);
  const filterType = query.get('filter');
  const startDate = query.get('startDate');
  const endDate = query.get('endDate');
  const setLoadingDataState = (key: keyof typeof LoadersState, value: boolean) => {
    setLoadingData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  useEffect(() => {
    if (currentDateRange) {
      loadData(currentDateRange);
    } else if (startDate && endDate) {
      loadData({
        startDate: startDate,
        endDate: endDate,
      } as IDateRange);
    }

    return () => {};
  }, []);

  useEffect(() => {
    if (currentDateRange) {
      loadData(currentDateRange);
    }
  }, [currentDateRange, currentFilterType, filterType, startDate, endDate]);

  const loadData = (dateRange: IDateRange) => {
    if (dateRange && dateRange.startDate && dateRange.endDate) {
      getAssignedTrainees(dateRange);
      getAssignmentHistory(dateRange);
    }
  };

  const getAssignmentHistory = (dateRange: IDateRange) => {
    setLoadingDataState(LoadersState.ASSIGNMENT_HISTORY, true);
    AnalyticsService.getAnalyticsOrganizationAssignedProceduresList(dateRange.startDate || moment().clone().startOf('month').format('DD-MM-YYYY'), dateRange.endDate || moment().format('DD-MM-YYYY'))
      .then((res) => {
        setLoadingDataState(LoadersState.ASSIGNMENT_HISTORY, false);
        setAssignmentHistory(res.data.data);
        saveAssignmentHistory(res.data.data);
      })
      .catch(({ response }) => {
        setLoadingDataState(LoadersState.ASSIGNMENT_HISTORY, false);
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
      });
  };

  const getAssignedTrainees = (dateRange: IDateRange) => {
    setLoadingDataState(LoadersState.ASSIGNED_TRAINEES, true);
    AnalyticsService.getAnalyticsOrganizationAssignedTrainees(dateRange.startDate, dateRange.endDate)
      .then((e) => {
        const {
          data: { data: records },
        } = e;
        let status = {
          completed: [] as any,
          inProgress: [] as any,
          notStarted: [] as any,
        };
        let completionRateNumerator = 0;
        let completionRateDenominator = 0;
        if (records) {
          records.map((record) => {
            completionRateNumerator += record.progress.completed.length;
            completionRateDenominator += record.progress.completed.length + record.progress.inProgress.length + record.progress.notStarted.length;
            if (record.progress.inProgress.length > 0 || (record.progress.completed.length === 0 && record.progress.notStarted.length === 0) || (record.progress.completed.length > 0 && record.progress.notStarted.length > 0)) {
              status.inProgress.push(record);
            } else {
              if (record.progress.completed.length > 0) {
                status.completed.push(record);
              } else {
                status.notStarted.push(record);
              }
            }
          });
          if (completionRateDenominator <= 0 || completionRateNumerator <= 0) {
            setCompletionRate('0%');
          } else setCompletionRate(((completionRateNumerator / completionRateDenominator) * 100).toFixed(2) + '%');
          setAssignedTraineesData(status);
          saveAssignedTraineesList(status);
        }
        setLoadingDataState(LoadersState.ASSIGNED_TRAINEES, false);
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        setLoadingDataState(LoadersState.ASSIGNED_TRAINEES, false);
      });
  };


  return (
    <Box
      component="div"
      className="analytics"
      sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
    >
      <AnalyticsHeader />
      <Box
        component="div"
        sx={{ padding: '20px 0' }}
      >
        {selectedAnalytics === ANALYTICS_TYPES.TRAINEE_ANALYTICS && (
          <TraineeOverview
            loadingData={loadingData}
            LoadersState={LoadersState}
            currentDateRange={currentDateRange}
            currentFilterType={currentFilterType}
            pendingProcedures={pendingProcedures}
            startDate={startDate}
            endDate={endDate}
            assignedTraineesData={assignedTraineesData}
            completionRate={completionRate}
            assignmentHistory={assignmentHistory}
            filter={filterType}
          />
        )}
        {selectedAnalytics === ANALYTICS_TYPES.TRAINER_ANALYTICS && (
          <TrainerOverview />
        )}
        {selectedAnalytics === ANALYTICS_TYPES.TRAINING_ANALYTICS && <TrainingOverview />}
      </Box>
    </Box>
  );
};

export default AnalyticsContainer;
