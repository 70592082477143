import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const SubstitutionsEmptyState = (props) => {
  const { t } = useTranslation();

  return (
    <Box
      component={'div'}
      sx={{
        height: '500px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '20px',
      }}
    >
      <EmptyStateSVG />
      <Typography variant="h4">{t('SUBSTITUTION.EMPTY_STATE.PRIMARY_TEXT')}</Typography>
      <Typography
        variant="h5"
        sx={{
          color: '#1C1D1F99',
        }}
      >
        {t('SUBSTITUTION.EMPTY_STATE.SECONDARY_TEXT')}
      </Typography>
    </Box>
  );
};

export default SubstitutionsEmptyState;

const EmptyStateSVG = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={120}
    height={100}
    fill="none"
    {...props}
  >
    <path
      fill="url(#a)"
      d="M68.354 14.962h16.047c2.148 0 4.043-1.797 4.043-4.108 0-2.182-1.768-4.108-4.043-4.108l-16.047.001c-5.053 0-9.223 4.236-9.223 9.37v29.65H43.717v-8.215c0-1.412-.758-2.696-2.022-3.209l-18.32-8.471c-.885-.385-2.022-.385-2.906 0l-18.447 8.6C.758 35.112 0 36.395 0 37.68v24.388c0 1.412.758 2.696 2.022 3.209l18.32 8.472c.505.257 1.01.385 1.516.385.505 0 1.01-.128 1.516-.385l18.32-8.472c1.264-.642 2.022-1.925 2.022-3.209v-8.215H59.13v29.65c0 5.134 4.17 9.37 9.223 9.37H84.4c2.148 0 4.043-1.797 4.043-4.108 0-2.181-1.768-4.107-4.043-4.107l-16.046.001c-.758 0-1.264-.642-1.264-1.284V54.11h14.783c2.148 0 4.044-1.797 4.044-4.108 0-2.182-1.77-4.108-4.044-4.108H66.964V16.373c0-.77.632-1.412 1.39-1.412ZM21.858 33.316l9.73 4.493-9.73 4.492-9.855-4.492 9.855-4.493ZM7.075 43.456 18.32 48.72v16.43L7.075 59.885v-16.43Zm18.32 21.692V48.591l11.246-5.263v16.43l-11.245 5.39Z"
    />
    <path
      fill="url(#b)"
      d="M118.768 40.759c-1.643-1.54-4.169-1.412-5.685.257l-8.719 9.498-3.158-3.465c-1.516-1.67-4.044-1.67-5.686-.257-1.643 1.54-1.643 4.108-.253 5.775l6.065 6.675c.758.77 1.769 1.284 2.906 1.284 1.136 0 2.147-.513 2.905-1.284l11.624-12.707c1.644-1.797 1.644-4.364.001-5.776Z"
    />
    <path
      fill="url(#c)"
      d="M96.91 20.479a3.888 3.888 0 0 0 2.779 1.155 3.885 3.885 0 0 0 2.779-1.155l3.791-3.851 3.791 3.85a3.888 3.888 0 0 0 2.779 1.156 3.889 3.889 0 0 0 2.78-1.155c1.516-1.54 1.516-4.108 0-5.776l-3.791-3.851 3.791-3.85c1.516-1.54 1.516-4.109 0-5.777-1.516-1.54-4.044-1.54-5.685 0l-3.791 3.851-3.791-3.85c-1.516-1.54-4.043-1.54-5.685 0-1.516 1.54-1.516 4.107 0 5.775l3.791 3.851-3.791 3.85c-1.264 1.67-1.264 4.237.252 5.777Z"
    />
    <path
      fill="url(#d)"
      d="M115.737 79.522c-1.516-1.54-4.044-1.54-5.686 0l-3.79 3.851-3.791-3.85c-1.516-1.54-4.043-1.54-5.685 0-1.516 1.54-1.516 4.107 0 5.775l3.79 3.85L96.785 93c-1.516 1.54-1.516 4.108 0 5.776a3.888 3.888 0 0 0 2.78 1.155c1.01 0 2.021-.385 2.779-1.155l3.791-3.851 3.79 3.85a3.889 3.889 0 0 0 2.78 1.156 3.889 3.889 0 0 0 2.78-1.155c1.516-1.54 1.516-4.108 0-5.776l-3.791-3.851 3.791-3.851c1.768-1.669 1.768-4.236.252-5.776Z"
    />
    <defs>
      <linearGradient
        id="a"
        x1={146.756}
        x2={-61.148}
        y1={55.765}
        y2={17.877}
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor="#AEAEAE"
          stopOpacity={0}
        />
        <stop
          offset={1}
          stopColor="#AEAEAE"
        />
      </linearGradient>
      <linearGradient
        id="b"
        x1={136.974}
        x2={77.311}
        y1={51.544}
        y2={38.468}
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor="#AEAEAE"
          stopOpacity={0}
        />
        <stop
          offset={1}
          stopColor="#AEAEAE"
        />
      </linearGradient>
      <linearGradient
        id="c"
        x1={130.74}
        x2={80.714}
        y1={12.343}
        y2={3.604}
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor="#AEAEAE"
          stopOpacity={0}
        />
        <stop
          offset={1}
          stopColor="#AEAEAE"
        />
      </linearGradient>
      <linearGradient
        id="d"
        x1={130.818}
        x2={80.859}
        y1={90.64}
        y2={81.925}
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor="#AEAEAE"
          stopOpacity={0}
        />
        <stop
          offset={1}
          stopColor="#AEAEAE"
        />
      </linearGradient>
    </defs>
  </svg>
);
