import { createSlice } from '@reduxjs/toolkit';

type InitialState = {
  listStepCommentThreads: any[];
};
const initialState: InitialState = {
  listStepCommentThreads: [],
};

const CommentsSlice = createSlice({
  name: 'comments',
  initialState: initialState,
  reducers: {
    setListStepCommentThreads: (state, action) => {
      state.listStepCommentThreads = action.payload;
    },
  },
});

export default CommentsSlice.reducer;
export const { setListStepCommentThreads } = CommentsSlice.actions;
