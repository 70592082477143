import { gzip } from 'pako';
import { IGltfFileInfo } from '../../entities/FileManager/GltfFileInfo';
import { IWorkInstructions } from '../../entities/Trainee/MockTrainee';
import { environment } from '../../environment/environment';
import { fmAxiosInstance as http } from '../http-common';
import { DataResponse } from '../../shared/interfaces';

const getWorkInstructionGltfFileId = (workInstructionId: string) => {
  const url = `v1/search?obj=files&type=cad/Gltf&cache=true&model=${workInstructionId}`;
  return http.get<DataResponse<Array<IGltfFileInfo>>>(url);
};

const getWorkInstructionThumbnail = (organizationId: string, workInstructionId: string) => {
  const url = `/v1/search/files?org=${organizationId}&model=${workInstructionId}&type=cad/Thumbnail`;
  return http.get<DataResponse<IGltfFileInfo>>(url);
};

const getWorkInstructionThumbnail2d = (organizationId: string, workInstructionId: string) => {
  const url = `/v1/search/files?org=${organizationId}&model=${workInstructionId}&type=2d/thumbnail`;
  return http.get<DataResponse<IGltfFileInfo>>(url);
};

const uploadImage = (data: any, name: any, type: any, dataType: any, metadata: any) => {
  const org = metadata.organizationId;
  const model = metadata.workInstructionId;
    let bodyFormData = new FormData();
    var content: any = JSON.stringify(data);
    content = gzip(content, { level: 9 });
    var blob = new Blob([content], { type: type });
    bodyFormData.append('file', blob);
    bodyFormData.append('org', org);
    bodyFormData.append('model', model);
    bodyFormData.append('name', name);
    bodyFormData.append('type', type);
    bodyFormData.append('compression', 'gzip');
    const fm = environment.AppConfigs.fileManagerApi;
   return http.post(`${fm}/v2/files`, bodyFormData)
      
  ;
};

function fileUploadBlob(file: File, metadata: any, config?: any): Promise<any> {
  const fm = environment.AppConfigs.fileManagerApi;
  const uploadUrl = `${fm}/v2/files`;
  const org = metadata.organizationId;
  const model = metadata.workInstructionId;

  const formData = new FormData();
  formData.append('file', file, file.name);
  formData.append('org', org);
  formData.append('model', model);
  formData.append('name', file.name);
  formData.append('type', file.type);

  return http.post(uploadUrl, formData, config);
}

const getFileManagerFile = (fileId: any) => {
  return new Promise((resolve, reject) => {
    http
      .get(`/v1/files/${fileId}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

const getWorkInstructionGltfFilePath = (fileId: string) => {
  const fm = environment.AppConfigs.fileManagerApi;
  return `${fm}/v2/files/${fileId}?cache=true`;
};

const getWorkInstructionThumbnailPath = (fileId: string, cache=true) => {
  const fm = environment.AppConfigs.fileManagerApi;
  const url = `${fm}/v1/files/${fileId}?cache=${cache}`;
  return http.get(url, {
    responseType: 'arraybuffer',
  });
};

const getFileById = (fileId: string) => {
  const fm = environment.AppConfigs.fileManagerApi;
  const url = `${fm}/v1/files/${fileId}?cache=true`;
  return http.get(url, {
    responseType: 'arraybuffer',
  });
};

const fileUpload = (formData: any, config: any) => {
  const url = `/v2/cad/files`;
  return http.post<DataResponse<IWorkInstructions>>(url, formData, config);
};

const retryFileUpload = (formData: any) => {
  const url = `/v2/cad/resubmit`;
  return http.post<DataResponse<any>>(url, formData);
};

const FileManagerService = {
  getWorkInstructionGltfFileId,
  getWorkInstructionGltfFilePath,
  getWorkInstructionThumbnail,
  getWorkInstructionThumbnailPath,
  fileUpload,
  getFileById,
  retryFileUpload,
  getFileManagerFile,
  uploadImage,
  fileUploadBlob,
  getWorkInstructionThumbnail2d
};

export default FileManagerService;
