import { Breadcrumbs, Button, IconButton, Link, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import TrainerService from '../../services/trainer/TrainerService';
import { ArrowBreadCrumbIcon, FolderIconBreadCrumb } from '../Icons/CdsIcons';
import './CdsBreadCrumb.scss';
import { ArrowBack, DescriptionOutlined, FolderOutlined } from '@mui/icons-material';
import { IFolders, IWorkInstructions } from '../../entities/Trainee/MockTrainee';

export interface CdsBreadCrumbRoute {
  title: string;
  route?: string;
  icon: React.ReactNode;
  metadata?: any
}

interface Props {
  routes: CdsBreadCrumbRoute[];
  folderBreadCrumb?: boolean;
  setCurrPage?: any;
  loadData?: any;
  setBreadCrumbRoute?: any;
  workInstructionAppBar?: boolean;
  setWorkInstructionAppBar?: any;
  btnClickHandler?: (route: CdsBreadCrumbRoute) => void;
}

const CdsBreadCrumb: React.FC<Props> = (props) => {

  useEffect(() => {
    if (props?.workInstructionAppBar) {
      const rootFolder = '';
      navigateFolders(rootFolder);
      props.setWorkInstructionAppBar(false);
    }
    
  }, [props.workInstructionAppBar]);

  const navigateFolders = (r) => {
    const folderId = r.metadata?.folderId ? r.metadata?.folderId : '';
    if (folderId) {
      getBreadCrumbRoute(folderId, 'Folder').then(newRoute => {
        props.setBreadCrumbRoute(newRoute);
        if(props.btnClickHandler){
          props.btnClickHandler(r);
        }
      });
    } else {
      props.setBreadCrumbRoute([]);
      if(props.btnClickHandler){
        props.btnClickHandler(r);
      }
    }
    props.setCurrPage && props.setCurrPage(1);
    props.loadData && props.loadData(folderId);
  };
  return (
    <Stack spacing={2}>
      <Breadcrumbs separator={<ArrowBreadCrumbIcon />} aria-label="breadcrumb" color="primary">
        {props.routes.map(
          (route, i) => (
            <Button 
              key={`breadcrumb-${i}`}
              onClick={() => {
                navigateFolders(route);
              }}
              startIcon={route.icon}
              disabled={i === props.routes.length - 1}
              sx={{
                ":disabled": {
                  color: '#1C1D1F99'
                }
              }}
            >
              {route.title}
            </Button>
          ),
          // ),
        )}
      </Breadcrumbs>
    </Stack>
  );
};

export default CdsBreadCrumb;


export const getBreadCrumbRoute = (folderId: string, type: "Folder" | "WorkInstruction") => {
  return TrainerService.getBreadCrumbPath(folderId, type).then((response) => {
    const home: CdsBreadCrumbRoute = { title: 'All Work Instruction', icon: <ArrowBack /> };
    const newRoute: CdsBreadCrumbRoute[] = [];
    newRoute.push(home);

    const routeToSet: any[] = response.data.data;
    routeToSet.forEach(route => {
      if(route.hasOwnProperty('workInstructionName')){
        newRoute.push({
          title: route.workInstructionName,
          icon: null,
          metadata: route
        })
      } else {
        newRoute.push({
          title: route.title,
          icon: <FolderOutlined />,
          metadata: route
        })
      }
    })
    return newRoute;
  })
}