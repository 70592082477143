import { IRbacUser } from '../../context/UserContext';

export const calculateTime = (second) => {
    var sec_num = parseInt(second, 10);
    var hours: number | string = Math.floor(sec_num / 3600);
    var minutes: number | string = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds: number | string = sec_num - (hours * 3600) - (minutes * 60);

    if (hours < 10) { hours = "0" + hours; }
    if (minutes < 10) { minutes = "0" + minutes; }
    if (seconds < 10) { seconds = "0" + seconds; }

    return hours + ':' + minutes + ':' + seconds;
}

export const calculateTimeInMinutes = (second) => {
    var sec_num = parseInt(second, 10);
    var minutes: number | string = Math.floor(sec_num / 60);
    var seconds: number | string = sec_num - (minutes * 60);

    if (minutes < 10) { minutes = "0" + minutes; }
    if (seconds < 10) { seconds = "0" + seconds; }

    return minutes + ':' + seconds;
}

export const hasPermissions = (currentUser: IRbacUser, permission: string | Array<string>, requireAll: boolean = true): boolean => {
  const { scopes } = currentUser;

  try {
    const permissions = Object.keys(scopes).map(resource => scopes[resource].map(action => resource + '.' + action)).reduce((pre, cur) => pre.concat(cur));
    const values = typeof permission !== 'object' ? [permission] : permission;
    const access = values.filter((value) => permissions.indexOf(value) > -1);

    return requireAll ? (access.length === values.length) : (access.length > 0);
  } catch (error) {
    return false;
  }
}

export const shorten = (str: string = '', limit: number = 0) => {
  if (str.length > limit) {
    return str.substring(0, limit) + ' ...';
  }
  return str;
}

export const fileSize = (bytes: number): string => {
  const kb = 1024;
  let unit = 0;
  while (bytes >= kb || -bytes >= kb) {
      bytes /= kb;
      unit++;
  }
  return (unit ? bytes.toFixed(1) + ' ' : bytes) + ' KMGTPEZY'[unit] + 'B';
}
