import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../hooks/redux';
import { Box, Button, CircularProgress, ClickAwayListener, Paper, Skeleton, Typography, useTheme } from '@mui/material';
import { SideBarHeader } from './Sidebar';
import { ArrowRightAlt, Check } from '@mui/icons-material';
import SearchBar from '../SearchBar/SearchBar';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import UserService from '../../services/User/UserService';
import LanguageService from '../../services/Language/LanguageService';
import { setCompanyTheme } from '../../redux/features/app/app.slice';
import { setSidebarToOpen } from '../../redux/features/SideBar/Sidebar.slice';
import { useNavigate } from 'react-router-dom';
import { SUPPORTED_LANGUAGES } from '../../shared/enums';

const UserLanguageSideBar = (props) => {
  const { sidebarName } = useAppSelector((state) => state.sidebar);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();
  const { companyTheme, persona } = useAppSelector((state) => state.app);

  const [preferredLanguage, setPreferredLanguage] = useState<string>(SUPPORTED_LANGUAGES.ENGLISH);
  const [searchText, setSearchText] = useState<string>('');
  const [clickedItemIndex, setClickedItemIndex] = useState<number | undefined>();


  useEffect(() => {
    if (companyTheme && companyTheme.language) {
      setPreferredLanguage(companyTheme.language.id);
    }
  }, [companyTheme]);

  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value)
  };
  const handleLanguageClick = (selectedLang: string) => {
    if(preferredLanguage === SUPPORTED_LANGUAGES[selectedLang]) return ;

    let langValue = selectedLang.toLowerCase();
    langValue = langValue.charAt(0).toUpperCase() + langValue.substring(1);

    const languagePayloadForTheme = {
      id: SUPPORTED_LANGUAGES[selectedLang],
      value: langValue,
    };

    LanguageService.updateLanguage(languagePayloadForTheme)
      .then((response) => {
        let theme = JSON.parse(JSON.stringify(companyTheme));
        if (!theme) {
          console.warn('Something is terribly wrong. Theme is not present');
          return;
        }
        theme.language = {
          id: SUPPORTED_LANGUAGES[selectedLang],
          value: langValue,
        };
        dispatch(setCompanyTheme(theme));
      })
      .catch((error) => {
        console.error(error)
      }).finally(() => {
        setClickedItemIndex(undefined);
        window.location.reload();
      });
  };
  const closeSidebar = () => {
    dispatch(setSidebarToOpen(null));
  }
  const handleClickAway = () => {
    closeSidebar();
  }
  const handleTranslationSubstitutionClick = () => {
    closeSidebar();
    navigate('/substitutions')
  }
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box
        component={'div'}
        sx={{ height: '100%' }}
      >
        <SideBarHeader sidebarHeaderTitle={t('LANGUAGE_SIDEBAR.HEADER_TEXT')} />
        <Box
          component={'div'}
          sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
        >
          <Box component={'div'}>
            <Box
              component={'span'}
              sx={{ opacity: 0.5 }}
            >
              {t('COMMON.SELECTED')}:
            </Box>{' '}
            {t(`LANGUAGE_SIDEBAR.SUPPORTED_LANGUAGES.${preferredLanguage.toUpperCase()}`)}
          </Box>
          <Box
            component={'div'}
            sx={{ width: '250px' }}
          >
            <SearchBar onChangeHandler={handleSearchTextChange} />
          </Box>
          <Box
            component={'div'}
            sx={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}
          >
            {Object.keys(SUPPORTED_LANGUAGES).map((each, i) => {
              if (searchText && each.toLowerCase().indexOf(searchText.toLowerCase()) === -1) {
                return null;
              }
              return (
                <Button
                  variant={preferredLanguage === SUPPORTED_LANGUAGES[each] ? 'outlined' : 'text'}
                  endIcon={preferredLanguage === SUPPORTED_LANGUAGES[each] ? <Check color="primary" /> : clickedItemIndex === i ? <CircularProgress size={20} /> : null}
                  sx={{ color: 'black' }}
                  onClick={() => {
                    handleLanguageClick(each);
                    setClickedItemIndex(i);
                  }}
                >
                  {t(`LANGUAGE_SIDEBAR.SUPPORTED_LANGUAGES.${SUPPORTED_LANGUAGES[each].toUpperCase()}`)}
                </Button>
              );
            })}
          </Box>
          {persona === 'trainer' ? (
            <Paper
              component={'div'}
              elevation={4}
              sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                borderRadius: 0,
                // border: '1px solid black',
                width: '100%',
              }}
            >
              <Typography
                sx={{ display: 'flex', alignItems: 'center', padding: '8px 16px', cursor: 'pointer', color: theme.palette.primary.main }}
                onClick={handleTranslationSubstitutionClick}
              >
                {t('SUBSTITUTION.HEADING')} <ArrowRightAlt />
              </Typography>
            </Paper>
          ) : null}
        </Box>
      </Box>
    </ClickAwayListener>
  );
};

export default UserLanguageSideBar;
