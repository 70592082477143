import * as React from 'react';
import { Box, Typography } from '@mui/material';
import QRCode from 'react-qr-code';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export const ScanQR = (props) => {
  const [open, setOpen] = React.useState(false);
  const [showDialog, setShowDialog] = React.useState<boolean>(false);

  const handleClose = () => {
    props.handleScanDialogClose();
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={props.openDialog}
      scroll="paper"
      className="share-qr-dialog"
      PaperProps={{
        sx: {
          padding: '24px',
        },
      }}
    >
      <Box component="div" sx={{ borderColor: 'divider' }}>
        <Box component="div">
          <Typography>
            <strong>Scan QR code</strong>
          </Typography>
          <Typography variant="subtitle2" sx={{marginBottom:'20px'}}>
            To open this in Apple/Hololens device
          </Typography>

          <Box
            component="div"
            sx={{
              border: '1px solid rgba(0, 0, 0, 0.2)',
              borderRadius: '16px',
              padding: '24px'
            }}
          >
            <QRCode
              //size={256}
              style={{ height:'auto', maxWidth: "100%", width: "100%" }}
              value={props.value}
              bgColor={'#FFFFFF'}
              fgColor={'black'}
            />
          </Box>
        </Box>
      </Box>
    </BootstrapDialog>
  );
};
