import { Box, Button, FormControl, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './LanguageSection.scss';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import OrganizationService from '../../services/Organization/OrganizationService';
import LanguageService from '../../services/Language/LanguageService';
import { Toast } from '../Notifications/Notification';
import { getMessage } from '../../shared/utils/message-helpers';

interface Props {
  onLoading: (flag: boolean) => void;
  handleClose : () => void;
}

export const LanguageSection = (props: Props) => {
  const [count, setCount] = useState(1);
  const [lang, setLang]: any = useState({ id: '', value: '' });
  const [preferedLanguage, setPreferedLanguage] = useState({ id: '', value: '' });
  const [languages, setLanguages] = useState([{ id: '', value: '' }]);

  const handleChange = (event: SelectChangeEvent) => {
    const selected = languages.find((obj) => obj.value === event.target.value);
    setLang(selected);
  };

  const changeLanguage = async () => {
    props.onLoading(true);
    await LanguageService.updateLanguage(lang)
      .then((response) => {
        props.handleClose();
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
      })
      .finally(() => {
        props.onLoading(false);
      });
  };

  useEffect(() => {
    props.onLoading(true);
    LanguageService.getLanguages()
      .then((response) => {
        setLanguages(response.data.data);
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
      })
      .finally(() => {
        props.onLoading(true);
        OrganizationService.getTheme()
          .then((e) => {
            const {
              data: { data: themeData },
            } = e;

            setPreferedLanguage(themeData.language);
          })
          .catch(({ response }) => {
            Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
          })
          .finally(() => {
            props.onLoading(false);
          });
      });
  }, []);

  useEffect(() => {
    setCount(languages.length);
    if(preferedLanguage.id){
    setLang(preferedLanguage);
    i18n.changeLanguage(preferedLanguage.id);
    }
  }, [preferedLanguage]);

  useEffect(() => {}, []);

  const { t } = useTranslation();

  return (
    <Box sx={{ height: '16rem' }} component="div">
      <Box component="div">
        <Typography sx={{ color: '#1C1D1F', fontSize: '.75rem', padding: '0 .5rem' }}>
          {t('SYSTEM_SETTINGS.ALL_LANGUAGES') as string} ({count})
        </Typography>
      </Box>
      <Box component="div">
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <Select value={lang.value} onChange={handleChange} displayEmpty inputProps={{ 'aria-label': 'Preffered language' }} sx={{ width: '17rem', height: '2.5rem' }}>
            {languages.map((language) => (
              <MenuItem
                //defaultValue={preferedLanguage.value}
                key={language.id}
                value={language.value}
              >
                {t(language.value)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box component="div" sx={{ margin: '1.5rem 0 0 .5rem' }}>
        <Button variant="contained" onClick={changeLanguage}>
          {t('BUTTON.SAVE')}
        </Button>
      </Box>
    </Box>
  );
};
