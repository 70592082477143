export interface PPE {
  img: string;
  title: string;
  active: boolean;
  isStandard?: boolean;
  id?: string;
  name?: string;
  previewImgUrl?: string;
}

export const ppeList = [
  {
    active: false,
    img: 'safety_gloves',
    title: 'Safety Gloves',
    name: 'Safety Gloves',
    isStandard: true,
  },
  {
    active: false,
    img: 'shoes',
    title: 'Shoes',
    name: 'Shoes',
    isStandard: true,
  },
  {
    active: false,
    img: 'earplugs_or_muffs',
    title: 'Earplugs or Muffs',
    name: 'Earplugs or Muffs',
    isStandard: true,
  },
  {
    active: false,
    img: 'vests',
    title: 'Vests',
    name: 'Vests',
    isStandard: true,
  },
  {
    active: false,
    img: 'gloves',
    title: 'Gloves',
    name: 'Gloves',
    isStandard: true,
  },
  {
    active: false,
    img: 'hard_hats',
    title: 'Hard Hats',
    name: 'Hard Hats',
    isStandard: true,
  },
  {
    active: false,
    img: 'Safety_glasses',
    title: 'Safety Glasses',
    name: 'Safety Glasses',
    isStandard: true,
  },
  {
    active: false,
    img: 'Respirator',
    title: 'Respirator',
    name: 'Respirator',
    isStandard: true,
  },
  {
    active: false,
    img: 'Face_Shield',
    title: 'Face Shield',
    name: 'Face Shield',
    isStandard: true,
  },
  {
    active: false,
    img: 'Harness',
    title: 'Harness',
    name: 'Harness',
    isStandard: true,
  },
  {
    active: false,
    img: 'full_body_suits',
    title: 'Full Body Suits',
  },
] as PPE[];
