import React from 'react';
import { styled, Switch, SwitchProps, useTheme } from '@mui/material';
import { red } from '@mui/material/colors';

export const StyledSwitch = (props) => {
  const theme = useTheme();

  const w = 46;
  const h = 24;
  const disabledOpacity = 0.4;

  const IOSSwitch = styled((props: SwitchProps) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      sx={{mr:'5px'}}
      {...props}
    />
  ))(({ theme }) => ({
    width: w,
    height: h,
    padding: '0.5px 0px 0.5px 0px',
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 4,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(23px)',
        color: theme.palette.primary.main,
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.common.white,
          opacity: 1,
          border: '1px solid ' + theme.palette.primary.main,
        },
        '& .MuiSwitch-thumb': {
          color: theme.palette.primary.main,
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
          color: theme.palette.primary.main,
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
          color: theme.palette.mode === 'light' ? theme.palette.primary.light : theme.palette.primary.dark,
          opacity: disabledOpacity,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          backgroundColor: theme.palette.primary.light,
          opacity: disabledOpacity,
          border: '1px solid ' + theme.palette.primary.dark,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#C12727',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.mode === 'light' ? red[600] : red[100],
        opacity: disabledOpacity,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        backgroundColor: '#C12727',
        opacity: disabledOpacity,
        border: '1px solid #C12727',
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: h - 8,
      height: h - 8,
      color: '#C12727',
    },
    '& .MuiSwitch-track': {
      borderRadius: h / 2,
      color: theme.palette.mode === 'light' ? red[600] : red[100],
      backgroundColor: theme.palette.common.white,
      border: '1px solid #C12727',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  return <IOSSwitch {...props} />;
};
