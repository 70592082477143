import * as React from 'react';
import Button from '@mui/material/Button';
import { styled, useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useMsal } from '@azure/msal-react';
import { FormControl, FormLabel, TextField } from '@mui/material';
import TrainerService from '../../../../services/trainer/TrainerService';
import { Toast } from '../../../Notifications/Notification';
import { getMessage } from '../../../../shared/utils/message-helpers';
import Loading from '../../../Loading/Loading';
import { AmplitudeEvents } from '../../../../shared/enums';
import { trackEvent } from '../../../../App';
import { useTranslation } from 'react-i18next';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: '12px 24px !important',
  },
  '& .MuiDialogActions-root': {
    padding: '0px 24px 24px',
  },
}));

interface Props {
  openDialog: boolean;
  closeDialog: () => void;
  procedure: any;
}

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="title-margin" sx={{ width: '387px' }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function ManagerApproval(props: any) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState(0);

  const { accounts } = useMsal();
  const [additionalComments, setAdditionalComments] = React.useState('');
  const theme = useTheme();
  const { t } = useTranslation();
  const name = accounts[0] && accounts[0].name;
  const oid = (accounts[0] && accounts[0].idTokenClaims && accounts[0].idTokenClaims.oid) || '';

  const tabs = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const handleChange = (e: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    setValue(props.action);
  }, [props.action]);
  
  useEffect(() => {
    setOpen(props.openDialog);
  }, [props.openDialog]);

  const refreshPage = () => {
    window.location.reload();
  };

  const handleClose = () => {
    props.closeDialog();
    setOpen(false);
  };

  const publishVersion = async () => {
    const res = await TrainerService.publishProcedure(props.procedure.procedureId);
    return res;
  };

  const handleApprove = async (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      procedureId: props.procedure.procedureId,
      approval: value === 0 ? true : false,
      comments: additionalComments,
    };
    let res;
    TrainerService.managerApproveProcedure(payload)
      .then(async (response) => {
        if (payload.approval === true) {
          trackEvent(AmplitudeEvents.APPROVE_PROCEDURE, {
            procedure_id: props.procedure.procedureId,
            version_id: props.procedure.versionId,
            approved_by: localStorage.getItem('email'),
          });
          res = await publishVersion();
          if(res.data.message.toLowerCase().includes('user not found')){
            Toast.error('Publish to ETQ failed', 'User is not present in ETQ system. Please create an account');
            await new Promise(resolve => setTimeout(resolve, 2000));
          }
          if(res.data.message.includes('Publish to integration system failed due to error :')){
            Toast.error(t('INTEGRATION_PUBLISH_FAILED', { ns: 'error' }), res.data.message.substring(res.data.message.indexOf(':') + 1));
            await new Promise(resolve => setTimeout(resolve, 2000));
          }
          if (res.status !== 200) {
            Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
            await new Promise(resolve => setTimeout(resolve, 2000));
          }
        } else {
          trackEvent(AmplitudeEvents.REJECT_PROCEDURE, {
            procedure_id: props.procedure.procedureId,
            version_id: props.procedure.versionId,
            rejected_by: localStorage.getItem('email'),
          });
        }
        setLoading(false);
        handleClose();
        refreshPage();
      })
      .catch(({ response }) => {
        setLoading(false);
        handleClose();
        refreshPage();
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
      });
  };

  const handleadditionalComments = () => (event) => {
    setAdditionalComments(event.target.value);
  };

  const updateStyles = {
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    minHeight: '32px !important',
    maxHeight: '32px !important',
    border: `1px solid ${theme.palette.primary.main}`,
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      opacity: '1',
    },
  };

  return (
    <div>
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} className="version-dialog">
        {loading && <Loading />}
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        {t('ADD_USER.APPROVE/REJECT_PROCEDURE')}
        </BootstrapDialogTitle>
        <form onSubmit={handleApprove}>
          <DialogContent dividers style={{ border: 'none' }}>
            <Box component="div" sx={{ borderColor: 'divider' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                TabIndicatorProps={{
                  style: {
                    display: 'none',
                  },
                }}
              >
                <Tab
                  sx={{
                    ...updateStyles,
                    borderTopLeftRadius: '8px',
                    borderBottomLeftRadius: '8px',
                  }}
                  label={t('ADD_USER.APPROVE')}
                />
                <Tab
                  sx={{
                    ...updateStyles,
                    borderTopRightRadius: '8px',
                    borderBottomRightRadius: '8px',
                  }}
                  label={t('ADD_USER.REJECT')}
                />
              </Tabs>
              <FormControl required sx={{ width: '100%' }}>
                <FormLabel className="dialog-header" required>
                  {t('ADD_USER.ADDITIONAL_COMMENTS')}
                </FormLabel>
                <TextField
                  id="outlined-basic"
                  multiline
                  required
                  FormHelperTextProps={{
                    style: {
                      display: 'flex',
                      justifyContent: 'end',
                    },
                  }}
                  onChange={handleadditionalComments()}
                  sx={{
                    width: '100% !important',
                    margin: '0px',
                  }}
                  inputProps={{
                    style: {
                      height: '90px',
                    },
                  }}
                  variant="outlined"
                />
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" sx={{ borderRadius: '8px' }} type="submit">
              {' '}
              {value === 0 ? t('ADD_USER.APPROVE') : t('ADD_USER.REJECT')}{' '}
            </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
}
