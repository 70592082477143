import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Box, Button, Card, CardContent, Grid, Step, StepLabel } from '@mui/material';
import { FormikTouched, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { IOrganizationFormValues, toOrganizationFormValues, toOrganizationPayload } from '.';
import Loading from '../../components/Loading/Loading';
import { Toast } from '../../components/Notifications/Notification';
import { StyledCardDetailsView } from '../../components/Styled/StyledCardDetailsView';
import { StyledStepper } from '../../components/Styled/StyledStepper';
import { IIdp, IOrganization } from '../../entities/Organization/Organization';
import OrganizationService from '../../services/Organization/OrganizationService';
import { RegExpConstants } from '../../shared/utils/const-helpers';
import AddressForm from './AddressForm';
import ContactForm from './ContactForm';
import DetailForm from './DetailForm';
import { useTranslation } from 'react-i18next';

interface OrganizationFormProps {
  organization: Partial<IOrganization>;
  onSubmit: (organization: IOrganization) => void;
  onBack: () => void;
  isLoading: boolean;
}
const steps = ['Details', 'Address', 'Contacts'];

const OrganizationForm: React.FC<OrganizationFormProps> = (props) => {
  const [loading, setLoading] = useState(true);
  const [activeStep, setActiveStep] = React.useState(0);
  const [idps, setIdps] = useState<IIdp[]>();
  const [organizations, setOrganizations] = useState<IOrganization[]>([]);
  const { t } = useTranslation();

  const organizationFormValidationSchema = Yup.object().shape({
    detailForm: Yup.object({
      name: Yup.string()
        .required(t('ORGANIZATION.NAME_IS_REQUIRED'))
        .test(t('ORGANIZATION.UNIQUE_ORGANIZATION_NAME'), t('ORGANIZATION.ORGANIZATION_NAME_ALREADY_IN_USE'), (value) => checkAlreadyInUse('name', value)),
      alias: Yup.string()
        .required(t('ORGANIZATION.ALIAS_IS_REQUIRED'))
        .test(t('ORGANIZATION.UNIQUE_ORGANIZATION_ALIAS'), t('ORGANIZATION.ORGANIZATION_ALIAS_IN_USE'), (value) => checkAlreadyInUse('alias', value)),
      idp: Yup.string().required(t('ORGANIZATION.IDP_IS_REQUIRED')),
    }),
    addressForm: Yup.object({
      addressLine1: Yup.string().required(t('ORGANIZATION.ADDRESS_LINE1_IS_REQUIRED')),
      country: Yup.string().required(t('ORGANIZATION.COUNTRY_IS_REQUIRED')),
      state: Yup.string().required(t('ORGANIZATION.STATE_IS_REQUIRED')),
      city: Yup.string().required(t('ORGANIZATION.CITY_IS_REQUIRED')),
      postalCode: Yup.string().required(t('ORGANIZATION.POSTAL_CODE_IS_REQUIRED')),
      location: Yup.object({
        latitude: Yup.number().nullable().min(-90, t('ORGANIZATION.LATITUDE_RANGE_-90_TO_90')).max(90, t('ORGANIZATION.LATITUDE_RANGE_-90_TO_90')),
        longitude: Yup.number().nullable().min(-180, t('ORGANIZATION.LONGITUDE_RANGE_-180_TO_180')).max(180, t('ORGANIZATION.LONGITUDE_RANGE_-180_TO_180')),
      }),
    }),
    contactForm: Yup.object({
      emailAddress: Yup.string().email(t('ORGANIZATION.INVALID_EMAIL_ADDRESS')).required(t('ORGANIZATION.EMAIL_ADDRESS_IS_REQUIRED')),
      phoneNumber: Yup.string().matches(RegExpConstants.phone, t('ORGANIZATION.INVALID_PHONE_NUMBER')).required(t('ORGANIZATION.PHONE_NUMBER_IS_REQUIRED')),
      alternateNumber: Yup.string().matches(RegExpConstants.phone, t('ORGANIZATION.INVALID_PHONE_NUMBER')).nullable(),
      additionalContacts: Yup.array(
        Yup.object({
          designation: Yup.string().required(t('ORGANIZATION.DESIGNATION_IS_REQUIRED')),
          firstName: Yup.string().required(t('ORGANIZATION.FIRST_NAME_IS_REQUIRED')),
          lastName: Yup.string().required(t('ORGANIZATION.LAST_NAME_IS_REQUIRED')),
          emailAddress: Yup.string().email(t('ORGANIZATION.INVALID_EMAIL_ADDRESS')).required(t('ORGANIZATION.EMAIL_ADDRESS_IS_REQUIRED')),
          phoneNumber: Yup.string().matches(RegExpConstants.phone, t('ORGANIZATION.INVALID_PHONE_NUMBER')).required(t('ORGANIZATION.PHONE_NUMBER_IS_REQUIRED')),
          alternateNumber: Yup.string().nullable().matches(RegExpConstants.phone, t('ORGANIZATION.INVALID_PHONE_NUMBER')),
        }),
      ),
    }),
  });

  const formik = useFormik({
    initialValues: toOrganizationFormValues(props.organization),
    validationSchema: organizationFormValidationSchema,
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  // Initialize
  useEffect(() => {
    getIdps();
    getAllOrganizations();

    return () => {};
  }, []);

  // Loading from Add/Edit forms
  useEffect(() => {
    setLoading(props.isLoading);

    return () => {};
  }, [props.isLoading]);

  useEffect(() => {
    if (props.organization && props.organization.id) {
      try {
        const formValues = toOrganizationFormValues(props.organization);
        formik.validateForm(formValues).then((e) => {
          formik.setErrors(e);
          formik.setTouched(e as FormikTouched<IOrganizationFormValues>);
        });
      } catch (error) {
        console.log('Formix mount error: ', error);
      }
    }
  }, [props.organization]);

  const getIdps = () => {
    setLoading(true);
    OrganizationService.getAllIdps()
      .then((e) => {
        const {
          data: { data: idpList },
        } = e;
        setIdps(idpList);
        setLoading(false);
      })
      .catch(({ response }) => {
        setLoading(false);
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
      });
  };

  const getAllOrganizations = () => {
    setLoading(true);
    OrganizationService.getAllOrganizations()
      .then((e) => {
        const {
          data: { data: organizationList },
        } = e;
        setOrganizations(organizationList);
        setLoading(false);
      })
      .catch(({ response }) => {
        setLoading(false);
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
      });
  };

  const checkAlreadyInUse = (name: string, value: string | undefined): boolean => {
    if (!props?.organization?.id && organizations && organizations.length && value) {
      const result = organizations.filter((o) => o[name].toLowerCase() === value.toLowerCase());
      return result && result.length === 0;
    }
    return true;
  };

  const handleNext = () => {
    if (activeStep !== steps.length - 1) {
      setActiveStep((step) => step + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((step) => step - 1);
  };

  const handleLocateInMapClick = () => {
    // ! Call an API to get longitude and latitude based on address1, address1, country, state, city, zipCode
    console.log('🚀 ~ file: OrganizationForm.tsx ~ line 123 ~ handleLocateInMapClick');
  };

  const submitHandler = (organizationData) => {
    organizationData.preventDefault();
    const formData = toOrganizationPayload(formik.values);
    props.onSubmit(formData);
  };

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return idps && idps.length && <DetailForm formik={formik} idps={idps} />;
      case 1:
        return <AddressForm formik={formik} onLocateInMapClick={handleLocateInMapClick} />;
      case 2:
        return <ContactForm formik={formik} />;
      default:
        throw new Error(t('ORGANIZATION.UNKNOWN_STEP'));
    }
  }

  function getStepName(step: number) {
    switch (step) {
      case 0:
        return 'Details';
      case 1:
        return 'Address';
      case 2:
        return 'Contacts';
      default:
        throw new Error(t('ORGANIZATION.UNKNOWN_STEP'));
    }
  }

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <>
          <Grid container spacing={3} sx={{ pl: 3 }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              lg={4}
              sx={{
                '&.MuiGrid-item': { paddingTop: '1rem' },
                paddingRight: { xs: 3, md: 0 },
              }}
            >
              <StyledStepper orientation="horizontal" activeStep={activeStep} sx={{ ml: 1 }}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </StyledStepper>
            </Grid>
          </Grid>
          <StyledCardDetailsView>
            <Box
              component="div"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                textAlign: 'left',
              }}
            >
              <CardContent sx={{ flex: '1 0 auto', p: 0 }} className="organization-form-content">
                <Box component={'div'} sx={{ padding: '1rem' }}>
                  <Box component="h2" sx={{ m: 0, p: 2, pt: 1, pb: 1 }}>
                    {getStepName(activeStep)}
                  </Box>
                </Box>
                <Box component="hr" sx={{}}></Box>

                <Box component={'div'} sx={{ padding: '1rem' }}>
                  <Box
                    component="form"
                    sx={{
                      padding: { xs: 0, lg: 2 },
                      width: '100%',
                      textAlign: 'left',
                    }}
                    onSubmit={submitHandler}
                  >
                    <Box component={'div'} sx={{ minHeight: '30rem' }}>
                      {getStepContent(activeStep)}
                    </Box>
                    <Box
                      component="div"
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        paddingRight: { xs: 0, lg: 4 },
                      }}
                    >
                      {activeStep !== 0 && (
                        <Button variant="outlined" size="small" onClick={handleBack} sx={{ mt: 3, ml: 1, minWidth: 'fit-content' }}>
                          <ArrowBackIosNewIcon />
                        </Button>
                      )}
                      {activeStep !== steps.length - 1 && (
                        <Button variant="contained" onClick={handleNext} sx={{ mt: 3, ml: 1 }}>
                          {t('BUTTON.NEXT')}
                        </Button>
                      )}
                      {activeStep === steps.length - 1 && (
                        <Button variant="contained" type="submit" sx={{ mt: 3, ml: 1 }} disabled={!formik.isValid}>
                          {t('BUTTON.SAVE')}
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Box>
              </CardContent>
            </Box>
          </StyledCardDetailsView>
        </>
      )}
    </>
  );
};

export default OrganizationForm;
