import {
  Box, FormControl, FormHelperText, FormLabel, Grid, InputLabel, MenuItem,
  Select, TextField, ToggleButton,
  ToggleButtonGroup
} from '@mui/material';
import { FormikProps } from 'formik';
import { IOrganizationFormValues } from '.';
import { IIdp } from '../../entities/Organization/Organization';
import { MaxLengthConstants } from '../../shared/utils/const-helpers';

interface DetailFormProps {
  formik: FormikProps<IOrganizationFormValues>;
  idps: Array<IIdp>;
}

const DetailForm: React.FC<DetailFormProps> = ({ formik, idps }) => {
  // handler for isActive ToggleButtonGroup
  const handleIsActiveChange = (event: any, status: boolean) => {
    // manually update formik
    formik.setFieldValue('detailForm.isActive', status);
  };

  return (
    <Box component={'div'} sx={{ paddingRight: '32px' }}>
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          sx={{ marginBottom: { xs: 0, md: 2 } }}
        >
          <FormControl variant="standard" required fullWidth>
            <FormLabel>Organization Name</FormLabel>
            <TextField
              disabled={Boolean(formik.values.detailForm.id)}
              label=""
              name="detailForm.name"
              value={formik.values.detailForm.name}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              placeholder="Enter Organization Name"
              style={{ borderRadius: '8px !important' }}
              inputProps={{ maxLength: MaxLengthConstants.Organization.name }}
              required
              error={Boolean(
                formik?.touched?.detailForm?.name &&
                  formik?.errors?.detailForm?.name,
              )}
            />
            {!Boolean(
              formik?.touched?.detailForm?.name &&
                formik?.errors?.detailForm?.name,
            ) && (
              <FormHelperText className="helperText">
                {MaxLengthConstants.Organization.name -
                  formik.values.detailForm.name.length}{' '}
                Characters Remaining
              </FormHelperText>
            )}
            {Boolean(
              formik?.touched?.detailForm?.name &&
                formik?.errors?.detailForm?.name,
            ) && (
              <FormHelperText className="helperText" error={true}>
                {formik?.errors?.detailForm?.name}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          sx={{ marginBottom: { xs: 0, md: 2 } }}
        >
          <FormControl variant="standard" required fullWidth>
            <FormLabel>Alias</FormLabel>
            <TextField
              disabled={Boolean(formik.values.detailForm.id)}
              label=""
              name="detailForm.alias"
              value={formik.values.detailForm.alias}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              placeholder="Enter Alias"
              style={{ borderRadius: '8px !important' }}
              inputProps={{ maxLength: MaxLengthConstants.Organization.alias }}
              required
              error={Boolean(
                formik?.touched?.detailForm?.alias &&
                  formik?.errors?.detailForm?.alias,
              )}
            />
            {!Boolean(
              formik?.touched?.detailForm?.alias &&
                formik?.errors?.detailForm?.alias,
            ) && (
              <FormHelperText className="helperText">
                {MaxLengthConstants.Organization.alias -
                  formik.values.detailForm.alias.length}{' '}
                Characters Remaining
              </FormHelperText>
            )}
            {Boolean(
              formik?.touched?.detailForm?.alias &&
                formik?.errors?.detailForm?.alias,
            ) && (
              <FormHelperText className="helperText" error={true}>
                {formik?.errors?.detailForm?.alias}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          sx={{ marginBottom: { xs: 0, md: 2 } }}
        >
          <FormControl fullWidth required>
            <FormLabel>IDP</FormLabel>
            <Select
              name="detailForm.idp"
              value={formik.values.detailForm.idp}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              displayEmpty
              required
              error={Boolean(
                formik?.touched?.detailForm?.idp &&
                  formik?.errors?.detailForm?.idp,
              )}
            >
              <MenuItem disabled value="">
                Select IDP
              </MenuItem>
              {idps &&
                idps.length &&
                idps.map((idp) => (
                  <MenuItem key={idp.id} value={idp.id}>
                    {idp.name}
                  </MenuItem>
                ))}
            </Select>
            {Boolean(
              formik?.touched?.detailForm?.idp &&
                formik?.errors?.detailForm?.idp,
            ) && (
              <FormHelperText className="helperText" error={true}>
                {formik?.errors?.detailForm?.idp}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{ marginBottom: { xs: 0, md: 2 } }}
        >
          <FormControl variant="standard" fullWidth>
            <FormLabel>Description</FormLabel>
            <TextField
              label=""
              name="detailForm.description"
              value={formik.values.detailForm.description}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              placeholder="Enter Description"
              style={{ borderRadius: '8px !important' }}
              inputProps={{
                maxLength: MaxLengthConstants.Organization.description,
              }}
              required
              error={Boolean(
                formik?.touched?.detailForm?.description &&
                  formik?.errors?.detailForm?.description,
              )}
            />
            {!Boolean(
              formik?.touched?.detailForm?.description &&
                formik?.errors?.detailForm?.description,
            ) && (
              <FormHelperText className="helperText">
                {MaxLengthConstants.Organization.description -
                  formik.values.detailForm.description.length}{' '}
                Characters Remaining
              </FormHelperText>
            )}
            {Boolean(
              formik?.touched?.detailForm?.description &&
                formik?.errors?.detailForm?.description,
            ) && (
              <FormHelperText className="helperText" error={true}>
                {formik?.errors?.detailForm?.description}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          sx={{ marginBottom: { xs: 2, lg: 0 } }}
        >
          <InputLabel id="detailForm.isActive" sx={{ mb: 1 }}>
            Status
          </InputLabel>
          <ToggleButtonGroup
            id="detailForm.isActive"
            color="primary"
            value={formik.values.detailForm.isActive}
            exclusive
            onChange={handleIsActiveChange}
          >
            <ToggleButton
              sx={{
                color: 'primary.main',
                bgcolor: '#ffffff',
                border: '1px solid',
                lineHeight: '0.7',
                width: '5rem',
                '&.Mui-selected': {
                  bgcolor: 'primary.main',
                  color: '#ffffff',
                  borderColor: 'primary.main',
                },
                '&.Mui-selected:hover': {
                  bgcolor: 'primary.main',
                  color: '#ffffff',
                },
              }}
              value={true}
            >
              Active
            </ToggleButton>
            <ToggleButton
              sx={{
                color: 'primary.main',
                bgcolor: '#ffffff',
                border: '1px solid',
                lineHeight: '0.7',
                width: '5rem',
                '&.Mui-selected': {
                  bgcolor: 'primary.main',
                  color: '#ffffff',
                  borderColor: 'primary.main',
                },
                '&.Mui-selected:hover': {
                  bgcolor: 'primary.main',
                  color: '#ffffff',
                },
              }}
              value={false}
            >
              Inactive
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DetailForm;
