import axios from 'axios';
import { environment } from '../../environment/environment';
const fm = environment.AppConfigs.fileManagerApi;

const getFileBlob = (fileId: string) => {
    return axios({
      url: `${fm}/v1/files/${fileId}`,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => response.data);
  }
  
  const getObjectUrlByFileId = (fileId: string, fileType: string) => {
    if (!fileId) return Promise.reject('File Id is not provided');
    return getFileBlob(fileId)
      .then((blob) => {
        const blobToConvert = fileType ? new Blob([blob], { type: fileType }) : blob;
        let objectUrl = URL.createObjectURL(blobToConvert);
        return objectUrl;
      })
      .catch((err) => {
        console.log('Error fetching or converting file:', err);
        return '';
      });
  }

  const FileHelper = {
    getObjectUrlByFileId,
  };

  export default FileHelper;