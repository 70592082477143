import { Breadcrumbs, Typography } from "@mui/material"
import { useEffect } from "react"
import { useNavigate, Link as RouterLink } from "react-router-dom"
import { UserGroup } from "../../entities/User/MockUser"
import UserGroupForm from "./UserGroupForm"
import Link, { LinkProps } from "@mui/material/Link"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import { useTranslation } from "react-i18next"

interface AddUserGroupProps {}

interface LinkRouterProps extends LinkProps {
  to: string
  replace?: boolean
}

const LinkRouter = (props: LinkRouterProps) => (
  <Link {...props} component={RouterLink as any} />
)

const AddUserGroup: React.FC<AddUserGroupProps> = (props) => {
  const { t } = useTranslation();
  const breadcrumbs = [
    <Link underline="hover" key="1" sx={{ opacity: "0.7" }}>
      {t('NAVBAR.ADMINISTRATION') as string}
    </Link>,
    <Link underline="hover" key="2" sx={{ opacity: "0.7" }}>
       {t('NAVBAR.USER_MANAGEMENT') as string}
    </Link>,
    <LinkRouter
      underline="hover"
      key="3"
      to="/user-group"
      sx={{ opacity: "0.7" }}
    >
      {t('NAVBAR.USER_GROUPS') as string}
    </LinkRouter>,
    <Typography key="4" color="primary.main">
      {t('ADD_USER.CREATE_NEW_USER_GROUP') as string}
    </Typography>,
  ]
  let navigate = useNavigate()

  const addGroupHandler = (groupData: UserGroup) => {
  }

  const backHandler = () => {
    navigate("/group")
  }
  return (
    <>
      {" "}
      <Breadcrumbs
        sx={{ margin: "2rem" }}
        separator={<NavigateNextIcon color="primary" fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
      <UserGroupForm onBack={backHandler} onSubmit={addGroupHandler} />
    </>
  )
}

export default AddUserGroup
