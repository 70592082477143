import { Search } from '@mui/icons-material';
import { Box, Button, FormControl, FormControlLabel, Grid, InputAdornment, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, ToggleButton, ToggleButtonGroup, Typography, useTheme } from '@mui/material';
import BorderAllRoundedIcon from '@mui/icons-material/BorderAllRounded';
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import AddIcon from '@mui/icons-material/Add';
import Loading from '../../components/Loading/Loading';
import React, { useEffect, useState } from 'react';
import './UserGroupList.scss';
import FuzzySearch from 'fuzzy-search';
import { useParams, useNavigate } from 'react-router-dom';
import { UserGroup } from '../../entities/User/MockUser';
import UserGroupCard from './UserGroupCard';
import { Access } from '../../components/Auth/Access';
import { UserGroupsPermissions } from '../../shared/permissions';
import { useHasPermissions } from '../../hooks/useHasPermissions';
import UserGroupService from '../../services/UserGroup/UserGroupService';
import { StyledAvatarRecordCount } from '../../components/Styled/StyledAvatarRecordCount';
import { StyledGridView } from '../../components/Styled/StyledGridView';
import { Toast } from '../../components/Notifications/Notification';
import { getMessage } from '../../shared/utils/message-helpers';
import { StyledSwitch } from '../../components/Styled/StyledSwitch';
import { ActionMenu } from '../../components/ActionMenu/ActionMenu';
import { AmplitudeEvents } from '../../shared/enums';
import { useTranslation } from 'react-i18next';
import { trackEvent, useDocumentTitle } from '../../App';

interface UserGroupListProps {}

const UserGroupList: React.FC<UserGroupListProps> = (props) => {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [layout, setLayout] = useState<string | null>('card');
  const handleLayout = (event: React.MouseEvent<HTMLElement>, newLayout: string | null) => {
    if (newLayout !== null) {
      setLayout(newLayout);
    }
  };
  const [sort, setSort] = useState<string>('createdDate');
  const [filter, setFilter] = useState<string>('');
  const [groups, setGroups] = useState<UserGroup[]>();
  const [filteredGroups, setFilteredGroups] = useState<UserGroup[]>();
  const [statusUpdating, setStatusUpdating] = useState({});
  const [userGroups, setUserGroups] = useState<Array<any>>([]);
  const [list, setList] = useState(false);

  const sortOptions = [
    {
      key: 'createdDate',
      name: 'Added On',
    },
    {
      key: 'name',
      name: 'Name',
    },
  ];

  const { companyId } = useParams();
  useDocumentTitle('CDS Mentor User Groups');

  const canUpdate = useHasPermissions(UserGroupsPermissions.UPDATE);
  const canDelete = useHasPermissions(UserGroupsPermissions.DELETE);

  const handleStatusChange = async (flag: boolean, record: any): Promise<boolean> => {
    const payload = { ...record, isActive: flag };
    let statusUpdateDataChange = { ...statusUpdating };
    statusUpdateDataChange[record.id] = true;
    setStatusUpdating(statusUpdateDataChange);
    return UserGroupService.updateUserGroup(payload.id, payload)
      .then((e) => {
        record.isActive = flag;
        setUserGroups([...userGroups]);
        Toast.success(t('USER_GROUP.USER_GROUP'), `${t('USER_GROUP.USER_GROUP_STATUS_IS')} ${flag ? t('USER_GROUP.ENABLED') : t('USER_GROUP.DISABLED')} ${t('USER_GROUP.SUCCESSFULLY')}.`);
        statusUpdateDataChange[record.id] = false;
        setStatusUpdating(statusUpdateDataChange);
        return Promise.resolve(false);
      })
      .catch(({ response }) => {
        statusUpdateDataChange = { ...statusUpdating };
        statusUpdateDataChange[record.id] = false;
        setStatusUpdating(statusUpdateDataChange);
        // setStatusUpdating((state) => state[record.id] = false);
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        return Promise.resolve(false);
      });
  };

  const getAllUserGroupsData = () => {
    UserGroupService.getAllUserGroups()
      .then((e) => {
        const {
          data: { data: userGroupList },
        } = e;

        setUserGroups(userGroupList);
        setFilteredGroups(userGroupList);
        setLoading(false);
        const statusUpdateData = {};
        userGroupList.map((o) => {
          statusUpdateData[o.id] = false;
          return o;
        });
        setStatusUpdating(statusUpdateData);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllUserGroupsData();
  }, [list]);

  const filterUserGroupList = () => {
    if (filter) {
      const filteredUserGroups = userGroups.filter((x) => x.isActive === (filter === 'active' ? true : false));
      setFilteredGroups(filteredUserGroups);
    } else {
      setFilteredGroups(userGroups);
    }
  };

  useEffect(() => {
    filterUserGroupList();
  }, [filter, userGroups]);

  const sortBy = (key) => {
    if (groups) {
      groups.sort((a, b) => a[key].localeCompare(b[key]));
      setGroups(groups as UserGroup[]);
    }
  };

  const search = (key: string) => {
    if (userGroups && key) {
      const searcher = new FuzzySearch(userGroups, ['name', 'identifier'], {
        caseSensitive: false,
      });
      const result = searcher.search(key);
      result.length > 0 ? setFilteredGroups(result) : setFilteredGroups(groups);
    } else {
      setFilteredGroups(userGroups);
    }
    setFilter('');
  };
  const theme = useTheme();

  const handleAdd = () => {
    navigate('/user-group/add');
  };
  const handleEdit = (id) => {
    navigate('/user-group/edit/' + id);
  };
  const handleDelete = (group) => {
    // handle delete
    setLoading(true);
    UserGroupService.deleteUserGroup(group.id || 'null')
      .then((e) => {
        setList(!list);
        trackEvent(AmplitudeEvents.USER_GROUP_DELETED, { deleted_by: localStorage.getItem('email'), group_name: group.name });
        setLoading(false);
        Toast.success(t('OPERATION_SUCCESS', { ns: 'success' }), t(e.data.messageCode, { ns: 'success' }) || e.data.message);
      })
      .catch(({ response }) => {
        setLoading(false);
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
      });
  };

  const { t } = useTranslation();

  return (
    <>
      {loading && <Loading />}
      <div className="group-list-container">
        <Paper elevation={0} className="group-title-container" style={{ marginTop: '1rem' }}>
          <Box component="div" className="left">
            <Typography variant="h6" component="div" className="page-title">
              {t('USER_GROUP.USER_GROUPS') as string}
            </Typography>
            {groups && <StyledAvatarRecordCount>{groups.length}</StyledAvatarRecordCount>}
          </Box>
          <Access permission={UserGroupsPermissions.CREATE}>
            <Box component="div" className="right">
              <Button className="activeButton nonActiveButton" variant="contained" onClick={handleAdd}>
                <Box component={'div'} sx={{ display: { xs: 'none', md: 'flex' } }}>
                  {t('USER_GROUP.CREATE_NEW') as string}
                </Box>
                <Box component={'div'} sx={{ display: { xs: 'flex', md: 'none' } }}>
                  <AddIcon />
                </Box>
              </Button>
            </Box>
          </Access>
        </Paper>

        <Paper elevation={0} className="group-title-container">
          <Box component="div" className="left">
            <ToggleButtonGroup size="small" color="primary" value={layout} exclusive onChange={handleLayout} aria-label="layout">
              <ToggleButton
                sx={{
                  color: 'primary.main',
                  bgcolor: '#ffffff',
                  border: '1px solid',
                  lineHeight: '0.7',
                  width: '2.5rem',
                  '&.Mui-selected': {
                    bgcolor: 'primary.main',
                    color: '#ffffff',
                    borderColor: 'primary.main',
                  },
                  '&.Mui-selected:hover': {
                    bgcolor: 'primary.main',
                    color: '#ffffff',
                  },
                }}
                value="card"
                aria-label="card"
              >
                <BorderAllRoundedIcon />
              </ToggleButton>
              <ToggleButton
                sx={{
                  color: 'primary.main',
                  bgcolor: '#ffffff',
                  border: '1px solid',
                  lineHeight: '0.7',
                  width: '2.5rem',
                  '&.Mui-selected': {
                    bgcolor: 'primary.main',
                    color: '#ffffff',
                    borderColor: 'primary.main',
                  },
                  '&.Mui-selected:hover': {
                    bgcolor: 'primary.main',
                    color: '#ffffff',
                  },
                }}
                value="list"
                aria-label="list"
              >
                <FormatListBulletedRoundedIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Box component="div" className="right">
            <Box component="div" sx={{ display: 'flex', alignItems: 'flex-end' }} className="search-btn">
              <FormControl fullWidth>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search sx={{ color: 'primary.main', mr: 1, my: 0.5 }} />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={t('COMMON.SEARCH')}
                  onChange={(e) => search(e.target.value)}
                />
              </FormControl>
            </Box>
            <FormControl
              sx={{
                m: 1,
                minWidth: 120,
                display: { xs: 'none', md: 'flex' },
              }}
            >
              <Select value={sort} onChange={(e) => sortBy(e.target.value)} size="small" displayEmpty inputProps={{ 'aria-label': 'Without label' }}>
                <MenuItem value={'createdDate'}>
                  {' '}
                  <em>{t('USER.SORT')}</em>{' '}
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl
              sx={{
                m: 1,
                mr: 0,
                minWidth: 120,
                display: { xs: 'none', md: 'flex' },
              }}
            >
              <Select value={filter} onChange={(e) => setFilter(e.target.value)} size="small" displayEmpty inputProps={{ 'aria-label': 'Without label' }}>
                <MenuItem value="">
                  {' '}
                  <em>{t('COMMON.FILTER')}</em>{' '}
                </MenuItem>
                <MenuItem value={'active'}>{t('USER.ACTIVE')}</MenuItem>
                <MenuItem value={'inactive'}>{t('USER.INACTIVE')}</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Paper>
        {layout === 'card' && (
          <StyledGridView>
            {filteredGroups &&
              filteredGroups.map((group, index) => {
                return (
                  <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                    <UserGroupCard group={group} onEdit={handleEdit} onDelete={handleDelete} canUpdate={canUpdate} canDelete={canDelete} onStatusChange={handleStatusChange} isLoading={statusUpdating[group.id]} />
                  </Grid>
                );
              })}
          </StyledGridView>
        )}
        {layout === 'list' && (
          <div className="wrapper">
            <TableContainer component={Paper} sx={{ borderRadius: '0', boxShadow: 'none' }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">{t('USER_GROUP.GROUP_NAME')}</TableCell>
                    <TableCell align="left">{t('USER_GROUP.NO_OF_USERS')}</TableCell>
                    <TableCell align="left">{t('USER_GROUP.DESCRIPTION')}</TableCell>
                    <TableCell align="left">{t('USER_GROUP.STATUS')}</TableCell>
                    <TableCell align="center">{t('USER_GROUP.ACTIONS')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredGroups &&
                    filteredGroups.map((group, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                          '&:nth-of-type(odd)': {
                            backgroundColor: theme.palette.action.hover,
                          },
                        }}
                      >
                        <TableCell align="left">{group.name}</TableCell>
                        <TableCell align="left">{(group as any).users?.length ?? 0}</TableCell>
                        <TableCell align="left">{group.description}</TableCell>
                        <TableCell align="left">
                          <FormControlLabel className="active" control={<StyledSwitch name="isActive" checked={group.isActive} color={group.isActive ? 'primary' : 'error'} onChange={(e) => handleStatusChange(e.target.checked, group)} />} label={group.isActive ? t('USER_GROUP.ACTIVE') : t('USER_GROUP.INACTIVE')} />
                        </TableCell>
                        <TableCell align="center">
                          <ActionMenu onEdit={() => handleEdit(group.id)} onDelete={() => handleDelete(group.id)} editPermission={UserGroupsPermissions.UPDATE} deletePermission={UserGroupsPermissions.DELETE} />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </div>
    </>
  );
};

export default UserGroupList;
