import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import './BasicDialog.scss';
import { useMsal } from '@azure/msal-react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Breakpoint, Button, DialogActions, DialogTitle } from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: '24px',
    minWidth: '358px',
  },
  '& .MuiDialogActions-root': {
    padding: '0px 24px 24px 24px',
  },
}));

interface Props {
  openApprovalDialog: boolean;
}

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}
interface defaultProps {
  title?: string;
  onClose?: any;
  onOpen?: any;
  actions?: any;
  children?: any;
  id?: string;
  fullWidth?: boolean,
  maxWidth?: false | Breakpoint | undefined,
  additionalProp?: any;
  components ?: {
    DialogTitle ?: any
  }
}

export const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <>
      <DialogTitle
        className="remove-margin"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '24px !important',
          minWidth: '400px',
          
          
        }}
        {...other}
      >
        {children}

        <IconButton
          aria-label="close"
          onClick={() => onClose()}
          sx={{
            color: (theme) => theme.palette.grey[500],
            padding: 0,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
    </>
  );
};


export const BasicDialog = (props: defaultProps) => {
  const { title, onClose, onOpen, actions, children ,additionalProp} = props;
  const [open, setOpen] = React.useState(false);
  const { accounts } = useMsal();
  const name = accounts[0] && accounts[0].name;
  return (
    <BootstrapDialog fullWidth={props.fullWidth} maxWidth={props.maxWidth} onClose={onClose} aria-labelledby="customized-dialog-title" open={onOpen} disableEnforceFocus id={props.id ? `dialogue-${props.id}` : ''}>
      {title && (
        <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
          <Typography sx={{ fontSize: '20px' }}>{title}</Typography>
        </BootstrapDialogTitle>
      )}
      {props.components?.DialogTitle && React.cloneElement(props.components.DialogTitle)}

      <DialogContent id={props.id ? `dialogue-content-${props.id}` : ''}>{children}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </BootstrapDialog>
  );
};
