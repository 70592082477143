import * as msal from '@azure/msal-browser';
import axios, { AxiosRequestConfig, CanceledError } from 'axios';
import { msalInstance } from '..';
import { backendConfig, getMsalConfig, loginRequest } from '../authConfig';
import { Toast } from '../components/Notifications/Notification';
import { environment } from '../environment/environment';
import i18n from '../../src/i18';

const env = environment.AppConfigs.env ?? 'dev';
const isProduction = ['uat', 'prod'].includes(env.toLowerCase());

const handleError = async (error: any) => {
  if(error instanceof CanceledError) return;

  const { data } = error?.response;
  const errorMessage = data?.errorMessage || data?.error 

  if (error.response.data.messageCode === 'UNAUTHORIZED_ACCESS_ERROR') {
    window.setTimeout(function () {
      window.location.href = '/';
    }, 3000);
  }
console.log(error,"error")
  if (error.response.data.messageCode === 'INACTIVE_USER_ERROR' && error.config.url.includes("/users/get/username/")) {
    
    sessionStorage.clear();
    localStorage.clear();
    await msalInstance.initialize();
    msalInstance.logoutRedirect();
  }

  if (errorMessage && errorMessage?.toLowerCase() === 'user not found') {
    sessionStorage.clear();
    localStorage.clear();
    await msalInstance.initialize();
    msalInstance.logoutRedirect();
    localStorage.removeItem('activeId');
  }

  if (!isProduction) {
    try {
      let url = '';
      let message = 'API request failed!';
      const response = error?.response;
      if (response) {
        url = `${response.config.method.toUpperCase()}: ${response.config.baseURL}${response.config.url}`;
        message = `${response.request.status} ${response.request.statusText}`;
        const {
          data: { errorMessage },
        } = response;
        if (errorMessage) {
          message = errorMessage;
        }
      }
      const notifyMessage = `${message}|[Source] ${url}`;
      const notifyTitle = `[${env.toUpperCase()}][DEBUG][API ERROR]`;
      if (!(url.includes('type=cad/Thumbnail') && url.includes('search/files'))) Toast.error(i18n.t('OPERATION_FAILED', { ns: 'error' }), i18n.t(response?.data?.messageCode, { ns: 'error' }) || response?.data?.errorMessage);
    } catch (error) {
      console.log('[http interceptor] - error: ', error);
    }
  }
};

const onFulfilledRequestHandler = async (config: AxiosRequestConfig<any>) => {
  const opco = localStorage.getItem('opco');
  await msalInstance.initialize();
  // WARNING: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/2799#issuecomment-754954450
  // Please verify that you are not re-initializing msal at any point
  // as these network calls will be made on the first token request after initialization.
  // When using React, you'll need to be careful to not call the PublicClientApplication constructor
  // on re-renders as that will cause everything in msal-react to re-render in addition to these network calls.
  // ----------------------------------------------------------------------
  // const msalObj = new msal.PublicClientApplication(getMsalConfig(opco, email));
  // let currentAccounts = msalObj.getAllAccounts();
  const currentAccounts = msalInstance.getAllAccounts();
  const request = {
    ...loginRequest,
    account: currentAccounts[0],
  };
  if (opco) {
    request['extraQueryParameters'] = { opco: opco };
  }
  const accessToken = await msalInstance
    .acquireTokenSilent(request)
    .then((response: any) => {
      // console.log(response.accessToken);
      return response.accessToken;
    })
    .catch((e) => {
      console.log(e, 'errr');
    });
  if (accessToken && config && config.headers) {
    config.headers['Authorization'] = 'Bearer ' + accessToken;
  }
  if (config && config.headers) {
    config.headers['Content-Type'] = 'application/json';
  }
  return config;
};

const onRejectedRequestHandler = async (error: any) => {
  handleError(error);
  return Promise.reject(error);
};

const onFulfilledResponseHandler = async (config: any) => {
  return config;
};

const onRejectedResponseHandler = async (error: any) => {
  handleError(error);
  return Promise.reject(error);
};

const onFulfilledPartnerRequestHandler = async (config: AxiosRequestConfig<any>, accessToken) => {
  if (accessToken && config && config.headers) {
    config.headers['Authorization'] = 'Bearer ' + accessToken;
  }
  if (config && config.headers) {
    config.headers['Content-Type'] = 'application/json';
  }
  return config;
};

const createAxios = (baseUrl: string, accessToken?: string) => {
  const axiosInstance = axios.create({
    baseURL: baseUrl,
    headers: {
      'Content-type': 'application/json',
    },
  });

  if (accessToken) {
    axiosInstance.interceptors.request.use((config) => onFulfilledPartnerRequestHandler(config, accessToken), onRejectedRequestHandler);
  } else {
    axiosInstance.interceptors.request.use(onFulfilledRequestHandler, onRejectedRequestHandler);
  }

  axiosInstance.interceptors.response.use(onFulfilledResponseHandler, onRejectedResponseHandler);

  return axiosInstance;
};

export const secureAccessToken = async (accessToken) => {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);

  const options = {
    method: 'GET',
    headers: headers,
  };

  return fetch(backendConfig.backendEndpoint, options)
    .then((response) => response.json())
    .catch(({ response }) => console.error(response));
};

// Instances
export const newAxiosInstance = createAxios(environment.AppConfigs.BackendURL);
export const rbacAxiosInstance = createAxios(environment.AppConfigs.rbacUrl);
export const fmAxiosInstance = createAxios(environment.AppConfigs.fileManagerApi);
export const localhostAxiosInstance = createAxios('https://localhost:3001/api'); // for dev only

export const partnerInstance = (accessToken) => {
  return createAxios(environment.AppConfigs.BackendURL, accessToken);
};

export default createAxios(environment.AppConfigs.BackendURL);
