import { Box, Typography, TableContainer, Table, TableBody, TableRow, TableCell, useTheme } from '@mui/material';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import './IntroStepContainer.scss';
import { Fragment } from 'react';
import StepsPpeList from './StepsPpeList';
import { secondsToHms } from '../Analytics/ProcedureReports/TraineeTime';
import moment from 'moment';
import { getFormattedDateFromUnixTime } from '../../shared/utils/formateDate';

interface props {
  introStep?: any;
  procedure?: any;
  workInstructionName?: string;
}

export const IntroStepContainer = ({ introStep, procedure, workInstructionName }: props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const rows = [
    {
      name: t("ADD_WI.PROCEDURE_NAME"),
      value: procedure.procedureName,
    },
    {
      name: t("ADD_WI.WORK_INSTRUCTION_NAME"),
      value: workInstructionName,
    },
    {
      name: t("ADD_WI.CYCLE_TIME"),
      value: secondsToHms(procedure.estimated_time) || '0s',
    },
    {
      name: t("PROCEDURE_HISTORY.PUBLISHED_BY"),
      value: procedure.publisher,
    },
    {
      name: t("PROCEDURE_HISTORY.PUBLISHED_DATE"),
      value: procedure.publishedOn
        ? getFormattedDateFromUnixTime(procedure.publishedOn)
        : null,
    },
    {
      name: t("PROCEDURE.CARD_VERSION"),
      value: procedure.isPublished ? procedure.version :  t("ANALYTICS_SECTION.DRAFT"),
    },
  ];

  return (
    <Box component="div" className="intro-container">
      {introStep === 1 ? (
        <>
          <TableContainer className="intro-table">
            <Table aria-label="simple table">
              <TableBody>
                {rows.map((row) => (
                  <Fragment key={row.name}>
                    {(row.value || row.value === 0) && (
                      <TableRow>
                        <TableCell component="th" scope="row" sx={{ color: theme.palette.primary.main, fontSize: '14px', border: '1px solid rgba(28, 29, 31, 0.10)', width: '30%' }}>
                          {row.name}
                        </TableCell>
                        <TableCell sx={{ fontSize: '14px', border: '1px solid rgba(28, 29, 31, 0.10)', width: '70%' }} align="left">
                          {row.value}
                        </TableCell>
                      </TableRow>
                    )}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <>
          <Typography className="intro-section-text" sx={{ mb: '8px' }}>
            {procedure?.ppeTitle ? `${procedure.ppeTitle}` : `Safety manual and requirements`}
          </Typography>
          <Typography className="section-text">
            {procedure?.ppeDescription?`${procedure.ppeDescription}`:`${t('3D_VIEWER.SAFETY_MANUAL')}. ${t('3D_VIEWER.WEAR_SAFETY_EQUIPMENT')}.`}
          </Typography>
          <StepsPpeList />
        </>
      )}
    </Box>
  );
};
