import { Box, Button, Grid, Paper, styled, Typography, useTheme } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Loading from '../../../components/Loading/Loading';
import { Toast } from '../../../components/Notifications/Notification';
import TrainerService from '../../../services/trainer/TrainerService';
import { getMessage } from '../../../shared/utils/message-helpers';
import './ProcedureHistory.scss';
import { BasicDialog } from '../../../components/Trainer/Dialog/BasicDialog/BasicDialog';
interface Props {
  children?: ReactNode;
  active: boolean;
  selectedVersion: string;
  id: string;
  getVersionDetails: (id) => void;
}

const Version: React.FC<Props> = (props) => {
  const theme = useTheme();

  const VersionCard = styled('div')({
    color: theme.palette.primary.main,
    opacity: props.selectedVersion && props.selectedVersion.length > 0 ? (props.selectedVersion === props.id ? 1 : 0.5) : props.active ? 1 : 0.5,
  });

  return (
    <VersionCard>
      <Button sx={{ fontSize: '20px' }} onClick={() => props.getVersionDetails(props.id)}>
        {props.children}
      </Button>
    </VersionCard>
  );
};

const LeftProcedureHistory = (props) => {
  const theme = useTheme();
  const themePrimaryExtraLight = theme.palette.primary.main + '0d';

  const { t } = useTranslation();

  return (
    <>
      <Box sx={{ backgroundColor: themePrimaryExtraLight }} component="div" className={`left-container`}>
        <div className="version-list">
          {props.versionList.map((version, key) => {
            return (
              <Version key={key} active={version.active} getVersionDetails={props.getVersionDetails} selectedVersion={props.currentVersionId} id={version.id}>
                {t('PROCEDURE.CARD_VERSION')} {version.name}
                <br></br>
              </Version>
            );
          })}
        </div>
        <Button variant="outlined" onClick={props.openPreview} className="version-buttons">
          {t('BUTTON.PREVIEW')}
        </Button>
        <Button
          variant="contained"
          className="version-buttons"
          disabled={props.restoreIsActive}
          onClick={() => {
            if (props.procedure && !props.procedure.isPublished) {
              props.setShowConfirmation(true);
            } else {
              props.restoreVersion();
            }
          }}
          sx={{
            '&:disabled': {
              color: 'white',
              backgroundColor: theme.palette.primary.main,
              opacity: '0.5',
            },
          }}
        >
          {t('BUTTON.RESTORE')}
        </Button>
      </Box>
    </>
  );
};

const RightProcedureHistory = (props) => {
  const AssignedTraineesCard = styled(Paper)(({ theme }) => ({
    padding: '16px',
    backgroundColor: '#F8F7F7',
    textAlign: 'center',
    borderRadius: '8px',
    color: '#1C1D1F',
    opacity: 0.7,
    fontSize: '14px',
  }));

  let changeLog = props.versionDetails?.changelog;
  let stepsLog = props.versionDetails?.stepsLog;
  let revisitLog = props.versionDetails?.revisitLogs;
  let referenceDocumentLog = props.versionDetails?.changelog?.publishDetails?.refDocLog;

  const date = new Date((props.versionDetails?.createdOn as any) * 1000).toLocaleString('en', { day: 'numeric', month: 'short', year: 'numeric' });

  const { t } = useTranslation();

  return (
    <Box sx={{ backgroundColor: 'white' }} component="div" className={`right-container`}>
      <Typography
        component="div"
        variant="h6"
        className="right-header"
        sx={{
          marginTop: '0px !important',
        }}
      >
        {t('PROCEDURE_HISTORY.ASSIGNMENT_LOG')}
      </Typography>

      <Typography component="div" variant="subtitle1" className="right-sub-header">
        {/* 23rd MAY 2022: */}
        <Typography component="span" className="style-text" sx={{ marginLeft: '8px' }}>
          {changeLog?.assignmentLog?.length} {t('PROCEDURE_HISTORY.TRAINEES')}
        </Typography>
      </Typography>

      <Grid container spacing={2}>
        {changeLog?.assignmentLog?.map((trainee, key) => {
          return (
            <Grid item xs={6} md={4} key={key}>
              <AssignedTraineesCard>{trainee}</AssignedTraineesCard>
            </Grid>
          );
        })}
      </Grid>

      {referenceDocumentLog?.length > 0 && (
        <Typography component="div" variant="h6" className="right-header">
          {t('PROCEDURE_HISTORY.REFERENCE_DOCUMENTS_LOGS')}
        </Typography>
      )}

      {referenceDocumentLog?.map((doc, key) => {
        return (
          <>
            <Typography component="div" variant="subtitle1" className="right-sub-header" key={key}>
              {doc?.fileName}
            </Typography>
            {doc.createdBy && (
              <Typography component="div" variant="subtitle1" className="right-sub-header" key={key}>
                {new Date((doc.createdOn as any) * 1000).toLocaleString('en', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                })}
                <Box component="span" className="style-text">
                  {t('PROCEDURE_HISTORY.CREATED_BY')} {doc.createdBy}
                </Box>
              </Typography>
            )}
            {doc?.deletedBy && (
              <Typography component="div" variant="subtitle1" className="right-sub-header" key={key}>
                {new Date((doc.deletedOn as any) * 1000).toLocaleString('en', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                })}
                <Box component="span" className="style-text">
                  {t('PROCEDURE_HISTORY.DELETED_BY')} {doc.deletedBy}
                </Box>
              </Typography>
            )}
          </>
        );
      })}

      {revisitLog?.length > 0 && (
        <Typography component="div" variant="h6" className="right-header">
          Periodic Review Details
        </Typography>
      )}
      {revisitLog?.map((log, key) => {
        return (
          <>
            <Typography component="div" variant="subtitle1" className="right-sub-header" key={key}>
              {new Date((log.lastRevisitedOn as any) * 1000).toLocaleString('en', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              })}
              <Box component="span" className="style-text">
                {log.stepsAdded} {log.revisitApproved ? 'Approved by ' : 'Rejected by '}
                {log.revisitedBy}
              </Box>
            </Typography>
            <Typography component="span" className="style-text" sx={{ marginBottom: '8px' }}>
              Review Details
            </Typography>
            <Typography component="span" sx={{ marginBottom: revisitLog.length - 1 === key ? '8px' : '24px' }}>
              {log.revisitComments}
            </Typography>
          </>
        );
      })}

      <Typography component="div" variant="h6" className="right-header">
        {t('PROCEDURE_HISTORY.PUBLISHING_DETAILS')}
      </Typography>
      <Typography component="div" variant="subtitle1" className="right-sub-header">
        {new Date((changeLog?.publishDetails?.approvedOn as any) * 1000).toLocaleString('en', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        })}
        <Box component="span" className="style-text">
          {t('PROCEDURE_HISTORY.APPROVED_BY')} {changeLog?.publishDetails?.approvingManager}
        </Box>
      </Typography>

      {changeLog?.publishDetails?.approvalComments && (
        <>
          <Typography component="span" className="style-text" sx={{ marginBottom: '8px' }}>
            {t('PROCEDURE_HISTORY.APPROVAL_COMMENTS')}
          </Typography>
          <Typography component="span" variant="body2" sx={{ marginBottom: '16px' }} className="style-text">
            {changeLog?.publishDetails?.approvalComments}
          </Typography>
        </>
      )}

      <Typography component="div" variant="subtitle1" className="right-sub-header">
        {new Date((changeLog?.publishDetails?.publishedOn as any) * 1000).toLocaleString('en', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        })}
        <Box component="span" className="style-text">
          {t('PROCEDURE_HISTORY.PUBLISHED_BY')} {changeLog?.publishDetails?.publishedBy}
        </Box>
      </Typography>

      <Typography component="span" className="style-text" sx={{ marginBottom: '8px' }}>
        {t('PROCEDURE_HISTORY.RELEASE_DETAILS')}
      </Typography>

      <Typography component="span" variant="body2" sx={{ textTransform: 'capitalize' }} className="style-text">
        {changeLog?.publishDetails?.updateType} {t('PROCEDURE_HISTORY.UPDATE')}
        <br />
        {changeLog?.publishDetails?.comments}
      </Typography>

      <Typography component="div" variant="h6" className="right-header">
        {t('PROCEDURE_HISTORY.STEPS_LOG')}
      </Typography>

      {stepsLog ? (stepsLog.length < 1 ? `0 ${t('PROCEDURE_HISTORY.STEPS')}` : '') : `0 ${t('PROCEDURE_HISTORY.STEPS')}`}
      {stepsLog?.map((step, key) => {
        return (
          <Typography component="div" variant="subtitle1" className="right-sub-header" key={key}>
            {new Date((step.modifiedOn as any) * 1000).toLocaleString('en', {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
            })}
            <Box component="span" className="style-text">
              {step.stepsAdded} {t('PROCEDURE_HISTORY.STEP')}
              {step.stepsAdded > 1 && 's'} {t('PROCEDURE_HISTORY.ADDED_BY')} {step.modifiedBy}
            </Box>
          </Typography>
        );
      })}

      <Typography component="div" variant="h6" className="right-header">
        {t('PROCEDURE_HISTORY.CREATION_DETAILS')}
      </Typography>
      <Typography component="div" variant="subtitle1" className="right-sub-header">
        <>
          {date}
          <Box component="span" className="style-text">
            {t('PROCEDURE_HISTORY.PROCEDURE_CREATED_BY')} {props.versionDetails?.createdBy}
          </Box>
        </>
      </Typography>
    </Box>
  );
};

const ProcedureHistoryTitle = (props) => {
  const commonStyles = {
    border: 1,
    borderColor: 'grey.500',
  };

  const { t } = useTranslation();

  return (
    <Paper className="title-container" elevation={0} sx={{ mt: '2px', mb: '5px' }}>
      <Typography component="div" variant="h6" sx={{ marginBottom: '0.2rem' }}>
        {t('PROCEDURE_HISTORY.GUIDE')} {props.procedureName}
      </Typography>
      <Typography
        component="div"
        variant="overline"
        sx={{
          textTransform: 'none',
        }}
      >
        <Box component="span" className="style-text" sx={{ ...commonStyles }}>
          {props.versionList ? props.versionList.length : 0} {t('PROCEDURE_HISTORY.VERSIONS_FOUND')}
        </Box>
        <Box component="span" className="style-text" sx={{ ...commonStyles }}>
          {t('PROCEDURE_HISTORY.ACTIVE_VERSION')} {props.activeVersionName}
        </Box>
      </Typography>
    </Paper>
  );
};

const ProcedureHistory: React.FC = (props) => {
  const [versionList, setVersionList] = useState(Array<any>);
  const [currentVersionId, setCurrentVersionId] = useState('');
  const [activeVersionName, setActiveVersionName] = useState('');
  const [activeVersionId, setActiveVersionId] = useState('');
  const [versionDetails, setVersionDetails] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [restoreIsActive, setRestoreIsActive] = useState(true);
  const [procedure, setProcedure] = useState<any>();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const { companyId, workInstructionId, id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { search: searchQuery } = useLocation();
  const query = new URLSearchParams(searchQuery);
  const wiType = query.get('workInstructionType');

  const openPreview = () => {
    navigate(`/assembly/${companyId}/${workInstructionId}/${id}?version=${currentVersionId}&workInstructionType=${wiType}`);
    window.location.reload();
  };

  const restoreVersion = () => {
    setLoading(true);
    TrainerService.restoreVersion(id, currentVersionId)
      .then((res) => {
        getActiveVersion(id);
        getProcedureById(id);
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (currentVersionId && activeVersionId) {
      setRestoreIsActive(currentVersionId === activeVersionId);
    }
  }, [currentVersionId, activeVersionId]);

  const getVersionDetails = (currentVersionId) => {
    if (!loading) setLoading(true);
    setCurrentVersionId(currentVersionId);

    TrainerService.getVersionDetails(currentVersionId)
      .then((res) => {
        setVersionDetails(res.data.data);
        setLoading(false);
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        setLoading(false);
      });
  };

  const getActiveVersion = (id) => {
    TrainerService.getActiveVersion(id)
      .then((res) => {
        setActiveVersionName(res.data.data.name);
        setActiveVersionId(res.data.data.id);
        setVersionDetails(res.data.data);
        setLoading(false);
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        setLoading(false);
      });
  };

  const getProcedureById = (id) => {
    TrainerService.getProcedureById(id)
      .then((response) => {
        setProcedure(response.data.data);
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
      });
  };

  useEffect(() => {
    setLoading(true);
    TrainerService.getProcedureVersionList(id).then((res) => {
      res.data.data.sort((a, b) => {
        const [aInt, aDec] = a.name.split('.').map(Number);
        const [bInt, bDec] = b.name.split('.').map(Number);
        
        if (aInt === bInt) {
            return aDec - bDec;
        } else {
            return aInt - bInt;
        }
    });
      setVersionList(res.data.data);
    });
    getProcedureById(id);

    getActiveVersion(id);
  }, [id]);

  return (
    <>
      {showConfirmation && (
        <BasicDialog
          onOpen={showConfirmation}
          onClose={() => setShowConfirmation(false)}
          title="Restore Procedure to previous version"
          actions={
            <>
              <Button
                onClick={() => {
                  setShowConfirmation(false);
                  restoreVersion();
                }}
                variant="contained"
                size="small"
                type="submit"
              >
                {t('BUTTON.RESTORE') as string}
              </Button>
            </>
          }
        >
          <Typography sx={{ width: '400px' }}>Restore will delete all unpublished changes. Are you sure you want to continue?</Typography>
        </BasicDialog>
      )}
      <Grid className="card-container" container>
        <Paper
          className="procedure-history-card"
          elevation={0}
          sx={{
            borderRadius: '12px',
            boxShadow: 'rgba(149, 157, 165, 0.5) 0px 8px 24px',
          }}
        >
          <ProcedureHistoryTitle versionList={versionList} activeVersionName={activeVersionName} procedureName={versionDetails?.procedureName} />

          <div className="body">
            {loading && <Loading />}

            <LeftProcedureHistory procedure={procedure} setShowConfirmation={setShowConfirmation} versionList={versionList} getVersionDetails={getVersionDetails} currentVersionId={currentVersionId} restoreIsActive={restoreIsActive} restoreVersion={restoreVersion} openPreview={openPreview} />

            <RightProcedureHistory versionDetails={versionDetails} />
          </div>
        </Paper>
      </Grid>
    </>
  );
};

export default ProcedureHistory;
