import React from "react";
import { useHasPermissions } from "../../hooks/useHasPermissions";

interface Props {
    children: JSX.Element;
    permission: string | Array<string>;
    requireAll?: boolean;
}

export const Access = (props: Props): JSX.Element | null => {
    const { permission, requireAll } = props;
    const hasAccess = useHasPermissions(permission, requireAll);
    return hasAccess ? props.children : null;
};
