import React from 'react';
import { Card, styled, useTheme } from '@mui/material';

export const StyledCardDetailsView = (props) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        display: 'flex',
        borderRadius: '12px',
        boxShadow: 'rgba(149, 157, 165, 0.5) 0px 8px 24px',
      }}
      elevation={5}
      style={{ margin: '2rem', height: '100%' }}
      {...props}
    >
      {props.children}
    </Card>
  );
};
