import {
  Box,
  CardContent,
  Typography,
  Tooltip,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
} from '@mui/material';
import React from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import './PersonaCard.scss';
import { StyledCard } from '../../components/Styled/StyledCard';
import { StyledSwitch } from '../../components/Styled/StyledSwitch';
import { IPersona } from '../../entities/Organization/Persona/Persona';

interface Props {
  persona: IPersona;
  canUpdate: boolean;
  onEdit: (personaId: String) => void;
  canDelete: boolean;
  onDelete: (personaId: String) => void;
  onStatusChange: (flag: boolean, persona: IPersona) => Promise<boolean>;
  isLoading: boolean;
}

const PersonaCard: React.FC<Props> = (props) => {
  const shortenDescription = (text) => {
    return text.length > 55 ? text.substring(0, 55) + '...' : text;
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    props.onEdit(props.persona.id!);
  };
  const handleDelete = () => {
    props.onDelete(props.persona.id!);
  };

  const handleStatusChange = async (flag: boolean, data: IPersona) => {
    await props.onStatusChange(flag, data);
  };

  return (
    <StyledCard minCardHeight={'230px'}>
      <Box
        component="div"
        sx={{ display: 'flex', flexDirection: 'column', p: 2, width: '100%' }}
      >
        <CardContent sx={{ flex: '1 0 auto', textAlign: 'left', pt: 0, pb: 0 }}>
          {/* <Stack
            sx={{
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "start",
              pb: 1,
              width: "fit-content",
            }}
            flexWrap={"wrap"}
          >
            <Chip
              size="small"
              label={props.persona.isActive ? "Active" : "Inactive"}
              variant="outlined"
              color={props.persona.isActive ? "success" : "error"}
            />
          </Stack> */}
          <Typography component="div" variant="h5" className="persona-text">
            {props.persona.name}
          </Typography>
          <Typography
            variant="subtitle2"
            color="text.secondary"
            component="div"
            className="persona-text"
          >
            {props.persona.alias}
          </Typography>
          <Tooltip title={props.persona.description} placement="bottom-start">
            <Typography
              variant="subtitle2"
              color="text.secondary"
              component="div"
              className="persona-text"
            >
              {shortenDescription(props.persona.description)}
            </Typography>
          </Tooltip>
        </CardContent>
        <Box
          component="div"
          sx={{
            display: 'flex',
            alignItems: 'center',
            pl: 1,
            pr: 1,
            width: '100%',
          }}
        >
          <Box
            component="div"
            sx={{
              display: 'flex',
              alignItems: 'center',
              pl: 1,
              justifyContent: 'flex-start',
              width: '100%',
            }}
          >
            {!props.isLoading ? (
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="isActive"
                    checked={props.persona.isActive}
                    color={props.persona.isActive ? 'primary' : 'error'}
                    onChange={(e) =>
                      handleStatusChange(e.target.checked, props.persona)
                    }
                  />
                }
                label={props.persona.isActive ? 'Active' : 'Inactive'}
              />
            ) : (
              <Skeleton sx={{ height: '32px', width: '50%' }} />
            )}
          </Box>
          <Box
            component="div"
            sx={{
              display: 'flex',
              alignItems: 'center',
              pr: 2,
              pb: 1,
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <IconButton
              size="small"
              onClick={handleClick}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              sx={{
                borderRadius: '5',
                border: '1px solid gray',
                p: 0,
                color: 'black',
              }}
            >
              <MoreHorizIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              {props.canUpdate && (
                <MenuItem onClick={handleEdit}>Edit</MenuItem>
              )}
              {props.canDelete && (
                <MenuItem onClick={handleDelete}>Delete</MenuItem>
              )}{' '}
            </Menu>
          </Box>
        </Box>
      </Box>
    </StyledCard>
  );
};

export default PersonaCard;
