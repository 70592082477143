import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, useTheme, Checkbox, Box, Avatar, Typography, Grid, TextField, FormControl, InputAdornment } from '@mui/material';
import { useEffect, useState } from 'react';
import { User } from '../../entities/User/MockUser';
import './UserForm.scss';
import FuzzySearch from 'fuzzy-search';
import { Search } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface UserFormProps {
  onSelectionChange: (ids: string[]) => void;
  users: Array<User>;
  userList: Array<string>;
}

const UserForm: React.FC<UserFormProps> = (props) => {
  const theme = useTheme();
  const [filteredUsers, setFilteredUsers] = useState<User[]>();
  const [selected, setSelected] = useState<string[]>([]);
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = filteredUsers ? filteredUsers.map((n) => n.id) : props.users.map((n) => n.id);
      setSelected(newSelecteds);
      props.onSelectionChange(newSelecteds);
      return;
    }
    setSelected([]);
    props.onSelectionChange([]);
  };
  const isSelected = (id: string) => selected && selected.indexOf(id) !== -1;
  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
    props.onSelectionChange(newSelected);
  };
  useEffect(() => {
    setFilteredUsers(props.users as User[]);
  }, [props.users]);
  useEffect(() => {
    setSelected(props.userList as string[]);
  }, [props.userList]);
  const search = (key: string) => {
    if (props.users) {
      const searcher = new FuzzySearch(props.users, ['firstName', 'lastName', 'email', 'persona', 'company'], {
        caseSensitive: false,
      });
      const result = searcher.search(key);
      result.length > 0 ? setFilteredUsers(result) : setFilteredUsers(props.users);
    }
  };
  const { t } = useTranslation();

  return (
    <>
      <Box component={'div'} sx={{ padding: '32px' }}>
        <Grid container spacing={4}>
          <Box component="div" className="user-group-container">
            <Box component="div" className="left">
              <Typography variant="h6" component="div" className="page-title">
                {t('ADD_USER_GROUP.SELECT_USERS') as string}
              </Typography>
            </Box>
            <Box component="div" className="right">
              <Box component="div" sx={{ display: 'flex', alignItems: 'flex-end' }} className="search-btn">
                <FormControl fullWidth>
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search sx={{ color: 'primary.main', mr: 1, my: 0.5 }} />
                        </InputAdornment>
                      ),
                    }}
                    placeholder={t('COMMON.SEARCH')}
                    style={{ borderRadius: '8px !important' }}
                    onChange={(e) => search(e.target.value)}
                  />
                </FormControl>
              </Box>
              <Typography variant="h6" component="div" className="page-title" pr={2}>
                {t('ADD_USER_GROUP.SELECTED_USERS') as string}
              </Typography>
              {selected && (
                <Avatar
                  sx={{
                    bgcolor: 'neutral',
                    color: '#000000',
                    borderRadius: '15%',
                  }}
                >
                  {selected?.length}
                </Avatar>
              )}
            </Box>
          </Box>
          <TableContainer component={Paper} sx={{ borderRadius: '0', boxShadow: 'none' }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      indeterminate={selected && selected.length > 0 && selected.length < props.users.length}
                      checked={props.users && props.users.length > 0 && selected && (selected.length === props.users.length || selected.length === filteredUsers?.length)}
                      onChange={handleSelectAllClick}
                      inputProps={{
                        'aria-label': 'Select all users',
                      }}
                    />
                  </TableCell>
                  <TableCell align="left">{t('USER.FIRST_NAME').toLocaleUpperCase() as string}</TableCell>
                  <TableCell align="left">{t('USER.LAST_NAME').toLocaleUpperCase() as string}</TableCell>
                  <TableCell align="left">{t('ADD_USER.EMAIL').toLocaleUpperCase() as string}</TableCell>
                  <TableCell align="left">{t('ADD_USER.COMPANY').toLocaleUpperCase() as string}</TableCell>
                  <TableCell align="left">{t('ADD_USER_GROUP.USER_PERSONA').toLocaleUpperCase() as string}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredUsers &&
                  filteredUsers.map((user, index) => {
                    const isItemSelected = isSelected(user.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                          '&:nth-of-type(odd)': {
                            backgroundColor: theme.palette.action.hover,
                          },
                        }}
                        onClick={(event) => handleClick(event, user.id)}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        </TableCell>
                        <TableCell align="left">{user.firstName}</TableCell>
                        <TableCell align="left">{user.lastName}</TableCell>
                        <TableCell align="left">{user.email}</TableCell>
                        <TableCell align="left">{user.company}</TableCell>
                        <TableCell align="left">{user.persona}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Box>
    </>
  );
};

export default UserForm;
