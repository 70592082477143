import { Box, Card, Typography, useTheme } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { DemoIcon } from '../../Icons/CdsIcons';
import useTour from '../../../hooks/tour';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export function lighten(color, amount) {
    // Convert the color to an RGB array
    const rgb = color.match(/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i);
    const r = parseInt(rgb[1], 16);
    const g = parseInt(rgb[2], 16);
    const b = parseInt(rgb[3], 16);

    // Calculate the lighter RGB values
    const max = 255;
    const newR = Math.round(r + (max - r) * amount);
    const newG = Math.round(g + (max - g) * amount);
    const newB = Math.round(b + (max - b) * amount);

    // Convert the lighter RGB values back to a hex color code
    const newColor = `#${newR.toString(16)}${newG.toString(16)}${newB.toString(16)}`;
    return newColor;
}
interface Props {
    overview?: string;
}
const TourCard = (props: Props) => {
    const { isTourOpen, setIsTourOpen, tourStep, setTourStep, setTourTitle } = useTour();
    const theme = useTheme();

    const navigate = useNavigate();
    const { t } = useTranslation();

    return props.overview === 'table_view' ? (
        <Box
            onClick={() => {
                setIsTourOpen(true);
                setTourStep(0);
                navigate('/trainer/addWorkInstruction');
                setTourTitle('First work instruction & procedure setup');
            }}
            sx={{
                background: `linear-gradient(90deg, ${lighten(theme.palette.primary.main, 0.2)} 0%, ${theme.palette.primary.main} 98.9%)`,
                boxShadow: ' 0px 0px 40px rgba(26, 40, 53, 0.2)',
                color: 'white',
                borderRadius: '8px',
                cursor: 'pointer',
                justifyContent: 'space-between',
            }}
        >
            <Box
                component="span"
                sx={{ display: 'flex', flexDirection: 'row', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', padding: '3px 12px', color: 'black', width: '100%', justifyContent: 'space-between' }}
            >
                <Typography sx={{ fontSize: '14px', color: 'white', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography sx={{ fontSize: '14px', marginRight: '8px', marginTop: '4px' }}>
                        <DemoIcon />
                    </Typography>
                    <Typography sx={{ marginRight: '40px' }}>{t('WI_LIST.LEARN_HOW_TO_CREATE_WI')}</Typography>
                    <Typography
                        sx={{
                            fontSize: '14px',
                            opacity: 0.7,
                            color: 'white',
                        }}
                    >
                        {t('WI_LIST.DEMO_WALKTHROUGH')}
                    </Typography>
                </Typography>

                <Typography sx={{ display: 'flex', fontSize: '14px', color: 'white', alignItems: 'center' }}>
                    {t('WI_LIST.LEARN')} <ArrowForwardIcon sx={{ fontSize: '14px', color: 'white' }} />
                </Typography>
            </Box>
        </Box>
    ) : (
        <Card
            sx={{
                display: 'flex',
                borderRadius: '12px',
                height: props.overview === 'extended_view' ? '144px' : '280px',
                // width: props.overview === 'extended_view' ? '256px' : '400px',
                flexGrow: 1,
                background: `linear-gradient(90deg, ${lighten(theme.palette.primary.main, 0.2)} 0%, ${theme.palette.primary.main} 98.9%)`,
                boxShadow: ' 0px 0px 40px rgba(26, 40, 53, 0.2)',
                color: 'white',
                flexDirection: props.overview === 'extended_view' ? 'row' : 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                cursor: 'pointer',
            }}
            elevation={0}
            onClick={() => {
                setIsTourOpen(true);
                setTourStep(0);
                navigate('/trainer/addWorkInstruction');
                setTourTitle('First work instruction & procedure setup');
            }}
            className="work-instruction-card"
        >
            <Box
                component="div"
                sx={{
                    display: 'flex',
                    flexDirection: props.overview === 'extended_view' ? 'row' : 'column',
                    justifyContent: 'space-between',
                    margin: props?.overview === 'extended_view' ? '12px' : '24px',
                    textAlign: 'start',
                    gap: '10px',
                }}
            >
                <DemoIcon />

                <Box
                    component="div"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'start',
                    }}
                >
                    <Typography sx={{ fontSize: props?.overview === 'extended_view' ? '14px' : '20px' }}>{t('WI_LIST.LEARN_HOW_TO_CREATE_WI')}</Typography>
                    <Typography
                        sx={{
                            margin: props.overview === 'extended_view' ? '4px 0px 7px 0px' : '8px 0px 13px 0px',
                            fontSize: props.overview === 'extended_view' ? '12px' : '14px',
                            opacity: 0.7,
                        }}
                    >
                        {t('WI_LIST.DEMO_WALKTHROUGH')}
                    </Typography>
                    <Typography sx={{ display: 'flex', fontSize: props.overview === 'extended_view' ? '12px' : null, gap: '5px' }}>
                        {t('WI_LIST.LEARN')} <ArrowForwardIcon sx={{ fontSize: props.overview === 'extended_view' ? '14px' : null, color: 'white' }} />
                    </Typography>
                </Box>
            </Box>
        </Card>
    );
};

export default TourCard;
