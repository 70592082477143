export const demoSteps = [
  {
    title: 'Step by step sequence',
    description: ['Navigate through steps with Next and Previous buttons', 'Click Finish from last step to complete', 'Provide feedback at the end'],
    video: 'steps',
  },
  {
    title: 'View Annotations and Media',
    description: ['Click labels button to view all the labels created on model', 'Click media button to view attached images and videos'],
    video: 'view_media',
  },
  {
    title: 'View Modes',
    description: ['Explosion Mode to view exploded view created in studio', 'Solid outline will enable a solid outline on the model', 'Translucent mode will enable translucent view of the model'],
    video: 'view_modes',
  },
  {
    title: 'Rotate Mode',
    description: ['To rotate model use trihedron or mouse keys'],
    video: 'rotate_model',
  },
];
