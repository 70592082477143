import React, { useContext } from 'react';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import { DeleteCommentIcon, EditCommentIcon } from '../../../Icons/CdsIcons';
import { useTranslation } from 'react-i18next';
import UserContext from '../../../../context/UserContext';
import TraineeService from '../../../../services/trainee/TraineeService';
import { Toast } from '../../../Notifications/Notification';
interface Props {
  comment: any;
  threadId: any;
  listStepCommentThread: any;
  editComment: any;
  setEditComment: any;
  listAllThreads?: any;
}
const CommentCrud = (props: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleDeleteComment = () => {
    setAnchorEl(null);
    TraineeService.deleteStepComment(props.threadId, props.comment.commentId)
      .then((e) => {
        if (props.listAllThreads) {
          props.listAllThreads();
        } else {
          props.listStepCommentThread(props.threadId);
        }

        Toast.success(t('NAVBAR.COMMENTS'), t('COMMON.COMMENT_DELETED_SUCCESSFULLY'));
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || t(response.data.errorMessage, { ns: 'error' }));
      });
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const { t } = useTranslation();

  const { currentUser } = useContext(UserContext);

  return (
    <>
      {props.comment.userId === currentUser.userId && (
        <Box
          sx={{
            alignItems: 'center',
            justifyContent: 'left',
            position: 'absolute',
            top: '12px',
            right: '12px',
            justifyItems: 'end',
          }}
        >
          <IconButton
            size="small"
            onClick={handleClick}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            sx={{
              borderRadius: '5',
              p: 0,
              color: 'black',
            }}
          >
            <MoreHoriz />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={(e: any) => {
              e.stopPropagation();
              handleClose();
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={{ padding: '20px', alignContent: 'left', alignItems: 'left' }}
          >
            <MenuItem
              onClick={(e) => {
                props.setEditComment();
                e.stopPropagation();
              }}
            >
              <EditCommentIcon />
              &nbsp;{t('COMMON.EDIT')}
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                handleDeleteComment();
                e.stopPropagation();
              }}
            >
              <DeleteCommentIcon />
              &nbsp;{t('COMMON.DELETE')}
            </MenuItem>
          </Menu>
        </Box>
      )}
    </>
  );
};

export default CommentCrud;
