import { Alert, AlertTitle, Box, IconButton } from '@mui/material';
import { CustomContentProps, SnackbarContent, closeSnackbar } from 'notistack';
import React, { memo } from 'react';
import CloseIcon from '@mui/icons-material/Close';

export interface INotificationProps extends CustomContentProps {
    title: string;
    message: string;
}

const NotificationSnackbar = React.forwardRef<HTMLDivElement, INotificationProps>((props, forwardedRef) => {
    const { variant, message, title,id } = props;
    const severity = variant === 'default' ? 'info' : variant;
    const messages = message?.split('|');

    return (
        <SnackbarContent ref={forwardedRef}>
            <Alert
                severity={severity}
                sx={{ position: 'relative', paddingRight: '40px' }}
            >
                <AlertTitle sx={{ fontWeight: 'bold' }}>{title}</AlertTitle>
                {messages?.map((message, index) => (
                    <Box
                        key={index}
                        component="div"
                    >
                        {message}
                    </Box>
                ))}
                <IconButton
                    onClick={() => closeSnackbar(id)}
                    color="inherit"
                    sx={{
                        position: 'absolute',
                        top: '8px',
                        right: '8px',
                        minWidth: 'auto',
                        padding: 0.5,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </Alert>
        </SnackbarContent>
    );
});

export default memo(NotificationSnackbar);
