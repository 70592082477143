import axios from 'axios';
import http from '../http-common';
import { partnerInstance } from '../http-common';
import { IProcedures, IWorkInstructions } from '../../entities/Trainee/MockTrainee';

const config = {
  headers: {
    'content-type': 'multipart/form-data',
  },
};

const createWorkInstruction = (workInstructionFormData: any) => {
  return http.post<DataResponse<IWorkInstructions>>(`/workinstructions/createWorkInstruction`, workInstructionFormData);
};

const createProcedure = (procedureData: any) => {
  return http.post<DataResponse<IWorkInstructions>>(`/procedures/createProcedure`, procedureData);
};

const fileUpload = (file: any, config: any) => {
  return http.post<DataResponse<IWorkInstructions>>(`/file/cad/upload`, file, config);
};

const deleteProcedure = (procedureId: string) => {
  return http.delete<DataResponse<IWorkInstructions>>(`/procedures/deleteProcedure/${procedureId}`);
};

const deleteWorkInstruction = (workInstructionId: string) => {
  return http.delete<DataResponse<IWorkInstructions>>(`/workinstructions/deleteWorkInstruction/${workInstructionId}`);
};

const recyclebinProcedure = (procedureId: string) => {
  return http.delete<DataResponse<IWorkInstructions>>(`/procedures/deleteProcedure/${procedureId}?bin=true`);
};

const recyclebinWorkInstruction = (workInstructionId: string) => {
  return http.delete<DataResponse<IWorkInstructions>>(`/workInstructions/deleteWorkInstruction/${workInstructionId}?bin=true`);
};

const getProcedureById = (id: string, accessToken?: string | null) => {
  let instance = http;
  let url = '';
  if (accessToken) {
    instance = partnerInstance(accessToken);
    url = '/partnerapps/procedure';
  } else {
    url = `/procedures/getProcedure/${id}`;
  }
  return instance.get<DataResponse<IProcedures>>(url);
};

const getIntegrations = () => {
  return http.get<any>(`/organization/integrations`);
};

const getAvailableIntegrationTypes = () => {
  return http.get<any>(`/organization/getAvailableIntegrationTypes`);
};

const addIntegrationSystem = (integration) => {
  return http.post<any>(`/organization/addIntegrationSystem`, integration);
};

const updateIntegrationSystem = (integration) => {
  return http.put<any>(`/organization/editIntegrationSystem`, integration);
};

const testConnection = (payload, tokenGenerationUrl, headers) => {
  return axios.post<any>(tokenGenerationUrl, payload, { headers: headers });
};

const deleteIntegrationSystem = (integrationId) => {
  return http.delete<any>(`/organization/deleteIntegrationSystem/${integrationId}`);
};
const activateIntegrationSystem = (checked, id) => {
  return http.patch<any>(`/organization/activateIntegrationSystem/${id}`, { enabled: checked });
};

const editUserTour = (tourData: any) => {
  return http.put<DataResponse<any>>('/user/settings/guidetour', tourData);
};

const revokeAssignees = (procedureId: string, userData: any) => {
  return http.put<DataResponse<any>>(`/procedures/revoke/${procedureId}`, userData);
};

const editProcedure = (id: string, procedureData: any) => {
  return http.put<DataResponse<IWorkInstructions>>(`/procedures/editProcedure/${id}`, procedureData);
};

const reorderProcedure = (workInstructionId: string, procedureData: any) => {
  return http.put<DataResponse<any>>(`/procedures/reorder/${workInstructionId}`, procedureData);
};

const selfApproveProcedure = (procedureId: string, selfApproveProcedureBody: any) => {
  return http.post<DataResponse<any>>(`/approval/self/publish/${procedureId}`, selfApproveProcedureBody);
};

const getAllUsers = () => {
  return http.get<any>(`/user/users`);
};

const getManagerList = (personaId: string | null) => {
  return http.get<any>(`/user/persona/${personaId}`);
};

const sendManagerApproval = (procedureId: string, payload: any) => {
  return http.post<any>(`/approval/manager/send/${procedureId}`, payload);
};

const addRevisitConfiguration = (procedureId: string, payload: any) => {
  return http.post<any>(`/procedures/addRevisitConfiguration/${procedureId}`, payload);
};

const addRecertificationConfiguration = (procedureId: string, payload: any) => {
  return http.post<any>(`procedures/editRecertificationDetails/${procedureId}`, payload);
};

const assignProcedureToUsers = (request: any) => {
  return http.post<DataResponse<IWorkInstructions>>(`/procedures/assignUsers`, request);
};

const getStatus = (id: string) => {
  return http.get<DataResponse<IWorkInstructions>>(`/workinstructions/status/${id}`);
};

const publishProcedure = (id: string, integration = {}) => {
  return http.post<DataResponse<any>>(`/version/procedure/publish/${id}`, integration);
};

const refFileUpload = (file: any, config?: any) => {
  return http.post<DataResponse<IWorkInstructions>>(`/file/upload`, file, config);
};

const shareQR = (shareQRBody: any) => {
  return http.post<DataResponse<any>>(`/procedures/share`, shareQRBody);
};

const getUnassignedUser = (procedureId: string) => {
  return http.get<any>(`/user/unassigned/${procedureId}`);
};

const getUnassignedUsersFromUserGroup = (procedureId: string) => {
  return http.get<any>(`/usergroup/unassigned/${procedureId}`);
};

const editWorkInstruction = (workInstructionId: string, workInstructionData: any) => {
  return http.put<IWorkInstructions>(`/workinstructions/editWorkInstruction/${workInstructionId}`, workInstructionData);
};

const editStepComment = (threadId: string, data: any)=>{
  return http.put<any>(`/userStats/editStepComment/${threadId}`,data)
}

const getProcedureVersionList = (procedureId?: string) => {
  return http.get<any>(`/version/procedure/getversions/${procedureId}`);
};

const getActiveVersion = (procedureId?: string) => {
  return http.get<any>(`/version/procedure/current/${procedureId}`);
};

const getVersionDetails = (versionId?: string) => {
  return http.get<any>(`/version/procedure/getVersion/${versionId}`);
};

const restoreVersion = (procedureId?: string, versionId?: string, approvingManager?: string) => {
  return http.put<any>(`/version/procedure/${procedureId}/restore/${versionId}`, { approvingManager: localStorage.getItem('currentUserId') });
};

const getNewVersion = (procedureId?: string) => {
  return http.get<any>(`/version/procedure/new/${procedureId}`);
};

const getPendingApprovals = (startDate, endDate) => {
  if (startDate && endDate) {
    return http.get<any>(`/approval/manager/pending?start=${startDate}&end=${endDate}`);
  } else {
    return http.get<any>(`/approval/manager/pending`);
  }
};

const getPendingRevisits = () => {
  return http.get<any>('/procedures/getWorkInstructionsWithRevisitPendingProcedures');
};

const managerApproveProcedure = (payload: any) => {
  return http.post<any>(`/approval/manager/approve`, payload);
};

const createFolder = (payload: any) => {
  return http.post<any>(`/folder/create`, payload);
};

const editFolder = (folderId: string, payload: any) => {
  return http.put<any>(`/folder/edit/${folderId}`, payload);
};

const moveToFolder = (parentFolderId: string, payload: any) => {
  return http.put<any>(`/folder/moveTo/${parentFolderId}`, payload);
};

const folderWiContent = (folderId, page: number, pageSize: number, search?: string, sort?: string, filter?: string, signal?: any) =>{
  let url = `/folder/browse/${folderId}?page=${page}&pageSize=${pageSize}`;
  if (search) {
    url = url + `&search=${search}`;
  }
  if (sort) {
    url = url + `&sort=${sort}`;
  }
  if (filter) {
    url = url + `&filter=${filter}`;
  }

  return http.get<any>(url, { signal });
}

const folderContent = (folderId, page: number, pageSize: number, search?: string, sort?: string, filter?: string, signal?: any) =>{
  let url = `/folder/browseFolders/${folderId}?page=${page}&pageSize=${pageSize}`;
  if (search) {
    url = url + `&search=${search}`;
  }
  if (sort) {
    url = url + `&sort=${sort}`;
  }
  if (filter) {
    url = url + `&filter=${filter}`;
  }

  return http.get<any>(url, { signal });
}

const getBreadCrumbPath = (id: string, type: string) => {
  let url= `/folder/getPath/${id}?type=${type}`;
  return http.get<any>(url);
}

const deleteFolder = (folderId: string)=>{
  return http.delete<any>(`/folder/delete/${folderId}`);
}

const duplicateFolder = (payload: any)=>{
  return http.post<any>(`/folder/copy/`, payload);
}

const revisitApprovalProcedure = (procedureId: string, payload: any) => {
  return http.post<any>(`/procedures/approveOrRejectRevisit/${procedureId}`, payload);
};

const sendLogs = (payload: any) => {
  return http.post<any>(`/logs`, payload);
};

const approvalRequestsCount = () => {
  const url = `/approval/manager/notification`;
  return http.get<any>(url);
};

const revisitRequestsCount = () => {
  const url = '/procedures/getRevisitExpiredCount';
  return http.get<any>(url);
};

const certificateDueCount = () => {
  const url = '/procedures/getRecertificationPendingAndExpiredCount';
  return http.get<any>(url);
};

const getAllPPEEquipments = () => {
  const url = `/configurations/ppe/procedures/create`;
  return http.get<any>(url);
};

const deletePPEEquipment = (ppeId: string) => {
  return http.delete<DataResponse<any>>(`/configurations/ppe/${ppeId}`);
};

const createPPEEquipment = (ppeFormData: any) => {
  return http.post<DataResponse<any>>(`/configurations/ppe`, ppeFormData);
};

const editPPEEquipment = (ppeId: string, ppeFormData: any) => {
  return http.put<DataResponse<any>>(`/configurations/ppe/${ppeId}`, ppeFormData);
};

const getPPEEquipmentById = (ppeId: string) => {
  return http.get<DataResponse<any>>(`/configurations/ppe/${ppeId}`);
};

const getPPEUsedCount = (ppeId: string) => {
  return http.get<DataResponse<any>>(`/configurations/ppe/used/${ppeId}`);
};

const getPPEOfProcedure = (procedureId: string, accessToken?: string | null) => {
  let instance = http;
  let url = '';
  if (accessToken) {
    instance = partnerInstance(accessToken);
    url = '/partnerapps/ppe';
  } else {
    url = `/configurations/ppe/procedure/${procedureId}`;
  }
  return instance.get<DataResponse<any>>(url);
};

const getMagicLink = (procedureId: string) => {
  return http.get<DataResponse<any>>(`/procedures/magiclink/${procedureId}`);
};

const getPartnerWorkInstruction = (accessToken) => {
  return partnerInstance(accessToken).get<DataResponse<any>>('partnerapps/workinstruction');
};

const getSubscriptionDetails = () => {
  return http.get<DataResponse<any>>(`/subscription`);
};

const getAssignees = (procedureId) => {
  return http.get<DataResponse<any>>(`/user/assigned/${procedureId}`);
};
const bulkDeleteReferenceDoc = (workInstructionId, procedureId, reqBody) => {
  return http.post<DataResponse<any>>(`/file/deleteFile/${workInstructionId}/${procedureId}`, reqBody, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

const customThumbnail = (workInstructionId, reqBody) => {
  return http.post<any>(`/workinstructions/generateThumbnail/${workInstructionId}`, reqBody);
};

const deleteThumbnail =(workInstructionId) =>{
  return http.delete<any>(`/workinstructions/deleteThumbnail/${workInstructionId}`)
}

const exportProcedurePdf = (procedureId) => {
  return http.get<any>(`/procedures/export/pdf/${procedureId}`, {
    responseType: 'arraybuffer',
    headers: {
      Accept: 'application/pdf',
    },
  });
};

const cloneProcedure = (cloneProcedurePayload: Object) => {
  const url = `/procedures/clone`;
  return http.post<DataResponse<any>>(url, cloneProcedurePayload);
};

const cloneWorkInstruction = (cloneWorkInstructionPayload: Object) => {
  const url = `/workinstructions/clone`;
  return http.post<DataResponse<any>>(url, cloneWorkInstructionPayload);
};

const getNewFolderName = (parentFolderName = '') => {
  const url = `/folder/newFolderName/${parentFolderName}`;
  return http.get<DataResponse<string>>(url);
};

const saveIntegration = (procedureId:string, integrationId:string) => {
  const url = `procedures/${procedureId}/publishToIntegration/${integrationId}`;
  return http.patch<DataResponse<string>>(url, {});
}

const TrainerService = {
  createWorkInstruction,
  cloneWorkInstruction,
  createProcedure,
  sendLogs,
  addIntegrationSystem,
  editUserTour,
  fileUpload,
  deleteProcedure,
  deleteWorkInstruction,
  recyclebinProcedure,
  recyclebinWorkInstruction,
  getProcedureById,
  editProcedure,
  revokeAssignees,
  selfApproveProcedure,
  reorderProcedure,
  getAllUsers,
  assignProcedureToUsers,
  getStatus,
  refFileUpload,
  getUnassignedUser,
  getUnassignedUsersFromUserGroup,
  editWorkInstruction,
  cloneProcedure,
  deleteIntegrationSystem,
  getProcedureVersionList,
  getActiveVersion,
  getVersionDetails,
  restoreVersion,
  publishProcedure,
  shareQR,
  getNewVersion,
  getManagerList,
  sendManagerApproval,
  addRevisitConfiguration,
  getPendingApprovals,
  managerApproveProcedure,
  approvalRequestsCount,
  getAllPPEEquipments,
  deletePPEEquipment,
  createPPEEquipment,
  editPPEEquipment,
  getPPEEquipmentById,
  updateIntegrationSystem,
  getPPEUsedCount,
  getPPEOfProcedure,
  getMagicLink,
  getPartnerWorkInstruction,
  getSubscriptionDetails,
  exportProcedurePdf,
  getAssignees,
  bulkDeleteReferenceDoc,
  revisitRequestsCount,
  getPendingRevisits,
  revisitApprovalProcedure,
  addRecertificationConfiguration,
  certificateDueCount,
  getIntegrations,
  getAvailableIntegrationTypes,
  activateIntegrationSystem,
  testConnection,
  createFolder,
  editFolder,
  moveToFolder,
  folderWiContent,
  folderContent,
  getBreadCrumbPath,
  deleteFolder,
  duplicateFolder,
  getNewFolderName,
  saveIntegration,
  customThumbnail,
  deleteThumbnail,
  editStepComment
};
export default TrainerService;

export interface DataResponse<T> {
  status(status: any);
  workInstructionId: any;
  data: T;
  statusCode;
}
