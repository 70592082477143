export const RESOURCE_ACTIONS = {
  WORK_INSTRUCTION: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
  PROCEDURE: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
  STEPS: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
  ASSIGN_USERS: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
  PUBLISH: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
  STUDIO: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
  USER: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
  USERGROUPS: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
  CAD_FILES: ['UPLOAD', 'DOWNLOAD', 'UPDATE'],
  FILES: ['UPLOAD', 'DOWNLOAD'],
  ORGANIZATION: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
  PERSONA: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
  ACTIONS: ['READ'],
  RESOURCES: ['READ'],
  THEMES: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
};
