import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SUPPORTED_VIEWS } from "../../../shared/enums";
import { IWorkInstructions } from "../../../entities/Trainee/MockTrainee";
import { HOMEPAGE_DIALOG } from "../../../pages/Trainee/TraineeHome/constants";

type InitialState = {
    currentView: SUPPORTED_VIEWS,
    folders: any[],
    workInstructions: IWorkInstructions[],
    dialogToShow: HOMEPAGE_DIALOG | undefined
}
const initialState: InitialState = {
    currentView: SUPPORTED_VIEWS.WORK_INSTRUCTION,
    folders: [],
    workInstructions: [],
    dialogToShow: undefined
}


const homePageSlice = createSlice({
  name: 'homePage',
  initialState: initialState,
  reducers: {
    setCurrentView: (state, action) => {
      state.currentView = action.payload;
    },
    setFolders: (state, action) => {
      state.folders = action.payload
    },
    setWorkInstructions: (state, action) => {
      state.workInstructions = action.payload
    },
    setDialogToShowAction: (state, action) => {
      state.dialogToShow = action.payload
    },
  },
});

export default homePageSlice.reducer;
export const { setCurrentView, setFolders, setWorkInstructions, setDialogToShowAction } = homePageSlice.actions;