import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import languageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import moment from "moment";

// TODO: find a better way. Ideally, the locale which is in use that should only get imported.
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/de';
import 'moment/locale/zh-cn';
import 'moment/locale/pt-br';
import 'moment/locale/pl';

let changeLanguageFunction = i18n.changeLanguage;

i18n.changeLanguage = function(lng, callback) {
  if(lng === 'esp'){
    lng = 'es';
  }
  moment.locale(lng);
  return changeLanguageFunction(lng, callback);
}
i18n
.use(initReactI18next) // passes i18n down to react-i18next
.use(languageDetector)
.use(HttpApi)
.init({
  //resources,
  lng: "en",
  fallbackLng: "en",
  ns:['translation','error','success', 'demoTour'],
  detection: {
    order: ['htmlTag', 'cookie', 'localStorage', 'path', 'subdomain'],
    caches: ['cookie'],
  },

  

  //keySeparator: false, // we do not use keys in form messages.welcome

  interpolation: {
    escapeValue: false,
  },
  backend : {
    loadPath: '/locales/{{lng}}/{{ns}}.json',
  }
});

export default i18n;
