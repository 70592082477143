import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { IAppTheme } from '../../entities/Organization/Theme/Theme';
import usePpeList from '../../hooks/ppeList';
import { LanguageSupport, PPEEquipments, RenameTabsAndLabels, ThemeSupport, VersionUpdateMethod } from '../Icons/CdsIcons';
import Loading from '../Loading/Loading';
import { LanguageSection } from './LanguageSection';
import { PpeEquipments } from './PpeEquipments';
import { ThemeSupportSection } from './ThemeSupportSection';
import { RenameTabsandLabels } from './RenameTabsandLabels';
import { useTranslation } from 'react-i18next';
import { textAlign } from '@mui/system';

interface Props {
  show: boolean;
  isTrainer?: boolean;
  onClose: (isOpen: boolean) => void;
  onThemeChange: (appTheme: IAppTheme) => void;
  getPpeList?: () => void;
}

const SystemSettingsDialog: React.FC<Props> = ({ show, isTrainer, onClose, onThemeChange, getPpeList }) => {
  const [open, setOpen] = useState(false);
  const { systemSettingsTab } = usePpeList();
  const [value, setValue] = React.useState(1);
  const [loading, setLoading] = useState(false);
  const [languageState,setLanguageState] = useState<boolean>(true);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const themePrimaryExtraLight = theme.palette.primary.main + '0d';
  const tabStyles = {
    fontSize: '0.9rem',
    justifyContent: 'flex-start',
    height: '50px',
    minHeight: '50px',
    '& svg': {
      marginRight: 1,
    },
  };

  useEffect(() => {
    setOpen(show);
  }, [show]);

  useEffect(() => {
    setValue(systemSettingsTab);
  }, [systemSettingsTab]);

  useEffect(() => {
    if (isTrainer !== true) {
      setValue(0);
    }
  });

  const handleClose = () => {
    setOpen(false);
    onClose(false);
    if (getPpeList) {
      getPpeList();
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleLoading = (flag: boolean) => {
    setLoading(flag);
  };

  const { t } = useTranslation();

  const getTabContent = (tabIndex: number) => {
    if (isTrainer) {
      switch (tabIndex) {
        case 0: // Version Update Method
          return null;
          break;
        case 1: // Theme Support
          return <ThemeSupportSection onThemeChange={(appTheme: IAppTheme) => onThemeChange(appTheme)} onLoading={(flag: boolean) => handleLoading(flag)} handleClose={handleClose} />;
          break;
        case 2: // Language Support
          return <LanguageSection handleClose={handleClose} onLoading={(flag: boolean) => handleLoading(flag)} />;
          break;
        case 3: // PPE Equipments
          return <PpeEquipments onLoading={(flag: boolean) => handleLoading(flag)} />;
          break;
        case 4: //Renaming Tabs and Labels
          return <RenameTabsandLabels handleClose={handleClose} onClose={onClose} open={open} setOpen={setOpen} languageState={languageState} onLoading={(flag: boolean) => handleLoading(flag)} />;
        default:
          break;
      }
    } else {
      return <LanguageSection handleClose={handleClose} onLoading={(flag: boolean) => handleLoading(flag)} />;
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        PaperProps={{
          sx: {
            borderRadius: '12px',
          },
        }}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth={'md'}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
          }}
        >
          {t('NAVBAR.USER_SYSTEM_SETTINGS') as string}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              float: 'right',
              color: '#000000',
              border: '1px solid #000000',
              padding: '0',
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ m: 0, p: 0 }}>
          {loading && <Loading />}
          <Grid container direction="row" justifyContent="flex-start" alignItems="stretch">
            <Grid item xs={3} sx={{ backgroundColor: themePrimaryExtraLight }}>
              <Box
                component="div"
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  width: '100%',
                }}
              >
                <Tabs
                  sx={{
                    width: '100%',
                    margin: '5px 0',
                  }}
                  orientation="vertical"
                  value={value}
                  onChange={handleChange}
                  TabIndicatorProps={{
                    style: {
                      marginTop: '11px',
                      marginRight: '-18px',
                      width: '35px',
                      height: '25px',
                      clipPath: 'polygon(50% 0%, 0% 50%, 50% 100%)',
                      backgroundColor: '#ffffff',
                    },
                  }}
                >
                  {isTrainer && <Tab sx={tabStyles} iconPosition="start" disabled={true} icon={<VersionUpdateMethod color={value === 0 ? 'primary' : 'disabled'} />} label={t('SYSTEM_SETTINGS.VERSION_UPDATE') as string} />}
                  {isTrainer && <Tab sx={tabStyles} iconPosition="start" icon={<ThemeSupport handleClose={handleClose} color={value === 1 ? 'primary' : 'disabled'} />} label={t('SYSTEM_SETTINGS.THEME_SUPPORT') as string} />}
                  {
                    <Tab
                      sx={tabStyles}
                      iconPosition="start"
                      icon={<LanguageSupport handleClose={handleClose} color={value === 2 ? 'primary' : 'disabled'} />}
                      //disabled={true}
                      label={t('SYSTEM_SETTINGS.LANGUAGE_SUPPORT') as string}
                    />
                  }
                  {isTrainer && <Tab sx={tabStyles} iconPosition="start" icon={<PPEEquipments color={value === 3 ? 'primary' : 'disabled'} />} label={t('SYSTEM_SETTINGS.PPE_EQUIPMENTS') as string} />}
                  {isTrainer && (
                    <Tab
                      sx={{ ...tabStyles, textAlign: 'left' }}
                      iconPosition="start"
                      icon={<RenameTabsAndLabels handleClose={handleClose} color={value === 4 ? 'primary' : 'disabled'} />}
                      //disabled={true}
                      label={t('SYSTEM_SETTINGS.RENAME_TABS_AND_LABELS') as string}
                    />
                  )}
                </Tabs>
              </Box>
            </Grid>
            <Grid item xs={9}>
              <Box component="div" sx={{ m: 3, mt: 1.5 }}>
                {getTabContent(value)}
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SystemSettingsDialog;
