import { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate, Link as RouterLink } from 'react-router-dom';
import { User } from '../../entities/User/MockUser';
import { CompanyTheme } from '../../entities/Trainee/MockTrainee';
import { Card, Box, CardContent, Breadcrumbs, Typography } from '@mui/material';
import UserForm from './UserForm';
import Link, { LinkProps } from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import UserService from '../../services/User/UserService';
import Loading from '../../components/Loading/Loading';
import { StyledCardDetailsView } from '../../components/Styled/StyledCardDetailsView';
import { Toast } from '../../components/Notifications/Notification';
import { getMessage } from '../../shared/utils/message-helpers';
import { useTranslation } from 'react-i18next';
import UserContext from '../../context/UserContext';

interface EditUserProps {
  theme?: CompanyTheme;
}

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

const LinkRouter = (props: LinkRouterProps) => <Link {...props} component={RouterLink as any} />;

const EditUser: React.FC<EditUserProps> = (props) => {
  const { t } = useTranslation();
  const breadcrumbs = [
    <Link underline="hover" key="1" sx={{ opacity: '0.7' }}>
      {t('NAVBAR.ADMINISTRATION') as string}
    </Link>,
    <Link underline="hover" key="2" sx={{ opacity: '0.7' }}>
      {t('NAVBAR.USER_MANAGEMENT') as string}
    </Link>,
    <LinkRouter underline="hover" key="3" to="/users" sx={{ opacity: '0.7' }}>
      {t('NAVBAR.USERS') as string}
    </LinkRouter>,
    <Typography key="4" color="primary.main">
      {t('NAVBAR.EDIT_USER') as string}
    </Typography>,
  ];
  const { userId } = useParams();
  const [user, setUser] = useState<User>();
  const [loading, setLoading] = useState(false);
  const { currentUser, setCurrentUser } = useContext(UserContext);

  let navigate = useNavigate();

  const editUserHandler = (userData: any) => {
    setLoading(true);
    UserService.updateUser(userId || 'null', userData)
      .then((response) => {
        setLoading(false); //toast\.success\(.+\);
        //  Toast.success(t('OPERATION_SUCCESS', { ns: 'success' }), t(e.data.messageCode, { ns: 'success' }) || e.data.message);
        Toast.success(t('OPERATION_SUCCESS', { ns: 'success' }), t(response.data.messageCode, { ns: 'success' }) || response.data.message);
        if(userData.email === currentUser.email){
          setCurrentUser({
            ...currentUser,
            userFirstName: userData.firstName,
            userFullName: userData.firstName + " " + userData.lastName
          })
        }
        backHandler();
      })
      .catch(({ response }) => {
        setLoading(false);
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        console.error('EditUser -> editUserHandler', response);
      });
  };

  const backHandler = () => {
    navigate('/users');
  };

  useEffect(() => {
    if (userId) {
      setLoading(true);
      UserService.getUsersById(userId)
        .then((res) => {
          setLoading(false);
          setUser(res.data.data);
        })
        .catch(({ response }) => {
          setLoading(false);
          Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        });
    }
  }, [userId]);
  return (
    <>
      <Breadcrumbs sx={{ margin: '2rem' }} separator={<NavigateNextIcon color="primary" fontSize="small" />} aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>
      {loading && <Loading />}
      <StyledCardDetailsView>
        <Box
          component="div"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            textAlign: 'left',
          }}
        >
          <CardContent sx={{ flex: '1 0 auto', p: 0, '&:last-child': { pb: 0 } }} className="user-form-content">
            <UserForm onBack={backHandler} onSubmit={editUserHandler} title={t('NAVBAR.EDIT_USER')} user={user} />
          </CardContent>
        </Box>
      </StyledCardDetailsView>
    </>
  );
};

export default EditUser;
