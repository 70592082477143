import {
  Alert,
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material"
import { User } from "../../entities/User/MockUser"
import { useEffect, useState } from "react"
import UserService from "../../services/User/UserService"
import { RegExpConstants } from "../../shared/utils/const-helpers"
import { useTranslation } from "react-i18next"

interface UserFormProps {
  user?: any
  onSubmit: (user: any) => void
  onBack: () => void
  title: string
}

const UserForm: React.FC<UserFormProps> = (props) => {
  const [firstName, setFirstName] = useState<string>("")
  const [lastName, setLastName] = useState<string>("")
  const [email, setEmail] = useState<string>("")
  const [username, setUserName] = useState<string>("")
  const [persona, setPersona] = useState<string>("")
  const [company, setCompany] = useState<string>("")
  const [organizationId, setOrganizationId] = useState<string>("")
  const [personaId, setPersonaId] = useState<string>("")
  const [isActive, setIsActive] = useState<boolean>(true)
  const [emailUsernameMismatch, setEmailUsernameMismatch] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");

  const [org, setOrg] = useState(Array<any>);
  const [personaList, setPersonaList] = useState(Array<any>);

  useEffect(() => {
    if (props.user) {
      const userData = props.user
      //   set user data
      setFirstName(userData.firstName)
      setLastName(userData.lastName)
      setEmail(userData.email)
      setUserName(userData.email)
      setPersonaId(userData.personas[0].id)
      setOrganizationId(userData.organizationId)
      setIsActive(userData.isActive)
    }
  }, [props.user])
  const handleFirstNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if(event.target.value){
    if (event.target.value.match(RegExpConstants.name)) {
      setFirstNameError("");
    } else {
      setFirstNameError(t('COMMON.NO_SPECIAL_CHARACTERS'));
    }
    }
    setFirstName(event.target.value as string)
  }
  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(event.target.value){
    if (event.target.value.match(RegExpConstants.name)) {
      setLastNameError("");
    } else {
      setLastNameError(t('COMMON.NO_SPECIAL_CHARACTERS'));
    }
    }
    setLastName(event.target.value as string)
  }
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
   if(username && username !== event.target.value){
      setEmailUsernameMismatch(t('COMMON.EMAIL_NO_MATCH'));
    } else{
      setEmailError("");
      setEmailUsernameMismatch("");
    }
    setEmail(event.target.value as string)
  }
  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(event.target.value as string)
    if(email !== event.target.value){
      setEmailUsernameMismatch(t('COMMON.EMAIL_NO_MATCH'));
    }
    else{
      setEmailUsernameMismatch("");
    }
  }
  const handlePersonaChange = (event: SelectChangeEvent) => {
    setPersonaId(event.target.value as string)
  }
  const handleCompanyChange = (event: SelectChangeEvent) => {
    setOrganizationId(event.target.value as string)
  }
  const handleIsActiveChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: boolean
  ) => {
    if (newAlignment !== null) {
      setIsActive(newAlignment)
    }
  }
  const submitHandler = (e) => {
    e.preventDefault()
    const user={
      firstName: firstName?.trim(),
      lastName: lastName?.trim(),
      email: email?.trim(),
      isActive,
      organizationId,
      personaId
    }
    props.onSubmit(user);
  }
  const handleClose = () => {
    props.onBack()
  }

  useEffect(() => {
    UserService.getOrganizations().then((e) => {
      setOrg(e.data.data);
    }).catch((e) => {
    });

    UserService.getPersonas().then((e) => {
      setPersonaList(e.data.data);
    }).catch((e) => {
    });
  }, [])

  const { t } = useTranslation();

  return (
    <>
      <Box component={"div"} sx={{ padding: "1rem" }}>
        <Box component="h2" sx={{ m: 0, p: 2, pt: 1, pb: 1 }}>
          {props.title}
        </Box>
      </Box>
      <Box component="hr" sx={{}}></Box>
      <Box component={"div"} sx={{ padding: "1.5rem 2.25rem 1rem" }}>
        <Box
          component="form"
          sx={{
            width: "100%",
            textAlign: "left",
          }}
          onSubmit={submitHandler}
        >
          <Box component={"div"}>
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                sx={{ marginBottom: { xs: 0, md: 2 } }}
              >
                <FormControl fullWidth required>
                  <FormLabel>{t('ADD_USER.SELECT_PERSONA')}</FormLabel>
                  <Select
                    value={personaId}
                    onChange={handlePersonaChange}
                    displayEmpty
                    disabled={props.user !== undefined}
                  >
                     {personaList.map((item,key)=>
                     <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                    )}

                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                sx={{ marginBottom: { xs: 0, md: 2 } }}
              >
                <FormControl variant="standard" required fullWidth>
                  <FormLabel>{t('ADD_USER.FIRST_NAME')}</FormLabel>
                  <TextField
                    label=""
                    name="firstName"
                    value={firstName}
                    onChange={handleFirstNameChange}
                    helperText={firstNameError}
                    error={!!firstNameError}
                    placeholder={t('ADD_USER.FIRST_NAME')}
                    id="component-outlined"
                    style={{ borderRadius: "8px !important" }}
                    inputProps={{ maxLength: 20 }}
                    required
                  />
                  <FormHelperText
                    id="component-helper-text"
                    className="helperText"
                  >
                    {20 - firstName.length} {t('COMMON.CHARACTERS_LEFT') as string}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                sx={{ marginBottom: { xs: 0, md: 2 } }}
              >
                <FormControl variant="standard" required fullWidth>
                  <FormLabel>{t('ADD_USER.LAST_NAME')}</FormLabel>
                  <TextField
                    label=""
                    name="lastName"
                    value={lastName}
                    onChange={handleLastNameChange}
                    helperText={lastNameError}
                    error={!!lastNameError}
                    placeholder={t('ADD_USER.LAST_NAME')}
                    id="component-outlined"
                    style={{ borderRadius: "8px !important" }}
                    inputProps={{ maxLength: 20 }}
                    required
                  />
                  <FormHelperText
                    id="component-helper-text"
                    className="helperText"
                  >
                    {20 - lastName.length} {t('COMMON.CHARACTERS_LEFT') as string}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                sx={{ marginBottom: { xs: 0, md: 2 } }}
              >
                <FormControl variant="standard" required fullWidth >
                  <FormLabel>{t('ADD_USER.EMAIL')}</FormLabel>
                  <TextField
                    label=""
                    name="email"
                    value={email}
                    type="email"
                    onChange={handleEmailChange}
                    helperText={emailError}
                    error={!!emailError}
                    placeholder={t('ADD_USER.EMAIL')}
                    id="component-outlined"
                    style={{ borderRadius: "8px !important" }}
                    required
                    disabled={props.user !== undefined}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                sx={{ marginBottom: { xs: 0, md: 2 } }}
              >
                <FormControl variant="standard" required fullWidth>
                  <FormLabel>{props.user !== undefined ? t('ADD_USER.USERNAME'): t('ADD_USER.REENTER_EMAIL') }</FormLabel>
                  <TextField
                    label=""
                    name="username"
                    value={username}
                    onChange={handleUsernameChange}
                    placeholder={t('ADD_USER.REENTER_EMAIL')}
                    id="component-outlined"
                    error={emailUsernameMismatch !== ""}
                    helperText={emailUsernameMismatch !== "" ? emailUsernameMismatch : ""}
                    style={{ borderRadius: "8px !important" }}
                    required
                    disabled={props.user !== undefined}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                sx={{ marginBottom: { xs: 0, md: 2 } }}
              >
                <FormControl fullWidth required>
                  <FormLabel>{t('ADD_USER.COMPANY')}</FormLabel>
                  <Select
                    value={organizationId}
                    onChange={handleCompanyChange}
                    displayEmpty
                  >
                    {org.map((item,key)=>
                     <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                    )}

                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                sx={{ marginBottom: { xs: 2, lg: 0 } }}
                marginTop={2}
              >
                <InputLabel id="isActive" sx={{ mb: 1 }}>
                  {t('COMMON.STATUS')}
                </InputLabel>
                <ToggleButtonGroup
                  id="isActive"
                  color="primary"
                  value={isActive === true}
                  exclusive
                  onChange={handleIsActiveChange}
                >
                  <ToggleButton
                    sx={{
                      color: "primary.main",
                      bgcolor: "#ffffff",
                      border: "1px solid",
                      lineHeight: "0.7",
                      width: "5rem",
                      "&.Mui-selected": {
                        bgcolor: "primary.main",
                        color: "#ffffff",
                        borderColor: "primary.main",
                      },
                      "&.Mui-selected:hover": {
                        bgcolor: "primary.main",
                        color: "#ffffff",
                      },
                    }}
                    value={true}
                  >
                   {t('COMMON.ACTIVE')}
                </ToggleButton>
                  <ToggleButton
                    sx={{
                      color: "primary.main",
                      bgcolor: "#ffffff",
                      border: "1px solid",
                      lineHeight: "0.7",
                      width: "5rem",
                      "&.Mui-selected": {
                        bgcolor: "primary.main",
                        color: "#ffffff",
                        borderColor: "primary.main",
                      },
                      "&.Mui-selected:hover": {
                        bgcolor: "primary.main",
                        color: "#ffffff",
                      },
                    }}
                    value={false}
                  >
                     {t('COMMON.INACTIVE')}
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </Grid>
          </Box>
          <Box
            component="div"
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mb: '1em',
            }}
          >
            <Button variant="contained" disabled={!!firstNameError || !!lastNameError} type="submit" sx={{ ml: 0 }}>
            {t('BUTTON.SAVE')}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default UserForm
