import { IOrganizationAssignedProcedures, IOrganizationAssignedTrainee, IOrganizationNewlyAdded, IOrganizationNewlyAssigned, IOrganizationPendingProcedure, IOrganizationProcedureCompletionStatus, IOrganizationProceduresAssigned, IOrganizationTraineeActivityAnalysis, IProcedure, IProcedureAverageTimeTaken, IProcedureCompletionStatus, IProcedureFeedback, IProcedureTraineeDetails, IProcedureTrainees } from '../../entities/Analytics/AnalyticsProcedure';
import { IProcedureListPageSearchPayload } from '../../redux/features/Analytics/Analytics.slice';
import { PROCEDURE_LOCATION, PROCEDURE_VISIBILITY_SCOPE } from '../../shared/enums';
import { DataResponse, Paginated } from '../../shared/interfaces';
import { newAxiosInstance as http, localhostAxiosInstance } from '../http-common';

/**
 * The requested analytics on the total no.of trainees who completed the procedure on the queried time frame.
 *
 * @param {string} procedureId
 * @param {string} startDate - The start date
 * @param {string} [endDate] - Then end date - Optional parameter. when left blank, system will take the current date as end date
 * @returns {*}
 */
const getAnalyticsProcedureCompletionStatus = (procedureId: string, startDate: string, endDate: string) => {
    const url = `/analytics/procedure/completionStatus/${procedureId}?start=${startDate}&end=${endDate}`;
    return http.get<DataResponse<Array<IProcedureCompletionStatus>>>(url);
};

/**
 * Returns information related to the feedback data for a given procedure
 *
 * @param {string} procedureId
 * @returns {*}
 */
const getAnalyticsProcedureFeedback = (procedureId: string) => {
    const url = `/analytics/procedure/feedback/${procedureId}`;
    return http.get<DataResponse<IProcedureFeedback>>(url);
};

/**
 * Returns the analytics related to the time taken for a procedure
 *
 * @param {string} procedureId
 * @returns {*}
 */
const getAnalyticsProcedureAverageTimeTaken = (procedureId: string) => {
    const url = `/analytics/procedure/averageTimeTaken/${procedureId}`;
    return http.get<DataResponse<IProcedureAverageTimeTaken>>(url);
};

/**
 * Returns list of all available trainees data for a procedure
 *
 * @param {string} procedureId
 * @returns {*}
 */
const getAnalyticsProcedureTrainees = (procedureId: string) => {
    const url = `/analytics/procedure/trainees/${procedureId}`;
    return http.get<DataResponse<IProcedureTrainees>>(url);
};

/**
 * Returns trainee related analytics for a trainee
 *
 * @param {string} procedureId
 * @param {string} userId
 * @returns {*}
 */
const getAnalyticsProcedureTraineeDetails = (procedureId: string, userId: string) => {
    const url = `/analytics/procedure/trainee/${procedureId}/${userId}`;
    return http.get<DataResponse<IProcedureTraineeDetails>>(url);
};

/**
 * Returns excel file containing the trainee names, the time taken, ratings, recommendation, feedback and support calls
 *
 * @param {string} procedureId
 * @returns {*}
 */
const exportAnalyticsProcedure = (procedureId: string) => {
    const url = `/analytics/procedure/export/${procedureId}`;
    return http.get<DataResponse<Array<any>>>(url);
};

/**
 * Gives the list of all assigned procedures and more information their equivalent trainees progress in the querying time frame.
 *
 * @param {string} startDate - The start date
 * @param {string} [endDate] - Then end date - Optional parameter. when left blank, system will take the current date as end date
 * @returns {*}
 */
const getAnalyticsOrganizationAssignedProceduresList = (startDate: string, endDate: string) => {
    const url = `/analytics/organization/procedures/assigned/more?start=${startDate}&end=${endDate}`;
    return http.get<DataResponse<Array<IOrganizationAssignedProcedures>>>(url);
};

const getAnalyticsOrganizationCompletionRateList = (startDate: string, endDate: string) => {
    const url = `/analytics/organization/procedures/completionRate?start=${startDate}&end=${endDate}`;
    return http.get<DataResponse<Array<any>>>(url);
};

/**
 * Lists the count of newly added Work Instructions and Procedures on the given time range.
 *
 * @param {string} startDate - The start date
 * @param {string} [endDate] - Then end date - Optional parameter. when left blank, system will take the current date as end date
 * @returns {*}
 */
const getAnalyticsOrganizationNewlyAdded = (startDate: string, endDate: string) => {
    const url = `/analytics/organization/newlyAdded?start=${startDate}&end=${endDate}`;
    return http.get<DataResponse<IOrganizationNewlyAdded>>(url);
};

/**
 * Lists all the trainees and the progress status of the procedures assigned to them in a given time frame.
 *
 * @param {string} startDate - The start date
 * @param {string} [endDate] - Then end date - Optional parameter. when left blank, system will take the current date as end date
 * @returns {*}
 */
const getAnalyticsOrganizationAssignedTrainees = (startDate: string, endDate: string) => {
    const url = `/analytics/organization/assignedTrainees?start=${startDate}&end=${endDate}`;
    return http.get<DataResponse<Array<IOrganizationAssignedTrainee>>>(url);
};

/**
 * Gives the count of newly assigned procedures to a trainee on the given time range.
 *
 * @param {string} userId - Trainer user id
 * @param {string} startDate - The start date
 * @param {string} [endDate] - Then end date - Optional parameter. when left blank, system will take the current date as end date
 * @returns {*}
 */
const getAnalyticsOrganizationTraineeNewlyAssigned = (userId: string, startDate: string, endDate: string) => {
    const url = `/analytics/organization/trainee/newlyAssigned/${userId}/?start=${startDate}&end=${endDate}`;
    return http.get<DataResponse<IOrganizationNewlyAssigned>>(url);
};

/**
 * Gives the analytics of trainee's progress about the assigned procedures on the given time range.
 *
 * @param {string} userId - Trainer user id
 * @param {('Completed' | 'In-Progress' | 'Not started')} progressStatus - Progress of a trainee. the values can be "Completed", "In-Progress" and "Not started"
 * @param {string} startDate - The start date
 * @param {string} [endDate] - Then end date - Optional parameter. when left blank, system will take the current date as end date
 * @returns {*}
 */
const getAnalyticsOrganizationTraineeCompletionStatus = (userId: string, progressStatus: 'Completed' | 'In-Progress' | 'Not started', startDate: string, endDate: string) => {
    const url = `/analytics/organization/trainee/completionStatus/${userId}/?start=${startDate}&end=${endDate}`;
    return http.get<DataResponse<Array<IOrganizationProcedureCompletionStatus>>>(url);
};

/**
 * Gives the analytics of trainee's progress about the assigned procedures on the given time range.
 *
 * @param {string} userId - Trainer user id
 * @param {string} startDate - The start date
 * @param {string} [endDate] - Then end date - Optional parameter. when left blank, system will take the current date as end date
 * @returns {*}
 */
const getAnalyticsOrganizationTraineeActivityAnalysis = (userId: string, startDate: string, endDate: string) => {
    const url = `/analytics/organization/trainee/activityAnalysis/${userId}/?start=${startDate}&end=${endDate}`;
    return http.get<DataResponse<Array<IOrganizationTraineeActivityAnalysis>>>(url);
};

/**
 * Gives the list of all pending procedures (both individual trainees' and organization) in the querying time frame.
 *
 * @param {string} procedures - The value "mine" indicates all the procedures that were created by trainer. If no query value is given, then the org level is picked up.
 * @param {string} startDate - The start date
 * @param {string} [endDate] - Then end date - Optional parameter. when left blank, system will take the current date as end date
 * @returns {*}
 */
const getAnalyticsOrganizationPendingProcedures = (procedures: string, startDate: string, endDate: string) => {
    const url = `/analytics/organization/pendingProcedures/?procedures=${procedures}&start=${startDate}&end=${endDate}`;
    return http.get<DataResponse<IOrganizationPendingProcedure>>(url);
};

const getAnalyticsOrganizationRejectedProcedures = (startDate: string, endDate: string) => {
    const url = `/approval/manager/rejected?start=${startDate}&end=${endDate}`;
    return http.get<DataResponse<any>>(url);
};

/**
 * Gives the list of all assigned procedures and their equivalent trainees progress in the querying time frame.
 *
 * @param {string} startDate - The start date
 * @param {string} [endDate] - Then end date - Optional parameter. when left blank, system will take the current date as end date
 * @returns {*}
 */
const getAnalyticsOrganizationProceduresAssigned = (startDate: string, endDate: string) => {
    const url = `/analytics/organization/procedures/assigned?start=${startDate}&end=${endDate}`;
    return http.get<DataResponse<IOrganizationProceduresAssigned>>(url);
};

const getAnalyticsTrainerAndUserOverview = (startDate: string, endDate: string) => {
    const url = `/analytics/organization/procedures/published?start=${startDate}&end=${endDate}`;
    return http.get<DataResponse<any>>(url);
};

const getTrainingAnalytics = (startDate: string, endDate: string) => {
    const url = `/analytics/organization/listSignOffs?start=${startDate}&end=${endDate}`;
    return http.get<DataResponse<any>>(url);
};

interface TrainerAnalyticsOverviewResponse {
    workInstruction: number;
    procedure: {
        draft: number;
        approvalPending: number;
        published: number;
        assigned: number;
        periodicReview: number;
        rejected: number;
        total: number;
    };
}

interface GetTrainerAnalyticsPayload {
    procedureLocation: PROCEDURE_LOCATION;
    user?: string;
    start?: string;
    end?: string;
}
const getTrainerAnalyticsOverview = (payload: GetTrainerAnalyticsPayload) => {
    const url = '/analytics/organization/overview';
    return http
        .get<DataResponse<TrainerAnalyticsOverviewResponse>>(url, { params: payload })
        .then((response) => response.data.data)
        .then((data) => {
            return {
                workInstructionCount: data.workInstruction,
                procedureCount: data.procedure.total,
                draft: data.procedure.draft,
                approvalPending: data.procedure.approvalPending,
                published: data.procedure.published,
                assigned: data.procedure.assigned,
                periodicReview: data.procedure.periodicReview,
                rejected: data.procedure.rejected,
            };
        });
};

interface ProcedureListItem {
    procedureId: string;
    organizationId: string;
    workInstructionId: string;
    procedureName: string;
    workInstructionName: string;
    createdOn: number;
    modifiedOn: number;
    version: string;
    visibilityScope: string;
    status: string;
    approvalRequested: boolean
}
const getAnalyticsProcedureList = (payload: IProcedureListPageSearchPayload, signal) => {
    if(!payload.pageSize){
        payload.pageSize = 30;
    }
    if(!payload.startDate || !payload.endDate){
        delete payload.startDate;
        delete payload.endDate;
    }
    const url = '/analytics/procedure/getProcedures';
    return http.get<DataResponse<Paginated<ProcedureListItem[]>>>(url, { params: payload, signal }).then((response) => response.data.data);
};
const AnalyticsService = {
    getAnalyticsProcedureCompletionStatus,
    getAnalyticsProcedureFeedback,
    getTrainingAnalytics,
    getAnalyticsOrganizationCompletionRateList,
    getAnalyticsTrainerAndUserOverview,
    getAnalyticsProcedureAverageTimeTaken,
    getAnalyticsProcedureTrainees,
    getAnalyticsProcedureTraineeDetails,
    exportAnalyticsProcedure,
    getAnalyticsOrganizationAssignedProceduresList,
    getAnalyticsOrganizationNewlyAdded,
    getAnalyticsOrganizationAssignedTrainees,
    getAnalyticsOrganizationTraineeNewlyAssigned,
    getAnalyticsOrganizationTraineeCompletionStatus,
    getAnalyticsOrganizationTraineeActivityAnalysis,
    getAnalyticsOrganizationPendingProcedures,
    getAnalyticsOrganizationRejectedProcedures,
    getAnalyticsOrganizationProceduresAssigned,
    getTrainerAnalyticsOverview,
    getAnalyticsProcedureList,
};

export default AnalyticsService;
