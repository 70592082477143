import React from 'react'
import AnalyticsContainer from '../../components/Analytics';
import { useDocumentTitle } from '../../App';

const Analytics = () => {
  useDocumentTitle("CDS Mentor Analytics");
  return (
    <AnalyticsContainer />
  )
}

export default Analytics
