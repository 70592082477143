import React from 'react';
import { createContext, Dispatch } from 'react';

export interface ITranslation {
  CORE_PROCEDURES: {
    EN: string;
    FR: string;
    DE: string;
    ES: string;
    "ZH-CN": string;
    "PT-BR": string;
    PL: string;
  };
  PREREQUISITES: {
    EN: string;
    FR: string;
    DE: string;
    ES: string;
    "ZH-CN": string;
    "PT-BR": string;
    PL: string;
  };
}

export const defaultLabels: ITranslation = {
  CORE_PROCEDURES: {
    EN: 'Core Procedures',
    FR: 'Procédure de base',
    DE: 'Kernverfahren',
    ES: 'Procedimientos principales',
    "ZH-CN": "核心程序",
    "PT-BR": 'Procedimentos Básicos',
    PL: "Podstawowe procedury"
  },
  PREREQUISITES: {
    EN: 'Prerequisites',
    FR: 'Prérequis',
    DE: 'Voraussetzung',
    ES: 'Requisito previo',
    "ZH-CN": "先决条件",
    "PT-BR": 'Pré-requisito',
    PL: "Warunki wstępne"
  },
};

const refreshProcedure: boolean = false;

const translationFn: Dispatch<React.SetStateAction<ITranslation>> = () => {};
const refreshProcedureFn: Dispatch<React.SetStateAction<boolean>> = () => {};

const TranslationContext = createContext({
  currentTranslation: defaultLabels,
  setCurrentTranslation: translationFn,
  refreshProcedure: refreshProcedure,
  setRefreshProcedure: refreshProcedureFn,
});

export default TranslationContext;
