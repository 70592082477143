import {
  Box,
  Button,
  CardContent,
  Grid,
  Step,
  StepLabel
} from '@mui/material';
import {useEffect, useState} from 'react';
import React from 'react';
import DetailForm from './DetailForm';
import AssociationForm from './AssociationForm';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {useFormik} from 'formik';
import {toPersonaFormValues, toPersonaPayload} from '.';
import {IPersona} from '../../entities/Organization/Persona/Persona';
import {StyledCardDetailsView} from '../../components/Styled/StyledCardDetailsView';
import {StyledStepper} from '../../components/Styled/StyledStepper';
import Loading from '../../components/Loading/Loading';
import * as Yup from 'yup';
import PersonaService from '../../services/Persona/PersonaService';
import {Toast} from '../../components/Notifications/Notification';
import {useTranslation} from 'react-i18next';

interface PersonaFormProps {
  persona: Partial < IPersona >;
  onSubmit: (persona : IPersona) => void;
  onBack: () => void;
  isLoading: boolean;
}

const steps = ['Details', 'Associations'];

const PersonaForm: React.FC<PersonaFormProps> = (props) => {
  const [loading, setLoading] = useState(true);
  const [activeStep, setActiveStep] = React.useState(0);
  const [personas, setPersonas] = useState < IPersona[] > ([]);
  const {t} = useTranslation();

  const personaFormValidationSchema = Yup.object().shape({
      detailForm: Yup.object(
          {
              name: Yup.string().required(t('PERSONA.NAME_IS_REQUIRED')).test(t('PERSONA.UNIQUE_PERSONA_NAME'), t('PERSONA.PERSONA_NAME_ALREADY_IN_USE'), (value) => checkAlreadyInUse('name', value)),
              alias: Yup.string().required(t('PERSONA.ALIAS_IS_REQUIRED')).test(t('PERSONA.UNIQUE_PERSONA_ALIAS'), t('PERSONA_ALIAS_ALREADY_IN_USE'), (value) => checkAlreadyInUse(t('alias'), value)),
              identifier: Yup.string().required(t('PERSONA.IDENTIFIER_IS_REQUIRED'))
          }
      ),
      associationForm: Yup.object(
          {
              appScopes: Yup.array(Yup.object({
                  resourceName: Yup.string().required(t('PERSONA.RESOURCE_NAME_IS_REQUIRED')),
                  actions: Yup.array().of(Yup.string()).required(t('PERSONA.ACTION_IS_REQUIRED')).min(1, t('PERSONA.ACTION_IS_REQUIRED'))
              }).required(t('PERSONA.RESOURCE_AND_ACTIONS_ARE_REQUIRED')),)
          }
      )
  });

  const formik = useFormik({
      initialValues: toPersonaFormValues(props.persona),
      validationSchema: personaFormValidationSchema,
      validateOnMount: true,
      validateOnBlur: true,
      validateOnChange: true,
      onSubmit: (values) => {
          console.log(values);
      }
  });

  // Initialize
  useEffect(() => {
      getAllPersonas();

      return() => {};
  }, []);

  // Loading from Add/Edit forms
  useEffect(() => {
      setLoading(props.isLoading);

      return() => {};
  }, [props.isLoading]);

  const getAllPersonas = () => {
      setLoading(true);
      PersonaService.getAllPersonas().then((e) => {
          const {
              data: {
                  data: personaList
              }
          } = e;
          setPersonas(personaList);
          setLoading(false);
      }).catch(({response}) => {
          setLoading(false);
          Toast.error(t('OPERATION_FAILED', {ns: 'error'}), t(response.data.messageCode, {ns: 'error'}) || response.data.errorMessage);
      });
  };

  const checkAlreadyInUse = (name : string, value : string | undefined) : boolean => {
      if (!props ?. persona ?. id && personas && personas.length && value) {
          const result = personas.filter((o) => o[name].toLowerCase() === value.toLowerCase());
          return result && result.length === 0;
      }
      return true;
  };

  const handleNext = () => {
      if (activeStep !== steps.length - 1) {
          setActiveStep((step) => step + 1);
      }
  };

  const handleBack = () => {
      setActiveStep((step) => step - 1);
  };

  const submitHandler = (personaData) => {
      personaData.preventDefault();
      const formData = toPersonaPayload(formik.values);
      props.onSubmit(formData);
  };

  function getStepContent(step: number) {
      switch (step) {
          case 0:
              return < DetailForm formik = {
                  formik
              } />;
          case 1:
              return < AssociationForm formik = {
                  formik
              } />;
          default:
              throw new Error(t('PERSONA.UNKNOWN_STEP'));
      }
  }

  function getStepName(step: number) {
      switch (step) {
          case 0:
              return 'Details';
          case 1:
              return 'Associations';
          default:
              throw new Error(t('PERSONA.UNKNOWN_STEP'));
      }
  }

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <>
          <Grid container spacing={3} sx={{ pl: 3 }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={3}
              sx={{
                '&.MuiGrid-item': { paddingTop: '1rem' },
                paddingRight: { xs: 3, md: 0 },
              }}
            >
              <StyledStepper orientation="horizontal" activeStep={activeStep} sx={{ ml: 1 }}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </StyledStepper>
            </Grid>
          </Grid>
          <StyledCardDetailsView>
            <Box
              component="div"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                textAlign: 'left',
              }}
            >
              <CardContent sx={{ flex: '1 0 auto', p: 0 }} className="persona-form-content">
                <Box component={'div'} sx={{ padding: '1rem' }}>
                  <Box component="h2" sx={{ m: 0, p: 2, pt: 1, pb: 1 }}>
                    {getStepName(activeStep)}
                  </Box>
                </Box>
                <Box component="hr" sx={{}}></Box>

                <Box component={'div'} sx={{ padding: '1rem' }}>
                  <Box
                    component="form"
                    sx={{
                      padding: { xs: 0, lg: 2 },
                      width: '100%',
                      textAlign: 'left',
                    }}
                    onSubmit={submitHandler}
                  >
                    <Box component={'div'} sx={{ minHeight: '30rem' }}>
                      {getStepContent(activeStep)}
                    </Box>
                    <Box
                      component="div"
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        paddingRight: { xs: 0, lg: 4 },
                      }}
                    >
                      {activeStep !== 0 && (
                        <Button variant="outlined" size="small" onClick={handleBack} sx={{ mt: 3, ml: 1, minWidth: 'fit-content' }}>
                          <ArrowBackIosNewIcon />
                        </Button>
                      )}
                      {activeStep !== steps.length - 1 && (
                        <Button variant="contained" onClick={handleNext} sx={{ mt: 3, ml: 1 }}>
                          Next
                        </Button>
                      )}
                      {activeStep === steps.length - 1 && (
                        <Button variant="contained" type="submit" sx={{ mt: 3, ml: 1 }} disabled={!formik.isValid}>
                          Save
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Box>
              </CardContent>
            </Box>
          </StyledCardDetailsView>
        </>
      )}
    </>
  );
};

export default PersonaForm;
