import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import './PeriodicReviewDialog.scss';
import { Autocomplete, Avatar, Box, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Skeleton, Tab, Tabs, TextField } from '@mui/material';
import { useEffect } from 'react';
import { IuserList } from '../../../../entities/Trainer/MockTrainer';
import TrainerService from '../../../../services/trainer/TrainerService';
import AssignmentConfirmationDialog from '../AssignmentConfirmationDialog/AssignmentConfirmationDialog';
import Loading from '../../../Loading/Loading';
import { useTranslation } from 'react-i18next';
import { Toast } from '../../../Notifications/Notification';
import { StyledSwitch } from '../../../Styled/StyledSwitch';
import { useRadioGroup } from '@mui/material/RadioGroup';
import { FormControlLabelProps } from '@mui/material/FormControlLabel';
import useCurrentUser from '../../../../hooks/useCurrentUser';
interface StyledFormControlLabelProps extends FormControlLabelProps {
  checked: boolean;
}

const StyledFormControlLabel = styled((props: StyledFormControlLabelProps) => <FormControlLabel {...props} />)(({ theme, checked }) => ({
  '.MuiFormControlLabel-label': checked && {
    color: theme.palette.primary.main,
  },
}));

type AutoCompleteFieldProps<T> = {
  selectValue: keyof T;
  options: T[];
  onChange: any;
  periodicProcedureStatus?: any;
  revisitedUserEdit?: any;
  notifyTrainerEdit?: any;
};
export const isString = (item: any): item is string => {
  return typeof item === 'string';
};
const AutoCompleteField = <T extends {}>({ selectValue, options, onChange, periodicProcedureStatus, revisitedUserEdit, notifyTrainerEdit }: AutoCompleteFieldProps<T>): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <Autocomplete
      disabled={periodicProcedureStatus}
      id="manager-auto-complete"
      options={options}
      getOptionLabel={(option: any) => {
        const val = option[selectValue];
        return isString(val) ? val : '';
      }}
      onChange={onChange}
      renderInput={(params) => (
        <Box component="div">
          <TextField sx={{ backgroundColor: !periodicProcedureStatus ? undefined : 'rgba(0, 0, 0, 0.1)' }} {...params} placeholder={Boolean(revisitedUserEdit) ? revisitedUserEdit : Boolean(notifyTrainerEdit) ? notifyTrainerEdit : t('NAVBAR.SELECT_EMAIL')} />
        </Box>
      )}
    />
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 0 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            padding: 0,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function PeriodicReviewDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [showDialog, setShowDialog] = React.useState<boolean>(false);
  const [usersCount, setUsersCount] = React.useState<number>(0);
  const [contentReady, setContentReady] = React.useState<boolean>(true);
  const [duration, setDuration] = React.useState<any>(props?.procedure?.revisitFormat === 'y' ? 'years' : props?.procedure?.revisitFormat === 'm' ? 'months' : props?.procedure?.revisitFormat === 'd' ? 'days' : 'years');
  const [managersLoading, setManagersLoading] = React.useState<boolean>(false);
  const [approvalManagers, setApprovalManagers] = React.useState<Array<any>>([]);
  const [userInputValue, setUserInputValue] = React.useState<any>(0);
  const [calculatedDays, setCalculatedDays] = React.useState<any>(null);
  const [periodicReviewDays, setPeriodicReviewDays] = React.useState<any>(20);
  const [revisitedUser, setRevisitedUser] = React.useState<any>();
  const [notifyTrainer, setNotifyTrainer] = React.useState<any>();
  const [revisitedUserEdit, setRevisitedUserEdit] = React.useState<any>();
  const [notifyTrainerEdit, setNotifyTrainerEdit] = React.useState<any>();
  const { refreshProcedure, setRefreshProcedure } = useCurrentUser();
  const [periodicProcedureStatus, setPeriodicProceudreStatus] = React.useState<boolean>(Boolean(props.procedure?.revisitExpiry));

  const { t } = useTranslation();

  useEffect(() => {
    setManagersLoading(true);
    TrainerService.getManagerList(localStorage.getItem('personaId')).then((response) => {
      const filteredTrainers = response.data.data;
      setApprovalManagers(filteredTrainers);
      setManagersLoading(false);
    });
  }, []);

  
  useEffect(() => {
    setOpen(props.openDialog);
  }, [props.openDialog]);

  useEffect(() => {
    if (props.procedure?.escalateTo) {
      const managerWithEmail = approvalManagers.find((manager) => manager.id === props.procedure.escalateTo);
      if (managerWithEmail) {
        setNotifyTrainerEdit(managerWithEmail.email);
      }
    }

    if (props.procedure?.revisitSentTo) {
      const managerWithEmail = approvalManagers.find((manager) => manager.id === props.procedure.revisitSentTo);
      if (managerWithEmail) {
        setRevisitedUserEdit(managerWithEmail.email);
      }
    }

  }, [approvalManagers, props.procedure?.escalateTo]);

  if(!props.procedure) return null;
  
  const handlePeriodicReviewSave = () => {
    setContentReady(false);
    if (periodicProcedureStatus) {
      const payload = {
        revisitExpiry: calculatedDays,
        revisitFormat: duration === 'years' ? 'y' : duration === 'months' ? 'm' : 'd',
        reviewPeriod: Number(periodicReviewDays),
        escalateTo: notifyTrainer,
        revisitSentTo: revisitedUser,
      };
      TrainerService.addRevisitConfiguration(props.procedure.procedureId, payload)
        .then(async (response) => {
          setContentReady(true);
          Toast.success(`${t('NAVBAR.PERIODIC_REVIEW_CONFIGURATION')}`, `${t('NAVBAR.PERIODIC_REVIEW_CONFIGURATION_SUCCESS_MESSAGE')}`);
          setRefreshProcedure(!refreshProcedure);
          props.closeDialog();
        })
        .catch(({ response }) => {
          Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
          setContentReady(true);
        });
    } else {
      const payload = {
        revisitExpiry: null,
        reviewPeriod: null,
        escalateTo: null,
        revisitSentTo: null,
      };
      TrainerService.addRevisitConfiguration(props.procedure.procedureId, payload)
        .then(async (response) => {
          setContentReady(true);
          setDuration('years');
          Toast.success(`${t('NAVBAR.PERIODIC_REVIEW_CONFIGURATION')}`, `${t('NAVBAR.PERIODIC_REVIEW_CONFIGURATION_SUCCESS_MESSAGE')}`);
          setRefreshProcedure(!refreshProcedure);
          props.closeDialog();
        })
        .catch(({ response }) => {
          Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
          setContentReady(true);
        });
    }
  };


  const handleClose = () => {
    props.closeDialog();
    setOpen(false);
    setPeriodicProceudreStatus(Boolean(props.procedure?.revisitExpiry));
  };

  const onInputChangeRevisitedUser = (e: React.ChangeEvent, value: any): void => {
    setRevisitedUser(value.id);
  };

  const onInputChangeNotifyTrainer = (e: React.ChangeEvent, value: any): void => {
    setNotifyTrainer(value.id);
  };

  const periodicProcedureStatusHandler = () => {
    setPeriodicProceudreStatus(!periodicProcedureStatus);
  };

  const handleCalculatedDays = (event) => {
    const numericValue = event.target.value;
    if (!Number.isInteger(parseFloat(numericValue))) {
      return;
    }
    setUserInputValue(parseInt(numericValue, 10));
    let conversionFactor = 1;
    switch (duration) {
      case 'years':
        conversionFactor = 365;
        break;
      case 'months':
        conversionFactor = 30;
        break;
      case 'days':
        conversionFactor = 1;
        break;
      default:
        conversionFactor = 1;
        break;
    }
    const days = numericValue * conversionFactor;
    setCalculatedDays(days);
  };

  const isValidForm = (): boolean => {
    let isValid = true;
    if (userInputValue < 1) {
      isValid = false;
    }
    if (!notifyTrainer) {
      isValid = false;
    }

    if (!revisitedUser) {
      isValid = false;
    }

    if (periodicReviewDays < 1) {
      isValid = false;
    }

    return isValid;
  };

  const handleDurationChange = (event) => {
    const selectedValue = event.target.value;
    setDuration(event.target.value);
    let conversionFactor = 1;
    switch (selectedValue) {
      case 'years':
        conversionFactor = 365;
        break;
      case 'months':
        conversionFactor = 30;
        break;
      case 'days':
        conversionFactor = 1;
        break;
      default:
        conversionFactor = 1;
        break;
    }
    const days = userInputValue * conversionFactor;
    setCalculatedDays(days);
  };

  const periodicReviewHandler = (event) => {
    const numericValue = event.target.value;
    if (!Number.isInteger(parseFloat(numericValue))) {
      setUserInputValue('');
      return;
    }
    setPeriodicReviewDays(parseInt(numericValue));
  };

  const getRevisitExpiryPlaceholder = () => {
    if (props?.procedure?.revisitFormat === 'y') {
      return (props.procedure.revisitExpiry / 365).toString();
    } else if (props?.procedure?.revisitFormat === 'm') {
      return (props.procedure.revisitExpiry / 30).toString();
    } else if (props?.procedure?.revisitFormat === 'd' && props?.procedure?.revisitExpiry > 0) {
      return props?.procedure?.revisitExpiry?.toString();
    } else {
      return '';
    }
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        scroll="paper"
        PaperProps={{
          sx: {
            width: '584px',
            padding: '24px',
          },
        }}
      >
        {!contentReady && <Loading />}

        <>
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
            {t('NAVBAR.PERIODIC_REVIEW_CONFIGURATION')}
          </BootstrapDialogTitle>

          <Box component="div" sx={{ borderColor: 'divider', marginTop: '24px' }}>
            <Box component="span" sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography sx={{ fontSize: '16px' }}>{t('NAVBAR.PERIODIC_PROCEDURES')}</Typography>
              <StyledSwitch name="isActive" checked={periodicProcedureStatus} color={'primary'} onChange={periodicProcedureStatusHandler} />
            </Box>
          </Box>

          <FormControl sx={{ width: '100%', marginTop: '24px' }}>
            <FormLabel
              component="legend"
              className="formStyle"
              sx={{
                color: 'rgba(28, 29, 31, .8)',
                fontSize: '12px',
                fontWeight: '500',
                paddingBottom: '15px',
              }}
            >
              {t('NAVBAR.PERIODIC_REVIEW_INTERVAL')}
            </FormLabel>

            <Box component="div" sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <TextField type="number" autoComplete="off" disabled={!periodicProcedureStatus} sx={{ width: '260px', height: '44px', borderRadius: '8px', backgroundColor: periodicProcedureStatus ? 'none' : 'rgba(0, 0, 0, 0.1)' }} onChange={handleCalculatedDays} placeholder={getRevisitExpiryPlaceholder()} InputProps={{ inputProps: { min: 1, sx: { height: '44px', boxSizing: 'border-box' } } }} />
              <Select value={duration} disabled={!periodicProcedureStatus} onChange={handleDurationChange} displayEmpty inputProps={{ 'aria-label': 'Without label' }} sx={{ width: '260px', border: '0.5px', borderRadius: '8px', height: '44px', backgroundColor: periodicProcedureStatus ? 'none' : 'rgba(0, 0, 0, 0.1)' }}>
                <MenuItem value="years">{t('NAVBAR.YEARS')}</MenuItem>
                <MenuItem value="months">{t('NAVBAR.MONTHS')}</MenuItem>
                <MenuItem value="days">{t('NAVBAR.DAYS')}</MenuItem>
              </Select>
            </Box>
            {/* {!revisitDurationValue && (
      <Box component="div">
        <TextField type="date" id="startDate" name="startDate" value={startDate} disabled={!periodicProcedureStatus} onChange={handleStartDateChange} inputProps={{ min: today }} sx={{ display: 'flex', justifyContent: 'space-between', width: '540px', height: '44px', border: '0.5px', borderRadius: '8px' }} />
      </Box>
    )} */}
          </FormControl>
          <FormControl sx={{ width: '100%', marginTop: '24px' }} className="tour-instruction-brand">
            <FormLabel
              component="legend"
              className="formStyle"
              sx={{
                color: 'rgba(28, 29, 31, .8)',
                fontSize: '12px',
                fontWeight: '500',
                paddingBottom: '8px',
              }}
            >
              {' '}
              {t('NAVBAR.REVIEW_WINDOW') as string}{' '}
            </FormLabel>
            <TextField name="periodicReview" sx={{ backgroundColor: periodicProcedureStatus ? 'none' : 'rgba(0, 0, 0, 0.1)' }} autoComplete="off" type="number" disabled={!periodicProcedureStatus} placeholder={Boolean(props?.procedure?.reviewPeriod) ? props?.procedure?.reviewPeriod : '20'} id="component-outlined" inputProps={{ maxLength: 55 }} required onChange={periodicReviewHandler} InputProps={{ inputProps: { min: 1 } }} />
          </FormControl>
          <FormControl sx={{ width: '100%', marginTop: '24px' }} className="tour-instruction-brand">
            <FormLabel
              component="legend"
              className="formStyle"
              sx={{
                color: 'rgba(28, 29, 31, .8)',
                fontSize: '12px',
                fontWeight: '500',
                paddingBottom: '8px',
              }}
            >
              {' '}
              {t('NAVBAR.REVIEWER_EMAIL') as string}{' '}
            </FormLabel>

            {!managersLoading ? (
              <>
                <AutoCompleteField<any> options={approvalManagers} selectValue="email" onChange={onInputChangeRevisitedUser} revisitedUserEdit={revisitedUserEdit} periodicProcedureStatus={!periodicProcedureStatus} />
              </>
            ) : (
              <Skeleton sx={{ height: '36px', width: '100%' }} />
            )}
          </FormControl>
          <FormControl sx={{ width: '100%', marginTop: '24px' }} className="tour-instruction-brand">
            <FormLabel
              component="legend"
              className="formStyle"
              sx={{
                color: 'rgba(28, 29, 31, .8)',
                fontSize: '12px',
                fontWeight: '500',
                paddingBottom: '8px',
              }}
            >
              {' '}
              {t('NAVBAR.ESCALATION_EMAIL') as string}{' '}
            </FormLabel>

            {!managersLoading ? (
              <>
                <AutoCompleteField<any> options={approvalManagers} selectValue="email" onChange={onInputChangeNotifyTrainer} notifyTrainerEdit={notifyTrainerEdit} periodicProcedureStatus={!periodicProcedureStatus} />
              </>
            ) : (
              <Skeleton sx={{ height: '36px', width: '100%' }} />
            )}
          </FormControl>

          <DialogActions style={{ display: 'flex', justifyContent: 'end', marginTop: '24px' }}>
            <Button variant="contained" type="submit" disabled={!periodicProcedureStatus ? false : !isValidForm()} onClick={handlePeriodicReviewSave}>
              {' '}
              {t('BUTTON.SAVE')}
            </Button>
            <Button variant="outlined" onClick={handleClose}>
              {' '}
              {t('BUTTON.CANCEL')}
            </Button>
          </DialogActions>
        </>
      </BootstrapDialog>
      {showDialog && <AssignmentConfirmationDialog openAssignmentDialog={showDialog} userAssignedCount={usersCount} workInstruction={props.workInstruction} procedure={props.procedure} />}
    </div>
  );
}
