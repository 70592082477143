import React, { useEffect, useState } from 'react';
import { Box, Button, FormControl, InputAdornment, MenuItem, Paper, Select, SelectChangeEvent, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import FuzzySearch from 'fuzzy-search';
import { Search } from '@mui/icons-material';
import { ProductAffected } from '../../../Icons/CdsIcons';
import moment from 'moment';
import AnalyticsService from '../../../../services/Analytics/AnalyticsService';
import { AssignedStatusType, IOrganizationAssignedProcedures } from '../../../../entities/Analytics/AnalyticsProcedure';
import { Toast } from '../../../Notifications/Notification';
import Loading from '../../../Loading/Loading';
import { useAnalytics } from '../../../../states/analytics';
import { insertUrlParam } from '../../../../shared/utils/url-helpers';
import { useTranslation } from 'react-i18next';
import { TRANSLATE_TRAINEE_STATUS } from '../../../../shared/enums';

export const AssignedProceduresList: React.FC = (props) => {
  const { t } = useTranslation();
  const FILTERS = {
    ALL: 'ALL',
    INPROGRESS: 'INPROGRESS',
    COMPLETED: 'COMPLETED',
    NOTSTARTED: 'NOTSTARTED',
  };

  const currentDateRange = useAnalytics((state) => state.selectedDateRange);
  const currentFilterType = useAnalytics((state) => state.selectedType);
  const [allRecords, setAllRecords] = useState<Array<IOrganizationAssignedProcedures>>();
  const [filteredRecords, setFilteredRecords] = useState<Array<IOrganizationAssignedProcedures>>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [filter, setFilter] = React.useState<AssignedStatusType>(FILTERS.ALL as AssignedStatusType);
  const [searchVal, setSearchVal] = React.useState<string>('');

  const theme = useTheme();
  const themePrimaryExtraLight = theme.palette.primary.main + '0d';
  const navigate = useNavigate();
  const { search: searchQuery } = useLocation();

  const commonStyles = {
    border: 1,
    borderColor: 'text.primary',
  };

  const query = new URLSearchParams(searchQuery);
  const filterType = query.get('filter');
  const startDate = query.get('startDate');
  const endDate = query.get('endDate');
  const assignedStatusQuery = query.get('assignedStatus') || 'ALL';

  useEffect(() => {
    setLoading(true);
    AnalyticsService.getAnalyticsOrganizationAssignedProceduresList(startDate || moment().clone().startOf('month').format('DD-MM-YYYY'), endDate || moment().format('DD-MM-YYYY'))
      .then((res) => {
        setLoading(false);
        setAllRecords(res.data.data);
        if (assignedStatusQuery === FILTERS.ALL) {
          setFilteredRecords(res.data.data);
        } else {
          setFilter(assignedStatusQuery as AssignedStatusType);
          setFilteredRecords(res.data.data.filter((a) => a.progressStatus.status.toLocaleLowerCase() === assignedStatusQuery.toLocaleLowerCase()));
        }
      })
      .catch(({ response }) => {
        setLoading(false);
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
      });
  }, [endDate, startDate]);

  const handleChange = (event: SelectChangeEvent) => {
    setSearchVal('');
    setFilter(event.target.value as AssignedStatusType);
    insertUrlParam('assignedStatus', event.target.value as AssignedStatusType);
    if (event.target.value === FILTERS.ALL) {
      setFilteredRecords(allRecords);
    } else {
      if (allRecords) setFilteredRecords(allRecords.filter((a) => a.progressStatus.status.toLocaleLowerCase() === event.target.value.toLocaleLowerCase()));
    }
  };

  const search = (key: string) => {
    setSearchVal(key);
    if (allRecords) {
      const filterSearcher = new FuzzySearch(allRecords, ['progressStatus.status'], {
        caseSensitive: false,
      });
      let result;
      if (filter === FILTERS.ALL) {
        result = allRecords;
      } else {
        result = filterSearcher.search(filter);
      }
      const searcher = new FuzzySearch(result, ['procedureName', 'workInstructionName'], {
        caseSensitive: false,
      });
      result = searcher.search(key);
      setFilteredRecords(result);
    }
  };

  const handleGotoProceduresAnalytics = (workInstructionId: string, procedureId: string) => {
    navigate(`/analytics/procedures/assigned/details/${workInstructionId}/${procedureId}?filter=${filterType}&startDate=${startDate}&endDate=${endDate}`);
  };

  return (
    <Box
      component="div"
      className="pending-procedure-analytics"
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        component="div"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          margin: '20px 0px',
        }}
      >
        <Typography variant="h6">
          {t('ANALYTICS_SECTION.ASSIGNED_PROCEDURES')} <strong className="count">{filteredRecords?.length}</strong>
        </Typography>
        <Box component="div" sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box
            component="div"
            sx={{
              display: 'flex',
              alignItems: 'flex-end',
              marginRight: '10px',
            }}
            className="search-btn"
          >
            <FormControl fullWidth>
              <TextField
                onChange={(e) => search(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {' '}
                      <Search sx={{ color: 'primary.main', mr: 1, my: 0.5 }} />{' '}
                    </InputAdornment>
                  ),
                }}
                placeholder={t('COMMON.SEARCH')}
                value={searchVal}
                style={{ borderRadius: '8px !important' }}
              />
            </FormControl>
          </Box>
          <Select size="small" value={filter} onChange={handleChange}>
            {Object.keys(FILTERS).map((key) => (
              <MenuItem key={key} value={FILTERS[key]}>
                {t(TRANSLATE_TRAINEE_STATUS[FILTERS[key]])}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      <Box
        component="div"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}
      >
        {isLoading ? (
          <Loading positionFixed={true} />
        ) : (
          <>
            {filteredRecords &&
              filteredRecords.map((procedure, index) => (
                <Box component="div" className="trainer-requisits-link" key={index}>
                  <Paper
                    className="btn-disable trainer-requisits-action"
                    elevation={0}
                    sx={{
                      borderRadius: '12px',
                      flex: '1 0 auto',
                      backgroundColor: themePrimaryExtraLight,
                    }}
                  >
                    <Box component="div" className="trainer-requisits-info">
                      <Box component="div" className="trainer-requisits-heading">
                        <Box component="span" className="product-affected-icon">
                          <ProductAffected />
                        </Box>
                        <Typography component="div" variant="h5">
                          <Typography component="div" variant="h6" sx={{ display: 'flex' }}>
                            {procedure.procedureName}
                          </Typography>
                          <Typography
                            component="div"
                            variant="overline"
                            sx={{
                              borderColor: 'grey.500',
                              textAlign: 'left',
                              textTransform: 'none',
                            }}
                          >
                            {procedure.workInstructionName && (
                              <Tooltip title={t("ADD_WI.WORK_INSTRUCTION_NAME")}>
                                <Box
                                  component="span"
                                  className="style-text"
                                  sx={{
                                    ...commonStyles,
                                    borderColor: 'grey.500',
                                  }}
                                >
                                  {procedure.workInstructionName}
                                </Box>
                              </Tooltip>
                            )}
                            {procedure.versionName && (
                              <>
                                <Box
                                  component="span"
                                  className="style-text"
                                  sx={{
                                    ...commonStyles,
                                    borderColor: 'grey.500',
                                  }}
                                >
                                  {t('PROCEDURE.CARD_VERSION')} {procedure.versionName}{' '}
                                </Box>
                              </>
                            )}
                            {procedure.progressStatus && (
                              <>
                                <Box
                                  component="span"
                                  className="style-text"
                                  sx={{
                                    ...commonStyles,
                                    textTransform: 'capitalize',
                                    borderColor: 'grey.500',
                                  }}
                                >
                                  {t(TRANSLATE_TRAINEE_STATUS[procedure.progressStatus.status.toUpperCase()])}
                                </Box>
                              </>
                            )}
                          </Typography>
                        </Typography>
                      </Box>
                      <Button onClick={() => handleGotoProceduresAnalytics(procedure.workInstructionId, procedure.procedureId)}>
                        <Typography
                          sx={{
                            display: 'flex',
                            color: theme.palette.primary.main,
                          }}
                        >
                          {t('ANALYTICS_SECTION.MORE_DETAILS')}
                          <ArrowForwardIcon />
                        </Typography>
                      </Button>
                    </Box>
                  </Paper>
                </Box>
              ))}
          </>
        )}
      </Box>
    </Box>
  );
};

export default AssignedProceduresList;
