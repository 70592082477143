import { Grid, Paper } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import LeftProceduresCard from '../../../components/Cards/Procedures/Left/LeftProceduresCard';
import RightProceduresCard from '../../../components/Cards/Procedures/Right/RightProceduresCard';
import { IProcedures, IWorkInstructions } from '../../../entities/Trainee/MockTrainee';
import TutorialService from '../../../services/trainee/TraineeService';
import './Procedures.scss';
import Loading from '../../../components/Loading/Loading';
import { Toast } from '../../../components/Notifications/Notification';
import { ProcedureDuplicationStatus, WorkInstructionModelStatus } from '../../../shared/enums';
import { useTranslation } from 'react-i18next';
import { useDocumentTitle } from '../../../App';
import useCurrentUser from '../../../hooks/useCurrentUser';

interface Props {
  isTrainer?: boolean;
  isDelete?: boolean;
}

const Procedures: React.FC<Props> = (props) => {
  const timerRef = useRef<NodeJS.Timer>();
  const [workInstruction, setWorkInstruction] = useState<IWorkInstructions>();
  const [procedures, setProcedures] = useState<IProcedures[]>();
  const { companyId, id } = useParams();
  const [cadFileUpload, setCadFileUpload] = useState<any>(null);
  const [status, setStatus] = useState<null | string>(null);
  const [isTaskCreated, setIsTaskCreated] = useState(false);
  const [showInfoMessage, setShowInfoMessage] = useState<boolean>(false);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [isStatusLoading, setIsStatusLoading] = useState(true);
  const [cadFileUploadError, setCadFileUploadError] = useState('');

  const { t } = useTranslation();

  useDocumentTitle('CDS Mentor Procedures');

  const resetMessages = (showInfo: boolean = false, showError: boolean = false) => {
    setShowInfoMessage(showInfo);
    setShowErrorMessage(showError);
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (procedures) {
      for (const procedure of procedures) {
        if (procedure.tasks_count > 0) {
          setIsTaskCreated(true);
        }
      }
      if (procedures.length === 0) {
        navigate(`/trainer/recycleBin/${companyId}`);
      }
    }
  }, [procedures]);

  const setErrorMessage = (showErrorMsg: boolean, showInfoMsg: boolean, statusMsg: null | string) => {
    setShowErrorMessage(showErrorMsg);
    setShowInfoMessage(showInfoMsg);
    setStatus(statusMsg ?? '');
  };

  const setStatusLoading = (flag: boolean) => {
    setIsStatusLoading(flag);
  };

  const getWorkInstruction = useCallback(
    (id: string) => {
      TutorialService.getWorkInstructionsById(id)
        .then((e) => {
          setWorkInstruction(e.data.data);
          if (e.data.data.status.toUpperCase() === WorkInstructionModelStatus.FILE_UPLOAD_FAILED) {
            setCadFileUploadError(WorkInstructionModelStatus.FILE_UPLOAD_FAILED);
          }
        })
        .catch((response) => {
          setIsStatusLoading(false);
          Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        });
    },
    [id],
  );

  const getProcedures = useCallback(
    (id: string) => {
      TutorialService.getProceduresBin(id)
        .then((e) => {
          setProcedures(e.data.data);
          for (let i = 0; i < e.data.data.length; i++) {
            if (e.data.data[i].duplicateContentCreated === ProcedureDuplicationStatus.PROCESSING) {
              const interval = setTimeout(() => {
                getProcedures(id);
              }, 20000);
              break;
            }
          }
        })
        .catch((response) => {
          setProcedures([]);
          Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        });
    },
    [id],
  );

  const clearCheckStatusWithTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
  };

  const { refreshProcedure, setRefreshProcedure } = useCurrentUser();

  useEffect(() => {
    clearCheckStatusWithTimer();
    resetMessages();

    if (id) {
      getWorkInstruction(id);
      getProcedures(id);
    }
    // Clear timer when unmount
    return () => {
      clearCheckStatusWithTimer();
    };
  }, [refreshProcedure]);

  return (
    <>
      {!procedures && <Loading />}
      <Grid sx={{ paddingBottom: '0px !important' }} className="procedure-card-container" container>
        <Paper className={`procedures-card ${props.isTrainer ? 'trainer-procedure-container' : 'trainee-procedure-container'}`} elevation={0} sx={{ borderRadius: '12px', boxShadow: 'rgba(149, 157, 165, 0.5) 0px 8px 24px' }}>
          {workInstruction && procedures && (
            <>
              <LeftProceduresCard statusLoading={isStatusLoading} isTaskCreated={isTaskCreated} showErrorMessage={showErrorMessage} showInfoMessage={showInfoMessage} status={status} id={workInstruction?.workInstructionId} title={workInstruction?.workInstructionName} description={workInstruction?.workInstructionDescription} pendingApprovals={workInstruction?.pendingApprovals} isTrainer={props.isTrainer} brand={workInstruction?.brand} procedures={procedures} workInstruction={workInstruction} isDelete={props.isDelete} />
              <RightProceduresCard cadFileUpload={cadFileUpload} showErrorMessage={showErrorMessage} showInfoMessage={showInfoMessage} status={status} workInstruction={workInstruction} procedures={procedures} setProcedures={setProcedures} isTrainer={props.isTrainer} emitShowErrorMessage={setErrorMessage} emitIsStatusLoading={setStatusLoading} cadFileUploadError={cadFileUploadError} isDelete={props.isDelete} />
            </>
          )}
        </Paper>
      </Grid>
    </>
  );
};

export default Procedures;
