import { Box, CardMedia, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { FallbackWorkInstructionIcon } from '../../../components/Icons/CdsIcons';
import { Toast } from '../../../components/Notifications/Notification';
import { BasicDialog } from '../../../components/Trainer/Dialog/BasicDialog/BasicDialog';
import { getLinkedWorkInstruction } from '../../../services/ModularProcedure/ModularProcedure';

const LinkedWorkInstructions = (props) => {
  const { isOpen, onClose, procedureId } = props;
  const [attachedWIs, setAttachedWIs] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (!procedureId) return;
    setIsLoading(true);
    getLinkedWorkInstruction(procedureId)
      .then((response) => {
        setAttachedWIs(response.data.data);
      })
      .catch((error) => {
        Toast.error(t('PROCEDURE_LIBRARY.LINKED_WI_DIALOG.ERROR_MSG'), error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [procedureId]);

  return (
    <BasicDialog title={t('PROCEDURE_LIBRARY.LINKED_WI_DIALOG.TITLE', { wiLength: attachedWIs.length })} onOpen={isOpen} onClose={onClose} fullWidth={true} maxWidth={'md'}>
      {isLoading && t('PROCEDURE_LIBRARY.LINKED_WI_DIALOG.LOADING')}
      <AttachedWIList attachedWIs={attachedWIs} />
      {!isLoading && !attachedWIs.length && t('PROCEDURE_LIBRARY.LINKED_WI_DIALOG.NO_LINKED_WI')}
    </BasicDialog>
  );
};

export default LinkedWorkInstructions;

export const AttachedWIList = (props) => {
  const { attachedWIs } = props;
  const navigate = useNavigate();
  const { companyId } = useParams();
  const theme = useTheme();

  const handleClick = (workInstructionId) => {
    navigate(`/trainer/procedures/${companyId}/${workInstructionId}`);
  };
  return (
    <>
      {attachedWIs.map((each: any, index) => {
        const workInstruction = each?.workInstruction;
        const thumbnailUrl = workInstruction?.workInstructionImageURL;
        if (!workInstruction) return null;
        return (
          <Box
            component={'div'}
            sx={{
              marginLeft: '8px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              borderBottom: '1px solid #D9D9D9',
              margin: '5px 0',
              ':last-child': {
                border: 'none',
              },
            }}
            onClick={() => handleClick(workInstruction.workInstructionId)}
            key={each.workInstructionId + '-' + index}
          >
            <Box component={'div'}>{thumbnailUrl ? <CardMedia component="img" className="wi-img" image={thumbnailUrl} alt="work instruction model" /> : <FallbackWorkInstructionIcon height={'24px'} width={'24px'} />}</Box>
            <Typography sx={{ marginLeft: '8px', fontSize: '16px', fontWeight: '600', color: theme.palette.primary.main, cursor: 'pointer' }}>{workInstruction.workInstructionName}</Typography>
          </Box>
        );
      })}
    </>
  );
};
