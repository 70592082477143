import { Card, Box, CardContent, Breadcrumbs, Typography, Alert } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { User } from '../../entities/User/MockUser';
import UserForm from './UserForm';
import Loading from '../../components/Loading/Loading';
import Link, { LinkProps } from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import UserService from '../../services/User/UserService';
import { StyledCardDetailsView } from '../../components/Styled/StyledCardDetailsView';
import { Toast } from '../../components/Notifications/Notification';
import { getMessage } from '../../shared/utils/message-helpers';
import { trackEvent } from '../../App';
import { AmplitudeEvents } from '../../shared/enums';
import { useTranslation } from 'react-i18next';

interface AddUserProps {}

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

const LinkRouter = (props: LinkRouterProps) => <Link {...props} component={RouterLink as any} />;

const AddUser: React.FC<AddUserProps> = (props) => {
  const [error, setError] = useState('');
  const { t } = useTranslation();

  const breadcrumbs = [
    <Link underline="hover" key="1" sx={{ opacity: '0.7' }}>
      {t('NAVBAR.ADMINISTRATION') as string}
    </Link>,
    <Link underline="hover" key="2" sx={{ opacity: '0.7' }}>
      {t('NAVBAR.USER_MANAGEMENT') as string}
    </Link>,
    <LinkRouter underline="hover" key="3" to="/users" sx={{ opacity: '0.7' }}>
      {t('NAVBAR.USER') as string}
    </LinkRouter>,
    <Typography key="4" color="primary.main">
      {t('ADD_USER.CREATE_NEW_USER') as string}
    </Typography>,
  ];
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const addUserHandler = (userData) => {
    setLoading(true);
    UserService.createUser(userData)
      .then((e) => {
        setLoading(false);
        trackEvent(AmplitudeEvents.CREATE_NEW_USER, { created_by: localStorage.getItem('email'), persona: userData.personaId, email: userData.email, company: userData.organizationId });
        Toast.success(t('OPERATION_SUCCESS', { ns: 'success' }), t(e.data.messageCode, { ns: 'success' }) || e.data.message);
        backHandler();
      })
      .catch(({ response }) => {
        setLoading(false);
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
      });
  };

  const backHandler = () => {
    navigate('/users');
  };
  return (
    <>
      <Breadcrumbs sx={{ margin: '2rem' }} separator={<NavigateNextIcon color="primary" fontSize="small" />} aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>
      {loading && <Loading />}
      <StyledCardDetailsView>
        <Box
          component="div"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            textAlign: 'left',
          }}
        >
          <CardContent sx={{ flex: '1 0 auto', p: 0, '&:last-child': { pb: 0 } }} className="user-form-content">
            <UserForm onBack={backHandler} onSubmit={addUserHandler} title={t('ADD_USER.CREATE_NEW_USER')} />
          </CardContent>
        </Box>
      </StyledCardDetailsView>
    </>
  );
};

export default AddUser;
