import CloseIcon from '@mui/icons-material/Close';
import { FormikErrors, FormikProps, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { ETQPayload as ETQ, MaxLengthConstants } from '../../../shared/utils/const-helpers';
import CryptoJS from 'crypto-js';
import React from 'react';
import './IntegrationForm.scss';
import * as Yup from 'yup';
import { Backdrop, Box, Breadcrumbs, Button, FormControl, FormHelperText, FormLabel, Grid, InputLabel, MenuItem, Paper, Select, Tab, Tabs, TextField, Tooltip, Typography } from '@mui/material';
import TrainerService from '../../../services/trainer/TrainerService';
import { useTranslation } from 'react-i18next';
import { Toast } from '../../../components/Notifications/Notification';
import { environment } from '../../../environment/environment';
import Loading from '../../../components/Loading/Loading';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import qs from 'qs';

const IntegrationForm = () => {
  const [apiTabIndex, setApiTabIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [testConnectionCheck, setTestConnectionCheck] = useState(false);
  const [tokenGenerationUrlCheck, setTokenGenerationUrlCheck] = useState(false);
  const navigate = useNavigate();
  const { id = '' } = useParams();
  const { state } = useLocation();
  const integration = (state as any)?.integration;
  console.log(integration, 'stateeeeeee');
  let ETQPayload = { ...ETQ };
  const transformText = (text) => {
    if (!text) return '';

    const transformedText = text
      .toLowerCase()
      .replace(/_/g, ' ')
      .split(' ')
      .map((word) => {
        const capitalizedWord = word.charAt(0).toUpperCase() + word.slice(1);
        return capitalizedWord;
      })
      .join(' ');
    return transformedText;
  };
  if (integration?.integrationId) {
    ETQPayload = { ...integration };
  }

  const apis = [
    {
      name: 'createContent',
      fields: [
        { field: 'httpMethod', value: ETQPayload.actions.createContent.httpMethod },
        { field: 'httpEndpoint', value: ETQPayload.actions.createContent.endpoint },
        { field: 'httpPayload', value: JSON.stringify(ETQPayload.actions.createContent.payload, null, '\t') },
      ],
    },
    {
      name: 'upgradeContent',
      fields: [
        { field: 'httpMethod', value: ETQPayload.actions.upgradeContent.httpMethod },
        { field: 'httpEndpoint', value: ETQPayload.actions.upgradeContent.endpoint },
        { field: 'httpPayload', value: JSON.stringify(ETQPayload.actions.upgradeContent.payload, null, '\t') },
      ],
    },
    {
      name: 'updateUserProgress',
      fields: [
        { field: 'httpMethod', value: ETQPayload.actions.updateUserProgress.httpMethod },
        { field: 'httpEndpoint', value: ETQPayload.actions.updateUserProgress.endpoint },
        { field: 'httpPayload', value: JSON.stringify(ETQPayload.actions.updateUserProgress.payload, null, '\t') },
      ],
    },
    {
      name: 'enableVersion',
      fields: [
        { field: 'httpMethod', value: ETQPayload.actions.enableVersion.httpMethod },
        { field: 'httpEndpoint', value: ETQPayload.actions.enableVersion.endpoint },
        { field: 'httpPayload', value: JSON.stringify(ETQPayload.actions.enableVersion.payload, null, '\t') },
      ],
    },
  ];

  const generateValidationSchema = () => {
    const schema = {
      displayName: Yup.string(),
      clientId: Yup.string().required('Client ID is required'),
      clientSecret: Yup.string().required('Client Secret is required'),
      tokenGenerationUrl: Yup.string().required('Token Generation URL is required'),
      refreshTokenGenerationUrl: Yup.string(),
      lmsApplicationUrl: Yup.string().required('LMS Application URL is required'),
      lmsType: Yup.string().required('LMS Type is required'),
    };
    apis.forEach((api) => {
      api.fields.forEach((field) => {
        schema[`${api.name}_${field.field}`] = Yup.string().required(`${field.field} is required for ${api.name} API`);
      });
    });
    return Yup.object().shape(schema);
  };

  const decryptSecret = () => {
    const key = integration.integrationId;
    let applicationSecret = CryptoJS.AES.decrypt(ETQPayload.integrationClientSecretHash.toString(), key).toString(CryptoJS.enc.Utf8);
    return applicationSecret;
  };

  // Dynamic initial values generation
  const generateInitialValues = () => {
    const values = {
      displayName: ETQPayload.name,
      clientId: ETQPayload.integrationClientId,
      clientSecret: integration?.integrationId ? JSON.parse(decryptSecret()) : '',
      tokenGenerationUrl: ETQPayload.bearerTokenGenerationUrl,
      refreshTokenGenerationUrl: ETQPayload.refreshTokenGenerationUrl,
      lmsApplicationUrl: ETQPayload.applicationUrl,
      lmsType: ETQPayload.lmsType,
    };
    apis.forEach((api) => {
      api.fields.forEach((field) => {
        values[`${api.name}_${field.field}`] = field.value;
      });
    });
    return values;
  };

  const initialValuesGenerated = generateInitialValues();
  const initialSchemaGenerated = generateValidationSchema();
  const { t } = useTranslation();

  const addorUpdateIntegrationSystem = (values) => {
    const integrationPayload = { ...ETQPayload };
    integrationPayload.name = values.displayName;
    integrationPayload.lmsType = values.lmsType;
    integrationPayload.applicationUrl = values.lmsApplicationUrl;
    integrationPayload.integrationClientId = values.clientId;
    integrationPayload.bearerTokenGenerationUrl = values.tokenGenerationUrl;
    integrationPayload.refreshTokenGenerationUrl = values.refreshTokenGenerationUrl;

    const key = environment.AppConfigs.clientDataEncryptionHash;
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);
    const enc = CryptoJS.AES.encrypt(values.clientSecret, keyutf, { iv: iv });
    const encryptedClientSecret = enc.toString();

    integrationPayload.integrationClientSecretHash = encryptedClientSecret;

    apis.forEach((api) => {
      api.fields.forEach((field) => {
        if (field.field === 'httpPayload') {
          integrationPayload.actions[api.name].payload = { ...JSON.parse(values[`${api.name}_${field.field}`]) };
        } else if (field.field === 'httpMethod') {
          integrationPayload.actions[api.name].httpMethod = values[`${api.name}_${field.field}`];
        } else if (field.field === 'httpEndpoint') {
          integrationPayload.actions[api.name].endpoint = values[`${api.name}_${field.field}`];
        }
      });
    });
    if (integration?.integrationId) {
      updateIntegrationSystem(integrationPayload);
    } else {
      addIntegrationSystem(integrationPayload);
    }
  };

  const addIntegrationSystem = (integrationPayload) => {
    setLoading(true);
    TrainerService.addIntegrationSystem(integrationPayload)
      .then((res) => {
        Toast.success(t('OPERATION_SUCCESS', { ns: 'success' }), t(res.data.messageCode, { ns: 'success' }) || res.data.message);
        setLoading(false);
        navigate(`/integration/${transformText(id)}`);
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        setLoading(false);
      });
  };

  const updateIntegrationSystem = (integrationPayload) => {
    setLoading(true);
    TrainerService.updateIntegrationSystem(integrationPayload)
      .then((res) => {
        Toast.success(t('OPERATION_SUCCESS', { ns: 'success' }), t(res.data.messageCode, { ns: 'success' }) || res.data.message);
        setLoading(false);
        navigate(`/integration/${transformText(id)}`);
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        setLoading(false);
      });
  };

  const handleTestConnection = () => {
    setLoading(true);
    let Payload = qs.stringify({
      grant_type: 'client_credentials',
      client_id: formik.values.clientId,
      client_secret: formik.values.clientSecret,
      resource: formik.values.clientId,
    });

    let tokenGenerationUrl = formik.values.tokenGenerationUrl;

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    };

    const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
    if (regex.test(formik.values.tokenGenerationUrl)) {
      TrainerService.testConnection(Payload, tokenGenerationUrl, headers)
        .then((e) => {
          Toast.success(t('BUTTON.OPERATION_SUCCESS'), t('BUTTON.TEST_CONNECTION_IS_SUCCESSFUL'));
          setLoading(false);
          setTestConnectionCheck(true);
        })
        .catch((e) => {
          Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t('BUTTON.TEST_CONNECTION_HAS_FAILED'));
          setLoading(false);
        });
    } else {
      setLoading(false);
      Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t('BUTTON.TEST_CONNECTION_HAS_FAILED'));
    }
  };

  const formik = useFormik({
    initialValues: initialValuesGenerated,
    validationSchema: initialSchemaGenerated,
    onSubmit: (values) => {
      // Handle form submission logic here
      addorUpdateIntegrationSystem(values);
    },
  });

  const handleApiTabChange = (event, newValue) => {
    setApiTabIndex(newValue);
  };

  const handleBack = () => {
    navigate(`/integration/${transformText(id)}`);
  };
  useEffect(() => {
    if (formik.values.tokenGenerationUrl === '') {
      setTokenGenerationUrlCheck(true);
    } else {
      setTokenGenerationUrlCheck(false);
    }
  }, [formik.values.tokenGenerationUrl]);

  return (
    <>
      {loading && (
        <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
          <Loading />
        </Backdrop>
      )}
      <form onSubmit={formik.handleSubmit}>
        <Grid container sx={{ display: 'flex', padding: '24px' }} spacing={2}>
          <Grid item xs={12} sm={12}>
            <Box component="div" sx={{ borderBottom: '1px solid #D8D8D8', paddingBottom: '24px', marginBottom: '24px' }}>
              <Typography>{window.location.pathname.includes("edit")?t('BUTTON.EDIT_CONNECTOR'): t('BUTTON.CREATE_CONNECTOR')}</Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl variant="standard" fullWidth>
              <FormLabel className="integration-label" sx={{ display: 'flex', flexDirection: 'row' }}>
                <Typography sx={{ marginRight: '8px' }}>{t('BUTTON.GIVE_A_DISPLAY_NAME')}</Typography>
                <Typography>
                  <Tooltip placement={'right'} title={t('BUTTON.PLEASE_ENTER_APPLICATION_NAME')}>
                    <Box component="div">
                      <HelpOutlineIcon sx={{ height: '18px', width: '18px' }} />
                    </Box>
                  </Tooltip>
                </Typography>{' '}
              </FormLabel>
              <TextField id="component-outlined" className="integration-field" placeholder={t('BUTTON.ENTER_A_NAME')} label="" variant="outlined" margin="normal" name="displayName" onChange={formik.handleChange} value={formik.values.displayName} />
              {Boolean(formik?.touched?.displayName && formik?.errors?.displayName) && <FormHelperText error>{formik.errors.displayName}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl variant="standard" fullWidth required>
              <FormLabel className="integration-label" sx={{ display: 'flex', flexDirection: 'row' }}>
                <Typography sx={{ marginRight: '8px' }}>{t('BUTTON.LMS_TYPE')}</Typography>
                <Typography>
                  <Tooltip placement={'right'} title={t('BUTTON.PLEASE_ENTER_APPLICATION_TYPE')}>
                    <Box component="div">
                      <HelpOutlineIcon sx={{ height: '18px', width: '18px' }} />
                    </Box>
                  </Tooltip>
                </Typography>{' '}
              </FormLabel>
              <TextField fullWidth label="" placeholder={t('BUTTON.SELECT_LMS_TYPE')} className="integration-field" variant="outlined" margin="normal" id="lmsType" name="lmsType" onChange={formik.handleChange} value={formik.values.lmsType} select>
                <MenuItem value="ETQ">ETQ</MenuItem>
              </TextField>
              {Boolean(formik?.touched?.lmsType && formik?.errors?.lmsType) && <FormHelperText error>{formik.errors.lmsType}</FormHelperText>}
            </FormControl>
          </Grid>
          <Box component={'div'} width="100%" />
          <Grid item xs={12} sm={4}>
            <FormControl variant="standard" fullWidth required>
              <FormLabel className="integration-label" sx={{ display: 'flex', flexDirection: 'row' }}>
                <Typography sx={{ marginRight: '8px' }}>{t('BUTTON.CLIENT_ID')}</Typography>
                <Typography>
                  <Tooltip placement={'right'} title={t('BUTTON.PLEASE_ENTER_CLIENT_ID')}>
                    <Box component="div">
                      <HelpOutlineIcon sx={{ height: '18px', width: '18px' }} />
                    </Box>
                  </Tooltip>
                </Typography>{' '}
              </FormLabel>
              <TextField fullWidth placeholder={t('BUTTON.ENTER_CLIENT_ID')} className="integration-field" label="" variant="outlined" margin="normal" id="clientId" name="clientId" onChange={formik.handleChange} value={formik.values.clientId} />
              {Boolean(formik?.touched?.clientId && formik?.errors?.clientId) && <FormHelperText error>{formik.errors.clientId}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl variant="standard" fullWidth required>
              <FormLabel className="integration-label" sx={{ display: 'flex', flexDirection: 'row' }}>
                <Typography sx={{ marginRight: '8px' }}>{t('BUTTON.CLIENT_SECRET')}</Typography>
                <Typography>
                  <Tooltip placement={'right'} title={t('BUTTON.PLEASE_ENTER_CLIENT_SECRET')}>
                    <Box component="div">
                      <HelpOutlineIcon sx={{ height: '18px', width: '18px' }} />
                    </Box>
                  </Tooltip>
                </Typography>{' '}
              </FormLabel>
              <TextField fullWidth placeholder={t('BUTTON.ENTER_CLIENT_SECRET')} className="integration-field" variant="outlined" margin="normal" id="clientSecret" name="clientSecret" onChange={formik.handleChange} value={formik.values.clientSecret} />
              {Boolean(formik?.touched?.clientSecret && formik?.errors?.clientSecret) && <FormHelperText error>{formik.errors.clientSecret?.toString()}</FormHelperText>}
            </FormControl>
          </Grid>
          <Box component={'div'} width="100%" />
          <Grid item xs={12} sm={4}>
            <FormControl variant="standard" fullWidth required>
              <FormLabel className="integration-label" sx={{ display: 'flex', flexDirection: 'row' }}>
                <Typography sx={{ marginRight: '8px' }}>{t('BUTTON.TOKEN_GENERATION_URL')}</Typography>
                <Typography>
                  <Tooltip placement={'right'} title={t('BUTTON.PLEASE_ENTER_TOKEN_GENERATION_URL')}>
                    <Box component="div">
                      <HelpOutlineIcon sx={{ height: '18px', width: '18px' }} />
                    </Box>
                  </Tooltip>
                </Typography>
              </FormLabel>
              <TextField fullWidth placeholder={t('BUTTON.ENTER_TOKEN_GENERATION_URL')} className="integration-field" label="" variant="outlined" margin="normal" id="tokenGenerationUrl" name="tokenGenerationUrl" onChange={formik.handleChange} value={formik.values.tokenGenerationUrl} />
              {Boolean(formik?.touched?.tokenGenerationUrl && formik?.errors?.tokenGenerationUrl) && <FormHelperText error>{formik.errors.tokenGenerationUrl}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl variant="standard" fullWidth>
              <FormLabel className="integration-label" sx={{ display: 'flex', flexDirection: 'row' }}>
                <Typography sx={{ marginRight: '8px' }}>{t('BUTTON.REFRESH_TOKEN_GENERATION_URL')}</Typography>
                <Typography></Typography>
              </FormLabel>
              <TextField fullWidth placeholder={t('BUTTON.ENTER_REFRESH_TOKEN_GENERATION_URL')} className="integration-field" label="" variant="outlined" margin="normal" id="refreshTokenGenerationUrl" name="refreshTokenGenerationUrl" onChange={formik.handleChange} value={formik.values.refreshTokenGenerationUrl} />
              {Boolean(formik?.touched?.refreshTokenGenerationUrl && formik?.errors?.refreshTokenGenerationUrl) && <FormHelperText error>{formik.errors.refreshTokenGenerationUrl}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl variant="standard" fullWidth required>
              <FormLabel className="integration-label" sx={{ display: 'flex', flexDirection: 'row' }}>
                <Typography sx={{ marginRight: '8px' }}>{t('BUTTON.LMS_APPLICATION_URL')}</Typography>
                <Typography>
                  <Tooltip placement={'right'} title={t('BUTTON.PLEASE_ENTER_APPLICATION_URL')}>
                    <Box component="div">
                      <HelpOutlineIcon sx={{ height: '18px', width: '18px' }} />
                    </Box>
                  </Tooltip>
                </Typography>{' '}
              </FormLabel>
              <TextField fullWidth placeholder={t('BUTTON.ENTER_LMS_APPLICATION_URL')} className="integration-field" label="" variant="outlined" margin="normal" id="lmsApplicationUrl" name="lmsApplicationUrl" onChange={formik.handleChange} value={formik.values.lmsApplicationUrl} />
              {Boolean(formik?.touched?.lmsApplicationUrl && formik?.errors?.lmsApplicationUrl) && <FormHelperText error>{formik.errors.lmsApplicationUrl}</FormHelperText>}
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="contained" color="primary" sx={{ marginRight: '24px', marginBottom: '24px' }} disabled={tokenGenerationUrlCheck} onClick={() => handleTestConnection()}>
            {t('BUTTON.TEST_CONNECTION')}
          </Button>
        </Grid>
        {testConnectionCheck && (
          <Grid container className="integration" sx={{ padding: '24px 24px' }} spacing={2}>
            {formik.values.lmsType === 'ETQ' && (
              <>
                <Grid item xs={12}>
                  <Typography>{t('BUTTON.APIS')}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Tabs value={apiTabIndex} onChange={handleApiTabChange} indicatorColor="primary" textColor="primary" sx={{ marginBottom: '24px' }}>
                    <Tab label={t('BUTTON.CREATE_CONTENT_API')} />
                    <Tab label={t('BUTTON.UPGRADE_CONTENT_API')} />
                    <Tab label={t('BUTTON.UPDATE_USER_PROGRESS_API')} />
                    <Tab label={t('BUTTON.ENABLE_VERSION_API')} />
                  </Tabs>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl variant="standard" fullWidth required>
                    <FormLabel className="integration-label">{t('BUTTON.HTTP_METHOD')}</FormLabel>
                    <TextField placeholder="Select HTTP Method" className="integration-field" label="" variant="outlined" margin="normal" name={`${apis[apiTabIndex].name}_${apis[apiTabIndex].fields[0].field}`} value={formik.values[`${apis[apiTabIndex].name}_${apis[apiTabIndex].fields[0].field}`]} onChange={formik.handleChange} select>
                      <MenuItem value="POST"> POST</MenuItem>
                      <MenuItem value="PATCH">PATCH</MenuItem>
                      <MenuItem value="GET">GET</MenuItem>
                      <MenuItem value="PUT">PUT</MenuItem>
                      <MenuItem value="DELETE">DELETE</MenuItem>
                    </TextField>
                    {Boolean(formik?.touched[`${apis[apiTabIndex].name}_${apis[apiTabIndex].fields[0].field}`] && formik?.errors[`${apis[apiTabIndex].name}_${apis[apiTabIndex].fields[0].field}`]) && <FormHelperText error>{formik.errors[`${apis[apiTabIndex].name}_${apis[apiTabIndex].fields[0].field}`]}</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl variant="standard" fullWidth required>
                    <FormLabel className="integration-label">{t('BUTTON.HTTP_ENDPOINT')}</FormLabel>
                    <TextField fullWidth label="" placeholder="Enter an endpoint URL" className="integration-field" variant="outlined" margin="normal" name={`${apis[apiTabIndex].name}_${apis[apiTabIndex].fields[1].field}`} value={formik.values[`${apis[apiTabIndex].name}_${apis[apiTabIndex].fields[1].field}`]} onChange={formik.handleChange} />
                    {Boolean(formik?.touched[`${apis[apiTabIndex].name}_${apis[apiTabIndex].fields[1].field}`] && formik?.errors[`${apis[apiTabIndex].name}_${apis[apiTabIndex].fields[1].field}`]) && <FormHelperText error>{formik.errors[`${apis[apiTabIndex].name}_${apis[apiTabIndex].fields[1].field}`]}</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <FormControl variant="standard" fullWidth required>
                    <FormLabel className="integration-label">{t('BUTTON.HTTP_PAYLOAD')}</FormLabel>
                    <TextField fullWidth multiline rows={10} label="" placeholder="Enter Payload" className="integration-field" variant="outlined" margin="normal" name={`${apis[apiTabIndex].name}_${apis[apiTabIndex].fields[2].field}`} value={formik.values[`${apis[apiTabIndex].name}_${apis[apiTabIndex].fields[2].field}`]} onChange={formik.handleChange} />
                    {Boolean(formik?.touched[`${apis[apiTabIndex].name}_${apis[apiTabIndex].fields[2].field}`] && formik?.errors[`${apis[apiTabIndex].name}_${apis[apiTabIndex].fields[2].field}`]) && <FormHelperText error>{formik.errors[`${apis[apiTabIndex].name}_${apis[apiTabIndex].fields[2].field}`]}</FormHelperText>}
                  </FormControl>
                </Grid>
              </>
            )}
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button color="primary" sx={{ marginRight: '8px' }} onClick={() => handleBack()}>
                {t('BUTTON.CANCEL')}
              </Button>
              <Button type="submit" variant="contained" color="primary">
                {integration?.integrationId ? t('BUTTON.UPDATE') : t('BUTTON.CREATE')}
              </Button>
            </Grid>
          </Grid>
        )}
      </form>
    </>
  );
};

export default IntegrationForm;
