import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Drawer, Typography, Box, useTheme, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Loading from '../../../Loading/Loading';
import { IWorkInstructions } from '../../../../entities/Trainee/MockTrainee';
import { BootstrapDialogTitle } from '../../../Dialog/mobileDialog/dialogTitle';
import TutorialService from '../../../../services/trainee/TraineeService';
import { getFormattedDateFromUnixTime } from '../../../../shared/utils/formateDate';
import { useNavigate } from 'react-router-dom';
import { environment } from '../../../../environment/environment';
import UserContext from '../../../../context/UserContext';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import TableContainer from '../TableContainer';
interface Props {
  traineesData?: any;
  showRightPaneComment?: boolean;
  setShowRightPaneComment?: any;
  activeThreadId?: string;
  setActiveThreadId?: any;
  setShowChats?: any;
  showChats?: boolean;
  procedure?: any;
  workInstruction?: IWorkInstructions | null;
  drawerLoading?: boolean;
  userId?: any;
  notification?: any;
  isTrainer?: boolean;
}

export const CommentSliderWindow = (props: Props) => {
  const [activeRow, setActiveRow] = useState(-1);
  const [drawerLoading, setDrawerLoading] = useState<boolean>(false);
  const [commentsThreadData, setCommentsThreadData] = React.useState<any>([]);
  const [listStepCommentThreads, setListStepCommentThreads] = React.useState<any>([]);
  const [unreadCountData, setUnreadCountData] = React.useState<any>([]);
  const studioUrl = environment.AppConfigs.studioURL;
  
  const getCommentThreadData = (procedureId: string) => {
    setDrawerLoading(true);
    TutorialService.listStepCommentThreads(procedureId)
      .then((e) => {
        let arr: any = [];
        setListStepCommentThreads(e.data.data);
        let unreadCount: any = [];
        e?.data?.data?.map((threadData) => {
          arr.push({
            workInstruction: props?.workInstruction?.workInstructionName || props?.notification?.target?.workInstructionName,
            procedure: props?.procedure?.procedureName || props?.notification?.target?.procedureName,
            stepName: threadData.stepName,
            startedOn: threadData.startedOn,
            startedBy: `${threadData.firstComment.userFirstName} ${threadData.firstComment.userLastName}`,
            comment: threadData.lastComment.comment,
            stepNo: threadData.stepNo,
            unreadCount: threadData.unreadCount
          });
          unreadCount.push({
            unreadCount: threadData.unreadCount,
          });
        });
        setUnreadCountData(unreadCount);
        setCommentsThreadData(arr);
        setDrawerLoading(false);
      })
      .catch(({ response }) => {
        setDrawerLoading(false);
      });
  };

  const navigate = useNavigate();
  const { currentUser } = useContext(UserContext);
  const companyId = localStorage.getItem('company');

  const HandleNavigation = (event, value) => {
    event?.stopPropagation();

    if (props.isTrainer) {
      var query = new URLSearchParams();
      query.append('procedureId', props?.procedure?.procedureId || props?.notification?.target?.procedureId);
      query.append('model', props?.procedure?.workInstructionId || props?.notification?.target?.workInstructionId);
      query.append('org', props?.procedure?.organizationId || currentUser?.organizationId);
      if (props?.workInstruction?.is2d) {
        query.append('workInstructionType', '2d');
      }
      query.append('isQueryExist', 'true');
      // query.append('authToken', authToken);
      var url = `${studioUrl}${query.toString()}`;
      window.open(url, '_blank', 'noopener,noreferrer');
    } else {
      let procedureId = props?.notification?.target?.procedureId || props?.procedure?.procedureId;
      let workInstructionId = props?.notification?.target?.workInstructionId || props?.procedure?.workInstructionId;
      let is2d = props?.workInstruction?.is2d || props?.notification?.target?.is2d;
      if (props?.notification?.target?.is2d) {
        navigate(`/assembly/${companyId}/${workInstructionId}/${procedureId}?workInstructionType=${is2d ? '2d' : '3d'}&isViewComment=true&stepNo=${value}`);
      } else {
        window.location.href = `/assembly/${companyId}/${workInstructionId}/${procedureId}?workInstructionType=${is2d ? '2d' : '3d'}&isViewComment=true&stepNo=${value}`;
      }
    }
  };

  useEffect(() => {
    if (props.showChats === true && (props.procedure || props.notification)) {
      let procedureId = props?.procedure?.procedureId || props?.notification?.target?.procedureId;
      getCommentThreadData(procedureId);
    }
  }, [props.showChats]);

  const showGoToAllCommentsColumn = !props.isTrainer;
  const columnsComment = useMemo(
    () =>
      [
        {
          Header: 'Work Instruction',
          accessor: 'workInstruction',
          Cell: ({ value, row }) => <Typography sx={{ marginLeft: '8px', fontSize: '14px', fontWeight: row.original.unreadCount ? 600 : 400 }}>{value}</Typography>,
        },
        {
          Header: 'Procedure',
          accessor: 'procedure',
          Cell: ({ value, row }) => <Typography sx={{ marginLeft: '8px', fontSize: '14px', fontWeight: row.original.unreadCount ? 600 : 400 }}>{value}</Typography>,
        },
        {
          Header: 'Step Name',
          accessor: 'stepName',
          Cell: ({ value, row }) => <Typography sx={{ marginLeft: '8px', fontSize: '14px', fontWeight: row.original.unreadCount ? 600 : 400 }}>{value}</Typography>,
        },
        {
          Header: 'Date',
          accessor: 'startedOn',
          Cell: ({ value, row }) => <span style={{ fontSize: '14px', fontWeight: row.original.unreadCount ? 600 : 400 }}>{getFormattedDateFromUnixTime(value / 1000)}</span>,
        },
        {
          Header: 'Trainee Name',
          accessor: 'startedBy',
          Cell: ({ value, row }) => <span style={{ fontSize: '14px', fontWeight: row.original.unreadCount ? 600 : 400 }}>{value}</span>,
        },
        {
          Header: 'Comment',
          accessor: 'comment',
          Cell: ({ value, row }) => (
            <Typography style={{ fontSize: '14px', fontWeight: row.original.unreadCount ? 600 : 400 }}>
              {value}{" "}
              {row.original.unreadCount > 1 ? (
                <span style={{ color: theme.palette.primary.main }}>
                  +{row.original.unreadCount - 1} {row.original.unreadCount == 2 ? t('ANALYTICS_SECTION.UNREAD_COMMENT') : t('ANALYTICS_SECTION.UNREAD_COMMENTS')}
                </span>
              ) : (
                <></>
              )}
            </Typography>
          ),
        },
        showGoToAllCommentsColumn && {
          Header: 'Go to All Comments',
          accessor: 'stepNo',
          disableSortBy: true,
          Filter: () => <></>,
          Cell: ({ value }) => {
            return (
              <Box component="div" sx={{ display: 'flex', justifyContent: 'center' }}>
                <IconButton onClick={(e) => HandleNavigation(e, value)} className="media-2d-control-right" sx={{ alignItems: 'center' }}>
                  <ArrowForwardIosIcon />
                </IconButton>
              </Box>
            );
          },
        },
      ].filter(Boolean),
    [showGoToAllCommentsColumn],
  );

  const toggleDrawer = (status) => {
    props.setShowChats(status);
    if (status === false) {
    }
  };

  const onRowClick = (row) => {
    setActiveRow(row.id);
    props.setActiveThreadId(listStepCommentThreads[row.id]._id);
    props.setShowRightPaneComment(true);
  };

  const { t } = useTranslation();
  const theme = useTheme();

  function shadeColor(color, percent) {
    var R = parseInt(color.substring(1, 3), 16);
    var G = parseInt(color.substring(3, 5), 16);
    var B = parseInt(color.substring(5, 7), 16);

    R = (R * (100 - percent) + percent * 255) / 100;
    G = (G * (100 - percent) + percent * 255) / 100;
    B = (B * (100 - percent) + percent * 255) / 100;

    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;
    R = Math.round(R);
    G = Math.round(G);
    B = Math.round(B);

    var RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16);
    var GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16);
    var BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16);

    return '#' + RR + GG + BB;
  }
  const themePrimaryExtraLight = shadeColor(theme.palette.primary.main, 87);

  return (
    <>
      <Drawer
        anchor={'bottom'}
        open={props.showChats}
        PaperProps={{
          sx: { width: '100%', height: '100%' },
        }}
      >
        <BootstrapDialogTitle onClose={() => toggleDrawer(false)} commonSliderTitle={true}>
          {t('NAVBAR.NOTIFICATIONS')}
        </BootstrapDialogTitle>
        {/* <Box sx={{ margin: '24px' }}>
          <Typography>
            {t('ADD_WI.WORK_INSTRUCTION')} {t('COMMON.NAME')}: {props.workInstruction?.workInstructionName || props?.notification?.target?.workInstructionName}
          </Typography>
          <Typography>
            {t('ADD_WI.PROCEDURE')} {t('COMMON.NAME')}: {props?.procedure?.procedureName || props?.notification?.target?.procedureName}
          </Typography>
        </Box> */}
        {drawerLoading && (
          <Box sx={{ position: 'relative', height: '100%' }}>
            <Loading />
          </Box>
        )}

        {!drawerLoading && (
          <Box sx={{ margin: '24px 24px' }}>
            <TableContainer columns={columnsComment} data={commentsThreadData} onRowClick={onRowClick} commentThreadTable={true} />
          </Box>
        )}
      </Drawer>
    </>
  );
};
