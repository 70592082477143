import useCurrentUser from './useCurrentUser';

export const useHasPermissions = (permission: string | Array<string>, requireAll: boolean = true): boolean => {
  const { currentUser } = useCurrentUser();
  const { scopes } = currentUser;

  try {
    const permissions = Object.keys(scopes).map(resource => scopes[resource].map(action => resource + '.' + action)).reduce((pre, cur) => pre.concat(cur));
    const values = typeof permission !== 'object' ? [permission] : permission;
    const access = values.filter((value) => permissions.indexOf(value) > -1);

    return requireAll ? (access.length === values.length) : (access.length > 0);
  } catch (error) {
    return false;
  }
};
