import { Box, Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export const Comments = ({ comments }) => {
  return (
    <>
      {comments.map((comment, index) => (
        <Box
          component="div"
          key={`key-${index}`}
          sx={{
            pb: 2,
            mb: 2,
            width: '500px',

            borderBottom: '1px solid #80838869',
          }}
        >
          <Typography sx={{ mb: 2, fontSize: '14px' }}>
            <AccountCircleIcon sx={{ mb: '-7px' }} /> {comment?.firstName + ' ' + comment?.lastName}
          </Typography>
          <Typography sx={{ color: '#808388', fontSize: '14px', ml: '8px' }}>{comment.comment}</Typography>
        </Box>
      ))}
    </>
  );
};
