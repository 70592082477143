import { useEffect } from "react";
import LinearWithValueLabel from "../LinearProgressWithLabel/LinearProgressWithLabel";
import "./Loading.scss";
import { useTranslation } from "react-i18next";
type Props = {
    children?: React.ReactNode;
    positionFixed?: boolean;
};

const Loading = ({ children, positionFixed }: Props) => {
    const drawerWidth = 80;
    const { t } = useTranslation();

    return (
        <div className={positionFixed ?  'container-fixed' : 'container'}>
            {children && <div>{children}</div>}
            {!children && (
                <div>
                    <LinearWithValueLabel
                        progress={50}
                        message={t('COMMON.LOADING')}
                    />
                </div>
            )}
            <div className="scene">
                <div className="webpack-cube">
                    <div className="outer-cube">
                        <div className="face face-top"></div>
                        <div className="face face-bottom"></div>
                        <div className="face face-left"></div>
                        <div className="face face-right"></div>
                        <div className="face face-front"></div>
                        <div className="face face-back"></div>
                    </div>
                    <div className="inner-cube">
                        <div className="face face-top"></div>
                        <div className="face face-bottom"></div>
                        <div className="face face-left"></div>
                        <div className="face face-right"></div>
                        <div className="face face-front"></div>
                        <div className="face face-back"></div>
                    </div>
                </div>
                <div className="shadows-outer-container">
                    <div className="shadow-outer"></div>
                </div>
                <div className="shadows-inner-container">
                    <div className="shadow-inner"></div>
                </div>
            </div>
        </div>
    );
};

export default Loading;
