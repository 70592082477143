import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
//import './i18n';
import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import { styled } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { getMsalConfig } from './authConfig';
import { FileUploadProvider } from './shared/context';
import { SnackbarProvider } from 'notistack';
import NotificationSnackbar from './components/Notifications/NotificationSnackbar';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import App from './App';
import ReactGA from 'react-ga4';
import { environment } from './environment/environment';

import { Provider } from 'react-redux';
import { store } from './redux/store';

if (!environment.AppConfigs.govServer) {
  ReactGA.initialize(environment.AppConfigs.googleAnalyticsTag);
}

const SendAnalytics = () => {
  if (!environment.AppConfigs.govServer) {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
    });
  }
};

export const msalInstance = new PublicClientApplication(getMsalConfig(localStorage.getItem('opco'), localStorage.getItem('email')));

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <MsalProvider instance={msalInstance}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <SnackbarProvider
          autoHideDuration={5000}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          preventDuplicate={true}
          Components={{
            default: NotificationSnackbar,
            error: NotificationSnackbar,
            success: NotificationSnackbar,
            warning: NotificationSnackbar,
            info: NotificationSnackbar,
          }}
        >
          <BrowserRouter>
            <FileUploadProvider>
              <App />
            </FileUploadProvider>
          </BrowserRouter>
        </SnackbarProvider>
      </LocalizationProvider>
    </MsalProvider>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (!environment.AppConfigs.govServer) {
  reportWebVitals(SendAnalytics);
}
