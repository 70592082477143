import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
// @ts-ignore
import { deepEqual } from 'fast-equals';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import * as React from 'react';
import { FormikProps } from 'formik';
import { IOrganizationFormValues } from '.';
import { COUNTRY_LIST } from '../../shared/data/country-list';
import { US_STATES } from '../../shared/data/us-states';
import { US_CITIES } from '../../shared/data/us-cities';
import { MaxLengthConstants } from '../../shared/utils/const-helpers';

interface AddressFormProps {
  formik: FormikProps<IOrganizationFormValues>;
  onLocateInMapClick: () => void;
}

const render = (status: Status) => {
  return <h1>{status}</h1>;
};

const AddressForm: React.FC<AddressFormProps> = ({
  formik,
  onLocateInMapClick,
}) => {
  // ! Create google map API key and use here
  const apiKey = 'AIzaSyB41DRUbKWJHPxaFjMAwdrzWzbVKartNGg';

  const [center, setCenter] = React.useState<google.maps.LatLngLiteral>({
    lat: 0,
    lng: 0,
  });

  const onIdle = (m: google.maps.Map) => {
    setCenter(m.getCenter()!.toJSON());
  };

  return (
    <>
      <Box component={'div'}>
        <Typography
          variant="h6"
          component="div"
          className="page-title"
          sx={{
            position: 'relative',
            '&::after': {
              content: '""',
              background:
                'linear-gradient(90deg, #000000 0.71%, rgba(0, 0, 0, 0) 100%)',
              position: 'absolute',
              bottom: 0,
              left: '0px',
              width: '57%',
              height: '1px',
            },
          }}
        >
          Address Details
        </Typography>
      </Box>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={7}
            lg={7}
            sx={{
              borderRight: { xs: 0, md: '1px solid #D8D8D8' },
              borderBottom: { xs: '1px solid #D8D8D8', md: 0 },
            }}
          >
            <Grid
              container
              spacing={4}
              sx={{ paddingTop: '32px', paddingRight: '32px' }}
            >
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControl variant="standard" required fullWidth>
                  <FormLabel>Address Line1</FormLabel>
                  <TextField
                    label=""
                    name="addressForm.addressLine1"
                    value={formik.values.addressForm.addressLine1}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    placeholder="Enter Address Line1"
                    style={{ borderRadius: '8px !important' }}
                    inputProps={{
                      maxLength: MaxLengthConstants.Common.address,
                    }}
                    required
                    error={Boolean(
                      formik?.touched?.addressForm?.addressLine1 &&
                        formik?.errors?.addressForm?.addressLine1,
                    )}
                  />
                  {!Boolean(
                    formik?.touched?.addressForm?.addressLine1 &&
                      formik?.errors?.addressForm?.addressLine1,
                  ) && (
                    <FormHelperText className="helperText">
                      {MaxLengthConstants.Common.address -
                        formik.values.addressForm.addressLine1.length}{' '}
                      Characters Remaining
                    </FormHelperText>
                  )}
                  {Boolean(
                    formik?.touched?.addressForm?.addressLine1 &&
                      formik?.errors?.addressForm?.addressLine1,
                  ) && (
                    <FormHelperText className="helperText" error={true}>
                      {formik?.errors?.addressForm?.addressLine1}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControl variant="standard" fullWidth>
                  <FormLabel>Address Line2</FormLabel>
                  <TextField
                    label=""
                    name="addressForm.addressLine2"
                    value={formik.values.addressForm.addressLine2}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    placeholder="Enter Address Line2"
                    style={{ borderRadius: '8px !important' }}
                    inputProps={{
                      maxLength: MaxLengthConstants.Common.address,
                    }}
                    error={Boolean(
                      formik?.touched?.addressForm?.addressLine2 &&
                        formik?.errors?.addressForm?.addressLine2,
                    )}
                  />
                  {!Boolean(
                    formik?.touched?.addressForm?.addressLine2 &&
                      formik?.errors?.addressForm?.addressLine2,
                  ) && (
                    <FormHelperText className="helperText">
                      {MaxLengthConstants.Common.address -
                        formik.values.addressForm.addressLine2.length}{' '}
                      Characters Remaining
                    </FormHelperText>
                  )}
                  {Boolean(
                    formik?.touched?.addressForm?.addressLine2 &&
                      formik?.errors?.addressForm?.addressLine2,
                  ) && (
                    <FormHelperText className="helperText" error={true}>
                      {formik?.errors?.addressForm?.addressLine2}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControl fullWidth required>
                  <FormLabel>Country</FormLabel>
                  <Select
                    name="addressForm.country"
                    value={formik.values.addressForm.country}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    displayEmpty
                    required
                    error={Boolean(
                      formik?.touched?.addressForm?.country &&
                        formik?.errors?.addressForm?.country,
                    )}
                    renderValue={(selected) => {
                      if (selected === '') {
                        return 'Select Country';
                      }
                      return selected;
                    }}
                  >
                    <MenuItem disabled value="">
                      Select Country
                    </MenuItem>
                    {COUNTRY_LIST.map((country) => (
                      <MenuItem key={country.code} value={country.name}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {Boolean(
                    formik?.touched?.addressForm?.country &&
                      formik?.errors?.addressForm?.country,
                  ) && (
                    <FormHelperText className="helperText" error={true}>
                      {formik?.errors?.addressForm?.country}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControl fullWidth required>
                  <FormLabel>State or Province</FormLabel>
                  <Autocomplete
                    defaultValue={"Enter State or Province"}
                    freeSolo
                    id="addressForm.state"
                    value={formik.values.addressForm.state}
                    onBlur={formik.handleBlur}
                    onChange={(event, value) => {
                      formik.setFieldValue('addressForm.state', value ?? '');
                    }}
                    options={
                      formik?.values?.addressForm?.country === 'United States'
                        ? US_STATES.map((state) => state.name)
                        : []
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Enter State or Province"
                        name="addressForm.state"
                        required
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.addressForm.state}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password', // disable autocomplete and autofill
                          maxLength: MaxLengthConstants.Common.state,
                        }}
                        error={Boolean(
                          formik?.touched?.addressForm?.state &&
                            formik?.errors?.addressForm?.state,
                        )}
                      />
                    )}
                  />
                  {!Boolean(
                    formik?.touched?.addressForm?.state &&
                      formik?.errors?.addressForm?.state,
                  ) && (
                    <FormHelperText className="helperText">
                      {MaxLengthConstants.Common.state -
                        (formik?.values?.addressForm?.state?.length ?? 0)}{' '}
                      Characters Remaining
                    </FormHelperText>
                  )}
                  {Boolean(
                    formik?.touched?.addressForm?.state &&
                      formik?.errors?.addressForm?.state,
                  ) && (
                    <FormHelperText className="helperText" error={true}>
                      {formik?.errors?.addressForm?.state}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControl fullWidth required>
                  <FormLabel>City</FormLabel>
                  <Autocomplete
                    defaultValue={"Enter City"}
                    freeSolo
                    id="addressForm.city"
                    value={formik.values.addressForm.city}
                    onBlur={formik.handleBlur}
                    onChange={(event, value) => {
                      formik.setFieldValue('addressForm.city', value ?? '');
                    }}
                    options={
                      formik?.values?.addressForm?.country ===
                        'United States' &&
                      formik?.values?.addressForm?.state !== ''
                        ? US_CITIES.filter(
                            (city) =>
                              city.state === formik?.values?.addressForm?.state,
                          ).map((city) => city.city)
                        : []
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Enter City"
                        name="addressForm.city"
                        required
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.addressForm.city}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password', // disable autocomplete and autofill
                          maxLength: MaxLengthConstants.Common.city,
                        }}
                        error={Boolean(
                          formik?.touched?.addressForm?.city &&
                            formik?.errors?.addressForm?.city,
                        )}
                      />
                    )}
                  />
                  {/* <Select
                    name="addressForm.city"
                    value={formik.values.addressForm.city}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    displayEmpty
                    required
                    error={Boolean(
                      formik?.touched?.addressForm?.city &&
                        formik?.errors?.addressForm?.city,
                    )}
                    renderValue={(selected) => {
                      if (selected === '') {
                        return 'Select City';
                      }
                      return selected;
                    }}
                  >
                    <MenuItem disabled value="">
                      Select City
                    </MenuItem>
                    {US_CITIES.filter(
                      (city) =>
                        city.state === formik?.values?.addressForm?.state,
                    ).map((city, index) => (
                      <MenuItem key={index} value={city.city}>
                        {city.city}
                      </MenuItem>
                    ))}
                  </Select> */}
                  {!Boolean(
                    formik?.touched?.addressForm?.city &&
                      formik?.errors?.addressForm?.city,
                  ) && (
                    <FormHelperText className="helperText">
                      {MaxLengthConstants.Common.city -
                        (formik?.values?.addressForm?.city?.length ?? 0)}{' '}
                      Characters Remaining
                    </FormHelperText>
                  )}
                  {Boolean(
                    formik?.touched?.addressForm?.city &&
                      formik?.errors?.addressForm?.city,
                  ) && (
                    <FormHelperText className="helperText" error={true}>
                      {formik?.errors?.addressForm?.city}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControl variant="standard" required fullWidth>
                  <FormLabel>Postal Code</FormLabel>
                  <TextField
                    label=""
                    name="addressForm.postalCode"
                    value={formik.values.addressForm.postalCode}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    placeholder="Enter Postal Code"
                    style={{ borderRadius: '8px !important' }}
                    inputProps={{ maxLength: MaxLengthConstants.Common.zip }}
                    required
                    error={Boolean(
                      formik?.touched?.addressForm?.postalCode &&
                        formik?.errors?.addressForm?.postalCode,
                    )}
                  />
                  {!Boolean(
                    formik?.touched?.addressForm?.postalCode &&
                      formik?.errors?.addressForm?.postalCode,
                  ) && (
                    <FormHelperText className="helperText">
                      {MaxLengthConstants.Common.zip -
                        formik.values.addressForm.postalCode.length}{' '}
                      Characters Remaining
                    </FormHelperText>
                  )}
                  {Boolean(
                    formik?.touched?.addressForm?.postalCode &&
                      formik?.errors?.addressForm?.postalCode,
                  ) && (
                    <FormHelperText className="helperText" error={true}>
                      {formik?.errors?.addressForm?.postalCode}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Grid
              container
              spacing={4}
              sx={{ paddingTop: '32px', paddingLeft: '32px' }}
            >
              <Box
                component="div"
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  paddingLeft: { xs: 0, md: 4 },
                  paddingTop: { xs: 0, md: 4 },
                }}
              >
                <Button variant="contained" onClick={onLocateInMapClick}>
                  Locate in Map
                </Button>
              </Box>
            </Grid>
            <Grid container spacing={4} sx={{ paddingTop: '32px', px: '32px' }}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControl variant="standard" fullWidth>
                  <FormLabel>Latitude</FormLabel>
                  <TextField
                    label=""
                    name="addressForm.location.latitude"
                    value={formik.values.addressForm.location.latitude}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    placeholder="Enter Latitude"
                    style={{ borderRadius: '8px !important' }}
                    inputProps={{
                      maxLength: MaxLengthConstants.Common.location,
                      type: 'number',
                    }}
                    error={Boolean(
                      formik?.touched?.addressForm?.location?.latitude &&
                        formik?.errors?.addressForm?.location?.latitude,
                    )}
                  />
                  {Boolean(
                    formik?.touched?.addressForm?.location?.latitude &&
                      formik?.errors?.addressForm?.location?.latitude,
                  ) && (
                    <FormHelperText className="helperText" error={true}>
                      {formik?.errors?.addressForm?.location?.latitude}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControl variant="standard" fullWidth>
                  <FormLabel>Longitude</FormLabel>
                  <TextField
                    label=""
                    name="addressForm.location.longitude"
                    value={formik.values.addressForm.location.longitude}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    placeholder="Enter Longitude"
                    style={{ borderRadius: '8px !important' }}
                    inputProps={{
                      maxLength: MaxLengthConstants.Common.location,
                      type: 'number',
                    }}
                    error={Boolean(
                      formik?.touched?.addressForm?.location?.longitude &&
                        formik?.errors?.addressForm?.location?.longitude,
                    )}
                  />
                  {Boolean(
                    formik?.touched?.addressForm?.location?.longitude &&
                      formik?.errors?.addressForm?.location?.longitude,
                  ) && (
                    <FormHelperText className="helperText" error={true}>
                      {formik?.errors?.addressForm?.location?.longitude}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ marginBottom: { xs: 0, md: 2 }, height: '20rem' }}
              >
                <div style={{ display: 'flex', height: '100%' }}>
                  <Wrapper apiKey={apiKey} render={render}>
                    <Map
                      center={center}
                      zoom={13}
                      style={{
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                      }}
                    >
                      <Marker position={center} />
                    </Map>
                  </Wrapper>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

interface MapProps extends google.maps.MapOptions {
  style: { [key: string]: string };
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
  children?: React.ReactNode;
}

const Map: React.FC<MapProps> = ({
  onClick,
  onIdle,
  children,
  style,
  ...options
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [map, setMap] = React.useState<google.maps.Map>();

  React.useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);

  // because React does not do deep comparisons, a custom hook is used
  // see discussion in https://github.com/googlemaps/js-samples/issues/946
  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  React.useEffect(() => {
    if (map) {
      ['click', 'idle'].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName),
      );

      if (onClick) {
        map.addListener('click', onClick);
      }

      if (onIdle) {
        map.addListener('idle', () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);

  return (
    <>
      <div ref={ref} style={style} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          // @ts-ignore
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
};

function deepCompareEqualsForMaps(a: any, b: any) {
  return deepEqual(a, b);
}

function useDeepCompareMemoize(value: any) {
  const ref = React.useRef();

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

function useDeepCompareEffectForMaps(
  callback: React.EffectCallback,
  dependencies: any[],
) {
  React.useEffect(callback, dependencies.map(useDeepCompareMemoize));
}

const Marker: React.FC<google.maps.MarkerOptions> = (options) => {
  const [marker, setMarker] = React.useState<google.maps.Marker>();

  React.useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  React.useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  return null;
};

export default AddressForm;
