import { createContext, useContext, useReducer } from 'react';
import type { ReactNode } from 'react';

export type Action = {
  type:
    | 'uploadPdf'
    | 'uploadCad'
    | 'clearPdf'
    | 'clearCad'
    | 'isUploadingPdf'
    | 'isUploadingCad'
    | 'selectedProcedure'
    | 'emptyProcedure'
    | 'setProcedureType'
    | 'resetProcedureType'
    | 'canUpload';
  payload: any;
};

export type State = typeof initialState;
export type Dispatch = (action: Action) => void;

const initialState = {
  cadFileUploadDetails: null,
  pdfFileUploadDetails: null,
  currentProcedureId: '',
  isPrerequisite: '',
  canUploadFiles: false,
  isUploadingPdf: false,
  isUploadingCad: false,
};

const fileUploadContext = createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

const fileUploadReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'uploadCad':
      return {
        ...state,
        cadFileUploadDetails: action.payload,
      };
    case 'uploadPdf':
      return {
        ...state,
        pdfFileUploadDetails: action.payload,
      };
    case 'clearPdf':
      return {
        ...state,
        pdfFileUploadDetails: null,
      };
    case 'clearCad':
      return {
        ...state,
        cadFileUploadDetails: null,
      };
    case 'isUploadingPdf':
      return {
        ...state,
        isUploadingPdf: action.payload,
      };
    case 'isUploadingCad':
      return {
        ...state,
        isUploadingCad: action.payload,
      };
    case 'selectedProcedure':
      return {
        ...state,
        currentProcedureId: action.payload,
      };
    case 'emptyProcedure':
      return {
        ...state,
        currentProcedureId: '',
      };
    case 'setProcedureType':
      return {
        ...state,
        isPrerequisite: action.payload,
      };
    case 'resetProcedureType':
      return {
        ...state,
        isPrerequisite: '',
      };
    case 'canUpload':
      return {
        ...state,
        canUploadFiles: action.payload,
      };
  }
};

export const FileUploadProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(fileUploadReducer, initialState);

  return (
    <fileUploadContext.Provider value={{ state, dispatch }}>
      {children}
    </fileUploadContext.Provider>
  );
};

export const useFileUpload = () => {
  const context = useContext(fileUploadContext);
  if (!context)
    throw new Error(
      'Global variable fileupload must be used inside a fileUploadProvider',
    );

  return context;
};
