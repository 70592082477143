import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { IconButton, Button, FormControl, styled, Box } from '@mui/material';
import { AppBar, Alert, Toolbar, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { loginRequest, getMsalConfig } from '../../authConfig';
import './SignIn.scss';
import { Link } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { PublicClientApplication } from '@azure/msal-browser';
import { environment } from '../../environment/environment';
import { trackEvent, useDocumentTitle } from '../../App';
import { AmplitudeEvents } from '../../shared/enums';
import { build } from '../../environment/build';

interface Props {}

const SignIn: React.FC<Props> = (props) => {
  const [email, setEmail] = useState('');
  const [terms, setTerms] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { instance, accounts } = useMsal();

  const navigate = useNavigate();
  useDocumentTitle('CDS Mentor Sign In');
  const isAuthenticated = useIsAuthenticated();

  const validOpcos = ['miauth'];

  const redirectToLogin = async (opco, idp, email) => {
    const request: any = {
      ...loginRequest,
      extraQueryParameters: { opco: opco, login_hint: email },
      account: accounts[0],
    };

    if (idp === environment.AppConfigs.doverADIDP) {
      request['extraQueryParameters'] = { ...request['extraQueryParameters'], domain_hint: 'dover.onmicrosoft.com' };
    }

    const msalInstance = new PublicClientApplication(getMsalConfig(opco, email));

    trackEvent(AmplitudeEvents.LOGGED_IN, { email: localStorage.getItem('email') });
    await msalInstance.initialize();
    msalInstance
      .acquireTokenRedirect(request)
      .then((response: any) => {
        localStorage.setItem('token', response.accessToken);
        if (response.account && !localStorage.getItem('userStats')) {
          localStorage.setItem('userStats', JSON.stringify({ userId: response.account.idTokenClaims.UsernameUPN }));
        }
      })
      .catch((e) => {
        console.log('ERROR: ', e);
      });
  };

  const handleSubmit = () => {
    setLoading(true);
    setError('');
    var requestOptions = {
      method: 'GET',
      redirect: 'follow' as any,
    };

    const baseUrl = environment.AppConfigs.rbacUrl;

    fetch(`${baseUrl}/getAuthConfiguration/getByUserName/${email}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        let opco = result.data.opconame;
        let idp = result.data.idp;
        if (!validOpcos.includes(opco)) {
          opco = '';
        }
        localStorage.setItem('opco', opco);
        localStorage.setItem('idp', idp);
        localStorage.setItem('email', email);
        if (result.statusCode === 200) {
          redirectToLogin(opco, idp, email);
        } else {
          setError(result.errorMessage);
          setLoading(false);
        }
      })
      .catch((error) => {
        setError('User Not Found');
        setLoading(false);
      });
  };

  const theme = useTheme();
  const StyledLink = styled(Link)({
    color: theme.palette.primary.main,
    '&:hover': {
      color: 'red',
    },
  });

  return (
    <>
      <div className="login-box">
        <div className="login-content">
          <div className="sign-in">Sign In</div>

          <FormControl onSubmit={handleSubmit} sx={{ width: '100%' }}>
            <label htmlFor="email" className="email-label">
              Email
            </label>
            <input id="email" value={email} onChange={(e) => setEmail(e.target.value)} />

            <div className="agreement">
              <input type="checkbox" id="terms" onChange={(e) => setTerms(e.target.checked)} />
              {/* <label htmlFor="terms" className="terms-label"> <span>Check here to indicate that you have read and agree to the terms of the Mentor <StyledLink target="_blank" to="/docs/End_User_License_Agreement_(EULA)_7.14.22.pdf">End User License agreement</StyledLink> and <StyledLink to="/">Privacy Policy</StyledLink></span></label> */}
              <label htmlFor="terms" className="terms-label">
                {' '}
                <span>
                  Check here to indicate that you have read and agree to the terms of the Mentor{' '}
                  <StyledLink target="_blank" to="/docs/End_User_License_Agreement_(EULA).pdf">
                    End User License agreement
                  </StyledLink>
                </span>
              </label>
            </div>
            <div>
              <Button variant="contained" size="small" sx={{ textTransform: 'none', float: 'right', mt: '14px' }} disabled={!email || !terms} onClick={handleSubmit}>
                Sign In
              </Button>
            </div>
          </FormControl>
          {loading && (
            <div style={{ textAlign: 'center', paddingTop: '10px' }}>
              <CircularProgress />
            </div>
          )}
          {error && error.length > 0 && (
            <Alert severity="error" style={{ marginTop: '10px' }}>
              {' '}
              {error}
            </Alert>
          )}
        </div>
      </div>
      <Box component="div" sx={{ position: 'absolute', bottom: '25px', left: '25px', color: '#1C1D1F', opacity: '0.4', fontSize: '10px' }}>
        v{build.version + ' ' + build.date}
      </Box>
    </>
  );
};

export default SignIn;
