import { IIdp, IOrganization } from "../../entities/Organization/Organization";
import { ITheme, IThemeLabel } from '../../entities/Organization/Theme/Theme';
import { newAxiosInstance as http } from "../http-common";
import { DataResponse } from "../service-models";

const getAllOrganizations = () => {
    const url = `/organization/getAllOrganizations`;
    return http.get<DataResponse<Array<IOrganization>>>(url);
};

const getOrganization = (organizationId: string) => {
    const url = `/organization/getOrganization/${organizationId}`;
    return http.get<DataResponse<IOrganization>>(url);
};

const createOrganization = (organization: IOrganization) => {
    const url = `/organization/createOrganization`;
    return http.post<DataResponse<IOrganization>>(url, organization);
};

const updateOrganization = (organization: IOrganization) => {
    const url = `/organization/editOrganization/${organization.id}`;
    return http.put<DataResponse<IOrganization>>(url, organization);
};

const deleteOrganization = (organizationId: string) => {
    const url = `/organization/deleteOrganization/${organizationId}`;
    return http.delete<DataResponse<IOrganization>>(url);
};

const getAllIdps = () => {
  const url = `/organization/idp/get`;
  return http.get<DataResponse<Array<IIdp>>>(url);
};

const getTheme = () => {
  const url = `/organization/theme`;
  return http.get<DataResponse<ITheme>>(url);
}

const updateTheme = (formData: FormData) => {
  const url = `/organization/theme`;
  return http.put<DataResponse<IThemeLabel>>(url, formData);
}

const EditLabel=(formData: FormData) => {
  const url='organization/labels';
  return http.patch<any>(url, formData);
}

const OrganizationService = {
    getAllOrganizations,
    getOrganization,
    createOrganization,
    updateOrganization,
    deleteOrganization,
    getAllIdps,
    getTheme,
    updateTheme,
    EditLabel
};

export default OrganizationService;
