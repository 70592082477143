import { Box, Grid, Typography, useTheme } from '@mui/material';
import { Cell, Pie, PieChart, ResponsiveContainer, Label, Tooltip, Legend } from 'recharts';
import { AnalyticsTooltip } from '../AnalyticsTooltip';
import { lighten } from '../../../Cards/TourCard/TourCard';
import CircleIcon from '@mui/icons-material/Circle';

interface CustomTooltipProps {
  active?: any;
  payload?: any;
  label?: any;
}

export const secondsToHms = (d) => {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay = h > 0 ? h + (h == 1 ? 'h, ' : 'h, ') : '';
  var mDisplay = m > 0 ? m + (m == 1 ? 'm, ' : 'm, ') : '';
  var sDisplay = s > 0 ? s + (s == 1 ? 's' : 's') : '';
  var result= hDisplay + mDisplay + sDisplay;
  if(result.charAt(result.length - 2)===','){
    result = result.slice(0, -2);
  }
  return result;
};

export const TraineeTime = ({ traineeAverageTime, traineeFinishTime }) => {
  const TraineeDetails = ({ title, name, time }) => {
    return (
      <Box component="div" sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
        <Typography sx={{ fontSize: '14px', color: 'rgba(18, 24, 31, 0.5)' }}>{title}</Typography>
        <Typography sx={{ fontSize: '20px', margin: '6px 0px' }}>{name}</Typography>
        <Typography sx={{ fontSize: '16px' }}>{time !== -Infinity && time !== Infinity ? time : 'Not Available'}</Typography>
      </Box>
    );
  };
  const CustomTooltip = ({ active, payload, label }: CustomTooltipProps) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">
            {`${payload[0]?.payload?.name}: `}
            <strong>{`${secondsToHms(payload[0]?.payload?.value)}`}</strong>
          </p>
        </div>
      );
    }

    return null;
  };

  const renderCustomLegendFormat = (value: string, entry: any) => {
    const {
      color,
      payload: { value: labelValue, toolTipLabel },
    } = entry;
    const legendColor = {
      'Time Taken': '#CC668C',
      'Time Allotted': '#004b8d',
    };

    return (
      <>
        <Box
          component="div"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: 0,
          }}
        >
          <Box component="div" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <CircleIcon sx={{ color: legendColor[value], margin: '0px 8px', fontSize: '16px' }} />
            <Box component="span" sx={{ color: '#000', fontSize: '16px' }}>
              {value}
            </Box>
          </Box>
          <Box
            component="div"
            sx={{
              color: '#000',
              marginLeft: '20px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              fontSize: '16px',
            }}
          >
            {secondsToHms(labelValue) || '0s'}
          </Box>
        </Box>
      </>
    );
  };

  const theme = useTheme();
  const COLORS = [
    { start: lighten('#004b8d', 0.4), middle: lighten('#004b8d', 0.4), end: '#004b8d' },
    { start: '#CC668C', middle: '#CC668C', end: '#CC668C' },
  ];
  // const COLORS = ['linear-gradient(to right,white, black, white)', '#F6A57B'];

  // const traineeAverageTime = [
  //   {
  //     name: 'Time Allotted',
  //     value: 32,
  //   },

  //   {
  //     name: 'Time Taken',
  //     value: 24,
  //   },
  // ];

  return (
    <Box component="div" sx={{ width: '100%', height: '100%' }}>
      <Grid container height="100%" sx={{ display: 'flex' }}>
        <Grid item xs={6} sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
          {traineeFinishTime.fastestTrainee.duration === Infinity ? <Typography sx={{ fontSize: '14px', color: 'rgba(18, 24, 31, 0.5)' }}>No data captured. Please come back later.</Typography> : ''}
          <ResponsiveContainer>
            <PieChart>
              <defs>
                {traineeAverageTime?.map((entry, index) => (
                  <linearGradient key={index} id={`myGradient${index}`}>
                    <stop offset="0%" stopColor={COLORS[index % COLORS.length].start} />
                    <stop offset="50%" stopColor={COLORS[index % COLORS.length].middle} />
                    <stop offset="100%" stopColor={COLORS[index % COLORS.length].end} />
                  </linearGradient>
                ))}
              </defs>
              <Pie strokeWidth={'5px'} data={traineeAverageTime} outerRadius={65} innerRadius={45} fill="#8884d8" dataKey="value">
                {traineeAverageTime?.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={`url(#myGradient${index})`} />
                ))}
              </Pie>
              <Tooltip wrapperStyle={{ outline: 'none' }} content={<CustomTooltip />} />
              <Legend
                layout="vertical"
                verticalAlign="bottom"
                align="center"
                iconSize={0}
                formatter={renderCustomLegendFormat}
                wrapperStyle={{
                  fontSize: '16px',
                }}
              />
            </PieChart>
          </ResponsiveContainer>
        </Grid>
        <Grid item xs={6} sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', flexGrow: 1 }}>
          <Box component="div" sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', justifyContent: 'space-around', padding: '0px 30px', flexGrow: 1, borderLeftStyle: 'solid', borderWidth: '2px', borderImage: `linear-gradient(white, ${theme.palette.primary.main + '1c'}, white) 30` }}>
            <TraineeDetails title={'Fastest Trainee'} name={traineeFinishTime.fastestTrainee.name} time={traineeFinishTime.fastestTrainee.duration === Infinity ? Infinity : secondsToHms(traineeFinishTime.fastestTrainee.duration)} />
            <TraineeDetails title={'Slowest Trainee'} name={traineeFinishTime.slowestTrainee.name} time={traineeFinishTime.slowestTrainee.duration === -Infinity ? -Infinity : secondsToHms(traineeFinishTime.slowestTrainee.duration)} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
