import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: 'red',
    color: 'red',
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  border: `2px solid ${theme.palette.background.paper}`,
}));

export default function BadgeAvatars({ firstName, lastName, role, color, sx, isRead }) {
  const [colorUsed, setColorUsed] = useState('red');
  const initials = `${firstName?.charAt(0)?.toUpperCase()}${lastName?.charAt(0)?.toUpperCase()}`;
  const colorCodes = ['#DCA2FF', '#FFAF65', '#A9E373', '#F6E073', '#EDEF61', '#FF989A'];
  const hashCode = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  };
  const pickColor = (str) => {
    const hash = hashCode(str);
    return colorCodes[Math.abs(hash) % colorCodes.length];
  };
  const backgroundColor = pickColor(initials);

  const avatarStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '32px',
    width: '32px',
    borderRadius: '32px',
    backgroundColor: backgroundColor,
    color: 'black',
    fontSize: '12px',
    fontWeight: '500',
  };
  return (
    <Stack
      direction="row"
      spacing={2}
    >
      {isRead ? (
        <Box
          style={avatarStyle}
          sx={{ ...sx }}
        >
          {initials}
        </Box>
      ) : (
        <StyledBadge
          overlap="circular"
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          variant="dot"
        >
          <Box
            style={avatarStyle}
            sx={{ ...sx }}
          >
            {initials}
          </Box>
        </StyledBadge>
      )}
    </Stack>
  );
}

BadgeAvatars.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
};
