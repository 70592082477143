import { IPersona } from '../../entities/Organization/Persona/Persona';
import { IAppScope } from '../../entities/Organization/Persona/Resource';

export interface IDetailForm {
  id: string;
  name: string;
  alias: string;
  identifier: string;
  description: string;
  isActive: boolean;
}

export interface IAppScopeFormValue {
  resourceName: string;
  actions: Array<string>;
}

export interface IAssociationForm {
  appScopes: Array<IAppScopeFormValue>;
}

export interface IPersonaFormValues {
  detailForm: IDetailForm;
  associationForm: IAssociationForm;
}

const getAppScopesFromApiData = (appScopes: IAppScope) => {
  if (appScopes !== undefined) {
    return (
      Object.keys(appScopes).map((key) => ({
        resourceName: key,
        actions: appScopes[key],
      })) || []
    );
  } else {
    return [];
  }
};

const getAppScopesPayloadValueFromFormData = (
  appScopesValues: Array<IAppScopeFormValue>,
): IAppScope => {
  return appScopesValues.reduce((acc, cur) => {
    return { ...acc, [cur.resourceName]: cur.actions };
  }, {});
};

export type toPersonaFormValuesType = (
  apiData: Partial<IPersona>,
) => IPersonaFormValues;
export type toPersonaPayloadType = (formValues: IPersonaFormValues) => IPersona;

export const toPersonaFormValues: toPersonaFormValuesType = (apiData) => ({
  detailForm: {
    id: apiData?.id || '',
    name: apiData?.name || '',
    alias: apiData?.alias || '',
    identifier: apiData?.identifier || '',
    description: apiData?.description || '',
    isActive: apiData?.isActive || true,
  },
  associationForm: {
    appScopes: getAppScopesFromApiData(apiData?.appScopes!),
  },
});

export const toPersonaPayload: toPersonaPayloadType = (formValues) => ({
  id: formValues.detailForm.id,
  name: formValues.detailForm.name,
  alias: formValues.detailForm.alias,
  identifier: formValues.detailForm.identifier,
  description: formValues.detailForm.description,
  isActive: formValues.detailForm.isActive,
  appScopes: getAppScopesPayloadValueFromFormData(
    formValues.associationForm.appScopes,
  ),
});
