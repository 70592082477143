import { useContext } from 'react';
import UserContext from '../context/UserContext';

const useCurrentUser = () => {
  const { currentUser, setCurrentUser, refreshProcedure, setRefreshProcedure } = useContext(UserContext);
  return {
    currentUser,
    setCurrentUser,
    refreshProcedure,
    setRefreshProcedure
  };
};

export default useCurrentUser;
