import React from 'react';
import { Stepper, styled, useTheme } from '@mui/material';

export const StyledStepper = (props) => {
  const theme = useTheme();

  const defaultBgColor = theme.palette.primary.main + '26';
  const defaultColor = theme.palette.primary.main;
  const completedBgColor = '#DEEFE5';
  const completedColor = '#219653';
  const disabledBgColor = '#ffffff';
  const disabledColor = '#1C1D1F66';

  const StyledStepperComponent = styled(Stepper)({
    '& .MuiStepConnector-line': {
      borderTopWidth: 2,
    },
    '& .MuiStep-root': {
      height: 24,
      borderRadius: 15,
      padding: '4px 10px 0px 4px',
      backgroundColor: defaultBgColor,
    },
    '& .MuiStep-root.Mui-completed': {
      backgroundColor: completedBgColor,
    },
    '& .MuiStep-root.Mui-active': {
      backgroundColor: defaultBgColor,
    },
    '& .MuiStep-root.Mui-disabled': {
      backgroundColor: disabledBgColor,
    },
    '& .MuiSvgIcon-root': { height: 20, width: 20 },
    '& .MuiStepLabel-label': { lineHeight: 1 },
    '& .Mui-completed.MuiStepIcon-root': { color: completedColor },
    '& .Mui-completed.MuiStepLabel-label': { color: completedColor },
    '& .Mui-active.MuiStepIcon-root': { color: defaultColor },
    '& .Mui-active.MuiStepLabel-label': { color: defaultColor },
    '& .Mui-disabled .MuiStepIcon-root': { color: disabledColor },
    '& .Mui-disabled .MuiStepLabel-label': { color: disabledColor },
  });

  return <StyledStepperComponent {...props}>{props.children}</StyledStepperComponent>;
};
