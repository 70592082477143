export const getSteps = (i18n) => {
  return [
    {
      target: '.tour-instruction-name',
      content: i18n.t('CREATE_WI.TOUR_INSTRUCTION_NAME', { ns: 'demoTour' }),
      placement: 'right' as const,
      disableBeacon: true,
    },
    {
      target: '.tour-instruction-desc',
      content: i18n.t('CREATE_WI.TOUR_INSTRUCTION_DESC', { ns: 'demoTour' }),
      placement: 'right' as const,
      disableBeacon: true,
    },
    {
      target: '.tour-instruction-brand',
      content: i18n.t('CREATE_WI.TOUR_INSTRUCTION_BRAND', { ns: 'demoTour' }),
      placement: 'right' as const,
      disableBeacon: true,
    },
    {
      target: '.tour-cad-asset',
      content: i18n.t('CREATE_WI.TOUR_CAD_ASSET', { ns: 'demoTour' }),
      disableBeacon: true,
      placement: 'right' as const,
    },
    {
      target: '.tour-wi-next',
      content: i18n.t('CREATE_WI.TOUR_WI_NEXT', { ns: 'demoTour' }),
      placement: 'right' as const,
      disableBeacon: true,
    },
    {
      target: '.tour-pre-or-core',
      content: i18n.t('CREATE_WI.TOUR_PRE_OR_CORE', { ns: 'demoTour' }),
      placement: 'right' as const,
      disableBeacon: true,
      hideBackButton: true,
    },
    {
      target: '.tour-proc-name',
      content: i18n.t('CREATE_WI.TOUR_PROC_NAME', { ns: 'demoTour' }),
      placement: 'right' as const,
      disableBeacon: true,
      hideBackButton: true,
    },
    {
      target: '.tour-proc-lang',
      content: i18n.t('CREATE_WI.TOUR_PROC_LANG', { ns: 'demoTour' }),
      placement: 'right' as const,
      disableBeacon: true,
    },
    {
      target: '.tour-doc-id',
      content: i18n.t('CREATE_WI.TOUR_DOC_ID', { ns: 'demoTour' }),
      placement: 'right' as const,
      disableBeacon: true,
    },
    {
      target: '.tour-cad-asset',
      content: i18n.t('CREATE_WI.TOUR_REF_DOC', { ns: 'demoTour' }),
      placement: 'right' as const,
      disableBeacon: true,
    },
    {
      target: '.tour-proc-next',
      content: i18n.t('CREATE_WI.TOUR_PROC_NEXT', { ns: 'demoTour' }),
      placement: 'right' as const,
      disableBeacon: true,
    },
    {
      target: '.buffer-step',
      content: i18n.t('CREATE_WI.TOUR_BUFFER_STEP', { ns: 'demoTour' }),
      placement: 'right' as const,
      disableBeacon: true,
    },
    {
      target: '.tour-ppe-check-box',
      content: i18n.t('CREATE_WI.TOUR_PPE_CHECK_BOX', { ns: 'demoTour' }),
      placement: 'right' as const,
      disableBeacon: true,
      hideBackButton: true,
    },
    {
      target: '.tour-ppe-finish',
      content: i18n.t('CREATE_WI.TOUR_PPE_FINISH', { ns: 'demoTour' }),
      placement: 'right' as const,
      disableBeacon: true,
    },
  ];
};

