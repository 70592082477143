export const insertUrlParam = (key: string, value: string) => {
  if (window.history.pushState) {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set(key, value);
    const url =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      '?' +
      searchParams.toString();
    window.history.pushState({ path: url }, '', url);
  }
};

export const removeUrlParam = (key: string) => {
  if (window.history.pushState) {
    const currentUrl = window.location.href;
    var url = new URL(currentUrl);
    url.searchParams.delete(key);
    const newUrl = url.href;
    window.history.pushState({ path: newUrl }, '', newUrl);
  }
};
