import { Backdrop, Box, Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BasicDialog } from '../BasicDialog/BasicDialog';
import TrainerService from '../../../../services/trainer/TrainerService';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import Loading from '../../../Loading/Loading';
import { closeSnackbar } from 'notistack';
import { Toast } from '../../../Notifications/Notification';
import { WarningIcon } from '../../../Icons/CdsIcons';

export const DeleteFolderDialog = (props) => {
  const [loading, setLoading] = useState(false);

  const handleDeleteFolder = () => {
    setLoading(true);
    let movePayload: any = [];
    props?.selectedFolderWiCard?.forEach((value) => {
      const parsedValue = JSON.parse(value);
      movePayload.push(parsedValue);
    });
    const deletePromises = movePayload?.map((item) => {
      if (item.type === 'Folder') {
        return TrainerService.deleteFolder(item.id)
          .then((e) => {})
          .catch(({ response }) => {
            Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
            setLoading(false);
          })
          .finally(() => {
            props.clearCardsSelection();
            setLoading(false);
          });
      } else if (item.type === 'WorkInstruction') {
        return TrainerService.recyclebinWorkInstruction(item.id)
          .then((response) => {})
          .catch(({ response }) => {
            Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
          })
          .finally(() => {
            props.clearCardsSelection();
            setLoading(false);
          });
      } else {
        return Promise.resolve();
      }
    });
    Promise.all(deletePromises)
      .then(() => {
        setLoading(false);
        Toast.success(t('OPERATION_SUCCESS', { ns: 'success' }), movePayload.length > 1 ? t('COMMON.ITEMS_MOVED_RECYCLE_BIN_SUCCESSFULLY', { itemSize: movePayload.length }) : t('COMMON.ITEM_MOVED_SUCCESSFULLY'));
        props.handleReloadData();
      })
      .finally(() => {
        props.clearCardsSelection();
        setLoading(false);
      });

    props.closeDialog();
  };

  const { t } = useTranslation();
  const { refreshProcedure, setRefreshProcedure } = useCurrentUser();

  return (
    <>
      {loading && (
        <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 100 }} open={loading}>
          <Loading />
        </Backdrop>
      )}
      <BasicDialog
        title={props?.selectedFolderWiCard?.size > 1 ? t('COMMON.DELETE_ITEMS') : t('COMMON.DELETE_ITEM')}
        onClose={() => {
          props.closeDialog();
        }}
        onOpen={props.openDialog}
        actions={
          <>
            <Button
              onClick={() => {
                props.closeDialog();
              }}
              variant="outlined"
              size="small"
              type="submit"
            >
              {t('BUTTON.CANCEL') as string}
            </Button>
            <Button
              onClick={() => {
                handleDeleteFolder();
              }}
              variant="contained"
              size="small"
              type="submit"
            >
              {t('BUTTON.CONFIRM') as string}
            </Button>
          </>
        }
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography sx={{ marginLeft: '16px', fontSize: '16px', display: 'flex', flexDirection: 'row' }}>
            <WarningIcon />

            <Typography sx={{ paddingLeft: '8px', fontWeight: '400' }}>{props?.selectedFolderWiCard?.size > 1 ? t('COMMON.ARE_YOU_SURE_YOU_WANT_TO_MOVE_THESE_ITEM_TO_RECYCLE_BIN', { selectedItems: props?.selectedFolderWiCard?.size }) : t('COMMON.ARE_YOU_SURE_YOU_WANT_TO_MOVE_THIS_ITEM_TO_RECYCLE_BIN')}</Typography>
          </Typography>
        </Box>
      </BasicDialog>
    </>
  );
};
