
import { Box, ButtonBase, Paper, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ExplosionActive, ExplosionDefault, Rotate, SolidOutlineActive, SolidOutlineDefault, TranslucentActive, TranslucentDefault } from "../../../../Icons/CdsIcons";
import { StyledModalIcon } from "../../../../Styled/StyledModalIcon";
import './ModesPopover.scss';
import { useCanvasState } from "../../../../../state/canvasState";
import { useTranslation } from 'react-i18next'

interface Props {
  
}


const ModesPopover: React.FC<Props> = (props) => {
    const canvasState = useCanvasState() 
const { t }=useTranslation()
    return (
        <Paper className="rotate-container canvas-control-container-buttons">
            <Box component="div" className={'rotate-content-container'} >
                <ButtonBase component="div" className={`rotate-link`} onClick={() =>    useCanvasState.setState({ changeMode: 'explode' })} >
                    <Box component="div" className={'rotate-content'} >
                    <Box component="div" className="mode-icon-top">
                                {canvasState.changeMode !== 'explode' && <StyledModalIcon text={t('3D_VIEWER.EXPLOSION')} tooltip={t('3D_VIEWER.EXPLOSION')}>
                                    <ExplosionDefault />
                                </StyledModalIcon>}
                                {canvasState.changeMode === 'explode' && <StyledModalIcon text={t('3D_VIEWER.EXPLOSION')} tooltip={t('3D_VIEWER.EXPLOSION')}>
                                    <ExplosionActive />
                                </StyledModalIcon>}
                            </Box>
                    </Box>
                </ButtonBase>
                <ButtonBase component="div" className={`rotate-link`} onClick={() => useCanvasState.setState({ changeMode: 'outline' })} >
                    <Box component="div" sx={{width: 'max-content'}} >
                    <Box component="div" className="mode-icon-top">
                                {canvasState.changeMode !== 'outline' && <StyledModalIcon text={t('3D_VIEWER.SOLID_OUTLINE')} tooltip={t('3D_VIEWER.SOLID_OUTLINE')}>
                                    <SolidOutlineDefault />
                                </StyledModalIcon>}
                                {canvasState.changeMode === 'outline' && <StyledModalIcon text={t('3D_VIEWER.SOLID_OUTLINE')} tooltip={t('3D_VIEWER.SOLID_OUTLINE')}>
                                    <SolidOutlineActive />
                                </StyledModalIcon>}
                            </Box>
                    </Box>
                </ButtonBase>
                <ButtonBase component="div" className={`rotate-link`} onClick={() => useCanvasState.setState({ changeMode: 'translucent' })} >
                    <Box component="div" className={'rotate-content'} >
                    <Box component="div" className="mode-icon-top">
                                {canvasState.changeMode !== 'translucent' && <StyledModalIcon text={t('3D_VIEWER.TRANSLUCENT')} tooltip={t('3D_VIEWER.TRANSLUCENT')}>
                                    <TranslucentDefault />
                                </StyledModalIcon>}
                                {canvasState.changeMode === 'translucent' && <StyledModalIcon text={t('3D_VIEWER.TRANSLUCENT')} tooltip={t('3D_VIEWER.TRANSLUCENT')}>
                                    <TranslucentActive />
                                </StyledModalIcon>}
                            </Box>
                    </Box>
                </ButtonBase>
            </Box>
        </Paper>

    )
}

export default ModesPopover;
