import React, { useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import './Dropzone.scss';
import { useCallback } from 'react';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { ReactComponent as Dragndrop } from '../../assets/dragndrop.svg';
import { environment } from '../../environment/environment';
import { fileSize } from '../../shared/utils/helpers';
import { useTranslation } from 'react-i18next';
import useTour from '../../hooks/tour';

interface Props {
  className?: string;
  fileUploadValues: (data: any) => void;
  acceptedFiles: string[];
  selectedFiles: any;
  maxFileSize: number;
  PPE?: boolean;
  PpeName?: any;
  editMode?: any;
  displayFile?: {
    name?: string;
    size?: number;
  };
  newProcedureDropzone?: boolean;
  updatedProcedureDetails?: any;
}

const Dropzone: React.FC<Props> = (props) => {
  const [files, setFiles] = useState<any>([]);
  const [fileSizeError, setFileSizeError] = useState('');
  const [fileSizeErrorPdf, setFileSizeErrorPdf] = useState('');
  const [fileCountErrorPdf, setFileCountErrorPdf] = useState('');

  useEffect(() => {
    if (props.selectedFiles) {
      setFiles(props.selectedFiles);
    }
    return () => {};
  }, [props.selectedFiles]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const fileNameRegex = new RegExp("^[a-zA-Z0-9\\s\\(\\)\\-\\_\\.\\']+$");
      const maxFileSizeAllowed =  props.maxFileSize ?? environment.AppConfigs.fileUploadLimit.media;
      const maxFileSizeInKB = Number(maxFileSizeAllowed) * 1024;
          
      if (acceptedFiles && acceptedFiles.length) {
        let newFiles;
        if (props.PPE) {
          newFiles = acceptedFiles;
        } else {
          newFiles = [...files, ...acceptedFiles];
        }

        const fileExtension = '.' + acceptedFiles[0].name.slice(((acceptedFiles[0].name.lastIndexOf('.') - 1) >>> 0) + 2).toLowerCase();
        let exceededSize = false;

        if (props.newProcedureDropzone) {
          var totalfileSizeInKB = 0;
          const exceedCount = newFiles.length > 10;
          if (exceedCount) {
            setFiles(newFiles.slice(0, 10));
            props.fileUploadValues(newFiles.slice(0, 10));
            setFileCountErrorPdf(`${t('REFERENCE_DOC_LIMIT_REACHED_ERROR', { ns: 'error' })}`);
          } else {
            if (newFiles.length + props.updatedProcedureDetails.length > 10) {
              setFiles(newFiles);
              const filesToRemove = newFiles.length + props.updatedProcedureDetails.length - 10;
              const updatedFiles = newFiles.slice(filesToRemove);
              props.fileUploadValues(updatedFiles);
              setFileCountErrorPdf(`${t('REFERENCE_DOC_LIMIT_REACHED_ERROR', { ns: 'error' })}`);
            } else {
              for (const file of acceptedFiles) {
                totalfileSizeInKB = totalfileSizeInKB + file.size / 1024;
                // This file size limits needs to be pushed to the configuration file later
                if (totalfileSizeInKB > maxFileSizeInKB) {
                  exceededSize = true;
                }
                if (exceededSize) {
                  setFiles(newFiles);
                  const filesToRemove = newFiles.length;
                  const updatedFiles = newFiles.slice(filesToRemove);
                  props.fileUploadValues(updatedFiles);
                  setFileSizeErrorPdf(`${t('DROPZONE.SELECTED_FILE_SIZE_EXCEED_LIMIT')} ${40}MB.`);
                } else if (!props.acceptedFiles.includes(fileExtension)) {
                  setFiles(newFiles);
                  props.fileUploadValues([]);
                  setFileSizeErrorPdf(t('DROPZONE.NOT_VALID_FILETYPE'));
                } else {
                  setFiles(newFiles);
                  props.fileUploadValues(newFiles);
                  setFileSizeErrorPdf('');
                  setFileCountErrorPdf('');
                }
              }
            }
          }
        } else {
          const fileSizeInKB = acceptedFiles[0].size / 1024;
          if (fileSizeInKB > maxFileSizeInKB) {
            exceededSize = true;
          }

          if (exceededSize) {
            setFiles(newFiles);
            props.fileUploadValues(null);
            setFileSizeError(`${t('DROPZONE.SELECTED_FILE_SIZE_EXCEED_LIMIT')} ${props.maxFileSize ?? 250}MB.`);
          } else if (!props.acceptedFiles.includes(fileExtension)) {
            setFiles(newFiles);
            props.fileUploadValues(null);
            setFileSizeError(t('DROPZONE.NOT_VALID_FILETYPE'));
          } else if (!fileNameRegex.test(acceptedFiles[0].name)) {
            setFiles(newFiles);
            props.fileUploadValues(null);
            setFileSizeError(t('DROPZONE.FILE_NAME_IS_INVALID'));
          } else {
            setFiles(newFiles);
            props.fileUploadValues(newFiles);
            setFileSizeError('');
          }
        }
      }
    },
    [setFiles],
  );

  const { getRootProps, getInputProps, acceptedFiles, fileRejections } = useDropzone({
    onDrop,
    multiple: props.newProcedureDropzone ? true : false,
    accept: {
      'application/x-step': props.acceptedFiles,
      //'application/octet-stream': props.acceptedFiles,
    },
  });

  const theme = useTheme();
  const { t } = useTranslation();
  const { isTourOpen, setIsTourOpen, tourStep, setTourStep, setTourTitle } = useTour();

  return (
    <div>
      <Box component="div" {...getRootProps()} className="w-full dropzone" sx={{ backgroundColor: theme.palette.primary.main + '1c', minHeight: '88px', borderRadius: '8px', display: 'flex', flexDirection: 'column', alignItems: 'center', maxHeight: '100px' }}>
        <input {...getInputProps()} />
        {!(files.length !== 0 && props.PPE) && !props.newProcedureDropzone && <Dragndrop style={{ paddingBottom: '.8rem', transform: 'scaleY(-1)' }} />}
        {files.length === 0 && !isTourOpen && !props.displayFile?.name && !props.newProcedureDropzone && <p style={{ color: 'rgba(28, 29, 31, .4)' }}>{t('COMMON.UPLOAD_FILE_MESSAGE') as string}</p>}
        {props.newProcedureDropzone && <Dragndrop style={{ marginTop: '8px', transform: 'scaleY(-1)' }} />}
        {props.newProcedureDropzone && <p style={{ color: 'rgba(28, 29, 31, .4)' }}>{t('COMMON.UPLOAD_FILE_MESSAGE') as string}</p>}

        {(files.length !== 0 || isTourOpen || props.displayFile?.name) && (
          <>
            {props.PPE ? (
              <Box component="div" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', width: '100%', height: '100%', padding: '12px' }}>
                <img style={{ margin: '0px 12px', fontSize: '12px', maxHeight: '65px' }} alt={'PPE Equipment'} width={73} height={'100%'} src={Array.isArray(files) ? URL.createObjectURL(files[0]) : 'data:image/jpeg;base64,' + files} />
                <Box component="div" sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography sx={{ fontSize: '14px' }}>{files[0].name || 'IMG'}</Typography>
                  <Typography sx={{ fontSize: '12px', color: '#545454', marginBottom: '4px' }}>{files[0].size ? fileSize(files[0].size) : fileSize(files.length)}</Typography>
                  <Button variant="outlined" sx={{ width: 'fit-content', height: '25px' }}>
                    {t('COMMON.REPLACE') as string}
                  </Button>
                  {fileSizeError && <p className="dropzone-file-error dropzone-text-align">{fileSizeError}</p>}
                </Box>
              </Box>
            ) : (
              <>
                <p className="dropzone-text-align" style={{ color: 'rgba(28, 29, 31, 1)' }}>
                  {isTourOpen && (
                    <>
                      {tourStep < 5 && `${t('DROPZONE.FILE_SELECTED')} Screw Driver.stp ${t('DROPZONE.IS')} 50Mb`}
                      {tourStep > 5 && `${t('DROPZONE.FILE_SELECTED')} abc.pdf ${t('DROPZONE.IS')} 2 Mb`}
                    </>
                  )}
                  {files.length === 0 && props.displayFile && (
                    <>
                      ${t('DROPZONE.FILE_SELECTED')} {props.displayFile?.name} is {fileSize(props.displayFile?.size || 0)}
                    </>
                  )}
                  {!isTourOpen && files.length !== 0 && !props.newProcedureDropzone && <>{t('COMMON.FILE_SELECTED', { name: files[0]?.name, size: fileSize(files[0]?.size) }) as string}</>}
                </p>
                {fileSizeError && <p className="dropzone-file-error dropzone-text-align">{fileSizeError}</p>}
                {fileSizeErrorPdf && <p className="dropzone-file-error dropzone-text-align">{fileSizeErrorPdf}</p>}
                {fileCountErrorPdf && <p className="dropzone-file-error dropzone-text-align">{fileCountErrorPdf}</p>}
              </>
            )}
          </>
        )}
      </Box>
    </div>
  );
};

export default Dropzone;
