import moment from 'moment';
import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { IDateRange, IOrganizationAssignedTrainee, IOrganizationPendingProcedure } from '../entities/Analytics/AnalyticsProcedure';

export enum SELECTION_TYPE {
  THIS_MONTH = 'THIS_MONTH',
  LAST_MONTH = 'LAST_MONTH',
  CUSTOM = 'CUSTOM',
}

export type SelectionType = keyof typeof SELECTION_TYPE;

export interface IAnalyticsType {
  selectedDateRange: IDateRange;
  selectedType: SelectionType;
  pendingProceduresList: IOrganizationPendingProcedure | null;
  setPendingProceduresList: (procedureList: IOrganizationPendingProcedure) => void;
  assignedTraineesList: any;
  assignmentHistory: any;
  setAssignmentHistory: (assignmentHistoryList: any) => void;
  setAssignedTraineesList: (traineeList: any) => void;
  setSelectedDateRange: (dateRange: IDateRange) => void;
  resetSelectedDateRange: () => void;
  setSelectedType: (selectedType: SelectionType) => void;
  resetSelectedType: () => void;
  setState: (dateRange: IDateRange, selectedType: SelectionType) => void;
  resetState: () => void;
}

export const initialDateRange: IDateRange = {
  startDate: '',
  endDate: '',
};

export const useAnalytics = create<IAnalyticsType>((set: any) => {
  return {
    selectedDateRange: initialDateRange,
    selectedType: SELECTION_TYPE.THIS_MONTH,
    pendingProceduresList: null,
    assignedTraineesList: null,
    assignmentHistory: null,
    setAssignedTraineesList: (traineeList: any) =>
      set((state: IAnalyticsType) => ({
        assignedTraineesList: traineeList,
      })),
    setAssignmentHistory: (assignmentHistoryList: any) =>
      set((state: IAnalyticsType) => ({
        assignmentHistory: assignmentHistoryList,
      })),
    setSelectedDateRange: (dateRange: IDateRange) =>
      set((state: IAnalyticsType) => ({
        selectedDateRange: dateRange,
      })),
    setPendingProceduresList: (procedureList: IOrganizationPendingProcedure) =>
      set((state: IAnalyticsType) => ({
        pendingProceduresList: procedureList,
      })),
    resetSelectedDateRange: () =>
      set((state: IAnalyticsType) => ({
        selectedDateRange: initialDateRange,
      })),
    setSelectedType: (selectedType: SelectionType) =>
      set((state: IAnalyticsType) => ({
        selectedType: selectedType,
      })),
    resetSelectedType: () =>
      set((state: IAnalyticsType) => ({
        selectedType: SELECTION_TYPE.THIS_MONTH,
      })),
    setState: (dateRange: IDateRange, selectedType: SelectionType) =>
      set((state: IAnalyticsType) => ({
        selectedDateRange: dateRange,
        selectedType: selectedType,
      })),
    resetState: () =>
      set((state: IAnalyticsType) => ({
        selectedDateRange: initialDateRange,
        selectedType: SELECTION_TYPE.THIS_MONTH,
      })),
  };
});
