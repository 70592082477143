import { ExpandMore } from '@mui/icons-material';
import { Box, Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, useTheme } from '@mui/material';
import { ChangeEvent, ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FilterOption } from '../../../components/Dialog/mobileDialog/filterDialog/filterDialog';
import FilterButton from '../../../components/FilterButton/FilterButton';
import SearchBar from '../../../components/SearchBar/SearchBar';
import SelectSort from '../../../components/SelectSort/SelectSort';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { fetchTemplateProcedures, setDialogueToOpen, setSearchPayload as setSearchPayloadAction, setSelectedFiltersAction } from '../../../redux/features/ModularProcedures/ModularProcedures.slice';
import { PROCEDURE_VISIBILITY_SCOPE, WorkInstructionModelStatus } from '../../../shared/enums';
import { PROCEDURE_LIBRARY_DIALOG } from './constants';
import { debounce } from 'lodash';

interface ProcedureLibraryToolbarProps {}

const ProcedureLibraryToolbar = (props: ProcedureLibraryToolbarProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const addTemplatePositionButton = useRef(null);
  
  const handleClose = () => {
    setIsMenuOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setIsMenuOpen(false);
    } else if (event.key === 'Escape') {
      setIsMenuOpen(false);
    }
  }

  const handleCreateNewProcedureClick = () => {
    handleClose();
    navigate(`/trainer/addProcedure/library`, {
      state: {
        visibilityScope: PROCEDURE_VISIBILITY_SCOPE.GLOBAL,
      },
    });
  };

  const handleMoveExistingProcedureClick = () => {
    dispatch(setDialogueToOpen(PROCEDURE_LIBRARY_DIALOG.SELECT_WORK_INSTRUCTION));
    handleClose();
  };

  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        gap: "10px"
      }}
    >
      <ProcedureLibrarySearchSortFilterOptions />
      <Box component="div" sx={{ display: 'flex', flexDirection: 'row' }}>
        <Button variant="contained" ref={addTemplatePositionButton} onClick={() => setIsMenuOpen(true)} endIcon={<ExpandMore />}>
          {t('BUTTON.ADD')}
        </Button>
        <Popper open={isMenuOpen} anchorEl={addTemplatePositionButton.current} role={undefined} placement="bottom-start" transition disablePortal sx={{ zIndex: 1 }}>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={isMenuOpen} aria-labelledby="add-procedure-menu-list" onKeyDown={handleListKeyDown}>
                    <MenuItem onClick={handleCreateNewProcedureClick}>
                      <Box component={'div'}>{t('PROCEDURE_LIBRARY.TOOLBAR.ADD_PROCEDURE_DROPDOWN.CREATE')}</Box>
                    </MenuItem>
                    <MenuItem onClick={handleMoveExistingProcedureClick}>
                      <Box component={'div'}>{t('PROCEDURE_LIBRARY.TOOLBAR.ADD_PROCEDURE_DROPDOWN.ADD_FROM_WI')}</Box>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
    </Box>
  );
};


export const ProcedureLibrarySearchSortFilterOptions = (props) => {
  const { searchPayload, availableTags, toolbar: {selectedFilters} } = useAppSelector((state) => state.modularProcedure.procedureLibrary);
  
  const [sortBy, setSortBy] = useState<string>('');
  const [filterOptions, setFilterOptions] = useState<FilterOption[]>([]);
  
  const dispatch = useAppDispatch();

  useEffect(() => {
    const filterOptionsToSet: FilterOption[] = [
      // {
      //   filter: 'status',
      //   filterValues: [WorkInstructionModelStatus.FILE_UPLOAD_FAILED, WorkInstructionModelStatus.MODEL_CONVERSION_FAILED, WorkInstructionModelStatus.PREPARING_MODEL, WorkInstructionModelStatus.MODEL_READY],
      // },
    ];

    if(availableTags.length){
      filterOptionsToSet.push({
        filter: 'tags',
        filterValues: availableTags.map(each => each.name),
      })
    }

    setFilterOptions(filterOptionsToSet);
  }, [availableTags])

  useEffect(() => {
    let tagsToApplyInSearch = selectedFilters.filter(each => each.applied).map(each => each.value);
    let searchPayloadToSet = structuredClone(searchPayload);
    if(!searchPayloadToSet){
      searchPayloadToSet = {};
    };

    if(!tagsToApplyInSearch.length){
      delete searchPayloadToSet.tags;
    } else {
      let tagMap = {};
      availableTags.forEach(tag => {
        tagMap[tag.name] = tag.tagId;  
      })

      tagsToApplyInSearch = tagsToApplyInSearch.map(each => tagMap[each]);
      searchPayloadToSet.tags = tagsToApplyInSearch;
    }

    setSearchPayload(searchPayloadToSet);
  }, [selectedFilters])

  useEffect(() => {
    dispatch(fetchTemplateProcedures(searchPayload));
  }, [searchPayload]);


  const setSearchPayload = (payload) => {
    dispatch(setSearchPayloadAction(payload));
  };
  const setSelectedFilters = (payload) => {
    dispatch(setSelectedFiltersAction(payload));
  }

  const search = debounce((e) => {
    setSearchPayload({
      ...searchPayload,
      procedureName: e.target.value,
    });
  }, 500);

  const handleSortChange = (value: string) => {
    setSortBy(value);
    switch (value) {
      case 'Date Added':
        setSearchPayload({
          ...searchPayload,
          sortBy: 'createdOn',
        });
        break;
      case 'Alphabetical':
        setSearchPayload({
          ...searchPayload,
          sortBy: 'procedureName',
        });
        break;
      default:
        let payload = structuredClone(searchPayload);
        delete payload?.sortBy;
        setSearchPayload(payload);
    }
  };

  const sortOptions = [
    { value: 'Date Added', label: 'WI_LIST.DATE_ADDED' }, // Adjust labels based on your translations
    { value: 'Alphabetical', label: 'WI_LIST.ALPHABETICAL' },
  ];

  const handleApplyFilterClick = (data) => {
    setSelectedFilters(data);
  };

  const onFilterClose = () => {};
  
  return (
    <>
      <SearchBar onChangeHandler={search} />
      <SelectSort sortOptions={sortOptions} onChange={handleSortChange} value={sortBy} />
      {filterOptions.length ? <FilterButton selectedFilters={selectedFilters} onApplyFilterClick={handleApplyFilterClick} onFilterClose={onFilterClose} filterOptions={filterOptions} /> : null}
    </>
  );
};

export default ProcedureLibraryToolbar;
