import React from 'react';
import { useAppSelector } from '../../hooks/redux';
import UserLanguageSideBar from './UserLanguageSidebar';
import { APP_SIDEBARS } from '../../shared/enums';
import { Box, IconButton, Paper, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { setSidebarToOpen } from '../../redux/features/SideBar/Sidebar.slice';
import EditSubstitutionSidebar from '../../pages/Substitutions/EditSubstitutionSidebar';

const availableSidebars = {
  [APP_SIDEBARS.USER_LANGUAGE]: <UserLanguageSideBar />,
  [APP_SIDEBARS.EDIT_SUBSTITUTION]: <EditSubstitutionSidebar />
};
const SideBar = (props) => {
  const { sidebarName } = useAppSelector((state) => state.sidebar);

  if (!sidebarName) return null;
  
  return (
    <Paper
      id="sidebar-container"
      elevation={3}
      sx={{
        position: 'fixed',
        zIndex: 999,
        top: 0,
        right: 0,
        width: '30vw',
        height: '100vh',
        background: 'white',
        borderRadius: 0,
        padding: '0 16px',
        textAlign: 'left'
      }}
    >
      {availableSidebars[sidebarName]}
    </Paper>
  );
};

export default SideBar;

export const SideBarHeader = ({ sidebarHeaderTitle }) => {
  const dispatch = useDispatch();
  const handleSidebarCloseClick = () => {
    dispatch(setSidebarToOpen(null));
  };
  return (
    <Box
      component={'div'}
      sx={{ display: 'flex', alignItems: 'center' }}
    >
      <Typography sx={{fontSize: '18px'}}>{sidebarHeaderTitle}</Typography>
      <IconButton
        sx={{
          marginLeft: 'auto',
          fontSize: '30px',
          color: 'black'
        }}
        onClick={handleSidebarCloseClick}
      >
        <Close fontSize='inherit'/>
      </IconButton>
    </Box>
  );
};
