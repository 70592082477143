import { Refresh, Search } from '@mui/icons-material';
import { Avatar, Box, Button, FormControl, Grid, IconButton, InputAdornment, MenuItem, Paper, Select, styled, TextField, Tooltip, Typography, useTheme, Tab, Tabs, Popover } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import WorkInstructionCards from '../../components/Cards/WorkInstructionCard/WorkInstructionCards';
import { IWorkInstructions } from '../../entities/Trainee/MockTrainee';
import TutorialService from '../../services/trainee/TraineeService';
import './RecycleBinHome.scss';
import { NavLink, useParams } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import { ActionFilter, SortDesc } from '../../components/Icons/CdsIcons';
import { SortDialog } from '../../components/Dialog/mobileDialog/sortDialog/sortDialog';
import { FilterDialog } from '../../components/Dialog/mobileDialog/filterDialog/filterDialog';
import InfiniteScroll from 'react-infinite-scroll-component';
import { TRANSLATE_TRAINEE_FILTERS, translate, WorkInstructionModelStatus } from '../../shared/enums';
import FileManagerService from '../../services/FileManager/FileManagerService';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useTranslation } from 'react-i18next';
import { useDocumentTitle } from '../../App';
import TourCard from '../../components/Cards/TourCard/TourCard';
import useTour from '../../hooks/tour';
import TourContext from '../../context/TourContext';
import { useContext } from 'react';

interface Props {
  isTrainer?: boolean;
  isDelete?: boolean;
}

interface IMenuItem {
  id: string;
  title: string;
  paths: Array<string>;
}

interface IMainMenu {
  [key: string]: IMenuItem;
}

const deletedMenu: IMainMenu = {
  DELETED_WORK_INSTRUCTION: {
    id: 'DELETED_WORK_INSTRUCTION',
    title: 'Deleted Work Instructions',
    paths: ['/trainer'],
  },
  DELETED_PROCEDURES: {
    id: 'DELETED_PROCEDURES',
    title: 'Deleted Procedures',
    paths: ['/deleted-procedures'],
  },
};

const RecycleBinHome: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [sort, setSort] = useState<string>('');
  const [sortVal, setSortVal] = useState<string>('');
  const [filterVal, setFilterVal] = useState<string>('');
  const [searchVal, setSearchVal] = useState<string>('');
  const [filter, setFilter] = useState<string>('');
  const [appliedFilterList, setAppliedFilterList] = useState<Array<any>>([]);
  const [workInstructions, setWorkInstructions] = useState<IWorkInstructions[]>();
  const [deletedProcedures, setDeletedProcedures] = useState<IWorkInstructions[]>();
  const [currPage, setCurrPage] = useState(1);
  const [currPageProcedure, setCurrPageProcedure] = useState(1);
  const [totalWorkInstructionsLength, setTotalWorkInstructionsLength] = useState(0);
  const [totalProcedureDeleteWILength, setTotalProcedureDeleteWILength] = useState(0);
  const [totalWiDeleteProcedure, setTotalWiDeleteProcedure] = useState(0);
  const [sortOptions, setSortOptions] = useState(['Sort', 'Date Added', 'Alphabetical']);
  const [filterOptionsTrainee, setFilterOptionsTrainee] = useState([
    {
      filter: 'completionStatus',
      filterValues: ['Not started', 'In-Progress', 'Completed'],
    },

    // {
    //   filter: 'Brand',
    //   filterValues: ['Heil', 'Marathon', 'Bayne', 'Soft pack']
    // },
    // {
    //   filter: 'Variants',
    //   filterValues: ['single', 'multiple'],
    // }
  ]);

  const { isDeletedWorkInstructionBackButton, setIsDeletedWorkInstructionBackButton } = useContext(TourContext);
  const [filterOptionsTrainer, setFilterOptionsTrainer] = useState([
    {
      filter: 'status',
      filterValues: [WorkInstructionModelStatus.FILE_UPLOAD_FAILED, WorkInstructionModelStatus.MODEL_CONVERSION_FAILED, WorkInstructionModelStatus.PREPARING_MODEL, WorkInstructionModelStatus.MODEL_READY],
    },
  ]);
  const [overview, setOverview] = useState('DELETED_WORK_INSTRUCTION');
  const isShowProcedures = true;

  const { companyId } = useParams();
  useDocumentTitle('CDS Mentor Home');
  const { createWiTour, setCreateWiTour } = useTour();

  useEffect(() => {
    if (companyId) {
      loadData(companyId);
      loadDataProcedure(companyId);
    }
  }, [companyId]);

  const applyFilters = (fullFilterList) => {
    let filter = '';
    let prevFilter = '';
    setAppliedFilterList(fullFilterList);
    fullFilterList.map((item) => {
      if (item.applied) {
        if (item.filter === prevFilter) {
          filter += '.' + item.value;
        } else {
          filter += item.filter + ':' + item.value;
        }
        prevFilter = item.filter;
      }
    });
    setFilterVal(filter);
    if (companyId) {
      loadData(companyId, searchVal, sortVal, filter);
      loadDataProcedure(companyId, searchVal, sortVal, filter);
    }
  };

  const sortFilter = (sort: string) => {
    if (sort === 'Alphabetical') {
      setSortVal('name:asc');
      loadData(companyId || '', searchVal, 'name:asc');
      loadDataProcedure(companyId || '', searchVal, 'name:asc');
    } else if (sort === 'Date Added') {
      setSortVal('modifiedOn:asc');
      loadData(companyId || '', searchVal, 'modifiedOn:asc');
      loadDataProcedure(companyId || '', searchVal, 'modifiedOn:asc');
    } else {
      setSortVal('modifiedOn:desc');
      loadData(companyId || '', searchVal, 'modifiedOn:desc');
      loadDataProcedure(companyId || '', searchVal, 'modifiedOn:desc');
    }
  };

  const previousController = useRef<any>(null);
  const [imageObject, setImageObject] = useState<Object>({});

  const loadData = (companyId: string, search = searchVal, sort = sortVal, filter = filterVal, page = 1, pageLimit = 15) => {
    setLoading(true);
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;

    TutorialService.getWorkInstructionByCompanyIdInRecycleBin(companyId, page, pageLimit, search, sort, filter)
      .then((e) => {
        setTotalWorkInstructionsLength(e.data.data.total);
        e.data.data.workInstructions.map((wi) => {
          FileManagerService.getWorkInstructionThumbnail(localStorage.getItem('organizationId') || '', wi.workInstructionId)
            .then((res) => {
              imageObject[wi.workInstructionId] = (res as any).data[0].id;
              setImageObject({ ...imageObject });
            })
            .catch((e) => {
              imageObject[wi.workInstructionId] = '';
            });
        });
        if (workInstructions && page > 1) {
          setWorkInstructions([...workInstructions, ...(e.data.data.workInstructions as IWorkInstructions[])]);
        } else {
          setWorkInstructions(e.data.data.workInstructions as IWorkInstructions[]);
        }
        setCurrPage(page + 1);
      })
      .catch(({ response }) => {
        setLoading(false);
      });
  };

  const loadDataProcedure = (companyId: string, search = searchVal, sort = sortVal, filter = filterVal, page = 1, pageLimit = 15) => {
    setLoading(true);
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;

    TutorialService.getWorkInstructionBasedOnDeleteProcedure(companyId, page, pageLimit, search, sort, filter)
      .then((e) => {
        setTotalProcedureDeleteWILength(e.data.data.procedureCount);
        setTotalWiDeleteProcedure(e.data.data.total);

        e.data.data.workInstructions.map((wi) => {
          FileManagerService.getWorkInstructionThumbnail(localStorage.getItem('organizationId') || '', wi.workInstructionId)
            .then((res) => {
              imageObject[wi.workInstructionId] = (res as any).data[0].id;
              setImageObject({ ...imageObject });
            })
            .catch((e) => {
              imageObject[wi.workInstructionId] = '';
            });
        });
        if (deletedProcedures && page > 1) {
          setDeletedProcedures([...deletedProcedures, ...(e.data.data.workInstructions as IWorkInstructions[])]);
        } else {
          setDeletedProcedures(e.data.data.workInstructions as IWorkInstructions[]);
        }

        setCurrPageProcedure(page + 1);
      })
      .catch(({ response }) => {
        setLoading(false);
      });
  };

  const handleReloadData = () => {
    if (companyId) {
      loadData(companyId);
      loadDataProcedure(companyId);
    }
  };
  const theme = useTheme();

  const StyledNavLink = styled(NavLink)({
    padding: '10px 24px',
    borderRadius: '40px',
    marginRight: '16px',
    whiteSpace: 'nowrap',
    fontSize: ' 0.875rem',
    '&.active': {
      border: '2px solid' + theme.palette.primary.main,
    },
  });

  const search = (key: string) => {
    setSearchVal(key);

    if (companyId) {
      loadData(companyId, key);
      loadDataProcedure(companyId, key);
    }
  };

  const onSortClose = () => {
    setOpen(false);
  };
  const onFilterClose = () => {
    setFilterDropdownOpen(false);
    setFilterOpen(false);
  };

  const sortByModifiedDate = (ws: IWorkInstructions[]) => {
    if (ws) {
      return ws.sort((obj1, obj2) => {
        if (obj1.modifiedOn < obj2.modifiedOn) {
          return 1;
        }
        if (obj1.modifiedOn > obj2.modifiedOn) {
          return -1;
        }
        return 0;
      });
    }
  };

  const removeFilter = (filter, value) => {
    let newArr = [...appliedFilterList];
    newArr.map((item) => {
      if (item.filter === filter && item.value === value) {
        item.applied = false;
      }
    });
    applyFilters(newArr);
    setAppliedFilterList(newArr);
  };

  const DeleteProcedureHandler = () => {
    setIsDeletedWorkInstructionBackButton(false);
  };
  const DeleteWiHandler = () => {
    setIsDeletedWorkInstructionBackButton(true);
  };

  return (
    <>
      {!workInstructions && !deletedProcedures && <Loading />}
      {workInstructions && deletedProcedures && (
        <div>
          <Paper elevation={0} className="instructions-title-container" sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Box
              component="div"
              className="analytics-header"
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box component="div">
                <Tabs
                  value={overview === 'DELETED_WORK_INSTRUCTION' ? 0 : 1}
                  onChange={(e, newTabIndex) => {
                    setOverview(newTabIndex === 0 ? 'DELETED_WORK_INSTRUCTION' : 'DELETED_PROCEDURES');
                  }}
                  sx={{ '& .MuiTabs-indicator': { display: 'none' } }}
                >
                  <Tab
                    sx={{ padding: 0, marginRight: '4px' }}
                    label={
                      <Button size="small" onClick={DeleteWiHandler} variant={overview === 'DELETED_WORK_INSTRUCTION' ? 'contained' : 'outlined'} sx={{ margin: 0, borderRadius: '8px 8px 8px 8px' }}>
                        {' '}
                        {t('WI_LIST.DELETED_WORK_INSTRUCTION') as string} {'('}
                        {workInstructions && totalWorkInstructionsLength}
                        {')'}
                      </Button>
                    }
                  />
                  <Tab
                    sx={{ padding: 0 }}
                    label={
                      <Button size="small" onClick={DeleteProcedureHandler} variant={overview === 'DELETED_PROCEDURES' ? 'contained' : 'outlined'} sx={{ margin: 0, borderRadius: '8px 8px 8px 8px', marginLeft: '8px' }}>
                        {' '}
                        {t('WI_LIST.DELETED_PROCEDURES') as string} {'('}
                        {deletedProcedures && totalProcedureDeleteWILength}
                        {')'}
                      </Button>
                    }
                  />
                </Tabs>
              </Box>
            </Box>

            <Box component="div" className="right">
              <Box component="div" sx={{ display: 'flex', alignItems: 'flex-end' }} className="search-btn">
                <FormControl fullWidth>
                  <TextField
                    onChange={(e) => search(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {' '}
                          <Search sx={{ color: 'primary.main', mr: 1, my: 0.5 }} />{' '}
                        </InputAdornment>
                      ),
                    }}
                    placeholder={t('COMMON.SEARCH')}
                    style={{ borderRadius: '8px !important' }}
                  />
                </FormControl>
              </Box>
            </Box>
          </Paper>

          <Grid container spacing={1} sx={{ py: 2, px: 3, display: { xs: 'flex', md: 'none' } }}>
            <Grid item xs={9}>
              <Box component="div" display="flex" justifyContent="flex-start">
                <Typography variant="h6" component="div" sx={{ mr: 1, fontSize: '13px' }}>
                  {' '}
                  {t('WI_LIST.ALL_WORK_INSTRUCTIONS') as string}{' '}
                </Typography>
                {workInstructions && (
                  <Avatar sx={{ bgcolor: 'grey.300', color: '#000', height: '24px', padding: '0', fontSize: '13px', fontWeight: 600}} variant="rounded">
                    {' '}
                    {totalWorkInstructionsLength}{' '}
                  </Avatar>
                )}
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box component="div" display="flex" justifyContent="flex-end">
                <Box component="div" mr={1} onClick={(e) => setOpen(true)}>
                  <SortDesc />
                </Box>
                <Box component="div" mr={1} onClick={(e) => setFilterOpen(true)}>
                  <ActionFilter />
                </Box>
                <Tooltip title="Refresh work instructions">
                  <IconButton color="primary" sx={{ mr: props.isTrainer ? 1 : 0, borderRadius: '8px', p: 0 }} onClick={handleReloadData}>
                    <Refresh />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box component="div" sx={{ display: 'flex', alignItems: 'flex-end' }} className="search-btn">
                <FormControl fullWidth>
                  <TextField
                    onChange={(e) => search(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {' '}
                          <Search sx={{ color: 'primary.main', mr: 1, my: 0.5 }} />{' '}
                        </InputAdornment>
                      ),
                    }}
                    placeholder={t('COMMON.SEARCH') as string}
                    style={{ borderRadius: '8px !important' }}
                  />
                </FormControl>
              </Box>
            </Grid>
          </Grid>
          <Box component="div" sx={{ display: { xs: 'none', md: 'flex' }, flexDirection: 'row', alignItems: 'flex-start', padding: '1rem 0rem 0rem 2rem', maxWidth: '100%' }}>
            {appliedFilterList.map((item, i) => {
              if (item.applied) {
                return (
                  <Box key={i} component="div" border={'1px solid' + theme.palette.primary.main + '1c'} sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', borderRadius: '8px', maxWidth: '100%', padding: '0px 4px', margin: '0px 10px 0px 0px' }}>
                    <Typography className="filter-text" key={item}>
                      {!props.isTrainer && (t(TRANSLATE_TRAINEE_FILTERS[item.value.toUpperCase()]) as string)}
                      {props.isTrainer && (t('COMMON.' + translate[item.value.toUpperCase()]) as string)}
                    </Typography>
                    <Button sx={{ minWidth: 0 }} onClick={() => removeFilter(item.filter, item.value)}>
                      <HighlightOffIcon fontSize="small" />
                    </Button>
                  </Box>
                );
              }
            })}
          </Box>
          {overview === 'DELETED_WORK_INSTRUCTION' && (
            <InfiniteScroll dataLength={workInstructions?.length || 10} next={() => loadData(companyId ? companyId : '', searchVal, sortVal, filterVal, currPage)} hasMore={(workInstructions?.length || 0) < totalWorkInstructionsLength} loader={<p>{t('COMMON.LOADING')}</p>}>
              <Grid className="card-container" container spacing={{ xs: 3, md: 4 }} direction={'row'} alignItems={'stretch'} sx={{ marginBottom: { xs: '80px', md: '0px' } }}>
                {workInstructions &&
                  workInstructions.map((ws, index) => {
                    return (
                      <Grid item xs={12} md={4} lg={4} key={index}>
                        <WorkInstructionCards companyId={companyId} workInstruction={ws} isTrainer={props.isTrainer} workInstructionImageURL={imageObject ? imageObject[ws.workInstructionId] : ''} showRecycleBinHome={true} hideRecycleBinHome={true} recycleBinCardHeight="60%" isDelete={props.isDelete} isDeletedWorkInstructionBackButton={isDeletedWorkInstructionBackButton} />
                      </Grid>
                    );
                  })}
              </Grid>
            </InfiniteScroll>
          )}
          {overview === 'DELETED_PROCEDURES' && (
            <InfiniteScroll dataLength={deletedProcedures?.length || 10} next={() => loadDataProcedure(companyId ? companyId : '', searchVal, sortVal, filterVal, currPageProcedure)} hasMore={(deletedProcedures?.length || 0) < totalWiDeleteProcedure} loader={<p>{t('COMMON.LOADING')}</p>}>
              <Grid className="card-container" container spacing={{ xs: 3, md: 4 }} direction={'row'} alignItems={'stretch'} sx={{ marginBottom: { xs: '80px', md: '0px' } }}>
                {deletedProcedures &&
                  deletedProcedures.map((ws, index) => {
                    return (
                      <Grid item xs={12} md={4} lg={4} key={index}>
                        <WorkInstructionCards isShowProcedures={isShowProcedures} isDelete={props.isDelete} companyId={companyId} workInstruction={ws} isTrainer={props.isTrainer} workInstructionImageURL={imageObject ? imageObject[ws.workInstructionId] : ''} isDeletedWorkInstructionBackButton={isDeletedWorkInstructionBackButton} />
                      </Grid>
                    );
                  })}
              </Grid>
            </InfiniteScroll>
          )}

          <SortDialog open={open} sort={sort} setSort={setSort} sortFilter={sortFilter} sortOptions={sortOptions} onClose={onSortClose}></SortDialog>
          <FilterDialog activeFilters={filterVal} open={filterOpen} filter={filter} setFilter={setFilter} isTrainer={props.isTrainer} filterOptions={props.isTrainer ? filterOptionsTrainer : filterOptionsTrainee} onClose={onFilterClose} applyFilters={applyFilters}></FilterDialog>
        </div>
      )}
    </>
  );
};

export default RecycleBinHome;
