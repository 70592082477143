import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import { IAppScopeFormValue, IPersonaFormValues } from '.';
import { RESOURCE_ACTIONS } from '../../shared/data/resource-actions';

interface AddResourcesProps {
  formik: FormikProps<IPersonaFormValues>;
  index: number;
  appScopes: Array<IAppScopeFormValue>;
  appScope: IAppScopeFormValue | null;
  onDelete: (appScope: IAppScopeFormValue, index: number) => void;
  onChange: (appScope: IAppScopeFormValue, index: number) => void;
}

const AddResources: React.FC<AddResourcesProps> = ({
  formik,
  index,
  appScopes,
  appScope,
  onDelete,
  onChange,
}) => {
  const [appScopeData, setAppScopeData] = useState<IAppScopeFormValue>();
  const [resources, setResources] = useState<string[]>([]);
  const [actions, setActions] = useState<string[]>([]);
  const [resourceData, setResourceData] = useState('');
  const [actionsData, setActionsData] = useState<string[]>([]);

  useEffect(() => {
    const resourceList = Object.keys(RESOURCE_ACTIONS).map((key) => key);
    setResources(resourceList);
    if (appScope && appScope.resourceName !== '') {
      setAppScopeData(appScope);
      setResourceData(appScope.resourceName);
      populateActions(appScope.resourceName);
      setActionsData(appScope.actions);
    }
  }, []);

  useEffect(() => {
    setAppScopeData({
      resourceName: resourceData,
      actions: actionsData,
    });

    return () => {};
  }, [resourceData, actionsData]);

  useEffect(() => {
    if (appScopeData) {
      onChange(appScopeData, index);
    }

    return () => {};
  }, [appScopeData]);

  const populateActions = (selectedResource: string) => {
    setResourceData(selectedResource);
    const selectedActions = RESOURCE_ACTIONS[selectedResource];
    if (selectedActions && selectedActions.length) {
      setActions(selectedActions);
    }
  };

  const handleChangeResource = (event) => {
    if (event?.target?.value) {
      const selectedResource = event.target.value;
      populateActions(selectedResource);
    }
  };

  const handleChangeAction = (
    event: React.ChangeEvent<HTMLInputElement>,
    action: string,
  ) => {
    let actionChanged = [...actionsData];
    if (event?.target?.checked) {
      if (!actionChanged.includes(action)) {
        actionChanged.push(action);
        setActionsData(actionChanged);
      }
    } else {
      actionChanged = actionChanged.filter((ac) => ac !== action);
      if (actionChanged.length < actionsData.length) {
        setActionsData(actionChanged);
      }
    }
  };

  const handleDelete = () => {
    if (appScopeData) {
      onDelete(appScopeData, index);
    }
  };

  const isResourceDisabled = (resourceName: string) => {
    if (resourceName) {
      const resourceList = formik.values.associationForm.appScopes.map((ac) => ac.resourceName);
      return resourceList.includes(resourceName);
    }
  };

  return (
    <Grid container spacing={4} pt={2}>
      <Grid item sx={{ minWidth: '250px' }}>
        <FormControl fullWidth required>
          <FormLabel>Resource</FormLabel>
          <Select
            name="resourceName"
            value={resourceData}
            onChange={handleChangeResource}
          >
            {resources?.length > 0 &&
              resources.map((resource) => (
                <MenuItem
                  disabled={isResourceDisabled(resource)}
                  key={resource}
                  value={resource}
                >
                  {resource}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <InputLabel required>Access</InputLabel>
        <Box component={'div'} sx={{ display: 'flex' }}>
          {actions &&
            actions.length > 0 &&
            actions.map((action) => (
              <FormGroup
                key={action}
                sx={{
                  border: '1px solid #c4c4c4',
                  borderRadius: '8px',
                  height: '40px',
                  marginRight: '10px',
                }}
              >
                <FormControlLabel
                  sx={{ marginTop: '-1px' }}
                  control={
                    <Checkbox
                      sx={{ ml: '12px' }}
                      color="primary"
                      name={action}
                      checked={actionsData.includes(action)}
                      onChange={(e) => handleChangeAction(e, action)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label={action}
                />
              </FormGroup>
            ))}
        </Box>
      </Grid>
      <Grid item>
        <IconButton
          size="small"
          color="primary"
          aria-label="close"
          onClick={handleDelete}
          sx={{
            border: '1px solid',
            height: 'fit-content',
            top: '1.5em',
          }}
        >
          <CloseIcon fontSize="small" color="primary" />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default AddResources;
