import { Box, Button, createTheme, Grid, Stack, Theme, Typography, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { SwatchesPicker } from 'react-color';
import { useDropzone } from 'react-dropzone';
import { IAppTheme, ITheme } from '../../entities/Organization/Theme/Theme';
import OrganizationService from '../../services/Organization/OrganizationService';
import { fileSize } from '../../shared/utils/helpers';
import { getMessage } from '../../shared/utils/message-helpers';
import { getAppTheme } from '../../shared/utils/theme-helpers';
import { Toast } from '../Notifications/Notification';
import { useTranslation } from 'react-i18next';
import './ThemeSupportSection.scss';

interface Props {
  onThemeChange: (appTheme: IAppTheme) => void;
  onLoading: (flag: boolean) => void;
  handleClose : () => void;
}

export const ThemeSupportSection = (props: Props) => {
  const [currentTheme, setCurrentTheme] = useState<ITheme>();
  const [selectedFile, setSelectedFile] = useState(null);
  const [file, setFile] = useState<any>();
  const [previewUrl, setPreviewUrl] = useState('');
  const [selectedColorObj, setSelectedColorObj] = useState({ hex: '#004B8D' });
  const [selectedColor, setSelectedColor] = useState('#004B8D');
  const [currentColor, setCurrentColor] = useState('#004B8D');
  const [fileError, setFileError] = useState('');
  const theme = useTheme();
  const maxFileSizeInKB = 2 * 1024;
  // const colors = [
  //   ['#004B8D', '#5C00AF', '#0099BC'],
  //   ['#284613', '#CD035B', '#b71c1c'],
  //   ['#f44336', '#9c27b0', '#3f51b5'],
  //   ['#2196f3', '#00bcd4', '#009688'],
  //   ['#4caf50', '#ffc107', '#795548'],
  // ];
  const colors = [
    ['#004B8D', '#5C00AF', '#0099BC'],
    ['#284613', '#CD035B'],
  ];

  const { t } = useTranslation();

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((f): void => {
      if (f) {
        const fileSizeInKB = f.size / 1024;
        if (fileSizeInKB > maxFileSizeInKB) {
          setFileError(`${t('SYSTEM_SETTINGS.SELECTED_FILE_SIZE')} [${fileSize(f.size)}] ${t('SYSTEM_SETTINGS.EXCEEDS_THE_MAXIMUM_LIMIT_OF')} 2MB.`);
          setSelectedFile(null);
          setPreviewUrl('');
          setFile(null);
        } else {
          setFileError('');
          setSelectedFile(f);
          setPreviewUrl(URL.createObjectURL(f));
          setFile(
            <div key={f.path}>
              <p className="file-name">
                <b>{f.path}</b>
              </p>
              {fileSize(f.size)}
            </div>,
          );
        }
      }
    });
  }, []);

  // Initialize
  useEffect(() => {
    getCurrentTheme();

    return () => {
      // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
      if (selectedFile && previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, []);

  const getCurrentTheme = () => {
    props.onLoading(true);
    OrganizationService.getTheme()
      .then((e) => {
        const {
          data: { data: themeData },
        } = e;
        setCurrentTheme(themeData);
        setCurrentColor(themeData.mainColor);
        setSelectedColor(themeData.mainColor);
        setSelectedColorObj({ hex: themeData.mainColor });
        props.onLoading(false);
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        props.onLoading(false);
      });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      'image/png': ['.png'],
      'image/svg+xml': ['.svg'],
    },
  });

  const handleChangeComplete = (color) => {
    if (color && color.hex) {
      setSelectedColorObj(color);
      setSelectedColor(color.hex);
    }
  };

  const handlePreview = () => {
    if (currentTheme) {
      const newTheme = createTheme(getAppTheme(selectedColor));
      const orgTheme: ITheme = {
        ...currentTheme,
        logoUrl: previewUrl ?  previewUrl : currentTheme.logoUrl,
        mainColor: selectedColor,
      };
      const appTheme: IAppTheme = {
        muiTheme: newTheme,
        orgTheme: orgTheme,
      };
      props.onThemeChange(appTheme);
    }
  };

  const handleSave = () => {
    if (currentTheme) {
      const formData: FormData = new FormData();

      if(selectedFile)
      formData.append('logo', selectedFile);
      formData.append('organizationId', currentTheme.organizationId);
      formData.append('mainColor', selectedColor);

      props.onLoading(true);
      OrganizationService.updateTheme(formData)
        .then((e) => {
          handlePreview();
          Toast.success(t('OPERATION_SUCCESS', { ns: 'success' }), t(e.data.messageCode, { ns: 'success' }) || e.data.message);
          props.onLoading(false);
          props.handleClose();
        })
        .catch(({ response }) => {
          Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
          console.error('[ThemeSupportSection -> handleSave]', response);
          props.onLoading(false);
        });
    }
  };

  return (
    <>
      <Grid container direction="column" spacing={0} className="themeSupportSection">
        <Grid item xs={12}>
          <Typography variant="h6">{t('SYSTEM_SETTINGS.UPLOAD_LOGO') as string}</Typography>
          <div className="drop-zone">
            {!selectedFile && (
              <div {...getRootProps()} className="drop-zone-container" style={{ height: '6rem', textAlign: 'center' }}>
                <input {...getInputProps()} />
                <Box component="div" sx={{ color: 'rgba(28, 29, 31, .4)', mb: 1 }}>
                  {t('COMMON.UPLOAD_FILE_MESSAGE') as string}
                </Box>
                <Box component="div" sx={{ display: 'flex', mb: 1 }}>
                  <img
                    src="/imgs/drag_drop.svg"
                    alt="files"
                    height="50"
                    width="50"
                    style={{
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  />
                </Box>
                {fileError && (
                  <Box component="div" sx={{ color: theme.palette.error.main }}>
                    {fileError}
                  </Box>
                )}
              </div>
            )}
            {selectedFile && (
              <div className="file-container">
                <Box component="div" className="image" sx={{ display: 'flex', bgcolor: '#ffffff' }}>
                  <img
                    src={previewUrl}
                    alt="preview"
                    height="80"
                    width="140"
                    style={{
                      objectFit: 'contain',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  />
                </Box>
                <Box component="div" sx={{ display: 'flex', flexDirection: 'column', pl: 2 }}>
                  <Box component="div"> {file}</Box>
                  <div {...getRootProps()} className="browse">
                    <Button
                      variant="outlined"
                      sx={{
                        color: (theme: Theme) => theme.palette.primary.main,
                        height: 'fit-content',
                      }}
                    >
                      Replace <input {...getInputProps()} />
                    </Button>
                  </div>
                </Box>
              </div>
            )}
          </div>
          <Stack direction="row" justifyContent="space-between" alignItems="flex-start" sx={{ py: 1 }} spacing={2}>
            <Box component="span" className="helperText" sx={{ color: 'rgba(28, 29, 31, .8)' }}>
              {t('COMMON.MAX_FILE_SIZE', { MB: '2MB' }) as string}
            </Box>
            <Box component="span" className="helperText" sx={{ color: 'rgba(28, 29, 31, .8)' }}>
              {t('COMMON.SUPPORTS') as string} ".png", ".svg"
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack sx={{ mt: 2 }} direction="row" justifyContent="space-between" alignItems="flex-start">
            <Stack direction="column" alignItems="flex-start">
              <Box component="div" sx={{ mb: 1 }}>
                {t('SYSTEM_SETTINGS.ALL_THEME_OPTIONS') as string}
              </Box>
              <SwatchesPicker height="105px" color={selectedColorObj} colors={colors} className="swatch-layout" onChangeComplete={handleChangeComplete} />
            </Stack>
            <Stack direction="column" alignItems="flex-start">
              <Box component="div">
                <Box component="div" sx={{ fontSize: '12px', opacity: 0.6, mb: '2px' }}>
                  {t('SYSTEM_SETTINGS.CURRENT_THEME') as string}
                </Box>
                <Box
                  component="div"
                  sx={{
                    width: 90,
                    height: 36,
                    paddingTop: '1px',
                    boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;',
                  }}
                >
                  <Box
                    component="div"
                    sx={{
                      width: 82,
                      height: 28,
                      margin: '4px',
                      backgroundColor: currentColor,
                    }}
                  ></Box>
                </Box>
              </Box>
              <Box component="div">
                <Box component="div" sx={{ fontSize: '12px', opacity: 0.6, mt: 1, mb: '2px' }}>
                  {t('SYSTEM_SETTINGS.SELECTED_THEME') as string}
                </Box>
                <Box
                  component="div"
                  sx={{
                    width: 90,
                    height: 36,
                    paddingTop: '1px',
                    boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;',
                  }}
                >
                  <Box
                    component="div"
                    sx={{
                      width: 82,
                      height: 28,
                      margin: '4px',
                      backgroundColor: selectedColor,
                    }}
                  ></Box>
                </Box>
              </Box>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Box
            component="div"
            sx={{
              mt: 3,
              display: 'flex',
              alignItems: 'flex-start',
            }}
          >
            <Button disabled={Boolean(fileError)} onClick={handlePreview} variant="outlined" sx={{ color: (theme: Theme) => theme.palette.primary.main }}>
              {t('BUTTON.PREVIEW') as string}
            </Button>
            <Button disabled={Boolean(fileError)} variant="contained" className="activeButton nonActiveButton" onClick={handleSave}>
              {t('BUTTON.SAVE') as string}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
