import { configureStore } from '@reduxjs/toolkit';
import appReducer from './features/app/app.slice';
import modularProcedureReducer, { fetchWorkInstruction } from './features/ModularProcedures/ModularProcedures.slice';
import homePageSlice from './features/HomePage/HomePage.slice';
import proceduresSlice from './features/Procedures/Procedures.slice';
import AnalyticsSlice from './features/Analytics/Analytics.slice';
import SidebarSlice from './features/SideBar/Sidebar.slice';
import SubstitutionsSlice from './features/Substitutions/Substitutions.slice';
import CommentsSlice from './features/Comments/Comments.slice';

export const store = configureStore({
  reducer: {
    app: appReducer,
    modularProcedure: modularProcedureReducer,
    homePage: homePageSlice,
    procedure: proceduresSlice,
    analytics: AnalyticsSlice,
    sidebar: SidebarSlice,
    substitution: SubstitutionsSlice,
    comments: CommentsSlice
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(paramWatcherMiddleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

function paramWatcherMiddleware(store) {
  return (next) => (action) => {
    const result = next(action);

    // Check if the action is setProcedureToView
    if (action.type === 'modularProcedure/setProcedureToView') {
      const state = store.getState();
      const selectedProcedure = state.modularProcedure.procedureLibrary.selectedProcedure;
      let procedure = state.modularProcedure.templateProcedures.data.data.find((procedure) => procedure.procedureId === selectedProcedure);

      const currentLoadedWorkInstructionDetails = state.modularProcedure.workInstruction.data;

      // Dispatch the asyncThunk if param is set
      if (procedure && currentLoadedWorkInstructionDetails?.workInstructionId !== procedure.workInstructionId) {
        let workInstructionToLoad = procedure.workInstructionId;
        store.dispatch(fetchWorkInstruction(workInstructionToLoad));
      }
    }

    return result;
  };
}
