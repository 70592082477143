const languages = [
  {
    code: 'id',
    name: 'Bahasa (Indonesia)',
  },
  {
    code: 'zh',
    name: 'Chinese',
  },
  {
    code: 'de',
    name: 'German',
  },
  {
    code: 'en',
    name: 'English',
  },
  {
    code: 'es',
    name: 'Spanish',
  },
  {
    code: 'fr',
    name: 'French (Canada)',
  },
  {
    code: 'fr',
    name: 'French (France)',
  },
  {
    code: 'it',
    name: 'Italian',
  },
  {
    code: 'ja',
    name: 'Japanese',
  },
  {
    code: 'ko',
    name: 'Korean',
  },
  {
    code: 'nl',
    name: 'Dutch',
  },
  {
    code: 'pl',
    name: 'Polish',
  },
  {
    code: 'pt',
    name: 'Portuguese (Brazil)',
  },
  {
    code: 'ro',
    name: 'Romanian',
  },
  {
    code: 'ru',
    name: 'Russian',
  },
  {
    code: 'sv',
    name: 'Swedish',
  },
  {
    code: 'vi',
    name: 'Vietnamese',
  },
  {
    code: 'kh',
    name: 'Khmer (Cambodia)',
  },
];

export default languages;
