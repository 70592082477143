import { Avatar, useTheme } from '@mui/material';

export const StyledAvatarRecordCount = (props) => {
  const theme = useTheme();
  const sx = {
    bgcolor: props.read ? 'grey.300' : '#1C1D1F',
    width: '24px',
    height: '24px',
    padding: '0 5px',
    border: '1px',
    borderColor: props.read ? 'black' : null,
    BorderInnerOutlined: 'black',
    color: props.read ? 'black' : 'white',
    fontSize: '16px'
  };
  return (
    <Avatar
      sx={
        props.stepComment
          ? sx
          : {
              bgcolor: 'grey.300',
              color: '#000',
              height: '32px',
              padding: '0 5px',
            }
      }
      variant="rounded"
      {...props}
    >
      {props.children}
    </Avatar>
  );
};
