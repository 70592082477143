import { IOrganization } from '../../entities/Organization/Organization';

export interface IDetailForm {
  id: string;
  name: string;
  alias: string;
  identifier: string;
  description: string;
  idp: string;
  idpName: string;
  isActive: boolean;
}

export interface ILocationForm {
  latitude: string;
  longitude: string;
}

export interface IAddressForm {
  addressLine1: string;
  addressLine2: string;
  country: string;
  state: string;
  city: string;
  postalCode: string;
  location: ILocationForm;
}

export interface IAdditionalContactForm {
  designation: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  alternateNumber: string;
}

export interface IContactForm {
  emailAddress: string;
  phoneNumber: string;
  alternateNumber: string;
  additionalContacts: Array<IAdditionalContactForm>;
}

export interface IOrganizationFormValues {
  detailForm: IDetailForm;
  addressForm: IAddressForm;
  contactForm: IContactForm;
}

export type toOrganizationFormValuesType = (apiData: Partial<IOrganization>) => IOrganizationFormValues;
export type toOrganizationPayloadType = (formValues: IOrganizationFormValues) => IOrganization;

export const toOrganizationFormValues: toOrganizationFormValuesType = apiData => ({
  detailForm: {
    id: apiData?.id || '',
    name: apiData?.name || '',
    alias: apiData?.alias || '',
    identifier: apiData?.identifier || '',
    description: apiData?.description || '',
    idp: apiData?.idp || '',
    idpName: apiData?.idpName || '',
    isActive: apiData?.isActive || true,
  },
  addressForm: {
    addressLine1: apiData?.address?.addressLine1 || '',
    addressLine2: apiData?.address?.addressLine2 || '',
    country: apiData?.address?.country || 'United States',
    state: apiData?.address?.state || '',
    city: apiData?.address?.city || '',
    postalCode: apiData?.address?.postalCode || '',
    location: apiData?.address?.location || { latitude: '', longitude: '' },
  },
  contactForm: {
    emailAddress: apiData?.ownContact?.emailAddress || '',
    phoneNumber: apiData?.ownContact?.phoneNumber || '',
    alternateNumber: apiData?.ownContact?.alternateNumber || '',
    additionalContacts: apiData?.additionalContacts || [],
  }
});

export const toOrganizationPayload: toOrganizationPayloadType = formValues => ({
  id: formValues.detailForm.id,
  name: formValues.detailForm.name?.trim(),
  alias: formValues.detailForm.alias?.trim(),
  identifier: formValues.detailForm.identifier?.trim(),
  description: formValues.detailForm.description?.trim(),
  idp: formValues.detailForm.idp,
  idpName: formValues.detailForm.idpName,
  isActive: formValues.detailForm.isActive,
  address: {
    addressLine1: formValues.addressForm.addressLine1,
    addressLine2: formValues.addressForm.addressLine2,
    country: formValues.addressForm.country,
    state: formValues.addressForm.state,
    city: formValues.addressForm.city,
    postalCode: formValues.addressForm.postalCode,
    location: formValues.addressForm.location,
  },
  additionalContacts: formValues.contactForm.additionalContacts,
  ownContact: {
    emailAddress: formValues.contactForm.emailAddress?.trim(),
    phoneNumber: formValues.contactForm.phoneNumber?.trim(),
    alternateNumber: formValues.contactForm.alternateNumber,
  }
});
