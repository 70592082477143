import Grid from '@mui/material/Grid';
import React from 'react';
import { IWorkInstructionCreate } from '../../../entities/Trainee/WorkInstructions';
import NewWorkInstruction from '../NewWorkInstruction/NewWorkInstruction';

interface Props {}

const EditWorkInstruction: React.FC<Props> = (props) => {
  const handleGoBackClickedInWorkInstruction = (formData: IWorkInstructionCreate, fileData: any) => {

  }

  const handleGoNextClickedInWorkInstruction = (formData: IWorkInstructionCreate, fileData: any) => {

  }

  return (
    <Grid container alignItems="center" justifyContent="center">
      <NewWorkInstruction
        formData={null}
        fileData={null}
        isEditWorkInstruction={true}
        showBackButton={false}
        onGoBack={(formData, fileData) => handleGoBackClickedInWorkInstruction(formData, fileData)}
        onGoNext={(formData, fileData) => handleGoNextClickedInWorkInstruction(formData, fileData)} />
    </Grid>
  );
};

export default EditWorkInstruction;
