import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Link, { LinkProps } from '@mui/material/Link';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { IconButton, Typography, Button, Box, MenuItem, useTheme, Badge, Drawer, DialogTitle, Divider, Grid, ClickAwayListener } from '@mui/material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { CommentSideBar } from '../../Analytics/ProcedureReports/CommentsSideBar/CommentSideBar';
import { CommentSliderWindow } from '../../Analytics/ProcedureReports/CommentsSideBar/CommentSliderWindow';
import TutorialService from '../../../services/trainee/TraineeService';
import { Toast } from '../../Notifications/Notification';
import { Access } from '../../Auth/Access';
import { WorkInstructionPermissions } from '../../../shared/permissions';
import Loading from '../../Loading/Loading';
import { ArrowRightIcon, CloseIcon } from '../../Icons/CdsIcons';
import { StyledAvatarRecordCount } from '../../Styled/StyledAvatarRecordCount';
import { getFormattedTimeFromUnixTime } from '../../../shared/utils/formateDate';
import useLoadThreads from '../../../hooks/useLoadThreads';

const formatDate = (timeStamp: number) => {
    const date = new Date(timeStamp);
    const options: any = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
    return formattedDate.toString();
};

interface NotificationCardProps {
    notification: any;
}

interface AppBarNotificationProps {
    isTrainer?: boolean;
    companyId?: string;
}

interface LinkRouterProps extends LinkProps {
    to: string;
    replace?: boolean;
}

const LinkRouter = (props: LinkRouterProps) => (
    <Link
        {...props}
        component={RouterLink as any}
    />
);

const RevisitNotification: React.FC<NotificationCardProps> = ({ notification }) => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        notification.count > 0 && (
            <LinkRouter to="/trainer/RevisitRequests/">
                <MenuItem
                    aria-controls="menu-user-manage-md"
                    aria-haspopup="true"
                    sx={{ display: 'flex', flexDirection: 'column' }}
                >
                    <Box
                        component="span"
                        sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', padding: '10px 12px', color: 'black', width: '100%' }}
                    >
                        <Typography sx={{ fontSize: '14px' }}>
                            <span>{notification.count}</span> <span style={{ fontWeight: '400' }}> {notification.count > 1 ? t('NAVBAR.PERIODIC_REVIEWS') : t('NAVBAR.PERIODIC_REVIEW')}</span>
                        </Typography>
                    </Box>
                </MenuItem>
            </LinkRouter>
        )
    );
};

const StepCommentNotification: React.FC<any> = (props) => {
    const { notification, isTrainer, handleCloseNotificationMenu } = props;
    const [showRightPaneComment, setShowRightPaneComment] = useState<boolean>(false);
    const [showChats, setShowChats] = React.useState<boolean>(false);
    const navigate = useNavigate();

    const traineeSpecificCommentHandler = (procedureId) => {
        // setShowChats(true);
        handleCloseNotificationMenu();
        navigate(`/notifications?procedureId=${procedureId}`);
    };

    const [activeThreadId, setActiveThreadId] = useState<string>('');

    const { t } = useTranslation();
    return (
        <>
            {showChats && (
                <>
                    <CommentSideBar
                        showRightPaneComment={showRightPaneComment}
                        setShowRightPaneComment={setShowRightPaneComment}
                        isTrainer={isTrainer}
                        activeThreadId={activeThreadId}
                        setActiveThreadId={setActiveThreadId}
                        notification={notification}
                    />
                    <CommentSliderWindow
                        showRightPaneComment={showRightPaneComment}
                        setShowRightPaneComment={setShowRightPaneComment}
                        showChats={showChats}
                        setShowChats={setShowChats}
                        activeThreadId={activeThreadId}
                        setActiveThreadId={setActiveThreadId}
                        notification={notification}
                        isTrainer={isTrainer}
                    />
                </>
            )}
            <MenuItem
                onClick={() => traineeSpecificCommentHandler(notification?.target?.procedureId)}
                aria-controls="menu-user-manage-md"
                aria-haspopup="true"
                sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: 0 }}
            >
                <Grid
                    item
                    component="span"
                    sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}
                >
                    <Grid>
                        <StyledAvatarRecordCount
                            stepComment={true}
                            read={notification.target.unreadCommentCount === 0 ? true : false}
                        >
                            {notification.target.unreadCommentCount > 0 ? notification.target.unreadCommentCount : notification.target.totalCommentCount}
                        </StyledAvatarRecordCount>
                    </Grid>

                    <Grid sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Grid>
                            <Typography sx={{ marginLeft: '8px', fontSize: '16px', fontWeight: 600 }}>{notification?.target?.procedureName}</Typography>
                            <Typography sx={{ marginLeft: '8px', color: '#12181F66', fontSize: '14px', opacity: '75%', fontWeight: '400' }}>{notification?.target?.workInstructionName}</Typography>
                        </Grid>
                        <Grid>
                            <Typography sx={{ color: '#12181F66', fontSize: '14px', opacity: '75%', fontWeight: '400' }}>{getFormattedTimeFromUnixTime(notification?.timeStamp)}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </MenuItem>
        </>
    );
};

const RecertificationNotification: React.FC<any> = (props) => {
    const { notification, companyId } = props;
    const { t } = useTranslation();
    return (
        <Access permission={WorkInstructionPermissions.READ}>
            <LinkRouter to={`/recertificationDueRequest/${companyId}`}>
                <MenuItem
                    aria-controls="menu-user-manage-md"
                    aria-haspopup="true"
                    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
                >
                    <Box
                        component="span"
                        sx={{ alignItems: 'flex-start', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', padding: '10px 12px', color: 'black' }}
                    >
                        <Typography sx={{ fontSize: '14px' }}>
                            <span>{notification.count}</span> <span style={{ fontWeight: '400' }}> {notification.count > 1 ? t('NAVBAR.RECERTIFICATIONS_DUE') : t('NAVBAR.RECERTIFICATION_DUE')}</span>
                        </Typography>
                    </Box>
                </MenuItem>
            </LinkRouter>
        </Access>
    );
};

const ApprovalNotification: React.FC<NotificationCardProps> = (props) => {
    const { notification } = props;
    const { t } = useTranslation();
    return (
        <LinkRouter to="/trainer/ApprovalRequests/">
            <MenuItem
                aria-controls="menu-user-manage-md"
                aria-haspopup="true"
                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
            >
                <Box
                    component="span"
                    sx={{ alignItems: 'flex-start', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', padding: '10px 12px', color: 'black' }}
                >
                    <Typography sx={{ fontSize: '14px' }}>
                        <span>{notification.count}</span> <span style={{ fontWeight: '400' }}> {notification.count > 1 ? t('NAVBAR.APPROVAL_REQUESTS') : t('NAVBAR.APPROVAL_REQUEST')}</span>
                    </Typography>
                </Box>
            </MenuItem>
        </LinkRouter>
    );
};

const AppBarNotification: React.FC<AppBarNotificationProps> = (props) => {
    const { isTrainer, companyId } = props;

    const location = useLocation();
    const trainerHomePath = new RegExp(`trainer/home`);
    const traineeHomePath = new RegExp('home');
    const [notifications, setNotifications] = React.useState<any[]>([]);
    const [anchorElNotificationMenu, setAnchorElNotificationMenu] = React.useState<null | HTMLElement>(null);
    const [notificationLoading, setNotificationLoading] = useState<boolean>(false);

    const [value, setValue] = React.useState(0);
    const { loadCommentData, drawerLoading } = useLoadThreads();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const procedureId: any = params.get('procedureId');
        if (procedureId && !drawerLoading) {
            loadCommentData(procedureId, '', 1, 10);
        }
    }, [location.search]);
    const getNotificationByType = (notification, isTrainer, companyId) => {
        switch (notification.action) {
            case 'revisit':
                return <RevisitNotification notification={notification} />;
            case 'step comment':
                return (
                    <StepCommentNotification
                        notification={notification}
                        isTrainer={isTrainer}
                        handleCloseNotificationMenu={handleCloseNotificationMenu}
                    />
                );
            case 'recertification':
                return (
                    notification.count > 0 && (
                        <RecertificationNotification
                            notification={notification}
                            companyId={companyId}
                        />
                    )
                );
            case 'approval':
                return notification.count > 0 && <ApprovalNotification notification={notification} />;
            default:
                return false;
        }
    };


    const handleOpenNotificationMenu = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorElNotificationMenu(event.currentTarget);
    };
    const handleCloseNotificationMenu = () => {
        setAnchorElNotificationMenu(null);
    };
    const listNotifications = () => {
        setNotificationLoading(true);
        TutorialService.notificationList()
            .then((e) => {
                setNotifications(e.data.data);
                setNotificationLoading(false);
            })
            .catch(({ response }) => {
                Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response?.data?.messageCode, { ns: 'error' }) || response?.data?.errorMessage);
                setNotificationLoading(false);
            });
    };
    const navigate = useNavigate();

    const handleClickAwayNotification = () => {
        handleCloseNotificationMenu();
    };
    const showAllNotificationHandler = () => {
        handleCloseNotificationMenu();

        navigate(`/notifications?procedureId=${''}`);
        window.location.reload();
    };
    useEffect(() => {
        listNotifications();
    }, []);

    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <>
            {notifications.length ? (
                <>
                    <MenuItem
                        aria-controls="menu-admin"
                        aria-haspopup="true"
                        onClick={(e) => handleOpenNotificationMenu(e)}
                    >
                        <Badge
                            badgeContent={notifications.length}
                            color="primary"
                            sx={{ margin: '0px 16px 0px 16px', borderRadius: '8px' }}
                        >
                            <NotificationsActiveIcon />
                        </Badge>
                    </MenuItem>

                    <Drawer
                        id="menu-admin-md"
                        keepMounted
                        // onClick={handleCloseNotificationMenu}
                        open={Boolean(anchorElNotificationMenu)}
                        onClose={handleCloseNotificationMenu}
                        aria-controls={anchorElNotificationMenu ? 'demo-positioned-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={anchorElNotificationMenu ? 'true' : undefined}
                        anchor={'right'}
                        hideBackdrop
                        PaperProps={{
                            sx: { width: '40%', padding: '24px', backgroundColor: { xs: 'none' } },
                        }}
                    >
                        {notificationLoading && <Loading />}
                        <DialogTitle sx={{ width: '100%', fontSize: '16px', padding: '2px 12px 2px 12px', marginBottom: '22px' }}>
                            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', top: '14px', left: '14px' }}>
                                <Typography sx={{ width: '100%', fontWeight: '600', fontSize: '18px', lineHeight: '21.6px' }}>{t('NAVBAR.NOTIFICATION') as string}</Typography>
                                <Button
                                    onClick={showAllNotificationHandler}
                                    sx={{ color: theme.palette.primary.main, display: 'flex', flexDirection: 'row', marginRight: '8px' }}
                                >
                                    <Typography sx={{ fontSize: '14px' }}>{t('COMMON.SHOW_ALL')}</Typography>
                                    <Typography sx={{ marginTop: '4px' }}>
                                        <ArrowRightIcon />
                                    </Typography>
                                </Button>
                                <IconButton
                                    aria-label="close"
                                    onClick={handleCloseNotificationMenu}
                                    sx={{
                                        color: (theme) => theme.palette.grey[500],
                                        padding: 0,
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        </DialogTitle>
                        {/* <Box sx={{ maxWidth: { xs: 320, sm: 480 }, bgcolor: 'background.paper' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab
                  icon={<NewNotificationIcon />}
                  iconPosition="start"
                  label={t('ANALYTICS.COMMENT')}
                />

                {props.isTrainer && (
                  <Tab
                    icon={<NewNotificationIcon />}
                    iconPosition="start"
                    label={t('COMMON.REVIEW')}
                  />
                )}
                {props.isTrainer && (
                  <Tab
                    icon={<NewNotificationIcon />}
                    iconPosition="start"
                    label={t('COMMON.APPROVAL')}
                  />
                )}

                {!props.isTrainer && (
                  <Tab
                    icon={<NewNotificationIcon />}
                    iconPosition="start"
                    label={t('COMMON.RECERTIFICATION')}
                  />
                )}
              </Tabs>
            </Box> */}
                        {notifications?.map((notification, index) => {
                            let isFirstStepComment = false;

                            if (notification.action === 'step comment' && !isFirstStepComment) {
                                isFirstStepComment = true;
                                return (
                                    <React.Fragment key={index}>
                                        <Divider sx={{ margin: '8px 0' }} />
                                        <ClickAwayListener onClickAway={handleClickAwayNotification}>
                                            <Box
                                                component={'div'}
                                                key={index}
                                                sx={{
                                                    backgroundColor: notification.action !== 'step comment' ? theme.palette.primary.main + '1c' : null,
                                                    marginBottom: '8px',
                                                    borderRadius: notification.action !== 'step comment' ? '8px' : null,
                                                }}
                                            >
                                                {getNotificationByType(notification, isTrainer, companyId)}
                                            </Box>
                                        </ClickAwayListener>
                                    </React.Fragment>
                                );
                            }

                            return (
                                <ClickAwayListener
                                    onClickAway={handleClickAwayNotification}
                                    key={index}
                                >
                                    <Box
                                        component={'div'}
                                        key={index}
                                        sx={{
                                            backgroundColor: notification.action !== 'step comment' ? theme.palette.primary.main + '1c' : null,
                                            marginBottom: '8px',
                                            borderRadius: notification.action !== 'step comment' ? '8px' : null,
                                        }}
                                    >
                                        {getNotificationByType(notification, isTrainer, companyId)}
                                    </Box>
                                </ClickAwayListener>
                            );
                        })}
                    </Drawer>
                </>
            ) : (
                <NotificationsNoneIcon sx={{ margin: '0px 16px 0px 16px' }} />
            )}
        </>
    );
};

export default AppBarNotification;
