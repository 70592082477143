import { useContext } from 'react';
import PpeContext from '../context/PpeContext';

const usePpeList = () => {
  const { sharedPpeList, setSharedPpeList, showSystemSettings, setShowSystemSettings, showIntro, setShowIntro, introStep, setIntroStep, systemSettingsTab, setSystemSettingsTab } = useContext(PpeContext);
  return {
    sharedPpeList,
    setSharedPpeList,
    showSystemSettings,
    setShowSystemSettings,
    showIntro,
    setShowIntro,
    introStep,
    setIntroStep,
    systemSettingsTab,
    setSystemSettingsTab
  };
};

export default usePpeList;
